import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Grid, IconButton } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  loginBgCircle,
  loginBgRectangle,
  ISO9001,
  ISO27001,
  netzeroLogo,
  iEnergyLogo,
  helpImage,
  oilindiaLogo
} from '../constant.js';
import CopyrightIcon from '@mui/icons-material/Copyright';
import WestIcon from '@mui/icons-material/West';
import { helpTicket } from '../Apis/apiCall.js';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'Invalid phone number')
    .required('Phone number is required'),
  issuesDescription: Yup.string().required('Description is required'),
});

export default function HelpPage() {
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    const requestData = {
      deployedLink: 'https://hsse.oilindia.in',
      name: values.name,
      email: values.email,
      mobileNumber: values.phoneNumber,
      issueDescription: values.issuesDescription,
    };

    try {
      const response = await helpTicket(requestData);
      console.log('Response:', response.data);
      alert('Help ticket submitted successfully!');
      resetForm(); // Clear the form
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit the help ticket. Please try again.');
    }
  };

  return (
    <Grid container spacing={0} sx={{ backgroundColor: '#F4F5FA', minHeight: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
          <Typography sx={{ position: 'absolute', top: '24px', left: '24px', zIndex: '10', fontSize: '24px', fontWeight: '600', color: '#000000' }}>HSSE System</Typography>
          <img src={helpImage} alt="help image" style={{ width: '100%', height: '100vh' }} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ padding: '24px' }}>
        <Box
          sx={{
            backgroundImage: `url(${loginBgCircle}), url(${loginBgRectangle})`,
            backgroundSize: 'auto, auto',
            backgroundPosition: 'right -180px top -220px, left -230px bottom -170px',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundColor: '#ffffff',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '16px',
            position: 'relative',
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={2} sx={{ position: 'absolute', top: '16px', left: '16px' }}>
            <IconButton onClick={() => navigate(-1)} style={{ padding: 0 }}>
              <WestIcon style={{ fontSize: '20px', color: '#09A2E3', cursor: 'pointer' }} />
            </IconButton>
            <Typography sx={{ fontSize: '20px', color: '#09A2E3' }}>Help</Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
            <Formik
              initialValues={{
                name: '',
                email: '',
                phoneNumber: '',
                issuesDescription: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, errors, touched, isValid, dirty }) => (
                <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '72%', padding: 2, mt: '24px' }}>
                  <img src={oilindiaLogo} alt="logo" style={{ width: '114px', height: '114px', padding: '12px' }} />

                  <Form noValidate>
                    <Field
                      as={TextField}
                      label="Name*"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: 2, borderRadius: '8px', backgroundColor: '#FAFAFA' }}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <Field
                      as={TextField}
                      label="Email*"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: 2, borderRadius: '8px', backgroundColor: '#FAFAFA' }}
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <Field
                      as={TextField}
                      label="Phone Number*"
                      type="text"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: 2, borderRadius: '8px', backgroundColor: '#FAFAFA' }}
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                    <Field
                      as={TextField}
                      label="Issues Description*"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      sx={{ marginBottom: 2, borderRadius: '8px', backgroundColor: '#FAFAFA' }}
                      name="issuesDescription"
                      value={values.issuesDescription}
                      onChange={handleChange}
                      error={touched.issuesDescription && Boolean(errors.issuesDescription)}
                      helperText={touched.issuesDescription && errors.issuesDescription}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={!(isValid && dirty)}
                      sx={{
                        marginTop: 2,
                        borderRadius: '50px',
                        backgroundColor: 'linear-gradient(180deg, #A6D7FF 0%, #2196F3 100%)',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'darkblue',
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Form>
                </Box>
              )}
            </Formik>
          </Box>
          <Box display="flex" justifyContent="center" width="100%">
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
              <Box display="flex" flexDirection="row" alignItems="center" gap={4}>
                <img src={ISO9001} alt="ISO9001 logo" />
                <img src={ISO27001} alt="ISO27001 logo" />
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Typography sx={{ color: '#545454', fontSize: '12px', mt: '8px' }}>Powered by</Typography>
                <img src={netzeroLogo} alt="netzero logo" />
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" gap={0} sx={{ position: 'absolute', right: '8px', bottom: '8px' }}>
            <CopyrightIcon sx={{ fontSize: '12px', color: '#C3C3C3' }} />
            <img src={iEnergyLogo} alt="iEnergyLogo" style={{ width: '60px' }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
