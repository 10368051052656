import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Popper,
  Typography,
  Card,
  Grid,
} from "@mui/material";

import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "leaflet/dist/leaflet.css";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { actionWhiteIcon, auditIcon, locationMark } from "../../constant";
import L from "leaflet";
import MapSearchBox from "../LossControl/MapSearchBox";
import { getUserNearbyLocations } from "../../Apis/apiCall";
import { useDispatch } from "react-redux";
import {
  addLocationID,
  addSiteLocation,
} from "../../redux/LossControl/actions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import {
  format,
  parse,
  startOfWeek,
  getDay,
  addMonths,
  subMonths,
} from "date-fns";
import { getCalendarDateActions } from "../../Apis/apiCall";
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant";

const CustomPopper = (props) => {
  return (
    <Popper
      {...props}
      modifiers={[{ name: "offset", options: { offset: [0, 4] } }]}
    />
  );
};

const CustomToolbar = ({ date, onNavigate, onMonthChange }) => {
  const handleNavigation = (action) => {
    onNavigate(action);
    const newDate = action === "NEXT" ? addMonths(date, 1) : subMonths(date, 1);
    onMonthChange(newDate.getMonth() + 1, newDate.getFullYear());
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between" // For horizontal spacing
      sx={{ padding: "0px 0px 12px 0px" }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, fontSize: "16px", color: "#222" }}
      >
        {format(date, "MMMM yyyy")}
      </Typography>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, fontSize: "16px", color: "#222" }}
      >
        Scheduled Audit
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        {/* Wrapping IconButtons in a Box with gap */}
        <IconButton onClick={() => handleNavigation("PREV")} size="small">
          <ArrowBackIos sx={{ fontSize: 16, color: "#3C3E43" }} />
        </IconButton>
        <IconButton onClick={() => handleNavigation("NEXT")} size="small">
          <ArrowForwardIos sx={{ fontSize: 16, color: "#3C3E43" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default function SelectAuditSiteLocation({ handleNext }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showHello, setShowHello] = useState(false);
  const [showauditcard, setShowAuditCard] = useState(false);

  const [centralLocation, setCentralLocation] = useState([0, 0]);
  const [nearbyLocations, setNearbyLocations] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [mapType, setMapType] = useState("map"); // 'map' for normal, 'satellite' for satellite view
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [plants, setPlants] = useState([]);
  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [showToggleButtonGroup, setShowToggleButtonGroup] = useState(true); // State to control visibility

  const calendarStyles = `
.rbc-header {
    padding: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #666;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .rbc-date-cell {
    padding: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-start;
    // border-right: 1px solid #ddd;
  }
  
  .rbc-month-view {
    background-image: 
      linear-gradient(#e0e0e0 1px, transparent 1px),
      linear-gradient(90deg, #e0e0e0 1px, transparent 1px);
    background-size: calc(100% / 7) 100%, calc(100% / 7) 100%;
    background-position: -1px -1px;
    background-color: #fff;
    border-radius: 8px
  }
 
  
  .rbc-today {
    background-color: #f5f5f5;
  }

  .selected-date {
    background-color: #E0F7FA !important;
  }

  .rbc-event {
    background-color: transparent !important;
    // border-right: 1px solid #ddd;
    box-shadow: none !important;
    margin: 2px !important;
  }

  .rbc-event.rbc-selected {
    background-color: transparent !important;
  }

  .module-chip {
    margin: 2px;
    font-size: 11px;
    height: 20px;
    border-radius: 10px;
    background-color: #e3f2fd;
    color: #1976d2;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
  }
    
`;

  const locales = { "en-US": require("date-fns/locale/en-US") };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const pastTourNavigate = (id) => {
    navigate(`/module/audit-management/past-audit/${id}`);
  };

  const selectedDates = (date) => {
    setSelectedDate(date);
    const selectedEvent = events.find(
      (event) => event?.date?.toDateString() === date?.toDateString()
    );
    if (selectedEvent) {
      setSelectedModuleName(selectedEvent.modules[0]?.moduleName || "");
    }
  };

  const handleMonthChange = (month, year) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const handleOpenCard = () => {
    setShowAuditCard((prev) => !prev);
  };

  const EventComponent = ({ event }) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
      {event?.modules?.map((module, index) => {
        // Check if any action in the module is overdue
        const hasOverdueAction = module?.actions?.some(
          (action) => action.actionStatus?.toLowerCase() === "overdue"
        );

        return (
          module?.actions?.length > 0 && (
            <Box
              key={index}
              sx={{
                display: "flex",
                padding: "2px 12px 2px 6px",
                alignItems: "center",
                gap: "8px",
                alignSelf: "stretch",
                borderRadius: "0px 2px 2px 0px",
                borderLeft: hasOverdueAction
                  ? "2px solid #E53935"
                  : "2px solid #0AA2E3",
                background: hasOverdueAction
                  ? "linear-gradient(0deg, rgba(229, 57, 53, 0.10) 0%, rgba(229, 57, 53, 0.10) 100%), #FFF"
                  : "linear-gradient(0deg, rgba(10, 162, 227, 0.10) 0%, rgba(10, 162, 227, 0.10) 100%), #FFF",
                color: hasOverdueAction ? "#E53935" : "#0AA2E3",
                fontSize: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <p onClick={handleOpenCard}>{module.moduleName}</p>
            </Box>
          )
        );
      })}
    </Box>
  );

  useEffect(() => {
    fetchEvents();
  }, [currentMonth, currentYear]);

  const fetchEvents = async () => {
    dispatch({ type: START_LOADING });
    try {
      const response = await getCalendarDateActions(currentMonth, currentYear);
      const formattedEvents = response.data.data.map((dateData) => ({
        date: new Date(dateData.date),
        modules: dateData.data.filter((module) => module.actions.length > 0),
        allDay: true,
        start: new Date(dateData.date),
        end: new Date(dateData.date),
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

  // Custom icons for markers
  const defaultIcon = L.icon({
    iconUrl: `${locationMark}`,
    iconSize: [20, 32],
    iconAnchor: [15, 45],
  });

  const currentLocationIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  L.Marker.prototype.options.icon = defaultIcon;

  // Haversine formula to calculate distance between two points in km
  function haversineDistance(coords1, coords2) {
    const toRad = (x) => (x * Math.PI) / 180;

    const lat1 = coords1[0];
    const lon1 = coords1[1];
    const lat2 = coords2[0];
    const lon2 = coords2[1];

    const R = 6371; // Radius of Earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const ans = R * c;
    return ans.toFixed(1);
  }

  // Component to update the map's center
  function MapCenter({ center }) {
    const map = useMap();
    useEffect(() => {
      map.setView(center);
    }, [center, map]);

    return null;
  }

  const handleMapTypeChange = (event, newMapType) => {
    if (newMapType !== null) {
      setMapType(newMapType);
      pastTourNavigate(newMapType);
    }
  };

  const tileLayerURL =
    mapType === "map"
      ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Normal map
      : "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"; // Satellite map

  // Function to filter nearby plants within 10km of the selected HQ
  const filterNearbyPlants = (hqCoords) => {
    return plants.filter(
      (plant) => haversineDistance(hqCoords, plant.coords) <= 10
    );
  };

  const handlePlantSelect = (event, newValue) => {
    if (newValue) {
      const plantCoords = newValue.coords;
      setCentralLocation(plantCoords);
      setNearbyLocations([]); // Show only the selected plant
      setSelectedPlant(newValue);
      dispatch(addSiteLocation(newValue.displayName));
      dispatch(addLocationID(newValue.id));
      setShowHello((prev) => !prev);
      setShowToggleButtonGroup(false);
    }
  };

  const locateDevice = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const coords = [latitude, longitude];
          setDeviceLocation(coords);
          setCentralLocation(coords);
          setNearbyLocations(filterNearbyPlants(coords)); // Filter plants within 10km
          setSelectedPlant(null); // Reset selected plant
        },
        (error) => console.error(error),
        { enableHighAccuracy: true }
      );
    }
  };

  // Fetch the user's current location when the component mounts
  useEffect(() => {
    locateDevice();
  }, []);
  // console.log("selectedPlant", selectedPlant);

  // ----------------------------------Api Calls---------------------------------
  const fetchNearbyLocations = async () => {
    try {
      const result = await getUserNearbyLocations();
      //   console.log(result.data.data);

      // Extract coordinates from the array of objects
      const arr = result.data.data;
      const plantsWithCoords = arr.map((plant) => ({
        ...plant,
        coords: [plant.latitude, plant.longitude],
      }));

      setPlants(plantsWithCoords);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNearbyLocations();
  }, []);

  //To hide the bottom label
  useEffect(() => {
    const bottomControls = document.getElementsByClassName("leaflet-bottom");
    for (let i = 0; i < bottomControls.length; i++) {
      bottomControls[i].style.zIndex = "-1";
    }
  }, []);
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const selectedEvent = events.find(
      (event) => event.date.toDateString() === date.toDateString()
    );
    if (selectedEvent) {
      setSelectedModuleName(selectedEvent.modules[0]?.moduleName || "");
    }
  };

  return (
    <>
      {/* Calendar start */}

      <Box
        sx={{
          position: "relative", // Ensures proper stacking with absolute elements
          width: "100%", // Adjusts to the parent container width
        }}
      >
        {showHello && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                top: "50%",
                right: "20%",
                zIndex: 999,
                height: "530px",
                width: "50%",
                marginBottom: "20px",
                marginTop: "30px",
              }}
            >
              {/* Calendar Component */}
              <style>{calendarStyles}</style>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  padding: "8px",
                }}
                views={["month"]}
                defaultView="month"
                selectable
                selected={selectedDates}
                onSelectSlot={({ start }) => handleDateSelect(start)}
                components={{
                  toolbar: (props) => (
                    <CustomToolbar
                      {...props}
                      onMonthChange={handleMonthChange}
                    />
                  ),
                  event: EventComponent,
                  dateCellWrapper: ({ value, children }) => (
                    <Box
                      onClick={() => handleDateSelect(value)}
                      className={
                        selectedDate?.toDateString() === value?.toDateString()
                          ? "selected-date"
                          : ""
                      }
                      sx={{ cursor: "pointer", height: "100%" }}
                    >
                      {children}
                    </Box>
                  ),
                }}
              />

              {/* Card Component */}
              <Card
                sx={{
                  width: "180px",
                  height: "100px",
                  padding: "8px 12px",
                  borderRadius: "10px",
                  background: " #FFF",
                  marginTop: "8px",
                  marginLeft: "79%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "400",
                      color: "#525966",
                      fontSize: "14px",
                    }}
                  >
                    Audits Due
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "400",
                      color: "#09A2E3",
                      fontSize: "14px",
                    }}
                  >
                    10
                  </Typography>
                </Box>

                <hr style={{ border: "1px solid #ddd", margin: "10px 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "400",
                      color: "#FF5753",
                      fontSize: "14px",
                      textDecorationLine: "underline",
                      textDecorationStyle: "solid",
                    }}
                  >
                    Audits Overdue
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "400",
                      color: "#FF5753",
                      fontSize: "14px",
                    }}
                  >
                    5
                  </Typography>
                </Box>
              </Card>
            </Box>
          </>
        )}

        {showauditcard && (
          <>
            <Box
              sx={{
                background: "#FAFAFA",
                padding: "8px 8px 16px 8px",
                border: " 0.876px solid #D5E0F6",
                borderRadius: "16px",
                boxShadow:
                  "1.753px 1.753px 3.506px 0px rgba(174, 191, 237, 0.25)",
                height: "300px",
                width: "354px",
                position: "absolute",
                top: "20%", // Adjust the positioning as required
                left: "10%", // Center horizontally
                zIndex: 1000, // Ensures it appears above the calendar
                marginTop: "20%",
              }}
            >
              <Box
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#09A2E3",
                  height: "72px",
                  width: "335px",
                  padding: "8px",
                }}
              >
                <Grid container spacing={0} justifyContent="space-between">
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      Audit Management
                    </Typography>
                  </Grid>

                  <Grid item>
                    <img
                      src={actionWhiteIcon}
                      alt="actionIcon"
                      style={{
                        bgColor: "#09A2E3",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    position: "absolute",
                    border: "8px solid white",
                    left: "16px",
                    top: "55px",
                    zIndex: 10,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50px",
                    height: "50px",
                    padding: "0px",
                  }}
                >
                  <img src={auditIcon} alt="" />
                </Box>
                <Grid item sx={{ position: "absolute", top: "40%" }}>
                  <Typography
                    sx={{
                      color: "#1F242F",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "10px",
                    }}
                  >
                    Audit Date : 24th June,2024
                  </Typography>
                  <Typography
                    sx={{
                      color: "#1F242F",
                      fontWeight: "400",
                      fontSize: "14px",
                      marginBottom: "14px",
                    }}
                  >
                    ID : Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010
                  </Typography>
                  <Typography
                    sx={{
                      color: "#525966",
                      fontWeight: "600",
                      fontSize: "16px",
                    }}
                  >
                    Status :{" "}
                    <Button
                      onClick={() => handleNext()}
                      disabled={selectedPlant === null}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        backgroundColor: "#FFEFE8",
                        color: "#FF7742",
                        paddingY: "8px",
                        paddingX: "32px",
                        borderRadius: "26px",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      Conducted
                    </Button>
                  </Typography>
                  <Box>
                    <Button
                      onClick={() => handleNext()}
                      disabled={selectedPlant === null}
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        backgroundColor: "#09A2E3",
                        color: "#FF8A00;",
                        padding: "5px 101px",
                        borderRadius: "16px",
                        alignItems: "end",
                        letterSpacing: "0.25px",
                        textAlign: "center",
                        textTransform: "capitalize",
                        background: "rgba(255, 138, 0, 0.15)",
                        width: "330px",
                        height: "30px",
                      }}
                    >
                      Overdue
                    </Button>
                  </Box>
                </Grid>
                {/* )} */}
              </Box>
            </Box>
          </>
        )}
      </Box>

      {/*  */}

      {/* Calendar end  */}

      <Box
        sx={{
          position: "relative",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "16px",
          minHeight: "560px",
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          gap={1}
          sx={{ position: "absolute", top: "24px", left: "24px" }}
        >
          {/* Autocomplete Dropdown for selecting plants */}
          <Box display="flex" flexDirection="column">
            <MapSearchBox
              plants={plants}
              handlePlantSelect={handlePlantSelect}
              deviceLocation={
                deviceLocation || [27.332648182377387, 95.29226287437059]
              }
              getDistance={haversineDistance}
              CustomPopper={CustomPopper}
              placeholder="Search or type"
              width="300px"
            />

            {/* Conditionally render the box below the dropdown when a plant is selected */}
            {selectedPlant !== null && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#ffffff",
                  padding: "10px",
                  marginTop: "4px",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                  zIndex: 1000,
                }}
                onClick={() => pastTourNavigate(selectedPlant.id)}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#1890FF",
                    fontWeight: 500,
                    textDecoration: "underline",
                  }}
                >
                  Past Audit
                </Typography>
                <ArrowForwardIcon sx={{ color: "#1890FF" }} />
              </Box>
            )}
          </Box>

          {/* My Location Icon to recenter to device location */}
          {showToggleButtonGroup && (
            <>
              <IconButton
                sx={{
                  backgroundColor: "white",
                  zIndex: 1000,
                }}
                onClick={locateDevice}
              >
                <MyLocationIcon />
              </IconButton>
            </>
          )}

          {/* For map/satellite view */}
          {showToggleButtonGroup && (
            <>
              <ToggleButtonGroup
                value={mapType}
                exclusive
                onChange={handleMapTypeChange}
                style={{
                  zIndex: 1000,
                  height: "40px",
                  backgroundColor: "white",
                }}
              >
                <ToggleButton
                  value="map"
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    "&.Mui-selected": {
                      backgroundColor: "#09A2E3",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    },
                  }}
                >
                  Map
                </ToggleButton>

                <ToggleButton
                  value="satellite"
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    "&.Mui-selected": {
                      backgroundColor: "#09A2E3",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "gray",
                      },
                    },
                  }}
                >
                  Satellite
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
        </Box>

        {/* OpenStreetMap integration */}
        <MapContainer
          center={[27.332648182377387, 95.29226287437059]}
          zoom={13}
          style={{ height: "550px", width: "100%" }}
          zoomControl={false}
        >
          <TileLayer
            url={tileLayerURL}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* Custom component to update the map center */}
          <MapCenter center={[27.332648182377387, 95.29226287437059]} />

          {/* Marker for current device location */}
          {deviceLocation && (
            <Marker position={deviceLocation} icon={currentLocationIcon}>
              <Popup>Your Location</Popup>
            </Marker>
          )}

          {/* Marker for selected plant */}
          {selectedPlant && (
            <Marker position={selectedPlant?.coords} icon={defaultIcon}>
              <Popup>{selectedPlant?.locationCode}</Popup>
            </Marker>
          )}

          {/* Markers for nearby plants */}
          {nearbyLocations?.map((location, index) => (
            <Marker key={index} position={location.coords}>
              <Popup>{location.locationCode}</Popup>
            </Marker>
          ))}
          <Box
            sx={{
              position: "absolute",
              bottom: 16, // Adjust spacing from bottom as needed
              right: 16, // Adjust spacing from right as needed
              zIndex: 1000, // Ensures it overlays on top of other elements
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleNext()}
              disabled={selectedPlant === null}
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                backgroundColor: "#09A2E3",
                color: "#ffffff",
                paddingY: "8px",
                paddingX: "32px",
                borderRadius: "26px",
                alignItems: "end",
              }}
            >
              Perform Audit
            </Button>
          </Box>
        </MapContainer>
      </Box>
    </>
  );
}
