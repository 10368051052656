import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
} from '@mui/material';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const AuditWorkflowDialog = ({
  open,
  onClose,
  workflow,
}) => {
  const [selectedSteps, setSelectedSteps] = useState([]);

  const toggleStepSelection = (step) => {
    setSelectedSteps((prevSelected) =>
      prevSelected.includes(step)
        ? prevSelected.filter((s) => s !== step) // Remove if already selected
        : [...prevSelected, step] // Add if not selected
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#E6F7FF',
              borderRadius: '50%',
              width: '48px',
              height: '48px',
            }}
          >
            <BusinessCenterOutlinedIcon fontSize="32px" sx={{ color: '#09A2E3' }} />
          </Box>
          <Box>
            <Typography sx={{ color: '#15294B', fontSize: '20px', fontWeight: '600' }}>Audit Workflow</Typography>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List sx={{ overflow: 'auto', height: '500px', padding: '10px', borderRadius: '10px', border: '1px solid #F5F6F7' }}>
          {workflow.map((step, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                selected={selectedSteps.includes(step)}
                onClick={() => toggleStepSelection(step)}
                sx={{
                  borderRadius: 1,
                  marginBottom: 1,
                  border: selectedSteps.includes(step) ? 'none' : '1px solid #F5F6F7',
                  backgroundColor: selectedSteps.includes(step) ? '#EAF9FF' : 'transparent',
                  position: 'relative'
                }}
              >
                <ListItemIcon>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: '50%',
                      width: '32px',
                      height: '32px',
                      border: selectedSteps.includes(step) ? 'none' : '1px solid #CBCBCB'
                    }}
                  >
                    <PreviewOutlinedIcon fontSize="16px" sx={{ color: selectedSteps.includes(step) ? '#09A2E3' : '#909090' }} />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={step}
                  primaryTypographyProps={{
                    sx: {
                      color: selectedSteps.includes(step) ? '#09A2E3' : '#091E42',
                      fontSize: '14px',
                      fontWeight: '700',
                    },
                  }}
                />
                <Checkbox
                  checked={selectedSteps.includes(step)}
                  icon={
                    <RadioButtonUncheckedIcon style={{ color: "#D0D5DD", fontSize: 24 }} />
                  }
                  checkedIcon={
                    <CheckCircleIcon style={{ color: "#09A2E3", fontSize: 24 }} />
                  }
                  onChange={() => toggleStepSelection(step)}
                  sx={{ position: 'absolute', top: '4px', right: '6px' }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Box gap={1} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', px: 2 }}>
          <Button onClick={onClose} variant="outlined" color="primary" sx={{ width: '100%' }}>
            Cancel
          </Button>
          <Button
            onClick={() => onClose()}
            variant="contained"
            color="primary"
            disabled={selectedSteps.length === 0}
            sx={{ width: '100%', color: 'white' }}
          >
            Done
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AuditWorkflowDialog;
