import React from 'react';
import { Card, CardContent, Typography, Chip, Avatar, IconButton, Box, Divider } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { formatDateToIndianLocale2 } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';

export default function TicketTableCard ({ ticket, flow }) {
  const navigate = useNavigate();
  const id = (ticket.id)
  
  const handleViewTicket = () => {
    navigate(`/support/view-ticket/${id}`, { state: { flow } })
  }

  return (
    <Card sx={{ borderRadius: '8px', border: '1px solid #e0e0e0', padding: '0px !important',marginTop:2,boxShadow:'none' }}>
      <CardContent sx={{paddingBottom:'4px !important'}}>
        {/* Header section */}
        <Box display="flex" justifyContent="space-between" padding={0}>
            <Box sx={{display:'flex',justifyContent:'space-around'}}>
                <Typography sx={{ fontWeight: 600,mr:4,mt:0.5 ,fontSize:'16px'}}>
                {`Ticket #${ticket.customID ? ticket.customID : ticket.id}`}
            </Typography>

            {/* Chips section */}
            <Box>
                    {/* Here on the basics of ticket.priority, you have to make changes */}
                <Chip label={ticket?.priority?.charAt(0).toUpperCase() + ticket?.priority?.slice(1)} sx={{backgroundColor:(ticket.priority === "high" || ticket.priority === "critical")  ? '#FEF1F3' : ticket.priority === "medium" ? "#ffffdf" : "#90ee9080" ,color:(ticket.priority === "high" || ticket.priority === "critical")  ? '#FF0000' :  ticket.priority === "medium" ? "orange" : 'green' ,fontWeight: 'bold',fontSize:'10px',marginRight:1}} size="small" />
                <Chip label={ticket?.status?.charAt(0).toUpperCase() + ticket?.status?.slice(1)} sx={{backgroundColor:'#F1FAFE',color:'#09A2E3',fontWeight: 'bold',fontSize:'10px'}} size="small" />
                </Box>
            </Box>
          


          <Typography sx={{fontSize:'10px'}} color="textSecondary">
            Reported on {formatDateToIndianLocale2(ticket.createdAt)}
          </Typography>
        </Box>

        

        {/* Title */}
        <Typography sx={{ fontWeight: 'bold',fontSize:'14px',mt:1.5 }}>
          {ticket.issueSummary}
        </Typography>

        {/* Description */}
        <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1,mb:1 }}>
          {ticket.issueDescription}
        </Typography>

        <Divider sx={{marginTop:1,marginBottom:1}}/>

        {/* Footer with avatar and email */}
        <Box display='flex' spacing={1}>
          <Box display='flex' gap={2}>
            <Avatar alt="i" src={ticket.img} sx={{ width: 20, height: 20,fontSize:'12px' }}  />
            <Typography variant="caption" sx={{color:'#84818A'}}>
              {ticket.name} / {ticket.email}
            </Typography>
          </Box>
          
          <IconButton color="primary" size="small" sx={{ marginLeft: 'auto' }}>
            <VisibilityOutlinedIcon fontSize="small" onClick={() => handleViewTicket()}/>
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};
