import { Box, Typography, Stack, Menu, MenuItem, IconButton, Checkbox, ListItemText } from "@mui/material";
import "leaflet/dist/leaflet.css";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useCallback, useState, useEffect } from "react";
import { Place } from "@mui/icons-material";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const getParentLocations = (allLocations, selectedLocation) => {
  let parents = [];
  let currentLocation = selectedLocation;

  // Traverse back through the hierarchy to find parent locations
  while (currentLocation) {
    // Look for the parent of the current location
    const parent = findParent(allLocations, currentLocation);
    if (parent) {
      parents.unshift(parent); // Add parent to the beginning of the array
      currentLocation = parent; // Move up to the parent
    } else {
      break; // No parent found, exit the loop
    }
  }

  return parents;
};

// Function to find the parent of a given location
const findParent = (locations, location) => {
  for (let loc of locations) {
    // If the location has children, check if the current location is one of them
    if (loc.children && loc.children.some(child => child.id === location.id)) {
      return loc; // Return the parent if the current location is a child
    }
    // Recursively check the parent in nested children
    if (loc.children && loc.children.length > 0) {
      const parent = findParent(loc.children, location);
      if (parent) return parent;
    }
  }
  return null; // No parent found
};

const findLocation = (locations, locationID) => {
  for (let location of locations) {
    if (location.id === locationID) {
      return location;
    }
    if (location.children && location.children.length > 0) {
      const foundLocation = findLocation(location.children, locationID);
      if (foundLocation) return foundLocation;
    }
  }
  return null; // Not found
};


const LocationSelector = ({
  selectedLocation,
  locations,
  locationStatus,
  map,
  unselectedDisciplines,
  allLocations,
  selectedLocationID,
  disciplines, 
  onDisciplineChange
}) => {
  const [stats, setStats] = useState({
    unsatisfactory: 0,
    satisfactory: 0,
    na: 0,
    totalReports: 0,
  });
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDisciplines, setSelectedDisciplines] = useState(disciplines || []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedDisciplines(disciplines);
  }, [disciplines]);

  // Handle discipline selection changes
  const handleDisciplineToggle = (discipline) => {
    setSelectedDisciplines((prevSelected) => {
      const isSelected = prevSelected.includes(discipline);
      const updatedSelected = isSelected
        ? prevSelected.filter((item) => item !== discipline) // Remove if already selected
        : [...prevSelected, discipline]; // Add if not selected

      // Notify parent component with unselected disciplines
      const unselectedDisciplines = disciplines.filter(
        (item) => !updatedSelected.includes(item)
      );
      onDisciplineChange(unselectedDisciplines);

      return updatedSelected;
    });
  };

  const selectedLevel = parseInt(selectedLocation) || 0;

  const filteredLocations = locations?.filter((location) => {
    return location.orgLevel === selectedLevel;
  });

  const [parentLocations, setParentLocations] = useState([]);

  // Reset stats to zone data when selected location changes
  useEffect(() => {
    setStats({
      unsatisfactory: locationStatus.unsatisfactory || 0,
      satisfactory: locationStatus.satisfactory || 0,
      na: locationStatus.not_applicable || 0,
      totalReports: locationStatus.withReports || 0,
    });
    setSelectedLocationName(null);

    let locationID;

    if(locations.length > 0){
      locationID = locations[0].id;
    }else{
      locationID = selectedLocationID;
    }
  
    const selectedLoc = findLocation(allLocations, locationID);
    console.log("Selected Location is",selectedLoc)
    if (selectedLoc) {
      const parents = getParentLocations(allLocations, selectedLoc);
      console.log("Parent are:",parents)
      setParentLocations(parents);
    }
  }, [selectedLocation, locationStatus]);

  const handleLocationAction = useCallback(
    (location) => {
      if (map && location.latitude && location.longitude) {
        map.flyTo([location.latitude, location.longitude], 8, {
          animate: true,
          duration: 1,
        });
      }
      setStats({
        unsatisfactory: location.questionStatusCounts.unsatisfactory,
        satisfactory: location.questionStatusCounts.satisfactory,
        na: location.questionStatusCounts.not_applicable,
        totalReports: location.reportCount,
      });
      setSelectedLocationName(location.displayName);
    },
    [map]
  );

  return (
    <>

      {/* Discipline Dropdown */}
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "8px",
        backgroundColor: "#FFF",
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "4px",
          padding: "4px 8px",
        }}
      >
        <Typography sx={{ fontWeight: "bold", mr: 1 }}>Tour Discipline</Typography>
        <IconButton size="small">
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{p:'0px !important',}}
      >
        {disciplines?.map((discipline) => (
              <MenuItem key={discipline} value={discipline} sx={{p:'0px !important'}} onClick={() => handleDisciplineToggle(discipline)}>
                <Checkbox checked={selectedDisciplines.indexOf(discipline) > -1} />
                <ListItemText primary={discipline} />
              </MenuItem>
        ))}
      </Menu>
    </Box>

      {/* Parent Card */}

      {parentLocations.length > 0 && (
          <Box
          sx={{
            boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)",
            height: "auto",
            pt: "12px",
            pb: "12px",
            pl: "10px",
            pr: "10px",
            borderRadius: "8px",
            overflowY: "auto",
            backgroundColor:'#fff'
          }}
        >
          {parentLocations?.map((location, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: "4px",
              backgroundColor:
                selectedLocationName === location.displayName
                  ? "#e0e0e0"
                  : "transparent",
              "&:last-child": {
                marginBottom: 0,
              },
              display: "flex",
              alignItems: "center",
              pl: index * 1.4,
            }}
          >
            {index !== 0 && (
                <SubdirectoryArrowRightIcon
                sx={{
                  color: "#00b1f4",
                }}
              />
            )}
            
            <Typography
              onClick={() => handleLocationAction(location)}
              sx={{
                cursor: "pointer",
                color: "#333",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {location.displayName}
            </Typography>
          </Box>
        ))}
        </Box>
      )} 

      {/* Locations Card */}
      <Box
        sx={{
          boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)",
          height: "270px",
          pt: "12px",
          pb: "12px",
          pl: "10px",
          pr: "10px",
          borderRadius: "8px",
          overflowY: "auto",
          backgroundColor:'#fff'
        }}
      >
        {locations?.map((location, index) => (
          <Box
            key={index}
            sx={{
              padding: "8px",
              borderRadius: "4px",
              backgroundColor:
                selectedLocationName === location.displayName
                  ? "#CEECF9"
                  : "#f5f5f5",
              marginBottom: "8px",
              "&:last-child": {
                marginBottom: 0,
              },
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
              overflow: "hidden",
              textOverflow: "ellipsis",    
              whiteSpace: "nowrap" 
            }}
          >
            <Typography
              onClick={() => handleLocationAction(location)}
              sx={{ cursor: "pointer" }}
            >
              <Place sx={{fontSize:'16px'}}/> {location.displayName}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Stats Cards */}
      <Box
      sx={{
        borderRadius: "8px",
        boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)",
        p: 1.5,
        bgcolor: "white",
        display:'flex',
        justifyContent:'space-between',
        gap:1
      }}
    >
      <Box
        sx={{
          width: 120,
          height: 120,
          bgcolor: "#F1F3F4",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",
          mb: 'auto',
          mt:'auto'
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {stats.totalReports}
        </Typography>
      </Box>

      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap:1
          }}
        >
          <SentimentSatisfiedAltIcon sx={{ color: "#00B1F4", fontSize: 32 }} />
          <Typography sx={{ fontWeight: 600 }}>{stats.satisfactory}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SentimentNeutralIcon sx={{ color: "#00B1F4", fontSize: 32 }} />
          <Typography sx={{ fontWeight: 600 }}>{stats.na}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SentimentVeryDissatisfiedIcon sx={{ color: "#00B1F4", fontSize: 32 }} />
          <Typography sx={{ fontWeight: 600 }}>{stats.unsatisfactory}</Typography>
        </Box>
      </Stack>
    </Box>
    </>
  );
};

export default LocationSelector;
