import React from "react";
import { Bubble } from "react-chartjs-2";
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    LinearScale,
    PointElement,
    CategoryScale,
} from "chart.js";
import { Box, Paper, Typography, Grid, Divider, Button, IconButton } from '@mui/material';


ChartJS.register(Tooltip, Legend, LinearScale, PointElement, CategoryScale);

const BubbleChart = ({ incidentData = [] }) => {
    // Define colors for each incident type
    const incidentTypeColors = {
        UA: "rgba(255, 99, 132, 0.5)", // Red
        UC: "rgba(54, 162, 235, 0.5)", // Blue
        NM: "rgba(255, 206, 86, 0.5)", // Yellow
        Minor: "rgba(75, 192, 192, 0.5)", // Green
        MR: "rgba(75, 192, 192, 0.5)", // Green
        Serious: "rgba(153, 102, 255, 1)", // Purple
        DO: "rgba(255, 159, 64, 0.5)", // Orange
        Fatal: "rgba(255, 159, 64, 0.5)", // Orange
    };

    // Get unique locations to use as labels on the X-axis
    const uniqueLocations = Array.from(
        new Set(incidentData.map((incident) => incident.location))
    );

    // Generate non-overlapping random Y values
    const getRandomY = (existingYValues) => {
        let yValue;
        do {
            yValue = Math.floor(Math.random() * 100) + 1; // Generate a random Y value
        } while (existingYValues.includes(yValue)); // Ensure it's not already used
        existingYValues.push(yValue); // Add to the list of used Y values
        return yValue;
    };

    // Store already used Y values to prevent overlap
    let usedYValues = [];

    // Format data for the bubble chart, grouping by incident type
    const formattedData = {
        datasets: Object.keys(incidentTypeColors).map((type) => ({
            label: type,
            data: incidentData
                .filter((incident) => incident.type === type)
                .map((incident) => ({
                    x: incident.location, // Use index for X value
                    y: getRandomY(usedYValues), // Generate unique random Y value
                    r: Math.sqrt(incident.count) * 5, // Bubble size based on report count
                    count: incident.count,
                })),
            backgroundColor: incidentTypeColors[type],
        })),
    };

    console.log(formattedData);

    return (
        <div style={{ maxHeight: "550px", width: 'auto' }}>
            <Typography variant='body1' sx={{ textAlign: 'left', fontWeight: 400, marginBottom: 1 }}>
                Incident Statistics
            </Typography>

            <Box
                sx={{
                    width: '100%', // Set the desired width
                    height: '500px', // Set the desired height
                    margin: '0 auto', // Center the chart horizontally
                    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
                    p:"16px",
                    background:"#F4F4F4",
                }}
            >
                <Bubble
                    data={formattedData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true, // Allow custom height and width
                        plugins: {
                            legend: {
                                display: true,
                                position: "top",
                               
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const label = context.dataset.label || "";
                                        const label2 = context.raw.x || "";
                                        const count = context.raw.count
                                            ? context.raw.count
                                            : 0;
                                        return `${label2}: ${" "}${label} ${count}`;
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                                type: "category",
                                labels: uniqueLocations, // Set unique locations as labels on X-axis
                                title: {
                                    display: false,
                                    text: "Locations",
                                },
                                ticks: {
                                    display: false, // Hide the location names
                                },
                            },
                            y: {
                                display: false,
                                title: {
                                    display: false,
                                    text: "Incident Types",
                                },
                                ticks: {
                                    display: false, // Hide Y-axis values for scatter effect
                                },
                            },
                        },
                    }}
                />
            </Box>

        </div>
    );
};

export default BubbleChart;