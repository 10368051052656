import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CustomStepper from '../Components/CustomStepper';
import CustomButtonGroup from '../Components/CustomButtonGroup';
import CustomInput from '../Components/CustomInputField';
import CustomSelect from '../Components/CustomSelectField';
import BroadCastCard from '../Components/BroadCastCard';
import { Paper, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import BasicDateTimePicker from '../Components/CustomDateTime';
import BasicDatePicker from '../Components/CustomeDate';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField, addCheckbox, removeCheckbox, updateIncidentDateTime, updateSwitchState } from '../redux/incidentReport/actions'; // Import your action here
import dayjs from 'dayjs';
import RightDrawer from '../Components/RightDrawerIncidentReport';
import Switch from '@mui/material/Switch';
import CustomRightOverlay from '../Components/CustomRightOverlay';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import IncidentReview from './IncidentReview';
import { getSubActivity, getStaffAndEntity, getEntity, getSubArea,getAllLocation } from '../Apis/apiCall';
import { Alert } from '@mui/material';




import FloatingButton from '../Components/FloatButton';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: '16px',
    color: theme.palette.text.secondary,
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.20)',
}));

const customTheme = createTheme({
    components: {
        MuiGrid: {
            styleOverrides: {

            },
        },
    },
});

export default function HomePage() {
    const dispatch = useDispatch();
    const loginDataString = localStorage.getItem('loginData');
    const data = JSON.parse(loginDataString);
    const formFields = data?.config.modulesDetail[0].sectionsDetail[0].formFields;
    const drawerContent = data?.config?.modulesDetail[0]?.sectionsDetail;
    const locationWorkFlowLevel = data?.config?.workflowDetails[0]?.step;
    const formData = useSelector(state => state.incidentReportFromReducer);
    const selectedCheckboxes = useSelector(state => state.incidentReportFromReducer.GeneralInfo || []);
    const isFormComplete = useSelector((state) => state.stepReducer.isFormComplete); // Assuming stepReducer has isFormComplete
    const [isDrawerOpen, setIsDrawerOpen] = useState(null);
    const [openOverlay, setOpenOverlay] = React.useState(false);
    const switchStates = useSelector((state) => state.incidentReportFromReducer.switchStates);
    const [staffNameList, setStaffNameList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [subAreaList, setSubAreaList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [subActivityList, setSubActivityList] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const[message ,setMessage]= useState("");
    
    const {
        Personnel,
        PersonnelDetail,
        VehicleDetail,
        EquipmentDetail,
        CAPADetail,
        LTIDetail,
        EvidenceDetail,
        Regulatory } = formData

    const hasAnyDetails = (
        PersonnelDetail.length > 0 ||
        VehicleDetail.length > 0 ||
        EquipmentDetail.length > 0
    );


    const servicesSection = {
        sectionName: 'Services',
        sections: [],
        cardData: []
    };

    drawerContent.forEach((section) => {
        if (['Personnel Details', 'Vehicle Details', 'Equipment Details'].includes(section.sectionName)) {
            servicesSection.sections.push({
                sectionID: section.sectionID,
                sectionName: section.sectionName,
                formFields: section.formFields
            });
        }
    });

    useEffect(()=>{
        fetchAllLocationDropValue();
    },[]);

    useEffect(() => {
        if (showAlert) {
          // Automatically close the alert after 5 seconds
          const timer = setTimeout(() => {
            setShowAlert(false);
            setMessage(null);
          }, 2000);
    
          // Clean up the timer when the component unmounts or showAlert changes
          return () => clearTimeout(timer);
        }
      }, [showAlert]);


    useEffect(() => {
        console.log(formData["Staff Type"]);
        if (formData["Staff Type"]) {
            fetchStaffEntitylist(formData["Staff Type"]);
        }
    }, [formData["Staff Type"]]);

    useEffect(() => {
        console.log(formData["Staff Type"]);
        if (formData["Staff Type"] && formData["Reported By - Staff Name"]) {
            fetchEntity(formData["Staff Type"], formData["Reported By - Staff Name"]);
        }
    }, [formData["Reported By - Staff Name"]]);

    useEffect(() => {
        console.log(formData["Activity"]);
        if (formData["Activity"]) {
            fetchSubActivity(formData["Activity"]);
        }
    }, [formData["Activity"]]);

    useEffect(() => {
        console.log(formData["Area"]);
        if (formData["Area"]) {
            fetchSubArea(formData["Area"]);
        }
    }, [formData["Area"]]);


    const fetchAllLocationDropValue = async () => {
        try {
          const result = await getAllLocation();
          const arr = result.data.data;
          const locations = arr?.map(ele => ({
            value: ele.id,
            label: ele.displayName
        }));
    
          setLocationList(locations);
        } catch (error) {
          console.log(error);
        }
      };

    const fetchStaffEntitylist = async (staffType) => {
        try {
            const result = await getStaffAndEntity(staffType);
            console.log(result.data.data);

            // Map the staff array to the desired format (key as `id` and label as `firstName` + `lastName`)
            const formattedStaffList = result.data.data.map(staffMember => ({
                value: staffMember.id,
                label: `${staffMember.firstName} ${staffMember.lastName}`
            }));

            setStaffNameList(formattedStaffList); // Set the formatted staff list

        } catch (error) {
            console.log(error);
        }
    }

    const fetchEntity = async (type, id) => {
        try {
            const result = await getEntity(type, id);
            console.log(result.data.data);
            const formattedStaffList = result.data.data.map(staffMember => ({
                value: staffMember.id,
                label: staffMember.name
            }));

            setEntityList(formattedStaffList); // Set the formatted staff list

        } catch (error) {
            console.log(error);
        }
    }

    const fetchSubArea = async (data) => {
        try {
            const result = await getSubArea(data);
            console.log(result.data.data);
            setSubAreaList(result.data.data); // Set the formatted staff list

        } catch (error) {
            console.log(error);
        }
    }

    const fetchSubActivity = async (data) => {
        try {
            const result = await getSubActivity(data);
            console.log(result.data.data);
            setSubActivityList(result.data.data); // Set the formatted staff list

        } catch (error) {
            console.log(error);
        }
    }



    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        dispatch(updateFormField({ fieldName, value }));
    };

    const handleSelectChange = (label, value) => {
        dispatch(updateFormField({ fieldName: label, value }));
    };

    const handleInputChange1 = (fieldName, value) => {
        dispatch(updateFormField({ fieldName, value }));
    };

    const handleCheckboxChange = (label) => (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            dispatch(addCheckbox(label));
        } else {
            dispatch(removeCheckbox(label));
        }
    };

    const handleDateChange = (newValue, label) => {
        let formattedDateTime;
        if(label ==="Date of Accident"){
            formattedDateTime = dayjs(newValue).format('DD-MM-YYYY');
        }else{
            formattedDateTime = dayjs(newValue).format('DD-MM-YYYY HH:mm:ss');  
        }
        const IncidentTime = formData?.["Incident Time"];
        if (label === 'Report Time' && IncidentTime) {
            const formattedIncidentTime = dayjs(IncidentTime, 'DD-MM-YYYY HH:mm:ss');
            const selectedDate = dayjs(newValue);

            // Check if the selected "Date of Duty Return" is after the "LTI Date"
            if (selectedDate.isBefore(formattedIncidentTime)) {
                setShowAlert(true)
                setMessage("The Report Date must be after the Incident Date.")
                 return;
            }
        }

        dispatch(updateIncidentDateTime(label, formattedDateTime));
        console.log(`Selected date and time for ${label}:`, formattedDateTime);
    };

    const handleDrawerOpen = (titleId) => {
        setIsDrawerOpen((prevTitleId) => {
            return titleId;
        });

    }
    const handleDrawerClose = () => {
        console.log("Drawer is closing:", isDrawerOpen);

        // Check if any of the PersonnelDetail, VehicleDetail, or EquipmentDetail are non-empty
        const hasServiceDetails = (
            PersonnelDetail.length > 0 ||
            VehicleDetail.length > 0 ||
            EquipmentDetail.length > 0
        );

        // Create a map of section details, and set 'Services' based on the condition
        const sectionDetailsMap = {
            Services: hasServiceDetails ? "Yes" : "No", // If any service-related detail exists, set to "Yes"
            CAPA: CAPADetail,
            LTI: LTIDetail,
            Evidence: EvidenceDetail,
            Regulatory: Regulatory && Object.keys(Regulatory).length > 0 ? Regulatory : []
        };

        // Get the details for the currently open section
        const sectionDetails = sectionDetailsMap[isDrawerOpen];

        // Check if the specific section's details exist and are not empty
        const hasDetailsForSection = Array.isArray(sectionDetails) ? sectionDetails.length > 0 : Boolean(sectionDetails);

        // Determine dispatch value based on section type
        let dispatchValue;
        if (isDrawerOpen === 'Services') {
            dispatchValue = sectionDetailsMap.Services; // "Yes" or "No" for Services
        } else {
            // For other sections, dispatch "Yes" if details exist; otherwise "No"
            dispatchValue = hasDetailsForSection ? "Yes" : "No";
        }

        // Dispatch the state update
        dispatch(updateSwitchState(isDrawerOpen, dispatchValue));

        if (isDrawerOpen === 'Services' ) {
            setIsDrawerOpen(null);
        }
        if (isDrawerOpen === 'CAPA'|| isDrawerOpen === 'Regulatory' || isDrawerOpen === "Evidence" || isDrawerOpen === "LTI") {
            setIsDrawerOpen(null);
        }
        
    };





    const handleSelect = (name, sectionName) => {
        if (name === 'Yes') {
            dispatch(updateSwitchState(sectionName, name));
            handleDrawerOpen(sectionName); // Open drawer for 'Yes'
        }
    };

    const activeStep = useSelector(state => state.stepReducer.activeStep);




    return (
        <>
            {activeStep === 0 ?
                (<ThemeProvider theme={customTheme}>
                    <Box component="main" sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <CustomStepper steps={["Incident Info", "Review"]} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Box
                                                display="flex"
                                                flexDirection={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }} // Column on small devices, row on larger devices
                                                justifyContent="space-between"  // Push Incident Type to left and Severity to right
                                                alignItems="flex-start"         // Align items to the top of the cross axis
                                                maxWidth="100%"                    // Full width for the main box
                                            >
                                                {/* Incident Type Box - Aligned to the left */}
                                                {formFields?.map((field, index) => (
                                                    field?.formFieldName === "Incident Type" && (
                                                        <Box
                                                            key={index}
                                                            display="flex"
                                                            flexDirection="column"
                                                            sx={{
                                                                flex: { xs: '1 1 auto', sm: '0 1 28%', lg: '0 1 28%' },  // 48% width on large screens to leave space for Severity
                                                                mr: { xs: 0, lg: '12px' },                // Margin on large screens
                                                                // Bottom margin for spacing
                                                            }}
                                                        >
                                                            <Box
                                                                key={index}
                                                                display="flex"
                                                                alignItems="center" // Center items vertically

                                                            >
                                                                <Typography variant="body2" sx={{ mb: '6px', flex: 1 }}> {/* Label takes up available space */}
                                                                    {field.formFieldName}
                                                                </Typography>

                                                                {/* Conditional rendering for selected value */}
                                                                {formData?.[field.formFieldName] && (
                                                                    <Typography
                                                                        variant="caption"
                                                                        sx={{ fontSize: "9px", color: '#0AA2E3', textDecoration: 'underline' }} // Added margin left for spacing
                                                                    >
                                                                        {formData[field.formFieldName] === "UA" ? "Unsafe Act" :

                                                                            formData[field.formFieldName] === "UC" ? "Unsafe Condition" :

                                                                                formData[field.formFieldName] === "NM" ? "Near Miss" :
                                                                                formData[field.formFieldName] === "Minor" ? "Minor" :
                                                                                formData[field.formFieldName] === "MR" ? "Minor Reportable" :

                                                                                    formData[field.formFieldName] === "DO" ? "Dangerous Occurrence" :

                                                                                        formData[field.formFieldName] === "Serious" ? "Serious Bodily Injury"

                                                                                            : "Fatal"
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </Box>

                                                            <CustomButtonGroup
                                                                buttonNames={field.options}               // Map through options to get the shortName
                                                                selected={formData?.[field.formFieldName] || ''}  // Select based on formData
                                                                onSelect={(name) => handleInputChange1(field.formFieldName, name)}  // Update field
                                                            />

                                                        </Box>
                                                    )
                                                ))}

                                                {/* Severity Box - Aligned to the right */}
                                                {formFields?.map((field, index) => (
                                                    field?.formFieldName === "Severity" && (
                                                        <Box
                                                            key={index}
                                                            display="flex"
                                                            flexDirection="column"
                                                            sx={{
                                                                flex: { xs: '1 1 auto', lg: '0 1 48%' },  // 48% width on large screens to leave space for Incident Type
                                                                ml: { xs: 0, },                // Margin on large screens
                                                                mb: { xs: "12px", lg: '16px' },                       // Bottom margin for spacing
                                                            }}
                                                        >
                                                            <Typography variant="body2" sx={{ mb: '6px' }}>
                                                                {field.formFieldName}
                                                            </Typography>
                                                            <CustomButtonGroup
                                                                name={field.formFieldName}
                                                                buttonNames={field.options}               // Map through options to get the shortName
                                                                selected={formData?.[field.formFieldName] || ''}  // Select based on formData
                                                                onSelect={(name) => handleInputChange1(field.formFieldName, name)}  // Update field
                                                            />
                                                        </Box>
                                                    )
                                                ))}
                                            </Box>

                                            <Grid container spacing={2}>
                                                {formFields?.map((field, index) => (
                                                    field?.formFieldName && (field?.formFieldName === "Incident Time" || field?.formFieldName === "Report Time") && (
                                                        <Grid item xs={12} sm={6} key={index}>
                                                            <BasicDateTimePicker
                                                                label={field?.formFieldName}
                                                                onChange={(e) => handleDateChange(e, field?.formFieldName)}

                                                            />
                                                        </Grid>)))}

                                                <Grid item xs={6} sm={12}>
                                                    {formFields?.map((field, index) => (
                                                        field?.formFieldName && field?.options && (field?.formFieldName === "Staff Type") && (
                                                            <Box
                                                                key={index}
                                                                flex={1}
                                                                display="flex"
                                                                flexDirection={{ xs: 'column', sm: 'row', md: 'column', lg: 'row' }}
                                                                alignItems="flex-start"
                                                            >
                                                                <Typography variant="body2" sx={{ mr: "12px", mt: "6px" }}>
                                                                    {field.formFieldName}
                                                                </Typography>
                                                                <CustomButtonGroup
                                                                    buttonNames={field.options} // Map through options to get the shortName
                                                                    selected={formData?.[field.formFieldName] || ''} // Select based on formData
                                                                    onSelect={(name) => handleInputChange1(field.formFieldName, name)} // Update field
                                                                />

                                                                {/* Conditionally show Employee Type button group if "Reported By" is selected by an employee */}
                                                                {formData?.["Staff Type"] === "Employee" && (
                                                                    formFields?.map((innerField, innerIndex) => (
                                                                        innerField?.formFieldName && innerField?.options && (innerField?.formFieldName === "Employee Type") && (
                                                                            <Box
                                                                                key={innerIndex}
                                                                                flex={1}
                                                                                display="flex"
                                                                                flexDirection="column"
                                                                                alignItems="flex-start"
                                                                                marginLeft={1}
                                                                            >

                                                                                <CustomButtonGroup
                                                                                    buttonNames={innerField.options} // Map through options to get the shortName
                                                                                    selected={formData?.[innerField.formFieldName] || ''} // Select based on formData
                                                                                    onSelect={(name) => handleInputChange1(innerField.formFieldName, name)} // Update field
                                                                                />
                                                                            </Box>
                                                                        )
                                                                    ))
                                                                )}
                                                            </Box>
                                                        )
                                                    ))}
                                                </Grid>

                                                {formData?.["Staff Type"] === "Visitor" ?
                                                    <>
                                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }}> {/* Added margin top for spacing */}
                                                            {formFields?.map((field, index) => (
                                                                field?.formFieldName && (field?.formFieldName === "Reported By - Staff Name") && (
                                                                    <CustomInput
                                                                        placeholder="Reported By - Staff Name"
                                                                        label="Reported By - Staff Name"
                                                                        name="Reported By - Staff Name"
                                                                        type={field?.dataType === "String" ? "text" : "number"}
                                                                        value={formData?.[field?.formFieldName] || ""}
                                                                        onChange={(e) => handleSelectChange(field?.formFieldName, e.target.value)}
                                                                        sx={{ mt: '12px' }}
                                                                    />
                                                                )
                                                            ))}
                                                        </Grid>

                                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }}> {/* Added margin top for spacing */}
                                                            {formFields?.map((field, index) => (
                                                                field?.formFieldName === "Reported By - Entity Name" && (
                                                                    <CustomInput
                                                                        placeholder="Reported by - Entity Name"
                                                                        label="Reported by - Entity Name"
                                                                        name="Reported By - Entity Name"
                                                                        value={formData?.[field?.formFieldName] || ""}
                                                                        type={field?.dataType === "String" ? "text" : "number"}
                                                                        onChange={(e) => handleSelectChange(field?.formFieldName, e.target.value)}
                                                                        sx={{ mt: '12px' }}
                                                                    />
                                                                )
                                                            ))}
                                                        </Grid>
                                                    </> :
                                                    <>
                                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }}> {/* Added margin top for spacing */}
                                                            {formFields?.map((field, index) => (
                                                                field?.formFieldName && (field?.formFieldName === "Reported By - Staff Name") && (
                                                                    <CustomSelect
                                                                        label={field?.formFieldName}
                                                                        value={formData?.[field?.formFieldName] || ""}
                                                                        options={staffNameList ? staffNameList : ''}
                                                                        onChange={(e) => handleInputChange(e, field?.formFieldName)}
                                                                        key={index}
                                                                    />
                                                                )
                                                            ))}
                                                        </Grid>

                                                        <Grid item xs={12} sm={6} sx={{ mt: 1 }}> {/* Added margin top for spacing */}
                                                            {formFields?.map((field, index) => (
                                                                field?.formFieldName === "Reported By - Entity Name" && (
                                                                    <CustomSelect
                                                                        label={field?.formFieldName}
                                                                        value={formData?.[field?.formFieldName] || ""}
                                                                        options={entityList ? entityList : ''}
                                                                        onChange={(e) => handleInputChange(e, field?.formFieldName)}
                                                                        key={index}
                                                                    />
                                                                )
                                                            ))}
                                                        </Grid>
                                                    </>
                                                }

                                            </Grid>

                                        </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Item>
                                                    <FormGroup>
                                                        <Box
                                                            sx={{
                                                                display: 'grid',
                                                                gridTemplateColumns: 'repeat(3, 1fr)', // 3 equal columns
                                                                alignItems: 'left',
                                                                '@media (max-width: 1300px)': {
                                                                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns on small devices (max-width: 600px)
                                                                },
                                                            }}
                                                        >
                                                            {formFields.find(field => field.formFieldName === "General Info")?.options.map((label, index) => (
                                                                <>
                                                                    <div key={index} style={{ display: 'flex', alignItems: 'left' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    sx={{
                                                                                        p: '2px',
                                                                                        size: '12px',
                                                                                        '&.Mui-checked': {
                                                                                            color: '#0AA2E3',  // Color when the checkbox is checked
                                                                                        },
                                                                                    }}
                                                                                    checked={selectedCheckboxes.includes(label)}
                                                                                    onChange={handleCheckboxChange(label)}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={label}
                                                                            sx={{
                                                                                '& .MuiTypography-root': {
                                                                                    fontSize: '12px',
                                                                                    fontWeight: 400,
                                                                                },
                                                                                padding: '9.5px',
                                                                            }}
                                                                        />
                                                                        {/* Conditionally show the input box when 'Other' is selected */}

                                                                    </div>
                                                                    {label === "Others" && selectedCheckboxes.includes("Others") && (
                                                                        <CustomInput
                                                                            label="Others"
                                                                            value={formData['Others'] || ''} // Store the custom input value for 'Other'
                                                                            onChange={(e) => handleSelectChange('Others', e.target.value)} // Handle custom input changes
                                                                            placeholder="Please specify"
                                                                            type="text"
                                                                            sx={{
                                                                                marginLeft: '50px', // Add space between checkbox and input
                                                                                // Adjust width of the input as needed
                                                                            }}
                                                                        />
                                                                    )}
                                                                </>


                                                            ))}

                                                        </Box>

                                                    </FormGroup>
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            {/* ---------------------------second section rightside ------------------------------ */}

                            <Grid item xs={12} md={7}>
                                <Grid container spacing={2} item xs={12}>
                                    <Grid item xs={12} md={4} >
                                        <Grid item xs={12} > {/* Add spacing below General Info */}
                                            <Item>
                                                {formFields?.map((field, index) => (
                                                    (field?.formFieldName === "Incident Short Summary" ||
                                                        field?.formFieldName === "Incident Summary" ||
                                                        field?.formFieldName === "Immediate Measures") && (
                                                        <div style={{ marginBottom: field?.formFieldName === "Immediate Measures" ? '0' : '13px' }} key={index}>
                                                            <CustomInput
                                                                label={field?.formFieldName}
                                                                multiline
                                                                rows={3.5}
                                                                value={formData[field?.formFieldName]}
                                                                type={field?.dataType === "String" ? "text" : "number"}
                                                                onChange={(e) => handleSelectChange(field.formFieldName, e.target.value)}
                                                                placeholder={`Summary`}
                                                            />
                                                        </div>
                                                    ))
                                                )}
                                            </Item>
                                        </Grid>


                                        <Grid item xs={12} sx={{ mt: 2 }}>
                                            <Item>
                                                {/* Use container prop and spacing to create space between grid items */}
                                                <Grid container spacing={2}>

                                                    {/* Immediate Last Accident Field */}
                                                    <Grid item xs={12}>
                                                        {formFields?.map((field, index) => (
                                                            field?.formFieldName && field?.options && field?.formFieldName === "Immediate Last Accident" && (
                                                                <Box
                                                                    key={index}
                                                                    flex={1}
                                                                    display="flex"
                                                                    flexDirection="column" // Stack elements vertically
                                                                    alignItems="flex-start"
                                                                >
                                                                    <Typography variant="body2" sx={{ mb: "6px" }}> {/* Margin bottom for spacing */}
                                                                        {field.formFieldName}
                                                                    </Typography>
                                                                    <CustomButtonGroup
                                                                        buttonNames={field.options} // Map through options to get the shortName
                                                                        selected={formData?.[field.formFieldName] || ''} // Select based on formData
                                                                        onSelect={(name) => handleInputChange1(field.formFieldName, name)} // Update field
                                                                    />
                                                                </Box>
                                                            )
                                                        ))}
                                                    </Grid>

                                                    {/* Date Field */}
                                                    <Grid item xs={12}>
                                                        {formFields?.map((field, index) => (
                                                            field?.formFieldName && field?.formFieldName === "Date of Accident" && (
                                                                <BasicDatePicker
                                                                    key={index}
                                                                    label={field?.formFieldName}
                                                                    value={formData?.[field.formFieldName] || ""}
                                                                    onChange={(e) => handleDateChange(e, field?.formFieldName)}
                                                                />
                                                            )
                                                        ))}
                                                    </Grid>

                                                    {/* Cause Field */}
                                                    <Grid item xs={12} sx={{ mt: "4px" }}>
                                                        {formFields?.map((field, index) => (
                                                            field?.formFieldName && field?.formFieldName === "Cause of Accident" && (
                                                                <CustomSelect
                                                                    key={index}
                                                                    label={field?.formFieldName}
                                                                    value={formData?.[field.formFieldName] || ""}
                                                                    options={field?.options || ''}
                                                                    onChange={(e) => handleInputChange(e, field?.formFieldName)}
                                                                />
                                                            )
                                                        ))}
                                                    </Grid>



                                                </Grid>
                                            </Item>
                                        </Grid>

                                    </Grid>


                                    {/* Second Column - Location and Other Sections */}
                                    <Grid item xs={12} md={4}>
                                        <Item>
                                            {formFields?.map((field, index) => {
                                                const isLastField = index === formFields.length - 1;

                                                // if (field?.formFieldName === "Incident Location") {
                                                //     return (
                                                //         <Grid
                                                //             item
                                                //             xs={12}
                                                //             key={`location-${index}`}
                                                //             sx={{ mb: isLastField ? 0 : 1.5 }} // Apply bottom margin except on the last field
                                                //         >
                                                //             <CustomInput
                                                //                 label="Incident Location"
                                                //                 name="Incident Location"
                                                //                 placeholder="Location"
                                                //                 value={formData?.[field.formFieldName] || ""}
                                                //                 type={field?.dataType === "String" && "text"}
                                                //                 onChange={(e) => handleSelectChange(field?.formFieldName, e.target.value)}
                                                //             />
                                                //         </Grid>
                                                //     );
                                                // }

                                                if (
                                                    field?.formFieldName ==="Incident Location"||
                                                    field?.formFieldName === "Area" ||
                                                    field?.formFieldName === "Sub Area" ||
                                                    field?.formFieldName === "Department" ||
                                                    field?.formFieldName === "Activity" ||
                                                    field?.formFieldName === "Sub Activity"
                                                ) {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            key={`select-${index}`}
                                                            sx={{ mb: field?.formFieldName === "Sub Activity" ? 0 : 1.5 }} // Apply bottom margin except on the last field
                                                        >
                                                            <CustomSelect
                                                                label={field?.formFieldName}
                                                                value={formData?.[field.formFieldName] || ""}
                                                                options={field?.formFieldName === "Sub Area" ? subAreaList : field?.formFieldName === "Sub Activity" ? subActivityList : field?.formFieldName === "Incident Location"? locationList :field?.options || ''}
                                                                onChange={(e) => handleInputChange(e, field?.formFieldName)}
                                                            />
                                                        </Grid>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </Item>

                                        <Grid item xs={12} sx={{ mt: 2 }}> {/* Add spacing below Location Section */}
                                            <Item>

                                                {formFields?.map((field, index) => (
                                                    <Box flex={1} key={index} display="flex" alignItems="center">
                                                        {field?.formFieldName && field?.options && (
                                                            (field?.formFieldName === "Shift" ||
                                                                field?.formFieldName === "Losses Type" ||
                                                                field?.formFieldName === "Operation Status") && (
                                                                <Box
                                                                    key={index}
                                                                    flex={1}
                                                                    display="flex"
                                                                    flexDirection="column" // Stack elements vertically
                                                                    alignItems="flex-start"
                                                                >
                                                                    <Typography variant="body2" sx={{ mb: "6px", mt: 0 }}> {/* Margin bottom for spacing */}
                                                                        {field.formFieldName}
                                                                    </Typography>
                                                                    <CustomButtonGroup
                                                                        buttonNames={field.options} // Map through options to get the shortName
                                                                        selected={formData?.[field.formFieldName] || ''} // Select based on formData
                                                                        onSelect={(name) => handleInputChange1(field.formFieldName, name)} // Update field
                                                                        name={field.formFieldName}
                                                                    />
                                                                </Box>
                                                            )
                                                        )}
                                                    </Box>
                                                ))}


                                            </Item>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <Item sx={{ minHeight: '355px' }}>
                                            <BroadCastCard />
                                           
                                        </Item>



                                        <Grid item xs={12} sx={{ mt: 2 }}>
                                            <Item>
                                                {/* Resources Section */}
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <Typography variant='body2'>Resources</Typography>
                                                    <Box display="flex">
                                                        {switchStates.Services === 'Yes' && hasAnyDetails ? (
                                                            <WysiwygIcon
                                                                onClick={() => handleDrawerOpen('Services')}
                                                                sx={{ cursor: 'pointer', padding: '1px', fontSize: 24, color: "#0AA2E3" }}
                                                            />
                                                        ) : (
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={switchStates.Services === 'Yes'}
                                                                        onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'Services')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                labelPlacement="start"
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>

                                                {/* CAPA Section */}
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <Typography variant='body2'>CAPA</Typography>
                                                    <Box display="flex" gap={1}>
                                                        {switchStates.CAPA === 'Yes' && CAPADetail.length > 0 ? (
                                                            <WysiwygIcon
                                                                onClick={() => handleDrawerOpen('CAPA')}
                                                                sx={{ cursor: 'pointer', padding: '1px', fontSize: 24, color: "#0AA2E3" }}
                                                            />
                                                        ) : (
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={switchStates.CAPA === 'Yes'}
                                                                        onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'CAPA')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                labelPlacement="start"
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>

                                                {/* Evidence Section */}
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <Typography variant='body2'>Evidence</Typography>
                                                    <Box display="flex" gap={1}>
                                                        {switchStates.Evidence === 'Yes' && EvidenceDetail.length > 0 ? (
                                                            <WysiwygIcon
                                                                onClick={() => handleDrawerOpen('Evidence')}
                                                                sx={{ cursor: 'pointer', padding: '1px', fontSize: 24, color: "#0AA2E3" }}
                                                            />
                                                        ) : (
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={switchStates.Evidence === 'Yes'}
                                                                        onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'Evidence')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                labelPlacement="start"
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>

                                                {/* Regulatory Section */}
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <Typography variant='body2'>Regulatory</Typography>
                                                    <Box display="flex" gap={1}>
                                                        {switchStates.Regulatory === 'Yes' && Regulatory && Object.keys(Regulatory).length > 0 ? (
                                                            <WysiwygIcon
                                                                onClick={() => handleDrawerOpen('Regulatory')}
                                                                sx={{ cursor: 'pointer', padding: '1px', fontSize: 24, color: "#0AA2E3" }}
                                                            />
                                                        )
                                                            : (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={switchStates.Regulatory === 'Yes'}
                                                                            onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'Regulatory')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    labelPlacement="start"
                                                                />
                                                            )}
                                                    </Box>
                                                </Box>

                                                {PersonnelDetail.some(person => person.Outcome === 'NA' || person.Outcome === "Major Injury" || person.Outcome === "Minor Injury") &&
                                                    (<Box display="flex" alignItems="center" justifyContent="space-between">
                                                        <Typography variant='body2'>LTI</Typography>
                                                        <Box display="flex" gap={1}>
                                                            {switchStates.LTI === 'Yes' && LTIDetail.length > 0 ? (
                                                                <WysiwygIcon
                                                                    onClick={() => handleDrawerOpen('LTI')}
                                                                    sx={{ cursor: 'pointer', padding: '1px', fontSize: 24, color: "#0AA2E3" }}
                                                                />
                                                            ) : (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            checked={switchStates.LTI === 'Yes'}
                                                                            onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'LTI')}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    labelPlacement="start"
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>)}
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Box
                                sx={{
                                    position: 'fixed',
                                    bottom: 20, // Distance from the bottom
                                    right: 80, // Distance from the right
                                    zIndex: 1000, // Ensure it's above other components
                                }}
                            >
                                <FloatingButton />

                            </Box>

                        </Grid >



                        <RightDrawer
                            open={isDrawerOpen !== null} // Open drawer if any switch is Yes
                            onClose={handleDrawerClose}
                            title={
                                isDrawerOpen === 'Services'
                                    ? 'Services'
                                    : drawerContent.find(item => item.sectionName === isDrawerOpen)?.sectionName || 'Default Title'
                            }
                            fields={
                                isDrawerOpen === 'Services'
                                    ? servicesSection.sections
                                    : drawerContent.find(item => item.sectionName === isDrawerOpen)?.formFields || []
                            }
                            cardData={
                                isDrawerOpen === 'Regulatory' ? Regulatory :
                                    isDrawerOpen === 'CAPA' ? CAPADetail :
                                        isDrawerOpen === 'Evidence' ? EvidenceDetail :
                                            isDrawerOpen === 'LTI' ? LTIDetail :
                                                []
                            }
                        />

                        <CustomRightOverlay open={openOverlay} setOpen={setOpenOverlay} />
                        {showAlert && (
                            <Alert
                                severity="warning"
                                onClose={() => setShowAlert(false)}
                                sx={{
                                    position: 'fixed',
                                    top: '40px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '100%',
                                    maxWidth: '600px',
                                    zIndex: 9999, // Ensure the alert is on top of other elements
                                    textAlign: 'center',
                                }}
                            >
                                {message}
                            </Alert>
                        )}

                    </Box >

                </ThemeProvider >)
                :
                <IncidentReview />
            }
        </>
    );
}
