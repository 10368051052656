import React from "react";
import { Box, Grid } from "@mui/material";
import SelectAuditSiteLocation from "../../Components/Audit/SelectAuditSiteLocation";
import { useNavigate } from "react-router-dom";



export default function AuditPerform() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/module/audit-management/audit-perform/create");
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
      <Grid container spacing={2} mb={3} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <SelectAuditSiteLocation handleNext={handleNext} />
        </Grid>
      </Grid>
    </Box>
  );
}
