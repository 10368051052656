import React, { useEffect, useState, useRef } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { getOverallStatusTimeline } from "../../Apis/apiCall";
import AssignmentIcon from "@mui/icons-material/Assignment";
import RecommendIcon from "@mui/icons-material/Recommend";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { ClockIcon } from "@mui/x-date-pickers";
import { formatDateToIndianLocale2 } from "../../utils/helper";

export default function CustomTimeline({ reportID }) {
  const [timelineData, setTimelineData] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(true);
  const timelineRef = useRef(null);

  useEffect(() => {
    fetchTimelineData();
  }, []);

  useEffect(() => {
    if (timelineData.length > 0) {
      const checkScroll = () => {
        if (timelineRef.current) {
          const { scrollHeight, clientHeight, scrollTop } = timelineRef.current;
          setShowScrollButton(scrollHeight - clientHeight - scrollTop > 20); // Show if more than 20px left to scroll
        }
      };

      // Add scroll event listener to the timeline container
      const timelineContainer = timelineRef.current;
      if (timelineContainer) {
        timelineContainer.addEventListener("scroll", checkScroll);
      }

      // Check initial scroll state after data loads
      setTimeout(checkScroll, 10);

      // Cleanup
      return () => {
        if (timelineContainer) {
          timelineContainer.removeEventListener("scroll", checkScroll);
        }
      };
    }
  }, [timelineData]);

  const handleScroll = () => {
    if (timelineRef.current) {
      const currentScroll = timelineRef.current.scrollTop;
      timelineRef.current.scrollTo({
        top: currentScroll + 100,
        behavior: "smooth",
      });
    }
  };

  const fetchTimelineData = async () => {
    try {
      const res = await getOverallStatusTimeline(reportID);
      setTimelineData(res.data.data);
    } catch (error) {
      console.error("Error while fetching timeline data");
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
  <Box
    ref={timelineRef}
    sx={{
      display: "flex",
      p: 0,
      ml: 1.5,
      width: "90%",
      overflow: "auto",
      maxHeight: "500px",
      scrollBehavior: "smooth",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#B7C8E5",
        borderRadius: "4px",
      },
    }}
  >
    <Timeline
      position="right"
      sx={{
        width: "90%",
        ml: 0,
        p: 0,
        [`& .MuiTimelineOppositeContent-root`]: {
          flex: 0.1,
          p: 0,
        },
        [`& .MuiTimelineItem-root`]: {
          minHeight: "auto",
        },
        [`& .MuiTimelineContent-root`]: {
          p: "0px 16px",
        },
      }}
    >
      <Typography
        sx={{ color: "#1A1A1A", fontSize: "16px", fontWeight: 600, mb: 1 }}
      >
       Status History
      </Typography>
      {timelineData
        ?.map((item, index) => (
          <TimelineItem key={item.id}>
            <TimelineOppositeContent sx={{ flex: 0.1 }} />
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor:
                    item.status === "Returned_By_MM" ||
                    item.status === "Revalidation"
                      ? "#F01818" : item.status === "In_Progress" ? "#FFAC07"
                      : "#5DF018",
                  color: "#fff",
                }}
              >
                {item.status === "Returned_By_MM" ||
                item.status === "Revalidation" ? (
                  <NorthWestIcon />
                ) : item.status === "In_Progress" ? (<QueryBuilderIcon />) : (
                  <DoneIcon />
                )}
              </TimelineDot>
              {index !== timelineData.length - 1 && (
                <TimelineConnector
                  sx={{
                    backgroundColor: "#B7C8E5",
                    height: "24px",
                    width: "2px",
                  }}
                />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Accordion
                sx={{
                  boxShadow: "none",
                  backgroundColor: "inherit",
                  border: "none",
                  m: 0,
                  p: "0 !important",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                  sx={{ padding: "0 !important", m: 0 }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {item.taskStatus ? 
                      `${item.taskStatus} / ${item.roleName}` : 
                      `${item.status.replace("_", " ") === "New Survey" ? "New Tour" : item.status.replace("_", " ")} / ${item.roleName}`
                    }

                    </Typography>
                    {(item.roleName === "Executive Officer" && item.status === "New_Survey") ? (
                        <Box
                        display="flex"
                        alignItems="center"
                        sx={{ color: "#98999D" }}
                      >
                        <PersonOutlineIcon
                          fontSize="small"
                          sx={{ marginRight: 0.5 }}
                        />
                        <Typography
                          sx={{ fontSize: "12.5px", color: "#98999D", mr: 1 }}
                        >
                          {`${item.firstName} ${item.middleName} ${item.lastName}`}
                        </Typography>
                        <CalendarMonthIcon
                          fontSize="small"
                          sx={{ marginRight: 0.5 }}
                        />
                        <Typography sx={{ fontSize: "12.5px", color: "#98999D" }}>
                          {formatDate(item.updatedAt)}
                        </Typography>
                      </Box>
                    ): (
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ color: "#98999D" }}
                      >
                        <Typography
                          sx={{ fontSize: "12.5px", color: "#98999D", mr: 1 }}
                        >
                          {`${item.firstName} ${item.middleName} ${item.lastName}, `}
                        </Typography>
                        <Typography sx={{ fontSize: "12.5px", color: "#98999D" }}>
                          {formatDate(item.createdAt)}
                        </Typography>
                      </Box>
                    )}
                    
                  </Box>
                </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          padding: "16px 16px 16px",
                          backgroundColor: "#f3f3f3",
                          borderRadius: "8px",
                        }}
                      >
                        <Grid container spacing={2}>
                          {/* Top section with discipline and recommendations */}
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" gap={1}>
                              {/* Discipline Name */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  borderRadius: "4px",
                                  border:
                                    "0.5px solid var(--Primary-Blue, #009FF5)",
                                  padding: "4px 8px",
                                  backgroundColor: "#009FF5",
                                }}
                              >
                                <AssignmentIcon
                                  sx={{
                                    fontSize: 20,
                                    color: "white",
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontWeight: 500,
                                    color: "white",
                                  }}
                                >
                                  {item.discipline}
                                </Typography>
                              </Box>

                              {/* Recommendations Count */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  borderRadius: "4px",
                                  border:
                                    "0.5px solid var(--Primary-Blue, #009FF5)",
                                  padding: "4px 8px",
                                  backgroundColor: "#009FF5",
                                }}
                              >
                                <RecommendIcon
                                  sx={{
                                    fontSize: 20,
                                    color: "white",
                                  }}
                                />
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontWeight: 500,
                                    color: "white",
                                  }}
                                >
                                  {item.totalRecommendations} Recommendations
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          {/* Remarks section - only shown if remarks exist */}
                          {item.remarks && item.remarks.length > 0 && (
                            <>
                              <Grid item xs={12}>
                                <Box sx={{ mt: 1 }}>
                                  {Array.isArray(item.remarks) &&
                                    item.remarks.map((remark, index) => (
                                      <Typography
                                        key={index}
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                          mb: index !== item.remarks.length - 1 ? 1 : 0,
                                        }}
                                      >
                                        {`Remark for Recommendation ${index+1}: ${remark.comment}`}
                                      </Typography>
                                    ))}
                                </Box>
                              </Grid>
                            </>
                          )}


                        {item.recommendation && item.recommendation.length > 0 && (
                            <>
                            <Grid item xs={12}>
                              <Box sx={{ mt: 1 }}>
                                {Array.isArray(item.recommendation) &&
                                  item.recommendation?.map((remark, index) => (
                                    <Typography
                                      key={index}
                                      variant="body2"
                                      color="text.secondary"
                                      sx={{
                                        mb:
                                          index !== item.recommendation.length - 1
                                            ? 1
                                            : 0,
                                      }}
                                    >
                                     {`Recommendation ${index+1}: ${remark}`}
                                    </Typography>
                                  ))}
                              </Box>
                            </Grid>
                            </>
                          )}

                          {item.overallRemark &&  (
                            <>
                            <Grid item xs={12}>
                              <Box sx={{ mt: 1 }}>
                                    <Typography
                                      key={index}
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                     {`Overall Remark: ${item.overallRemark}`}
                                    </Typography>
                              </Box>
                            </Grid>
                            </>
                          )}

                          {item.createdAt && (
                            <Grid item xs={12}>
                            <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                              <ClockIcon  sx={{fontSize:'12px',mr:0.3,mt:0.3,color:'grey'}}/>
                              <Typography color='text.secondary' sx={{fontSize:'12px'}}>{formatDateToIndianLocale2(item.createdAt)}</Typography>
                            </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </Box>

      {showScrollButton && (
        <IconButton
          onClick={handleScroll}
          sx={{
            position: "absolute",
            bottom: "16px",
            right: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            backdropFilter: "blur(4px)",
            border: "1px solid rgba(183, 200, 229, 0.5)",
            borderRadius: "50%",
            zIndex: 1,
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
            ml: 0.5,
          }}
        >
          <KeyboardDoubleArrowDownIcon />
        </IconButton>
      )}
    </Box>
  );
}
