import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DisciplineDoughnutChart ({counts}) {
  const total = counts.reduce((acc, curr) => acc + curr, 0);
  
  // Calculate percentages
  const percentages = counts.map(count => Math.round((count / total) * 100));

  const data = {
    labels: ['Satisfactory', 'Unsatisfactory', 'Not Applicable'],
    datasets: [
      {
        data: percentages, // Using calculated percentages
        backgroundColor: ['#0FAB8E', '#FF231F', '#09A2E3'],
        hoverOffset: 4,
        cutout: '50%',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // This helps with sizing
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            // Show both percentage and actual count
            const count = counts[tooltipItem.dataIndex];
            const percentage = percentages[tooltipItem.dataIndex];
            return `${tooltipItem.label}: ${percentage}% (${count})`;
          },
        },
      },
    },
  };

  return (
    <div style={{ position: 'relative', width: '50%' }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};
