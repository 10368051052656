import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { Checkmark } from "../../constant";
import { Close } from "@mui/icons-material";

const CompletionModal = ({ open, onClose, onYes,message, subMessage1,subMessage2 }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { borderRadius: "8px", width: "550px" },
      }}
    >
      <DialogContent sx={{ pt: 0, pb: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Close onClick={onClose} sx={{ pointer: "cursor" }} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
           {message? message:"All Points Completed!!"} 
          </Typography>

          <img src={Checkmark} alt="check" />
          
          <Typography align="center" gutterBottom sx={{ fontWeight: 600, fontSize: '20px', m: 1 }}>
            {subMessage1? subMessage1:"Missed some points?"}
          </Typography>
          <Typography variant="body2" align="center">
            {subMessage2 ? subMessage2 :"Select Yes to add the missing points"}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
        <Button
          variant="contained"
          onClick={onClose}       
          sx={{
            borderRadius: 28,
            px: 3,
            bgcolor: "deepskyblue",
            color: "white",
          }}
        >
          No
        </Button>
        <Button
          variant="outlined"
          onClick={onYes}
          sx={{ borderRadius: 28, px: 3, color: "#4D4D4D" }} 
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompletionModal;
