// reducer.js
import { UPDATE_ACTIVE_STEP, COMPLETE_FORM } from './constant';

const initialState = {
  activeStep: 0,
  isFormComplete: false,
};

const stepReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case COMPLETE_FORM:
      return {
        ...state,
        isFormComplete: action.payload,
      };
    default:
      return state;
  }
};

export default stepReducer;
