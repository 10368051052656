import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function AuditAssignMemberDialog({ open, onClose }) {
  const [selectedLeaders, setSelectedLeaders] = useState([]);

  const leaders = [
    { id: 1, name: "Simran", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 2, name: "Anjali", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 3, name: "Rahul", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 4, name: "Dipanshu", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 5, name: "Sarthak", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
  ];

  const handleSelect = (id) => {
    setSelectedLeaders((prev) =>
      prev.includes(id) ? prev.filter((leaderId) => leaderId !== id) : [...prev, id]
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "#E6F7FF",
              borderRadius: "50%",
              width: "48px",
              height: "48px",
            }}
          >
            <PersonOutlineOutlinedIcon fontSize="32px" sx={{ color: "#09A2E3" }} />
          </Box>
          <Typography sx={{ color: "#15294B", fontSize: "20px", fontWeight: "600" }}>
            Assign Leaders for Audit
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TextField
          fullWidth
          placeholder="Search for Leader"
          size="small"
          sx={{
            mb: 2,
            border: "1px solid #EFF1F4",
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#9095A0" }} />
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            borderRadius: "10px",
            border: "1px solid #F5F6F7",
            padding: "10px 12px 10px 12px",
            overflowY: "auto",
          }}
        >
          {leaders.map((leader) => (
            <Card
              key={leader.id}
              sx={{
                mb: 2,
                border: "1px solid #F5F6F7",
                borderRadius: "8px",
                backgroundColor: selectedLeaders.includes(leader.id) ? "#e3f2fd" : "white",
                boxShadow: "none !important",
                padding:'0px !important',
              }}
            >
              <CardActionArea onClick={() => handleSelect(leader.id)}>
                <CardContent sx={{ padding: "12px !important" }}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center" gap={1.5}>
                      <Avatar>{leader.name.charAt(0)}</Avatar>
                      <Box>
                        <Typography
                          sx={{ color: "#091E42", fontSize: "14px", fontWeight: "700" }}
                        >
                          {leader.name}
                        </Typography>
                        <Typography
                          sx={{ color: "#667085", fontSize: "12px", fontWeight: "500" }}
                        >
                          {leader.email}
                        </Typography>
                      </Box>
                    </Box>
                    <Checkbox
                    checked={selectedLeaders.includes(leader.id)} // Check if the leader is selected
                    icon={
                        <RadioButtonUncheckedIcon style={{ color: "#D0D5DD", fontSize: 24 }} />
                    } // Empty circle when not selected
                    checkedIcon={
                        <CheckCircleIcon style={{ color: "#09A2E3", fontSize: 24 }} />
                    } // Tick icon when selected
                    onChange={() => handleSelect(leader.id)} // Handle selection toggle
                    value={leader.id}
                    />

                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="center" gap={1} sx={{ width: "100%" }}>
          <Button
            variant="outlined"
            onClick={() => onClose()}
            sx={{ width: "100%", border: "1px solid #09A2E3", color: "#344054" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ width: "100%", backgroundColor: "#09A2E3", color: "white" }}
            onClick={() => console.log("Assigning to Leaders:", selectedLeaders)}
            disabled={selectedLeaders.length === 0}
          >
            Assign
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
