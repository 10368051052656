import React, { useState, useEffect } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
  IconButton,
} from "@mui/material";
import CustomInput from "../CustomInputField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";

export default function CAPADrawer({ open, onClose, title }) {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "36%", padding: "0px !important" } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          {/* {title === "return"
            ? "Return Back"
            : title === "complete"
            ? "CAPA Completion Details"
            : ""} */}
            CAPA Completion Details
        </Typography>
      </Grid>
      {/* {title === "return" ? (
        <div style={{ padding: "12px 16px" }}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            my={2}
            p={3}
            sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
            borderRadius={4}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Review Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                format="DD-MM-YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                // minDate={dayjs(tourDate)}
                // maxDate={dayjs()}
              />
            </LocalizationProvider>
            <CustomInput
              // placeholder={"Action Taken"}
              label={"CAPA Input / Remark"}
              multiline
              rows={4}
              type={"text"}
              // value={comment}
              // onChange={(e) => setComment(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            // onClick={openConfirmationModal}
            sx={{ float: "right", color: "#ffffff" }}
          >
            Return to Previous level
          </Button>
        </div>
      ) : title === "complete" ? (

      ) : null} */}
        <div style={{ padding: "12px 16px" }}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            my={2}
            p={3}
            sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
            borderRadius={4}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Completion Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                format="DD-MM-YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                // minDate={dayjs(tourDate)}
                // maxDate={dayjs()}
              />
            </LocalizationProvider>

            <CustomInput
              // placeholder={"Action Taken"}
              label={"Action Taken"}
              multiline
              rows={4}
              type={"text"}
              // value={comment}
              // onChange={(e) => setComment(e.target.value)}
            />
            <Box display="flex" flexDirection="column" gap={0}>
              <Typography
                gutterBottom
                sx={{ fontSize: "14px", fontWeight: "600" }}
              >
                Completion Proof
              </Typography>
              {/* Can be taken from IMDrawer */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px dashed #09A2E3",
                  padding: 2,
                  borderRadius: 1,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <IconButton component="label">
                  <UploadIcon fontSize="large" sx={{ color: "#000000" }} />
                  <input
                    hidden
                    accept="image/*,.doc,.pdf,.mp4"
                    type="file"
                    // onChange={handleEvidenceUpload}
                    multiple
                  />
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    color: "#348BD2",
                    fontWeight: "600",
                    textDecoration: "underline",
                  }}
                >
                  Click to upload
                </Typography>
                <Typography variant="caption" sx={{ fontWeight: "600" }}>
                  PNG, JPG, Docx, PDF, and MP4 (max. 10 MB)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            // onClick={openConfirmationModal}
            sx={{ float: "right", color: "#ffffff", borderRadius: "50px", height:'32px' }}
          >
            CAPA Completed
          </Button>
        </div>
      <Fab
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "100px",
          right: open ? "37.3%" : "0",
          transform: "translateX(50%)",
          zIndex: 1200,
          height: "36px",
          width: "36px",
          backgroundColor: "#0AA2E3",
          borderRadius: "60px 0 0 60px",
        }}
      >
        <CloseIcon />
      </Fab>
    </Drawer>
  );
}
