import React,{useEffect,useState} from 'react';
import {cloudImage } from "../constant";
import { Box, Typography} from '@mui/material';
import axios from 'axios';


const WelcomeUserCard = () => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState(null);

    const API_KEY = 'e4dbc97b1b674b6ad3cd32d2ae95aa5c'; // Replace with your API key

    useEffect(()=>{
        const loginDataString = localStorage.getItem("loginData");
        if (loginDataString) {
          const data = JSON.parse(loginDataString);
          const {latitude,longitude} = data;
          setLatitude(latitude);
          setLongitude(longitude);
    
        }
     })

    useEffect(() => {
        if (latitude && longitude) {
            // Fetch weather data when we have the latitude and longitude
            const fetchWeatherData = async () => {
                try {
                    const response = await axios.get(
                        `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`
                    );
                    console.log(response.data);
                    setWeather(response.data);
                } catch (error) {
                    console.error("Error fetching the weather data", error);
                    setError("Error fetching weather data");
                }
            };

            fetchWeatherData();
        }
    }, [latitude, longitude, API_KEY]);

//style={{mixBlendMode:"color-burn"}}

    return (
        <>
            <Box display='flex' alignItems='center' gap={0.5}>
                {latitude && longitude && 
                (<Typography sx={{ fontSize: '16px', fontWeight: '600',color:"#0AA2E3"}}>
                            {Math.round(weather?.main.temp)}°C
                </Typography>)
                }
                <img src={cloudImage} style={{height:'44px'}} alt="image"   />
            </Box>
        </>
    )
}

export default WelcomeUserCard;