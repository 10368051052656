import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { Box, Typography } from "@mui/material";

const TrademarkIcon = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 34,
        right: -10,
        width: 28,
        height: 28,
        borderRadius: "50%",
        border: "2px solid black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
        }}
      >
        R
      </Typography>
    </Box>
  );
};

const AnimatedText = ({ text, color, fontSize }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "2px",
        overflow: "hidden",
      }}
    >
      {text.split("").map((char, index) => (
        <Typography
          key={index}
          sx={{
            opacity: 0,
            transform: "translateY(100%)",
            animation: `slideIn 0.3s ease ${index * 0.2}s forwards`,
            fontWeight: "700",
            color: color,
            fontSize: fontSize,
          }}
        >
          {char}
        </Typography>
      ))}
      <style>
        {`
          @keyframes slideIn {
            0% {
              opacity: 0;
              transform: translateY(100%);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </Box>
  );
};

const Flashpage = () => {
  const navigate = useNavigate();
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationComplete(true);
      const redirectTimer = setTimeout(() => {
        navigate("/landing-dashboard"); // Redirect to the landing-dashboard page after animation
      }, 2000);

      return () => clearTimeout(redirectTimer);
    }, 1000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box
      sx={{
        backgroundColor: "#F1F0F1",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ position: "relative" }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={0}
          sx={{ position: "relative" }}
        >
          <AnimatedText text="net" color="#09A2E3" fontSize="112px" />
          <AnimatedText text="zero" color="#000000" fontSize="112px" />
          <TrademarkIcon />
        </Box>
        {animationComplete && (
          <Typography
            sx={{
              fontSize: "42px",
              fontWeight: "700",
              color: "#09A2E3",
              position: "absolute",
              bottom: -20,
            }}
          >
            Journey to zero
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Flashpage;
