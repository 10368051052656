import React from 'react';
import { ButtonGroup, Button, Box } from '@mui/material';

const CustomButtonGroup = ({ buttonNames, selected, onSelect, name }) => {
    const gradientColors = [
        '#22C55E', // Green
        '#FDE047', // Yellow
        '#EF4444', // Red
    ];

    // Full gradient background across the container
    const fullGradient = 'linear-gradient(90deg, #22C55E 0%, #FDE047 50%, #EF4444 100%)';

    // Calculate button styles
    const getButtonStyle = (index) => ({
        fontSize: {
            xs: '12px', // Default for extra small devices
            sm: '12px', // Small devices
            md: '12px', // Medium devices
            lg: '9px',  // Large devices
            xl:"12px"
          },
        textTransform: 'none',
        color: selected === buttonNames[index] ? '#FFF' : '#666F7F',
        fontWeight: '500',
       
        background: selected === buttonNames[index]
            ? 'transparent'  // Make selected button transparent so gradient shows
            : '#FFF',  // White background for non-selected buttons
        border: selected === buttonNames[index] ? '#666F7F' : '1px solid #1976d280',
        '&:hover': {
            background: selected === buttonNames[index]
                ? 'transparent'  // Keep the button transparent on hover
                : '#f0f0f0',  // Hover effect for non-selected buttons
        },
    });

    return (
        <>
        { name === "Severity"  ? (
            <Box
            sx={{
                position: 'relative',
                display: 'flex',  // Use flex to keep Box and ButtonGroup aligned
                justifyContent: 'center',  // Center content horizontally
                maxWidth:  '115px',
                background: fullGradient,  // Gradient background
                borderRadius: '4px',  // Rounded corners for container
                boxSizing: 'border-box',
                marginBottom:"10px" 
              }}
        >
            {/* Button group overlays the gradient container */}
            <ButtonGroup
                size="small"
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    width:'100%',
                    '& .MuiButtonGroup-grouped': {
                        minWidth: '5px',  // Override min-width here
                       
                    },
                   
                }}
            >
                {buttonNames.map((name, index) => (
                    <Button
                        key={index}
                        sx={getButtonStyle(index)}
                        onClick={() => onSelect(name)}
                        variant="outlined"  // Outlined variant for all buttons
                    >
                        {name}
                    </Button>
                ))}
            </ButtonGroup>
        </Box>
        ):
        <ButtonGroup size="small"  sx={{
            marginBottom:  name === "Operation Status" ? "0px":"7px",
            '& .MuiButtonGroup-grouped': {
                minWidth: '5px',  // Override min-width here
                
            },
        }}>
        {buttonNames.map((name, index) => (
            <Button
                key={index}
                sx={{
                    fontSize: {
                        xs: '12px', // Default for extra small devices
                        sm: '12px', // Small devices
                        md: '12px', // Medium devices
                        lg: '9px',  // Large devices
                        xl:"12px"
                      },
                    textTransform: 'none',
                    color: selected === name ? '#FFF' : '#666F7F',
                    fontWeight: '500',
                    
                    border: selected === buttonNames[index] ?  "none" :'1 solid #666F7F',
                    backgroundColor: selected === name ? '#0AA2E3' : 'transparent', // Background color for selected
                    '&:hover': {
                        backgroundColor: selected === name ? '#0AA2E3' : '#f0f0f0' // Hover effect
                    }
                }}
                onClick={() => onSelect(name)}
                variant={selected === name ? 'contained' : 'outlined'}
            >
                {name}
            </Button>
        ))}
    </ButtonGroup> }

    </>
       
    );
};

export default CustomButtonGroup;
