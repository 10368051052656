import React, { useState } from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import CircleIcon from "@mui/icons-material/Circle";

const getStepIcon = (status, isActive) => {
  const boxShadow = isActive
    ? "0px 4px 12px rgba(0, 0, 255, 0.5)" // Add shadow when active
    : "none";

  if (status === "Incident Review") {
    return (
      <CheckOutlinedIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "#4591F5",
          border: "2px solid #4591F5",
          boxShadow,
        }}
      />
    );
  } else if (status === "Investigation Reinitiated") {
    return (
      <CircleIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "#FFAC07",
          border: "2px solid #FFAC07",
          boxShadow,
        }}
      />
    );
  } else if (status === "Investigation Validation") {
    return (
      <KeyboardBackspaceOutlinedIcon
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          color: "#4591F5",
          border: "2px solid #4591F5",
          boxShadow,
        }}
      />
    );
  }
  return (
    <CircleIcon
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        color: "#B7C8E5",
        border: "2px solid #B7C8E5",
        boxShadow,
      }}
    />
  );
};

export default function CustomStepperBox({ stepData }) {
  console.log("stepData", stepData);

  // Calculate the last step index
  const lastStepIndex = stepData.length -1 ;

  // Initialize the active step with the last step index
  const [activeStep, setActiveStep] = useState(lastStepIndex);

  const handleStepClick = (step, status) => {
    // Allow clicking only on specific step statuses
    if (
      ["New Incident", "Incident Review", "Investigation Initiated", "Investigation Validation", "Investigation Reinitiated"].includes(
        status
      )
    ) {
      setActiveStep(step);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingX: "24px",
        paddingY: "12px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        position: "relative",
        pt: 3,
        height: "110px",
      }}
    >
      {stepData?.length === 0 ? (
        <Typography sx={{ textAlign: "center", color: "#888" }}>
          No step data available.
        </Typography>
      ) : (
        <>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              transform: "translateY(-50%)",
              width: "85%",
            }}
          >
            {stepData.map((obj, index) => (
              <Step
                key={index}
                onClick={() => handleStepClick(index, obj.status)}
                sx={{ cursor: "pointer" }}
              >
                <StepLabel
                  StepIconComponent={() => getStepIcon(obj.status, activeStep === index)}
                />
              </Step>
            ))}
          </Stepper>
          <Box px={1} display="flex" flexDirection="column" gap={0.5}>
            <Typography sx={{ color: "#525966", fontSize: "16px", fontWeight: "600" }}>
              {stepData[activeStep]?.status || "N/A"} / {stepData[activeStep]?.roleName || "N/A"}
            </Typography>
            <Box>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" sx={{ color: "#222222" }}>
                  Reported By:
                </Typography>
                <Typography variant="body2" sx={{ color: "#525966" }}>
                  {stepData[activeStep]?.employeeID || "N/A"} /{" "}
                  {`${stepData[activeStep]?.firstName} ${stepData[activeStep]?.lastName}` || "N/A"} /{" "}
                  {stepData[activeStep]?.email || "N/A"}
                </Typography>
              </Box>
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" sx={{ color: "#222222" }}>
                  Reporting Officer:
                </Typography>
                <Typography variant="body2" sx={{ color: "#525966" }}>
                  {stepData[activeStep]?.firstName || "N/A"} /{" "}
                  {stepData[activeStep]?.email || "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}