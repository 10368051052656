import React from 'react';
import { waves } from '../constant';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Percent } from '@mui/icons-material';

export default function ActionsCard({title,percentage,value,indicator}) {
  return (
    <Box position="relative" sx={{ backgroundColor: "#FFFFFF", padding: "16px", minHeight: "104px", flexGrow: 1, overflow: 'hidden' }}>
        <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={8}>
                <Typography variant='body2' sx={{color:'#525966', fontWeight:'500'}}>{title}</Typography>
                <Typography variant='caption' sx={{color:'#8FA6CC'}}>Overdue Actions</Typography>
                <Typography sx={{color: indicator === "positive"? '#FF5753' :"red", fontWeight:'400', fontSize: '20px'}}>{percentage?percentage:"0"}</Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
              <Typography sx={{color:'#525966', fontWeight:'600', fontSize: '36px'}}>{value? value:"0"}</Typography>
            </Grid>
      </Grid>
      {/* Waves Image */}
      <img 
        src={waves} 
        alt="waves" 
        style={{ 
          position: 'absolute', 
          bottom: '-15px', 
          left: 0, 
          width: '100%', 
          height: 'auto', 
          zIndex: 0,
        }} 
      />
    </Box>
  );
}
