import * as React from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
 
 
function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();
 
  if (valueAngle === null) {
    return null;
  }
 
  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={target.x} cy={target.y} r={10} fill="#09A2E3" stroke="white" strokeWidth={4} />
    </g>
  );
}
 
export default function CompositionExample() {
  return (
    <GaugeContainer
      width={180}
      height={140}
      startAngle={-90}
      endAngle={90}
      value={80}
      cornerRadius="25%"
    >
      <GaugeReferenceArc />
      <GaugeValueArc
        color="#00FF00"   // Arc color
        innerRadius={80}  // Smaller inner radius for a thinner arc
        outerRadius={90}  // Adjust for thinness; difference between inner and outer defines thickness
        cornerRadius={5}  // Rounded corners
      />
       
      {/* <GaugePointer /> */}
    </GaugeContainer>
  );
}
 
 