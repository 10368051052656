import {
  ADD_MITIGATION_MEASURES,
  ADD_LESSON_LEARNED,
  UPDATE_FORM_FIELD,
  ADD_TO_CAUSE_ANALYSIS,
  UPDATE_CAUSE, ADD_CAUSE, REMOVE_CAUSE,
  ADD_INCIDENT_RESPONSIBILITY,
  UPDATE_INVESTIGATION_EVIDENCE_FILE,
  ADD_CHECKBOX,
  REMOVE_CHECKBOX,
  UPDATE_DATE_TIME,
  ONCHANGE_DRAWER_FORM,
  ADD_EVIDENCE_DETAIL,
  ADD_EQUIPMENT_DETAIL,
  ADD_PERSONNEL_DETAIL,
  SET_CAPA_DETAILS,
  ADD_CAPA_DETAIL,
  UPDATE_SWITCH_STATE,
  EDIT_CAPA_DETAIL,
  DELETE_CARD_DETAIL,
  SET_ALL_REPORT_DATA,
  RESET_STATE,
  REMOVE_INVESTIGATION_MEMBER,
  UPDATE_INVESTIGATION_WITNESS_FILE,
  ADD_WITNESS_STATEMENT,
  ADD_COMPENSATION_INFO,
  SET_COMPENSATION_INFO_DATA,
  ADD_CONTRIBUTING_FACTOR_DETAIL,
  EDIT_FACTOR_CARD_DETAIL, DELETE_FACTOR_CARD_DETAIL,
  SET_ROOT_CAUSE_ANALYSIS,
  SET_INCIDENT_DATA,
  SET_WORKFLOW_INPUT_DATA
} from './constant';

const initialState = {
  allReportsData: [],
  PersonnelDetail: [],
  Return: {},
  Review: {},
  InvestigationEvidence: {},
  WitnessStatement: {},
  ContributingFactor: {},
  IncidentResponsibilityDetail: [],
  MitigationMeasuresDetail: [],
  LessonsLearnedDetail: [],
  InvestigationEvidenceDetail: [],
  WitnessStatementDetail: [],
  RegulatoryInfo: {},
  CompensationInfoDetail: [],
  CAPADetail: [],
  ContributingFactorDetail: [],
  RootCauseAnalysis: {
    rootCause: [
    ],
    immediateCause: [
    ],
    indirectCause: [

    ]
  },
  WorkflowInput: {},
  switchStates: {
    IncidentResponsibility: 'Yes',
    MitigationMeasures: 'Yes',
    LessonsLearned: "Yes",
    RootCauseAnalysis: "Yes",
    InvestigationEvidence: "Yes",
    WitnessStatement: "Yes",
    RegulatoryInfo: "Yes",
    CompensationInfo: "Yes",
    CAPA: 'Yes',
    ContributingFactor: "Yes"
  },
  formEdit: {
    CompensationInfo: false,
    WitnessStatement: false,
    InvestigationEvidence: false,
    CAPA: false,
    Equipment: false,
    IncidentResponsibility: false,
    MitigationMeasures: false,
    LessonsLearned: false,
    ContributingFactor: false,
    Personnel: false,
  },



};
const incidentInvestigationFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...initialState, // Resets state to initialState
      };


    case SET_INCIDENT_DATA:
      const {
        incidentResponsibility,
        mitigationMeasures,
        lessonsLearned,
        investigationEvidence,
        witnessStatement,
        regulatoryInfo,
        compensationInfo,
        investigationCAPA,
        contributingFactor,
        rootCauseAnalysis,
        reviewedData,
        switchStates
      } = action.payload;

      return {
        ...state,
        IncidentResponsibilityDetail: incidentResponsibility || [],
        MitigationMeasuresDetail: mitigationMeasures || [],
        LessonsLearnedDetail: lessonsLearned || [],
        InvestigationEvidenceDetail: investigationEvidence || [],
        WitnessStatementDetail: witnessStatement || [],
        RegulatoryInfo: regulatoryInfo || {},
        CompensationInfoDetail: compensationInfo || [],
        CAPADetail: investigationCAPA || [],
        ContributingFactorDetail: contributingFactor || [],
        RootCauseAnalysis: rootCauseAnalysis || {
          rootCause: [],
          immediateCause: [],
          indirectCause: [],
        },
        WorkflowInput: reviewedData || {},
        switchStates:switchStates
      };


      case SET_WORKFLOW_INPUT_DATA:
        return {
          ...state,
          WorkflowInput: action.payload || {},
        };
  
  
    case ADD_CONTRIBUTING_FACTOR_DETAIL:
      const newFactor = action.payload; // Assuming payload is a single object
      const updatedContributingFactors = state.ContributingFactorDetail.map(factor =>
        factor.contributingFactor === newFactor.contributingFactor
          ? newFactor  // Replace the entire object if contributingFactor matches
          : factor     // Otherwise keep the existing object
      );

      // If the contributingFactor does not exist, add the new one
      const isNewFactor = !state.ContributingFactorDetail.some(factor =>
        factor.contributingFactor === newFactor.contributingFactor
      );

      return {
        ...state,
        ContributingFactorDetail: isNewFactor
          ? [...updatedContributingFactors, newFactor] // Add new factor if it's not found
          : updatedContributingFactors // Replace the existing factor
      };


    case UPDATE_INVESTIGATION_EVIDENCE_FILE:
      return {
        ...state,
        InvestigationEvidence: {
          ...state.InvestigationEvidence,
          file: Array.isArray(state.InvestigationEvidence.file)
            ? [...state.InvestigationEvidence.file, action.payload.fileURL] // Append the new file object to the array
            : [action.payload.fileURL], // Initialize as array if it's not an array yet
          files: Array.isArray(state.InvestigationEvidence.files)
            ? [...state.InvestigationEvidence.files, action.payload.file] // Append the new file URL to the array
            : [action.payload.file], // Initialize as array if it's not an array yet
        },
      };

    case UPDATE_INVESTIGATION_WITNESS_FILE:
      return {
        ...state,
        WitnessStatement: {
          ...state.WitnessStatement,
          file: Array.isArray(state.WitnessStatement.file)
            ? [...state.WitnessStatement.file, action.payload.fileURL] // Append the new file object to the array
            : [action.payload.fileURL], // Initialize as array if it's not an array yet
          files: Array.isArray(state.WitnessStatement.files)
            ? [...state.WitnessStatement.files, action.payload.file] // Append the new file URL to the array
            : [action.payload.file], // Initialize as array if it's not an array yet
        },
      };

    case UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,  // Dynamically update field
      };




    case UPDATE_DATE_TIME:
      return {
        ...state,
        [action.payload.title]: {
          ...state[action.payload.title],
          [action.payload.fieldName]: action.payload.value, // Use fieldName dynamically as key
        }
      };

    // Dynamic case for adding/updating PERSONNEL detail
    case ONCHANGE_DRAWER_FORM:
      return {
        ...state,
        [action.payload.title]: {
          ...state[action.payload.title],
          [action.payload.fieldName]: action.payload.value, // Use fieldName dynamically as key
        }
      };


    case SET_CAPA_DETAILS:
      return {
        ...state,
        CAPADetail: [...action.payload], // store the new CAPADetail array
      };

    case ADD_MITIGATION_MEASURES: {
      // Find the highest ID in the current CAPADetail array
      const highestId = state.MitigationMeasuresDetail.reduce((maxId, detail) =>
        Math.max(detail.id, maxId), 0); // Get the max id, or 0 if the array is empty

      const existingIndex = state.MitigationMeasuresDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedMitigationMeasures;

      if (existingIndex !== -1) {

        updatedMitigationMeasures = state.MitigationMeasuresDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        updatedMitigationMeasures = [
          ...state.MitigationMeasuresDetail,
          {
            id: highestId + 1,
            ...action.payload
          }
        ];
      }
      return {
        ...state,
        MitigationMeasuresDetail: updatedMitigationMeasures,
        formEdit: { ...state.formEdit, MitigationMeasures: false }, // Reset form edit state for CAPA
        MitigationMeasures: {} // Reset CAPA object
      };
    }

    case ADD_LESSON_LEARNED: {
      // Find the highest ID in the current CAPADetail array
      const highestId = state.LessonsLearnedDetail.reduce((maxId, detail) =>
        Math.max(detail.id, maxId), 0); // Get the max id, or 0 if the array is empty

      const existingIndex = state.LessonsLearnedDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedLessonsLearnedDetail;

      if (existingIndex !== -1) {

        updatedLessonsLearnedDetail = state.LessonsLearnedDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        updatedLessonsLearnedDetail = [
          ...state.LessonsLearnedDetail,
          {
            id: highestId + 1,
            ...action.payload
          }
        ];
      }
      return {
        ...state,
        LessonsLearnedDetail: updatedLessonsLearnedDetail,
        formEdit: { ...state.formEdit, LessonsLearnedDetail: false }, // Reset form edit state for CAPA
        LessonsLearned: {} // Reset CAPA object
      };
    }

    case ADD_CAPA_DETAIL: {
      // Find the highest ID in the current CAPADetail array
      const highestId = state.CAPADetail.reduce((maxId, detail) =>
        Math.max(detail.id, maxId), 0); // Get the max id, or 0 if the array is empty

      const existingIndex = state.CAPADetail.findIndex(detail => detail.id === action.payload.id);

      let updatedCAPADetail;

      if (existingIndex !== -1) {
        // Replace the object at the found index with the new object
        updatedCAPADetail = state.CAPADetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add a new object with id as the highestId + 1
        updatedCAPADetail = [
          ...state.CAPADetail,
          {
            id: highestId + 1, // Increment the highest existing ID
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        CAPADetail: updatedCAPADetail,
        formEdit: { ...state.formEdit, CAPA: false }, // Reset form edit state for CAPA
        CAPA: {} // Reset CAPA object
      };
    }

    case ADD_INCIDENT_RESPONSIBILITY: {
      // Find the highest ID in the current CAPADetail array
      const highestId = state.IncidentResponsibilityDetail.reduce((maxId, detail) =>
        Math.max(detail.id, maxId), 0); // Get the max id, or 0 if the array is empty

      const existingIndex = state.IncidentResponsibilityDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedIncidentResponsibilityDetail;

      if (existingIndex !== -1) {

        updatedIncidentResponsibilityDetail = state.IncidentResponsibilityDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        updatedIncidentResponsibilityDetail = [
          ...state.IncidentResponsibilityDetail,
          {
            id: highestId + 1,
            ...action.payload
          }
        ];
      }
      return {
        ...state,
        IncidentResponsibilityDetail: updatedIncidentResponsibilityDetail,
        formEdit: { ...state.formEdit, IncidentResponsibility: false }, // Reset form edit state for CAPA
        IncidentResponsibility: {} // Reset CAPA object
      };
    }


    case REMOVE_INVESTIGATION_MEMBER:
      return {
        ...state,
        Assign_Investigation: {
          ...state.Assign_Investigation,
          "Investigation Members": state.Assign_Investigation["Investigation Members"].filter(member => member !== action.payload),
        },
      };


    case UPDATE_SWITCH_STATE:
      return {
        ...state,
        switchStates: {
          ...state.switchStates,
          [action.payload.sectionName]: action.payload.value, // Update the specific switch state
        },
      };

    case EDIT_CAPA_DETAIL: {
      const { id, title } = action.payload;
      let detailArray;
      switch (title) {
        case 'CAPA':
          detailArray = state.CAPADetail;
          break;
        case 'Personnel':
          detailArray = state.PersonnelDetail;
          break;

        case 'Evidence':
          detailArray = state.EvidenceDetail;
          break;
        case 'MitigationMeasures':
          detailArray = state.MitigationMeasuresDetail;
          break;
        case 'LessonsLearned':
          detailArray = state.LessonsLearnedDetail;
          break;

        case 'IncidentResponsibility':
          detailArray = state.IncidentResponsibilityDetail;
          break;
        case 'InvestigationEvidence':
          detailArray = state.InvestigationEvidenceDetail;
          break;
        case 'WitnessStatement':
          detailArray = state.WitnessStatementDetail;
          break;

        default:
          detailArray = [];
          break;
      }

      const detailObject = detailArray.find(detail => detail.id === id);

      return {
        ...state,
        formEdit: { ...state.formEdit, [title]: true },
        [title]: title === "Personnel"
          ? {
            ...state[title],     // Keep the existing data of Personnel
            ...detailObject      // Merge the new data from detailObject
          }
          : detailObject || {}    // For other titles, simply set the detailObject
      };
    };



    case DELETE_CARD_DETAIL: {
      const { id, title } = action.payload;
      let updatedDetailArray;

      // Determine which detail array to modify based on the title
      switch (title) {
        case 'CAPA':
          updatedDetailArray = state.CAPADetail.filter(detail => detail.id !== id);
          return {
            ...state,
            CAPADetail: updatedDetailArray, // Update CAPADetail
          };

        case 'Personnel':
          updatedDetailArray = state.PersonnelDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            PersonnelDetail: updatedDetailArray, // Update PersonnelDetail
          };

        case 'Evidence':
          updatedDetailArray = state.EvidenceDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            EvidenceDetail: updatedDetailArray, // Update EvidenceDetail
          };
        case 'MitigationMeasures':
          updatedDetailArray = state.MitigationMeasuresDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            MitigationMeasuresDetail: updatedDetailArray, // Update EvidenceDetail
          };
        case 'LessonsLearned':
          updatedDetailArray = state.LessonsLearnedDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            LessonsLearnedDetail: updatedDetailArray, // Update EvidenceDetail
          };
        case 'IncidentResponsibility':
          updatedDetailArray = state.IncidentResponsibilityDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            IncidentResponsibilityDetail: updatedDetailArray, // Update EvidenceDetail
          };
        case 'InvestigationEvidence':
          updatedDetailArray = state.InvestigationEvidenceDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            InvestigationEvidenceDetail: updatedDetailArray, // Update EvidenceDetail
          };

        case 'WitnessStatement':
          updatedDetailArray = state.WitnessStatementDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            WitnessStatementDetail: updatedDetailArray, // Update EvidenceDetail
          };


        default:
          return state; // Return the current state if the title doesn't match any case
      }
    }

    case EDIT_FACTOR_CARD_DETAIL: {

      let detailArray = state.ContributingFactorDetail;
      const detailObject = detailArray.find(detail => detail.contributingFactor === action.payload);

      return {
        ...state,
        formEdit: { ...state.formEdit, ContributingFactor: true },
        ContributingFactor: detailObject || []    // For other titles, simply set the detailObject
      };
    };



    case DELETE_FACTOR_CARD_DETAIL: {
      let updatedDetailArray;
      updatedDetailArray = state.ContributingFactorDetail.filter(detail => detail.contributingFactor !== action.payload);
      return {
        ...state,
        ContributingFactorDetail: updatedDetailArray, // Update CAPADetail
      }

    }



    case UPDATE_CAUSE: {
      const { causeType, index, updatedData } = action.payload;
      return {
        ...state,
        RootCauseAnalysis: {
          ...state.RootCauseAnalysis,
          [causeType]: state.RootCauseAnalysis[causeType].map((item, i) =>
            i === index ? { ...item, ...updatedData } : item
          )
        }
      };
    }

    case SET_ROOT_CAUSE_ANALYSIS:
      return {
        ...state,
        RootCauseAnalysis: {
          rootCause: action.payload.rootCause || [],
          immediateCause: action.payload.immediateCause || [],
          indirectCause: action.payload.indirectCause || [],
        },
      };

    case ADD_CAUSE: {
      const { causeType, newCause } = action.payload;
      return {
        ...state,
        RootCauseAnalysis: {
          ...state.RootCauseAnalysis,
          [causeType]: [...state.RootCauseAnalysis[causeType], newCause]
        }
      };
    }

    case REMOVE_CAUSE: {
      const { causeType, index } = action.payload;
      return {
        ...state,
        RootCauseAnalysis: {
          ...state.RootCauseAnalysis,
          [causeType]: state.RootCauseAnalysis[causeType].filter((_, i) => i !== index)
        }
      };
    }


    case ADD_EVIDENCE_DETAIL: {
      const existingIndex = state.InvestigationEvidenceDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedEvidenceDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedEvidenceDetail = state.InvestigationEvidenceDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedEvidenceDetail = [
          ...state.InvestigationEvidenceDetail,
          {
            id: state.InvestigationEvidenceDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        InvestigationEvidenceDetail: updatedEvidenceDetail,
        formEdit: { ...state.formEdit, InvestigationEvidence: false },
        InvestigationEvidence: {} // Reset Evidence object
      };
    }


    case ADD_WITNESS_STATEMENT: {
      const existingIndex = state.WitnessStatementDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedWitnessStatementDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedWitnessStatementDetail = state.WitnessStatementDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedWitnessStatementDetail = [
          ...state.WitnessStatementDetail,
          {
            id: state.WitnessStatementDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        WitnessStatementDetail: updatedWitnessStatementDetail,
        formEdit: { ...state.formEdit, WitnessStatement: false },
        WitnessStatement: {} // Reset Evidence object
      };
    }


    case ADD_COMPENSATION_INFO: {
      const existingIndex = state.CompensationInfoDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedCompensationInfoDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedCompensationInfoDetail = state.CompensationInfoDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedCompensationInfoDetail = [
          ...state.CompensationInfoDetail,
          {
            id: state.CompensationInfoDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        CompensationInfoDetail: updatedCompensationInfoDetail,
        formEdit: { ...state.formEdit, CompensationInfo: false },
        CompensationInfo: {} // Reset Evidence object
      };
    }


    // ADD_COMPENSATION_INFO
    case ADD_COMPENSATION_INFO: {
      const existingIndex = state.CompensationInfoDetail.findIndex(
        (detail) => detail.id === action.payload.id
      );

      let updatedCompensationInfoDetail;

      if (existingIndex !== -1) {
        // Replace the existing object with full payload
        updatedCompensationInfoDetail = state.CompensationInfoDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object with incremented ID
        updatedCompensationInfoDetail = [
          ...state.CompensationInfoDetail,
          {
            id: state.CompensationInfoDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        CompensationInfoDetail: updatedCompensationInfoDetail,
        formEdit: { ...state.formEdit, CompensationInfo: false }, // Resetting form edit flag
        CompensationInfo: {} // Resetting CompensationInfo object
      };
    }

    // SET_COMPENSATION_INFO_DATA
    case SET_COMPENSATION_INFO_DATA: {
      // Destructure the action payload
      const { title, row } = action.payload;
      const { id, Name, Outcome } = row;

      // Check if there's a match in CompensationInfoDetail for the given id
      const matchedDetail = state.CompensationInfoDetail.find((detail) => detail.id === id);

      // If match found, set the entire object, else just set id, Name, and Outcome
      return {
        ...state,
        [title]: matchedDetail
          ? { ...matchedDetail } // If match, set the entire object
          : { id, Name, Outcome }, // If no match, set only id, Name, and Outcome
      };
    }






    // ADD_PERSONNEL_DETAIL case
    // case ADD_PERSONNEL_DETAIL: {
    //   const noOfPeople = state.Personnel?.["No. of People"] || 0;
    //   const currentPersonnelDetailCount = state.PersonnelDetail.length;


    //   const existingIndex = state.PersonnelDetail.findIndex(detail => detail.id === action.payload.id);
    //   if (existingIndex === -1 && currentPersonnelDetailCount >= noOfPeople) {
    //     return state; // Prevent adding new personnel details if limit is reached
    //   }

    //   let updatedPersonnelDetail;

    //   if (existingIndex !== -1) {
    //     // Replace existing object
    //     updatedPersonnelDetail = state.PersonnelDetail.map((detail, index) =>
    //       index === existingIndex ? { ...detail, ...action.payload } : detail
    //     );
    //   } else {
    //     // Add new object
    //     updatedPersonnelDetail = [
    //       ...state.PersonnelDetail,
    //       {
    //         id: state.PersonnelDetail.length + 1, // Increment ID based on array length
    //         ...action.payload
    //       }
    //     ];
    //   }

    //   const retainedPersonnel = Object.keys(state.Personnel || {}) // Ensure state.PERSONNEL is not null or undefined
    //   .filter(key => [
    //     "No. of People",
    //     "Injury Count",
    //     "Fatality Count",
    //     "Location of Accident"
    //   ].includes(key))
    //   .reduce((acc, key) => ({ ...acc, [key]: state.Personnel[key] }), {});

    // return {
    //   ...state,
    //   PersonnelDetail: updatedPersonnelDetail,
    //   formEdit:{...state.formEdit, Personnel:false},
    //   Personnel: retainedPersonnel // Retain only specific keys, resetting the rest
    // };

    // }






    // case ADD_CHECKBOX:
    //   if (!state.GeneralInfo.includes(action.payload)) {
    //     return {
    //       ...state,
    //       GeneralInfo: [...state.GeneralInfo, action.payload],
    //     };
    //   }
    //   return state;

    // case REMOVE_CHECKBOX:
    //   return {
    //     ...state,
    //     GeneralInfo: state.GeneralInfo.filter(item => item !== action.payload),
    //   };
    //   case SET_ALL_REPORT_DATA:
    //     return {
    //         ...state,
    //         allReportsData: action.payload
    //     };





    default:
      return state;
  }
};

export default incidentInvestigationFlowReducer;
