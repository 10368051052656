import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function ApexChart({ data, title }) {
    // Transform input data into ApexCharts format
    const formattedData = data.map((locationItem) => ({
        name: locationItem.name,
        data: locationItem.data?.map((valueItem) => ({
            x: valueItem.x, // Discipline name
            y: valueItem.y, // Value
            location: locationItem.name, // Location name
        })),
    }));

    const [chartOptions] = useState({
        legend: {
            show: false,
            position: "top",
        },
        chart: {
            height: 400,
            type: "treemap",
            toolbar: {
                show: false, // Hide the toolbar
            },
        },
        title: {
            text: title,
            align: "left",
            style: {
                fontSize: "16px",
                fontWeight: 500,
            },
        },
        tooltip: {
            enabled: true,
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                // Extract current data
                const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
                return `
                    <div style="padding: 8px; background: #fff; border: 1px solid #ccc; border-radius: 5px;">
                       ${dataPoint.location}<br />
                        <b>Discipline:</b> ${dataPoint.x}<br />
                         <b> ${dataPoint.y}</b>
                    </div>
                `;
            },
        },
        plotOptions: {
            treemap: {
                distributed: false, // Same color within a location
            },
        },
    });

    return (
        <div>
            <div id="chart">
                <ReactApexChart
                    options={chartOptions}
                    series={formattedData}
                    type="treemap"
                    height={550}
                />
            </div>
        </div>
    );
}

export default ApexChart;
