import React, { useState, useEffect } from "react";
import { Typography, Box, Card, CardContent } from "@mui/material";
import DraggableImageDialog from "./DraggableImageDialog";

const BroadcastSlideshowTwo = ({ broadcasts = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [openMediaModel, setOpenMediaModel] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [text, setText] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Automatically change slides every 3 seconds
  useEffect(() => {
    if (broadcasts.length > 0) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % broadcasts.length);
      }, 3000);

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [broadcasts.length]);

  const handleDotClick = (index) => {
    setCurrentSlide(index); // Set the slide manually when dot is clicked
  };

  if (broadcasts.length === 0) {
    return <Typography>No broadcasts available</Typography>;
  }

  const currentBroadcast = broadcasts[currentSlide];
  const isVideo =
    currentBroadcast.url?.includes(".mp4") ||
    currentBroadcast.url?.includes(".webm");
  // console.log("currentBroadcast.url", currentBroadcast.url);

  const handleClickImage = (image, text) => {
    setMediaUrl(image);
    setText(text);
    setOpenMediaModel(true);
  };

  const handleCloseDialog = () => {
    setOpenMediaModel(false);
    setMediaUrl(null);
    setText(null);
  };

  const truncateText = (text, maxChars = 100) => {
    if (text.length <= maxChars) return text;
    return text.slice(0, maxChars) + "...";
  };

  const handlePlayPause = (e) => {
    if (isPlaying) {
      e.target.pause();
    } else {
      e.target.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Box sx={{ width: "100%", textAlign: "left" }}>
      {currentBroadcast.url && <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
          marginBottom: '8px',
        }}
      >
        Important Alert
      </Typography>}
      <Typography
        variant="body2"
        sx={{ fontSize: "16px", fontWeight: "500" }}
      >
        {broadcasts[currentSlide]?.text
          && truncateText(
            broadcasts[currentSlide].text.charAt(0).toUpperCase() +
            broadcasts[currentSlide].text.slice(1),
            100 // Adjust character limit based on desired width
          )
        }
      </Typography>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          //height: "280px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isVideo ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: 'auto'
            }}
            onClick={() =>
              handleClickImage(
                currentBroadcast.url,
                broadcasts[currentSlide]?.text
              )
            }
          >
            {currentBroadcast.url &&
              <video
                src={currentBroadcast.url}
                //poster={currentBroadcast.posterUrl} // Add poster URL here
                style={{
                  width: "100%",
                  height: "220px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
                onClick={() =>
                  handleClickImage(
                    currentBroadcast.url,
                    broadcasts[currentSlide]?.text
                  )
                }
              />
            }

            {/* Custom Play Button Overlay */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 255, 255, 0.8)", // semi-transparent white background
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5v14l11-7L8 5z" fill="#595959" />{" "}
                {/* Set the play icon color */}
              </svg>
            </div>
          </Box>
        ) : (
          <img
            src={currentBroadcast?.url ? currentBroadcast?.url : currentBroadcast?.defaultUrl}
            alt={`Broadcast ${currentSlide + 1}`}
            style={{
              width: "100%",
              maxHeight: "300px",
              minHeight: "300px",
              objectFit: "fill",
              borderRadius: "8px",
            }}
            onClick={() =>
              handleClickImage(
                currentBroadcast?.url ? currentBroadcast?.url : currentBroadcast?.defaultUrl,
                broadcasts[currentSlide]?.text
              )
            }
          />
        )}
      </Box>

      
      <DraggableImageDialog
        imageSrc={mediaUrl}
        open={openMediaModel}
        onClose={handleCloseDialog}
        text={text}
      />
    </Box>
  );
};

export default BroadcastSlideshowTwo;
