export const ADD_SITE_LOCATION = "ADD_SITE_LOCATION";
export const ADD_DISCIPLINE_NAME = "ADD_DISCIPLINE_NAME";
export const ADD_DEFAULT_QUESTIONS = "ADD_DEFAULT_QUESTIONS";
export const ADD_NEW_CHECKPOINTS = "ADD_NEW_CHECKPOINTS";
export const ADD_OBSERVATION = "ADD_OBSERVATION";
export const ADD_DATA = "ADD_DATA";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const ADD_LOCATION_ID = "ADD_LOCATION_ID";
export const UPDATE_DASHBOARD_FILTER = 'UPDATE_DASHBOARD_FILTER';
export const UPDATE_DASHBOARD_FILTER_ARRAY = "UPDATE_DASHBOARD_FILTER_ARRAY";
export const ADD_SATISFACTORY_REMARK = "ADD_SATISFACTORY_REMARK";
export const ADD_NOTAPPLICABLE_REMARK = "ADD_NOTAPPLICABLE_REMARK";
