import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SecurityIcon from "@mui/icons-material/Security";
import PersonIcon from "@mui/icons-material/Person";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import {
  disciplineCover,
  disciplineIconBG,
  disciplineDrilling,
  disciplineFinance,
  disciplineDefaultIcon,
  disciplineGMS,
  disciplinePSS,
  disciplineOGPS,
} from "../../constant";
import { useDispatch,useSelector } from "react-redux";
import {
  addDefaultQuestions,
  addDisciplineName,
} from "../../redux/LossControl/actions";

export default function ChooseDiscipline({ handleNext,handleBack }) {
  const [allDisciplines, setAllDisciplines] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState(null);
  const [disciplineQuestionnaire, setDisciplineQuestionnaire] = useState([]);

  const dispatch = useDispatch();

  const handleDisciplineSelect = (index, name) => {
    setSelectedDiscipline(index);
    dispatch(addDisciplineName(name));
    const data = JSON.parse(localStorage.getItem("loginData"));
    const formFields =
      data?.config?.modulesDetail?.[1]?.sectionsDetail?.[0]?.formFields;
    if (formFields && formFields.length > 0) {
      const matchedField = formFields.find(
        (field) => field.formFieldName === name
      );
      if (matchedField && matchedField.options) {
        setDisciplineQuestionnaire(matchedField.options);
      } else {
        setDisciplineQuestionnaire([]);
      }
    }
  };

  const handleProceed = () => {
    if (selectedDiscipline !== null) {
      const formattedQuestions = disciplineQuestionnaire?.map(
        (question, index) => ({
          id: index + 1,
          quesName: question,
          observation: "",
          data: null,
        })
      );

      dispatch(addDefaultQuestions(formattedQuestions));

      handleNext();
    }
  };

  useEffect(() => {
    fetchDisciplines();
  }, []);

  const fetchDisciplines = () => {
    const data = JSON.parse(localStorage.getItem("loginData"));

    const formFields =
      data?.config?.modulesDetail?.[1]?.sectionsDetail?.[0]?.formFields;
    if (formFields) {
      const formFieldNames = formFields?.map((field) => field.formFieldName);
      setAllDisciplines(formFieldNames);
    } else {
      console.error("formFields is undefined or cannot be found");
    }
  };

  const tourSite = useSelector(state => state.lossControlReducer?.tours?.siteLocation);
  
  const handleEditLocation = () => {
    handleBack();
  };

  const getDisciplineIcon = (name) => {
    switch (name.toLowerCase()) {
      case "asset":
        return (
          <ApartmentIcon
            style={{ width: "32px", height: "32px", color: "#009FF5" }}
          />
        );
      case "engineering":
        return (
          <EngineeringIcon
            style={{ width: "32px", height: "32px", color: "#009FF5" }}
          />
        );
      case "security":
        return (
          <SecurityIcon
            style={{ width: "32px", height: "32px", color: "#009FF5" }}
          />
        );
      case "hr":
        return (
          <PersonIcon
            style={{ width: "32px", height: "32px", color: "#009FF5" }}
          />
        );
      case "medical":
        return (
          <MedicalServicesIcon
            style={{ width: "32px", height: "32px", color: "#009FF5" }}
          />
        );
      case "drilling":
        return (
          <img
            src={disciplineDrilling}
            alt="Drilling"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "finance & other":
        return (
          <img
            src={disciplineFinance}
            alt="Finance"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "ogps":
        return (
          <img
            src={disciplineOGPS}
            alt="Finance"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "pss":
        return (
          <img
            src={disciplinePSS}
            alt="Finance"
            style={{ width: "32px", height: "32px" }}
          />
        );
      case "gms & lpg":
        return (
          <img
            src={disciplineGMS}
            alt="Finance"
            style={{ width: "32px", height: "32px" }}
          />
        );
      default:
        return (
          <img
            src={disciplineDefaultIcon}
            alt="default"
            style={{ width: "32px", height: "32px" }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        padding: "16px",
        minHeight: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            sx={{ color: "#222222", fontSize: "20px", fontWeight: "600" }}
          >
            Choose a Discipline
          </Typography>
          <Typography
            sx={{ color: "#525966", fontSize: "14px", fontWeight: "400" }}
          >
            Select the discipline which you want to inspect.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Box
            display="flex"
            gap={0.5}
            alignItems="center"
            sx={{
              minWidth: "300px",
              backgroundColor: "#EEE",
              borderRadius: "8px",
              padding: "4px 12px",
            }}
          >
            <LocationOnOutlinedIcon fontSize="small" />
            <Typography
              sx={{ color: "#323232", fontSize: "14px", fontWeight: "400" }}
            >
              Tour Site:
            </Typography>
            <Typography
              sx={{ color: "#009FF5", fontSize: "14px", fontWeight: "400" }}
            >
              {tourSite}
            </Typography>
            <IconButton>
              <ModeEditOutlineOutlinedIcon
              onClick={handleEditLocation}
                fontSize="small"
                sx={{ color: "#009FF5" }}
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={0} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            {allDisciplines?.map((name, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={5}
                md={3}
                lg={2}
                xl={2}
                sx={{ p: 1.5, display: "flex", alignItems: "flex-start" }}
              >
                <Box
                  onClick={() => handleDisciplineSelect(index, name)}
                  sx={{
                    height: "114px",
                    width: "927px",
                    borderRadius: "8px",
                    border: "0.5px solid #B7C8E5",
                    padding: "16px",
                    backgroundImage:   selectedDiscipline != index  && `url(${disciplineCover})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex-start",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow:
                      selectedDiscipline === index &&
                      "0px 0px 12px 0px rgba(9, 162, 227, 0.65)",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      width: "52px",
                      height: "52px",
                      backgroundImage: `url(${disciplineIconBG})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {getDisciplineIcon(name)}
                  </Box>
                  <Typography
                    sx={{
                      color: "#525966",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginTop: "8px",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>



      
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          onClick={handleProceed}
          disabled={selectedDiscipline === null}
          sx={{
            fontSize: "14px",
            fontWeight: "700",
            backgroundColor: "#09A2E3",
            color: "#ffffff",
            paddingY: "8px",
            paddingX: "32px",
            borderRadius: "24px",
          }}
        >
          Proceed
        </Button>
      </Box>
    </Box>
  );
}
