import React, { useEffect, useState } from "react";
import { Box, Avatar, Typography, Grid } from "@mui/material";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import PersonIcon from "@mui/icons-material/Person";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useDispatch, useSelector } from 'react-redux';
import RightDrawer from './RightDrawerInvestigation';
import CheckIcon from "@mui/icons-material/Check";
import Badge from '@mui/material/Badge';
import {convertMillisecondsToDate} from "../utils/helper";
import { setRootCauseAnalysis, setIncidentData, setWorkflowInputData } from "../redux/incidentInvestflow/actions";
import { lessonsLearnedFields, compentionsFormFields, mitigationFormFields, regulatoryFormFields, incidentResponsibilityfields, witnessStatementField, firstReviwerNextDrawerFields, firstReviwerReturnDrawerFields, assignCAPAFormFields, assignInvestigationFormFields, workFlowInputDrawerFields } from "../utils/workFlowFormFields";


const investigationCards = [
    { icon: 'GppMaybeOutlinedIcon', label: 'Mitigation Measures', status: '1' },
    { icon: 'PersonIcon', label: 'Incident Responsibility', status: '0' },
    { icon: 'GavelOutlinedIcon', label: 'Regulatory Info', status: '0' },
    { icon: 'CurrencyRupeeOutlinedIcon', label: 'Compensation Info', status: '0' },
    { icon: 'MenuBookOutlinedIcon', label: 'Lessons Learned', status: '0' },
    { icon: 'SupervisedUserCircleIcon', label: 'Witness Statement', status: '0' },
    { icon: 'TroubleshootIcon', label: 'Root Cause Analysis', status: '0' },
    { icon: 'AddToDriveIcon', label: 'Contributing Factor', status: '0' },
    { icon: 'AddAPhotoIcon', label: 'Investigation Evidence', status: '0' },
    { icon: 'PendingActionsIcon', label: 'CAPA', status: '0' },
    { icon: 'CreateOutlinedIcon', label: 'Workflow Input', status: '0' },
]


const personData = [
    { id: 1, Outcome: "Minor Injury", Name: "Rahul" },
    { id: 2, Outcome: "Fatality", Name: "Sanjay" },
    { id: 3, Outcome: "Major Injury", Name: "Ajay" }

];

const InvestigationCard = ({ levelReviwer, status, IncidentReportData, cardHeadBG }) => {
    const dispatch = useDispatch();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState("");
    const formData = useSelector(state => state.incidentInvestigationFlowReducer);
    const {
        Return,
        Review,
        CAPA,
        CAPADetail,
        MitigationMeasures,
        MitigationMeasuresDetail,
        LessonsLearnedDetail,
        IncidentResponsibilityDetail,
        InvestigationEvidenceDetail,
        WitnessStatementDetail,
        RootCauseAnalysis,
        LessonsLearned,
        ContributingFactorDetail,
        switchStates
    } = formData;





    useEffect(() => {
        // Check if rootCauseAnalysis exists and is an array
        if (Array.isArray(IncidentReportData?.rootCauseAnalysis) && IncidentReportData.rootCauseAnalysis.length) {
            dispatch(setRootCauseAnalysis(IncidentReportData?.rootCauseAnalysis));
        }
        if (status === "Investigation Initiated" || status === "Investigation Reinitiated") {
            const WorkflowInputData = {
                Severity: String(IncidentReportData?.secondReviewerRecommendedSeverity),
                "Incident Type": IncidentReportData?.secondReviewerRecommendedIncidentType,
                "Investigation Recommended": IncidentReportData?.investigationRecommended,
                DGMS: IncidentReportData?.srReviewData?.DGMS,
                OISD: IncidentReportData?.srReviewData?.OISD,
                PNGRB: IncidentReportData?.srReviewData?.PNGRB,
                "OISD Fire": IncidentReportData?.srReviewData?.["OISD Fire"],
                "PNGRB Fire": IncidentReportData?.srReviewData?.["PNGRB Fire"],
                "Factories Act": IncidentReportData?.srReviewData?.["Factories Act"],
                "OISD Major Fire": IncidentReportData?.srReviewData?.["OISD Major Fire"],
                "PNGRB Major Fire": IncidentReportData?.srReviewData?.["PNGRB Major Fire"],
                "High Potential Near Miss": IncidentReportData?.srReviewData?.["High Potential Near Miss"]
            };
            dispatch(setWorkflowInputData(WorkflowInputData))
        } else {
            const reviewedData = {
                Severity: String(IncidentReportData?.investigatorRecommendedSeverity),
                "Incident Type": IncidentReportData?.investigatorRecommendedType,
                "Investigation Recommended": IncidentReportData?.investigationRecommended,
                DGMS: IncidentReportData?.invData?.DGMS,
                OISD: IncidentReportData?.invData?.OISD,
                PNGRB: IncidentReportData?.invData?.PNGRB,
                "OISD Fire": IncidentReportData?.invData?.["OISD Fire"],
                "PNGRB Fire": IncidentReportData?.invData?.["PNGRB Fire"],
                "Factories Act": IncidentReportData?.invData?.["Factories Act"],
                "OISD Major Fire": IncidentReportData?.invData?.["OISD Major Fire"],
                "PNGRB Major Fire": IncidentReportData?.invData?.["PNGRB Major Fire"],
                "High Potential Near Miss": IncidentReportData?.invData?.["High Potential Near Miss"]
            };
            let switchStates = {
                IncidentResponsibility: IncidentReportData?.incidentResponsibility?.length > 0 ? 'Yes' : 'No',
                MitigationMeasures: IncidentReportData?.mitigationMeasures?.length > 0 ? 'Yes' : 'No',
                LessonsLearned: IncidentReportData?.lessonsLearned?.length > 0 ? 'Yes' : 'No',
                RootCauseAnalysis: IncidentReportData?.rootCauseAnalysis?.rootCause?.length > 0 ||
                    IncidentReportData?.rootCauseAnalysis?.immediateCause?.length > 0 ||
                    IncidentReportData?.rootCauseAnalysis?.indirectCause?.length > 0 ? 'Yes' : 'No',
                InvestigationEvidence: IncidentReportData?.investigationEvidence?.length > 0 ? 'Yes' : 'No',
                WitnessStatement: IncidentReportData?.witnessStatement?.length > 0 ? 'Yes' : 'No',
                RegulatoryInfo: Object.keys(IncidentReportData?.regulatoryInfo || {}).length > 0 ? 'Yes' : 'No',
                CompensationInfo: IncidentReportData?.compensationInfo?.length > 0 ? 'Yes' : 'No',
                CAPA: IncidentReportData.investigationCAPA?.length > 0 ? 'Yes' : 'No',
                ContributingFactor: IncidentReportData?.contributingFactor?.length > 0 ? 'Yes' : 'No',
            };
            const processedIncidentData = {
                ...IncidentReportData,
                rootCauseAnalysis: { ...IncidentReportData.rootCauseAnalysis },
              
                regulatoryInfo: {
                  ...IncidentReportData.regulatoryInfo,
                  ...(IncidentReportData.regulatoryInfo?.["Date of Immediate last Accident"] && {
                    "Date of Immediate last Accident": convertMillisecondsToDate(
                      IncidentReportData?.regulatoryInfo?.["Date of Immediate last Accident"]
                    ),
                  }),
                },
              
                investigationCAPA: [...IncidentReportData.investigationCAPA],
              
                investigationEvidence: [...IncidentReportData.investigationEvidence],
              
                witnessStatement: IncidentReportData.witnessStatement.map((item) => ({
                  ...item,
                  ...(item?.["Date of Statement"] && {
                    "Date of Statement": convertMillisecondsToDate(item?.["Date of Statement"]),
                  }),
                })),
              
                incidentResponsibility: [...IncidentReportData.incidentResponsibility],
              
                mitigationMeasures: [...IncidentReportData.mitigationMeasures],
              
                lessonsLearned: [...IncidentReportData.lessonsLearned],
              
                compensationInfo: IncidentReportData.compensationInfo.map((item) => ({
                  ...item,
                  ...(item?.["Date Of Return To Duty"] && {
                    "Date Of Return To Duty": convertMillisecondsToDate(item?.["Date Of Return To Duty"]),
                  }),
                  ...(item?.["Date Of Refresher Training"] && {
                    "Date Of Refresher Training": convertMillisecondsToDate(item?.["Date Of Refresher Training"]),
                  }),
                })),
              
                contributingFactor: [...IncidentReportData.contributingFactor]
              };
              
            let incidentData = { ...processedIncidentData, reviewedData, switchStates };
            dispatch(setIncidentData(incidentData))
        }
    }, [IncidentReportData]); // Add IncidentReportData as a dependency


    const handleDrawerOpen = (label) => {
        setDrawerTitle(label);
        setDrawerOpen(true); // Open the drawer
        console.log(label);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false); // Close the drawer
    };



    return (
        <>
            <Grid container spacing={2}>
                {investigationCards?.map((obj, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <Box
                            position="relative"
                            display="flex"
                            alignItems="center"
                            sx={{
                                backgroundColor: "#C7E1F3",
                                minHeight: "56px",
                                borderRadius: "8px",
                                padding: "8px",
                                overflow: "hidden",
                            }}
                        >
                            <Box display='flex' alignItems='center' gap={1} sx={{ zIndex: 10 }} onClick={() => handleDrawerOpen(obj.label)}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        // Use the destructured variables instead of `state`
                                        (
                                            // Check if the label is "Root Cause Analysis"
                                            obj.label === "Root Cause Analysis" ?
                                                (
                                                    switchStates[obj.label.replace(/\s+/g, '')] === "No" ||
                                                    typeof formData[obj.label.replace(/\s+/g, '')] === 'object' &&
                                                    formData[obj.label.replace(/\s+/g, '')] !== null &&
                                                    Object.values(formData[obj.label.replace(/\s+/g, '')]).some(val =>
                                                        Array.isArray(val) && val.length > 0
                                                    )
                                                )
                                                :
                                                obj.label === "Regulatory Info" ?
                                                    (
                                                        switchStates[obj.label.replace(/\s+/g, '')] === "No" ||
                                                        typeof formData[obj.label.replace(/\s+/g, '')] === 'object' &&
                                                        Object.keys(formData[obj.label.replace(/\s+/g, '')]).length > 20
                                                    )
                                                    :
                                                    obj.label === "Workflow Input" ? (
                                                        Object.keys(formData[obj.label.replace(/\s+/g, '')]).length > 1 &&
                                                        formData[obj.label.replace(/\s+/g, '')]["Investigation complete"] == true
                                                    )
                                                        :
                                                        (
                                                            switchStates[obj.label.replace(/\s+/g, '')] === "No" ||
                                                            (
                                                                Array.isArray(formData[obj.label.replace(/\s+/g, '') + "Detail"]) &&
                                                                formData[obj.label.replace(/\s+/g, '') + "Detail"].length > 0
                                                            )

                                                        )
                                        ) ? (
                                            <CheckIcon sx={{ color: '#fff', bgcolor: "#5DF018", fontSize: 13, borderRadius: "50%" }} />
                                        ) : (
                                            ""
                                        )
                                    }

                                >
                                    <Avatar sx={{ bgcolor: 'white', width: 32, height: 32 }}>
                                        {obj.label === 'Mitigation Measures' ? (
                                            <GppMaybeOutlinedIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Incident Responsibility' ? (
                                            <PersonIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Regulatory Info' ? (
                                            <GavelOutlinedIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Compensation Info' ? (
                                            <CurrencyRupeeOutlinedIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Lessons Learned' ? (
                                            <MenuBookOutlinedIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Witness Statement' ? (
                                            <SupervisedUserCircleIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Root Cause Analysis' ? (
                                            <TroubleshootIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Contributing Factor' ? (
                                            <AddToDriveIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Investigation Evidence' ? (
                                            <AddAPhotoIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'CAPA' ? (
                                            <PendingActionsIcon sx={{ color: 'gray' }} />
                                        ) : obj.label === 'Workflow Input' ? (
                                            <CreateOutlinedIcon sx={{ color: 'gray' }} />
                                        ) : (
                                            <></>
                                        )}
                                    </Avatar>
                                </Badge>
                                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                                    {obj.label}
                                </Typography>
                            </Box>

                            <img
                                src={cardHeadBG}
                                alt="bg"
                                style={{
                                    position: "absolute",
                                    bottom: "-460px",
                                    left: "-400px",
                                    width: "1020px",
                                    height: "660px",
                                    transform: "rotate(0deg)",
                                    zIndex: 0,
                                }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <RightDrawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                titles={drawerTitle}
                fields={
                    drawerTitle === "Compensation Info" ? compentionsFormFields : drawerTitle === "Mitigation Measures" ? mitigationFormFields : drawerTitle === "Lessons Learned" ? lessonsLearnedFields : drawerTitle === "CAPA" ? assignCAPAFormFields : drawerTitle === "Root Cause Analysis" ? [] : drawerTitle === "Incident Responsibility" ? incidentResponsibilityfields : drawerTitle === "Witness Statement" ? witnessStatementField : drawerTitle === "Workflow Input" ? workFlowInputDrawerFields : drawerTitle === "Regulatory Info" ? regulatoryFormFields : drawerTitle === "Contributing Factor" ? [] : firstReviwerReturnDrawerFields
                }
                cardData={drawerTitle === "Compensation Info" ? IncidentReportData?.PersonnelDetail : drawerTitle === "CAPA" ? CAPADetail : drawerTitle === "Mitigation Measures" ? MitigationMeasuresDetail : drawerTitle === "Lessons Learned" ? LessonsLearnedDetail : drawerTitle === "Incident Responsibility" ? IncidentResponsibilityDetail : drawerTitle === "Investigation Evidence" ? InvestigationEvidenceDetail : drawerTitle === "Witness Statement" ? WitnessStatementDetail : drawerTitle === "Contributing Factor" ? ContributingFactorDetail : drawerTitle === "Mitigation Measures" ? mitigationFormFields : drawerTitle === "Lessons Learned" ? lessonsLearnedFields : drawerTitle === "CAPA" ? assignCAPAFormFields : []}
                data={drawerTitle === "Review" ? IncidentReportData : drawerTitle === "Compensation Info" ? personData : drawerTitle === "Workflow Input" ? IncidentReportData : ""} // Provide the card data if needed
            />
        </>
    );
};

export default InvestigationCard;
