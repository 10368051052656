import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography, Grid, Button, Chip, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/system";
import { DeleteOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { editDetail, deleteCardDetail } from "../redux/incidentReport/actions"; // Import your action here
import { editInvestFlowCardDetail, deleteInvestCardDetail, editContributingFactorDetail, deleteContributingCardDetail } from "../redux/incidentInvestflow/actions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { cardHeadBG } from "../constant";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import EditNoteIcon from "@mui/icons-material/EditNote";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import { Tooltip } from "@mui/material";
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import ArticleIcon from '@mui/icons-material/Article';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AlertModel from "../Components/AlertModel";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DateRangeIcon from '@mui/icons-material/DateRange';
import YardIcon from '@mui/icons-material/Yard';


const titles = ["Mitigation Measures", "LessonLearned", 'IncidentResponsibility', 'WitnessStatement'];


const CustomBox = ({ children, size = "30px", bgColor = "#F6F6F6" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: bgColor,
      }}
    >
      {children}
    </Box>
  );
};

export default function CardEditDelete({ cardData, title, subtitle }) {
  // console.log("editdeltitle", title);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [name, setName] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedWhyId, setSelectedWhyId] = useState(0);

  // Function to handle chip click
  const handleClick = (id) => {
    setSelectedWhyId(id); // Update selected why ID
  };

  // Slider settings
  const settings = {
    dots: true, // Show dots below the slider
    infinite: false, // Infinite looping of slides
    speed: 400, // Transition speed
    slidesToShow: 1, // Show 2 slides if subtitle, otherwise 1
    slidesToScroll: 1,
    arrows: false
  };

  const handleEditData = (id) => {
    if (titles.includes(title)) {
      dispatch(editInvestFlowCardDetail({ id, title }));
    } else {
      dispatch(editDetail({ id, title }));

    }

  };





  const handleDeleteCard = (id) => {
    setOpen(true);
    setMessage("Are you sure want to delete?");
    setData(id);

  };

  const handleClickImage = (image) => {
    // setSelectedImage(image);
    // setOpenDialog(true);
  };

  const handleProceed = () => {
    const id = data;
    if (id) {
      if (titles.includes(title)) {
        dispatch(deleteInvestCardDetail({ id, title }));
      } else {
        dispatch(deleteCardDetail({ id, title }));

      }
    } else {
      dispatch(deleteContributingCardDetail(name));
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditFactorCard = (name) => {
    // console.log("hhhhh");
    dispatch(editContributingFactorDetail(name));
  };

  const handleDeleteFactorCard = (name) => {
    setOpen(true);
    setMessage("Are you sure want to delete?");
    setName(name);

  };




  const StyledBox = styled(Box)({
    ".slick-dots": {
      position: "absolute",
      bottom: "-20px",
    },
    ".slick-slide": {
      paddingRight: "12px",
      maxHeight: "184px",
    },
    ".slick-list": {
      overflowX: "hidden",
      overflowY: 'visible',
      minHeight: '300px'
    },
    maxWidth: "100%",
    maxHeight: "100%",
    // boxSizing: "border-box",
  });


  const StyledBox2 = styled(Box)({
    ".slick-dots": {
      position: "absolute",
      bottom: "-10px",
    },
    ".slick-slide": {
      paddingRight: "12px",
      maxHeight: "120px",

    },
    ".slick-list": {
      overflowX: "hidden",
      overflowY: 'visible',
      minHeight: '120px',

    },
    ".slick-track": {
      width: "100%",
    },
    maxWidth: "100%",
    maxHeight: "100%",
    // boxSizing: "border-box",
  });


  const renderFile = ({ link, category }) => {
    // console.log(link);

    switch (category) {
      case 'Photo' :

        return (
          <img
            src={link}
            alt='Display image'
            style={{ width: '100%', height: '130px', borderRadius: '4px', cursor: 'pointer' }}
            onClick={() => handleClickImage(link)}  // Open dialog on click
          />
        );
        case 'image/png' || "image/jpg" :

        return (
          <img
            src={link}
            alt='Display image'
            style={{ width: '100%', height: '130px', borderRadius: '4px', cursor: 'pointer' }}
            onClick={() => handleClickImage(link)}  // Open dialog on click
          />
        );
      case 'Video':

        return (
          <video
            controls
            style={{ width: '100%', height: '130px', borderRadius: '4px' }}
          >
            <source src={link} />
            Your browser does not support the video tag.
          </video>
        );
      case 'Document':
        return (
          <a href={link} target="_blank" rel="noopener noreferrer" style={{ display: 'block', width: '100%', height: '130px', borderRadius: '4px' }}>
            <button style={{ width: '100%', height: '100%', borderRadius: '4px' }}>Open PDF</button>
          </a>
        );
      default:
        return null; // Handle unsupported file types if necessary
    }
  };

  // Function to render a single card
  const renderCard = (person, index, title) => (
    <div key={index}>
      {title === "CAPA" ? (
        <>
          <Box
            key={index} // Use index as key or person.id if available
            sx={{
              borderRadius: "8px",
              background: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
              minWidth: "370px",
              maxWidth: "100%",
              height: '300px'
            }}
          >
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                backgroundColor: "#09A2E3",
                minHeight: "56px",
                borderRadius: "8px 8px 0 0",
                padding: "8px",
                overflow: "hidden",
              }}
            >
              <Box display="flex" alignItems="center">
                {/* Profile image */}
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "none",
                    marginRight: "8px",
                    overflow: "hidden",
                  }}
                >
                  {person.image ? (
                    <Box
                      component="img"
                      src={person.image}
                      alt="Profile"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <AccountCircleIcon
                      sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                    />
                  )}
                </Box>
                {/* Title and ID */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  {/* Left Section with CAPA Type, Priority, and Owner */}
                  <Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#ffffff",
                        }}
                      >
                        {person?.["CAPA Type"]}
                      </Typography>
                      {subtitle != "InvestCAPA" &&
                        <Chip
                          label={person?.["CAPA Priority"]}
                          color="error"
                          sx={{
                            fontSize: "10px",
                            fontWeight: "400",
                            height: "20px",
                            padding: "4px 4px",
                          }}
                        />}
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#ffffff",
                      }}
                    >
                      {`Owner: ${person?.["CAPA Owner"] ? person?.["CAPA Owner"] : "N/A"}`}
                    </Typography>
                  </Box>

                  {/* Right Section with Edit and Delete Buttons */}

                </Box>


              </Box>
              <Box display="flex" gap={1}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                    padding: '4px',
                    borderRadius: '5px',
                    minWidth: 'auto',
                    boxShadow: 'none'
                  }}
                  onClick={(e) => {

                    handleEditData(person.id);
                  }}
                >
                  <EditIcon sx={{ color: '#ffffff' }} />
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                    padding: '4px',
                    borderRadius: '5px',
                    minWidth: 'auto',
                    boxShadow: 'none'
                  }}
                  onClick={() => handleDeleteCard(person.id)}
                >
                  <DeleteOutline sx={{ color: '#ffffff' }} />
                </Button>
              </Box>
              <img
                src={cardHeadBG}
                alt="bg"
                style={{
                  position: "absolute",
                  bottom: "-410px",
                  left: "-360px",
                  width: "370px",
                  height: "560px",
                  zIndex: 0,
                }}
              />
            </Box>

            <Box sx={{ padding: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <CustomBox>
                      <DateRangeOutlinedIcon
                        sx={{ width: "20px", height: "20px" }}
                      />
                    </CustomBox>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#222222",
                        }}
                      >
                        {"Due date"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#4D4D4D",
                        }}
                      >
                        {person["Due Date"]
                          ? person["Due Date"]
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* {subtitle === "InvestCAPA" &&
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CustomBox>
                        <DateRangeOutlinedIcon
                          sx={{ width: "20px", height: "20px" }}
                        />
                      </CustomBox>
                      <Box display="flex" flexDirection="column" gap={0.5}>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#222222",
                          }}
                        >
                          Initial Vocational Training
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#4D4D4D",
                          }}
                        >
                          {person["Initial Vocational Training"]
                            ? person["Initial Vocational Training"]
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>} */}

                <Grid item sm={6}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <CustomBox>
                      <LocationOnOutlinedIcon
                        sx={{ width: "20px", height: "20px" }}
                      />
                    </CustomBox>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#222222",
                        }}
                      >
                        Area
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#4D4D4D",
                        }}
                      >
                        {person?.Area ? person?.Area : "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Sub Area
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person?.["Sub Area"] ? person?.["Sub Area"] : "N/A"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={12}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <CustomBox>
                      <DescriptionOutlinedIcon
                        sx={{ width: "20px", height: "20px" }}
                      />
                    </CustomBox>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#222222",
                        }}
                      >
                        CAPA Description
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#4D4D4D",
                        }}
                      >
                        <Tooltip title={person?.["CAPA Description"] || "N/A"}>
                          <span>
                            {person?.["CAPA Description"]
                              ? person?.["CAPA Description"].length > 22
                                ? `${person?.["CAPA Description"].substring(
                                  0,
                                  22
                                )}...`
                                : person?.["CAPA Description"]
                              : "N/A"}
                          </span>
                        </Tooltip>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      ) : title === "Personnel" ? (
        <Box
          key={person.id || index} // Use person.id if available; fallback to index
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "270px"
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                {/* Profile image */}
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "none",
                    marginRight: "8px",
                    overflow: "hidden",
                  }}
                >
                  {person.image ? (
                    <Box
                      component="img"
                      src={person.image}
                      alt="Profile"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <AccountCircleIcon
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#ffffff",
                      }}
                    />
                  )}
                </Box>
                {/* Title and ID */}
                <Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#ffffff",
                      }}
                    >
                      {person.Name}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#ffffff",
                    }}
                  >
                    {person["Staff Type"] + "/" + person.Designation}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={(e) => {

                  handleEditData(person.id);
                }}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={() => handleDeleteCard(person.id)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "370px",
                height: "560px",
                zIndex: 0,
              }}
            />

          </Box>
          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={0.5}>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <ContactPageOutlinedIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Gender
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person.Gender ? person.Gender : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item sm={4}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    Age
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person.Age ? person.Age : "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item sm={3}>
                <Box display="flex" flexDirection="row" alignItems='center' gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    LTI
                  </Typography>
                  <Switch
                    checked={person.LTI === 1}
                    disabled
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "rgba(33, 150, 243, 1)", // Customize the color when ON (checked)
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                      {
                        backgroundColor: "#4caf50", // Customize the track color when ON
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: "rgba(33, 150, 243, 1)", // Customize the track color when OFF
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <DateRangeOutlinedIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      IME Date
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["IME Date"] ? person["IME Date"] : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    PME Date
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person["PME Date"] ? person["PME Date"] : "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    VTC Date
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person["VTC Date"] ? person["VTC Date"] : "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <EmojiPeopleIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Injury Code
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Injury Code"]
                        ? person["Injury Code"]
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    Body Parts Involved
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    <Tooltip title={(person["Body Parts Involved"] && person["Body Parts Involved"].length > 0)
                      ? person["Body Parts Involved"].join(", ")
                      : "N/A"}>
                      <span>
                        {person["Body Parts Involved"] && person["Body Parts Involved"].length > 0
                          ? person["Body Parts Involved"].join(", ").length > 40
                            ? `${person["Body Parts Involved"].join(", ").substring(0, 40)}...`
                            : person["Body Parts Involved"].join(", ")
                          : "N/A"}
                      </span>
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <AccessTimeIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Hours at work
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Hours at work"]
                        ? person["Hours at work"]
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    Form A No.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person["Form A No."]
                      ? person["Form A No."]
                      : "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    Outcome
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person["Outcome"] ? person["Outcome"] : "N/A"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : title === "Vehicle" ? (
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "270px"
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "none",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                {person.image ? (
                  <Box
                    component="img"
                    src={person.image}
                    alt="Profile"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <DirectionsCarOutlinedIcon
                    sx={{
                      width: "100%",
                      height: "100%",
                      color: "#ffffff",
                    }}
                  />
                )}
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  {person["Vehicle Type"]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#ffffff",
                  }}
                >
                  {person["Vehicle Owner"]}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={(e) => {

                  handleEditData(person.id);
                }}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={() => handleDeleteCard(person.id)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "370px",
                height: "560px",
                zIndex: 0,
              }}
            />

          </Box>
          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <TwoWheelerIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Vehicle Type
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Vehicle Type"]
                        ? person["Vehicle Type"]
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    Vehicle No.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person["Vehicle No."]
                      ? person["Vehicle No."]
                      : "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <PersonPinIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Vehicle Owner
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Vehicle Owner"]
                        ? person["Vehicle Owner"]
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    Insurance Status
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person["Insurance Status"]
                      ? person["Insurance Status"]
                      : "N/A"}
                  </Typography>
                </Box>
              </Grid>


              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <EditNoteIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Observation
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      <Tooltip title={person["Observation"] || "N/A"}>
                        <span>
                          {person["Observation"]
                            ? person["Observation"].length > 22
                              ? `${person["Observation"].substring(
                                0,
                                22
                              )}...`
                              : person["Observation"]
                            : "N/A"}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </Box>
      ) : title === "Equipment" ? (
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "270px",
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "none",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                {person.image ? (
                  <Box
                    component="img"
                    src={person.image}
                    alt="Profile"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <ConstructionOutlinedIcon
                    sx={{
                      width: "100%",
                      height: "100%",
                      color: "#ffffff",
                    }}
                  />
                )}
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  {person["Equipment Name"]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#ffffff",
                  }}
                >
                  {person["Equipment Type"]}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={(e) => {

                  handleEditData(person.id);
                }}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={() => handleDeleteCard(person.id)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "370px",
                height: "560px",
                zIndex: 0,
              }}
            />

          </Box>
          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <BuildOutlinedIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Equipment Type
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Equipment Type"]
                        ? person["Equipment Type"]
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "#222222",
                    }}
                  >
                    Equipment Damage
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#4D4D4D",
                    }}
                  >
                    {person["Equipment Damage"]
                      ? person["Equipment Damage"]
                      : "N/A"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <EditNoteIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Equipment Incident Details
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      <Tooltip title={person["Equipment Incident Details"] || "N/A"}>
                        <span>
                          {person["Equipment Incident Details"]
                            ? person["Equipment Incident Details"].length > 22
                              ? `${person["Equipment Incident Details"].substring(
                                0,
                                22
                              )}...`
                              : person["Equipment Incident Details"]
                            : "N/A"}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : title === "Evidence" ? (
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "300px",
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "none",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                {person.Category === "Photo" ? <PhotoCameraBackIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} /> : person.Category === "Video" ? <OndemandVideoIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} /> : <ArticleIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} />
                }

              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  Evidence
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#ffffff",
                  }}
                >
                  {person["Category"]}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={(e) => {

                  handleEditData(person.id);
                }}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={() => handleDeleteCard(person.id)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "370px",
                height: "560px",
                zIndex: 0,
              }}
            />

          </Box>
          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box alignItems="center" width="100%" gap={1}>
                  <StyledBox2>
                    {person.file.length > 1 ? (
                      <Slider {...settings} style={{ width: '100%' }}>
                        {person.file.map((link, index) => (
                          <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {renderFile({ link, category: person.Category })}
                          </Box>
                        ))}
                      </Slider>
                    ) : (
                      person.file.map((link, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          {renderFile({ link, category: person.Category })}
                        </Box>
                      ))
                    )}
                  </StyledBox2>
                </Box>
              </Grid>


              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <EditNoteIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      <Tooltip title={person["Description"] || "N/A"}>
                        <span>
                          {person["Description"]
                            ? person["Description"].length > 22
                              ? `${person["Description"].substring(
                                0,
                                22
                              )}...`
                              : person["Description"]
                            : "N/A"}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : title === "MitigationMeasures" ? (
        <>
          <Box
            key={index} // Use index as key or person.id if available
            sx={{
              borderRadius: "8px",
              background: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
              minWidth: "370px",
              maxWidth: "100%",
              height: '270px'
            }}
          >
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                backgroundColor: "#09A2E3",
                minHeight: "56px",
                borderRadius: "8px 8px 0 0",
                padding: "8px",
                overflow: "hidden",
              }}
            >
              <Box display="flex" alignItems="center">
                {/* Profile image */}
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "none",
                    marginRight: "8px",
                    overflow: "hidden",
                  }}
                >
                  {person.image ? (
                    <Box
                      component="img"
                      src={person.image}
                      alt="Profile"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <AccountCircleIcon
                      sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                    />
                  )}
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#ffffff",
                        }}
                      >
                        {title}
                      </Typography>

                    </Box>
                  </Box>

                </Box>


              </Box>
              <Box display="flex" gap={1}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                    padding: '4px',
                    borderRadius: '5px',
                    minWidth: 'auto',
                    boxShadow: 'none'
                  }}
                  onClick={(e) => {

                    handleEditData(person.id);
                  }}
                >
                  <EditIcon sx={{ color: '#ffffff' }} />
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                    padding: '4px',
                    borderRadius: '5px',
                    minWidth: 'auto',
                    boxShadow: 'none'
                  }}
                  onClick={() => handleDeleteCard(person.id)}
                >
                  <DeleteOutline sx={{ color: '#ffffff' }} />
                </Button>
              </Box>
              <img
                src={cardHeadBG}
                alt="bg"
                style={{
                  position: "absolute",
                  bottom: "-410px",
                  left: "-360px",
                  width: "370px",
                  height: "560px",
                  zIndex: 0,
                }}
              />
            </Box>

            <Box sx={{ padding: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={1}>

                    <Box display="flex" flexDirection="row" gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#222222",
                        }}
                      >
                        {person.id}{"."}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#4D4D4D",
                        }}
                      >
                        {person["Mitigation Measures"]
                          ? person["Mitigation Measures"]
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>


              </Grid>
            </Box>
          </Box>
        </>
      ) : title === "LessonsLearned" ? (
        <>
          <Box
            key={index} // Use index as key or person.id if available
            sx={{
              borderRadius: "8px",
              background: "#FFFFFF",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
              minWidth: "370px",
              maxWidth: "100%",
              height: '270px'
            }}
          >
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                backgroundColor: "#09A2E3",
                minHeight: "56px",
                borderRadius: "8px 8px 0 0",
                padding: "8px",
                overflow: "hidden",
              }}
            >
              <Box display="flex" alignItems="center">
                {/* Profile image */}
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "none",
                    marginRight: "8px",
                    overflow: "hidden",
                  }}
                >
                  {person.image ? (
                    <Box
                      component="img"
                      src={person.image}
                      alt="Profile"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <AccountCircleIcon
                      sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                    />
                  )}
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#ffffff",
                        }}
                      >
                        {title}
                      </Typography>

                    </Box>
                  </Box>

                </Box>


              </Box>
              <Box display="flex" gap={1}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                    padding: '4px',
                    borderRadius: '5px',
                    minWidth: 'auto',
                    boxShadow: 'none'
                  }}
                  onClick={(e) => {

                    handleEditData(person.id);
                  }}
                >
                  <EditIcon sx={{ color: '#ffffff' }} />
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.20)',
                    padding: '4px',
                    borderRadius: '5px',
                    minWidth: 'auto',
                    boxShadow: 'none'
                  }}
                  onClick={() => handleDeleteCard(person.id)}
                >
                  <DeleteOutline sx={{ color: '#ffffff' }} />
                </Button>
              </Box>
              <img
                src={cardHeadBG}
                alt="bg"
                style={{
                  position: "absolute",
                  bottom: "-410px",
                  left: "-360px",
                  width: "370px",
                  height: "560px",
                  zIndex: 0,
                }}
              />
            </Box>

            <Box sx={{ padding: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" gap={1}>

                    <Box display="flex" flexDirection="row" gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#222222",
                        }}
                      >
                        {person.id}{"."}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#4D4D4D",
                        }}
                      >
                        {person["Lessons Learned"]
                          ? person["Lessons Learned"]
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>


              </Grid>
            </Box>
          </Box>
        </>
      ) : title === "IncidentResponsibility" ? (
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "270px"
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "none",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                {person.image ? (
                  <Box
                    component="img"
                    src={person.image}
                    alt="Profile"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <AccountCircleIcon
                    sx={{
                      width: "100%",
                      height: "100%",
                      color: "#ffffff",
                    }}
                  />
                )}
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  {person["Person Responsible"]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#ffffff",
                  }}
                >
                  {person?.["Person Responsible"]} ( {person?.["Designation Code"]})
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={(e) => {

                  handleEditData(person.id);
                }}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={() => handleDeleteCard(person.id)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "370px",
                height: "560px",
                zIndex: 0,
              }}
            />

          </Box>
          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <ContactPageIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Rules Contravened
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Rules Contravened"]
                        ? person["Rules Contravened"]
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>

              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <DateRangeIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Nature of Contravention
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Nature of Contravention"]
                        ? person["Nature of Contravention"]
                        : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <EmojiPeopleIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Action Taken
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      <Tooltip title={person["Action Taken"] || "N/A"}>
                        <span>
                          {person["Action Taken"]
                            ? person["Action Taken"].length > 22
                              ? `${person["Action Taken"].substring(
                                0,
                                22
                              )}...`
                              : person["Action Taken"]
                            : "N/A"}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Box>
        </Box>
      ) : title === "InvestigationEvidence" ? (
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "300px",
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "none",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                {person.Category === "Photo" ? <PhotoCameraBackIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} /> : person.Category === "Video" ? <OndemandVideoIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} /> : <ArticleIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} />
                }

              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  Evidence
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#ffffff",
                  }}
                >
                  {person["Category"]}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={(e) => {

                  handleEditData(person.id);
                }}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={() => handleDeleteCard(person.id)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "370px",
                height: "560px",
                zIndex: 0,
              }}
            />

          </Box>
          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box alignItems="center" width="100%" gap={1}>
                  <StyledBox2>
                    {person.file.length > 1 ? (
                      <Slider {...settings} style={{ width: '100%' }}>
                        {person.file.map((link, index) => (
                          <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {renderFile({ link, category: person.Category })}
                          </Box>
                        ))}
                      </Slider>
                    ) : (
                      person.file.map((link, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          {renderFile({ link, category: person.Category })}
                        </Box>
                      ))
                    )}
                  </StyledBox2>
                </Box>
              </Grid>


              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <EditNoteIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      <Tooltip title={person["Description"] || "N/A"}>
                        <span>
                          {person["Description"]
                            ? person["Description"].length > 22
                              ? `${person["Description"].substring(
                                0,
                                22
                              )}...`
                              : person["Description"]
                            : "N/A"}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : title === "WitnessStatement" ? (
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "300px",
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "none",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                {person.Category === "Photo" ? <PhotoCameraBackIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} /> : person.Category === "Video" ? <OndemandVideoIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} /> : <ArticleIcon sx={{
                  width: "100%",
                  height: "100%",
                  color: "#ffffff",
                }} />
                }

              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  {person?.["Witness Name"]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#ffffff",
                  }}
                >
                  {person?.["Staff Type"]}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={(e) => {

                  handleEditData(person.id);
                }}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none'
                }}
                onClick={() => handleDeleteCard(person.id)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "370px",
                height: "560px",
                zIndex: 0,
              }}
            />

          </Box>
          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box alignItems="center" width="100%" gap={1}>
                  <StyledBox2>
                    {person?.file.length > 1 ? (
                      <Slider {...settings} style={{ width: '100%' }}>
                        {person?.file.map((link, index) => (
                          <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                            {renderFile({ link, category: person.files[index]?.type })}
                          </Box>
                        ))}
                      </Slider>
                    ) : (
                      person?.file.map((link, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          {renderFile({ link, category: person.files[index]?.type })}
                        </Box>
                      ))
                    )}
                  </StyledBox2>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <DateRangeIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Date of Statement
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      {person["Date of Statement"]? person["Date of Statement"]
                            : "N/A"}
                       
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CustomBox>
                    <ContactPageOutlinedIcon
                      sx={{ width: "20px", height: "20px" }}
                    />
                  </CustomBox>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#222222",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#4D4D4D",
                      }}
                    >
                      <Tooltip title={person["Description"] || "N/A"}>
                        <span>
                          {person["Description"]
                            ? person["Description"].length > 22
                              ? `${person["Description"].substring(
                                0,
                                22
                              )}...`
                              : person["Description"]
                            : "N/A"}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : title === "ContributingFactor" ? (
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
            minWidth: "370px",
            maxWidth: "100%",
            height: "270px",
          }}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#09A2E3",
              minHeight: "56px",
              borderRadius: "8px 8px 0 0",
              padding: "8px",
              overflow: "hidden",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "none",
                  marginRight: "8px",
                  overflow: "hidden",
                }}
              >
                {person.image ? (
                  <Box
                    component="img"
                    src={person.image}
                    alt="Profile"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <YardIcon
                    sx={{
                      width: "100%",
                      height: "100%",
                      color: "#ffffff",
                    }}
                  />
                )}
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  {person?.["contributingFactor"]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#ffffff",
                  }}
                >
                  {person?.["reason"]}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none',
                }}
                onClick={(e) => handleEditFactorCard(person.contributingFactor)}
              >
                <EditIcon sx={{ color: '#ffffff' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  padding: '4px',
                  borderRadius: '5px',
                  minWidth: 'auto',
                  boxShadow: 'none',
                }}
                onClick={() => handleDeleteFactorCard(person.contributingFactor)}
              >
                <DeleteOutline sx={{ color: '#ffffff' }} />
              </Button>
            </Box>
            <img
              src={cardHeadBG}
              alt="bg"
              style={{
                position: "absolute",
                bottom: "-410px",
                left: "-360px",
                width: "500px",
                height: "560px",
                zIndex: 0,
              }}
            />
          </Box>

          <Box sx={{ padding: "16px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gap={1}>
                  {person.why.map((data, index) => (
                    <Chip
                      key={index}
                      label={`Why ${index + 1}`} // Label showing "Why 1", "Why 2", etc.
                      onClick={() => handleClick(index)} // Pass the ID on click
                      color={selectedWhyId === index ? 'primary' : 'default'} // Highlight selected chip
                    />
                  ))}
                </Box>


                <Box>
                  {person.why[selectedWhyId] && (
                    <>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Box display="flex" flexDirection="column" gap={1}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#222222",
                              }}
                            >
                              {person.why[selectedWhyId]?.reason}
                            </Typography>

                          </Box>
                        </Box>
                      </Grid>
                      {person.why[selectedWhyId].rootCause.length > 0 &&
                        <Box gap={1}>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "400",
                              color: "#222222",
                            }}
                          >

                            Root Cause
                          </Typography>
                          {person.why[selectedWhyId]?.rootCause?.map((cause, idx) => (
                            <Chip
                              key={idx}
                              label={cause}
                              color="grey"
                              sx={{ margin: "2px" }}
                            />
                          ))}
                        </Box>
                      }



                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Box display="flex" flexDirection="column" gap={0.5}>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "#222222",
                              }}
                            >
                              Comment
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#4D4D4D",
                              }}
                            >
                              <Tooltip title={person.why[selectedWhyId]?.comment || "N/A"}>
                                <span>
                                  {person.why[selectedWhyId]?.comment
                                    ? person.why[selectedWhyId]?.comment.length > 22
                                      ? `${person.why[selectedWhyId]?.comment.substring(0, 22)}...`
                                      : person.why[selectedWhyId]?.comment
                                    : "N/A"}
                                </span>
                              </Tooltip>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Box>
              </Grid>


            </Grid>
          </Box>
        </Box>

      )
        : null}

    </div>
  );

  return (
    <>
      <StyledBox
        sx={{
          backgroundColor: "#EFEFF1",
          padding: "12px",
          borderRadius: "8px",
          height: '360px'
        }}
      >
        <Typography sx={{ color: "#625B71", fontSize: "16px", mb: "8px" }}>
          Preview
        </Typography>
        {cardData.length > 1 ? (
          <Slider {...settings}>
            {cardData.map((person, index) =>
              renderCard(person, index, title)
            )}
          </Slider>
        ) : (

          cardData.map((person, index) =>
            renderCard(person, index, title)
          )
        )}
      </StyledBox>

      <AlertModel
        open={open}
        onClose={handleClose}
        onProceed={handleProceed}
        description={message}

      />
    </>
  );
}
