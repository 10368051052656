import { SET_SELECTED_DATE, SET_SELECTED_TEMPLATE, SET_SELECTED_WORKFLOW } from "./constant";

export const setScheduleSelectedDate = (date) => {
  console.log("date",date); // Log the date to check its value
  return {
    type: SET_SELECTED_DATE,
    payload: date,
  };
};

export const setSelectedTemplate = (template) => {
  return {
    type: 'SET_SELECTED_TEMPLATE',
    payload: template,
  };
};

export const setSelectedWorkflow = (workflow) => {
  return {
    type: 'SET_SELECTED_WORKFLOW',
    payload: workflow,
  };
};

 