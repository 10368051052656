import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changePassword } from '../../Apis/apiCall';
import { encrypt } from '../../utils/encrypt';

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    
    const [isValid, setIsValid] = useState(false);
    const [errors, setErrors] = useState({
        newPassword: '',
        confirmPassword: '',
        currentPassword: ''
    });
  
    const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Validate passwords whenever they change
    useEffect(() => {
        const newErrors = {
            newPassword: '',
            confirmPassword: '',
            currentPassword: ''
        };

        // Check if all fields are filled
        if (currentPassword && newPassword && confirmPassword) {
            // Check if new password matches current password
            if (newPassword === currentPassword) {
                newErrors.newPassword = 'New password must be different from current password';
            }

            // Check if new password and confirm password match
            if (newPassword !== confirmPassword) {
                newErrors.confirmPassword = 'Passwords do not match';
            }
        }

        setErrors(newErrors);

        // Enable submit button if all conditions are met
        setIsValid(
            currentPassword !== '' &&
            newPassword !== '' &&
            confirmPassword !== '' &&
            newPassword !== currentPassword &&
            newPassword === confirmPassword
        );

    }, [currentPassword, newPassword, confirmPassword]);

    const handleSubmit = async() => {
        try {
           await changePassword({oldPassword:encrypt(currentPassword), newPassword:encrypt(newPassword), confirmPassword:encrypt(confirmPassword)}); 
           alert("Password Changed Successfully")
        } catch (error) {
            console.error("error while changing password",error)
        }  
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '80vh',
                margin: 'auto',
                padding: 4,
                border: '1px solid #d9d9d9',
                borderRadius: 2,
                backgroundColor: '#fff'
            }}
        >
            <Typography gutterBottom sx={{ fontWeight: 600, fontSize: '24px' }}>
                Change Password
            </Typography>
            <Typography color="textSecondary" gutterBottom sx={{ color: '#929292', fontSize: '16px', mb: 2 }}>
                We highly recommend you to create a strong password with 8 minimum characters, one upper case letter, one special character (@#$%&)
            </Typography>

            <Box>
                <Typography mt={1}>Current Password</Typography>
                <TextField
                    variant="outlined"
                    sx={{ width: '500px', borderRadius: 1, backgroundColor: '#FAFAFA' }}
                    margin="normal"
                    size="small"
                    placeholder="Enter Current Password"
                    type={showCurrentPassword ? 'text' : 'password'}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    error={!!errors.currentPassword}
                    helperText={errors.currentPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowCurrentPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Box>
                <Typography mt={1}>Create New Password</Typography>
                <TextField
                    variant="outlined"
                    sx={{ width: '500px', borderRadius: 1, backgroundColor: '#FAFAFA' }}
                    margin="normal"
                    placeholder="Enter New Password"
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    size="small"
                    onChange={(e) => setNewPassword(e.target.value)}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Box>
                <Typography mt={1}>Confirm New Password</Typography>
                <TextField
                    variant="outlined"
                    sx={{ width: '500px', borderRadius: 1, backgroundColor: '#FAFAFA' }}
                    margin="normal"
                    size="small"
                    placeholder="Confirm New Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        bgcolor: isValid ? '#1976d2' : '#c7c7c7',
                        color: '#ffffff',
                        '&:hover': {
                            bgcolor: isValid ? '#1565c0' : '#b0b0b0',
                        },
                    }}
                    disabled={!isValid}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
}

export default ChangePassword;