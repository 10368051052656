import React from "react";
import GaugeChart from "react-gauge-chart";
import CustomGauge from "./GaugeMeterTwo";

const styles = {
  dialContainer: {
    display: "inline-block",
    width: "500px",
    height: "auto",
    textAlign: "center",
    color: "#000",
    position: "relative",
  },
  centeredText: {
    position: "absolute",
    top: "70%", // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Align the center correctly
    textAlign: "center",
  },
  gaugeInner: {
    position: "absolute",
    top: "58%", // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Align the center correctly
    textAlign: "center",
  },
  mainValue: {
    fontSize: "32px",
    fontWeight: "bold",
  },
  subValue: {
    fontSize: "16px",
    color: "#555",
  },
};

const Dial = () => {
  const value = 809; // Displayed value
  const maxValue = 1000; // Adjust based on your requirement
  const percent = value / maxValue; // Calculate percentage for GaugeChart

  return (
    <div style={styles.dialContainer}>
      <GaugeChart
        id="gauge-chart4"
        nrOfLevels={50} // Number of thin segments
        arcWidth={0.3} // Thickness of the arc
        colors={["#00FF00", "#FF0000"]} // Gradient from green to red
        percent={percent}
        hideText={true} // Hide the default text inside the gauge
        arcPadding={0.03} // Minimal padding for closer segments
        animate={false} // Static rendering
      />
      {/* Overlayed Text */}
      <div style={styles.centeredText}>
        <div style={styles.mainValue}>{value}</div>
        <div style={styles.subValue}>35/375</div>
      </div>

      <div style={styles.gaugeInner}>
        <CustomGauge />
      </div>

      {/* Hide the needle and central circle */}
      <style>
        {`
          #gauge-chart4 svg .needle,
          #gauge-chart4 svg .central-circle {
            display: none;
          }
        `}
      </style>
    </div>
  );
};

export default Dial;
