import {
  UPDATE_FORM_FIELD,
  ADD_CHECKBOX,
  REMOVE_CHECKBOX,
  UPDATE_DATE_TIME,
  ONCHANGE_DRAWER_FORM,
  ADD_EQUIPMENT_DETAIL,
  ADD_PERSONNEL_DETAIL,
  SET_CAPA_DETAILS,
  ADD_CAPA_DETAIL,
  UPDATE_SWITCH_STATE,
  EDIT_CAPA_DETAIL,
  DELETE_CARD_DETAIL,
  SET_ALL_REPORT_DATA,
  UPDATE_EVIDENCE_FILE,
  RESET_STATE,
  REMOVE_INVESTIGATION_MEMBER,
  SET_REVIEW_DATA
} from './constant';

const initialState = {
  allReportsData: [],
  PersonnelDetail: [],
  CAPADetail: [],
  EvidenceDetail: [],
  Return:{},
  Review:{},
  Assign_Investigation:{},
  switchStates: {
    Investigation: 'Yes',
    CAPA: 'Yes',
  },
  formEdit: {
    Evidence:false,
    CAPA:false,
    Equipment:false,
    Vehicle:false,
    Personnel:false,
  },
  

};
const incidentWorkFlowReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...initialState, // Resets state to initialState
      };


      case SET_REVIEW_DATA:
      return {
        ...state,
        Review: {
          ...state.Review,
          ...action.payload,
        },
      };

    case UPDATE_EVIDENCE_FILE:
      return {
        ...state,
        Evidence: {
          ...state.Evidence,
          file: Array.isArray(state.Evidence.file)
            ? [...state.Evidence.file, action.payload] // Append the new string to the existing array
            : [action.payload], // If file isn't already an array, initialize it as an array with the new string
        },
      };
  
    case UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,  // Dynamically update field
      };

   


    case UPDATE_DATE_TIME:
      return {
        ...state,
        [action.payload.title]: {
          ...state[action.payload.title],
          [action.payload.fieldName]: action.payload.value, // Use fieldName dynamically as key
        }
      };

    // Dynamic case for adding/updating PERSONNEL detail
    case ONCHANGE_DRAWER_FORM:
      return {
        ...state,
        [action.payload.title]: {
          ...state[action.payload.title],
          [action.payload.fieldName]: action.payload.value, // Use fieldName dynamically as key
        }
      };


      case SET_CAPA_DETAILS:
      return {
        ...state,
        CAPADetail: [...action.payload], // store the new CAPADetail array
      };


      case ADD_CAPA_DETAIL: {
        // Find the highest ID in the current CAPADetail array
        const highestId = state.CAPADetail.reduce((maxId, detail) => 
          Math.max(detail.id, maxId), 0); // Get the max id, or 0 if the array is empty
      
        const existingIndex = state.CAPADetail.findIndex(detail => detail.id === action.payload.id);
      
        let updatedCAPADetail;
      
        if (existingIndex !== -1) {
          // Replace the object at the found index with the new object
          updatedCAPADetail = state.CAPADetail.map((detail, index) =>
            index === existingIndex ? { ...detail, ...action.payload } : detail
          );
        } else {
          // Add a new object with id as the highestId + 1
          updatedCAPADetail = [
            ...state.CAPADetail,
            {
              id: highestId + 1, // Increment the highest existing ID
              ...action.payload
            }
          ];
        }
      
        return {
          ...state,
          CAPADetail: updatedCAPADetail,
          formEdit: { ...state.formEdit, CAPA: false }, // Reset form edit state for CAPA
          CAPA: {} // Reset CAPA object
        };
      }


      case REMOVE_INVESTIGATION_MEMBER:
      return {
        ...state,
        Assign_Investigation: {
          ...state.Assign_Investigation,
          "Investigation Members": state.Assign_Investigation["Investigation Members"].filter(member => member !== action.payload),
        },
      };
      
  
      case UPDATE_SWITCH_STATE:
        return {
          ...state,
          switchStates: {
            ...state.switchStates,
            [action.payload.sectionName]: action.payload.value, // Update the specific switch state
          },
        };
  
      case EDIT_CAPA_DETAIL: {
        const { id, title } = action.payload;
        let detailArray;
        switch (title) {
          case 'CAPA':
            detailArray = state.CAPADetail;
            break;
          case 'Personnel':
            detailArray = state.PersonnelDetail;
            break;
         
          case 'Evidence':
            detailArray = state.EvidenceDetail;
            break;
          default:
            detailArray = [];
            break;
        }
  
        const detailObject = detailArray.find(detail => detail.id === id);
  
        return {
          ...state,
          formEdit:{...state.formEdit, [title]:true},
          [title]: title === "Personnel" 
            ? {
                ...state[title],     // Keep the existing data of Personnel
                ...detailObject      // Merge the new data from detailObject
              }
            : detailObject || {}    // For other titles, simply set the detailObject
        };
      };
      
  
   
        case DELETE_CARD_DETAIL: {
          const { id, title } = action.payload;
          let updatedDetailArray;
        
          // Determine which detail array to modify based on the title
          switch (title) {
            case 'CAPA':
              updatedDetailArray = state.CAPADetail.filter(detail => detail.id !== id);
              return {
                ...state,
                CAPADetail: updatedDetailArray, // Update CAPADetail
              };
        
            case 'Personnel':
              updatedDetailArray = state.PersonnelDetail.filter(detail => detail.id !== id);
              return {
                ...state,
                PersonnelDetail: updatedDetailArray, // Update PersonnelDetail
              };
        
            case 'Evidence':
              updatedDetailArray = state.EvidenceDetail.filter(detail => detail.id !== id);
              return {
                ...state,
                EvidenceDetail: updatedDetailArray, // Update EvidenceDetail
              };
        
            default:
              return state; // Return the current state if the title doesn't match any case
          }
        }
        

    // ADD_PERSONNEL_DETAIL case
    // case ADD_PERSONNEL_DETAIL: {
    //   const noOfPeople = state.Personnel?.["No. of People"] || 0;
    //   const currentPersonnelDetailCount = state.PersonnelDetail.length;
    
     
    //   const existingIndex = state.PersonnelDetail.findIndex(detail => detail.id === action.payload.id);
    //   if (existingIndex === -1 && currentPersonnelDetailCount >= noOfPeople) {
    //     return state; // Prevent adding new personnel details if limit is reached
    //   }

    //   let updatedPersonnelDetail;

    //   if (existingIndex !== -1) {
    //     // Replace existing object
    //     updatedPersonnelDetail = state.PersonnelDetail.map((detail, index) =>
    //       index === existingIndex ? { ...detail, ...action.payload } : detail
    //     );
    //   } else {
    //     // Add new object
    //     updatedPersonnelDetail = [
    //       ...state.PersonnelDetail,
    //       {
    //         id: state.PersonnelDetail.length + 1, // Increment ID based on array length
    //         ...action.payload
    //       }
    //     ];
    //   }

    //   const retainedPersonnel = Object.keys(state.Personnel || {}) // Ensure state.PERSONNEL is not null or undefined
    //   .filter(key => [
    //     "No. of People",
    //     "Injury Count",
    //     "Fatality Count",
    //     "Location of Accident"
    //   ].includes(key))
    //   .reduce((acc, key) => ({ ...acc, [key]: state.Personnel[key] }), {});
    
    // return {
    //   ...state,
    //   PersonnelDetail: updatedPersonnelDetail,
    //   formEdit:{...state.formEdit, Personnel:false},
    //   Personnel: retainedPersonnel // Retain only specific keys, resetting the rest
    // };
    
    // }

   

    // ADD_EVIDENCE_DETAIL case
    // case ADD_EVIDENCE_DETAIL: {
    //   const existingIndex = state.EvidenceDetail.findIndex(detail => detail.id === action.payload.id);

    //   let updatedEvidenceDetail;

    //   if (existingIndex !== -1) {
    //     // Replace existing object
    //     updatedEvidenceDetail = state.EvidenceDetail.map((detail, index) =>
    //       index === existingIndex ? { ...detail, ...action.payload } : detail
    //     );
    //   } else {
    //     // Add new object
    //     updatedEvidenceDetail = [
    //       ...state.EvidenceDetail,
    //       {
    //         id: state.EvidenceDetail.length + 1, // Increment ID based on array length
    //         ...action.payload
    //       }
    //     ];
    //   }

    //   return {
    //     ...state,
    //     EvidenceDetail: updatedEvidenceDetail,
    //     formEdit:{...state.formEdit, Evidence:false},
    //     Evidence: {} // Reset Evidence object
    //   };
    // }


     // case ADD_CHECKBOX:
    //   if (!state.GeneralInfo.includes(action.payload)) {
    //     return {
    //       ...state,
    //       GeneralInfo: [...state.GeneralInfo, action.payload],
    //     };
    //   }
    //   return state;

    // case REMOVE_CHECKBOX:
    //   return {
    //     ...state,
    //     GeneralInfo: state.GeneralInfo.filter(item => item !== action.payload),
    //   };
    //   case SET_ALL_REPORT_DATA:
    //     return {
    //         ...state,
    //         allReportsData: action.payload
    //     };


   
    

    default:
      return state;
  }
};

export default incidentWorkFlowReducer;
