import React, { useEffect, useState } from 'react';  
import { Container, Box, Typography, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { updateDashboardLocation } from "../redux/LossControl/actions";
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const TreeNode = ({ node, level = 0, selectedNodeID, onNodeSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const isSelected = node.id === selectedNodeID;

    const handleToggle = () => {
        setIsOpen(!isOpen);
        onNodeSelect(node);
    };

    const hasChildren = Array.isArray(node.children) && node.children.length > 0;

    return (
        <Box sx={{ml:1, position: 'relative' }}>
            {level > 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '-25px',
                        height: '100%',
                        borderLeft: '1px dashed #ccc',
                        zIndex: 0,
                    }}
                />
            )}
            <Box
                onClick={handleToggle}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '4px 0',
                    position: 'relative',
                    backgroundColor: isSelected ? '#7fcced' : 'transparent',
                    borderRadius: '4px',
                }}
            >
                {level > 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '-25px',
                            top: '14px',
                            width: '25px',
                            borderBottom: '1px dashed #ccc',
                        }}
                    />
                )}
                <Box 
                    sx={{  
                        width: 16, 
                        height: 16, 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...(hasChildren 
                            && {
                                fontSize: '12px',
                                color: isSelected ?'#000':'#666',
                                backgroundColor: 'transparent',
                            }
                        ),
                    }}
                >
                    {hasChildren && (isOpen ?  <ExpandLess /> : <ExpandMore/>)}
                </Box>
                <Typography variant="body1" sx={{ color: '#333', ml: 1, fontWeight: isSelected ? 'bold' : 'normal' }}>
                    {node.displayName} - (L{node.orgLevel})
                </Typography>
            </Box>
            {isOpen && hasChildren && (
                <Box>
                    {node.children.map((childNode) => (
                        <TreeNode 
                            key={childNode.id} 
                            node={childNode} 
                            level={level + 1}
                            selectedNodeID={selectedNodeID}
                            onNodeSelect={onNodeSelect}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

const OrgTreeView = ({ data }) => {
    const dispatch = useDispatch();
    const [selectedNodeID, setSelectedNodeID] = useState(null);
    const [locationCodes, setLocationCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Recursive function to gather all IDs with non-null locationCode from the node and its children
    const gatherLocationCodes = (node) => {
        let codes = [];
    
        // Collect ID only if locationCode is not null
        if (node.locationCode) {
            codes.push(node.id);
        }
    
        // Recursively gather IDs from children with non-null locationCode
        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => {
                codes = codes.concat(gatherLocationCodes(child));
            });
        }
        return codes;
    };

    const handleNodeSelect = (node) => {
        setSelectedNodeID(node.id);
        
        // Gather location codes from selected node and all its descendants
        const codes = gatherLocationCodes(node);
        setLocationCodes(codes);
        dispatch(updateDashboardLocation(codes));
    };

    // Set the first node as selected by default on initial render
    useEffect(() => {
        if (data.length > 0) {
            handleNodeSelect(data[0]);
        }
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        {data.map((node) => (
                            <TreeNode 
                                key={node.id} 
                                node={node} 
                                selectedNodeID={selectedNodeID}
                                onNodeSelect={handleNodeSelect}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default OrgTreeView;
