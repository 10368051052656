import React from 'react';
import { Box } from '@mui/material';

export default function SeverityTag({severity}) {
  return (
    <div>
        {
            severity === "1" ? (
                <Box sx={{ color: '#FFFFFF', backgroundColor: '#FC5858', fontSize: "14px", fontWeight: '500', width: '24px', height: '24px', borderRadius:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>{severity}</Box>
            ) : 
            severity === "2" ? (
                <Box sx={{ color: '#FFFFFF', backgroundColor: '#E53939', fontSize: "14px", fontWeight: '500', width: '24px', height: '24px', borderRadius:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>{severity}</Box>
            ) : 
            severity === "3" ? (
                <Box sx={{ color: '#FFFFFF', backgroundColor: '#CC1F1F', fontSize: "14px", fontWeight: '500', width: '24px', height: '24px', borderRadius:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>{severity}</Box>
            ) : 
            severity === "4" ? (
                <Box sx={{ color: '#FFFFFF', backgroundColor: '#B20909', fontSize: "14px", fontWeight: '500', width: '24px', height: '24px', borderRadius:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>{severity}</Box>
            ) : (
                <Box sx={{ color: '#FFFFFF', backgroundColor: '#921010', fontSize: "14px", fontWeight: '500', width: '24px', height: '24px', borderRadius:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0}}>{severity}</Box>
            )
        }
        
    </div>
  )
}
