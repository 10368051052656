import React from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PreviewIcon from '@mui/icons-material/Preview';
import WestIcon from "@mui/icons-material/West";

export default function AuditViewStepperBox() {
  const steps = [
    { label: "Scheduled", status: "completed" },
    { label: "Conducted", status: "active" },
    { label: "Reviews", status: "review" },
    { label: "Actions", status: "inactive" },
    { label: "Compliance", status: "inactive" },
    { label: "Closed", status: "inactive" },
  ];

  const getStepIcon = (status) => {
    switch (status) {
      case "completed":
        return <CheckCircleOutlineIcon sx={{ color: "#4591F5" }} />;
      case "active":
        return <RadioButtonCheckedIcon sx={{ color: "#FFAC07" }} />;
      case "review": // Fixed 'Reviews' to 'review' to match status
        return <WestIcon sx={{ color: "#B7C8E5" }} />;
      default:
        return <PreviewIcon sx={{ color: "#B7C8E5" }} />;
    }
  };
  

  return (
    <Box
      sx={{
        width: "100%",
        padding: "24px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        position: "relative",
        pt: 3,
        marginBottom: "20px",
        marginTop: "10px",
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={1}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          transform: "translateY(-26%)",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          "& .MuiStepConnector-root": {
            flex: "1 1 0",
            height: "2px",
            backgroundColor: "rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={() => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {getStepIcon(step.status)}
                </Box>
              )}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#525966",
                  textAlign: "center",
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box display="flex" justifyContent="space-between" alignItems="center"sx={{marginTop:'20px'}}>
        <Typography
          sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
        >
          Audit Scheduled / Scheduler
        </Typography>
        <Typography variant="body2" sx={{ color: "#222",fontWeight: "400" }}>
          Audit Date: <span sx={{color:'#525966', fontWeight: "400"}}>24-05-24 / 1:23 PM</span>
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body2" sx={{color:'#525966', fontWeight: "400"}}>
          Ashish Patel / AshishPatel@ieddigital.com
        </Typography>
        <Typography variant="body2" sx={{color:'#525966', fontWeight: "400"}}>
          Remarks: Ashish Patel
        </Typography>
      </Box>

      {/* <Box px={1} display="flex" flexDirection="column" gap={0.5} mt={3}>
        <Typography
          sx={{ color: "#525966", fontSize: "16px", fontWeight: "600" }}
        >
          Audit Scheduled / Scheduler
        </Typography>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="body2" sx={{ color: "#525966" }}>
            Ashish Patel / AshishPatel@ieddigital.com
          </Typography>
          <Typography variant="body2" sx={{ color: "#525966" }}>
            Remarks: Ashish Patel
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
}
