import React, { useState, useEffect } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomInput from "../CustomInputField";
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import { revalidateTask } from "../../Apis/apiCall";
import ConfirmationModal from "./ConfirmationModal";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant"
 
export default function IMEditCommentDrawer({ open, onClose, title, quesData, reportID, tourDate }) {

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);

  // Initialize state with empty values  
  const [completionDate, setCompletionDate] = useState(null);
  const [actionTaken, setActionTaken] = useState("");
  const [imEvidence, setImEvidence] = useState([]);

  // useEffect to set initial values when quesData changes
  useEffect(() => {    
    setCompletionDate(quesData?.completionDate || null);
    setActionTaken(quesData?.actionTaken || "");
    setImEvidence(quesData?.imEvidence || []);
  }, [quesData]);

  const handleDateChange = (newValue) => {
    // Set date back to epoch format on change
    setCompletionDate(newValue ? newValue.valueOf() : null);
  };

  const handleEvidenceUpload = async (e) => {
    const files = Array.from(e.target.files);

    const newEvidence = await Promise.all(
      files.map(async (file) => {
        const base64 = await convertToBase64(file);
        return { name: file.name, type: file.type, base64 : base64.split(",")[1] };
      })
    );

    setImEvidence((prevEvidence) => [...prevEvidence, ...newEvidence]);
  };

  const handleFileDelete = (file) => {
    // console.log("filedelete",file);
    setImEvidence((prevImEvidence) => 
      prevImEvidence.filter((item) => item !== file)
    );
  };
console.log("imEvidencedeeee",imEvidence);
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      // Compress the file before converting it to base64
      new Compressor(file, {
        quality: 0.2, // Set the compression quality
        success(compressedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  const handleSubmit = async () => {
    const updatedData = {
      reportID: reportID,
      questionID: quesData.id,
      actionTaken: actionTaken,
      completionDate: completionDate,
      imEvidence: imEvidence,
    };

    // console.log("imdatas",updatedData);
    dispatch({type : START_LOADING})
    try {
      // Call the API with the correct data structure
      await revalidateTask(updatedData);
      onClose(); // Close the modal or dialog after a successful API call
      window.location.reload();
    //   console.log("Task revalidated successfully");
    } catch (error) {
      console.error("Error revalidating task:", error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const openConfirmationModal = () => setIsConfirmationModalOpen(true);
  const closeConfirmationModal = () => setIsConfirmationModalOpen(false);

  const handleConfirm = () => {
    closeConfirmationModal(); // Close the confirmation modal first
    handleSubmit(); // Then execute the original submit logic
  };

//   console.log("imEvidence",imEvidence);

  return (
    <>
    {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
      <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "36%", padding: "0px !important" } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          {title || ""}
        </Typography>
      </Grid>
      <Box p={2}>
        <Box my={2} p={3} border="1px solid #ddd" sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }} borderRadius={4}>
          <Box mb={2}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {(quesData?.question || "").replace(/_/g, " ")}
            </Typography>
            <Box mb={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Completion Date"
                    value={completionDate ? dayjs(completionDate) : dayjs()}
                    onChange={(newValue) => handleDateChange(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    format ="DD-MM-YYYY"
                    minDate={dayjs(tourDate)}
                    maxDate={dayjs()}
                />
            </LocalizationProvider>
            </Box>
            <Box mb={2}>
            <CustomInput
              placeholder="type here..."
              label="Action Taken"
              multiline
              rows={3}
              value={actionTaken}
              onChange={(e) => setActionTaken(e.target.value)}
            />
            </Box> 
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography gutterBottom sx={{ fontSize: "14px", fontWeight: "600" }}>Evidence</Typography>
            <Box sx={{
        display: "flex", flexDirection: "column", alignItems: "center",
        border: "1px dashed #09A2E3", padding: 2, borderRadius: 1, textAlign: "center", width: "100%"
      }}>
        <IconButton component="label">
          <UploadIcon fontSize="large" sx={{ color: "#000000" }} />
          <input hidden accept="image/*,.doc,.pdf,.mp4" type="file" onChange={handleEvidenceUpload} multiple />
        </IconButton>
        <Typography variant="caption" sx={{ mt: 1, color: "#348BD2", fontWeight: "600", textDecoration: "underline" }}>
          Click to upload
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: "600" }}>
          PNG, JPG, Docx, PDF, and MP4 (max. 10 MB)
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2, gap: 1 }}>
        {imEvidence?.map((file, index) => (
          <Box key={index} sx={{ position: "relative", width: 80, height: 80 }}>
            <IconButton onClick={() => handleFileDelete(file)} sx={{ position: "absolute", top: -8, right: -8, zIndex: 2, backgroundColor: "white" }} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box sx={{ width: "100%", height: "100%", borderRadius: 2, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #ccc" }}>
              {file && typeof file === "string" ? (
                <img src={file} alt={`evidence-${index}`} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
              ) : (
                file && file.base64 && (
                  <img 
                    src={`data:${file.type || "image/jpeg"};base64,${file.base64}`} 
                    alt={file.name || `evidence-${index}`} 
                    style={{ width: "100%", height: "100%", objectFit: "cover" }} 
                  />
                )
              )}
            </Box>
          </Box>
        ))}
      </Box>

          </Box>
        </Box>
        <Grid item xs={12} mt={1}>
          <Button variant="contained" onClick={openConfirmationModal} sx={{ float: "right", color: '#ffffff' }}>Update</Button>
        </Grid>
      </Box>
      <Fab aria-label="close" onClick={onClose} sx={{
        position: "fixed", top: "100px", right: open ? "37.3%" : "0", transform: "translateX(50%)",
        zIndex: 1200, height: "36px", width: "36px", backgroundColor: "#0AA2E3", borderRadius: "60px 0 0 60px"
      }}>
        <CloseIcon />
      </Fab>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title="Confirm Action"
        message="Are you sure you want to update this?"
        onCancel={closeConfirmationModal}
        onConfirm={handleConfirm}
      />
    </Drawer>

    </>
    
  );
}
