import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  LinearProgress,
  Card,
  CardContent,
  Tabs,
  Tab,
  Select,
  MenuItem,
  useTheme,
  Paper,
  InputAdornment,
  Checkbox,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EastIcon from "@mui/icons-material/East";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import GaugeMeter from "../../Components/ChartAndGraphs/GagugeMeter";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import SearchIcon from "@mui/icons-material/Search";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { disciplineCover, disciplineIconBG, sectionIcon } from "../../constant";
import AuditViewStepperBox from "../../Components/Audit/AuditViewStepperBox";
import AuditVerticalStepperStatus from "../../Components/Audit/AuditVerticalStepperStatus";
import QuestionnairSection from "../../Components/Audit/Questionnair/QuestionnairSection";


// CustomTab style
const CustomTab = styled(Tab)(({ theme }) => ({
  background: "none", // Remove background
  color: "#000000",
  "&.Mui-selected": {
    background: "none", // Ensure no background for the selected tab as well
    color: "#000000", // Optional: Change selected tab text color
    position: "relative", // Required for ::after positioning
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: "8px 8px 0px 0px",
      height: "6px", // Thickness of the underline
      backgroundColor: "#09a2e3", // Color of the underline
    },
  },
  "&:hover": {
    background: "none", // Remove hover background
  },
  textTransform: "none", // Prevent uppercase
  fontWeight: 600,
}));

const AuditCreateDashboard = () => {
  const tabsRef = useRef(null);
  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [section, setSection] = useState("0");
  const [showGrid, setGrid] = useState(true);
  const [isLeftHidden, setIsLeftHidden] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showStepper, setShowStepper] = useState(true);
  // Actions
  const [actionFirstFilter, setActionFirstFilter] = useState("All");
  const [currentActionCols, setCurrentActionCols] = useState([]);
  const [currentActionData, setCurrentActionData] = useState(null);
  // Attachments
  const [attachmentsFilter, setAttachmentsFilter] = useState("CAPA");
  const [clickedCard, setClickedCard] = useState(null);
  const [questionnaireSection, SetQuestionnaireSection] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  const handleIconClick = () => {
    setIsLeftHidden(!isLeftHidden);
  };

  const StyledTabs = styled(Tabs)({
    minHeight: "48px",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  });

  const StyledTab = styled(Tab)(({ theme, selected }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "40px",
    background: selected ? "#FCFCFC;" : "#FCFCFC;", // Background changes based on selection
    color: "#000",
    fontSize: "16px",
    fontWeight: "600",
    padding: "8px 16px",
    textTransform: "none",
    marginLeft: "10px",
    width: "150px",
    "& .MuiTab-iconWrapper": {
      display: "flex",
      alignItems: "center",
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const data = [
    {
      name: "Jan", // Representing January
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Feb", // Representing February
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar", // Representing March
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Apr", // Representing April
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May", // Representing May
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jun", // Representing June
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Jul", // Representing July
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const SectionName = ({ section, setSection }) => {
    return (
      <div
        style={{
          marginBottom: "20px",
          border: "none",
          outLine: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Select
          labelId="section-select-label"
          value={section}
          onChange={(e) => setSection(e.target.value)}
          sx={{
            width: "150px",
            marginBottom: "10px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Removes the border
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none", // Removes border on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none", // Removes border when focused
            },
          }}
        >
          <MenuItem value="0">Section Name</MenuItem>
          <MenuItem value="1">Section 1</MenuItem>
          <MenuItem value="2">Section 2</MenuItem>
        </Select>
        <div>
          <KeyboardArrowLeftIcon />
          <KeyboardArrowRightIcon />
        </div>
      </div>
    );
  };

  const legendItems = [
    { color: "green", label: "Answered" },
    { color: "red", label: "Action Present" },
    { color: "gray", label: "Not Answered" },
  ];

  const circles = [
    [
      "#FF5151",
      "#05FF00",
      "#05FF00",
      "#05FF00",
      "#05FF00",
      "#05FF00",
      "#05FF00",
      "#05FF00",
      "#05FF00",
      "#05FF00",
    ],
    [
      "#05FF00",
      "#05FF00",
      "#FF5151",
      "#FF5151",
      "#FF5151",
      "#FF5151",
      "#05FF00",
      "#05FF00",
      "#05FF00",
      "#05FF00",
    ],
    [
      "#D9D9D9",
      "#D9D9D9",
      "#D9D9D9",
      "#D9D9D9",
      "#FF5151",
      "#D9D9D9",
      "#D9D9D9",
      "#D9D9D9",
      "#D9D9D9",
      "#D9D9D9",
    ],
  ];

  const handleCardClick = (index) => {
    setClickedCard(index); // Set the clicked card index
    alert("Hello"); // Show a "Hello" message on click
    setIsLeftHidden(!isLeftHidden);
    SetQuestionnaireSection(!questionnaireSection);
    setGrid(!showGrid); // Toggle grid visibility
  };

  // Section Cards
  const cards = Array.from({ length: 12 }, (_, index) => (
    <Box
      key={index}
      onClick={() => handleCardClick(index)} // Attach click handler here
      sx={{
        position: "relative",
        borderRadius: "8px",
        border: "0.5px solid #B7C8E5",
        padding: "16px",
        backgroundImage: `url(${disciplineCover})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          width: "52px",
          height: "52px",
          backgroundImage: `url(${disciplineIconBG})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CategoryIcon sx={{ color: "#09A2E3" }} />
      </Box>
      <Typography
        sx={{
          color: "#000000",
          fontSize: "12px",
          marginTop: "8px",
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        H2S Hazard Control (Where Applicable) Applicable / Not Applicable
      </Typography>
    </Box>
  ));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // --------------Actions Begin---------------
  const allActions = [
    "Question ID",
    "Audit Type",
    "Recommendation",
    "Observation",
    "Assigned By",
    "Due Date",
    "CAPA Owner",
    "Status",
    "Comments",
    "Evidence",
  ];
  const allActionsCollapsed = [
    "Question ID",
    "Recommendation",
    "Observation",
    "Due Date",
    "Status",
  ];
  const allActionsData = [
    {
      "Question ID": "ENV-1",
      "Audit Type": "Type 1",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      "Due Date": "29/11/2024",
      "CAPA Owner": "Depanshu",
      Status: "Active",
      Comments: "Lorem Ipsumm",
      Evidence: "Eviddd 1",
    },
    {
      "Question ID": "ENV-2",
      "Audit Type": "Type 2",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      "Due Date": "29/11/2024",
      "CAPA Owner": "Depanshu",
      Status: "Active",
      Comments: "Lorem Ipsumm",
      Evidence: "Eviddd 2",
    },
    {
      "Question ID": "ENV-3",
      "Audit Type": "Type 3",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      "Due Date": "29/11/2024",
      "CAPA Owner": "Depanshu",
      Status: "Active",
      Comments: "Lorem Ipsumm",
      Evidence: "Eviddd 3",
    },
    {
      "Question ID": "ENV-4",
      "Audit Type": "Type 4",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      "Due Date": "29/11/2024",
      "CAPA Owner": "Depanshu",
      Status: "Active",
      Comments: "Lorem Ipsumm",
      Evidence: "Eviddd 4",
    },
  ];

  const questionActions = [
    "Question ID",
    "Audit Type",
    "Recommendation",
    "Observation",
    "Assigned By",
    "Total",
    "Complete",
    "Overdue",
    "Max Due Date",
  ];
  const questionActionsCollapsed = [
    "Question ID",
    "Recommendation",
    "Observation",
    "Total",
    "Overdue",
  ];
  const questionActionsData = [
    {
      "Question ID": "ENV-1",
      "Audit Type": "Type 1",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      Total: 47,
      Complete: 34,
      Overdue: 13,
      "Max Due Date": "29/12/2024",
    },
    {
      "Question ID": "ENV-2",
      "Audit Type": "Type 2",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      Total: 47,
      Complete: 34,
      Overdue: 13,
      "Max Due Date": "29/12/2024",
    },
    {
      "Question ID": "ENV-3",
      "Audit Type": "Type 3",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      Total: 47,
      Complete: 34,
      Overdue: 13,
      "Max Due Date": "29/12/2024",
    },
    {
      "Question ID": "ENV-4",
      "Audit Type": "Type 4",
      Recommendation: "Lorem Ipsum Dolor",
      Observation: "Lorem Ipsum Dolor",
      "Assigned By": "Dipanshu",
      Total: 47,
      Complete: 34,
      Overdue: 13,
      "Max Due Date": "29/12/2024",
    },
  ];
  const sectionActions = [
    "Section Name",
    "Audit Type",
    "Assigned By",
    "Total",
    "Complete",
    "Overdue",
    "Max Due Date",
  ];
  const sectionActionsCollapsed = [
    "Section Name",
    "Audit Type",
    "Assigned By",
    "Total",
    "Overdue",
  ];
  const sectionActionsData = [
    {
      "Section Name": "Lorem Ipsum 1",
      "Audit Type": "ENV-1",
      "Assigned By": "Dipanshu",
      Total: 39,
      Complete: 22,
      Overdue: 17,
      "Max Due Date": "24/12/2024",
    },
    {
      "Section Name": "Lorem Ipsum 2",
      "Audit Type": "ENV-2",
      "Assigned By": "Dipanshu",
      Total: 39,
      Complete: 22,
      Overdue: 17,
      "Max Due Date": "24/12/2024",
    },
    {
      "Section Name": "Lorem Ipsum 3",
      "Audit Type": "ENV-3",
      "Assigned By": "Dipanshu",
      Total: 39,
      Complete: 22,
      Overdue: 17,
      "Max Due Date": "24/12/2024",
    },
    {
      "Section Name": "Lorem Ipsum 4",
      "Audit Type": "ENV-4",
      "Assigned By": "Dipanshu",
      Total: 39,
      Complete: 22,
      Overdue: 17,
      "Max Due Date": "24/12/2024",
    },
  ];

  const handleActionColsFilterChange = (event) => {
    const value = event.target.value;

    setCurrentActionCols(
      typeof value === "string" ? value.split(",") : value // Ensures compatibility with the multiple select
    );
  };

  useEffect(() => {
    if (actionFirstFilter === "All") {
      setCurrentActionData(allActionsData);
      if (isLeftHidden) {
        setCurrentActionCols(allActions);
      } else {
        setCurrentActionCols(allActionsCollapsed);
      }
    } else if (actionFirstFilter === "Question") {
      setCurrentActionData(questionActionsData);
      if (isLeftHidden) {
        setCurrentActionCols(questionActions);
      } else {
        setCurrentActionCols(questionActionsCollapsed);
      }
    } else if (actionFirstFilter === "Section") {
      setCurrentActionData(sectionActionsData);
      if (isLeftHidden) {
        setCurrentActionCols(sectionActions);
      } else {
        setCurrentActionCols(sectionActionsCollapsed);
      }
    }
  }, [actionFirstFilter, isLeftHidden]);
  // --------------Actions End---------------------

  // --------------Attachments Begin---------------
  const attachmentsCol = [
    "Category",
    "Section Name",
    "Title",
    "Attachment Details",
    "Uploaded By",
  ];

  const attachmentsData = [
    {
      Category: "ENV-1",
      "Section Name": "Lorem 1",
      Title: "Lorem Ipsum 1",
      "Attachment Details": "Attachment 1",
      "Uploaded By": "Dipanshu",
    },
    {
      Category: "ENV-2",
      "Section Name": "Lorem 2",
      Title: "Lorem Ipsum 2",
      "Attachment Details": "Attachment 2",
      "Uploaded By": "Sarthak",
    },
    {
      Category: "ENV-3",
      "Section Name": "Lorem 3",
      Title: "Lorem Ipsum 3",
      "Attachment Details": "Attachment 3",
      "Uploaded By": "Ayush",
    },
    {
      Category: "ENV-4",
      "Section Name": "Lorem 4",
      Title: "Lorem Ipsum 4",
      "Attachment Details": "Attachment 4",
      "Uploaded By": "Rahul",
    },
  ];

  // --------------Attachments End---------------

  // --------------Notes Begin-------------------
  const notesCol = ["Date", "Notes Title", "Notes Summary", "User"];
  const notesData = [
    {
      Date: "21/11/2024",
      "Notes Title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Notes Summary":
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      User: "Dipanshu",
    },
    {
      Date: "22/11/2024",
      "Notes Title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Notes Summary":
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      User: "Sarthak",
    },
    {
      Date: "23/11/2024",
      "Notes Title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Notes Summary":
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      User: "Rahul",
    },
    {
      Date: "24/11/2024",
      "Notes Title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Notes Summary":
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      User: "Ayush",
    },
  ];

  // --------------Notes End---------------------
  const tabData = [
    {
      label: 'Section 1',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 2',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 3',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 4',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
    {
      label: 'Section 5',
      icon: <CircleIcon sx={{ color: '#D9D9D9' }} />,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* First Left side Grid Item */}
          {!isLeftHidden && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                {/* Header part start */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" }, // Column for smaller screens, row for larger
                    alignItems: { xs: "flex-start", sm: "center" }, // Adjust alignment for smaller screens
                  }}
                >
                  <Typography variant="h6">
                    Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#09A2E3",
                      color: "#09A2E3",
                      textTransform: "none",
                      fontWeight: "400",
                      borderRadius: "4px",
                      background: "rgba(9, 162, 227, 0.20)",
                      fontSize: "12px",
                      lineHeight: "18px",
                      "&:hover": {
                        backgroundColor: "rgba(9, 162, 227, 0.20)", // Same as the initial background
                        boxShadow: "none", // Disable hover shadow
                      },
                    }}
                  >
                    In Progress
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px",
                    flexDirection: { xs: "column", sm: "row" }, // Column for smaller screens, row for larger
                    alignItems: { xs: "flex-start", sm: "center" }, // Adjust alignment for smaller screens
                  }}
                >
                  {/* Scheduler Information */}
                  <Typography
                    sx={{
                      color: "#222",
                      fontSize: { xs: "14px", sm: "16px" }, // Smaller font size for smaller screens
                      fontWeight: "400",
                      marginBottom: { xs: "8px", sm: "0" }, // Add margin for spacing in smaller screens
                    }}
                  >
                    Scheduler:{" "}
                    <span
                      style={{
                        color: "#525966",
                        fontSize: "inherit", // Inherit parent size
                        fontWeight: "400",
                      }}
                    >
                      Ashish Patel
                    </span>
                  </Typography>

                  {/* Audit Date Information */}
                  <Typography
                    sx={{
                      color: "#222",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "400",
                    }}
                  >
                    Audit Date:{" "}
                    <span
                      style={{
                        color: "#525966",
                        fontSize: "inherit",
                        fontWeight: "400",
                      }}
                    >
                      02-05-24
                    </span>
                  </Typography>
                </Box>
                {/* Header part end */}

                {/* GaugeMeter Part start  */}
                <Box
                  sx={{
                    width: { xs: "100%", sm: "80%", md: "60%" }, // Adjust width for different screens
                  }}
                >
                  {" "}
                  <GaugeMeter />
                </Box>
                {/* GaugeMeter Part end  */}

                <Box sx={{ marginLeft: "-15px" }}>
                  <Grid container>
                    {/* First Grid Item */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Card
                        variant="outlined"
                        sx={{
                          border: "0.25px solid #B7C8E5",
                          borderRadius: "8px",
                          background: "#FFF",
                          width: {
                            xs: "260px", // Width for extra small screens (mobile)
                            sm: "350px", // Width for small screens (tablets)
                            md: "250px", // Width for medium screens (larger tablets, laptops)
                          },
                          height: "150px", // Fixed height
                          overflow: "hidden", // Prevent content overflow
                          display: "flex", // Ensures flex layout for children
                          flexDirection: "column", // Aligns content vertically
                          marginBottom: "10px",
                          marginLeft: {
                            xs: "15%", // No margin for extra small screens
                            sm: "15%", // 20px margin left for tablets (sm breakpoint)
                            md: "0", // No margin for medium and larger screens
                          },
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center", // Align items vertically in the center
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              sx={{ fontSize: "16px" }}
                            >
                              Audit Sections
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: "#F1F0F1", // Grey background
                                borderRadius: "50%", // Circular shape
                                padding: "8px", // Padding for spacing around the icon
                                display: "flex", // Center the icon inside the circle
                                justifyContent: "center",
                                alignItems: "center",
                                width: "24px", // Circle width
                                height: "24px", // Circle height
                              }}
                            >
                              <ArrowOutwardIcon
                                sx={{
                                  color: "#000",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center", // Align items vertically in the center
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "700",
                                color: "#09A2E3",
                                fontSize: "32px",
                              }}
                            >
                              118{" "}
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "500",
                                }}
                              >
                                Actions
                              </span>
                            </Typography>
                            <Button
                              sx={{
                                color: "#009FF5",
                                fontSize: "10px",
                                fontWeight: "400",
                                borderRadius: "20px",
                                background: "#E7F6FD",
                                padding: "4px 8px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              In Progress
                            </Button>
                          </Box>

                          <Box display="flex" alignItems="center" marginY={1}>
                            <Typography variant="body2" marginRight={1}>
                              50%
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={50}
                              sx={{
                                flexGrow: 1,
                                height: "8px",
                                borderRadius: "4px",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "#4caf50",
                                },
                              }}
                            />
                            <Typography variant="body2" color="textSecondary">
                              &nbsp; 59/118
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>

                      <Card
                        variant="outlined"
                        sx={{
                          border: "0.25px solid #B7C8E5",
                          borderRadius: "8px",
                          background: "#FFF",
                          width: {
                            xs: "260px", // Width for extra small screens (mobile)
                            sm: "350px", // Width for small screens (tablets)
                            md: "250px", // Width for medium screens (larger tablets, laptops)
                          },
                          overflow: "hidden", // Prevent content overflow
                          display: "flex", // Ensures flex layout for children
                          flexDirection: "column", // Aligns content vertically
                          marginBottom: "10px",
                          marginLeft: {
                            xs: "15%", // No margin for extra small screens
                            sm: "15%", // 20px margin left for tablets (sm breakpoint)
                            md: "0", // No margin for medium and larger screens
                          },
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center", // Align items vertically in the center
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              sx={{ fontSize: "16px" }}
                            >
                              Audit Reports
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: "#F1F0F1", // Grey background
                                borderRadius: "50%", // Circular shape
                                padding: "8px", // Padding for spacing around the icon
                                display: "flex", // Center the icon inside the circle
                                justifyContent: "center",
                                alignItems: "center",
                                width: "24px", // Circle width
                                height: "24px", // Circle height
                              }}
                            >
                              <ArrowOutwardIcon
                                sx={{
                                  color: "#000",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>

                      <Card
                        variant="outlined"
                        sx={{
                          border: "0.25px solid #B7C8E5",
                          borderRadius: "8px",
                          background: "#FFF",
                          width: {
                            xs: "260px", // Width for extra small screens (mobile)
                            sm: "350px", // Width for small screens (tablets)
                            md: "250px", // Width for medium screens (larger tablets, laptops)
                          },
                          overflow: "hidden", // Prevent content overflow
                          display: "flex", // Ensures flex layout for children
                          flexDirection: "column", // Aligns content vertically
                          marginBottom: "10px",
                          marginLeft: {
                            xs: "15%", // No margin for extra small screens
                            sm: "15%", // 20px margin left for tablets (sm breakpoint)
                            md: "0", // No margin for medium and larger screens
                          },
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center", // Align items vertically in the center
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              sx={{ fontSize: "16px" }}
                            >
                              Performance
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: "#F1F0F1", // Grey background
                                borderRadius: "50%", // Circular shape
                                padding: "8px", // Padding for spacing around the icon
                                display: "flex", // Center the icon inside the circle
                                justifyContent: "center",
                                alignItems: "center",
                                width: "24px", // Circle width
                                height: "24px", // Circle height
                              }}
                            >
                              <ArrowOutwardIcon
                                sx={{
                                  color: "#000",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>

                    {/* Second Grid Item */}
                    <Grid
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{
                        marginLeft: {
                          xs: "15%", // Margin for extra small screens (mobile)
                          sm: "15%", // Remove margin for small screens (tablets)
                          md: "-40px", // Margin for medium and larger screens (desktop/laptops)
                        },
                      }}
                    >
                      <Box
                        sx={{
                          borderRadius: "8px",
                          border: "0.25px solid #B7C8E5",
                          background: "#FFF",
                          padding: "16px",
                          width: "380px",
                          height: "450px", // Set a fixed height
                          overflowY: "auto", // Enable vertical scrolling
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          Past Audits
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: "#F5FBFE",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                            padding: "12px",
                            width: "99%",
                            borderRadius: "8px",
                            padding: "16px",
                            marginTop: "20px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={9}>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  marginBottom: "8px",
                                  color: "#333",
                                }}
                              >
                                Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "#666",
                                    }}
                                  >
                                    👤 Ashish Patel
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "18px",
                                      color: "#000",
                                      fontWeight: "600",
                                    }}
                                  >
                                    895
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={0.5}
                                  >
                                    <CalendarMonthOutlinedIcon
                                      sx={{
                                        color: "#525966",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: "#525966",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      02-05-24
                                    </Typography>
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      color: "#000",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Audit score
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                      color: "#333",
                                    }}
                                  >
                                    50%
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={50}
                                    sx={{
                                      flexGrow: 1,
                                      height: "8px",
                                      borderRadius: "4px",
                                      "& .MuiLinearProgress-bar": {
                                        backgroundColor: "#4BC16D",
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <ArrowOutwardIcon
                                sx={{
                                  color: "#525966",
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "80%",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: "#F5FBFE",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                            padding: "12px",
                            width: "99%",
                            borderRadius: "8px",
                            padding: "16px",
                            marginTop: "20px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={9}>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  marginBottom: "8px",
                                  color: "#333",
                                }}
                              >
                                Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "#666",
                                    }}
                                  >
                                    👤 Ashish Patel
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "18px",
                                      color: "#000",
                                      fontWeight: "600",
                                    }}
                                  >
                                    895
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={0.5}
                                  >
                                    <CalendarMonthOutlinedIcon
                                      sx={{
                                        color: "#525966",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: "#525966",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      02-05-24
                                    </Typography>
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      color: "#000",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Audit score
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                      color: "#333",
                                    }}
                                  >
                                    50%
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={50}
                                    sx={{
                                      flexGrow: 1,
                                      height: "8px",
                                      borderRadius: "4px",
                                      "& .MuiLinearProgress-bar": {
                                        backgroundColor: "#4caf50",
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <ArrowOutwardIcon
                                sx={{
                                  color: "#525966",
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "80%",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: "#F5FBFE",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                            padding: "12px",
                            width: "99%",
                            borderRadius: "8px",
                            padding: "16px",
                            marginTop: "20px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={9}>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  marginBottom: "8px",
                                  color: "#333",
                                }}
                              >
                                Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap={0.5}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "#666",
                                    }}
                                  >
                                    👤 Ashish Patel
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "18px",
                                      color: "#000",
                                      fontWeight: "600",
                                    }}
                                  >
                                    895
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={0.5}
                                  >
                                    <CalendarMonthOutlinedIcon
                                      sx={{
                                        color: "#525966",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: "#525966",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      02-05-24
                                    </Typography>
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontSize: "10px",
                                      color: "#000",
                                      fontWeight: "400",
                                    }}
                                  >
                                    Audit score
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                      color: "#333",
                                    }}
                                  >
                                    50%
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={50}
                                    sx={{
                                      flexGrow: 1,
                                      height: "8px",
                                      borderRadius: "4px",
                                      "& .MuiLinearProgress-bar": {
                                        backgroundColor: "#4caf50",
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <ArrowOutwardIcon
                                sx={{
                                  color: "#525966",
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "80%",
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}

          {/* Second Right Grid Item */}
          <Grid
            item
            xs={isLeftHidden ? 12 : 12}
            sm={isLeftHidden ? 12 : 12}
            md={isLeftHidden ? 12 : 6}
          >
            <Box>
              <Box>
                <KeyboardDoubleArrowLeftIcon
                  onClick={handleIconClick}
                  sx={{ cursor: "pointer" }}
                  style={{
                    transition: "transform 0.3s ease",
                    transform: isLeftHidden ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </Box>
              {!questionnaireSection && (
                <>
                  {" "}
                  <Typography
                    sx={{
                      textAlign: "end",
                      cursor: "pointer", // Show pointer for clickability
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginTop: "-30px",
                    }}
                    onClick={() => setShowStepper((prev) => !prev)}
                  >
                    {/* Overall Status */}
                    <AuditVerticalStepperStatus />
                  </Typography>
                </>
              )}
              {/* Typography with Icon */}

              {/* custom tabs start */}
              {!isLeftHidden && (
                <>
                  <Box
                    mt={2}
                    ref={tabsRef}
                    sx={{
                      width: "95%",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      padding: "10px",
                    }}
                  >
                    <Box
                      ref={tabsRef}
                      sx={{
                        display: "flex",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                        minWidth: "max-content",
                      }}
                    >
                      <StyledTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="custom tabs"
                        scrollButtons={false}
                      >
                        {/* Scheduled Tab */}
                        <StyledTab
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <CheckCircleIcon sx={{ color: "#5DF018" }} />
                              <Typography>Scheduled</Typography>
                            </Box>
                          }
                          selected={value === 0}
                        />

                        {/* Audit Perform Tab */}
                        <StyledTab
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <RadioButtonCheckedIcon
                                sx={{ color: "orange" }}
                              />
                              <Typography>Conducted</Typography>
                            </Box>
                          }
                          selected={value === 1}
                        />

                        {/* Audit Reviewer Tab */}
                        <StyledTab
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <CircleIcon sx={{ color: "#D9D9D9" }} />
                              <Typography>Reviewer</Typography>
                            </Box>
                          }
                          selected={value === 2}
                        />

                        {/* Stage Completion Tab */}
                        <StyledTab
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <CircleIcon sx={{ color: "#D9D9D9" }} />
                              <Typography>Actions</Typography>
                            </Box>
                          }
                          selected={value === 3}
                        />
                        <StyledTab
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <CircleIcon sx={{ color: "#D9D9D9" }} />
                              <Typography>Storage</Typography>
                            </Box>
                          }
                          selected={value === 4}
                        />
                      </StyledTabs>
                    </Box>
                  </Box>
                </>
              )}

              {showGrid && ( // Conditional rendering for the chart and grid
                <>
                  {!questionnaireSection && (
                    <>
                      {/* Line chat start */}
                      <Box
                        sx={{
                          background: "#fff",
                          borderRadius: "12px",
                          width: "580px",
                          padding: "4px 4px 4px 4px",
                          marginLeft: "3%",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "700" }}
                        >
                          Time Chart when the working done
                        </Typography>
                        <LineChart
                          width={580}
                          height={250}
                          data={data}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} />
                          <Line dataKey="pv" stroke="#0077B6" />
                          <Line dataKey="uv" stroke="#023E8A" />
                        </LineChart>
                      </Box>
                      {/* Line chat start */}

                      <Box
                        sx={{
                          padding: "20px",
                          background: "#fff",
                          borderRadius: "12px",
                          marginLeft: "3%",
                          marginTop: "20px",
                          width: "580px",
                        }}
                      >
                        <SectionName
                          section={section}
                          setSection={setSection}
                        />

                        <Grid container spacing={2} justifyContent="center">
                          {circles.map((row, rowIndex) => (
                            <Grid
                              item
                              key={rowIndex}
                              container
                              justifyContent="center"
                            >
                              {row.map((color, colIndex) => (
                                <Box
                                  key={colIndex}
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: color,
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  1
                                </Box>
                              ))}
                            </Grid>
                          ))}
                        </Grid>

                        <Box sx={{ marginTop: "20px" }}>
                          <Grid container alignItems="center" spacing={2}>
                            {legendItems.map((item, index) => (
                              <Grid
                                item
                                key={index}
                                display="flex"
                                alignItems="center"
                                sx={{ margin: "auto" }}
                              >
                                <Box
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: item.color,
                                    borderRadius: "50%",
                                    marginRight: "18px",
                                  }}
                                />
                                <Typography variant="body1">
                                  {item.label}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}

              {isLeftHidden && (
                <>
                  {!questionnaireSection && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: { xs: "column", sm: "row" }, // Column for smaller screens, row for larger
                          alignItems: { xs: "flex-start", sm: "center" }, // Adjust alignment for smaller screens
                          marginBottom: "20px",
                        }}
                      >
                        <Typography variant="h6">
                          Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010 &nbsp;
                          &nbsp;
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#09A2E3",
                              color: "#09A2E3",
                              textTransform: "none",
                              fontWeight: "400",
                              borderRadius: "4px",
                              background: "rgba(9, 162, 227, 0.20)",
                              fontSize: "12px",
                              lineHeight: "18px",
                              "&:hover": {
                                backgroundColor: "rgba(9, 162, 227, 0.20)", // Same as the initial background
                                boxShadow: "none", // Disable hover shadow
                              },
                            }}
                          >
                            In Progress
                          </Button>
                        </Typography>
                        {/* Scheduler Information */}

                        {/* Audit Date Information */}
                        <Typography
                          sx={{
                            color: "#222",
                            fontSize: { xs: "14px", sm: "16px" },
                            fontWeight: "400",
                          }}
                        >
                          {" "}
                          <Typography
                            sx={{
                              color: "#222",
                              fontSize: { xs: "14px", sm: "16px" }, // Smaller font size for smaller screens
                              fontWeight: "400",
                              marginBottom: { xs: "8px", sm: "0" }, // Add margin for spacing in smaller screens
                            }}
                          >
                            Scheduler:{" "}
                            <span
                              style={{
                                color: "#525966",
                                fontSize: "inherit", // Inherit parent size
                                fontWeight: "400",
                              }}
                            >
                              Ashish Patel
                            </span>
                          </Typography>
                          Audit Date:{" "}
                          <span
                            style={{
                              color: "#525966",
                              fontSize: "inherit",
                              fontWeight: "400",
                            }}
                          >
                            02-05-24
                          </span>
                        </Typography>
                      </Box>

                      <AuditViewStepperBox />
                    </>
                  )}
                </>
              )}

              {questionnaireSection && (
                <>
                  {!showGrid /* Dynamic Card Grid */ && (
                    <>
                      <Box sx={{ bgcolor: "#f5f5f5", p: 2 }}>
                        <Tabs
                          value={activeTab}
                          onChange={handleTabChange}
                          textColor="inherit"
                          variant="fullWidth"
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#09A2E3", // Blue indicator
                              height: "4px",
                              borderRadius: "2px",
                            },
                          }}
                          aria-label="custom tabs"
                          centered
                        >
                          <CustomTab label="Sections" />
                          <CustomTab label="Actions" />
                          <CustomTab label="Attachments" />
                          <CustomTab label="Notes" />
                        </Tabs>
                      </Box>
                      {/* Dynamic Card Grid */}
                      {activeTab === 0 ? (
                        <Box
                          sx={{
                            marginLeft: {
                              xs: "10px", // Margin for small screens (mobile)
                              sm: "20px", // Margin for tablets (sm breakpoint)
                              md: "20px", // Margin for medium and larger screens (desktops/laptops)
                            },
                            height: "500px", // Fixed height
                            overflowY: "auto", // Enable vertical scrolling
                          }}
                        >
                          <Grid container spacing={2}>
                            {cards.slice(0, 12).map((card, index) => (
                              <Grid
                                item
                                xs={12} // On mobile, 6 columns when hidden, 12 for full width
                                sm={4} // On tablet, 4 columns if hidden, 6 if not
                                md={isLeftHidden ? 2 : 4}
                                key={index}
                              >
                                {card}
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      ) : activeTab === 1 ? (
                        <Box
                          p={0}
                          sx={{
                            marginLeft: {
                              xs: "10px", // Margin for small screens (mobile)
                              sm: "20px", // Margin for tablets (sm breakpoint)
                              md: "20px", // Margin for medium and larger screens (desktops/laptops)
                            },
                          }}
                        >
                          <Paper
                            elevation={3}
                            sx={{
                              padding: "16px",
                              overflowX: "auto", // Enables horizontal scroll for large tables
                              borderRadius: "8px",
                              height: "500px",
                              overflowY: "auto",
                            }}
                          >
                            <Grid container spacing={0} mb={2}>
                              <Grid item xs={7}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "700",
                                      color: "#222",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Action Items
                                  </Typography>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Search for Action"
                                    size="sm"
                                    fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchIcon
                                            style={{ color: "gray" }}
                                          />
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontSize: "14px",
                                        borderRadius: "10px",
                                        backgroundColor: "#F4F4F4",
                                        paddingX: "8px",
                                        height: "36px",
                                        width: "84%",
                                      },
                                    }}
                                    sx={{
                                      ".MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "transparent",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "transparent",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#13A1E0", // Adjust this for the blue highlight
                                          borderWidth: "2px",
                                        },
                                      },
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={1}></Grid>
                              <Grid item xs={4}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Select
                                    value={actionFirstFilter}
                                    onChange={(e) =>
                                      setActionFirstFilter(e.target.value)
                                    }
                                    size="small" // Small size dropdown
                                    displayEmpty // Ensures placeholder text is visible
                                    fullWidth // Optional: Makes it full width
                                    sx={{
                                      borderRadius: "8px", // Optional: Add rounded corners
                                      height: "36px",
                                      width: "40%",
                                    }}
                                  >
                                    <MenuItem value="" disabled>
                                      Select an Option
                                    </MenuItem>
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Question">
                                      Question
                                    </MenuItem>
                                    <MenuItem value="Section">Section</MenuItem>
                                  </Select>

                                  <Box sx={{ maxWidth: "60%" }}>
                                    <Select
                                      multiple // Enables multi-select
                                      value={currentActionCols}
                                      onChange={handleActionColsFilterChange}
                                      size="small"
                                      displayEmpty
                                      fullWidth
                                      renderValue={(selected) =>
                                        selected.length === 0
                                          ? "Select Options"
                                          : selected.join(", ")
                                      }
                                      sx={{
                                        borderRadius: "8px",
                                        height: "36px",
                                        whiteSpace: "nowrap", // Prevents line breaks
                                        overflow: "hidden", // Hides overflow content
                                      }}
                                    >
                                      {actionFirstFilter === "All"
                                        ? allActions?.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                              <Checkbox
                                                checked={currentActionCols?.includes(
                                                  item
                                                )}
                                              />
                                              <ListItemText primary={item} />
                                            </MenuItem>
                                          ))
                                        : actionFirstFilter === "Question"
                                        ? questionActions?.map(
                                            (item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item}
                                              >
                                                <Checkbox
                                                  checked={currentActionCols?.includes(
                                                    item
                                                  )}
                                                />
                                                <ListItemText primary={item} />
                                              </MenuItem>
                                            )
                                          )
                                        : actionFirstFilter === "Section"
                                        ? sectionActions?.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                              <Checkbox
                                                checked={currentActionCols?.includes(
                                                  item
                                                )}
                                              />
                                              <ListItemText primary={item} />
                                            </MenuItem>
                                          ))
                                        : null}
                                    </Select>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <TableContainer>
                              <Table>
                                <TableHead
                                  sx={{
                                    backgroundColor: "#FCFCFC",
                                    borderTop: "1px solid #D1D1D1",
                                    borderBottom: "1px solid #D1D1D1",
                                  }}
                                >
                                  <TableRow>
                                    {currentActionCols?.map((item, index) => (
                                      <TableCell
                                        key={index}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          color: "#222",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item}
                                      </TableCell>
                                    ))}
                                    <TableCell
                                      sx={{
                                        whiteSpace: "nowrap",
                                        color: "#222",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody sx={{ border: "none !important" }}>
                                  {currentActionData?.map(
                                    (currentDataItem, index) => (
                                      <TableRow key={index}>
                                        {currentActionCols?.map(
                                          (currentCol, index1) => (
                                            <TableCell
                                              key={index1}
                                              sx={{
                                                color: "#222",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {currentDataItem[currentCol]
                                                ?.length > 18 ? (
                                                <Tooltip
                                                  title={
                                                    currentDataItem[currentCol]
                                                  }
                                                >
                                                  <span>{`${currentDataItem[
                                                    currentCol
                                                  ].slice(0, 18)}..`}</span>
                                                </Tooltip>
                                              ) : (
                                                currentDataItem[currentCol]
                                              )}
                                            </TableCell>
                                          )
                                        )}
                                        <TableCell align="center">
                                          <IconButton size="small">
                                            <VisibilityOutlinedIcon
                                              sx={{ color: "#09A2E3" }}
                                            />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        </Box>
                      ) : activeTab === 2 ? (
                        <Box
                          p={0}
                          sx={{
                            marginLeft: {
                              xs: "10px", // Margin for small screens (mobile)
                              sm: "20px", // Margin for tablets (sm breakpoint)
                              md: "20px", // Margin for medium and larger screens (desktops/laptops)
                            },
                          }}
                        >
                          <Paper
                            elevation={3}
                            sx={{
                              padding: "16px",
                              overflowX: "auto", // Enables horizontal scroll for large tables
                              borderRadius: "8px",
                              height: "500px",
                              overflowY: "auto",
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                              gap={1}
                              mb={2}
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    color: "#222",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Attachments
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="Search for Attachments"
                                  size="sm"
                                  fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon style={{ color: "gray" }} />
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontSize: "14px",
                                      borderRadius: "10px",
                                      backgroundColor: "#F4F4F4",
                                      paddingX: "8px",
                                      height: "36px",
                                      width: "100%",
                                    },
                                  }}
                                  sx={{
                                    ".MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "transparent",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "transparent",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#13A1E0", // Adjust this for the blue highlight
                                        borderWidth: "2px",
                                      },
                                    },
                                  }}
                                />
                              </Box>
                              <Select
                                value={attachmentsFilter}
                                onChange={(e) =>
                                  setAttachmentsFilter(e.target.value)
                                }
                                size="small" // Small size dropdown
                                displayEmpty // Ensures placeholder text is visible
                                fullWidth // Optional: Makes it full width
                                sx={{
                                  borderRadius: "8px", // Optional: Add rounded corners
                                  height: "36px",
                                  width: "26%",
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Select an Option
                                </MenuItem>
                                <MenuItem value="Audit">Audit Program</MenuItem>
                                <MenuItem value="CAPA">CAPA</MenuItem>
                                <MenuItem value="Question">Question</MenuItem>
                              </Select>
                            </Box>
                            <TableContainer>
                              <Table>
                                <TableHead
                                  sx={{
                                    backgroundColor: "#FCFCFC",
                                    borderTop: "1px solid #D1D1D1",
                                    borderBottom: "1px solid #D1D1D1",
                                  }}
                                >
                                  <TableRow>
                                    {attachmentsCol?.map((item, index) => (
                                      <TableCell
                                        key={index}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          color: "#222",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item}
                                      </TableCell>
                                    ))}
                                    <TableCell
                                      sx={{
                                        whiteSpace: "nowrap",
                                        color: "#222",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    ></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody sx={{ border: "none !important" }}>
                                  {attachmentsData?.map(
                                    (currentDataItem, index) => (
                                      <TableRow key={index}>
                                        {attachmentsCol?.map(
                                          (currentCol, index1) => (
                                            <TableCell
                                              key={index1}
                                              sx={{
                                                color: "#222",
                                                fontSize: "12px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {currentDataItem[currentCol]
                                                ?.length > 18 ? (
                                                <Tooltip
                                                  title={
                                                    currentDataItem[currentCol]
                                                  }
                                                >
                                                  <span>{`${currentDataItem[
                                                    currentCol
                                                  ].slice(0, 18)}..`}</span>
                                                </Tooltip>
                                              ) : (
                                                currentDataItem[currentCol]
                                              )}
                                            </TableCell>
                                          )
                                        )}
                                        <TableCell align="center">
                                          <IconButton size="small">
                                            <VisibilityOutlinedIcon
                                              sx={{ color: "#09A2E3" }}
                                            />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        </Box>
                      ) : activeTab === 3 ? (
                        <Box
                          p={0}
                          sx={{
                            marginLeft: {
                              xs: "10px", // Margin for small screens (mobile)
                              sm: "20px", // Margin for tablets (sm breakpoint)
                              md: "20px", // Margin for medium and larger screens (desktops/laptops)
                            },
                          }}
                        >
                          <Paper
                            elevation={3}
                            sx={{
                              padding: "16px",
                              overflowX: "auto", // Enables horizontal scroll for large tables
                              borderRadius: "8px",
                              height: "500px",
                              overflowY: "auto",
                            }}
                          >
                            <Grid container spacing={0} mb={2}>
                              <Grid item xs={7}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "700",
                                      color: "#222",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Notes
                                  </Typography>
                                  <TextField
                                    variant="outlined"
                                    placeholder="Search for Notes"
                                    size="sm"
                                    fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchIcon
                                            style={{ color: "gray" }}
                                          />
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontSize: "14px",
                                        borderRadius: "10px",
                                        backgroundColor: "#F4F4F4",
                                        paddingX: "8px",
                                        height: "36px",
                                        width: "84%",
                                      },
                                    }}
                                    sx={{
                                      ".MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "transparent",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "transparent",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#13A1E0", // Adjust this for the blue highlight
                                          borderWidth: "2px",
                                        },
                                      },
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <TableContainer>
                              <Table>
                                <TableHead
                                  sx={{
                                    backgroundColor: "#FCFCFC",
                                    borderTop: "1px solid #D1D1D1",
                                    borderBottom: "1px solid #D1D1D1",
                                  }}
                                >
                                  <TableRow>
                                    {notesCol?.map((item, index) => (
                                      <TableCell
                                        key={index}
                                        sx={{
                                          whiteSpace: "nowrap",
                                          color: "#222",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody sx={{ border: "none !important" }}>
                                  {notesData?.map((currentDataItem, index) => (
                                    <TableRow key={index}>
                                      {notesCol?.map((currentCol, index1) => (
                                        <TableCell
                                          key={index1}
                                          sx={{
                                            color: "#222",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {currentDataItem[currentCol]?.length >
                                          18 ? (
                                            <Tooltip
                                              title={
                                                currentDataItem[currentCol]
                                              }
                                            >
                                              <span>{`${currentDataItem[
                                                currentCol
                                              ].slice(0, 18)}..`}</span>
                                            </Tooltip>
                                          ) : (
                                            currentDataItem[currentCol]
                                          )}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Paper>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}

              {!showGrid /* Dynamic Card Grid */ && (
                <>
                  <Box sx={{ bgcolor: "#f5f5f5", p: 2 }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      textColor="inherit"
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#09A2E3", // Blue indicator
                          height: "4px",
                          borderRadius: "2px",
                        },
                      }}
                      aria-label="custom tabs"
                      centered
                    >
                      <CustomTab label="Sections" />
                      <CustomTab label="Actions" />
                      <CustomTab label="Attachments" />
                      <CustomTab label="Notes" />
                    </Tabs>
                  </Box>
                  {/* Dynamic Card Grid */}
                  {activeTab === 0 ? (
                    <Box
                      sx={{
                        marginLeft: {
                          xs: "10px", // Margin for small screens (mobile)
                          sm: "20px", // Margin for tablets (sm breakpoint)
                          md: "20px", // Margin for medium and larger screens (desktops/laptops)
                        },
                        height: "500px", // Fixed height
                        overflowY: "auto", // Enable vertical scrolling
                      }}
                    >
                      <Grid container spacing={2}>
                        {cards.slice(0, 12).map((card, index) => (
                          <Grid
                            item
                            xs={12} // On mobile, 6 columns when hidden, 12 for full width
                            sm={4} // On tablet, 4 columns if hidden, 6 if not
                            md={isLeftHidden ? 2 : 4}
                            key={index}
                          >
                            {card}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ) : activeTab === 1 ? (
                    <Box
                      p={0}
                      sx={{
                        marginLeft: {
                          xs: "10px", // Margin for small screens (mobile)
                          sm: "20px", // Margin for tablets (sm breakpoint)
                          md: "20px", // Margin for medium and larger screens (desktops/laptops)
                        },
                      }}
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          padding: "16px",
                          overflowX: "auto", // Enables horizontal scroll for large tables
                          borderRadius: "8px",
                          height: "500px",
                          overflowY: "auto",
                        }}
                      >
                        <Grid container spacing={0} mb={2}>
                          <Grid item xs={7}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  color: "#222",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Action Items
                              </Typography>
                              <TextField
                                variant="outlined"
                                placeholder="Search for Action"
                                size="sm"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon style={{ color: "gray" }} />
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontSize: "14px",
                                    borderRadius: "10px",
                                    backgroundColor: "#F4F4F4",
                                    paddingX: "8px",
                                    height: "36px",
                                    width: "84%",
                                  },
                                }}
                                sx={{
                                  ".MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "transparent",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#13A1E0", // Adjust this for the blue highlight
                                      borderWidth: "2px",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={4}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Select
                                value={actionFirstFilter}
                                onChange={(e) =>
                                  setActionFirstFilter(e.target.value)
                                }
                                size="small" // Small size dropdown
                                displayEmpty // Ensures placeholder text is visible
                                fullWidth // Optional: Makes it full width
                                sx={{
                                  borderRadius: "8px", // Optional: Add rounded corners
                                  height: "36px",
                                  width: "40%",
                                }}
                              >
                                <MenuItem value="" disabled>
                                  Select an Option
                                </MenuItem>
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Question">Question</MenuItem>
                                <MenuItem value="Section">Section</MenuItem>
                              </Select>

                              <Box sx={{ maxWidth: "60%" }}>
                                <Select
                                  multiple // Enables multi-select
                                  value={currentActionCols}
                                  onChange={handleActionColsFilterChange}
                                  size="small"
                                  displayEmpty
                                  fullWidth
                                  renderValue={(selected) =>
                                    selected.length === 0
                                      ? "Select Options"
                                      : selected.join(", ")
                                  }
                                  sx={{
                                    borderRadius: "8px",
                                    height: "36px",
                                    whiteSpace: "nowrap", // Prevents line breaks
                                    overflow: "hidden", // Hides overflow content
                                  }}
                                >
                                  {actionFirstFilter === "All"
                                    ? allActions?.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                          <Checkbox
                                            checked={currentActionCols?.includes(
                                              item
                                            )}
                                          />
                                          <ListItemText primary={item} />
                                        </MenuItem>
                                      ))
                                    : actionFirstFilter === "Question"
                                    ? questionActions?.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                          <Checkbox
                                            checked={currentActionCols?.includes(
                                              item
                                            )}
                                          />
                                          <ListItemText primary={item} />
                                        </MenuItem>
                                      ))
                                    : actionFirstFilter === "Section"
                                    ? sectionActions?.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                          <Checkbox
                                            checked={currentActionCols?.includes(
                                              item
                                            )}
                                          />
                                          <ListItemText primary={item} />
                                        </MenuItem>
                                      ))
                                    : null}
                                </Select>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <TableContainer>
                          <Table>
                            <TableHead
                              sx={{
                                backgroundColor: "#FCFCFC",
                                borderTop: "1px solid #D1D1D1",
                                borderBottom: "1px solid #D1D1D1",
                              }}
                            >
                              <TableRow>
                                {currentActionCols?.map((item, index) => (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      color: "#222",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item}
                                  </TableCell>
                                ))}
                                <TableCell
                                  sx={{
                                    whiteSpace: "nowrap",
                                    color: "#222",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ border: "none !important" }}>
                              {currentActionData?.map(
                                (currentDataItem, index) => (
                                  <TableRow key={index}>
                                    {currentActionCols?.map(
                                      (currentCol, index1) => (
                                        <TableCell
                                          key={index1}
                                          sx={{
                                            color: "#222",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {currentDataItem[currentCol]?.length >
                                          18 ? (
                                            <Tooltip
                                              title={
                                                currentDataItem[currentCol]
                                              }
                                            >
                                              <span>{`${currentDataItem[
                                                currentCol
                                              ].slice(0, 18)}..`}</span>
                                            </Tooltip>
                                          ) : (
                                            currentDataItem[currentCol]
                                          )}
                                        </TableCell>
                                      )
                                    )}
                                    <TableCell align="center">
                                      <IconButton size="small">
                                        <VisibilityOutlinedIcon
                                          sx={{ color: "#09A2E3" }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                  ) : activeTab === 2 ? (
                    <Box
                      p={0}
                      sx={{
                        marginLeft: {
                          xs: "10px", // Margin for small screens (mobile)
                          sm: "20px", // Margin for tablets (sm breakpoint)
                          md: "20px", // Margin for medium and larger screens (desktops/laptops)
                        },
                      }}
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          padding: "16px",
                          overflowX: "auto", // Enables horizontal scroll for large tables
                          borderRadius: "8px",
                          height: "500px",
                          overflowY: "auto",
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={1}
                          mb={2}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={1}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#222",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Attachments
                            </Typography>
                            <TextField
                              variant="outlined"
                              placeholder="Search for Attachments"
                              size="sm"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon style={{ color: "gray" }} />
                                  </InputAdornment>
                                ),
                                style: {
                                  fontSize: "14px",
                                  borderRadius: "10px",
                                  backgroundColor: "#F4F4F4",
                                  paddingX: "8px",
                                  height: "36px",
                                  width: "100%",
                                },
                              }}
                              sx={{
                                ".MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "transparent",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "transparent",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#13A1E0", // Adjust this for the blue highlight
                                    borderWidth: "2px",
                                  },
                                },
                              }}
                            />
                          </Box>
                          <Select
                            value={attachmentsFilter}
                            onChange={(e) =>
                              setAttachmentsFilter(e.target.value)
                            }
                            size="small" // Small size dropdown
                            displayEmpty // Ensures placeholder text is visible
                            fullWidth // Optional: Makes it full width
                            sx={{
                              borderRadius: "8px", // Optional: Add rounded corners
                              height: "36px",
                              width: "26%",
                            }}
                          >
                            <MenuItem value="" disabled>
                              Select an Option
                            </MenuItem>
                            <MenuItem value="Audit">Audit Program</MenuItem>
                            <MenuItem value="CAPA">CAPA</MenuItem>
                            <MenuItem value="Question">Question</MenuItem>
                          </Select>
                        </Box>
                        <TableContainer>
                          <Table>
                            <TableHead
                              sx={{
                                backgroundColor: "#FCFCFC",
                                borderTop: "1px solid #D1D1D1",
                                borderBottom: "1px solid #D1D1D1",
                              }}
                            >
                              <TableRow>
                                {attachmentsCol?.map((item, index) => (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      color: "#222",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item}
                                  </TableCell>
                                ))}
                                <TableCell
                                  sx={{
                                    whiteSpace: "nowrap",
                                    color: "#222",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ border: "none !important" }}>
                              {attachmentsData?.map(
                                (currentDataItem, index) => (
                                  <TableRow key={index}>
                                    {attachmentsCol?.map(
                                      (currentCol, index1) => (
                                        <TableCell
                                          key={index1}
                                          sx={{
                                            color: "#222",
                                            fontSize: "12px",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {currentDataItem[currentCol]?.length >
                                          18 ? (
                                            <Tooltip
                                              title={
                                                currentDataItem[currentCol]
                                              }
                                            >
                                              <span>{`${currentDataItem[
                                                currentCol
                                              ].slice(0, 18)}..`}</span>
                                            </Tooltip>
                                          ) : (
                                            currentDataItem[currentCol]
                                          )}
                                        </TableCell>
                                      )
                                    )}
                                    <TableCell align="center">
                                      <IconButton size="small">
                                        <VisibilityOutlinedIcon
                                          sx={{ color: "#09A2E3" }}
                                        />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                  ) : activeTab === 3 ? (
                    <Box
                      p={0}
                      sx={{
                        marginLeft: {
                          xs: "10px", // Margin for small screens (mobile)
                          sm: "20px", // Margin for tablets (sm breakpoint)
                          md: "20px", // Margin for medium and larger screens (desktops/laptops)
                        },
                      }}
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          padding: "16px",
                          overflowX: "auto", // Enables horizontal scroll for large tables
                          borderRadius: "8px",
                          height: "500px",
                          overflowY: "auto",
                        }}
                      >
                        <Grid container spacing={0} mb={2}>
                          <Grid item xs={7}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  color: "#222",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Notes
                              </Typography>
                              <TextField
                                variant="outlined"
                                placeholder="Search for Notes"
                                size="sm"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon style={{ color: "gray" }} />
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontSize: "14px",
                                    borderRadius: "10px",
                                    backgroundColor: "#F4F4F4",
                                    paddingX: "8px",
                                    height: "36px",
                                    width: "84%",
                                  },
                                }}
                                sx={{
                                  ".MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "transparent",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "transparent",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#13A1E0", // Adjust this for the blue highlight
                                      borderWidth: "2px",
                                    },
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <TableContainer>
                          <Table>
                            <TableHead
                              sx={{
                                backgroundColor: "#FCFCFC",
                                borderTop: "1px solid #D1D1D1",
                                borderBottom: "1px solid #D1D1D1",
                              }}
                            >
                              <TableRow>
                                {notesCol?.map((item, index) => (
                                  <TableCell
                                    key={index}
                                    sx={{
                                      whiteSpace: "nowrap",
                                      color: "#222",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ border: "none !important" }}>
                              {notesData?.map((currentDataItem, index) => (
                                <TableRow key={index}>
                                  {notesCol?.map((currentCol, index1) => (
                                    <TableCell
                                      key={index1}
                                      sx={{ color: "#222", fontSize: "12px" }}
                                    >
                                      {currentDataItem[currentCol]?.length >
                                      18 ? (
                                        <Tooltip
                                          title={currentDataItem[currentCol]}
                                        >
                                          <span>{`${currentDataItem[
                                            currentCol
                                          ].slice(0, 18)}..`}</span>
                                        </Tooltip>
                                      ) : (
                                        currentDataItem[currentCol]
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                  ) : (
                    <></>
                  )}
                </>
              )}

              {showGrid && (
                <>
                  {!questionnaireSection && (
                    <>
                      <Box sx={{ marginLeft: "30px", marginTop: "10px" }}>
                        <Button
                          sx={{
                            width: "590px",
                            borderRadius: "30px",
                            background: "#09A2E3",
                            color: "#fff",
                          }}
                          onClick={() => setGrid((prev) => !prev)}
                        >
                          Start Audit &nbsp;
                          <EastIcon />
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </Grid>

          {/* Add more Grid items as needed */}
          {questionnaireSection && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column", // Ensures the main items stack vertically
                    gap: 1, // Adds consistent spacing between children
                  }}
                >
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}
                  >
                    Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center", // Aligns items vertically in the center
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#696969",
                      }}
                    >
                      Last Updated on 25 Nov, 2024 | 16:55
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center", // Aligns the progress bar and percentage text
                        gap: 1, // Adds spacing between the bar and the percentage text
                        width: "30%",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "#525966",fontSize:'12px',fontWeight:"500" }}>
                        50%
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={50}
                        sx={{
                          flexGrow: 1,
                          height: "8px",
                          borderRadius: "4px",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#4BC16D",
                          },
                        }}
                      />
                      <Typography variant="body2" sx={{ color: "#525966",fontSize:'12px',fontWeight:"500" }}>
                        59/118
                      </Typography>
                    </Box>
                  </Box>
                </Box>


                <Box
                    mt={2}
                    // ref={tabsRef}
                    // sx={{
                    //   width: "95%",
                    //   overflowX: "auto",
                    //   whiteSpace: "nowrap",
                    //   padding: "10px",
                    // }}
                  >
                    <Box
                      // ref={tabsRef}
                      // sx={{
                      //   display: "flex",
                      //   overflowX: "auto",
                      //   whiteSpace: "nowrap",
                      //   minWidth: "max-content",
                      // }}
                    >
                     <QuestionnairSection tabData={tabData}/>
                    </Box>
                  </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default AuditCreateDashboard;
