import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
  Divider,
  Switch,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "./CustomInputField";
import CustomSelect from "./CustomSelectField";
import CustomButtonGroup from "./CustomButtonGroup";
import CustomMultipleSelect from "./MutlipleSelect"
import BasicDateTimePicker from "./CustomDateTime";
import CustomeDatePicker from "./CustomeDate"
import CardEditDelete from "./CardEditDelete";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { updateSwitchState, handleChangeDrawerFormData, addPersonnelDetail, addVehicleDetail, addEquipmentDetail, addCAPADetail, addEvidenceDetail, addLTIDetail } from '../redux/incidentReport/actions';
import dayjs from 'dayjs';
import CustomFileUpload from "./CustomFileUpload";
import CardPerson from "./CardPerson";
import CachedIcon from '@mui/icons-material/Cached';
import { capitalizeFirstChar,formatDateToIndianLocale } from "../utils/helper";
import DesignerButtonGroup from "./DesignerButtonGroup";
import { getSubArea, getStaffAndEntity, getEntity, getCAPAOwner } from '../Apis/apiCall'


const togleStyle =
{
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#0AA2E3', // Color when checked (knob)
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#0AA2E3', // Color when checked (track)
  },
}
const AddButtonStyle= {
  fontSize: "16px",
  fontWeight: "600",
  paddingY: "8px",
  paddingX: "16px",
  color: "#fff",
  borderRadius: "60px",
  backgroundColor: "#0AA2E3",
  width: "70px",
  textTransform:"none"
};

const genderList = ["Male", "Female", "Other"];


const RightDrawer = ({open, onClose, title, fields = [], cardData = []
}) => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.incidentReportFromReducer);
  const {
    Personnel,
    Vehicle,
    Equipment,
    PersonnelDetail,
    VehicleDetail,
    EquipmentDetail,
    CAPADetail,
    LTIDetail,
    EvidenceDetail,
    CAPA,
    LTI,
    Evidence,
    Regulatory,
    switchStates ,
    formEdit
  } = formData
  
  const [tabValue, setTabValue] = React.useState('1');
  const [staffNameList, setStaffNameList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [subAreaList, setSubAreaList] = useState([]);
  const [CAPAOwnerList, setCAPAOwnerList] = useState([]);



 

  useEffect(() => {
    if (PersonnelDetail && PersonnelDetail.length > 0) {
      // Initialize counts
      let fatilityCount = 0;
      let injuryCount = 0;

      // Loop through each detail in PersonnelDetail
      PersonnelDetail.forEach(detail => {
        // Check the Outcome value and increment accordingly
        if (detail.Outcome === 'Fatality') {
          fatilityCount += 1; // Increment Fatality Count
        } else if (detail.Outcome === 'Major Injury' || detail.Outcome === 'Minor Injury') {
          injuryCount += 1; // Increment Injury Count
        }
      });

      // Dispatch the updated counts
      dispatch(handleChangeDrawerFormData({ fieldName: "Fatality Count", value: fatilityCount, title: "Personnel" }));
      dispatch(handleChangeDrawerFormData({ fieldName: "Injury Count", value: injuryCount, title: "Personnel" }));
    } else {
      dispatch(handleChangeDrawerFormData({ fieldName: "Fatality Count", value: 0, title: "Personnel" }));
      dispatch(handleChangeDrawerFormData({ fieldName: "Injury Count", value: 0, title: "Personnel" }));
    }
  }, [PersonnelDetail, dispatch]); // Ensure dispatch is in the dependency array


  useEffect(() => {
    if (CAPA && CAPA["Area"]) { // Ensure CAPA exists and has Area
      fetchSubArea(CAPA["Area"]);
    }

    fetchCAPAOwner();

  }, [CAPA]);






  useEffect(() => {
    if (Personnel?.["Staff Type"]) {
      fetchStaffEntitylist(Personnel["Staff Type"]);
    }
  }, [Personnel?.["Staff Type"]]);

  useEffect(() => {

    if (Personnel?.["Staff Type"] && Personnel?.["Name"]) {
      fetchEntity(Personnel["Staff Type"], Personnel["Name"]);
    }
  }, [Personnel?.["Name"]]);

  const fetchStaffEntitylist = async (staffType) => {
    try {
      const result = await getStaffAndEntity(staffType);
      console.log(result.data.data);

      // Map the staff array to the desired format (key as `id` and label as `firstName` + `lastName`)
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: `${staffMember.firstName} ${staffMember.lastName}`
      }));

      setStaffNameList(formattedStaffList); // Set the formatted staff list

    } catch (error) {
      console.log(error);
    }
  }

  const fetchEntity = async (type, id) => {
    try {
      const result = await getEntity(type, id);
      console.log(result.data.data);
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: staffMember.name
      }));

      setEntityList(formattedStaffList); // Set the formatted staff list

    } catch (error) {
      console.log(error);
    }
  }

  const fetchSubArea = async (data) => {
    try {
      const result = await getSubArea(data);
      console.log(result.data.data);
      setSubAreaList(result.data.data); // Set the formatted staff list

    } catch (error) {
      console.log(error);
    }
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchCAPAOwner = async () => {
    try {
      const result = await getCAPAOwner();
      console.log(result.data.data);
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: `${staffMember.firstName} ${staffMember.lastName}`
      }));
      setCAPAOwnerList(formattedStaffList);

    } catch (error) {
      console.log(error);
    }
  }


  const handleInputChange = (e, fieldName, title) => {
    const { value } = e.target;
    if (fieldName === 'Age' || fieldName === 'LTI Hours' || fieldName === "Hours at Work") {
      if (value.length <= 2) {
        dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
      }
    } else {
      dispatch(handleChangeDrawerFormData({ fieldName, value, title }));

    }
  };

  const handleSelectChange = (label, value, title) => {
    if (label === 'Age' || label === 'LTI Hours' || label === "Hours at Work") {
      if (value.length <= 2) {
        dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));
      }
    } else {
      dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));

    }


  };

  const handleButtonChange = (fieldName, value, title) => {
    dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
  };

  const handleDateChange = (newValue, label, title) => {
    const timestamp = dayjs(newValue).valueOf();
    dispatch(handleChangeDrawerFormData({ fieldName: label, value: timestamp, title }));
  };

  const SubmitFormData = async (title) => {
    console.log("hhhhh",title)
    const isObjectValid = (obj) => {
      return obj && typeof obj === 'object' && Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
    };

    switch (title) {
      case "Vehicle":
        if (isObjectValid(Vehicle)) {
          dispatch(addVehicleDetail(Vehicle));
        }
        break;
      case "Equipment":
        if (isObjectValid(Equipment)) {
          dispatch(addEquipmentDetail(Equipment));
        }
        break;
      case "Personnel":
        if (isObjectValid(Personnel)) {
          // Create a copy of the Personnel object and exclude the specified keys
          const {
            "No. of People": _,
            "Injury Count": __,
            "Fatality Count": ___,
            "Location of Accident": ____,
            ...filteredPersonnel
          } = Personnel;

          // if (PersonnelDetail.length < Personnel["No. of People"]) {
            dispatch(addPersonnelDetail(filteredPersonnel));
          // }
        }
        break;
      case "CAPA":
        if (isObjectValid(CAPA)) {
          dispatch(addCAPADetail(CAPA));
        }
        break;
      case "Evidence":
        if (isObjectValid(Evidence)) {
          dispatch(addEvidenceDetail(Evidence));
        }
        break;
      case "LTI":
        if (isObjectValid(LTI)) {
          dispatch(addLTIDetail(LTI));
        }
        break;

      default:
        console.warn("Invalid title provided");
    }
  };


  const ApplyFilter = async (title) => {
    console.log(title);
    onClose()
    //dispatch(addLTIDetail(LTI));
  }


  const renderField = (field, title) => {
  
    let isSelected;
    const fieldValue = formData?.[title]?.[field.formFieldName] || '';
    if (field.formFieldName === "Employee Type") {
      isSelected = formData?.[title]?.["Type"] ? "Yes" : null;
    }

    const incidentType = formData?.["Incident Type"];
    const filteredOptions = (field?.options || []).filter(option => {

      const trimmedOption = typeof option === 'string' ? option.trim() : option; // Handle cases where option is not a string
      const trimmedIncidentType = typeof incidentType === 'string' ? incidentType.trim() : incidentType; // Handle cases where incidentType is not a string

      if (trimmedIncidentType === "UA" || trimmedIncidentType === "UC" || trimmedIncidentType === "NM") {
        return trimmedOption !== "Fatality" && trimmedOption !== "Major Injury";
      }

      // For all other incident types except "Fatality", remove "Fatality"
      if (trimmedIncidentType !== "Fatality") {
        return trimmedOption !== "Fatality";
      }

      // Return all other options if no filtering criteria are met
      return true;
    });

    switch (field.type) {
      case 'InputBox':
        return (
          <CustomInput
            placeholder={field.formFieldName}
            label={field.formFieldName}
            name={field.formFieldName}
            value={fieldValue}
            type={field?.dataType === "String" ? "text" : "number"}
            onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
          />
        );

      case 'Dropdown':
        return (

          <>
            {Personnel && Personnel["Staff Type"] === "Visitor" && (field.formFieldName === "Name" || field.formFieldName === "Entity") ? (
              <CustomInput
                placeholder={field.formFieldName}
                label={field.formFieldName}
                name={field.formFieldName}
                value={fieldValue}
                type={field?.dataType === "String" ? "text" : "number"}
                onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
              />
            ) : field?.formFieldName === "Body Parts Involved" ? (
              <CustomMultipleSelect
                label={field.formFieldName}
                value={fieldValue}
                options={field?.options ? field?.options : ""}
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            ) :
              (
                <CustomSelect
                  label={field.formFieldName}
                  value={fieldValue}
                  options={field?.formFieldName == "Name" ? staffNameList : field?.formFieldName == "Entity" ? entityList : field?.formFieldName == "Gender" ? genderList : field?.formFieldName == "Sub Area" ? subAreaList : field?.formFieldName == "CAPA Owner" ? CAPAOwnerList : field?.formFieldName == "Outcome" ? filteredOptions : (field?.options) ? field.options : ""}
                  onChange={(e) => handleInputChange(e, field.formFieldName, title)}
                />
              )
            }
          </>

        );

      case 'TextArea':
        return (
          <CustomInput
            placeholder={field.formFieldName}
            label={field.formFieldName}
            multiline
            rows={4}
            type={field?.dataType === "String" ? "text" : "number"}
            value={fieldValue}
            onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
          />
        );


      case 'ButtonGroup':
        return (
          <>
            {field.formFieldName === "Equipment Damage" ? (
              <Box display="flex" alignItems="center" sx={{ mb: '8px' }}>
                <Typography variant='caption' sx={{ mb: '8px', mr: "12px" }}>
                  {field.formFieldName === "Employee Type" ? "" : field.formFieldName}
                </Typography>
                <CustomButtonGroup
                  buttonNames={field?.options || []}
                  selected={fieldValue}
                  onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
                />
              </Box>
            ) : (field.formFieldName === "Employee Type" ? (
              <div style={{ marginTop: "16px", float: 'right' }}>
                {Personnel && Personnel["Staff Type"] === "Employee" &&
                  (<CustomButtonGroup
                    buttonNames={field?.options || []}
                    selected={fieldValue}
                    onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
                  />)}
              </div>
            ) : (
              <>
                <Typography variant='body2'>
                  {field.formFieldName}
                </Typography>
                <CustomButtonGroup
                  buttonNames={field?.options || []}
                  selected={fieldValue}
                  onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
                />
              </>
            ))}
          </>
        );


      case 'DateTime':
        return (
          <BasicDateTimePicker
            label={field.formFieldName}
            value={fieldValue? formatDateToIndianLocale(fieldValue):"" }
            onChange={(e) => handleDateChange(e, field.formFieldName, title)}
          />




        );
      case 'Date':
        return (

          <CustomeDatePicker
            label={field.formFieldName}
            value={fieldValue? formatDateToIndianLocale(fieldValue):"" }
            onChange={(e) => handleDateChange(e, field.formFieldName, title)}
          />

        );

      case 'toggleSwitch':
        return (
          <>
            <Box display='flex' gap={1} alignItems='center'>
              <Typography variant="body1" sx={{ color: '#525966' }}>{field.formFieldName}</Typography>
              <Switch style={{ ...togleStyle }} />
            </Box>
          </>
        );

      case 'DesignerButtonGroup':
        return (
          <>
            <DesignerButtonGroup title={field.formFieldName} reported={field.reported} buttonNames={field.options} />
          </>
        );

      default:
        return null;
    }
  };

  const resetFilter = (field, title) => {
    switch (field.type) {
      case 'InputBox':
        handleSelectChange(field.formFieldName, "", title);
        break;

      case 'Dropdown':
        handleInputChange({ target: { value: "" } }, field.formFieldName, title);
        break;

      case 'TextArea':
        handleSelectChange(field.formFieldName, "", title);
        break;

      case 'ButtonGroup':
        handleButtonChange(field.formFieldName, "", title);
        break;

      case 'DateTime':
        handleDateChange(null, field.formFieldName, title);
        break;

      default:
        break;
    }
  };

  const renderFields = (fields, title) => {
    return (
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid
            item
            xs={12}
            md={
              field.formFieldName === "District/Panel/Bench outside work District" ||
                field.formFieldName === "Details not covered above" ||
                field.formFieldName === "Distance From The Face" ||
                field.formFieldName === "CAPA Description" ||
                field.formFieldName === "Equipment Incident Details" ||
                field.formFieldName === "Incident Type" ||
                field.formFieldName === "Review Date" ||
                field.formFieldName === "Review Input" ||
                field.formFieldName === "Return to previous level" ||
                field.formFieldName === "Investigation recommended" ||
                field.formFieldName === "Review  completed" ||
                field.type === "DesignerButtonGroup" ||
                field.formFieldName === "Observation" ||
                field.formFieldName === "Body Parts Involved"||
                field.formFieldName === "Investigation Leader" ||
                field.formFieldName === "Investigation Members" ||
                field.formFieldName === "Target completion date" ||
                field.formFieldName === "Input On Severity and Incident Type"||
                field.formFieldName === "Description of Other Training" ? 12 : 6
            }
            key={field.formFieldName}
          >
            {renderField(field, title)}
          </Grid>
        ))}
      </Grid>
    );
  };


  const [selectedPerson, setSelectedPerson] = useState(null); // To store selected person's details
  const [cardShow, setCardShow] = useState(false); // Control card visibility

  const handleToggleToShowCard = (person) => {
    setCardShow((prev) => !prev); // Toggle card visibility
    setSelectedPerson(person);    // Set the selected person's details
  };





  const handleSelect = (name, sectionName) => {
    // Map sectionName to its corresponding detail
    const sectionDetailsMap = {
      Personnel: PersonnelDetail,
      Vehicle: VehicleDetail,
      Equipment: EquipmentDetail,
      CAPA: CAPADetail,
      LTI: LTIDetail,
      Evidence: EvidenceDetail,
      Regulatory: Regulatory && Object.keys(Regulatory).length > 0 ? Regulatory : []
    };

    // Check if the specific section's detail is not empty
    const hasDetailsForSection = sectionDetailsMap[sectionName] && sectionDetailsMap[sectionName].length > 0;

    if (name === 'Yes') {
      dispatch(updateSwitchState(sectionName, name)); // Set to "Yes" directly if clicked
    } else {
      // Check if that section has details, and dispatch based on that
      dispatch(updateSwitchState(sectionName, hasDetailsForSection ? "Yes" : "No"));
    }
  };

  return (
    <Drawer
      anchor="right" // You can pass 'right', 'left', 'top', 'bottom'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: "30%", padding: '0px !important' }, // Customize drawer style
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        {title === "Services" ? (
          <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
            Resources
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
            {title}
          </Typography>
        )}

      </Grid>

      {title === "Services" ? (
        <>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
                <TabList onChange={handleTabChange} aria-label="">
                  <Tab icon={<PersonOutlineOutlinedIcon />} value="1" label="Personnel" sx={{
                    typography: 'body2', // Set base typography
                    fontSize: '12px', // Change font size
                    textTransform: 'none', // Prevent text transformation
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }} />
                  <Tab icon={<DirectionsCarOutlinedIcon />} value="2" label="Vehicle" sx={{
                    typography: 'body2', // Set base typography
                    fontSize: '12px', // Change font size
                    textTransform: 'none', // Prevent text transformation
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }} />
                  <Tab icon={<BuildOutlinedIcon />} value="3" label="Equipment" sx={{
                    typography: 'body2', // Set base typography
                    fontSize: '12px', // Change font size
                    textTransform: 'none', // Prevent text transformation
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }} />
                </TabList>
              </Box>

              <TabPanel value="1" sx={{ padding: '12px' }}>
                <Box display='flex' justifyContent="flex-end">
                  <Switch
                    checked={switchStates.Personnel === 'Yes'}
                    onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'Personnel')}
                    sx={{ ...togleStyle }}
                  />
                </Box>
                {switchStates.Personnel === 'Yes' && (
                  <>
                    <div style={{ padding: "0px 0px" }}>
                      {fields.length > 0 && fields[0] && renderFields(fields[0].formFields, "Personnel")}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} mt={3}>
                        <Button
                          variant="contained"
                          onClick={() => SubmitFormData("Personnel")}
                          sx={{
                            ...AddButtonStyle
                          }}
                        >
                         {formEdit.Personnel? "Save": "Add"}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {PersonnelDetail.length > 0 && (<CardEditDelete cardData={PersonnelDetail} title={"Personnel"} />)}
                      </Grid>
                    </Grid>
                  </>)}
              </TabPanel>

              <TabPanel value="2" sx={{ padding: '12px' }}>
                <Box display='flex' justifyContent="flex-end">
                  <Switch
                    checked={switchStates.Vehicle === 'Yes'}
                    onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'Vehicle')}
                    sx={{ ...togleStyle }}
                  />
                </Box>

                {switchStates.Vehicle === 'Yes' && (
                  <>
                    <div style={{ padding: "0px 0px" }}>
                      {fields.length > 0 && fields[1] && renderFields(fields[1].formFields, "Vehicle")}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} mt={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => SubmitFormData("Vehicle")}
                          sx={{
                            ...AddButtonStyle
                          }}
                        >
                          {formEdit.Vehicle ? "Save": "Add"}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {VehicleDetail.length > 0 && (<CardEditDelete cardData={VehicleDetail} title={"Vehicle"} />)}
                      </Grid>
                    </Grid>
                  </>)}
              </TabPanel>

              <TabPanel value="3" sx={{ padding: '12px' }}>
                <Box display='flex' justifyContent="flex-end">
                  <Switch
                    checked={switchStates.Equipment === 'Yes'}
                    onChange={(e) => handleSelect(e.target.checked ? 'Yes' : 'No', 'Equipment')}
                    sx={{ ...togleStyle }}
                  />
                </Box>
                {switchStates.Equipment === 'Yes' && (
                  <>
                    <div style={{ padding: "0px 0px" }}>
                      {fields.length > 0 && fields[2] && renderFields(fields[2].formFields, "Equipment")}
                    </div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} mt={3}>
                        <Button
                          variant="contained"
                          onClick={() => SubmitFormData("Equipment")}
                          sx={{
                            ...AddButtonStyle
                          }}
                        >
                         {formEdit.Equipment ? "Save": "Add"}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {EquipmentDetail.length > 0 && (<CardEditDelete cardData={EquipmentDetail} title={"Equipment"} />)}
                      </Grid>
                    </Grid>
                  </>)}
              </TabPanel>

            </TabContext>
          </Box>
        </>
      )
        : title === "Evidence" ? (
          <>
            <Box sx={{ padding: '24px 12px' }}>
              <Typography variant='body1' sx={{ mb: '8px' }}>Category</Typography>
              <Box display='flex' gap={1} sx={{ mb: '16px' }}>
                <CustomButtonGroup
                  buttonNames={["Photo"]}
                  selected={Evidence?.Category || ''} // Select based on Redux state value
                  onSelect={(name) => handleButtonChange("Category", name, title)}

                />
                <CustomButtonGroup
                  buttonNames={["Video"]}
                  selected={Evidence?.Category || ''} // Select based on Redux state value
                  onSelect={(name) => handleButtonChange("Category", name, title)}
                />
                <CustomButtonGroup
                  buttonNames={["Document"]}
                  selected={Evidence?.Category || ''} // Select based on Redux state value
                  onSelect={(name) => handleButtonChange("Category", name, title)}
                />
              </Box>

              <CustomFileUpload from={"Evidence"} />
              <Box paddingTop={2}>
                <CustomInput
                  placeholder="Description"
                  label="Description"
                  multiline
                  rows='4'
                  value={Evidence?.Description || ""}   // selectedEvidence={selectedEvidence}
                  onChange={(e) => handleSelectChange("Description", e.target.value, title)}

                />
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} mt={3}>
                  <Button
                    variant="contained"
                    onClick={() => SubmitFormData(title)}
                    sx={{
                      ...AddButtonStyle
                    }}
                  >
                  {formEdit.Evidence ? "Save": "Add"}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} />)}
                </Grid>
              </Grid>
            </Box>
          </>
        ) : title === "LTI" ? (
          <>
            <Box sx={{ padding: '12px 12px' }}>
              {PersonnelDetail?.filter((person) => person.outcome !== 'fertility').map((person, index) => (
                <>
                  <Box key={person.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    {/* Name and Label */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                      <Typography variant="body2" sx={{ textAlign: 'left', mb: '4px' }}> {/* Added margin-bottom for spacing */}
                        Name
                      </Typography>
                      <Typography variant="caption" sx={{ textAlign: 'left' }}>
                        {person.Name}
                      </Typography>
                    </Box>

                    {/* Type and Label */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                      <Typography variant="body2" sx={{ textAlign: 'left', mb: '4px' }}> {/* Added margin-bottom for spacing */}
                        Type
                      </Typography>
                      <Typography variant="caption" sx={{ textAlign: 'left' }}>
                        {capitalizeFirstChar(person?.["Staff Type"])}
                      </Typography>
                    </Box>

                    {/* Status Label and Toggle */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
                      <Typography variant="body2" sx={{ textAlign: 'left', mb: '4px' }}> {/* Added margin-bottom for spacing */}
                        Status
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={cardShow && selectedPerson?.id === person.id} // Show card only for the selected person
                            onChange={() => handleToggleToShowCard(person)}
                            sx={{ ...togleStyle }}
                          />
                        }
                        label="" // Remove the label next to the Switch
                        labelPlacement="start"
                      />
                    </Box>
                  </Box>

                  {index !== PersonnelDetail.length - 1 && (
                    <Divider sx={{ backgroundColor: '#C1C1C1', height: '1px' }} />
                  )}         </>
              ))}

              {/* Divider */}
              <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px', mt: '24px', mb: '24px' }} />

              {/* Display CardPerson component if cardShow is true */}
              {cardShow && selectedPerson && (
                <CardPerson cardData={selectedPerson} title={title} />
              )}
            </Box>

          </>
        ) : (
          <div style={{ padding: "28px 12px" }}>{fields.length > 0 && renderFields(fields, title)}

            {title !== "Regulatory" && title !== "Filter" && title !== "Return Back" && title !== "Review Details" && title !== "Assign Investigation" && <Grid container spacing={3}>
              <Grid item xs={12} sm={4} mt={3}>
                <Button
                  variant="contained"
                  onClick={() => SubmitFormData(title)}
                  sx={{
                    ...AddButtonStyle
                  }}
                >
                  {formEdit[title]? "Save": "Add"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} />)}
              </Grid>
            </Grid>}

            {title === "Filter" && (
              <Box display='flex' justifyContent='flex-end' mt={3}>
                <Box display='flex' gap={2}>
                  <Button
                    variant="contained"
                    onClick={() => fields.forEach((field) => resetFilter(field, title))}  // Trigger reset for all fields
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      backgroundColor: "rgba(69, 145, 244, 0.15)",
                      borderRadius: "4px",
                      color: '#4591F4',
                      boxShadow: 'none'
                    }}
                    startIcon={<CachedIcon width='20px' height='20px' />}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => ApplyFilter(title)}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      backgroundColor: '#0AA2E3',
                      boxShadow: 'none',
                      borderRadius: "4px"
                    }}
                  >
                    Apply Filter
                  </Button>
                </Box>
              </Box>
            )}

          </div>
        )}
      <Fab

        aria-label="close"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "100px",
          right: open ? "31.2%" : "0", // Adjust this value based on drawer width
          transform: "translateX(50%)",
          zIndex: 1200,
          height: "36px",
          width: "36px",
          backgroundColor: "#0AA2E3",
          borderRadius: "60px 0 0 60px",
        }}
      >
        <CloseIcon />
      </Fab>
    </Drawer>
  );
};

export default RightDrawer;
