import CryptoJS from "crypto-js";

 const passphrase = process.env.REACT_APP_ENCRIPTION_KEY;

export const encrypt = (text) => {
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const key = CryptoJS.PBKDF2(passphrase, salt, {
        keySize: 256 / 32,
        iterations: 1000
    });

    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });

    const ciphertext = encrypted.toString();
    const combinedData = `${ciphertext}:${salt.toString()}:${iv.toString()}`;

    return combinedData;
};

