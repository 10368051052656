import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomToggleButton = styled(ToggleButton)(({ active }) => ({
  border: 'none',
  color: active ? '#FFFFFF' : '#0AA2E3',
  fontSize: '14px',
  backgroundColor: active ? '#0AA2E3' : 'white',
  padding: '8px',
  '&.Mui-selected': {
    color: '#FFFFFF',
    backgroundColor: '#0AA2E3',
  },
  '&:hover': {
    backgroundColor: active ? '#0AA2E3' : '#f0f0f0',
  },
}));

export default function AcceptReturnButtonGrp({
  title = '',
  options = [],
  defaultValue = '',
  onChange,
  isDisabled = 0
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleButtonClick = (value) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);  // Pass the selected value directly to the parent
    }
  };

  return (
    <div>
      <Typography variant="body2" sx={{ color: '#666F7F', mb: '8px' }}>
        {title}
      </Typography>
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        aria-label={title.toLowerCase().replace(/\s/g, '-')}
        disabled={isDisabled}
        sx={{float:'right'}}
      >
        {options.map((option) => (
          <CustomToggleButton
            key={option.value}
            active={selectedValue === option.value}
            onClick={() => handleButtonClick(option.value)}
          >
            {option.label}
          </CustomToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
