export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const ADD_CHECKBOX = 'ADD_CHECKBOX';
export const REMOVE_CHECKBOX = 'REMOVE_CHECKBOX';
export const UPDATE_DATE_TIME = 'UPDATE_DATE_TIME';
export const ONCHANGE_DRAWER_FORM = "ONCHANGE_DRAWER_FORM";
export const ADD_PERSONNEL_DETAIL='ADD_PERSONNEL_DETAIL';
export const ADD_VEHICLE_DETAIL= 'ADD_VEHICLE_DETAIL';
export const ADD_EQUIPMENT_DETAIL= 'ADD_EQUIPMENT_DETAIL';
export const ADD_EVIDENCE_DETAIL = "ADD_EVIDENCE_DETAIL";
export const ADD_CAPA_DETAIL = "ADD_CAPA_DETAIL";
export const RESET_STATE = "RESET_STATE";
export const UPDATE_SWITCH_STATE = 'UPDATE_SWITCH_STATE';
export const EDIT_CAPA_DETAIL = "EDIT_CAPA_DETAIL";
export const DELETE_CARD_DETAIL = 'DELETE_CARD_DETAIL';
export const SET_ALL_REPORT_DATA = 'SET_ALL_REPORT_DATA';
export const REMOVE_INVESTIGATION_MEMBER = "REMOVE_INVESTIGATION_MEMBER";
export const UPDATE_EVIDENCE_FILE ="UPDATE_EVIDENCE_FILE";
export const SET_CAPA_DETAILS = 'SET_CAPA_DETAILS';

export const ADD_MITIGATION_MEASURES = "ADD_MITIGATION_MEASURES";
export const ADD_LESSON_LEARNED = "ADD_LESSON_LEARNED";
export const ADD_TO_CAUSE_ANALYSIS = 'ADD_TO_CAUSE_ANALYSIS';
export const CLEAR_CAUSES = 'CLEAR_CAUSES';
export const EDIT_TO_CAUSE_ANALYSIS ="EDIT_TO_CAUSE_ANALYSIS";
export const DELETE_TO_CAUSE_ANALYSIS ="DELETE_TO_CAUSE_ANALYSIS";
export const ADD_INCIDENT_RESPONSIBILITY = "ADD_INCIDENT_RESPONSIBILITY";
export const UPDATE_INVESTIGATION_EVIDENCE_FILE = "UPDATE_INVESTIGATION_EVIDENCE_FILE"
export const UPDATE_INVESTIGATION_WITNESS_FILE = "UPDATE_INVESTIGATION_WITNESS_FILE";
export const ADD_WITNESS_STATEMENT = "ADD_WITNESS_STATEMENT";
export const ADD_COMPENSATION_INFO = "ADD_COMPENSATION_INFO";
export const SET_COMPENSATION_INFO_DATA = "SET_COMPENSATION_INFO_DATA";
export const ADD_CONTRIBUTING_FACTOR_DETAIL = 'ADD_CONTRIBUTING_FACTOR_DETAIL';
export const EDIT_FACTOR_CARD_DETAIL= "EDIT_FACTOR_CARD_DETAIL";
export const DELETE_FACTOR_CARD_DETAIL = "DELETE_FACTOR_CARD_DETAIL";


export const UPDATE_CAUSE = 'UPDATE_CAUSE';
export const ADD_CAUSE = 'ADD_CAUSE';
export const REMOVE_CAUSE = 'REMOVE_CAUSE';
export const SET_ROOT_CAUSE_ANALYSIS = 'SET_ROOT_CAUSE_ANALYSIS';

export const SET_INCIDENT_DATA = 'SET_INCIDENT_DATA';
export const SET_WORKFLOW_INPUT_DATA="SET_WORKFLOW_INPUT_DATA";



