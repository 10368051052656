import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, InputBase } from "@mui/material";
import IncidentTopCard from "../Components/IncidentTopCard";
import CustomSelect from "../Components/CustomSelectField";
import CustomButtonGroup from "../Components/CustomButtonGroup";
import { filterIcon, listIcon, searchIcon } from "../constant";
import IncidentModuleCard from "../Components/IncidentModuleCard";
import ActionsCard from "../Components/ActionsCard";
import CustomTable from "../Components/CustomTable";
import RightDrawer from "../Components/RightDrawerIncidentReport";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllReportData,
  handleChangeDrawerFormData,
  updateFilter,
  resetFilter
} from "../redux/incidentReport/actions"; // Import your action here
import { getHistoryStatis, getMyStatiCard, getMyStatiCardByModule, getMyReports, getMyReportsByModule, getEntity, getStaffAndEntity, getFilterdropValues, ReportHistoryList, ReportCompletedHistoryList } from '../Apis/apiCall';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useLocation } from 'react-router-dom';
import { setSearchQuery } from '../redux/Loader/actions';
import Loader from "../Components/Loader/Loader";
import { START_LOADING, STOP_LOADING } from "../redux/Loader/constant"

const lossControlstatus = [
  { label: "New Survey", value: "New_Survey" },
  { label: "Revalidation", value: "Revalidation" },
  { label: "In Progress", value: "In_Progress" },
  { label: "Resubmission", value: "Resubmission" },
  { label: "Under Review", value: "Under_Review" },
  { label: "Returned", value: "Returned_By_MM" },
  { label: "Completed", value: "Completed" },
]

const incidentStatus = [
  { label: "New Incident", value: "New Incident" },
  { label: "Reported Return", value: "Reported_With_Return_Arrow" },
  { label: "Second Review", value: "Incident Review" },
  { label: "Second Review Return", value: 'Incident Revalidation' },
  { label: "Under Investigation", value: 'Investigation Initiated' },
  { label: "Under Investigation Return", value: 'Investigation Validation' },
  { label: "Investigation Review", value: "Investigation Completed" },
  { label: "Investigation Review Return", value: "Investigation Reinitiated" },
  { label: "CAPA Assigned", value: "CAPA_Assigned" },
  { label: "CAPA InProgress", value: "CAPA_In_Progress" },
  { label: "CAPA Review", value: "CAPA_Review" },
  { label: "CAPA Review Return", value: "CAPA_Review_With_Return_Arrow" },
  { label: "Incident Closed", value: "Incident Closed" }
];


export default function MyActionPage() {
  const dispatch = useDispatch();
  const locations = useLocation();
  const { moduleID, moduleName, homepage, overDue } = locations?.state || {};
  const loginDataString = localStorage.getItem("loginData");
  const data = JSON.parse(loginDataString);
  const modules = data?.config?.modulesDetail;
  const [staffNameList, setStaffNameList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [location, setLocation] = useState([]);
  const [reportingOfficer, setReportingOfficer] = useState([]);;
  const [executiveOfficerName, setExecutiveOfficerName] = useState([]);
  const [tableView, setTableView] = useState(false);
  const [selectedIncidentsToShow, setSelectedIncidentsToShow] = useState("My Action");

  const [filterForm, setFilterForm] = useState(false);
  const [fourCard, setFourCard] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { searchQuery } = useSelector((state) => state.loadingReducer);

  const [cardData, setCardData] = useState([]);
  console.log(cardData, "cardData999");
  const { Filter, allReportsData } = useSelector(
    (state) => state.incidentReportFromReducer
  );
  const roles = data?.config?.permissionDetails?.workflowRoles?.find((workflowRole) => workflowRole.moduleName === Filter.Modules)?.roles || [];
  const roleIDs = roles?.map(role => role.id);
  const loading = useSelector((state) => state.loadingReducer.loading);

  useEffect(() => {
    if (homepage) {
      dispatch(updateFilter({ fieldName: moduleName, moduleId: moduleID, moduleName: "Filter" }));
      if (overDue > 0) {
        dispatch(handleChangeDrawerFormData({ fieldName: "overDue", value: 1, title: "Filter" }))
      }
    }
    dispatch(setSearchQuery(""));
    fetchReportCardData();
    fetchTopCardData();
    getAllfilterOptions();


  }, []);

  const getAllfilterOptions = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getFilterdropValues();
      const formattedExecutiveOfficers = result.data?.data?.executiveOfficers?.map(officer => ({
        value: officer?.name,
        label: officer?.name
      }));

      const formattedReportingOfficers = result.data?.data?.reportingOfficers?.map(officer => ({
        value: officer?.name,
        label: officer?.name
      }));

      const formattedLocations = result.data?.data?.locations?.map(location => ({
        value: location?.displayName,
        label: location?.displayName
      }));

      // Set the state for each dropdown
      setExecutiveOfficerName(formattedExecutiveOfficers);
      setReportingOfficer(formattedReportingOfficers);
      setLocation(formattedLocations);
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  useEffect(() => {
    if (Filter?.ModuleId != "") {
      fetchReportCardDataByModule(Filter.ModuleId);
      fetchTopCardDataMyModule(Filter.ModuleId)
    }

  }, [Filter.ModuleId]);

  useEffect(() => {
    if (Filter?.ModuleId != "") {
      dispatch(handleChangeDrawerFormData({ fieldName: "overDue", value: "", title: "Filter" }));
      if (selectedIncidentsToShow === "Completed") {
        fetchTopCardDataHistoy(Filter.ModuleId, roleIDs, true, "my")
      }
      else if (selectedIncidentsToShow === "My History") {
        fetchTopCardDataHistoy(Filter.ModuleId, roleIDs, false, "my")
      } else {
        fetchTopCardDataMyModule(Filter.ModuleId)
      }
    }

  }, [selectedIncidentsToShow]);


  const fetchTopCardDataMyModule = async (value) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getMyStatiCardByModule(value);
      setCardData(result.data.data);
      console.log("kkkkkkk")
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const fetchTopCardDataHistoy = async (moduleID,roleIDs,status ,action) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getHistoryStatis(moduleID, roleIDs, status, action);
      setCardData(result.data.data);
      console.log("kkkkkkk")
    } catch (error) {
      console.log(error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }


  const fetchTopCardData = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getMyStatiCard();
      console.log("api res", result.data.data);
      setCardData(result.data?.data);
    } catch (error) {
      console.log(error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const fetchReportCardData = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getMyReports();
      dispatch(setAllReportData(result.data?.data));
    } catch (error) {
      console.log(error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const fetchReportCardDataByModule = async (value) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getMyReportsByModule(value);
      console.log("api res", result.data.data);
      dispatch(setAllReportData(result.data?.data));
    } catch (error) {
      console.log(error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const handleInputChange = (e, fieldName, title) => {
    if (fieldName === "Modules") {
      let id;
      const { value } = e.target;  // Destructure the value from the event directly

      // Set label based on the value
      if (value === "Incident Management") {
        id = 1;
      } else {
        id = 2;
      }

      // Dispatch the action with label and value
      dispatch(updateFilter({ fieldName: value, moduleId: id, moduleName: title }));
    } else {
      const { value } = e.target; // For other fields, get value from target
      dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
    }
  };


  const incidentFilterFormFields = [
    {
      formFieldID: 29,
      formFieldName: "Incident Type",
      dataType: "String",
      type: "ButtonGroup",
      isEditable: 0,
      options: ["UA", "UC", "NM", "Minor", "MR", "DO","Serious", "Fatal"],
    },
    {
      formFieldID: 30,
      formFieldName: "Severity",
      dataType: "Number",
      type: "ButtonGroup",
      isEditable: 0,
      options: ["1", "2", "3", "4", "5","NA"],
    },
    {
      formFieldID: 31,
      formFieldName: "Shift",
      dataType: "String",
      type: "ButtonGroup",
      isEditable: 0,
      options: ["I", "II", "III", "General"],
    },
    {
      formFieldID: 36,
      formFieldName: "Incident Time",
      dataType: "Date",
      type: "Date",
      isEditable: 0,
      options: null,
    },
    {
      formFieldID: 42,
      formFieldName: "Staff Type",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: [
        { label: "Employee", value: "Employee" },
        { label: "Executive", value: "Executive" },
        { label: "Contactor", value: "Contactor" },
        { label: "Visitor", value: "Visitor" },
        { label: "Workperson", value: "Workperson" },
        { label: "Executive", value: "Executive" },
      ],
    },
    {
      formFieldID: 41,
      formFieldName: "Reported By - Staff Name",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: staffNameList,
    },
    {
      formFieldID: 40,
      formFieldName: "Reported By - Entity Name",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: entityList,
    },


    {
      formFieldID: 43,
      formFieldName: "Incident Location",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: location,
    },
    {
      formFieldID: 44,
      formFieldName: "Department",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: ["Mining", "Mechanical", "Electrical", "Civil", "Personal", "Survey", "Security", "IT", "Others"],
    },
    {
      formFieldID: 45,
      formFieldName: "Status",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: incidentStatus,
    },
    {
      formFieldID: 46,
      formFieldName: "Reporting Officer",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: reportingOfficer,
    },
  ];

  const lossFilterFormFields = [
    {
      formFieldID: 1,
      formFieldName: "Executive Name",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: executiveOfficerName,
    },
    {
      formFieldID: 2,
      formFieldName: "Discipline",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: ["Asset", "Drilling", "OGPS", "PSS", "GMS & LPG", "Engineering", "Finance & Other", "HR", "Security", "Medical"],
    },

    {
      formFieldID: 3,
      formFieldName: "Date",
      dataType: "Date",
      type: "Date",
      isEditable: 0,
      options: null,
    },


    {
      formFieldID: 4,
      formFieldName: "Location",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: location,
    },

    {
      formFieldID: 5,
      formFieldName: "Status",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: lossControlstatus,
    },

  ];



  const handleFilterClose = () => {

    setFilterForm(false);

  };



  useEffect(() => {
    console.log(Filter?.["Staff Type"]);
    if (Filter?.["Staff Type"]) {
      fetchStaffEntitylist(Filter["Staff Type"]);
    }
  }, [Filter["Staff Type"]]);

  useEffect(() => {
    console.log(Filter?.["Staff Type"]);
    if (Filter?.["Staff Type"] && Filter?.["Reported By - Staff Name"]) {
      fetchEntity(Filter?.["Staff Type"], Filter?.["Reported By - Staff Name"]);
    }
  }, [Filter["Reported By - Staff Name"]]);

  const fetchStaffEntitylist = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getStaffAndEntity(Filter?.["Staff Type"]);
      console.log(result.data.data);

      // Map the staff array to the desired format (key as `id` and label as `firstName` + `lastName`)
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: `${staffMember.firstName} ${staffMember.lastName}`
      }));

      setStaffNameList(formattedStaffList); // Set the formatted staff list

    } catch (error) {
      console.log(error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const fetchEntity = async (type, id) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getEntity(type, id);
      console.log(result.data.data);
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: staffMember.name
      }));

      setEntityList(formattedStaffList); // Set the formatted staff list

    } catch (error) {
      console.log(error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  useEffect(() => {
    if (Filter.Modules === "Incident Management") {
      const filteredReports = allReportsData.filter((report) => {
        const matchesRole = !Filter.Roles || report.role === Filter.Roles;
        const matchesStatus = !Filter.Status ||
          (Array.isArray(report.status)
            ? report.status.includes(Filter.Status)
            : report.status === Filter.Status);
        const matchesSeverity =
          !Filter.Severity || report.Severity == Filter.Severity;
        const matchesShift = !Filter.Shift || report.Shift === Filter.Shift;
        const matchesIncidentType =
          !Filter["Incident Type"] ||
          report?.["Incident Type"] === Filter["Incident Type"];
        const matchesIncidentTime =
          !Filter["Incident Time"] ||
          report?.["Incident Time"] === Filter["Incident Time"];
        const matchesStaffType =
          !Filter["Staff Type"] ||
          report?.["Staff Type"] === Filter["Staff Type"];
        const matchesReportedStaffName =
          !Filter["Reported By - Staff Name"] ||
          report["Reported By - Staff Name"] === Filter["Reported By - Staff Name"];
        const matchesReportedEntityName =
          !Filter["Reported By - Entity Name"] ||
          report["Reported By - Entity Name"] === Filter["Reported By - Entity Name"];
        const matchesIncidentLocation =
          !Filter["Incident Location"] || report["Incident Location"] === Filter["Incident Location"];
        const matchesDepartment =
          !Filter.Department || report.Department === Filter.Department;
        const matchesOverDue =
          !Filter["overDue"]
          || report.overdueTaskCount >= Filter["overDue"];

        return (
          matchesRole &&
          matchesStatus &&
          matchesIncidentTime &&
          matchesSeverity &&
          matchesIncidentType &&
          matchesStaffType &&
          matchesShift &&
          matchesReportedStaffName &&
          matchesReportedEntityName &&
          matchesIncidentLocation &&
          matchesDepartment &&
          matchesOverDue
        );
      });
      // .filter(module => module.data.length > 0);

      const isAnyFilterApplied = Object.values(Filter).some((value) => value);
      if (isAnyFilterApplied) {
        setFourCard(true);
      }

      const isModuleSet = Filter.Modules;

      // Check if any other key in Filter (excluding 'Module') has a value
      const hasOtherFilters = Object.keys(Filter).some(
        (key) => key != "Modules" && key != "ModuleId" && Filter[key]
      );

      // Apply filtering only if both conditions are met
      if (isModuleSet && hasOtherFilters) {
        setFilterApplied(true); // Apply filtering
        setFilteredData(filteredReports.length > 0 ? filteredReports : []);
      } else {
        setFilterApplied(false); // Reset filter state
        setFilteredData([]); // Clear the filtered data if filters are not applied
      }
    }
    if (Filter.Modules === "Loss Control") {
      const filteredReports = allReportsData.filter((report) => {
        const matchesRole = !Filter.Roles ||
          (Array.isArray(report?.historyRoles) && report?.historyRoles.includes(Filter.Roles));

        const matchesStatus = !Filter.Status ||
          (Array.isArray(report.status) ? report.status.includes(Filter.Status) : report.status === Filter.Status);

        const matchesDiscipline = !Filter.Discipline || report.discipline === Filter.Discipline;

        const matchesDate = !Filter["Date"] || report?.["Tour Date"] === Filter["Date"];

        const matchesExecutiveName = !Filter["Executive Name"] || report["Executive Name"] === Filter["Executive Name"];

        const matchesOverDue =
          !Filter["overDue"]
          || report.overdueTaskCount >= Filter["overDue"];


        const matchesLocation = !Filter["Location"] || report.locationName === Filter["Location"];

        return (
          matchesRole &&
          matchesDate &&
          matchesStatus &&
          matchesDiscipline &&
          matchesExecutiveName &&
          matchesOverDue &&
          matchesLocation
        );
      });

      const isAnyFilterApplied = Object.values(Filter).some(value => value !== undefined && value !== null && value !== "");

      if (isAnyFilterApplied) {
        setFourCard(true);
      }

      const isModuleSet = !!Filter.Modules;
      const hasOtherFilters = Object.keys(Filter).some(
        (key) => key !== "Modules" && key !== "ModuleId" && Filter[key]
      );

      // Apply filtering only if both conditions are met
      if (isModuleSet && hasOtherFilters) {
        setFilterApplied(true); // Apply filtering
        setFilteredData(filteredReports.length > 0 ? filteredReports : []);
      } else {
        setFilterApplied(false); // Reset filter state
        setFilteredData([]); // Clear the filtered data if filters are not applied
      }
    }


  }, [Filter, selectedIncidentsToShow]);




  const handleIncidentsToShow2 = (fieldName) => {
    setSelectedIncidentsToShow((prev) => {
      const newSelected = fieldName;
      filterMyIncidentOnly(newSelected);
      return newSelected;
    });
  };

  const filterMyIncidentOnly = async (fieldName) => {
    // Determine data to set based on the field name clicked
    if (fieldName === "My Action") {
      fetchReportCardDataByModule(Filter.ModuleId);
    }

    if (fieldName === "My History") {
      const dataToSet = await fetchHistoryByRole(Filter.ModuleId, roleIDs);
      dispatch(setAllReportData(dataToSet))
    }
    if (fieldName === "Completed") {
      const data = await fetchCompletedHistoryList(Filter.ModuleId, roleIDs);
      dispatch(setAllReportData(data))
    }


  };

  const fetchHistoryByRole = async (ModuleId, roleIDs) => {
    dispatch({type : START_LOADING})
    try {
      const result = await ReportHistoryList(ModuleId, roleIDs);
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  const fetchCompletedHistoryList = async (ModuleId, roleIDs) => {
    dispatch({type : START_LOADING})
    try {
      const result = await ReportCompletedHistoryList(ModuleId, roleIDs);
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  const handleSearchIconClick = () => {
    setShowSearchInput((prev) => !prev); // Toggle visibility
    if (showSearchInput) setSearchQuery(''); // Reset query when hiding input
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    dispatch(setSearchQuery(query)); // Update search query
  };

  const handleResetFilter = () => {
    setSelectedIncidentsToShow(null);
    setFourCard(false)
    dispatch(resetFilter());
  }


  return (
    <>
      {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
      <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
      {/* top cards */}
      <Grid container spacing={0} mb={3} sx={{ width: "100%" }}>
        {Filter.Modules === "Incident Management" ? (
          <>
            {cardData?.map((item, index) => (
              <Grid key={index} item xs={12} md={fourCard && selectedIncidentsToShow == "My Action" ? 3:fourCard ? 4  : 4} sx={{ width: "100%" }}>
                <IncidentTopCard
                  data={{
                    name: item.title,                 // Title from the data
                    info: "Monthly Trend", // Static info
                    percentage: `${item.percentage}%`, // Percentage value
                    value: item.value,
                    indicator: item.indicator              // Value from the data
                  }}
                />
              </Grid>
            ))}
          </>

        ) : Filter.Modules === "Loss Control" ? (
          <>
            {cardData?.map((item, index) => (
              <Grid key={index} item xs={12} md={4} sx={{ width: "100%" }}>
                <IncidentTopCard
                  data={{
                    name: item.title,                 // Title from the data
                    info: "Monthly Trend", // Static info
                    percentage: `${item.percentage}%`, // Percentage value
                    value: item.value,
                    indicator: item.indicator                              // Value from the data
                  }}
                />
              </Grid>
            ))}

          </>
        ) : (
          <>
            {cardData?.map((item, index) => (
              <Grid key={index} item xs={12} md={fourCard ? 3 : 4} sx={{ width: "100%" }}>
                <IncidentTopCard
                  data={{
                    name: item.title,                 // Title from the data
                    info: "Monthly Trend", // Static info
                    percentage: `${item.percentage}%`, // Percentage value
                    value: item.value,
                    indicator: item.indicator                              // Value from the data
                  }}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>

      <Grid
        container
        spacing={0}
        sx={{
          borderRadius: "4px",
          backgroundColor: "#F9FAFC",
          boxShadow: "0px 0px 12px -4px rgba(0, 0, 0, 0.25)",
          padding: "20px",
          width: "100%",
        }}
      >
        {/* Filter Dropdowns */}
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomSelect
                label="Modules"
                value={Filter?.Modules}
                options={modules?.map((obj) => ({
                  label: obj.moduleName,
                  value: obj.moduleName,
                }))}
                onChange={(e) => handleInputChange(e, "Modules", "Filter")}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomSelect
                label="Roles"
                value={Filter?.Roles}
                options={roles?.map((obj) => ({
                  label: obj.name,
                  value: obj.name,
                }))}
                onChange={(e) => handleInputChange(e, "Roles", "Filter")}
                disable={!fourCard}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomSelect
                label="Status"
                value={Filter?.Status}
                options={Filter?.Modules === "Loss Control" ? lossControlstatus : incidentStatus}
                onChange={(e) => handleInputChange(e, "Status", "Filter")}
                disable={!fourCard}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={12}>
          <Grid container spacing={2} mt="20px" mb="16px">
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                {(Filter.Modules === "Incident Management" || Filter.Modules === "Loss Control") && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <CustomButtonGroup
                        buttonNames={["My Action"]}
                        selected={selectedIncidentsToShow}
                        onSelect={(name) => handleIncidentsToShow2(name)}
                      />
                    </Grid>
                    <Grid item>
                      <CustomButtonGroup
                        buttonNames={["My History"]}
                        selected={selectedIncidentsToShow}
                        onSelect={(name) => handleIncidentsToShow2(name)}
                      />
                    </Grid>

                    <Grid item>
                      <CustomButtonGroup
                        buttonNames={["Completed"]}
                        selected={selectedIncidentsToShow}
                        onSelect={(name) => handleIncidentsToShow2(name)}
                      />
                    </Grid>


                  </Grid>
                )}
              </Grid>

              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <RefreshOutlinedIcon sx={{ color: "#8FA6CC", fontSize: "32px", borderRadius: "4px", background: "#EEF2FA", p: "4px" }} onClick={handleResetFilter} />
                  </Grid>
                  {fourCard && (
                    <Grid item>
                      <AccessTimeFilledIcon sx={{ color: "#8FA6CC", fontSize: "32px", color: "#CC0000", borderRadius: "4px", background: Filter.overDue == 1 ? "#0aa2e3" : "#EEF2FA", p: "4px" }} onClick={() => dispatch(handleChangeDrawerFormData({ fieldName: "overDue", value: 1, title: "Filter" }))} />
                    </Grid>)}

                  {fourCard && (
                    <Grid item>
                      <img
                        src={filterIcon}
                        alt="filter"
                        style={{ cursor: "pointer" }}
                        onClick={() => setFilterForm(true)}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {/* Search Icon */}
                      <img
                        src={searchIcon}
                        alt="search"
                        style={{ cursor: 'pointer', marginRight: 10 }}
                        onClick={handleSearchIconClick}
                      />
                      {/* Search Input */}
                      {showSearchInput && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: '#EEF2FA',
                            borderRadius: '8px',
                            minWidth: { xs: '50%', md: '25%' },
                            height: '36px',
                          }}
                        >
                          <InputBase
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            sx={{
                              padding: '0 10px',
                              width: '100%',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid item>
                    <img
                      src={listIcon}
                      alt="list"
                      style={{ cursor: "pointer" }}
                      onClick={() => setTableView(!tableView)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!tableView && (
          <Grid item xs={12} sx={{ width: "100%" }}>
            {" "}
            {/* Adjust height as needed */}
            <Grid container spacing={2} sx={{ width: "100%" }}>
              {fourCard ? (
                <IncidentModuleCard
                  cardData={filterApplied ? filteredData : allReportsData}
                  title={Filter.Modules}
                  mode={(selectedIncidentsToShow === "My History" || selectedIncidentsToShow === "Completed") ? "view" : "action"}
                  tab={selectedIncidentsToShow}
                />
              ) : !fourCard && allReportsData?.length > 0 ? (
                <IncidentModuleCard cardData={allReportsData} title={"All"} mode={"action"} tab={selectedIncidentsToShow} />
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ width: "100%", padding: "20px" }}
                >
                  No data available.
                </Typography>
              )}
            </Grid>
          </Grid>
        )}

        {tableView && (
          <Grid item xs={12} sx={{ width: "100%" }}>
            {fourCard ? (
              <CustomTable data={filterApplied ? filteredData : allReportsData} title={Filter.Modules} mode={(selectedIncidentsToShow === "My History" || selectedIncidentsToShow === "Completed") ? "view" : "action"} tab={selectedIncidentsToShow} />
            ) : (
              <CustomTable data={allReportsData} title={"All"} mode={"action"} tab={selectedIncidentsToShow} />
            )}
          </Grid>
        )}
      </Grid>

      <RightDrawer
        open={filterForm}
        onClose={handleFilterClose}
        title="Filter"
        fields={Filter.Modules === "Loss Control" ? lossFilterFormFields : incidentFilterFormFields}
        cardData={[]}
      />
    </Box>
    </>
    
  );
}
