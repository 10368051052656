import React from 'react';
import { Autocomplete, TextField, MenuItem, Checkbox, ListItemText, Chip } from '@mui/material';

const CustomMultipleSelect = ({ label, value = [], options = [], onChange, name }) => {
    const safeOptions = Array.isArray(options) ? options : [];
    
    // Ensure value is an array to avoid 'value.map' error
    const safeValue = Array.isArray(value) ? value : [];

    // Helper function to get the label for both object and string options
    const getOptionLabel = (option) => {
        return typeof option === 'object' && option !== null ? option.label : option;
    };

    // Determine if an option is selected based on the current value
    const isSelected = (option) => {
        return safeValue.includes(typeof option === 'object' ? option.value : option);
    };

    return (
        <Autocomplete
            multiple // Enable multiple selections
            options={safeOptions}
            ListboxProps={{ style: { maxHeight: 160 ,padding:0 } }}
            getOptionLabel={(option) => getOptionLabel(option)}
            value={safeOptions.filter(opt => 
                safeValue.includes(typeof opt === 'object' ? opt.value : opt)
            )} // Filter options based on selected values
            onChange={(event, newValue) => {
                // Extract values from selected options
                const selectedValues = newValue.map((newVal) =>
                    typeof newVal === 'object' ? newVal.value : newVal
                );
                // Trigger the onChange event with selected values
                onChange({ target: { name, value: selectedValues } });
            }}
            isOptionEqualToValue={(option, value) =>
                typeof option === 'object' && option !== null
                    ? option.value === value
                    : option === value
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    placeholder="Select"
                    size="small"
                    inputProps={{ ...params.inputProps, style: { fontSize: "12px", minHeight:"30px" } }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
            renderOption={(props, option) => (
                <MenuItem {...props} key={typeof option === 'object' ? option.value : option}>
                    <ListItemText
                        primary={<span style={{ fontSize: "12px" }}>{getOptionLabel(option)}</span>}
                    />
                </MenuItem>
            )}
            renderTags={(value, getTagProps) => 
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        label={getOptionLabel(option)}
                        size="small"
                        {...getTagProps({ index })}
                        sx={{
                            fontSize: '12px',  // Set the font size for the chip
                            height: '22px',    // Set the height for the chip
                                 // Adjust margin if needed
                        }}
                    />
                ))
            }
            disableClearable
            sx={{
                minHeight: '40px',
                '& .MuiAutocomplete-inputRoot': {
                    minHeight: '40px',
                    display: 'flex',
                    alignItems: 'center',
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        top: 0,
                    },
                    '&:hover fieldset': {
                        borderColor: '#3f51b5',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#3f51b5',
                    },
                },
            }}
        />
    );
};

export default CustomMultipleSelect;
