import React, { useEffect, useRef, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  addMonths,
  subMonths,
} from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarCard from "./CalendarCard";
import { getCalendarDateActions } from "../../Apis/apiCall";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux"; // Import Redux hooks
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant";


const locales = { "en-US": require("date-fns/locale/en-US") };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});


const calendarStyles = `
  .rbc-header {
    padding: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #666;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .rbc-date-cell {
    padding: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-start;
    // border-right: 1px solid #ddd;
  }
  
  .rbc-month-view {
    background-image: 
      linear-gradient(#e0e0e0 1px, transparent 1px),
      linear-gradient(90deg, #e0e0e0 1px, transparent 1px);
    background-size: calc(100% / 7) 100%, calc(100% / 7) 100%;
    background-position: -1px -1px;
    background-color: #fff;
    border-radius: 8px
  }
 
  
  .rbc-today {
    background-color: #f5f5f5;
  }

  .selected-date {
    background-color: #E0F7FA !important;
  }

  .rbc-event {
    background-color: transparent !important;
    // border-right: 1px solid #ddd;
    box-shadow: none !important;
    margin: 2px !important;
  }

  .rbc-event.rbc-selected {
    background-color: transparent !important;
  }

  .module-chip {
    margin: 2px;
    font-size: 11px;
    height: 20px;
    border-radius: 10px;
    background-color: #e3f2fd;
    color: #1976d2;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
  }
    
`;

const CustomToolbar = ({ date, onNavigate, onMonthChange }) => {
  const handleNavigation = (action) => {
    onNavigate(action);
    const newDate = action === "NEXT" ? addMonths(date, 1) : subMonths(date, 1);
    onMonthChange(newDate.getMonth() + 1, newDate.getFullYear());
  };

  return (
    <Box display="flex" alignItems="center" sx={{padding: '0px 0px 12px 0px'}} gap={3}>
      <IconButton onClick={() => handleNavigation("PREV")} size="small">
        <ArrowBackIos sx={{ fontSize: 24, color: "#3C3E43" }} />
      </IconButton>
      <IconButton onClick={() => handleNavigation("NEXT")} size="small">
        <ArrowForwardIos sx={{ fontSize: 24, color: "#3C3E43" }} />
      </IconButton>
      <Typography variant="h6" sx={{ fontWeight: 500 }}>
        {format(date, "MMMM yyyy")}
      </Typography>
    </Box>
  );
};

const CalendarView = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [cardData, setCardData] = useState(null);
  const [cardOpen, setCardOpen] = useState(false);
  const [selectedModuleName, setSelectedModuleName] = useState("");

  const cardRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, [currentMonth, currentYear]);

  const fetchEvents = async () => {
    dispatch({type : START_LOADING})
    try {
      const response = await getCalendarDateActions(currentMonth, currentYear);
      const formattedEvents = response.data.data.map((dateData) => ({
        date: new Date(dateData.date),
        modules: dateData.data.filter((module) => module.actions.length > 0),
        allDay: true,
        start: new Date(dateData.date),
        end: new Date(dateData.date),
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  const handleMonthChange = (month, year) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const selectedEvent = events.find(
      (event) => event.date.toDateString() === date.toDateString()
    );
    if (selectedEvent) {
      setSelectedModuleName(selectedEvent.modules[0]?.moduleName || "");
    }
  };

  const handleActionClick = (action, moduleName) => {
    setCardData(action);
    setSelectedModuleName(moduleName);
    setCardOpen(true);
  };

  const handleCloseCard = () => {
    setCardOpen(false);
    setCardData(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        handleCloseCard();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const EventComponent = ({ event }) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
      {event?.modules?.map((module, index) => {
      // Check if any action in the module is overdue
      const hasOverdueAction = module?.actions?.some(
        action => action.actionStatus?.toLowerCase() === 'overdue'
      );

      return module?.actions?.length > 0 && (
        <Box
          key={index}
          sx={{
            display: "flex",
            padding: "2px 12px 2px 6px",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch",
            borderRadius: "0px 2px 2px 0px",
            borderLeft: hasOverdueAction 
              ? "2px solid #E53935"  
              : "2px solid #0AA2E3",
            background: hasOverdueAction
              ? "linear-gradient(0deg, rgba(229, 57, 53, 0.10) 0%, rgba(229, 57, 53, 0.10) 100%), #FFF" 
              : "linear-gradient(0deg, rgba(10, 162, 227, 0.10) 0%, rgba(10, 162, 227, 0.10) 100%), #FFF", 
            color: hasOverdueAction ? "#E53935" : "#0AA2E3", 
            fontSize: "10px",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {module.moduleName}
        </Box>
      );
    })}
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row",padding: '0px 0px 12px 0px' },
        gap: 2,
      }}
    >

      {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
      {/* Calendar Section */}
      <Box sx={{ width: { xs: "100%", md: "75%" }, borderRadius: 2 }}>
        <style>{calendarStyles}</style>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "calc(95vh - 100px)" }}
          views={["month"]}
          defaultView="month"
          selectable
          selected={selectedDate}
          onSelectSlot={({ start }) => handleDateSelect(start)}
          components={{
            toolbar: (props) => (
              <CustomToolbar {...props} onMonthChange={handleMonthChange} />
            ),
            event: EventComponent,
            dateCellWrapper: ({ value, children }) => (
              <Box
                onClick={() => handleDateSelect(value)}
                className={
                  selectedDate?.toDateString() === value.toDateString()
                    ? "selected-date"
                    : ""
                }
                sx={{ cursor: "pointer", height: "100%"}}
              >
                {children}
              </Box>
            ),
          }}
        />
      </Box>

      {/* Sidebar Section */}
      <Box sx={{ width: { xs: "100%", md: "25%" }, mt: { xs: 2, md: 6 } }}>
        <Container
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            height: "calc(88.5vh - 100px)",
            p: 2,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ borderBottom: "1px solid #B7C8E5", pb: 1 }}
          >
            {format(selectedDate, "MMMM d, yyyy")}
          </Typography>

          {events
            ?.find(
              (event) =>
                event.date.toDateString() === selectedDate.toDateString()
            )
            ?.modules.map((module, index) =>
              module.actions.length > 0 ? (
                <Accordion
                  key={index}
                  sx={{ mt: 1, boxShadow: "none", p: "0px !important" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid rgba(9, 162, 227, 0.70)",
                      backgroundColor: "rgba(9, 162, 227, 0.10)",
                    }}
                  >
                    <Typography>{module.moduleName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {module?.actions?.map((action, actionIndex) => (
                      <Box
                        key={actionIndex}
                        onClick={() =>
                          handleActionClick(action, module.moduleName)
                        }
                        sx={{
                          mt: 1,
                          p: 1,
                          cursor: "pointer",
                          borderRadius: "2px",
                          borderLeft: action.actionStatus === "Overdue" ? "4px solid rgba(255, 87, 83, 0.80)" : "4px solid rgba(9, 162, 227, 0.70)",
                          backgroundColor: action.actionStatus === "Overdue" ? " rgba(255, 87, 83, 0.07) " : "rgba(9, 162, 227, 0.07)",
                        }}
                      >
                        <Typography variant="caption" fontWeight='600'>
                          Recommendation ID: {action.questionID}
                        </Typography>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Typography variant="body2" color="text.secondary" mt="8px">
                  No events for this date
                </Typography>
              )
            )}
        </Container>
      </Box>

      {/* Calendar Card */}
      {cardOpen && cardData && (
        <Box
          ref={cardRef}
          sx={{
            position: "absolute",
            right: "22%",
            top: "50%",
            transform: "translateY(-50%)",
            width: "260px",
            boxShadow: 3,
            zIndex: 1000,
            borderRadius: "8px",
            backgroundColor: "white",
          }}
        >
          <CalendarCard
            cardData={cardData}
            onClose={handleCloseCard}
            title={selectedModuleName}
          />
        </Box>
      )}
    </Box>
  );
};

export default CalendarView;
