import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';

const SafetyTipSlider = ({ data = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Automatically change slides every 3 seconds
  useEffect(() => {
    if (data.length > 0) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % data.length);
      }, 3000);

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, [data.length]);

  const handleDotClick = (index) => {
    setCurrentSlide(index); // Set the slide manually when dot is clicked
  };

  if (data.length === 0) {
    return <Typography>No safety tips available</Typography>;
  }

  return (
    <>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 600,
          lineHeight:1,
          mb: 1
        }}
      >
        Safety Tips
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          flexDirection: 'row',
          backgroundColor: '#F1F0F1', // Light gray background
          borderRadius: '8px',
          boxShadow: '0px 4px 4px rgba(97, 97, 97, 0.5)', // Subtle shadow for depth
        }}
      >
        {/* Left-aligned image */}
        {data[currentSlide]?.icon && (
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <img
              src={data[currentSlide]?.icon}
              height="50px"
              width="50px"
              alt="Safety Icon"
              style={{ objectFit: 'contain' }}
            />
          </Box>
        )}

        {/* Right-aligned text */}
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body2"
            sx={{
              color: "#9A9FA5",
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            {data[currentSlide]?.tip
              ? data[currentSlide]?.tip.charAt(0).toUpperCase() + data[currentSlide]?.tip.slice(1)
              : "No tip"}
          </Typography>
        </Box>
      </Box>

      {/* Dots for manual control */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
        {data.map((_, index) => (
          <Box
            key={index}
            onClick={() => handleDotClick(index)}
            sx={{
              height: 6,
              width: 6,
              borderRadius: '50%',
              backgroundColor: index === currentSlide ? '#9A9FA5' : '#ccc',
              mx: 0.5,
              cursor: 'pointer',
            }}
          />
        ))}
      </Box> */}
    </>
  );
};

export default SafetyTipSlider;
