import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DoughnutChart from "../../Components/ChartAndGraphs/DoughnutChart";
import BarGraph from "../../Components/ChartAndGraphs/BarGraph";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { overDueImage, cloudImage } from "../../constant";
import { useNavigate } from "react-router-dom";
import SafetyTipSlider from "../../Components/AlertSliderShow";
import LineGraph from "../../Components/ChartAndGraphs/LineGraph";
import CombinedBarLineGraph from "../../Components/ChartAndGraphs/CombinedBarLineGraph";
import {
  DashBoardData,
  DashBoardDataWithFilter,
  getAssignedLocationsTree,
  dashboardBroadCast,
  getAllLocationName,
} from "../../Apis/apiCall";
import { useSelector, useDispatch } from "react-redux";
import WelcomeUserCard from "../../Components/welcomeUserCard";
import BroadcastSlideshow from "../../Components/BroadCastInfoSlide";
import CloseIcon from "@mui/icons-material/Close";
import OrgTreeView from "../../Components/OrgTree";
import { showTreeModel } from "../../redux/Loader/actions";
import BodyChart from "../../Components/ChartAndGraphs/BodyChart";
import PyramidChart from "../../Components/ChartAndGraphs/PyramidChart";

// pyraimd data -------------------///
const sampleData = [
  { label: "Candidates applied", value: 100 },
  { label: "Initial validation", value: 75 },
  { label: "Screening", value: 45 },
  { label: "Telephone interview", value: 30 },
  { label: "In-person interview", value: 18 },
  { label: "Employee hired", value: 8 },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(1),
  borderRadius: "12px",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const chartData = {
  datasets: [
    {
      label: "location",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "#D9DEC1",
        "#FFCA58",
        "#BCE1A5",
        "#A1CFDD",
        "#A1A0EC",
        "#FF9567",
      ],
      borderColor: [
        "#D9DEC1",
        "#FFCA58",
        "#BCE1A5",
        "#A1CFDD",
        "#A1A0EC",
        "#FF9567",
      ],
      borderWidth: 1,
    },
  ],
};

const BarGraphData = {
  labels: ["January", "February", "March", "April", "May"],
  datasets: [
    {
      label: "Dataset 1",
      data: [5, 8, 3, 7, 6],
      backgroundColor: ["#FFD780", "#76d7c4", "#BCE1A5", "#FF9C41", "#ec7063"],
    },
    {
      label: "Dataset 2",
      data: [6, 9, 2, 6, 7],
      backgroundColor: ["#FFD780", "#ec7063", "#36A2EB", "#3498db", "#76d7c4"],
    },
  ],
};

const LineGraphData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      label: "Dataset 1",
      data: [500, 700, 1000, 800, 600, 400, 300],
      borderColor: "rgba(154, 137, 255, 0.7)",
      backgroundColor: "rgba(154, 137, 255, 0.7)",
      pointBackgroundColor: "rgba(154, 137, 255, 0.7)",
      pointRadius: 6,
      fill: false, // No fill under the line
    },
    {
      label: "Dataset 2",
      data: [300, 500, 400, 600, 700, 500, 400],
      borderColor: "rgba(251, 227, 142, 0.7)",
      backgroundColor: "rgba(251, 227, 142, 0.7)",
      pointBackgroundColor: "rgba(251, 227, 142, 0.7)",
      pointRadius: 6,
      fill: false, // No fill under the line
    },
  ],
};

const CombinedBarLineGraphData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      type: "bar",
      label: "Bar Dataset 1",
      data: [-30, 40, 20, -50, 30, -20, 50],
      backgroundColor: "rgba(0, 255, 127, 0.5)", // Light green
      // borderColor: 'rgba(0, 255, 127, 1)', // Darker green for border
      borderWidth: 1,
    },
    {
      type: "bar",
      label: "Bar Dataset 2",
      data: [-50, 60, 30, -20, 20, -30, 40],
      backgroundColor: "rgba(0, 0, 255, 0.5)", // Light blue
      // borderColor: 'rgba(0, 0, 255, 1)', // Darker blue for border
      borderWidth: 1,
    },
    {
      type: "line",
      label: "Line Dataset",
      data: [30, 50, 40, 60, 45, 30, -70],
      borderColor: "rgba(254, 174, 174, 0.7)", // Red for the line
      backgroundColor: "rgba(254, 174, 174, 0.7)",
      pointBackgroundColor: "rgba(254, 174, 174, 0.7)",
      pointBorderColor: "rgba(254, 174, 174, 1)",
      pointRadius: 6,
      fill: false,
    },
  ],
};

const AuditDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [role, setRole] = useState(null);
  const [userName, setUserName] = useState(null);
  const [viewCount, setViewCount] = useState(null);
  const [overDueCount, setOverDueCount] = useState(null);
  const loginDataString = localStorage.getItem("loginData");
  const loginData = loginDataString ? JSON.parse(loginDataString) : null;
  const locationID = loginData?.currentLocationID;
  const [allLocationsReportCountIDs, setAllLocationsReportCountIDs] = useState(
    []
  );
  const { dashboardFilter } = useSelector(
    (state) => state.lossControlReducer.tours
  );
  const { isShowTreeModel } = useSelector((state) => state.loadingReducer);
  const [boardCasteData, setBoardCasteData] = useState({
    broadcasts: [],
    safetyTips: [],
    greetings: [],
  });

  const [allLoactionName, setAllLoactionName] = useState([]);

  useEffect(() => {
    isShowTreeModel && dispatch(showTreeModel());
    getallLocationName();
  }, []);

  useEffect(() => {
    if (dashboardFilter?.location.length > 0) {
      fetchChartData(dashboardFilter?.period, dashboardFilter?.location);
    } else {
      fetchChartData(dashboardFilter?.period, locationID);
    }
  }, [dashboardFilter]);

  useEffect(() => {
    fetchBroadCasteData();
    const roles = localStorage.getItem("IncidentRoles");
    setRole(roles);
    const loginDataString = localStorage.getItem("loginData");
    if (loginDataString) {
      const data = JSON.parse(loginDataString);
      const { firstName } = data;
      setUserName(firstName);
    }
  }, []);

  const fetchChartData = async (month, locationID) => {
    try {
      let result;
      if (month || locationID) {
        result = await DashBoardDataWithFilter(month, locationID);
      } else {
        result = await DashBoardData();
      }
      const data = result.data.data;

      // Setting Dashboard Data from here

      setViewCount(result.data.data.view);
      setOverDueCount(result.data.data.overdue);
      setAllLocationsReportCountIDs(
        data.allLocationsReportCount.map((location) => location.id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBroadCasteData = async () => {
    try {
      const result = await dashboardBroadCast();
      setBoardCasteData(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getallLocationName = async () => {
    try {
      const result = await getAllLocationName();
      setAllLoactionName(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseOverlay = () => {
    dispatch(showTreeModel());
  };

  const handleButtonClick = () => {
    navigate("/module/audit-management/audit-perform");
  };

  const handleScheduleButtonClick = () => {
    navigate("/module/audit-management/audit-schedule");
  };

  const handleStepchange = (value) => {
    setStep(value);
  };

  const handleActionPage = ({ overDue }) => {
    if (overDue) {
      if (
        role?.includes(
          "Reporting Officer",
          "First Reviewer",
          "Second Reviewer",
          "Investigator",
          "CAPA Owner"
        )
      ) {
        navigate("/my-actions", {
          state: {
            moduleID: 1,
            moduleName: "Incident Management",
            homepage: true,
            overDue: 1,
          },
        });
      } else {
        navigate("/module/incident-management/view-incidents", {
          state: {
            moduleID: 1,
            moduleName: "Incident-Management",
            homepage: true,
            overDue: 1,
          },
        });
      }
    } else {
      navigate("/module/incident-management/view-incidents", {
        state: {
          moduleID: 1,
          moduleName: "Incident-Management",
          homepage: true,
          overDue: 0,
        },
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        gap={2}
        alignItems="center"
        marginBottom={1.6}
      >
        <FiberManualRecordIcon
          onClick={() => handleStepchange(0)}
          sx={{ color: step === 0 ? "#0AA2E3" : "#AEAEB1", fontSize: "12px" }}
        />
        <FiberManualRecordIcon
          onClick={() => handleStepchange(1)}
          sx={{ color: step === 1 ? "#0AA2E3" : "#AEAEB1", fontSize: "12px" }}
        />
        <FiberManualRecordIcon
          onClick={() => handleStepchange(2)}
          sx={{ color: step === 2 ? "#0AA2E3" : "#AEAEB1", fontSize: "12px" }}
        />
      </Box>

      {step === 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-By Type
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-By Severity
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-By Time of the day
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-Top 5 Areas
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-Top 5 Sub-Areas
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-By Worker types
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-By Shifts
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-By Departments
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-By Resources
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
              </Grid>
            </Grid>

            {/* Main Grid of 4 columns */}
            <Grid item xs={12} md={12} lg={3}>
              <Grid container spacing={1}>
                {isShowTreeModel ? (
                  <Grid item xs={12}>
                    <Item
                      sx={{
                        minHeight: "600px",
                        maxHeight: "600px",
                        overflowY: "auto", // Enables vertical scrolling
                        padding: "8px", // Optional: Adjust for inner content spacing
                        scrollbarWidth: "none", // Hides scrollbar in Firefox
                        "-ms-overflow-style": "none", // Hides scrollbar in Internet Explorer and Edge
                        "&::-webkit-scrollbar": {
                          display: "none", // Hides scrollbar in Chrome, Safari, and Edge
                        },
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 600,
                          float: "left",
                          mt: 1,
                          ml: "6px",
                        }}
                      >
                        All Locations
                      </Typography>

                      <IconButton
                        onClick={handleCloseOverlay}
                        sx={{ float: "right" }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <OrgTreeView data={allLoactionName} />
                    </Item>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Item>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between", // Ensures content is spread with text on left, image on right
                            width: "100%", // Full width to allow proper alignment
                            lineHeight: 0,
                          }}
                        >
                          {/* Left side: Typography content */}
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600 }}
                            >
                              {boardCasteData?.greetings[0]?.message
                                ? boardCasteData?.greetings[0]?.message
                                : "Welcome"}
                            </Typography>
                            <Typography variant="caption">
                              {userName
                                ? userName.charAt(0).toUpperCase() +
                                  userName.slice(1)
                                : "User"}
                            </Typography>
                          </Box>

                          {/* Right side: Image */}
                          <WelcomeUserCard />
                        </Box>
                      </Item>
                    </Grid>
                    <Grid item xs={12}>
                      <Item>
                        <BroadcastSlideshow
                          broadcasts={boardCasteData?.broadcasts}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={12}>
                      <Item>
                        <SafetyTipSlider data={boardCasteData?.safetyTips} />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="medium"
                        sx={{
                          color: "#fff",
                          bgcolor: "#0AA2E3",
                          width: "100px",
                          boxShadow: " 4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          height: "34px",
                          padding: "8px 68px",
                          justifyContent: "center",
                          flex: "1 0 0",
                          alignItems: "center",
                          marginBottom: "12px",
                        }}
                        onClick={handleScheduleButtonClick}
                      >
                        Schedule
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="medium"
                        sx={{
                          color: "#fff",
                          bgcolor: "#0AA2E3",
                          width: "100px",
                          boxShadow: " 4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          height: "34px",
                          padding: "8px 68px",
                          justifyContent: "center",
                          flex: "1 0 0",
                          alignItems: "center",
                          marginBottom: "12px",
                        }}
                        onClick={handleButtonClick}
                      >
                        Perform
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="medium"
                        sx={{
                          color: "#fff",
                          bgcolor: "#0AA2E3",
                          width: "100px",
                          boxShadow: " 4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          height: "34px",
                          padding: "8px 68px",
                          justifyContent: "center",
                          flex: "1 0 0",
                          alignItems: "center",
                          marginBottom: "12px",
                        }}
                        // onClick={handleButtonClick}
                      >
                        Manage
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="medium"
                        sx={{
                          color: "#fff",
                          bgcolor: "#0AA2E3",
                          width: "100px",
                          boxShadow: " 4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          borderRadius: "4px",
                          height: "34px",
                          padding: "8px 68px",
                          justifyContent: "center",
                          flex: "1 0 0",
                          alignItems: "center",
                          marginBottom: "12px",
                        }}
                        // onClick={handleButtonClick}
                      >
                        Action
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {step === 1 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={3}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-Top 5 Injury Types
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-Top 5 Locations UA/UC/NM
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-Top 5 Locations of Injuries
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-Top 5 Contactor Entities
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents-Top 5 Reporters
                    </Typography>
                    <DoughnutChart data={chartData} title="Topic" />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={9}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents- All Injury Types
                    </Typography>
                    <BarGraph data={BarGraphData} />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents- All Locations
                    </Typography>
                    <BarGraph data={BarGraphData} />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents- By Type-All Locations
                    </Typography>
                    <BarGraph data={BarGraphData} />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents- By Severity-All Locations
                    </Typography>
                    <BarGraph data={BarGraphData} />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Incidents - By Contractors
                    </Typography>
                    <BarGraph data={BarGraphData} />
                  </Item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {step === 2 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <Item sx={{ height: "580px" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Pyramid Chart
                    </Typography>
                    <PyramidChart data={sampleData} />
                  </Item>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Item sx={{ height: "580px" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                        fontWeight: 600,
                        marginBottom: 1,
                      }}
                    >
                      Body chart
                    </Typography>
                    <BodyChart />
                  </Item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default AuditDashboard;
