import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustomStepper from '../Components/CustomStepper'; // Assuming it's a custom component
import { Paper, FormGroup, Typography,Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import IncidentReviewTabsSection from '../Components/IncidentReviewTab';
import { capitalizeFirstChar } from '../utils/helper';
import { useSelector } from 'react-redux';
import FloatingButton from '../Components/FloatButton';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    color: theme.palette.text.secondary,
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.20)',
}));




export default function IncidentReview() {
    const entireData = useSelector(state => state.incidentReportFromReducer);
    const entireData2 = [entireData];
    const activeStep = useSelector((state) => state.stepReducer.activeStep);
    const isFormComplete = useSelector((state) => state.stepReducer.isFormComplete);

    return (
        <Box component="main" sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CustomStepper steps={["Incident Info", "Review"]} />
                        </Grid>
                        <Grid item xs={12}>
                            <Item>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">Incident Type</Typography>
                                        <Typography variant="caption">{entireData && entireData["Incident Type"] ? capitalizeFirstChar(entireData["Incident Type"]) : "NA"}</Typography>
                                    </Grid>


                                    <Grid item xs={6}>
                                        <Typography variant="body2">Reported By-Staff Name</Typography>
                                        <Typography variant="caption">{entireData && entireData["Reported By - Staff Name"] ? entireData["Reported By - Staff Name"] : "NA"}</Typography>
                                    </Grid>



                                    {/* Second Row - Two Items */}
                                    <Grid item xs={6}>
                                        <Typography variant="body2">Incident Time</Typography>
                                        <Typography variant="caption">{entireData && entireData["Incident Time"] ? entireData["Incident Time"] : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">Report Time</Typography>
                                        <Typography variant="caption">{entireData && entireData["Report Time"] ? entireData["Report Time"] : "N/A"}</Typography>
                                    </Grid>

                                    {/* Third Row - Three Items */}
                                    <Grid item xs={6}>
                                        <Typography variant="body2">Staff Type</Typography>
                                        <Typography variant="caption">{entireData && entireData["Staff Type"] ? capitalizeFirstChar(entireData["Staff Type"]) : "N/A"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">Reported By-Entity Name</Typography>
                                        <Typography variant="caption">{entireData && entireData["Reported By - Entity Name"] ? entireData["Reported By - Entity Name"] : "NA"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">Severity</Typography>
                                        <Typography variant="caption">{entireData && entireData.Severity ? entireData.Severity : "NA"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                            {Array.isArray(entireData.GeneralInfo) && entireData.GeneralInfo.length > 0
                                                ? entireData.GeneralInfo
                                                    .filter(value =>
                                                        typeof value === 'string' &&
                                                        value.trim() !== '' &&
                                                        value !== 'Others' // Exclude 'Others'
                                                    )
                                                    .map((value, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={value}
                                                            size="small"
                                                            
                                                            sx={{ fontSize: '12px', height: '24px', bgcolor:"#9ad6f0" }}
                                                        />
                                                    ))
                                                : <Chip label="N/A" size="small" sx={{ fontSize: '12px', height: '24px' }} />
                                            }

                                            {/* Render 'Others' as a chip if it exists */}
                                            {entireData?.Others && (
                                                <Chip
                                                    label={entireData.Others}
                                                    size="small"                                                    
                                                    sx={{ fontSize: '12px', height: '24px' ,bgcolor:"#9ad6f0" }}
                                                />
                                            )}
                                        </Box>

                                    </Grid>

                                </Grid>

                            </Item>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={8}>
                                    <Item>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">Department</Typography>
                                                <Typography variant="caption">{capitalizeFirstChar(entireData.Department)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">Incident Location</Typography>
                                                <Typography variant="caption">{entireData && entireData["Incident Location"] ? entireData["Incident Location"] : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">Area</Typography>
                                                <Typography variant="caption">{entireData && entireData["Area"] ? capitalizeFirstChar(entireData["Area"]) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">Sub Area</Typography>
                                                <Typography variant="caption">{entireData && entireData["Sub Area"] ? capitalizeFirstChar(entireData["Sub Area"]) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">Activity</Typography>
                                                <Typography variant="caption">{entireData && entireData.Activity ? capitalizeFirstChar(entireData.Activity) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">Sub Activity</Typography>
                                                <Typography variant="caption">{entireData && entireData["Sub Activity"] ? capitalizeFirstChar(entireData["Sub Activity"]) : "NA"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Item>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Item>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Operation Sta...</Typography>
                                                <Typography variant="caption">{entireData && entireData["Operation Status"] ? capitalizeFirstChar(entireData["Operation Status"]) : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Shift</Typography>
                                                <Typography variant="caption">{entireData && entireData.Shift ? entireData.Shift : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Losses Type</Typography>
                                                <Typography variant="caption">{entireData && entireData?.["Losses Type"] ? capitalizeFirstChar(entireData?.["Losses Type"]) : "NA"}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2">Immediate Last Accident</Typography>
                                                <Typography variant="caption">{capitalizeFirstChar(entireData["Immediate Last Accident"])}</Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2">Date of Accident</Typography>
                                                <Typography variant="caption">{entireData && entireData["Date of Accident"] ? entireData["Date of Accident"] : "NA"}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">Cause</Typography>
                                                <Typography variant="caption">{capitalizeFirstChar(entireData["Cause of Accident"])}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Item>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


                {/* ---------------------------second section rightside ------------------------------ */}

                <Grid item xs={12} md={8}>
                    <Grid container spacing={1} item xs={12}>
                        <Grid item xs={12}>
                            <Item>
                                <Typography variant="body2" >Incident Short Summary</Typography>
                                <Typography variant="caption"> {entireData && entireData["Incident Short Summary"] ? entireData["Incident Short Summary"] : "N/A"}</Typography>
                                <Typography variant="body2" >Incident Summary</Typography>
                                <Typography variant="caption"> {entireData && entireData["Incident Summary"] ? entireData["Incident Summary"] : "N/A"}</Typography>
                                <Typography variant="body2" >Immediate Measures</Typography>
                                <Typography variant="caption"> {entireData && entireData["Immediate Measures"] ? entireData["Immediate Measures"] : "N/A"}</Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item>
                                <IncidentReviewTabsSection data={entireData2} title={"review"} />
                            </Item>
                        </Grid>
                    </Grid>
                </Grid>
                {(activeStep === 1 && isFormComplete) && (<Box
                    sx={{
                        position: 'fixed',
                        bottom: 30, // Distance from the bottom
                        right: 24, // Distance from the right
                        zIndex: 1000, // Ensure it's above other components
                    }}
                >
                    <FloatingButton />

                </Box>)}
            </Grid >

        </Box >
    )
}
