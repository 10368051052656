import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  TextField,
  Avatar,
  Menu,
  MenuItem,
  Snackbar,
  IconButton,
  Typography,
  Divider,
  ListItemText,
  ListItem,
  List,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardFilter } from "../../redux/LossControl/actions";
import { logoutUser } from "../../Apis/apiCall"; // Assuming this is your logout function
import { showTreeModel } from "../../redux/Loader/actions";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ProfileMenuBG,
} from "../../constant";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";



const drawerWidth = 227;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#FCFCFC",
  boxShadow: "0.833px 0px 0px 0px #F4F4F4 inset",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: `calc(100% - 75px)`, // Adjust width when the sidebar is closed
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

const periodOptions = [
  { label: "Last Month", value: 1 },
  { label: "Last Quartry", value: 3 },
  { label: "Last Six Month", value: 6 },
  { label: "Last Year", value: 12 },
  { label: "Since Inception", value: 0 },
];

export default function CustomAppBar({ open }) {
  const location = useLocation(); // Get current URL path
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const targetPaths = ["/module/loss-control",  "module/incident-management","/home"];
  const [anchorEl, setAnchorEl] = useState(null);
  const isTargetPath = targetPaths.some((path) =>
    location.pathname.includes(path)
  );

  const { dashboardFilter } = useSelector(
    (state) => state.lossControlReducer.tours
  );

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleDrawerOpen = () => {
    dispatch(showTreeModel());
  };

  const handleMapView = () => {
    navigate(`/loss-control/map-view`);
  };

  const handleInputChange = (value, key) => {
    if (value === 0) {
      dispatch(updateDashboardFilter({ key, value }));
    }
    dispatch(updateDashboardFilter({ key, value }));
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleProfileMenuClose();
    try {
      const result = await logoutUser();
      if (result.data.is_error === 0) {
        localStorage.clear();
        window.location.href = "/login";
      } else {
        setSnackbar({
          open: true,
          message: "Error while logging out super admin",
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Error logging out",
        severity: "error",
      });
      console.error("Error logging out:", error);
    }
  };

  const handleCalenderView = () => {
    navigate(`/calender`);
    
  };

  const handleClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl1(null);
  };

  const ProfileMenu = styled(Menu)({
    "& .MuiPaper-root": {
      width: 290,
      padding: '0px !important'
    },
  });

  const ProfileButton = styled(Button)({
    width: "100%",
    marginTop: "16px",
    backgroundColor: "#007bff",
    color: "#fff",
    borderRadius: "20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
  });

  const handleChangePassword = () => {
    navigate(`/profile/change-password`)
    handleProfileMenuClose();
    handleClose();
  };

  const handleViewProfile = () => {
    navigate(`/profile/view-profile`)
    handleProfileMenuClose();
    handleClose();
  }

  const handleSupportView = () => {
    navigate(`/support`)
  }

  const loginDataString = localStorage.getItem("loginData");
  const loginData = loginDataString ? JSON.parse(loginDataString) : null;

  const IncidentRoles = JSON.parse(
    localStorage.getItem("IncidentRoles") || "[]"
  );
  const LCRoles = JSON.parse(localStorage.getItem("lossControlRoles") || "[]");
  const modules = [
    {
      name: "Incident Management",
      roles: IncidentRoles,
    },
    {
      name: "Loss Control",
      roles: LCRoles,
    },
  ];

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ maxHeight: "56px", padding: "0px !important" }}
        open={open}
        elevation={0}
      >
        <Toolbar sx={{ alignItems: "center" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ backgroundColor: "#F4F4F4", borderRadius: "10px", p: 0 }}
            >
              <SearchIcon style={{ color: "#000000" }} />
              <TextField
                placeholder="Search"
                variant="outlined"
                size="small"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                sx={{
                  height: "42px",
                  "& .MuiInputBase-input": {
                    color: "#000",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#000",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: 0,
                  },
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" gap="24px">
              {isTargetPath && (
                <>
                  <Box display="flex" alignItems="center">
                    <IconButton sx={{ color: "gray" }}>
                      <CalendarMonthOutlinedIcon />
                    </IconButton>
                    <Button
                      variant="text"
                      endIcon={<KeyboardArrowDownIcon />}
                      onClick={handleClick}
                      sx={{ color: "black", textTransform: "none" }}
                    >
                      {dashboardFilter.period
                        ? periodOptions.find(
                            (option) => option.value === dashboardFilter.period
                          )?.label
                        : "Select Period"}
                    </Button>
                    <Menu
                      anchorEl={anchorEl1}
                      open={Boolean(anchorEl1)}
                      onClose={handleClose}
                    >
                      {periodOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          selected={option.value === dashboardFilter.period}
                          onClick={() =>
                            handleInputChange(option.value, "period")
                          }
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                  {/* <CustomSelect
                    label={"Period"}
                    value={dashboardFilter?.period}
                    options={periodOptions || ""}
                    onChange={(e) => handleInputChange(e, "period")}
                  /> */}
                  <LocationOnOutlinedIcon
                    style={{ width: "20px", height: "20px", color: "#000000" }}
                    onClick={handleDrawerOpen}
                  />
                </>
              )}
              <MapOutlinedIcon
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  cursor: "pointer",
                }}
                onClick={handleMapView}
              />
              <HeadsetMicOutlinedIcon
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  cursor: "pointer",
                }}
                onClick = {handleSupportView}
              />
              <CalendarTodayOutlined
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  cursor: "pointer",
                }}
                onClick={handleCalenderView}
              />
              <NotificationsOutlinedIcon
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
              <Avatar
                alt="Profile"
                src={`${loginData?.img}`}
                onClick={handleProfileMenuOpen}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Profile Menu */}
      <ProfileMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundImage: `url(${ProfileMenuBG})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: 1,
            borderRadius: "0px 0px 27.062px 27.062px",
            paddingTop:0,
          }}
        >
          <Avatar
            src={`${loginData?.img}`}
            sx={{ width: 70, height: 70, mr: 2, mt: 2 }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {loginData?.middleName || loginData?.lastName ?
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginTop: "8px", color: "white" }}
            >
              {`${loginData?.firstName} ${loginData?.middleName} ${loginData?.lastName && loginData?.lastName}`}
            </Typography> :
            <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginTop: "8px", color: "white" }}
          >
            {`${loginData?.firstName}`}
          </Typography> 
            }
            <Typography variant="body2" sx={{ color: "white" }}>
              {loginData?.email}
            </Typography>
            <Typography variant="body2" sx={{ color: "white" }}>
              {loginData?.mobileNumber}
            </Typography>
            <ProfileButton
              sx={{ backgroundColor: "white", color: "#09A2E3", gap: 2 }}
              onClick={handleViewProfile}
            >
              View Profile <KeyboardArrowRightIcon sx={{ fontSize: "16px" }} />{" "}
            </ProfileButton>
          </Box>
        </Box>
        <Box mt={2}>
          <MenuItem onClick={handleChangePassword}>
            <LockOutlinedIcon sx={{ marginRight: "8px", color: "#747474" }} />
            <Box gap={8} sx={{ display: "flex", flexDirection: "row" }}>
              <Typography sx={{ color: "#747474" }}>
                {" "}
                Change Password{" "}
              </Typography>
              <KeyboardArrowRightIcon
                sx={{ fontSize: "20px", color: "#747474" }}
              />
            </Box>
          </MenuItem>
          <Divider variant="middle" sx={{height:'4px'}}/>
          <MenuItem>
            <PsychologyOutlinedIcon
              sx={{ marginRight: "8px", color: "#09A2E3" }}
            />
            <Typography sx={{ color: "#09A2E3" }}>Roles</Typography>
          </MenuItem>

          <Divider variant="middle" />

          {modules?.map((module, index) => (
            <Accordion key={index} disableGutters sx={{ p: "0px !important",boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="body1" sx={{ color: "#747474" }}>
                  {module.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{padding:'0px !important'}}>
                <List disablePadding>
                  {module?.roles.length > 0 ? (
                    module?.roles.map((role, idx) => (
                      <ListItem key={idx} sx={{ paddingLeft: 1.5 }}>
                        <KeyboardArrowRightIcon
                          fontSize="small"
                          sx={{ marginRight: 1, color: "#747474" }}
                        />
                        <ListItemText primary={role} sx={{ color: "#747474" }} />
                      </ListItem>
                    ))
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{ color: "#B0B0B0", paddingLeft: 4 }}
                    >
                      No roles available
                    </Typography>
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}

          <Divider variant="middle" />
          <MenuItem onClick={handleLogout}>
            <PowerSettingsNewOutlinedIcon
              color="error"
              sx={{ marginRight: "8px" }}
            />
            <Typography sx={{ color: "red" }}>Log Out</Typography>
          </MenuItem>
        </Box>
      </ProfileMenu>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        severity={snackbar.severity}
        sx={{ zIndex: 1000 }}
      />
    </>
  );
}
