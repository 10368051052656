import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CloseIcon from "@mui/icons-material/Close";


const AuditTemplateDialog = ({
  open,
  onClose,
  templates,
  onTemplateSelect,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleSelect = (template) => {
    setSelectedTemplate(template);
    onTemplateSelect(template);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box display="flex" flexDirection='column' gap={1}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#E6F7FF',
                        borderRadius: '50%',
                        width: '48px', // Set equal width and height for a perfect circle
                        height: '48px',
                    }}
                >
                <InsertDriveFileOutlinedIcon fontSize="32px" sx={{color: '#09A2E3'}}/>
                </Box>
                <Box>
            <Typography sx={{color:'#15294B', fontSize:'20px', fontWeight:'600'}}>Select Audit Template</Typography>
            </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List sx={{overflow:'auto', height:'500px', padding:'10px', borderRadius: '10px', border: '1px solid #F5F6F7'}}>
          {templates.map((template, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                selected={selectedTemplate === template}
                onClick={() => handleSelect(template)}
                sx={{
                  borderRadius: 1,
                  marginBottom: 1,
                  border: selectedTemplate === template ? 'none' : '1px solid #F5F6F7',
                  backgroundColor: selectedTemplate === template ? '#EAF9FF' : 'transparent',
                }}
              >
                <ListItemIcon>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#ffffff',
                        borderRadius: '50%',
                        width: '32px', // Set equal width and height for a perfect circle
                        height: '32px',
                        border: selectedTemplate === template ? 'none' : '1px solid #CBCBCB'
                    }}
                  >
                  <InsertDriveFileOutlinedIcon fontSize="16px" sx={{color: selectedTemplate === template ? '#09A2E3' : '#909090'}}/>
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={template}
                  primaryTypographyProps={{
                    sx: {
                      color: selectedTemplate === template ? '#09A2E3' : '#091E42',
                      fontSize: '14px',
                      fontWeight: '700',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Box gap={1} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', px: 2 }}>
          <Button onClick={onClose} variant="outlined" color="primary" sx={{width: '100%'}}>
            Cancel
          </Button>
          <Button
            onClick={() => onClose()}
            variant="contained"
            color="primary"
            disabled={!selectedTemplate}
            sx={{width: '100%', color:'white'}}
          >
            Done
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AuditTemplateDialog;
