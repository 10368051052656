import React from 'react';
import { Chip, Box, Typography } from '@mui/material';

// Define a reusable StatusChip component
const StatusChip = ({ label, color, bgColor }) => (
  <Chip
    label={label}
    sx={{
      color: color,
      backgroundColor: bgColor,
      fontWeight: 400,
      fontSize:"12px",
      height:"24px",
     
    }}
  />
);

export default StatusChip;