import React, { useEffect, useState } from "react";
import { cloudImage } from "../constant";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { rainCloud } from "../constant";

const WelcomeUserCardTwo = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState(null);

  const API_KEY = "e4dbc97b1b674b6ad3cd32d2ae95aa5c"; // Replace with your API key

  useEffect(() => {
    const loginDataString = localStorage.getItem("loginData");
    if (loginDataString) {
      const data = JSON.parse(loginDataString);
      const { latitude, longitude } = data;
      setLatitude(latitude);
      setLongitude(longitude);
    }
  });

  useEffect(() => {
    if (latitude && longitude) {
      // Fetch weather data when we have the latitude and longitude
      const fetchWeatherData = async () => {
        try {
          const response = await axios.get(
            `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`
          );
          console.log(response.data);
          setWeather(response.data);
        } catch (error) {
          console.error("Error fetching the weather data", error);
          setError("Error fetching weather data");
        }
      };

      fetchWeatherData();
    }
  }, [latitude, longitude, API_KEY]);

  //style={{mixBlendMode:"color-burn"}}

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Typography
            variant="h6"
            component="h2"
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "28px",
            }}
          >
            Heavy Rain
          </Typography>
          {latitude && longitude && 
                (<Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#0AA2E3",
                    display: "flex",
                  }}
                >
                {Math.round(weather?.main.temp)}°C
                </Typography>)
                }
        
          
        
        <img src={rainCloud} alt="Rain Cloud" />
      </Box>
    </>
  );
};

export default WelcomeUserCardTwo;
