import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckboxSelect = ({ label, checked, onChange, disabled = false }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          color="primary"
          size="small"
          sx={{ '& .MuiTypography-root': { fontSize: '12px' } }}
        />
      }
      label={label.charAt(0).toUpperCase() + label.slice(1)}
    />
  );
};

export default CheckboxSelect;
