import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomButtonGroup from "../../Components/CustomButtonGroup";
import TemplateDialog from "./AuditTemplateDialog";
import AuditSitesTreeDialog from "./AuditSitesTree";
import AuditProtocolDialog from "./AuditProtocolDialog";
import AuditAssignMemberDialog from "./AuditAssignMemberDialog";
import AuditWorkflowDialog from "./AuditWorkflowDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedDate,
  setSelectedTemplate,
  setSelectedWorkflow,
} from "../../redux/audit/action";

const NewAuditScheduleCreate = () => {
    const dispatch = useDispatch();

  const [selectTemplateModal, setSelectTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [auditWorkflowModal, setAuditWorkflowModal] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState([]);
  const [auditSitesTreeModal, setAuditSitesTreeModal] = useState(false);
  const [auditProtocolModal, setAuditProtocolModal] = useState(false);
  const [auditAssignMemberModal, setAuditAssignMemberModal] = useState(false);
  



  const selectedDates = useSelector((state) => state.auditReducer.selectedDate);

  useEffect(() => {
    console.log("Hello", selectedDates);
  }, []);

  const templates = [
    "Template 1",
    "Template 2",
    "Template 3",
    "Template 4",
    "Template 5",
    "Template 6",
    "Template 7",
    "Template 8",
    "Template 9",
    "Template 10",
    "Template 11",
    "Template 12",
  ];

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    dispatch(setSelectedTemplate(template)); // Dispatch action
  };

  const workflow = [
    "Audit Scheduled",
    "Audit Conducted",
    "Section Head Review",
    "Report submitted to IM & Section Head",
    "Compliance report by IM",
    "Compliance report Approval",
    "Final Review",
    "Audit Closed",
  ];

  const handleWorkflowSelect = (workflowStep) => {
    setSelectedWorkflow(workflowStep);
    dispatch(setSelectedWorkflow(workflowStep)); // Dispatch action
  };
  
  

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "600px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        paddingX: "16px",
        paddingY: "24px",
      }}
    >
      <Grid container rowSpacing={3} columnSpacing={1}>
        <Grid item xs={12} md={6}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Audit Name
          </Typography>
          <Grid container spacing={1.5}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  height: "32px",
                  borderRadius: "8px",
                  border: "1px solid #D0D5DD",
                }}
                // placeholder="Please enter Audit Name"
                // value={name}
                // onChange={(e) => setName(e.target.value)}
                inputProps={{
                  style: {
                    height: "32px",
                    borderRadius: "8px",
                    padding: "0 14px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#09A2E3",
                  height: "32px",
                  whiteSpace: "nowrap",
                  borderRadius: "4px",
                }}
                onClick={() => setSelectTemplateModal(true)}
              >
                Select Template
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Audit Start Date
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(params) => (
                <TextField {...params} fullWidth variant="outlined" />
              )}
              format="DD-MM-YYYY"
              // value={auditStartDate ? dayjs(auditStartDate) : null}
              // onChange={(newValue) => handleDateChange(newValue)}
              // minDate={dayjs(tourDate)}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    "& .MuiOutlinedInput-input": {
                      height: "1rem", // Adjust height as needed
                      padding: "0.5rem", // Adjust padding for proper alignment
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Audit End Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(params) => (
                <TextField {...params} fullWidth variant="outlined" />
              )}
              format="DD-MM-YYYY"
              // value={auditEndDate ? dayjs(auditEndDate) : null}
              // onChange={(newValue) => handleDateChange(newValue)}
              // minDate={dayjs(tourDate)}
              slotProps={{
                textField: {
                  size: "small",
                  sx: {
                    "& .MuiOutlinedInput-input": {
                      height: "1rem", // Adjust height as needed
                      padding: "0.5rem", // Adjust padding for proper alignment
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Audit Locations
          </Typography>
          <CustomButtonGroup
            buttonNames={["Site", "All Site"]}
            selected={""} // Select based on data
            onSelect={(name) => console.log(name)} // Update field
          />
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Sites
          </Typography>
          <Box
            sx={{
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              cursor: "pointer",
            }}
            onClick={() => setAuditSitesTreeModal(true)}
          ></Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Category
          </Typography>
          <FormControl variant="outlined" fullWidth>
            {/* <InputLabel id="dropdown-label">Options</InputLabel> */}
            <Select
              labelId="dropdown-label"
              // value={value}
              // onChange={handleChange}
              // label="Options"
              sx={{
                height: "32px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MenuItem value={10}>Option 1</MenuItem>
              <MenuItem value={20}>Option 2</MenuItem>
              <MenuItem value={30}>Option 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Section
          </Typography>
          <CustomButtonGroup
            buttonNames={["Audit Areas", "Preconfigured Audit Protocol"]}
            selected={""} // Select based on data
            // onSelect={(name) => console.log(name)}  // Update field
            onSelect={() => setAuditProtocolModal(true)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Recurring
          </Typography>
          <CustomButtonGroup
            buttonNames={["Yes", "No"]}
            selected={""} // Select based on data
            onSelect={(name) => console.log(name)} // Update field
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Audit Leader
          </Typography>
          <Box
            sx={{
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              cursor: "pointer",
            }}
            onClick={() => setAuditAssignMemberModal(true)}
          ></Box>
        </Grid>
        <Grid item xs={6}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Audit Team Member
          </Typography>
          <Box
            sx={{
              height: "32px",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              cursor: "pointer",
            }}
            onClick={() => setAuditAssignMemberModal(true)}
          ></Box>
        </Grid>
        <Grid item xs={6}>
          <Typography
            gutterBottom
            sx={{ color: "#374151", fontSize: "14px", fontWeight: "500" }}
          >
            Audit Workflow
          </Typography>
          <CustomButtonGroup
            buttonNames={["Custom", "Standard"]}
            selected={""} // Select based on data
            // onSelect={(name) => console.log(name)}  // Update field
            onSelect={() => setAuditWorkflowModal(true)}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          right: "16px",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          // onClick={() => handleBack()}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{ color: "white", backgroundColor: "#09A2E3" }}
          // onClick={() => handleSubmitButton()}
        >
          Submit
        </Button>
      </Box>
      <TemplateDialog
        open={selectTemplateModal}
        onClose={() => setSelectTemplateModal(false)}
        templates={templates}
        onTemplateSelect={handleTemplateSelect}
      />
      <AuditSitesTreeDialog
        open={auditSitesTreeModal}
        onClose={() => setAuditSitesTreeModal(false)}
        onNext={console.log("nxt")}
      />
      <AuditProtocolDialog
        open={auditProtocolModal}
        onClose={() => setAuditProtocolModal(false)}
      />
      <AuditAssignMemberDialog
        open={auditAssignMemberModal}
        onClose={() => setAuditAssignMemberModal(false)}
      />
      <AuditWorkflowDialog
        open={auditWorkflowModal}
        onClose={() => setAuditWorkflowModal(false)}
        workflow={workflow}
      />
    </Box>
  );
};

export default NewAuditScheduleCreate;
