// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    width: 50px;
    aspect-ratio: 1;
    display:grid;
    -webkit-mask: conic-gradient(from 15deg,#0000,#000);
    animation: l26 1s infinite steps(12);
  }
  .loader,
  .loader:before,
  .loader:after{
    background:
      radial-gradient(closest-side at 50% 12.5%,
       #09a2e3 96%,#0000) 50% 0/20% 80% repeat-y,
      radial-gradient(closest-side at 12.5% 50%,
       #09a2e3 96%,#0000) 0 50%/80% 20% repeat-x;
  }
  .loader:before,
  .loader:after {
    content: "";
    grid-area: 1/1;
    transform: rotate(30deg);
  }
  .loader:after {
    transform: rotate(60deg);
  }
  
  @keyframes l26 {
    100% {transform:rotate(1turn)}
  }`, "",{"version":3,"sources":["webpack://./src/Components/Loader/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,YAAY;IACZ,mDAAmD;IACnD,oCAAoC;EACtC;EACA;;;IAGE;;;;gDAI4C;EAC9C;EACA;;IAEE,WAAW;IACX,cAAc;IACd,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;;EAEA;IACE,MAAM,uBAAuB;EAC/B","sourcesContent":[".loader {\n    width: 50px;\n    aspect-ratio: 1;\n    display:grid;\n    -webkit-mask: conic-gradient(from 15deg,#0000,#000);\n    animation: l26 1s infinite steps(12);\n  }\n  .loader,\n  .loader:before,\n  .loader:after{\n    background:\n      radial-gradient(closest-side at 50% 12.5%,\n       #09a2e3 96%,#0000) 50% 0/20% 80% repeat-y,\n      radial-gradient(closest-side at 12.5% 50%,\n       #09a2e3 96%,#0000) 0 50%/80% 20% repeat-x;\n  }\n  .loader:before,\n  .loader:after {\n    content: \"\";\n    grid-area: 1/1;\n    transform: rotate(30deg);\n  }\n  .loader:after {\n    transform: rotate(60deg);\n  }\n  \n  @keyframes l26 {\n    100% {transform:rotate(1turn)}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
