import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Grid
} from "@mui/material";
import CustomInput from './CustomInputField';
import CustomeDatePicker from './CustomeDate';
import { handleChangeDrawerFormData, setLTIDetails, addLTIDetail } from '../redux/incidentReport/actions';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { capitalizeFirstChar } from "../utils/helper";
import { Alert } from '@mui/material';


export default function CardPerson({ cardData, title }) {
  const dispatch = useDispatch();
  const { LTI, LTIdetail } = useSelector(state => state.incidentReportFromReducer);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (showAlert) {
      // Automatically close the alert after 5 seconds
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 1000);

      // Clean up the timer when the component unmounts or showAlert changes
      return () => clearTimeout(timer);
    }
  }, [showAlert]);


  useEffect(() => {
    // Filter the LTI data based on the Name from cardData
    const filteredLTI = LTIdetail?.filter(item => item.Name === cardData.Name);

    // If found, set the first matched LTI data to state; otherwise set an empty object
    if (filteredLTI?.length > 0) {
      //setLtiFormData(filteredLTI[0]);
    }
  }, [cardData, LTIdetail]);

  const handleSelectChange = (label, value, title = "LTI") => {
    // console.log(label, value.length)
    if (label === 'LTI Mandays' || label === 'LTI Hours') {
      if (value.length <= 2) {
        dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));
      }
    } else {
      dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));
    }
  };

  const handleDateChange = (newValue, label, title = "LTI") => {
    const formattedDateTime = dayjs(newValue).format('DD-MM-YYYY HH:mm:ss');

    // Get the LTI date from the form or state
    const LtiDate = LTI?.["LTI Date"];
    // console.log(LtiDate);

    if (label === 'Date of Return to Duty' && LtiDate) {
      const formattedLtiDate = dayjs(LtiDate, 'DD-MM-YYYY HH:mm:ss');
      const selectedDate = dayjs(newValue);

      // Check if the selected "Date of Duty Return" is after the "LTI Date"
      if (selectedDate.isBefore(formattedLtiDate)) {
        setShowAlert(true);
        return; // Prevent further execution
      }
    }

    // Dispatch the value if it passes the condition
    dispatch(handleChangeDrawerFormData({ fieldName: label, value: formattedDateTime, title }));
  };


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(setLTIDetails(ltiFormData));
  //   setLtiFormData({
  //     "LTI Date":"",
  //     "Date of Return to Duty": "",
  //     "LTI Hours": "",
  //     "LTI Mandays": "",
  //     "Location Inside Employee Premises":"",
  //     Name: cardData.Name || '', 
  //   });
  // };

  const SubmitFormData = async (title) => {
    const isObjectValid = (obj) => {
      return obj && typeof obj === 'object' && Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
    };

    switch (title) {
      case "LTI":
        if (isObjectValid(LTI)) {
          LTI["Name"] = cardData.Name
          dispatch(addLTIDetail(LTI));
        }
        break;

      default:
        console.warn("Invalid title provided");
    }
  };


  return (
    <Box
      sx={{
        padding: "12px",
        borderRadius: "8px",
        border: "1px solid #C1C1C1",
        marginBottom: "16px" // Add margin between cards
      }}
    >
      <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "none",
            marginRight: "8px",
            overflow: "hidden",
          }}
        >
          {/* Placeholder for the image */}
          <AccountCircleIcon sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
        <Box display='flex' flexDirection='column' alignItems='start' gap={0}>
          <Typography variant='body2' sx={{ fontWeight: '600' }}>{cardData?.Name}</Typography>
          <Typography variant='caption'>Designation: {capitalizeFirstChar(cardData?.Designation)}</Typography>
        </Box>
      </Box>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} sm={3}>
          <Box display='flex'>
            <Typography sx={{ color: "#525252", fontSize: "12px", fontWeight: '600' }}>Gender</Typography>
            <Typography sx={{ color: "#525252", fontSize: "12px", fontWeight: '400' }}>: {cardData?.Gender || 'NA'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box display='flex'>
            <Typography sx={{ color: "#525252", fontSize: "12px", fontWeight: '600' }}>Age</Typography>
            <Typography sx={{ color: "#525252", fontSize: "12px", fontWeight: '400' }}>: {cardData?.Age || 'NA'}</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Render the dynamic fields for this person */}
      <div style={{ padding: "8px 0px" }}>
        <Grid container spacing={2}>
          {/* First row - Date Inputs */}
          <Grid item xs={12} sm={6}>
            <CustomeDatePicker
              label={"LTI Date"}
              onChange={(e) => handleDateChange(e, "LTI Date", title)}
            />
            {/* <BasicDatePicker
              label="LTI Date"
              value={ltiFormData["LTI Date"]}
              name="LTI Date"
              onChange={handleChange}
              sx={{ mb: 2, mt: 1 }}
            /> */}

          </Grid>
          <Grid item xs={12} sm={6}>

            <CustomeDatePicker
              label={"Date of Return to Duty"}
              onChange={(e) => handleDateChange(e, "Date of Return to Duty", title)}
            />
          </Grid>

          {/* Second row - Age and LTI Mandays Inputs */}
          <Grid item xs={12} sm={6}>
            <CustomInput
              placeholder="LTI Hours"
              label="LTI Hours"
              name="LTI Hours"
              type="number" // Set type to number for age
              value={LTI?.["LTI Hours"]} // Set value to empty string
              onChange={(e) => handleSelectChange("LTI Hours", e.target.value, title)}
              sx={{ mb: 2, mt: 1, p: 1 }} // Add margin and padding
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInput
              placeholder="LTI Mandays"
              label="LTI Mandays"
              name="LTI Mandays"
              type="number"
              value={LTI?.["LTI Mandays"]}
              onChange={(e) => handleSelectChange("LTI Mandays", e.target.value, title)}
              sx={{ mb: 2, mt: 1 }}
            />
          </Grid>

          {/* Last row - Location Input */}
          <Grid item xs={12}>
            <CustomInput
              placeholder="Location Inside Employee Premises"
              label="Location Inside Employee Premises"
              name="Location Inside Employee Premises"
              value={LTI?.["Location Inside Employee Premises"]}
              onChange={(e) => handleSelectChange("Location Inside Employee Premises", e.target.value, title)}
              sx={{ mb: 2, mt: 1 }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => SubmitFormData("LTI")}
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                backgroundColor: "#0AA2E3",
                paddingX: "16px",
              }}
            >
              Save
            </Button>

          </Grid>
        </Grid>
      </div>
      {showAlert && (
        <Alert
          severity="warning"
          onClose={() => setShowAlert(false)}
          sx={{
            position: 'fixed',
            top: '40px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '600px',
            zIndex: 9999, // Ensure the alert is on top of other elements
            textAlign: 'center',
          }}
        >
          The Date of Duty Return must be after the LTI Date.
        </Alert>
      )}
    </Box>
  );
}
