import {
    CHANGE_INPUT_VALUE,
    CHANGE_EXECUTIVE_COMMENT,
    CHANGE_EXECUTIVE_EDIT
  } from "./constant";
  
  const initialState = {
    mineManger:{},
    executive: [],
    executiveEditData:[]
  };
  
  const lossWorkFlowReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_INPUT_VALUE:
        return {
          ...state,
          mineManger: {
            ...state.mineManger,
            [action.payload.fieldName]: action.payload.value,
          },
        };

        case CHANGE_EXECUTIVE_COMMENT:
          // Check if an entry with the given questionID already exists
          const existingIndex = state.executive.findIndex(
            (item) => item.id === action.payload.quesId
          );
    
          if (existingIndex !== -1) {
            // Update the existing comment for the matching questionID
            return {
              ...state,
              executive: state.executive.map((item, index) =>
                index === existingIndex
                  ? { ...item, comment: action.payload.comment }
                  : item
              ),
            };
          } else {
            // Add a new comment if the questionID does not exist
            return {
              ...state,
              executive: [
                ...state.executive,
                { id: action.payload.quesId, comment: action.payload.comment },
              ],
            };
          }

          case CHANGE_EXECUTIVE_EDIT:
            const editIndex = state.executiveEditData.findIndex(
              (item) => item.id === action.payload.id
            );
      
            if (editIndex !== -1) {
              return {
                ...state,
                executiveEditData: state.executiveEditData.map((item, index) =>
                  index === editIndex
                    ? {
                        ...item,
                        observation: action.payload.observation,
                        recommendation: action.payload.recommendation,
                        targetCompletionDate: action.payload.targetCompletionDate,
                        evidence: action.payload.evidence,
                      }
                    : item
                ),
              };
            } else {
              return {
                ...state,
                executiveEditData: [
                  ...state.executiveEditData,
                  {
                    id: action.payload.id,
                    observation: action.payload.observation,
                    recommendation: action.payload.recommendation,
                    targetCompletionDate: action.payload.targetCompletionDate,
                    evidence: action.payload.evidence,
                  },
                ],
              };
            }
           
    default:
        return state;
    }
  };
  
  export default lossWorkFlowReducer;