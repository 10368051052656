import { Avatar, Box, Grid, Typography, IconButton } from "@mui/material";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import ProfileSearchLocation from "./SearchLocations";
import { updateProfile } from "../../Apis/apiCall";
import EditIcon from "@mui/icons-material/Edit";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export default function ViewProfile() {

  const loginDataString = localStorage.getItem("loginData");
  const loginData = loginDataString ? JSON.parse(loginDataString) : null;


  const [value, setValue] = useState(0);
  const [avatar, setAvatar] = useState(loginData?.img);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const base64 = await toBase64(file);
      const payload = {
        base64,
        name: file.name,
        type: file.type,
      };

      try {
        const response = await updateProfile(payload);
        if (response.status === 200) {
          alert("Profile updated successfully! Now, You'll have to logout to see profile changes");
          setAvatar(URL.createObjectURL(file)); // Temporarily show the updated avatar
        } else {
          alert("Failed to update profile.");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        alert("An error occurred while updating your profile.");
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Remove the "data:image/jpeg;base64," prefix
      reader.onerror = (error) => reject(error);
    });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>

      <Box sx={{
        width: '100%',
        height: '80vh',
        maxHeight: 'auto',
        margin: 'auto',
        padding: 4,
        border: '1px solid #d9d9d9',
        borderRadius: 2,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        overflowY: 'scroll'
      }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Box sx={{ position: 'relative' }}>
              <Avatar
                src={avatar}
                sx={{ width: 150, height: 150, mt: 2 }}
              />
              <IconButton
                component="label"
                htmlFor="image-upload"
                sx={{
                  position: 'absolute',
                  left: 110,
                  bottom: 12,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                <EditIcon />
              </IconButton>
            </Box>
            <input
              accept="image/*"
              type="file"
              id="image-upload"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <Box>
              <Box sx={{ display: 'flex' }}>
                {loginData?.middleName || loginData?.lastName ?
                  <Typography sx={{ color: '#747474', fontSize: '24px', fontWeight: 600, mr: 6 }}>
                    {`${loginData?.firstName} ${loginData?.middleName} ${loginData?.lastName && loginData?.lastName}`}
                  </Typography> :
                  <Typography sx={{ color: '#747474', fontSize: '24px', fontWeight: 600, mr: 6 }}>
                    {`${loginData?.firstName}`}
                  </Typography>
                }

                <LocationOnOutlinedIcon sx={{ color: '#B1B1B1', fontSize: '24px', mt: 0.5 }} />
                <Typography sx={{ color: '#B1B1B1', mt: 0.5 }}>{loginData?.currentWorkLocation}</Typography>
              </Box>

              <Box>
                <Box sx={{ width: '100%', mt: '60px' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="profile-tabs"
                      textColor="inherit"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: 'transparent',
                        },
                      }}
                      sx={{ backgroundColor: 'white', color: 'black' }}
                    >
                      <Tab
                        label={
                          <Box display="flex" alignItems="center">
                            <AccountBoxOutlinedIcon />
                            <Box ml={1}>About</Box>
                          </Box>
                        }
                        {...a11yProps(0)}
                        sx={{ color: '#000', '&.Mui-selected': { color: '#09A2E3' }, backgroundColor: 'transparent', }}
                      />
                      <Tab
                        label={
                          <Box display="flex" alignItems="center">
                            <LocationOnOutlinedIcon />
                            <Box ml={1}>Location</Box>
                          </Box>
                        }
                        {...a11yProps(1)}
                        sx={{ color: '#000', '&.Mui-selected': { color: '#09A2E3' }, backgroundColor: 'transparent', }}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Typography sx={{ textDecoration: 'underline', color: '#747474', fontSize: '16px', mb: '45px' }}>Contact Information</Typography>
                    <Box sx={{ mb: '26px', display: 'flex', }}>
                      <Typography sx={{ opacity: 0.3 }}>Email Address :  </Typography>
                      <Typography sx={{ color: '#747474', ml: 1 }}> {loginData?.email}</Typography>
                    </Box>
                    <Box sx={{ mb: '26px', display: 'flex' }}>
                      <Typography sx={{ opacity: 0.3 }}>Mobile Number : </Typography>
                      <Typography sx={{ color: '#747474', ml: 1 }}> {loginData?.mobileNumber}</Typography>
                    </Box>
                    {/* <Box sx={{mb:'26px',display:'flex'}}>
                                        <Typography  sx={{opacity:0.3}}>Department : </Typography>
                                        <Typography sx={{color:'#747474'}}> { userEmail}</Typography>
                                    </Box> */}

                    <Typography sx={{ textDecoration: 'underline', color: '#747474', fontSize: '16px', mb: '45px', mt: '35px' }}>Basic Information</Typography>
                    <Box sx={{ mb: '26px', display: 'flex', }}>
                      <Typography sx={{ opacity: 0.3 }}>Gender :  </Typography>
                      <Typography sx={{ color: '#747474', ml: 1 }}> {loginData?.gender}</Typography>
                    </Box>
                    <Box sx={{ mb: '26px', display: 'flex' }}>
                      <Typography sx={{ opacity: 0.3 }}>Date Of Birth : </Typography>
                      <Typography sx={{ color: '#747474', ml: 1 }}> {formatDate(loginData?.dateOfBirth)}</Typography>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <ProfileSearchLocation />
                  </CustomTabPanel>
                </Box>
              </Box>

            </Box>
          </Grid>

        </Grid>

      </Box>

    </>
  )
}
