import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Divider,
  IconButton,
  Chip,
} from "@mui/material";
import BasicDatePicker from "../CustomeDate";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import CustomInput from "../CustomInputField";
import UploadIcon from "@mui/icons-material/Upload";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MessageIcon from "@mui/icons-material/Message";
import { disciplineCover } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { userFullName, todayDate } from "../../constant";
import { addObservation, addData, addSatisfactoryRemark, addNotApplicableRemark } from "../../redux/LossControl/actions";
import DraggableImageDialog from "../DraggableImageDialog";
import CompletionModal from "./CompletionModal";
import AddPointModal from "./AddPointModal";
import Compressor from 'compressorjs';
import CompletionModalForReview from "./CompletionReviewModal";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { blue } from "@mui/material/colors";

export default function TourBegin({handleNext}) {
  const dispatch = useDispatch();
  const [cards, setCards] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionData, setQuestionData] = useState({});  //For unsatisfactory data
  const [questionRemark, setQuestionRemark] = useState({});  //For satisfactory, not applicable data
  const [questionStatus, setQuestionStatus] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [completionModalOpen, setCompletionModalOpen] = useState(false);
  const [addPointsModalOpen, setAddPointsModalOpen] = useState(false);
  const [completionReviewModalOpen,setCompletionReviewModalOpen] = useState(false);
  const [remark, setRemark] = useState("");
  const [remarkEditMode, setRemarkEditMode] = useState(0);

  const tourData = useSelector((state) => state.lossControlReducer.tours);

  useEffect(() => {
    if (
      tourData?.questionnaire &&
      cards.length !== tourData.questionnaire.length
    ) {
      const newQuestions = tourData.questionnaire.slice(cards.length);

      if (newQuestions.length > 0) {
        setCards((prevCards) => [...prevCards, ...newQuestions]);

        const newData = newQuestions.reduce((acc, question) => {
          acc[question.id] = {
            observation: "",
            recommendation: "",
            targetCompletionDate: null,
            evidence: [],
          };
          return acc;
        }, {});
        
        const newRemark = newQuestions.reduce((acc, question) => {
          acc[question.id] = null;
          return acc;
        }, {});

        const newStatus = newQuestions.reduce((acc, question) => {
          acc[question.id] = 0; // Default value set to 0 (SATISFACTORY) for status
          dispatch(addObservation(question.id, 0))
          return acc;
        }, {});

        setQuestionData((prevData) => ({
          ...prevData,
          ...newData,
        }));
        setQuestionRemark((prevData) => ({
          ...prevData,
          ...newRemark,
        }));
        setQuestionStatus((prevStatus) => ({
          ...prevStatus,
          ...newStatus,
        }));

        setCurrentQuestionIndex(cards.length);
      }
    }
  }, [tourData?.questionnaire, cards.length]);

  const handleDateChange = (newDate) => {
    updateQuestionData("targetCompletionDate", newDate);
  };

  const handleOptionChange = (newOption) => {
    const currentQuestion = cards[currentQuestionIndex];
    updateQuestionStatus(currentQuestion.id, newOption);
    dispatch(addObservation(currentQuestion.id, newOption));

    if (newOption === 1) {
      dispatch(
        addData(currentQuestion.id, {
          targetCompletionDate:
            questionData[currentQuestion.id]?.targetCompletionDate,
          evidence: questionData[currentQuestion.id]?.evidence,
          recommendation: questionData[currentQuestion.id]?.recommendation,
          observation: questionData[currentQuestion.id]?.observation,
        })
      );
      dispatch(
        addNotApplicableRemark(currentQuestion.id, "")
      );
      dispatch(
        addSatisfactoryRemark(currentQuestion.id, "")
      );
    } else if (newOption === 0) {
      dispatch(
        addSatisfactoryRemark(currentQuestion.id, currentQuestionRemark || "")
      );
      dispatch(
        addNotApplicableRemark(currentQuestion.id, "")
      );
      dispatch(
        addData(currentQuestion.id, {
          targetCompletionDate: null,
          evidence: [],
          recommendation: "",
          observation: "",
        })
      );
    } else if (newOption === 2) {
      dispatch(
        addSatisfactoryRemark(currentQuestion.id, "")
      );
      dispatch(
        addNotApplicableRemark(currentQuestion.id, currentQuestionRemark || "")
      )
      dispatch(
        addData(currentQuestion.id, {
          targetCompletionDate: null,
          evidence: [],
          recommendation: "",
          observation: "",
        })
      );
    } else {
      console.log("nothing");
    }
  };

  const updateQuestionStatus = (questionId, newOption) => {
    setQuestionStatus((prevStatus) => ({
      ...prevStatus,
      [questionId]: newOption,
    }));
  };

  const updateQuestionSatisfactoryRemark = (questionId, remark) => {
    setQuestionRemark((prevRemark) => ({
      ...prevRemark,
      [questionId]: remark,
    }));
    dispatch(
      addSatisfactoryRemark(questionId, remark)
    )
    setRemark("");
  };
  const updateQuestionNotApplicableRemark = (questionId, remark) => {
    setQuestionRemark((prevRemark) => ({
      ...prevRemark,
      [questionId]: remark,
    }));
    dispatch(
      addNotApplicableRemark(questionId, remark)
    )
    setRemark("");
  };

  const updateQuestionData = (field, value) => {
    const currentQuestion = cards[currentQuestionIndex];
    const fieldValue =
      field === "targetCompletionDate"
        ? new Date(value).toLocaleDateString("en-CA")
        : value;
    setQuestionData((prevData) => {
      const updatedData = {
        ...prevData,
        [currentQuestion.id]: {
          ...prevData[currentQuestion.id],
          [field]: fieldValue,
        },
      };
      dispatch(addData(currentQuestion.id, updatedData[currentQuestion.id]));
      return updatedData;
    });
  };

  const handleNext1 = () => {
    if (currentQuestionIndex < cards.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setCompletionModalOpen(true);
    }
    setUploadedFiles([]);
    setRemark("");
    setRemarkEditMode(0);
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleObservationChange = (e) => {
    updateQuestionData("observation", e.target.value);
  };

  const handleRecommendationChange = (e) => {
    updateQuestionData("recommendation", e.target.value);
  };

  // const handleEvidenceUpload = (event) => {
  //   const files = Array.from(event.target.files);
  //   const filePromises = files.map((file) => {
  //     return new Promise((resolve, reject) => {
  //       new Compressor(file, {
  //         quality: 0.2,
  //         success(compressedFile) {
  //           const reader = new FileReader();
  //           reader.onload = (e) => {
  //             resolve({
  //               base64: e.target.result.split(",")[1],
  //               name: compressedFile.name,
  //               type: compressedFile.type,
  //             });
  //           };
  //           reader.onerror = reject;
  //           reader.readAsDataURL(compressedFile);
  //         },
  //         error(err) {
  //           reject(err);
  //         },
  //       });
  //     });
  //   });
  
  //   Promise.all(filePromises)
  //     .then((newFiles) => {
  //       const updatedFiles = [
  //         ...uploadedFiles,
  //         ...newFiles.filter(
  //           (newFile) =>
  //             !uploadedFiles.some(
  //               (existingFile) => existingFile.name === newFile.name
  //             )
  //         ),
  //       ];
  //       setUploadedFiles(updatedFiles);
  //       updateQuestionData("evidence", updatedFiles);
  //     })
  //     .catch((error) => {
  //       console.error("Image upload/compression failed:", error);
  //     });
  // };

  const handleEvidenceUpload = (event) => {
    const files = Array.from(event.target.files);
    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        // Check if the file is an image
        if (file.type.startsWith("image/")) {
          // Compress image files
          new Compressor(file, {
            quality: 0.2,
            success(compressedFile) {
              const reader = new FileReader();
              reader.onload = (e) => {
                resolve({
                  base64: e.target.result.split(",")[1],
                  name: compressedFile.name,
                  type: compressedFile.type,
                });
              };
              reader.onerror = reject;
              reader.readAsDataURL(compressedFile);
            },
            error(err) {
              reject(err);
            },
          });
        } else {
          // Directly read non-image files as base64 without compression
          const reader = new FileReader();
          reader.onload = (e) => {
            resolve({
              base64: e.target.result.split(",")[1],
              name: file.name,
              type: file.type,
            });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        }
      });
    });
  
    Promise.all(filePromises)
      .then((newFiles) => {
        const updatedFiles = [
          ...uploadedFiles,
          ...newFiles.filter(
            (newFile) =>
              !uploadedFiles.some(
                (existingFile) => existingFile.name === newFile.name
              )
          ),
        ];
        setUploadedFiles(updatedFiles);
        updateQuestionData("evidence", updatedFiles);
      })
      .catch((error) => {
        console.error("File upload/compression failed:", error);
      });
  };
  
  const handleFileDelete = (fileNameToDelete) => {
    const updatedFiles = uploadedFiles.filter(
      (file) => file.name !== fileNameToDelete
    );
    setUploadedFiles(updatedFiles);
    updateQuestionData("evidence", updatedFiles);
  };

  const handleChipClick = (file) => {
    setSelectedImage(`data:${file.type};base64,${file.base64}`);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedImage(null);
  };

  const handleCompletionModalYes = () => {
    setCompletionModalOpen(false);
    setAddPointsModalOpen(true);
  };

  const handleCompletionModalNo = () => {
    setCompletionModalOpen(false);
    setCompletionReviewModalOpen(true);
  };

  const handleCompletionReviewModal = () => {
    setCompletionReviewModalOpen(false);
    handleNext();
  }

  const handleAddPointsClose = () => {
    setAddPointsModalOpen(false);
  };

  const currentQuestion = cards[currentQuestionIndex] || {};
  const currentQuestionData = questionData[currentQuestion.id] || {};
  const currentQuestionStatus = questionStatus[currentQuestion.id];
  const currentQuestionRemark = questionRemark[currentQuestion.id];

  // console.log("cards",cards);
  // console.log("questionData",questionData);
  // console.log("questionStatus",questionStatus);
  // console.log("questionRemark",questionRemark);
  // console.log("currentQuestion",currentQuestion);
  // console.log("currentQuestionData",currentQuestionData);
  // console.log("currentQuestionStatus",currentQuestionStatus);
  // console.log("currentQuestionRemark",currentQuestionRemark);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Grid item>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", color: "#323232" }}
          >
            {tourData.disciplineName}
          </Typography>
          <Typography variant="body2" sx={{ color: "#525966" }}>
            Loss Control Tour
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={0.5}
            >
              <PersonOutlineOutlinedIcon
                sx={{ color: "#949494", width: "20px", height: "20px" }}
              />
              <Typography
                sx={{ color: "#949494", fontSize: "12px", fontWeight: "600" }}
              >
                {userFullName}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={0.5}
            >
              <CalendarMonthOutlinedIcon
                sx={{ color: "#949494", width: "20px", height: "20px" }}
              />
              <Typography
                sx={{ color: "#949494", fontSize: "12px", fontWeight: "600" }}
              >
                {todayDate}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={0.5}
            >
              <LocationOnOutlinedIcon
                sx={{ color: "#949494", width: "20px", height: "20px" }}
              />
              <Typography
                sx={{ color: "#949494", fontSize: "12px", fontWeight: "600" }}
              >
                {tourData.siteLocation}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2} sx={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              borderRadius: "8px",
              background: "#FFF",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
              padding: "16px",
              minHeight: "480px",
              flexGrow: 1,
            }}
          >
            <Box>
              <Box sx={{ p: 2 }}>
                <Typography
                  sx={{
                    mb: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#222",
                  }}
                >
                  Point {currentQuestion.id}/{cards.length}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#525966",
                    width: "357px",
                  }}
                >
                  {currentQuestion.quesName}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: "center", padding: "20px" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    border: "1px solid #CCC",
                    borderRadius: "100px",
                    padding: "8px",
                    width: "100%",
                  }}
                >
                  <Button
                    size="large"
                    onClick={() => handleOptionChange(1)}
                    sx={{
                      borderRadius: "100px",
                      backgroundColor:
                        currentQuestionStatus === 1 ? "#09A2E3" : "white",
                      width: "33%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SentimentVeryDissatisfiedIcon
                        sx={{
                          fontSize: "24px",
                          color:
                            currentQuestionStatus === 1 ? "white" : "#525966",
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          color:
                            currentQuestionStatus === 1 ? "white" : "#525966",
                        }}
                      >
                        Unsatisfactory
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={() => handleOptionChange(2)}
                    sx={{
                      borderRadius: "100px",
                      backgroundColor:
                        currentQuestionStatus === 2 ? "#09A2E3" : "white",
                      width: "33%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SentimentNeutralIcon
                        sx={{
                          fontSize: "24px",
                          color:
                            currentQuestionStatus === 2 ? "white" : "#525966",
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          color:
                            currentQuestionStatus === 2 ? "white" : "#525966",
                        }}
                      >
                        Not Applicable
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={() => handleOptionChange(0)}
                    sx={{
                      borderRadius: "100px",
                      backgroundColor:
                        currentQuestionStatus === 0 ? "#09A2E3" : "white",
                      width: "33%",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <SentimentSatisfiedAltIcon
                        sx={{
                          fontSize: "24px",
                          color:
                            currentQuestionStatus === 0 ? "white" : "#525966",
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          color:
                            currentQuestionStatus === 0 ? "white" : "#525966",
                        }}
                      >
                        Satisfactory
                      </Typography>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              {currentQuestionIndex !== 0 && (
                <Button
                  variant="outlined"
                  onClick={handlePrev}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    paddingY: "8px",
                    paddingX: "32px",
                    borderRadius: "24px",
                    width: "100%",
                  }}
                >
                  Previous
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext1}
                disabled={currentQuestionStatus === null || (currentQuestionStatus === 1 && (currentQuestionData.observation?.length === 0 || currentQuestionData.recommendation?.length === 0 || currentQuestionData.targetCompletionDate === null )) }
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  backgroundColor: "#09A2E3",
                  color: "#ffffff",
                  paddingY: "8px",
                  paddingX: "32px",
                  borderRadius: "24px",
                  width: "100%",
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: "16px",
              flexGrow: 1,
              textAlign: "center",
              backgroundImage:
                currentQuestionStatus === 1
                  ? "none"
                  : `url(${disciplineCover})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "auto",
              width: "100%",
              borderRadius: "12px",
              border: "0.5px solid rgba(9, 162, 227, 0.20)",
              backdropFilter: "blur(7px)"
            }}
          >
            {currentQuestionStatus === null ? (
              <Box sx={{ width: "70%" }}>
                <MessageIcon sx={{ color: "#09A2E3", fontSize: "100px" }} />
                <Typography
                  sx={{ color: "#323232", fontSize: "24px", fontWeight: "600" }}
                >
                  Waiting for your input!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#4D4D4D", marginBottom: "8px" }}
                >
                  Please select your response.
                </Typography>
              </Box>
            ) : currentQuestionStatus === 1 ? (
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    gutterBottom
                    sx={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Observation
                    <Typography
                      component="span"
                      sx={{ fontSize: "14px", fontWeight: "600", color: "red", marginLeft: "4px" }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <CustomInput
                    value={currentQuestionData.observation}
                    type="text"
                    onChange={handleObservationChange}
                    placeholder="type here..."
                  />
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    gutterBottom
                    sx={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Recommendation
                    <Typography
                      component="span"
                      sx={{ fontSize: "14px", fontWeight: "600", color: "red", marginLeft: "4px" }}
                    >
                      *
                    </Typography>
                  </Typography>
                  <CustomInput
                    value={currentQuestionData.recommendation}
                    type="text"
                    onChange={handleRecommendationChange}
                    placeholder="type here..."
                  />
                </Box>

                {/* Target Completion Date */}
                {/* <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    gutterBottom
                    sx={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Target Completion Date
                  </Typography>
                  <BasicDatePicker
                    value={currentQuestionData.targetCompletionDate}
                    onChange={handleDateChange}
                  />
                </Box> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Target Completion Date *"
                      value={currentQuestionData?.targetCompletionDate ? dayjs(currentQuestionData?.targetCompletionDate) : null}
                      onChange={(newValue) => handleDateChange(new Date(newValue).toLocaleDateString("en-CA"))}
                      renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                      format ="DD-MM-YYYY"
                      minDate={dayjs()}
                    />
                  </LocalizationProvider>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography
                    gutterBottom
                    sx={{ fontSize: "14px", fontWeight: "600" }}
                  >
                    Evidence
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      border: "1px dashed #09A2E3",
                      padding: 2,
                      borderRadius: 1,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <IconButton component="label">
                      <UploadIcon fontSize="large" sx={{ color: "#000000" }} />
                      <input
                        hidden
                        accept="image/*,.doc,.pdf,.mp4"
                        type="file"
                        onChange={handleEvidenceUpload}
                        multiple
                      />
                    </IconButton>
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 1,
                        color: "#348BD2",
                        fontWeight: "600",
                        textDecoration: "underline",
                      }}
                    >
                      Click to upload
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: "600" }}>
                      PNG, JPG, Docx, PDF, and MP4 (max. 10 MB)
                    </Typography>
                  </Box>

                  {/* Render uploaded files */}
                  <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
                    {uploadedFiles?.map((file, index) => (
                      <Chip
                        key={index}
                        label={file.name}
                        onClick={() => handleChipClick(file)}
                        onDelete={() => handleFileDelete(file.name)}
                        sx={{ m: 0.5, fontWeight: "600" }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            ) : currentQuestionStatus === 2 ? (
              <Box sx={{ width: "70%" }}>
                <SentimentNeutralIcon
                  sx={{ color: "#09A2E3", fontSize: "60px" }}
                />
                <Typography
                  sx={{ color: "#323232", fontSize: "24px", fontWeight: "600" }}
                >
                  Not Applicable
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#4D4D4D", marginBottom: "8px" }}
                >
                  We understand that you have marked your response as not
                  applicable.
                </Typography>
                <Button size="medium" 
                  onClick={() => {
                    if(currentQuestionRemark?.length > 0 || remarkEditMode === 1){
                      setRemarkEditMode(0);
                      updateQuestionNotApplicableRemark(currentQuestion.id, "");
                    }
                    else{
                      setRemarkEditMode(1);
                      updateQuestionNotApplicableRemark(currentQuestion.id, "");
                    }
                  }} 
                  sx={{ bgcolor: "none", color: "#09A2E3", fontWeight: 400, borderRadius: "4px" }}
                >
                    {(remarkEditMode === 1 || currentQuestionRemark?.length > 0) ? "- Remove Remark" : "+ Add Remark"}
                </Button>
                {remarkEditMode === 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    bgcolor="#F5FBFE"
                    p={0}
                    borderRadius="4px"
                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                    position="relative"
                    mt={1.5}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Add remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      fullWidth
                      multiline
                      rows={3}
                      InputProps={{
                        style: { padding: 0 },
                      }}
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "#F5FBFE",
                          pr: 8, // Adjust padding to make room for the button
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {updateQuestionNotApplicableRemark(currentQuestion.id,remark); setRemarkEditMode(0);}}
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 8,
                        color: "#ffffff",
                        padding: "4px 16px",
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                ) : currentQuestionRemark?.length > 0 ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                    bgcolor="#F5FBFE"
                    p={1}
                    borderRadius="4px"
                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                    mt={1.5}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={0}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "#222222" }}
                      >
                        Remark
                      </Typography>
                      <IconButton
                        aria-label="edit"
                        sx={{
                          color: "#009FF5",
                          width: 32,
                          height: 32,
                          backgroundColor: "#F5FBFE",
                          borderRadius: "8px",
                          border: "none",
                          "&:hover": {
                            backgroundColor: blue[50],
                          },
                        }}
                        onClick={() => {
                          setRemarkEditMode(1);
                          setRemark(currentQuestionRemark || "");
                        }}
                      >
                        <EditOutlinedIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ color: "#525966" }}
                    >
                      {currentQuestionRemark}
                    </Typography>
                  </Box>
                ): null}
              </Box>
            ) : currentQuestionStatus === 0 ? (
              <Box sx={{ width: "70%" }}>
                <SentimentSatisfiedAltIcon
                  sx={{ color: "#09A2E3", fontSize: "60px" }}
                />
                <Typography
                  sx={{ color: "#323232", fontSize: "24px", fontWeight: "600" }}
                >
                  Satisfactory!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#4D4D4D", marginBottom: "8px" }}
                >
                  Good to know that you found this satisfactory.
                </Typography>
                <Button size="medium" 
                  onClick={() => {
                    if(currentQuestionRemark?.length > 0 || remarkEditMode === 1){
                      setRemarkEditMode(0);
                      updateQuestionSatisfactoryRemark(currentQuestion.id, "");
                    }
                    else{
                      setRemarkEditMode(1);
                      updateQuestionSatisfactoryRemark(currentQuestion.id, "");
                    }
                  }} 
                  sx={{ bgcolor: "none", color: "#09A2E3", fontWeight: 400, borderRadius: "4px" }}
                >
                    {(remarkEditMode === 1 || currentQuestionRemark?.length > 0) ? "- Remove Remark" : "+ Add Remark"}
                </Button>
                {remarkEditMode === 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    bgcolor="#F5FBFE"
                    p={0}
                    borderRadius="4px"
                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                    position="relative"
                    mt={1.5}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Add remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      fullWidth
                      multiline
                      rows={3}
                      InputProps={{
                        style: { padding: 0 },
                      }}
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "#F5FBFE",
                          pr: 8, // Adjust padding to make room for the button
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {updateQuestionSatisfactoryRemark(currentQuestion.id,remark); setRemarkEditMode(0);}}
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 8,
                        color: "#ffffff",
                        padding: "4px 16px",
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                ) : currentQuestionRemark?.length > 0 ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                    bgcolor="#F5FBFE"
                    p={1}
                    borderRadius="4px"
                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                    mt={1.5}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={0}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "#222222" }}
                      >
                        Remark
                      </Typography>
                      <IconButton
                        aria-label="edit"
                        sx={{
                          color: "#009FF5",
                          width: 32,
                          height: 32,
                          backgroundColor: "#F5FBFE",
                          borderRadius: "8px",
                          border: "none",
                          "&:hover": {
                            backgroundColor: blue[50],
                          },
                        }}
                        onClick={() => {
                          setRemarkEditMode(1);
                          setRemark(currentQuestionRemark || "");
                        }}
                      >
                        <EditOutlinedIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ color: "#525966" }}
                    >
                      {currentQuestionRemark}
                    </Typography>
                  </Box>
                ): null}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
      <DraggableImageDialog
        imageSrc={selectedImage}
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
      <CompletionModal
        open={completionModalOpen}
        onClose={handleCompletionModalNo}
        onYes={handleCompletionModalYes}
      />
      <CompletionModalForReview 
        open={completionReviewModalOpen}
        handleClose={handleCompletionReviewModal}
      />
      <AddPointModal
        open={addPointsModalOpen}
        handleClose={handleAddPointsClose}
      />
    </>
  );
}
