import React, { useState } from 'react';
import { Box, Typography, Divider, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatDateToIndianLocale2 } from "../../utils/helper";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const CommentSection = ({ returnComments }) => {
  const [expandedSections, setExpandedSections] = useState({});

  // Toggle expand/collapse for each comment based on its index
  const toggleExpand = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Get the title based on the roleId
  const getTitleByRoleId = (roleId) => {
    switch (roleId) {
      case 27:
        return "Executive Manager Comment";
      case 28:
        return "Installation Manager Comment";
      case 29:
        return "Mines Manager Comment";
      default:
        return "Comment";
    }
  };

  return (
    <Box mt={2}>
      {returnComments?.map((commentObj, index) => (
        <Box key={index} mt={2}>
          {/* Title section with toggle */}
          <Box display="flex" alignItems="center" onClick={() => toggleExpand(index)} sx={{ cursor: 'pointer' }}>
            <Typography variant="body2" sx={{ color: '#525966' }}>
              {getTitleByRoleId(commentObj.roleId)}
            </Typography>
            <IconButton size="small">
              <ExpandMoreIcon
                sx={{
                  color: "#525966",
                  transform: expandedSections[index] ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </IconButton>
          </Box>
          <Divider />

          {/* Collapsible comment section */}
          <Collapse in={expandedSections[index]}>
            <Box
              display="flex"
              flexDirection="column"
              gap={0.5}
              bgcolor="#F5FBFE"
              p={1}
              borderRadius="4px"
              mt={1.5}
              position="relative" // Enable absolute positioning inside this box
            >
             
              <Typography variant="body2" sx={{ color: '#525966' }}>
                {commentObj?.comment}
              </Typography>

              {/* Time display in the bottom-right corner */}
              <Box
                display="flex"
                justifyContent="flex-end"
               
                sx={{ color: 'grey.500' }} // Grey color for icon and text
              >
                <AccessTimeOutlinedIcon sx={{ fontSize: 16, mr: 0.5 }} /> {/* Clock icon */}
                <Typography variant="caption" sx={{ color: 'grey.500' }}>
                  {formatDateToIndianLocale2(commentObj?.timestamp) || ""} {/* Replace "00:00" with your actual time variable */}
                </Typography>
              </Box>
            </Box>
          </Collapse>
        </Box>
      ))}
    </Box>
  );
};

export default CommentSection;
