import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DraggableImageDialog from "../DraggableImageDialog";
import { todayDate } from "../../constant";

const CenteredIconBox = ({
  children,
  backgroundColor = "rgba(0, 159, 245, 0.10)",
  borderRadius = "6px",
  padding = "8px",
  ...props
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        padding: padding,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};


const loginDataString = localStorage.getItem("loginData");
const loginData = loginDataString ? JSON.parse(loginDataString) : null;

export default function DetailedView({
  cards,
  close,
  selectedDiscipline,
  disciplineData,
  newSnapshot = 0,
  finalData
}) {
  console.log("DD:",finalData)
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [listView, setListView] = useState(true);
  const [selectedImg, setSelectedImg] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (index) => {
    setSelectedCardIndex(selectedCardIndex === index ? 0 : index);
  };

  const handlePrevious = () => {
    setSelectedCardIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setSelectedCardIndex((prev) => (prev < cards.length - 1 ? prev + 1 : prev));
  };

  function convertEpochToIST(timestamp) {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp);
    const istDate = date.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return istDate;
  }

  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Not Applicable";
      case 1:
        return "Unsatisfactory";
      case 2:
        return "Satisfactory";
      default:
        return "Unknown";
    }
  };

  const selectedCard = cards[selectedCardIndex];

  const handleImageView = (src) => {
    setSelectedImg(src);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedImg(null);
  };

  return (
    <>
      <Grid container spacing={0} sx={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              background: "#FFF",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
              padding: "16px",
              flexGrow: 1,
              height: "auto",
              width: "98%",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <ArrowBackIosIcon
                  fontSize="10px"
                  sx={{ cursor: "pointer" }}
                  onClick={() => close(false)}
                />
                <Typography
                  sx={{
                    color: "#222222",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {newSnapshot === 1 ? finalData?.discipline :  disciplineData?.discipline}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <CenteredIconBox
                  backgroundColor={
                    listView ? "#009FF5" : "rgba(0, 159, 245, 0.10)"
                  }
                  onClick={() => setListView(1)}
                >
                  <FormatListBulletedIcon
                    fontSize="small"
                    sx={{ color: listView ? "white" : "#009FF5" }}
                  />
                </CenteredIconBox>
                <CenteredIconBox
                  backgroundColor={
                    !listView ? "#009FF5" : "rgba(0, 159, 245, 0.10)"
                  }
                  onClick={() => setListView(0)}
                >
                  <GridViewIcon
                    fontSize="small"
                    sx={{ color: !listView ? "white" : "#009FF5" }}
                  />
                </CenteredIconBox>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={1}
              sx={{ marginY: "16px" }}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <PersonOutlineOutlinedIcon
                  sx={{ color: "#525966", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{
                    color: "#525966",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  {newSnapshot === 1 ? `${loginData.firstName} ${loginData.middleName} ${loginData.lastName}` : `${disciplineData.firstName} ${disciplineData.middleName} ${disciplineData.lastName}`}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <CalendarMonthOutlinedIcon
                  sx={{ color: "#525966", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{
                    color: "#525966",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  {newSnapshot === 1 ? todayDate : convertEpochToIST(disciplineData.createdAt)}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <LocationOnOutlinedIcon
                  sx={{ color: "#525966", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{
                    color: "#525966",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                {newSnapshot === 1 ? finalData?.siteLocation : disciplineData.locationName}
                </Typography>
              </Box>
            </Box>

            {/* Grid View */}
            {listView === 0 && (
              <Box>
                {/* Detailed View Section */}
                <Paper
                  elevation={1}
                  sx={{
                    padding: "12px",
                    mb: 2,
                    boxShadow: "0px 3px 20px 1px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0px 8px 8px 0px",
                    borderTop: "0.5px solid #09A2E3",
                    borderRight: "0.5px solid #09A2E3",
                    borderBottom: "0.5px solid #09A2E3",
                    borderLeft: "4px solid #09A2E3",
                    background: "rgba(230, 246, 254, 0.40)",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          backgroundColor: "#09A2E3",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#ffffff",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          {selectedCardIndex + 1}
                        </Typography>
                      </Box>

                      <Typography
                        variant="caption"
                        sx={{ color: "#525966", marginLeft: "8px" }}
                      >
                        {cards[selectedCardIndex].question}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>

                <Divider sx={{ mb: 2 }} />

                {/* Cards Grid */}
                <div
                  style={{
                    maxHeight: "calc(10 * 40px)",
                    overflowY: "auto",
                    padding: "4px",
                  }}
                >
                  <Grid container spacing={1}>
                    {cards?.map((card, index) => (
                      <Grid item xs={3} sm={2} md={1.5} key={card.id}>
                        <Box
                          onClick={() => handleChange(index)}
                          sx={{
                            cursor: "pointer",
                            color:
                              index === selectedCardIndex ? "#009FF5" : "black",
                            fontWeight: "700",
                            borderRadius: "4px",
                            backgroundColor:
                              index === selectedCardIndex
                                ? "rgba(0, 159, 245, 0.10)"
                                : "white",
                            border:
                              index === selectedCardIndex
                                ? "0.5px solid #009FF5"
                                : "none",
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            padding: "8px 16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {index + 1}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Box>
            )}

            {/* List View */}
            {listView !== 0 && (
              <Box>
                <Divider sx={{ mb: 2 }} />
                <div
                  style={{ maxHeight: "calc(10 * 40px)", overflowY: "auto" }}
                >
                  <Grid container spacing={1}>
                    {cards.map((card, index) => (
                      <Grid item xs={12} key={card.id}>
                        <Accordion
                          expanded={selectedCardIndex === index}
                          onChange={() => handleChange(index)}
                          sx={{
                            borderRadius: "8px",
                            backgroundColor: "#F9FAFC",
                            border: "0.5px solid rgba(9, 162, 227, 0.20)",
                            boxShadow: "none",
                            overflow: "hidden",
                            transition: "max-height 0.3s ease",
                            paddingY: "0px !important",
                          }}
                        >
                          <AccordionSummary
                            sx={{
                              height: "40px",
                              padding: 0,
                              justifyContent: "flex-start",
                              "& .MuiAccordionSummary-expandIconWrapper": {
                                display: "none",
                              },
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  backgroundColor: "#E6F6FE",
                                  borderRadius: "50%",
                                  width: "16px",
                                  height: "16px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#09A2E3",
                                    fontSize: "10px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{ color: "#525966" }}
                              >
                                {selectedCardIndex === index
                                  ? card.question // Show full text if expanded
                                  : card.question.length > 20
                                  ? `${card.question.substring(0, 30)}...` // Show truncated text if not expanded
                                  : card.question}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                        </Accordion>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Box>
            )}
          </Box>
        </Grid>

        {/* Right Grid */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              background: "#FFF",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
              padding: "16px",
              flexGrow: 1,
              minHeight: "auto",
              width: "100%",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#222",
                fontSize: "16px",
                fontWeight: "500",
                mb: "4px",
              }}
            >
              {newSnapshot === 1 ? finalData?.discipline :  disciplineData?.discipline}
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <PersonOutlineOutlinedIcon
                  sx={{ color: "#525966", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{ color: "#525966", fontSize: "13px", fontWeight: "400" }}
                >
                  {newSnapshot === 1 ? `${loginData.firstName} ${loginData.middleName} ${loginData.lastName}` : `${disciplineData.firstName} ${disciplineData.middleName} ${disciplineData.lastName}`}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <CalendarMonthOutlinedIcon
                  sx={{ color: "#525966", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{ color: "#525966", fontSize: "13px", fontWeight: "400" }}
                >
                {newSnapshot === 1 ? todayDate : convertEpochToIST(disciplineData.createdAt)}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <LocationOnOutlinedIcon
                  sx={{ color: "#525966", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{ color: "#525966", fontSize: "13px", fontWeight: "400" }}
                >
                  {newSnapshot === 1 ? finalData?.siteLocation : disciplineData.locationName}
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ my: "12px" }} />

            <Box display="flex" flexDirection="column" gap={1}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", color: "#323232" }}
                >
                  Point {selectedCardIndex + 1}/{cards.length}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1.5}
                >
                  <CenteredIconBox
                    backgroundColor={"rgba(0, 159, 245, 0.10)"}
                    onClick={handlePrevious}
                    sx={{ opacity: selectedCardIndex === 0 ? 0.5 : 1 }}
                  >
                    <ArrowBackIosIcon
                      fontSize="small"
                      sx={{ color: "#009FF5" }}
                    />
                  </CenteredIconBox>
                  <CenteredIconBox
                    backgroundColor={"rgba(0, 159, 245, 0.10)"}
                    onClick={handleNext}
                    sx={{
                      opacity: selectedCardIndex === cards.length - 1 ? 0.5 : 1,
                    }}
                  >
                    <ArrowForwardIosIcon
                      fontSize="small"
                      sx={{ color: "#009FF5" }}
                    />
                  </CenteredIconBox>
                </Box>
              </Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#525966" }}
              >
                {selectedCard.question}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#09A2E3",
                }}
              >
                {getStatusText(selectedCard.questionStatus)}
              </Typography>
            </Box>

            <Divider sx={{ my: "12px" }} />

            {selectedCard.questionStatus === 1 && (
              <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "400", color: "#222" }}
                  >
                    Observation
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#525966",
                    }}
                  >
                    {selectedCard.observation || "No observation recorded"}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "400", color: "#222" }}
                  >
                    Recommendation
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#525966",
                    }}
                  >
                    {selectedCard.recommendation ||
                      "No recommendation provided"}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "400", color: "#222" }}
                  >
                    Target Completion Date
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#525966",
                    }}
                  >
                    {convertEpochToIST(selectedCard.targetCompletionDate) ||
                      "Not set"}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#525966",
                    }}
                  >
                    {selectedCard.evidence &&
                      selectedCard.evidence.length > 0 && (
                        <>
                          <Box display="flex" flexDirection="column" gap={1}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#222",
                              }}
                            >
                              Evidence
                            </Typography>
                            <Box
                              sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}
                            >
                              {Array.isArray(selectedCard.evidence) &&
                                selectedCard.evidence.map((evidence, index) => {
                                  const isBase64Image = (newSnapshot === 1 && evidence.base64);
                                  const src = isBase64Image ? `data:${evidence.type};base64,${evidence.base64}`  : evidence;
                                  return(
                                  <Box
                                    key={index}
                                    sx={{
                                      width: 100,
                                      height: 100,
                                      borderRadius: "8px",
                                      overflow: "hidden",
                                      border: "1px solid #E0E0E0",
                                    }}
                                  >
                                    <img
                                      src={src}
                                      alt={`Evidence ${index + 1}`}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleImageView(src)}
                                    />
                                  </Box>
                                  )
                                })};
                               
                            </Box>
                          </Box>
                        </>
                      )}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <DraggableImageDialog
        imageSrc={selectedImg}
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
    </>
  );
}
