import { styled } from '@mui/system';
import React from 'react';
import { Box, Typography, LinearProgress } from "@mui/material";

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 40,
    borderRadius: 5,
    [`& .MuiLinearProgress-bar`]: {
      borderRadius: 5,
    },
  }));
  
  export default function ProgressBar ({ value, label }) {
    return (
      <Box display="flex" alignItems="center" width="100%">
        <Box width="100%" ml={2} mr={1} position="relative">
          <CustomLinearProgress variant="determinate" value={value || 0} />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            sx={{
              transform: 'translate(-50%, -50%)', // Center the title
              color: '#fff', // Change text color as needed
              fontWeight: 'bold', // Make the title bold
            }}
          >
            <Typography variant="body2" sx={{color:'#000000'}}>{label}</Typography>
          </Box>
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${value}%`}</Typography>
        </Box>
      </Box>
    );
  };
