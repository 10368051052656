export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const ADD_CHECKBOX = 'ADD_CHECKBOX';
export const REMOVE_CHECKBOX = 'REMOVE_CHECKBOX';
export const UPDATE_INCIDENT_DATE_TIME = 'UPDATE_INCIDENT_DATE_TIME';
export const ONCHANGE_DRAWER_FORM = "ONCHANGE_DRAWER_FORM";
export const ADD_PERSONNEL_DETAIL='ADD_PERSONNEL_DETAIL';
export const ADD_VEHICLE_DETAIL= 'ADD_VEHICLE_DETAIL';
export const ADD_EQUIPMENT_DETAIL= 'ADD_EQUIPMENT_DETAIL';
export const ADD_EVIDENCE_DETAIL = "ADD_EVIDENCE_DETAIL";
export const ADD_CAPA_DETAIL = "ADD_CAPA_DETAIL";
export const ADD_LTI_DETAIL = "ADD_LTI_DETAIL";
export const UPDATE_SWITCH_STATE = 'UPDATE_SWITCH_STATE';
export const EDIT_CAPA_DETAIL = "EDIT_CAPA_DETAIL";
export const DELETE_CARD_DETAIL = 'DELETE_CARD_DETAIL';
export const SET_ALL_REPORT_DATA = 'SET_ALL_REPORT_DATA';
export const SET_LTI_DETAILS = "SET_LTI_DETAILS";
export const UPDATE_EVIDENCE_FILE ="UPDATE_EVIDENCE_FILE";
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const RESET_FILTER = "RESET_FILTER";
export const RESET_STATE = "RESET_STATE";
export const SET_REPORT_FORM_DATA ="SET_REPORT_FORM_DATA";