import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  LocationOn as LocationOnIcon,
} from "@mui/icons-material";
import { changeDefaultLocation, getUserAssignedLocations } from "../../Apis/apiCall";

const ProfileSearchLocation = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [locations, setLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [defaultLocationChanged,setDefaultLocationChanged] = useState(false);

  useEffect(() => {
    fetchAssignedLocations();
  }, []);

  const fetchAssignedLocations = async () => {
    try {
      const res = await getUserAssignedLocations();
      const assignedLocations = res.data.data?.roles.map((role) => ({
        orgLevelID: role.orgLevelID,
        locationName: role.locationName,
      }));
      setLocations(assignedLocations);
    } catch (error) {
      console.error("Error while fetching locations", error);
    }
  };

  const handleSetDefaultLocation = (location) => {
    setSelectedLocation(location);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLocation(null);
  };

  const handleSubmit = async () => {
    try {
        await changeDefaultLocation({locationID:selectedLocation});
        handleClose();
        setDefaultLocationChanged(true);  
    } catch (error) {
        console.error("error while setting location", error);
    }
};


  return (
    <>
      <Box sx={{ width: "70%" }}>
        {/* Search Bar */}
        <TextField
          variant="outlined"
          placeholder="Search Location"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              display: "flex",
              height: "52px",
              padding: "12px",
              alignItems: "center",
              gap: "12px",
              flexShrink: 0,
              alignSelf: "stretch",
              borderRadius: "8px",
              border: "1.04px solid rgba(0, 0, 0, 0.13)",
              background: "#FAFAFA",
            },
          }}
          fullWidth
        />

        {/* Dropdown List */}
        <Paper
          style={{
            marginTop: "8px",
            height: "auto",
            maxHeight: "314px",
            flexShrink: 0,
            alignSelf: "stretch",
            borderRadius: "8px",
            background: "#FFF",
            boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            overflowY: "auto",
          }}
        >
          <List>
            {locations
              ?.filter((location) =>
                location.locationName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              ?.map((location, index) => (
                <ListItem
                  key={index}
                  button
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleSetDefaultLocation(location.orgLevelID)}
                >
                  <ListItemIcon>
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary={location.locationName} />
                </ListItem>
              ))}
          </List>
        </Paper>
      </Box>

      {defaultLocationChanged === true && (
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: 'success',
            borderRadius: 1,
            p: 2,
            my: 2,
            maxWidth: 'fit-content'
          }}
        >
          <Typography
            variant="body1"
            color="success"
            sx={{
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
              '& .MuiSvgIcon-root': {
                mr: 1
              }
            }}
          >
            Your default location has changed. Please login again to see changes.
          </Typography>
        </Box>
      )}

      

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "455px",
            height: "228.704px",
            borderRadius: "14.444px",
            background: "#FFF",
            boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            padding: "20px",
          },
        }}
      >
        <DialogTitle
          style={{ textAlign: "center", color: "#007bff", fontWeight: "bold" }}
        >
          Are You Sure?
        </DialogTitle>
        <DialogContent style={{ textAlign: "center", marginBottom: "20px" }}>
          Are you sure you want to change the location?
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{ color: "#000", borderColor: "#000" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: "#007bff", color: "#FFF" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileSearchLocation;
