import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardMedia,
  Divider,
  Button,
  IconButton,
} from "@mui/material";
import {
  videoTutorialCardImg,
  helpManualsCardImg,
  faqsCardImg,
} from "../constant";
import CustomHelpManualCard from "../Components/CustomHelpManualCard";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Description as HelpIcon } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CustomHelpManualDialog from "../Components/CustomHelpManualDialog";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { getHelpManuals, getFAQs, getVideoTutorials } from "../Apis/apiCall";
import PDFReviwer from "../Components/PdfReview";

export default function HelpManual() {
  const data = JSON.parse(localStorage.getItem("loginData"));
  const systemRole = data?.config?.permissionDetails?.systemRole?.name;
  // console.log("systemRole",systemRole);

  const [role, setRole] = useState("user"); //"user", "admin"
  useEffect(() => {
    if (systemRole === "Admin") {
      setRole("admin");
    }
  }, []);

  const [modulesList, setModulesList] = useState([
    "Incident Management",
    "Loss Control",
    "CAPA",
  ]);
  const [selectedModuleName, setSelectedModuleName] = useState(
    "Incident Management"
  ); //"Incident Management", "Loss Control", "CAPA", Any other..
  const [viewMode, setViewMode] = useState(null); //null, videoTutorials, helpManuals, faqs
  const [pdfViewPage, setPdfViewPage] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [pdfName, setPdfName] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null); //null, videoTutorials, helpManuals, faqs

  const handleDialogOpen = (title) => {
    setDialogTitle(title);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handlePdfView = (link, title) =>{
    setPdfViewPage(true);
    setPdfLink(link);
    setPdfName(title);
  }
  const handleHelpCenterBack = () =>{
    if(pdfViewPage){
      setViewMode("helpManuals");
      setPdfViewPage(false);
      setPdfLink(null);
      setPdfName(null);
    }
    else{
      setViewMode(null);
    }
  }
  //-------------------------APIs-----------------------------//
  const [videoData, setVideoData] = useState([]);
  const [helpManualData, setHelpManualData] = useState([]);
  const [faqsData, setFaqsData] = useState([]);

  const [moduleVideoData, setModuleVideoData] = useState([]);
  const [moduleHelpManualData, setModuleHelpManualData] = useState([]);
  const [moduleFaqsData, setModuleFaqsData] = useState([]);

  useEffect(() => {
    fetchVideoTutorials();
    fetchHelpManuals();
    fetchFaqs();
  },[])
  
  const fetchVideoTutorials = async() => {
    try {
      const res = await getVideoTutorials();
      setVideoData(res.data.data);
    } catch (error) {
      console.error("Error while fetching Videos",error);
    }
  }

  const fetchHelpManuals = async() => {
    try {
      const res = await getHelpManuals();
      setHelpManualData(res.data.data);
    } catch (error) {
      console.error("Error while fetching Manuals",error);
    }
  }
  
  const fetchFaqs = async() => {
    try {
      const res = await getFAQs();
      setFaqsData(res.data.data);
    } catch (error) {
      console.error("Error while fetching FAQs",error);
    }
  }
  useEffect(() => {
    setViewMode(null);
    setPdfViewPage(false);
    setPdfLink(null);
    setPdfName(null);
    if (selectedModuleName === "Incident Management") {
      setModuleVideoData(videoData.filter((item) => item.moduleID === 1));
      setModuleFaqsData(faqsData.filter((item) => item.moduleID === 1));
      setModuleHelpManualData(helpManualData.filter((item) => item.moduleID === 1));
    } else if (selectedModuleName === "Loss Control") {
      setModuleVideoData(videoData.filter((item) => item.moduleID === 2));
      setModuleFaqsData(faqsData.filter((item) => item.moduleID === 2));
      setModuleHelpManualData(helpManualData.filter((item) => item.moduleID === 2));
    } else if (selectedModuleName === "CAPA") {
      setModuleVideoData(videoData.filter((item) => item.moduleID === 3));
      setModuleFaqsData(faqsData.filter((item) => item.moduleID === 3));
      setModuleHelpManualData(helpManualData.filter((item) => item.moduleID === 3));
    }
  }, [selectedModuleName, videoData, faqsData, helpManualData]);

  return (
    <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box
            sx={{
              position: "relative",
              padding: "24px",
              minHeight: "80vh",
              borderRadius: "12px",
              backgroundColor: "#FFFFFF",
              boxShadow: "2px 0px 4px 0px rgba(0, 0, 0, 0.16)",
            }}
          >
            <Typography
              sx={{ color: "#525966", fontSize: "16px", fontWeight: "500" }}
            >
              Help Center
            </Typography>
            <Divider sx={{ my: 1 }} />
            <List>
              {modulesList?.map((moduleName, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => setSelectedModuleName(moduleName)}
                  sx={{
                    backgroundColor:
                      selectedModuleName === moduleName ? "#E5F5FF" : "none",
                    borderRadius: 1,
                    mb: 0.5,
                  }}
                >
                  <ListItemIcon>
                    {moduleName === "Incident Management" ? (
                      <InsertDriveFileIcon
                        sx={{
                          color:
                            selectedModuleName === moduleName
                              ? "#09A2E3"
                              : "#525966",
                        }}
                      />
                    ) : moduleName === "Loss Control" ? (
                      <ManageAccountsOutlinedIcon
                        sx={{
                          color:
                            selectedModuleName === moduleName
                              ? "#09A2E3"
                              : "#525966",
                        }}
                      />
                    ) : moduleName === "CAPA" ? (
                      <MenuBookIcon
                        sx={{
                          color:
                            selectedModuleName === moduleName
                              ? "#09A2E3"
                              : "#525966",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color:
                            selectedModuleName === moduleName
                              ? "#09A2E3"
                              : "#525966",
                          fontWeight:
                            selectedModuleName === moduleName
                              ? "bold"
                              : "medium",
                        }}
                      >
                        {moduleName}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
            {role === "admin" && (
              <Button
                startIcon={<AddIcon />}
                sx={{
                  backgroundColor: "none", // Light blue background
                  color: "#19A2E3", // Blue text color
                  textTransform: "none", // Keep the text as is, without uppercase transformation
                  boxShadow: "none",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "rgba(10, 162, 227, 0.07)", // Slightly darker on hover
                  },
                  position: "absolute",
                  bottom: "16px",
                  right: "16px",
                }}
              >
                Add Module
              </Button>
            )}
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box
            sx={{
              position: "relative",
              padding: "24px",
              minHeight: "80vh",
              borderRadius: "12px",
              backgroundColor: "#FFFFFF",
              boxShadow: "2px 0px 4px 0px rgba(0, 0, 0, 0.16)",
            }}
          >
            {viewMode === null ? (
              <>
                <Typography
                  sx={{ color: "#525966", fontSize: "16px", fontWeight: "600" }}
                >
                  {selectedModuleName}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomHelpManualCard
                      mediaSrc={videoTutorialCardImg}
                      mediaType="image"
                      role={role}
                      title="Video Tutorials"
                      description="Browse to see various workflows in an interactive manner."
                      onClick={() => setViewMode("videoTutorials")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomHelpManualCard
                      mediaSrc={helpManualsCardImg}
                      mediaType="image"
                      role={role}
                      title="Help Manuals"
                      description="Browse help manuals for various activities within a workflow."
                      onClick={() => setViewMode("helpManuals")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomHelpManualCard
                      mediaSrc={faqsCardImg}
                      mediaType="image"
                      role={role}
                      title="FAQs"
                      description="Browse Frequently Asked Questions about various modules."
                      onClick={() => setViewMode("faqs")}
                    />
                  </Grid>
                </Grid>
              </>
            ) : viewMode === "videoTutorials" ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                  >
                  <IconButton onClick={() => setViewMode(null)}>
                    <ArrowBackIosIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "#525966",
                        cursor: "pointer",
                      }}
                    />
                    </IconButton>
                    <Box>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {selectedModuleName}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Video Tutorials
                      </Typography>
                    </Box>
                  </Box>
                  {role === "admin" && (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      sx={{
                        backgroundColor: "rgba(10, 162, 227, 0.08)", // Light blue background
                        color: "#19A2E3", // Blue text color
                        textTransform: "none", // Keep the text as is, without uppercase transformation
                        boxShadow: "none",
                        fontWeight: "500",
                        "&:hover": {
                          backgroundColor: "rgba(10, 162, 227, 0.2)", // Slightly darker on hover
                        },
                      }}
                      onClick={() => handleDialogOpen(viewMode)}
                    >
                      Add More Tutorial
                    </Button>
                  )}
                </Box>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2} mt={1}>
                  {moduleVideoData?.map((video,index)=>(
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomHelpManualCard
                      mediaSrc={
                        // "https://netzero-ehs.s3.ap-south-1.amazonaws.com/uploads/1731558617258_SampleVideo_1280x720_2mb.mp4"
                        video.url
                      }
                      mediaType="video"
                      role={role}
                      title={video.title}
                      description={video.description}
                      onClick={() => setViewMode("videoTutorials")}
                    />
                  </Grid>))}
                </Grid>
              </>
            ) : viewMode === "helpManuals" ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                  >
                  <IconButton onClick={handleHelpCenterBack}>
                    <ArrowBackIosIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "#525966",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                    <Box>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {pdfViewPage ? pdfName : selectedModuleName}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Help Manuals
                      </Typography>
                    </Box>
                  </Box>
                  {role === "admin" && (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      sx={{
                        backgroundColor: "rgba(10, 162, 227, 0.08)", // Light blue background
                        color: "#19A2E3", // Blue text color
                        textTransform: "none", // Keep the text as is, without uppercase transformation
                        boxShadow: "none",
                        fontWeight: "500",
                        "&:hover": {
                          backgroundColor: "rgba(10, 162, 227, 0.2)", // Slightly darker on hover
                        },
                      }}
                      onClick={() => handleDialogOpen(viewMode)}
                    >
                      Add More Files
                    </Button>
                  )}
                </Box>
                <Divider sx={{ mt: 1, mb: 2 }} />
                
                {!pdfViewPage && moduleHelpManualData?.map((manual,index)=>(
                  <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "8px",
                      backgroundColor: "#FFF",
                      boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.20)",
                      padding: "12px 16px",
                      width: "100%",
                      position: "relative",
                      cursor: "pointer",
                      mt: 2
                    }}
                    onClick={() => handlePdfView(manual.url, manual.title)}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: 48,
                          height: 48,
                          backgroundColor: "#F4F6F9",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "16px",
                        }}
                      >
                        <HelpIcon fontSize="large" style={{ color: "#8A94A6" }} />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#525966",
                          }}
                        >
                          {manual.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#525966",
                          }}
                        >
                          {manual.description?.length > 50 ? manual.description.substring(0, 50) + "..." : manual.description}
                        </Typography>
                      </Box>
                    </Box>
                    <ArrowForwardIcon
                      style={{
                        color: "#525966",
                        position: "absolute",
                        bottom: 8,
                        right: 12,
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    {role === "admin" && (
                      <DeleteOutlineIcon
                        style={{
                          color: "#09A2E3",
                          position: "absolute",
                          top: 16,
                          right: 12,
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    )}
                  </Box>
                </>
                ))}
                {pdfViewPage && (<PDFReviwer pdfUrl={pdfLink} />)}
              </>
            ) : viewMode === "faqs" ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                  >
                  <IconButton onClick={() => setViewMode(null)}>
                    <ArrowBackIosIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "#525966",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                    <Box>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {selectedModuleName}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Frequently Asked Questions
                      </Typography>
                    </Box>
                  </Box>
                  {role === "admin" && (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      sx={{
                        backgroundColor: "rgba(10, 162, 227, 0.08)", // Light blue background
                        color: "#19A2E3", // Blue text color
                        textTransform: "none", // Keep the text as is, without uppercase transformation
                        boxShadow: "none",
                        fontWeight: "500",
                        "&:hover": {
                          backgroundColor: "rgba(10, 162, 227, 0.2)", // Slightly darker on hover
                        },
                      }}
                      onClick={() => handleDialogOpen(viewMode)}
                    >
                      Add More Questions
                    </Button>
                  )}
                </Box>
                <Divider sx={{ mt: 1, mb: 2 }} />
                {moduleFaqsData?.map((faq,index)=>(
                <Accordion
                  sx={{
                    border: "0.5px solid #B7C8E5",
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.20)",
                    mt: 2
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: "#525966",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {`${index+1}. ${faq.question}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{
                        color: "#525966",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {faq.answer}
                    </Typography>
                    {role === "admin" && (
                      <DeleteOutlineIcon
                        style={{
                          color: "#09A2E3",
                          position: "absolute",
                          bottom: 12,
                          right: 60,
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {role === "admin" && (
                      <ModeEditOutlineOutlinedIcon
                        sx={{
                          color: "rgba(82, 89, 102, 1)",
                          position: "absolute",
                          bottom: 12,
                          right: 32,
                          width: "18px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
                ))}
              </>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
      <CustomHelpManualDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        type={dialogTitle}
      />
    </Box>
  );
}
