import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const TreeNode = ({ node, level = 0, isLastChild = false, getLocationIDs }) => {
  // Check if this node or any of its children are in the target path
  const isInPath = (node) => {
    if (getLocationIDs?.includes(node.id)) return true;
    if (!node.children) return false;
    return node.children.some(child => isInPath(child));
  };

  // If node is not in path to any target location, don't render it
  if (!isInPath(node)) return null;

  const hasChildren = node.children && node.children.length > 0;
  const isTarget = getLocationIDs?.includes(node.id);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* Node Content with Arrow */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pl: level * 3,
          mb: 1,
        }}
      >
        {/* Arrow for child nodes */}
        {level !== 0 && (
          <SubdirectoryArrowRightIcon
            sx={{
              color: "#00b1f4",
              mr: 1,
            }}
          />
        )}

        {/* Node Content */}
        <Paper
          elevation={isTarget ? 0 : 1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: hasChildren ? '#1E88E5' : '#fff',
            padding: isTarget ? '4px !important' : '4px !important',
            borderRadius: '8px',
            width: '250px',
            border:  'none',
          }}
        >
          {isTarget ? (
            <LocationOnIcon sx={{ mr: 1, color: '#666' }} />
          ) : (
            <BusinessIcon fontSize="small" sx={{ mr: 1, color: hasChildren ? '#fff' : '#666' }} />
          )}

          <Box sx={{ flexGrow: 1 }}>
            <Typography 
              variant="body1" 
              sx={{ 
                fontWeight: isTarget ? 'none' : 'bold',
                color: hasChildren ? '#fff' : '#666',
                overflow:'hidden',
                textOverflow:'ellipsis',
                whiteSpace:'nowrap',
                pr:'4px'
              }}
            >
              {node.displayName}
            </Typography>
            {/* {isTarget && node.locationCode && (
              <Typography variant="caption" sx={{ color: '#1E88E5', display: 'block' }}>
                {node.locationCode}
              </Typography>
            )} */}
          </Box>
        </Paper>
      </Box>

      {/* Render Children */}
      {hasChildren && (
        <Box>
          {node.children
            .filter(child => isInPath(child))
            .map((child, index, filteredArray) => (
              <TreeNode
                key={child.id}
                node={child}
                level={level + 1}
                isLastChild={index === filteredArray.length - 1}
                getLocationIDs={getLocationIDs}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

const OfficeTree = ({ data,allLocations,getLocationIDs }) => {

  return (
    <Box sx={{ p: 4 }}>
      {allLocations?.map((node) => (
        <TreeNode 
          key={node.orgID || node.id} 
          node={node} 
          getLocationIDs={getLocationIDs}
        />
      ))}
    </Box>
  );
};

export default OfficeTree;