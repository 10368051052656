import React, { useState, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false, // Disable default tooltip
        },
    },
};

// Custom plugin for center text display
const centerTextPlugin = {
    id: 'centerText',
    beforeDraw: (chart) => {
        const { width, height, ctx } = chart;
        const total = chart.data.datasets[0].data.reduce((acc, value) => acc + value, 0);
        const activeElement = chart.getActiveElements()[0];
        const activeValue = activeElement
            ? chart.data.datasets[0].data[activeElement.index]
            : chart.data.datasets[0].data[0];
        const percentage = ((activeValue / total) * 100).toFixed(0);

        ctx.save();
        ctx.font = 'bold 16px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = activeElement ? '#000' : '#fff';
        ctx.fillText(`${percentage}%`, width / 2, height / 2);
        ctx.restore();
    },
};

const DoughnutChart = ({ data, view = 0 }) => {
    const defaultData = {
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['#FF5753', '#13DEB9', '#09A2E3'],
                borderColor: ['#FF5753', '#13DEB9', '#09A2E3'],
                borderWidth: 1,
            },
        ],
        labels: ['Unsatisfactory', 'Satisfactory', 'Not Applicable'],
    };

    const [tooltipContent, setTooltipContent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const chartRef = useRef();

    const handleHover = (event, chartElement) => {
        if (chartElement.length) {
            const index = chartElement[0]?.index;
            const label = data?.labels?.[index];
            const value = data?.datasets[0]?.data?.[index];
            setTooltipContent(`${label}: ${value}`);
            
            const chartInstance = chartRef.current;
            const { offsetLeft, offsetTop } = chartInstance.canvas;
            setTooltipPosition({
                x: offsetLeft + chartInstance.canvas.width -30,
                y: offsetTop + chartElement[0].element.y-30,
            });
        } else {
            setTooltipContent(null); // Hide tooltip when not hovering
        }
    };

    // Hide tooltip when mouse leaves the chart area
    const handleMouseLeave = () => {
        setTooltipContent(null);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ p: '0px 24px', height: view === 1 ? "280px" : "130px" }}
            onMouseLeave={handleMouseLeave} // Add mouse leave event to hide tooltip
        >
            <Doughnut
                ref={chartRef}
                data={data || defaultData}
                options={{
                    ...options,
                    onHover: (event, chartElement) => handleHover(event, chartElement),
                }}
                plugins={[centerTextPlugin]}
            />
            {tooltipContent && (
                <div
                    style={{
                        position: 'absolute',
                        top: tooltipPosition.y,
                        left: tooltipPosition.x,
                        backgroundColor: 'rgba(128, 128, 128, 0.8)',
                        padding: '6px 10px',
                        borderRadius: '4px',
                        color: '#fff',
                        fontSize: '12px',
                    }}
                >
                    {tooltipContent}
                </div>
            )}
        </Box>
    );
};

export default DoughnutChart;
