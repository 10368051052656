import axios from 'axios';

export const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    if (/mobile|android|iphone|ipad|tablet/.test(userAgent)) {
      return 'Web';
    }
    return 'Web';
  };

 export  const getIPAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      // console.log(response.data.ip);
      return response.data.ip;
     
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return null;
    }
  };

//   export const getDeviceID = () => {
//     let deviceId = localStorage.getItem('device_id');
//     if (!deviceId) {
//       deviceId = uuidv4();
//       localStorage.setItem('device_id', deviceId);
//     }
//     return deviceId;
//   };

