import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import {
    temperature,
    SafetyFirst,
    rainy,  // Path to your rainy GIF
    sunny   // Path to your sunny GIF
} from '../constant';

const BroadCastCard = () => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [weather, setWeather] = useState(null);
    const [error, setError] = useState(null);

    const API_KEY = 'e4dbc97b1b674b6ad3cd32d2ae95aa5c'; // Replace with your API key

    // useEffect(() => {
    //     // Get user's current location
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 setLatitude(position.coords.latitude);
    //                 setLongitude(position.coords.longitude);
    //             },
    //             (error) => {
    //                 setError("Unable to retrieving your location");
    //                 console.error(error);
    //             }
    //         );
    //     } else {
    //         setError("Geolocation is not supported by this browser.");
    //     }
    // }, []);
    useEffect(()=>{
        const loginDataString = localStorage.getItem("loginData");
        if (loginDataString) {
          const data = JSON.parse(loginDataString);
          const {latitude,longitude} = data;
          setLatitude(latitude);
          setLongitude(longitude);
    
        }
     })

    useEffect(() => {
        if (latitude && longitude) {
            // Fetch weather data when we have the latitude and longitude
            const fetchWeatherData = async () => {
                try {
                    const response = await axios.get(
                        `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`
                    );
                    // console.log(response.data);
                    setWeather(response.data);
                } catch (error) {
                    console.error("Error fetching the weather data", error);
                    setError("Error fetching weather data");
                }
            };

            fetchWeatherData();
        }
    }, [latitude, longitude, API_KEY]);

    // Function to get background GIF based on weather description
    const getWeatherBackground = (description) => {
        if (description.includes('rain')) return rainy;
        if (description.includes('clear')) return sunny;
        // Add more conditions if needed
        return sunny; // Default to sunny if no match
    };

    return (
        <>
            {error ? (
                <p>{error}</p>
            ) : weather ? (
                <>
                <Box
                    sx={{
                        backgroundImage: `url(${getWeatherBackground(weather?.weather[0].description)})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        minHeight:"200px",
                        padding: '12px',
                        borderRadius: '8px',
                        color: '#fff' // Ensure text is readable over the background
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body2" >{weather?.name}</Typography>
                        <Box display="flex" alignItems="center">
                            <img src={temperature} alt="temperature" />
                            <Typography sx={{ fontSize: '18px', fontWeight: '700' ,color:"#000000" }}>
                            {Math.round(weather?.main.temp)}°C
                            </Typography>
                        </Box>
                    </Box>
                    
                </Box>
                <Box display="flex" alignItems="center" mt={2}>
                <img src={SafetyFirst} height="77px" alt="Safety First" />
                <Typography variant="subtitle2" sx={{ fontWeight: '600', ml: 2 }}>
                    Alert and Stay Safe
                </Typography>
            </Box>
            </>
            ) : (
                <p>Loading weather data...</p>
            )}
        </>
    );
};

export default BroadCastCard;
