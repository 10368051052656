import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Chip,
  Typography,
  Divider,
  Button,
  Tabs,
  Tab,
  Checkbox,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Tooltip
} from "@mui/material";
import ViewStepperBox from "../Components/LossControl/ViewStepperBox";
import VerticalStepperStatus from "../Components/LossControl/VerticalStepperStatus";
import CommentSection from "../Components/LossControl/CommentSection";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { userFullName, todayDate, disciplineCover } from "../constant";
import IMDrawer from "../Components/LossControl/IMDrawer";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import DoughnutChart from "../Components/ChartAndGraphs/DoughnutChart";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import {
  getLossControlReport,
  acceptOrRevertLossControlReport,
  acceptOrRevertReportByMM,
  revalidateLossControlReport,
} from "../Apis/apiCall";
import ProgressBar from "../Components/LossControl/ProgressBar";
import AcceptReturnButtonGrp from "../Components/LossControl/AcceptReturnButtonGrp";
import AcceptModal from "../Components/LossControl/AcceptModal";
import {
  formatTableDate,
  formatDateToIndianLocale,
  formatUpdateTime,
} from "../utils/helper";
import { styled } from "@mui/system";
import RemarkModal from "../Components/LossControl/RemarkModal";
import RightDrawer from "../Components/LossControl/LossWorkFlowDrawer";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { blue } from "@mui/material/colors";
//import VerticalStepperStatus from "../Components/LossControl/VerticalStepperStatus";
import ReturnValidationModal from "../Components/LossControl/ReturnValidationModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExecutiveEditDrawer from "../Components/LossControl/ExecutiveEditDrawer";
import {
  ArrowDropDown,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import IMEditCommentDrawer from "../Components/LossControl/IMEditCommentDrawer";
import IMCommentDrawer from "../Components/LossControl/IMCommentDrawer";
import DraggableImageDialog from "../Components/DraggableImageDialog";
import ConfirmationModal from "../Components/LossControl/ConfirmationModal";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import StatusChip from "../Components/Chip";
import Loader from "../Components/Loader/Loader";
import { START_LOADING, STOP_LOADING } from "../redux/Loader/constant"

const CenteredIconBox = ({
  children,
  backgroundColor = "rgba(9, 162, 227, 0.10)",
  borderRadius = "6px",
  padding = "8px",
  ...props
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        padding: padding,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const getLossStatusChip = (status) => {
  switch (status) {
    case 1:
      return (
        <Chip
          label="In Progress"
          sx={{
            mt: 2,
            backgroundColor: "#E7F6FD",
            color: "#009FF5",
            borderRadius: "24px",
            fontSize: "12px",
            fontWeight: "600",
            minWidth: "100px",
            maxHeight: "26px",
          }}
        />
      );
    case 2:
      return (
        <Chip
          label="For Approval"
          sx={{
            mt: 2,
            color: "#FF5753",
            backgroundColor: "rgba(255, 87, 83, 0.15)",
            fontSize: "12px",
            fontWeight: "600",
            minWidth: "100px",
            maxHeight: "26px",
            borderRadius: "24px",
          }}
        />
      );
    case "New_Survey":
      return (
        <Chip
          label="New Tour"
          color="#FF8A00"
          bgColor="rgba(255, 138, 0, 0.1)"
        />
      );
    case "Under_Review":
      return (
        <Chip
          label="Under Review"
          sx={{
            mt: 2,
            color: "#FFA800",
            backgroundColor: "rgba(255, 168, 0, 0.1)",
            fontSize: "12px",
            fontWeight: "600",
            minWidth: "100px",
            maxHeight: "26px",
            borderRadius: "24px",
          }}
        />
      );
    case 3:
      return (
        <Chip
          label="Return"
          sx={{
            mt: 2,
            color: "#FFA800",
            backgroundColor: "rgba(255, 168, 0, 0.1)",
            fontSize: "12px",
            fontWeight: "600",
            minWidth: "100px",
            maxHeight: "26px",
            borderRadius: "24px",
          }}
        />
      );
    case "Revalidation":
      return (
        <Chip
          label="Revalidation"
          sx={{
            mt: 2,
            color: "#FF7742",
            backgroundColor: "rgba(255, 119, 66, 0.1)",
            fontSize: "12px",
            fontWeight: "600",
            minWidth: "100px",
            maxHeight: "26px",
            borderRadius: "24px",
          }}
        />
      );

    case 4:
      return (
        <Chip
          label="Completed"
          sx={{
            mt: 2,
            color: "#0B7F6A",
            backgroundColor: "rgba(11, 127, 106, 0.1)",
            fontSize: "12px",
            fontWeight: "600",
            minWidth: "100px",
            maxHeight: "26px",
            borderRadius: "24px",
          }}
        />
      );
    default:
      return "";
  }
};
const getTopStatusChip = (status) => {
  switch (status) {
    // all losss control status
    case "In_Progress":
      return (
        <StatusChip
          label="In Progress"
          color="#0B7F6A"
          bgColor="rgba(11, 127, 106, 0.1)"
        />
      );
    case "New_Survey":
      return (
        <StatusChip
          label="New Tour"
          color="#FF8A00"
          bgColor="rgba(255, 138, 0, 0.1)"
        />
      );
    case "Under_Review":
      return (
        <StatusChip
          label="Under Review"
          color="#009FF5"
          bgColor="rgba(0, 159, 245, 0.1)"
        />
      );
    case "Revalidation":
      return (
        <StatusChip
          label="Revalidation"
          color="#FF7742"
          bgColor="rgba(255, 119, 66, 0.1)"
        />
      );
    case "Resubmission":
      return (
        <StatusChip
          label="Resubmission"
          color="#FF7742"
          bgColor="rgba(255, 119, 66, 0.1)"
        />
      );
    case "Recommendation_Resubmission":
      return (
        <StatusChip
          label="For Approval"
          color="#FF7742"
          bgColor="rgba(255, 119, 66, 0.1)"
        />
      );
    case "Returned_By_MM":
      return (
        <StatusChip
          label="Returned"
          color="#FF7742"
          bgColor="rgba(255, 119, 66, 0.1)"
        />
      );
    case "Completed":
      return (
        <StatusChip
          label="Tour Completed"
          color="#0B7F6A"
          bgColor="rgba(11, 127, 106, 0.1)"
        />
      );
  }
};

const CustomTabs = styled(Tabs)({
  minHeight: "auto",
  marginBottom: "16px",
  width: "100%",
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-evenly",
  },
});

const CustomTab = styled(Tab)(({ theme, selected }) => ({
  textTransform: "none",
  padding: theme.spacing(1, 2),
  minHeight: "auto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  backgroundColor: selected ? "rgba(9, 162, 227, 0.12)" : "transparent",
  borderRadius: 8,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export default function LossControlView() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);
  const { data } = location?.state || {};
  const role = data?.role || ""; // Default to empty string or appropriate fallback
  const status = data?.status || "";
  const mode = data?.mode || "";
  const id = data?.id || "";
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [allData, setAllData] = useState({});
  const [counts, setCounts] = useState([]);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  // const [acceptModaltitle, setAcceptModaltitle] = useState("");
  const [activeTab, setActiveTab] = useState(0); // 0 for "Executive Info", 1 for "Installation Manager"
  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");
  const [recommendationNumber, setRecommendationNumber] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [questionID, setQuestionID] = useState(null);

  const [editDrawer, setEditDrawer] = useState(false);
  const [imCommentDrawer, setImCommentDrawer] = useState(false);

  // const [activeTab, setactiveTab] = useState(0);
  const [isReturned, setIsReturned] = useState(0);
  const [returnMode, setReturnMode] = useState(0);
  const [revalidationDisabled, setRevalidationDisabled] = useState(0);
  const [checkBoxMap, setCheckBoxMap] = useState([]);
  const [remark, setRemark] = useState("");
  const [remarkEditMode, setRemarkEditMode] = useState(0);
  const [cards, setCards] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(1);  // null, 0, 1, 2, 3 ---> null-All, 0-Satisfactory, 1-Unsatisfactory, 2-Not Applicable, 3-Approval
  const [flow, setFlow] = useState(null);
  const [showVerticalStepper, setShowVerticalStepper] = useState(false);
  const [isIconUp, setIsIconUp] = useState(false);
  const [openReturnMessage, setOpenReturnMessage] = useState(0);

  // console.log("getprops", status, mode, role, id);
  // console.log("activeTab", activeTab);

  // Executive Return Drawer Data
  const [executiveQues, setExecutiveQues] = useState("");
  const [executiveRemark, setExecutiveRemark] = useState([]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (role === "Installation Manager") {
      setFlow(1);
    }

    if (role === "Executive Officer") {
      setFlow(2);
    }
    if (role === "Mine Manager") {
      setFlow(3);
      setActiveTab(1);
    }
  }, []);

  const [chartData, setChartData] = useState({
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: ["#13DEB9", "#FF5753", "#09A2E3"],
        borderColor: ["#13DEB9", "#FF5753", "#09A2E3"],
        borderWidth: 1,
      },
    ],
    labels: ["Satisfactory", "Unsatisfactory", "Not Applicable"],
  });
  const [buttonOptions, setButtonOptions] = useState([
    { value: "return", label: "Return" },
    { value: "accept", label: "Accept" },
  ]);
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [openReturnValidationModal, setOpenReturnValidationModal] =
    useState(false);

  const handleOpenRemarkModal = () => setOpenRemarkModal(true);
  const handleCloseRemarkModal = () => setOpenRemarkModal(false);
  const handleOpenReturnValidationModal = () =>
    setOpenReturnValidationModal(true);
  const handleCloseReturnValidationModal = () =>
    setOpenReturnValidationModal(false);

  const handleSubmitRemarkModal = (remark) => {
    dispatch({ type: START_LOADING });
    acceptOrRevertLossControlReport(
      { action: "revert", overallRemark: remark, disciplineData: checkBoxMap },
      allData.id
    )
      .then((response) => {
        // console.log("API call successful:", response.data);
        setAcceptModalOpen(true);
        // setAcceptModaltitle("return");
        setMessage1("Loss Control Tour returned");
        setMessage2("Loss control tour has been returned to executive");
        // Handle successful response
      })
      .catch((error) => {
        console.error("API call failed:", error);
        // Handle error
      })
      .finally(() => {
        dispatch({ type: STOP_LOADING }); // Stop the loader after the API call completes
      });
  };
  const handleDrawerOpen = (action) => {
    setDrawerTitle("Recommendation " + (selectedCardIndex + 1));
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setEditDrawer(false);
    setImCommentDrawer(false);
  };

  const handleRemarkSave = () => {
    const currentId = allData.disciplineData[selectedCardIndex].id;

    setCheckBoxMap((prevMap) => {
      const exists = prevMap?.some((item) => item.id === currentId);

      if (exists) {
        // If it exists, update the object with the new remark
        return prevMap?.map((item) =>
          item.id === currentId ? { id: currentId, returnRemark: remark } : item
        );
      } else {
        // If it doesn't exist, add the new object
        return [...prevMap, { id: currentId, returnRemark: remark }];
      }
    });

    // Reset remark to an empty string
    setRemark("");
    setRemarkEditMode(0);
  };

  const handleCheckboxChange = () => {
    const currentId = allData.disciplineData[selectedCardIndex].id;

    setCheckBoxMap((prevMap) => {
      // Check if the ID is already in the checkBoxMap
      const exists = prevMap?.some((item) => item.id === currentId);

      if (exists) {
        // If it exists, remove the object
        return prevMap?.filter((item) => item.id !== currentId);
      } else {
        // If it doesn't exist, add the new object
        return [...prevMap, { id: currentId, returnRemark: remark }];
      }
    });
    setRemark("");
  };

  const handleChange = (index) => {
    setSelectedCardIndex(selectedCardIndex === index ? null : index);
    setActiveTab(0);
  };

  //For Confirmation Modal
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalDetails, setConfirmationModalDetails] = useState({
    title: "",
    message: "",
    action: null,
  });

  const handleIMButtonChange = (selectedValue) => {
    let title, message;

    if (selectedValue === "return") {
      title = "Confirm Return";
      message = "Are you sure you want to return this Loss Control Report?";
      setConfirmationModalDetails({ title, message, action: "return" });
    } else if (selectedValue === "accept") {
      title = "Confirm Accept";
      message = "Are you sure you want to accept this Loss Control Report?";
      setConfirmationModalDetails({ title, message, action: "accept" });
    } else if (selectedValue === "revalidation") {
      title = "Confirm Revalidation";
      message =
        "Are you sure you want to revalidate this Loss Control Report with Executive Officer?";
      setConfirmationModalDetails({ title, message, action: "revalidation" });
    }

    setIsConfirmationModalOpen(true);
  };

  const handleConfirmAction = () => {
    const { action } = confirmationModalDetails;

    if (action === "return") {
      setIsReturned(1);
      setOpenReturnMessage(1);
      // console.log("Return selected, perform return operation");
    } else if (action === "accept") {
      dispatch({ type: START_LOADING });
      acceptOrRevertLossControlReport({ action: "accept" }, allData.id)
        .then((response) => {
          // console.log("API call successful:", response.data);
          setAcceptModalOpen(true);
          setMessage1("Loss Control Survey Accepted");
          setMessage2("You can now update recommendations status");
        })
        .catch((error) => {
          console.error("API call failed:", error);
        })
        .finally(() => {
          dispatch({ type: STOP_LOADING }); // Stop the loader after the API call completes
        });
    } else if (action === "revalidation") {
      if (checkBoxMap?.some((item) => item.returnRemark?.length === 0)) {
        handleOpenReturnValidationModal();
      } else {
        handleOpenRemarkModal();
      }
    }

    setIsConfirmationModalOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleAcceptModal = () => {
    setAcceptModalOpen(false);
    if (flow != 3) {
      // setAcceptModaltitle("");
      navigate(`/my-actions`);
    } else {
      window.location.reload();
    }
  };

  const stepperBoxData = [
    {
      role: "Installation Manager",
      status: "completed",
      label: "Tour Submitted",
      executive: "Ashish Patel",
      surveyDate: "24-05-2024",
    },
    {
      role: "Installation Manager 1",
      status: "active",
      label: "IM Review",
      surveyDate: "24-05-2024",
    },
    {
      role: "Installation Manager 2",
      status: "returned",
      label: "Mines Manager",
      executive: "Ashish Patel",
      surveyDate: "24-05-2024",
    },
    {
      role: "Installation Manager 3",
      status: "incomplete",
      label: "Mine Agent ",
      executive: "Ashish Patel",
      surveyDate: "24-05-2024",
    },
    {
      role: "Installation Manager 4",
      status: "incomplete",
      label: "Intimation",
      executive: "Ashish Patel",
      surveyDate: "24-05-2024",
    },
    {
      role: "Installation Manager 5",
      status: "incomplete",
      label:  "Tour Closed",
      executive: "Ashish Patel",
      surveyDate: "24-05-2024",
    },
  ];

  const fetchLossControlReport = async (id, status) => {
    dispatch({type: START_LOADING})
    try {
      const result = await getLossControlReport(id, status);

      // Set the fetched data and counts
      setAllData(result.data.data);
      const fetchedCounts = [
        result?.data?.data?.counts?.satisfactory,
        result?.data?.data?.counts?.unsatisfactory,
        result?.data?.data?.counts?.notApplicable,
      ];
      setCounts(fetchedCounts);
      setCards(
        Array.from(
          { length: result.data.data.disciplineData.length },
          (_, i) => i + 1
        )
      );
    } catch (error) {
      console.log("Error fetching loss control report:", error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  useEffect(() => {
    if (isReturned === 1) {
      setButtonOptions([
        { value: "revalidation", label: "Proceed for Revalidation" },
      ]);
      setSelectedCardIndex(0);
      setActiveTab(0);
      setSelectedStatus(1);
      setReturnMode(1);
      setRevalidationDisabled(1);
    }
  }, [isReturned]); // to re-evaluate the component whenever isReturned updates.

  // Fetch the report data on component mount
  useEffect(() => {
    fetchLossControlReport(data?.id, selectedStatus);
  }, [selectedStatus]);

  useEffect(() => {
    if (checkBoxMap?.length === 0 && returnMode === 1) {
      setRevalidationDisabled(1);
    } else {
      if (
        !checkBoxMap?.find((item) => item?.returnRemark?.length > 0) &&
        returnMode === 1
      ) {
        setRevalidationDisabled(1);
      } else {
        setRevalidationDisabled(0);
      }
    }
  }, [checkBoxMap]);

  // Update chart data when counts change, only if counts have valid values
  useEffect(() => {
    if (counts.length === 3) {
      // Ensure we have exactly 3 values
      setChartData({
        datasets: [
          {
            data: counts,
            backgroundColor: ["#13DEB9", "#FF5753", "#09A2E3"],
            borderColor: ["#13DEB9", "#FF5753", "#09A2E3"],
            borderWidth: 1,
          },
        ],
        labels: ["Satisfactory", "Unsatisfactory", "Not Applicable"],
      });
    }
  }, [counts]);
  useEffect(() => {
    setRemarkEditMode(0);
    setRemark("");
  }, [selectedCardIndex]);


  const [mmAcceptConfirmation, setMmAcceptConfirmation] = useState(false);
  const [mmReturnConfirmation, setMmReturnConfirmation] = useState(false);

  const handleMMAccept = async (id) => {
    dispatch({ type: START_LOADING }); 
    try {
      let values = {
        action: "accept",
        questionID: id,
      };

      const result = await acceptOrRevertReportByMM(values, allData.id);
      if (result) {
        setMessage1("Recommendations Accepted");
        setMessage2("");
        setAcceptModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  const handleMMReturn = async (
    selectedCardIndex,
    recommendation,
    questionId
  ) => {
    dispatch({type : START_LOADING})
    try {
      setDrawerOpen(true);
      setRecommendation(recommendation);
      setRecommendationNumber(`Recommendation${" "}${selectedCardIndex + 1}`);
      setQuestionID(questionId);
    } catch (error) {
      console.log(error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };
  const mmConfirmAccept = () => {
    setMmAcceptConfirmation(false);
    handleMMAccept(allData.disciplineData[selectedCardIndex].id);
  };

  const mmConfirmReturn = () => {
    setMmReturnConfirmation(false);
    handleMMReturn(
      selectedCardIndex,
      allData.disciplineData[selectedCardIndex]?.question,
      allData.disciplineData[selectedCardIndex].id
    );
  };

  const handleReturnExecutiveComment = (ques, remark, quesID) => {
    setDrawerOpen(true);
    setRecommendation(recommendation);
    setRecommendationNumber(`Recommendation${" "}${selectedCardIndex + 1}`);
    setExecutiveQues(ques);
    setExecutiveRemark(remark);
    setEditDrawer(false);
    setQuestionID(quesID);
  };

  const handleReturnExecutiveEdit = () => {
    setEditDrawer(true);
    setDrawerOpen(true);
    setRecommendationNumber(`Recommendation${" "}${selectedCardIndex + 1}`);
  };
  const handleReturnIMEdit = () => {
    setEditDrawer(true);
    setRecommendationNumber(`Recommendation${" "}${selectedCardIndex + 1}`);
  };
  const handleReturnIMComment = () => {
    setImCommentDrawer(true);
    setRecommendationNumber(`Recommendation${" "}${selectedCardIndex + 1}`);
  };

  const executiveComments = useSelector(
    (state) => state.lossWorkFlowReducer.executive
  );
  const executiveEditData = useSelector((state) =>
    state.lossWorkFlowReducer.executiveEditData?.find(
      (item) => item.id === allData.disciplineData?.[selectedCardIndex]?.id
    )
  );
  const executiveEditFullData = useSelector(
    (state) => state.lossWorkFlowReducer.executiveEditData
  );

  const handleVerticalStepper = () => {
    setShowVerticalStepper((prev) => !prev);
    setIsIconUp((prev) => !prev);
    setSelectedCardIndex(null);
    setActiveTab(0);
  };

  const handleSubmitForReview = async () => {
    // Set up confirmation modal details
    const title = "Confirm Submission";
    const message =
      "Are you sure you want to submit this Loss Control Report for review?";
    setConfirmationModalDetails({ title, message, action: "submitReview" });
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmSubmitReviewAction = async () => {
    const { action } = confirmationModalDetails;

    if (action === "submitReview") {
      dispatch({type : START_LOADING})
      try {
        // Format executiveEditFullData into "questionnaires" objects with only base64 Evidence
        // console.log("executiveEditFullData", executiveEditFullData);

        // const filteredEvidenceEditFullData = executiveEditFullData.filter(
        //   (item) => item.evidence.length > 0
        // );

        const formattedEditFullData = await Promise.all(
          executiveEditFullData?.map(async (item) => {
            const base64Evidence = item.evidence.map((evidenceItem) => {
              if (typeof evidenceItem === "string") {
                // If evidenceItem is a string, store it directly
                return evidenceItem;
              } else if (typeof evidenceItem === "object" && evidenceItem.base64) {
                // If evidenceItem is an object with a base64 property, format it
                return {
                  name: evidenceItem.name,
                  type: evidenceItem.type,
                  base64: evidenceItem.base64.split(",")[1],
                };
              }
            });
        
            return {
              questionID: item.id,
              questionStatus: 1,
              observation: item.observation,
              recommendation: item.recommendation,
              targetCompletionDate: item.targetCompletionDate,
              evidence: base64Evidence,
            };
          })
        );

        const formattedComments = executiveComments.map((item) => ({
          questionID: item.id,
          returnRemark: item.comment,
        }));

        const questionaires = [...formattedEditFullData, ...formattedComments];

        const response = await revalidateLossControlReport(allData?.id, {
          questionaires,
        });
        navigate(`/my-actions`);
        // console.log("API response:", response);
      } catch (error) {
        console.error("Error posting data:", error);
      }
      finally{
        dispatch({ type: STOP_LOADING }); 
      }
    }

    setIsConfirmationModalOpen(false);
  };

  const [selectedImgForDialog, setSelectedImgForDialog] = useState(null);
  const [draggableDialogOpen, setDraggableDialogOpen] = useState(false);

  const handleImageClick = (src) => {
    setSelectedImgForDialog(src);
    setDraggableDialogOpen(true);
  };

  const handleCloseDraggableDialog = () => {
    setDraggableDialogOpen(false);
    setSelectedImgForDialog(null);
  };

  // console.log("executiveEditFULDataVIEW", executiveEditFullData);
  // console.log("executiveCOMMDataVIEW", executiveComments);
  // console.log("executiveEditData",executiveEditData);
  

  return (
    <>
    {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Typography variant="h2">Loss Control</Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                {allData?.currentStatusName?.map((status, index) => (
                  <React.Fragment key={index}>
                    {getTopStatusChip(status)}
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Box display="flex">
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  ID:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {allData?.lossControlReportID}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  Executive:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {allData?.firstName +
                    " " +
                    allData?.middleName +
                    (allData?.middleName ? " " : "") +
                    allData?.lastName}
                </Typography>
              </Box>

                  
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex">
                  <Typography variant="body1" sx={{ color: "#222222" }}>
                    Survey Date:
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#525966" }}>
                    {formatDateToIndianLocale(allData?.createdAt)}
                  </Typography>
                </Box>

                <Box
                  onClick={handleVerticalStepper}
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    size="small"
                    sx={{ color: "#000", p: 0 }}
                    endIcon={
                      <Box
                        sx={{
                          fontSize: "28px!important",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {isIconUp ? (
                          <KeyboardArrowUp fontSize="inherit" />
                        ) : (
                          <KeyboardArrowDown fontSize="inherit" />
                        )}
                      </Box>
                    }
                  >
                    {isIconUp ? "Back" : "Status History"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        {flow != 3 && mode === "action" && (
          <Grid item xs={12} md={4}>
            {allData?.currentStatusName?.includes("New_Survey") ||
            allData?.currentStatusName?.includes("Resubmission") ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end", // Aligns horizontally to the right
                  alignItems: "flex-end",
                }}
              >
                <AcceptReturnButtonGrp
                  title={
                    flow === 1
                      ? "Installation Manager Action"
                      : flow === 2
                      ? "Executive Officer Action"
                      : "Mine Manager"
                  }
                  defaultValue=""
                  options={buttonOptions}
                  onChange={handleIMButtonChange}
                  isDisabled={revalidationDisabled}
                />
              </Box>
            ) : (
              <>
                {allData?.returnOverallRemark && flow === 2 && (
                  <Box sx={{ float: "right" }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                    >
                      <Typography sx={{ color: "#666F7F", mb: 1 }}>
                        Executive Action
                      </Typography>
                      <Button
                        variant="contained"
                        disabled={
                          allData?.disciplineData?.some((item) =>
                            item.returnRemark !== null &&
                            !(
                              executiveEditFullData?.some((editItem) => editItem.id === item.id) ||
                              executiveComments?.some((commentItem) => commentItem.id === item.id)
                            )
                          )
                        }
                        sx={{
                          borderRadius: "4px",
                          borderTop: "0.5px solid #B7C8E5",
                          borderRight: "0.5px solid #B7C8E5",
                          borderBottom: "0.5px solid #B7C8E5",
                          backgroundColor: "#09A2E3",
                          color: "#fff",
                        }}
                        onClick={() => handleSubmitForReview()}
                      >
                        Resubmit Survey
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Grid>
        )}

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {showVerticalStepper === true && (
              <Box
                sx={{
                  borderRadius: "8px",
                  background: "#FFF",
                  padding: 0,
                  mt: 3,
                  ml: 2,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexShrink: 0,
                  width:'97%',
                  maxWidth: {
                    xs: '100%',
                    sm: '100%',
                    md: '580px',
                    lg: '700px',
                    xl: '930px'
                  },
                  height: "507px",
                }}
              >
                {showVerticalStepper && <VerticalStepperStatus reportID={id} />}
              </Box>
            )}

            {showVerticalStepper === false && (
              <>
                <Grid item xs={12} mt={1}>
                  <ViewStepperBox stepData={stepperBoxData} reportID={id} />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      backgroundColor: "#ffffff",
                      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                      borderRadius: "8px",
                      padding: "24px",
                      height: "355px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#222222",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      {allData.discipline}
                    </Typography>

                    <Box
                      display="flex"
                      flexDirection="row"
                      gap={2}
                      sx={{ marginY: "16px" }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={0.5}
                      >
                        <PersonOutlineOutlinedIcon
                          sx={{
                            color: "#525966",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#525966",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {allData.firstName + " " + allData.lastName}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={0.5}
                      >
                        <CalendarMonthOutlinedIcon
                          sx={{
                            color: "#525966",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#525966",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {formatDateToIndianLocale(allData.createdAt)}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={0.5}
                      >
                        <LocationOnOutlinedIcon
                          sx={{
                            color: "#525966",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#525966",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {allData.locationName || "N/A"}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Grid View */}
                    <Box>
                      {/* Detailed View Section */}
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                        mb={1}
                      >
                        <Button
                          onClick={() => {
                            if (selectedStatus !== null) {
                              setSelectedCardIndex(null);
                              setSelectedStatus(null);
                              setActiveTab(0);
                            }
                          }}
                          sx={{
                            borderRadius: "12px",
                            fontWeight: selectedStatus === null ? "600" : "500",
                            fontSize: "12px",
                            color:
                              selectedStatus === null ? "white" : "#000000",
                            backgroundColor:
                              selectedStatus === null
                                ? "#09A2E3"
                                : "rgba(9, 162, 227, 0.04)",
                          }}
                        >
                          All
                        </Button>
                        <Button
                          onClick={() => {
                            if (selectedStatus !== 0) {
                              setSelectedCardIndex(null);
                              setSelectedStatus(0);
                              setActiveTab(0);
                            }
                          }}
                          sx={{
                            borderRadius: "12px",
                            fontWeight: selectedStatus === 0 ? "600" : "500",
                            fontSize: "12px",
                            color: selectedStatus === 0 ? "white" : "#000000",
                            backgroundColor:
                              selectedStatus === 0
                                ? "#09A2E3"
                                : "rgba(9, 162, 227, 0.04)",
                          }}
                        >
                          Satisfactory
                        </Button>
                        <Button
                          onClick={() => {
                            if (selectedStatus !== 1) {
                              setSelectedCardIndex(null);
                              setSelectedStatus(1);
                              setActiveTab(0);
                            }
                          }}
                          sx={{
                            borderRadius: "12px",
                            fontWeight: selectedStatus === 1 ? "600" : "500",
                            fontSize: "12px",
                            color: selectedStatus === 1 ? "white" : "#000000",
                            backgroundColor:
                              selectedStatus === 1
                                ? "#09A2E3"
                                : "rgba(9, 162, 227, 0.04)",
                          }}
                        >
                          Unsatisfactory
                        </Button>
                        <Button
                          onClick={() => {
                            if (selectedStatus !== 2) {
                              setSelectedCardIndex(null);
                              setSelectedStatus(2);
                              setActiveTab(0);
                            }
                          }}
                          sx={{
                            borderRadius: "12px",
                            fontWeight: selectedStatus === 2 ? "600" : "500",
                            fontSize: "12px",
                            color: selectedStatus === 2 ? "white" : "#000000",
                            backgroundColor:
                              selectedStatus === 2
                                ? "#09A2E3"
                                : "rgba(9, 162, 227, 0.04)",
                          }}
                        >
                          Not Applicable
                        </Button>
                        {flow === 3 && mode === "action" && (
                        <Button
                          onClick={() => {
                            if (selectedStatus !== 3) {
                              setSelectedCardIndex(null);
                              setSelectedStatus(3);
                              setActiveTab(0);
                            }
                          }}
                          sx={{
                            borderRadius: "12px",
                            fontWeight: selectedStatus === 3 ? "600" : "500",
                            fontSize: "12px",
                            color: selectedStatus === 3 ? "#FF5753" : "#FF5753",
                            backgroundColor:
                              selectedStatus === 3
                                ? "rgba(255, 87, 83, 0.15)"
                                : "rgba(255, 87, 83, 0.08)",
                          }}
                        >
                          For Approval
                        </Button>)}
                      </Box>

                      <Divider sx={{ mb: 2 }} />

                      {/* Cards Grid */}
                      <div
                        style={{
                          maxHeight: "calc(5 * 40px)",
                          overflowY: "auto",
                          padding: "4px",
                        }}
                      >
                        <Grid container spacing={1}>
                          {cards?.map((card, index) => (
                            <Grid item xs={3} sm={2} md={1.2} key={card.id}>
                              <Box
                                onClick={() => handleChange(index)}
                                sx={{
                                  cursor: "pointer",
                                  color:
                                    index === selectedCardIndex
                                      ? "#009FF5"
                                      : "black",
                                  fontWeight: "700",
                                  borderRadius: "4px",
                                  backgroundColor:
                                    index === selectedCardIndex
                                      ? "rgba(0, 159, 245, 0.10)"
                                      : "white",
                                  border:
                                    index === selectedCardIndex
                                      ? "0.5px solid #009FF5"
                                      : "none",
                                  boxShadow:
                                    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  padding: "8px 16px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                {card}
                                {/* {(allData?.disciplineData?.[index]?.workStatus === 1 && allData?.disciplineData?.[index]?.workStatus === 3) && 
                            ((allData?.disciplineData?.[index]?.workStatus === 1) || (allData?.disciplineData?.[index]?.workStatus !== 1 && allData.disciplineData?.[index]?.returnRemark?.length > 0 )) ? (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: 1,
                                    right: 1,
                                    backgroundColor: "#009FF5",
                                    borderRadius: "50%",
                                    width: "18px",
                                    height: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <ChatBubbleIcon
                                    sx={{
                                      fontSize: "10px",
                                      color: "#ffffff",
                                    }}
                                  />
                                </Box>
                              ): null } */}
                                {executiveEditFullData?.some((item) => item.id === allData.disciplineData[index]?.id) ||
                                  executiveComments?.some((item) => item.id === allData.disciplineData[index]?.id) ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 1,
                                      right: 1,
                                      backgroundColor: "green",
                                      borderRadius: "50%",
                                      width: "8px",
                                      height: "8px",
                                    }}
                                  ></Box>
                                )
                                : checkBoxMap?.find((item) => item.id === allData.disciplineData[index]?.id)?.returnRemark?.length > 0 ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      // backgroundColor: "#009FF5",
                                      // borderRadius: "50%",
                                      // width: "18px",
                                      // height: "18px",
                                      // display: "flex",
                                      // alignItems: "center",
                                      // justifyContent: "center",
                                    }}
                                  >
                                    <KeyboardReturnIcon
                                      sx={{
                                        fontSize: "14px",
                                        color: "#009FF5",
                                      }}
                                    />
                                  </Box>
                                )
                                 : allData?.disciplineData?.[index]
                                  ?.workStatus === 0 ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 1,
                                      right: 1,
                                      backgroundColor: "red",
                                      borderRadius: "50%",
                                      width: "8px",
                                      height: "8px",
                                    }}
                                  ></Box>
                                ) : allData?.disciplineData?.[index]
                                    ?.workStatus === 1 ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      // backgroundColor: "#009FF5",
                                      // borderRadius: "50%",
                                      // width: "18px",
                                      // height: "18px",
                                      // display: "flex",
                                      // alignItems: "center",
                                      // justifyContent: "center",
                                    }}
                                  >
                                    <HourglassEmptyIcon
                                      sx={{
                                        fontSize: "14px",
                                        color: "#009FF5",
                                      }}
                                    />
                                  </Box>
                                ) : allData?.disciplineData?.[index]
                                    ?.workStatus === 2 ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                    }}
                                  >
                                    <VisibilityIcon
                                      sx={{
                                        fontSize: "18px",
                                        color: "#009FF5",
                                      }}
                                    />
                                  </Box>
                                ) : allData?.disciplineData?.[index]
                                    ?.workStatus === 4 ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 1,
                                      right: 1,
                                      backgroundColor: "#4CAF50", // Green background color
                                      borderRadius: "50%",
                                      width: "16px",
                                      height: "16px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <CheckIcon
                                      sx={{
                                        fontSize: "10px",
                                        color: "#ffffff", // White color for better contrast
                                      }}
                                    />
                                  </Box>
                                ) : (allData?.disciplineData?.[index]
                                    ?.returnRemark?.length > 0 ||
                                  allData?.disciplineData?.[index]
                                    ?.returnComment?.length > 0 ||
                                  allData?.disciplineData?.[index]
                                    ?.workStatus === 3) ? (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      // backgroundColor: "#009FF5",
                                      // borderRadius: "50%",
                                      // width: "18px",
                                      // height: "18px",
                                      // display: "flex",
                                      // alignItems: "center",
                                      // justifyContent: "center",
                                    }}
                                  >
                                    <KeyboardReturnIcon
                                      sx={{
                                        fontSize: "14px",
                                        color: "#AA4A44",
                                      }}
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} mt={1}>
          <Box
            sx={{
              borderRadius: "8px",
              background: "#FFF",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
              flexGrow: 1,

              minHeight: "507px",
              width: "100%",
            }}
          >
            {selectedCardIndex === null ? (
              <Box
                sx={{
                  padding: "16px",
                  gap: "16px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    border: "0.394px solid #B7C8E5",
                    borderRadius: "10px",
                    padding: "16px",
                  }}
                >
                  {/* <Typography variant="body1" sx={{ color: "#222" }}>
                    {allData?.discipline}
                  </Typography> */}

                  <DoughnutChart
                    data={chartData}
                    title="Questionnaire Status"
                  />

                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2.5}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={0.7}
                    >
                      {/* <Box
                        sx={{
                          padding: "8px",
                          backgroundColor: "rgba(19, 222, 185, 0.20)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "10px",
                        }}
                      > */}
                      <SentimentSatisfiedAltIcon
                        sx={{ fontSize: 24, color: "#0FAB8E" }}
                      />
                      {/* </Box> */}
                      <Typography
                        sx={{
                          color: "#525966",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        Satisfactory
                      </Typography>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        {counts[0]}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={0.7}
                    >
                      {/* <Box
                        sx={{
                          padding: "8px",
                          backgroundColor: "rgba(255, 87, 83, 0.20)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "10px",
                        }}
                      > */}
                      <SentimentVeryDissatisfiedIcon
                        sx={{ fontSize: 24, color: "#FF231F" }}
                      />
                      {/* </Box> */}
                      <Typography
                        sx={{
                          color: "#525966",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        Unsatisfactory
                      </Typography>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        {counts[1]}
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap={0.7}
                    >
                      {/* <Box
                        sx={{
                          padding: "8px",
                          backgroundColor: "rgba(9, 162, 227, 0.20)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "10px",
                        }}
                      > */}
                      <SentimentNeutralIcon
                        sx={{ fontSize: 24, color: "#09A2E3" }}
                      />
                      {/* </Box> */}
                      <Typography
                        sx={{
                          color: "#525966",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        Not Applicable
                      </Typography>
                      <Typography
                        sx={{
                          color: "#525966",
                          fontWeight: "500",
                          fontSize: "12px",
                        }}
                      >
                        {counts[2]}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "0.394px solid #B7C8E5",
                    borderRadius: "10px",
                    padding: "16px",
                  }}
                >
                  {/* <Typography variant="body1" sx={{ color: "#222", mb: "8px" }}>
                    {allData?.discipline}
                  </Typography> */}
                  <Box display="flex" flexDirection="column" gap={1}>
                    <ProgressBar
                      label="Satisfactory"
                      value={parseFloat(
                        (
                          (counts[0] * 100) /
                          (counts[1] + counts[0] + counts[2])
                        ).toFixed(2)
                      )}
                    />
                    <ProgressBar
                      label="Unsatisfactory"
                      value={parseFloat(
                        (
                          (counts[1] * 100) /
                          (counts[1] + counts[0] + counts[2])
                        ).toFixed(2)
                      )}
                    />
                    <ProgressBar
                      label="Not Applicable"
                      value={parseFloat(
                        (
                          (counts[2] * 100) /
                          (counts[1] + counts[0] + counts[2])
                        ).toFixed(2)
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            ) : flow === 1 ? (
              <>
                {/* --------------------IM Flow---------------- */}
                {allData.disciplineData[selectedCardIndex].questionStatus ===
                0 ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 2,
                      height: "auto",
                      minHeight: "420px",
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323232",
                        mb: 2,
                      }}
                    >
                      {allData.disciplineData[selectedCardIndex].question}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: "16px",
                        flexGrow: 1,
                        textAlign: "center",
                        backgroundImage: `url(${disciplineCover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        borderRadius: "12px",
                        border: "0.5px solid rgba(9, 162, 227, 0.20)",
                        backdropFilter: "blur(7px)",
                        minHeight: "400px",
                      }}
                    >
                      <Box sx={{ width: "70%" }}>
                        <SentimentSatisfiedAltIcon
                          sx={{ color: "#09A2E3", fontSize: "60px" }}
                        />
                        <Typography
                          sx={{
                            color: "#323232",
                            fontSize: "24px",
                            fontWeight: "600",
                          }}
                        >
                          Satisfactory
                        </Typography>
                        {allData?.disciplineData[selectedCardIndex]?.satisfactoryRemark?.length > 0 && (<Box
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          bgcolor="#F5FBFE"
                          p={1}
                          borderRadius="4px"
                          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                          mt={1.5}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#222222" }}
                            >
                              Remark
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ color: "#525966" }}
                          >
                            {allData?.disciplineData[selectedCardIndex]?.satisfactoryRemark}
                          </Typography>
                        </Box>)}
                      </Box>
                    </Box>
                  </Box>
                ) : allData.disciplineData[selectedCardIndex].questionStatus ===
                  1 ? (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: 2,
                        height: "auto",
                        minHeight: "420px",
                        p: 2,
                      }}
                    >
                      {(allData?.disciplineData?.[selectedCardIndex]?.imEvidence
                        ?.length > 0 || allData?.disciplineData[selectedCardIndex]?.completionDate !== null) ||
                      (allData?.disciplineData?.[selectedCardIndex]
                        ?.workStatus === 1 &&
                        allData?.disciplineData?.[selectedCardIndex]
                          ?.workInProgressComment !== null) ||
                      allData?.disciplineData?.[selectedCardIndex]?.returnRemark
                        ?.length > 0 ||
                      allData?.disciplineData?.[selectedCardIndex]
                        ?.returnComment?.length > 0 ? (
                        <CustomTabs
                          value={activeTab}
                          onChange={handleTabChange}
                        >
                          <CustomTab label="Executive Input" value={0} />
                          {((allData?.disciplineData?.[selectedCardIndex]
                            ?.imEvidence?.length > 0 || allData?.disciplineData[selectedCardIndex]?.completionDate !== null ||
                            allData?.disciplineData?.[selectedCardIndex]
                              ?.actionTaken !== null) ||
                            (allData?.disciplineData?.[selectedCardIndex]
                              ?.workStatus === 1 &&
                              allData?.disciplineData?.[selectedCardIndex]
                                ?.workInProgressComment !== null)) && (
                            <CustomTab label="IM Input" value={1} />
                          )}
                          {(allData?.disciplineData?.[selectedCardIndex]
                            ?.returnRemark?.length > 0 ||
                            allData?.disciplineData?.[selectedCardIndex]
                              ?.returnComment?.length > 0) && (
                            <CustomTab label="Comments" value={2} />
                          )}
                        </CustomTabs>
                      ) : null}

                      {activeTab === 0 && (
                        <Box>
                          <Grid container>
                            <Grid item xs={12}>
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  gap={0}
                                >
                                  {returnMode === 1 && (
                                    <Checkbox
                                      icon={<RadioButtonUncheckedIcon />}
                                      checkedIcon={<CheckCircleOutlineIcon />}
                                      size="small"
                                      style={{ padding: 0 }}
                                      checked={checkBoxMap?.some(
                                        (item) =>
                                          item.id ===
                                          allData.disciplineData[
                                            selectedCardIndex
                                          ].id
                                      )}
                                      onChange={handleCheckboxChange}
                                    />
                                  )}
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#323232",
                                      ml: returnMode === 1 ? 1 : 0,
                                    }}
                                  >
                                    Recommendation {selectedCardIndex + 1}
                                  </Typography>
                                </Box>
                                {(!allData?.currentStatusName?.includes(
                                  "New_Survey"
                                ) ||
                                  !allData?.currentStatusName?.includes(
                                    "Resubmission"
                                  ) ||
                                  !allData?.currentStatusName?.includes(
                                    "Returned_By_MM"
                                  )) &&
                                  (allData?.disciplineData[selectedCardIndex]
                                    ?.workStatus === 0 ? (
                                    <Chip
                                      label="Yet To Start"
                                      sx={{
                                        backgroundColor: "#F2E6FA",
                                        color: "#7400CC",
                                        borderRadius: "24px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        padding: "4px 12px",
                                      }}
                                    />
                                  ) : allData?.disciplineData[selectedCardIndex]
                                      ?.workStatus === 1 ? (
                                    <Chip
                                      label="In Progress"
                                      sx={{
                                        backgroundColor: "#E7F6FD",
                                        color: "#009FF5",
                                        borderRadius: "24px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        padding: "4px 12px",
                                      }}
                                    />
                                  ) : allData?.disciplineData[selectedCardIndex]
                                      ?.workStatus === 2 ? (
                                    <Chip
                                      label="Under Review"
                                      sx={{
                                        backgroundColor:
                                          "rgba(234, 179, 8, 0.10)",
                                        color: "#EAB308",
                                        borderRadius: "24px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        padding: "4px 12px",
                                      }}
                                    />
                                  ) : null)}
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              flexDirection="column"
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  color: "#525966",
                                  textAlign: "left",
                                  ml: returnMode === 1 ? 3.5 : 0,
                                }}
                              >
                                {
                                  allData.disciplineData[selectedCardIndex]
                                    .question
                                }
                              </Typography>
                            </Grid>
                          </Grid>

                          <Divider sx={{ my: "12px" }} />

                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            gap={1}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Typography
                                gutterBottom
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#222",
                                }}
                              >
                                Observation
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#525966",
                                  textAlign: "left",
                                }}
                              >
                                {
                                  allData.disciplineData[selectedCardIndex]
                                    .observation
                                }
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Typography
                                gutterBottom
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#222",
                                }}
                              >
                                Recommendation
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#525966",
                                  textAlign: "left",
                                }}
                              >
                                {
                                  allData.disciplineData[selectedCardIndex]
                                    .recommendation
                                }
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#222",
                                }}
                              >
                                Target Completion Date
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  color: "#525966",
                                  textAlign: "left",
                                }}
                              >
                                {formatDateToIndianLocale(
                                  allData.disciplineData[selectedCardIndex]
                                    .targetCompletionDate
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#222",
                                }}
                              >
                                Evidence
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  mt: 2,
                                  gap: 1,
                                }}
                              >
                                {allData.disciplineData[
                                  selectedCardIndex
                                ].evidence?.map((url, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      width: 80,
                                      height: 80,
                                      borderRadius: 2,
                                      overflow: "hidden",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      border: "1px solid #ccc",
                                      cursor: "pointer",
                                      transition: "box-shadow 0.3s ease", // Smooth transition effect
                                      "&:hover": {
                                        boxShadow:
                                          "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                      },
                                    }}
                                    onClick={() =>
                                      handleImageClick(
                                        typeof url === "string"
                                          ? url
                                          : url.base64
                                      )
                                    }
                                  >
                                    <img
                                      src={url}
                                      alt={`Evidence ${index + 1}`}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>

                          {remarkEditMode === 0 &&
                          checkBoxMap?.some(
                            (item) =>
                              item.id ===
                              allData.disciplineData[selectedCardIndex].id
                          ) &&
                          checkBoxMap.find(
                            (item) =>
                              item.id ===
                              allData.disciplineData[selectedCardIndex].id
                          )?.returnRemark.length > 0 ? (
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={0.5}
                              bgcolor="#F5FBFE"
                              p={1}
                              borderRadius="4px"
                              boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                              mt={1.5}
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={0}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#222222" }}
                                >
                                  Return Remark
                                </Typography>
                                <IconButton
                                  aria-label="edit"
                                  sx={{
                                    color: "#009FF5",
                                    width: 32,
                                    height: 32,
                                    backgroundColor: "#F5FBFE",
                                    borderRadius: "8px",
                                    border: "none",
                                    "&:hover": {
                                      backgroundColor: blue[50],
                                    },
                                  }}
                                  onClick={() => {
                                    setRemarkEditMode(1);
                                    setRemark(
                                      checkBoxMap.find(
                                        (item) =>
                                          item.id ===
                                          allData.disciplineData[
                                            selectedCardIndex
                                          ].id
                                      )?.returnRemark || ""
                                    );
                                  }}
                                >
                                  <EditOutlinedIcon sx={{ fontSize: 20 }} />
                                </IconButton>
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{ color: "#525966" }}
                              >
                                {
                                  checkBoxMap.find(
                                    (item) =>
                                      item.id ===
                                      allData.disciplineData[selectedCardIndex]
                                        .id
                                  )?.returnRemark
                                }
                              </Typography>
                            </Box>
                          ) : (returnMode === 1 &&
                              checkBoxMap.find(
                                (item) =>
                                  item.id ===
                                  allData.disciplineData[selectedCardIndex].id
                              )?.returnRemark?.length === 0) ||
                            remarkEditMode === 1 ? (
                            <Box
                              display="flex"
                              alignItems="center"
                              bgcolor="#F5FBFE"
                              p={0}
                              borderRadius="4px"
                              boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                              position="relative"
                              mt={1.5}
                            >
                              <TextField
                                variant="outlined"
                                placeholder="Return remark"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                fullWidth
                                multiline
                                rows={3}
                                InputProps={{
                                  style: { padding: 0 },
                                }}
                                sx={{
                                  border: "none",
                                  "& fieldset": { border: "none" },
                                  "& .MuiOutlinedInput-root": {
                                    bgcolor: "#F5FBFE",
                                    pr: 8, // Adjust padding to make room for the button
                                  },
                                }}
                              />
                              <Button
                                variant="contained"
                                onClick={handleRemarkSave}
                                sx={{
                                  position: "absolute",
                                  bottom: 8,
                                  right: 8,
                                  color: "#ffffff",
                                  padding: "4px 16px",
                                }}
                              >
                                Save
                              </Button>
                            </Box>
                          ) : null}

                          {
                            allData?.disciplineData[selectedCardIndex]
                              ?.workStatus < 2 && allData?.disciplineData[selectedCardIndex]
                              ?.workStatus !== null && (
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                mt={1}
                              >
                                {mode === "action" && (
                                  <Button
                                    onClick={() => {
                                      handleDrawerOpen();
                                    }}
                                    sx={{
                                      borderRadius: "4px",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                      color: "white",
                                      backgroundColor: "#09A2E3",
                                      padding: "4px 12px",
                                    }}
                                  >
                                    Complete
                                  </Button>
                                )}
                              </Box>
                            )}
                          {allData?.submittedByEX && (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              mt={1}
                            >
                              <AccessTimeOutlinedIcon
                                sx={{ fontSize: "16px", color: "#838EA3" }}
                              />
                              <Typography
                                variant="caption"
                                sx={{ color: "#838EA3" }}
                              >
                                Submitted by Executive,{" "}
                                {formatUpdateTime(allData?.submittedByEX)}
                              </Typography>
                            </Box>
                          )}

                          
                        </Box>
                      )}

                      {activeTab === 1 &&
                        (allData?.disciplineData?.[selectedCardIndex]
                          ?.workStatus === 1 ? (
                          <>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#323232",
                                      ml: returnMode === 1 ? 1 : 0,
                                    }}
                                  >
                                    Recommendation {selectedCardIndex + 1}
                                  </Typography>
                                  <Chip
                                    label="In Progress"
                                    sx={{
                                      backgroundColor: "#E7F6FD",
                                      color: "#009FF5",
                                      borderRadius: "24px",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      padding: "4px 12px",
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                flexDirection="column"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                    ml: returnMode === 1 ? 3.5 : 0,
                                  }}
                                >
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .question
                                  }
                                </Typography>
                              </Grid>
                            </Grid>

                            <Divider sx={{ my: "12px" }} />
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={0.5}
                            >
                              <Typography
                                variant="body1"
                                sx={{ color: "#222" }}
                              >
                                Work in progress
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "#525966" }}
                              >
                                {
                                  allData?.disciplineData?.[selectedCardIndex]
                                    ?.workInProgressComment
                                }
                              </Typography>
                            </Box>
                            {allData?.submittedByMM && allData.disciplineData[selectedCardIndex]?.returnComment?.length > 0 && (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              mt={1}
                            >
                              <AccessTimeOutlinedIcon
                                sx={{ fontSize: "16px", color: "#838EA3" }}
                              />
                              <Typography
                                variant="caption"
                                sx={{ color: "#838EA3" }}
                              >
                                Returned by MM,{" "}
                                {formatUpdateTime(allData?.submittedByMM)}
                              </Typography>
                            </Box>
                          )}
                          </>
                        ) : allData?.currentStatusName?.includes(
                            "Returned_By_MM"
                          ) ? (
                          <>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap={0}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#323232",
                                      }}
                                    >
                                      Recommendation {selectedCardIndex + 1}
                                    </Typography>
                                  </Box>
                                  {/* allData.disciplineData?.[selectedCardIndex]?.returnRemark?.length > 0 */}
                                  {mode === "action" &&
                                  allData.disciplineData?.[selectedCardIndex]
                                    ?.workStatus === 3 ? (
                                    <Box
                                      sx={{
                                        m: 1,
                                        display: "flex",
                                        gap: 1,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {/* <Button variant="outlined" sx={{color:'#009FF5',height: '32px',padding: '8px 12px'}} onClick={() => handleReturnExecutiveComment(allData?.disciplineData?.[selectedCardIndex]?.question,allData?.disciplineData?.[selectedCardIndex]?.returnRemark,allData?.disciplineData?.[selectedCardIndex]?.id)}>
                                        Comment
                                      </Button> */}
                                        <CenteredIconBox
                                          backgroundColor={
                                            "rgba(9, 162, 227, 0.10)"
                                          }
                                          onClick={() => handleReturnIMEdit()}
                                        >
                                      <Tooltip title="Edit Recommendation">
                                          <EditOutlinedIcon
                                            fontSize="small"
                                            sx={{ color: "#009FF5" }}
                                          />
                                      </Tooltip>
                                        </CenteredIconBox>
                                        <CenteredIconBox
                                          backgroundColor={
                                            "rgba(9, 162, 227, 0.10)"
                                          }
                                          onClick={() => handleReturnIMComment()}
                                        >
                                      <Tooltip title="Add Comment">
                                          <ChatBubbleOutlineIcon
                                            fontSize="small"
                                            sx={{ color: "#009FF5" }}
                                          />
                                      </Tooltip>
                                        </CenteredIconBox>
                                      {/* <Button variant="contained" sx={{color:'white',backgroundColor:'#009FF5',height: '32px',padding: '8px 12px'}} onClick={() => handleReturnExecutiveEdit()}>
                                        Edit
                                      </Button> */}
                                    </Box>
                                  ) : allData.disciplineData?.[
                                      selectedCardIndex
                                    ]?.workStatus === 3 ? (
                                    <Chip
                                      label="Returned"
                                      sx={{
                                        backgroundColor:
                                          "rgba(234, 179, 8, 0.10)",
                                        color: "#EAB308",
                                        borderRadius: "24px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        padding: "4px 12px",
                                      }}
                                    />
                                  ) : allData.disciplineData?.[
                                      selectedCardIndex
                                    ]?.workStatus === 4 ? (
                                    <Chip
                                      label="Completed"
                                      sx={{
                                        backgroundColor:
                                          "rgba(11, 127, 106, 0.1)",
                                        color: "#0B7F6A",
                                        borderRadius: "24px",
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        padding: "4px 12px",
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                flexDirection="column"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                  }}
                                >
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .question
                                  }
                                </Typography>
                              </Grid>
                            </Grid>

                            <Divider sx={{ my: "12px" }} />

                            <Box display="flex" flexDirection="column" gap={1}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Completion Date
                                </Typography>
                                <Typography variant="caption">
                                  {formatTableDate(
                                    allData?.disciplineData[selectedCardIndex]
                                      .completionDate
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Action Taken
                                </Typography>
                                <Typography gutterBottom variant="caption">
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .actionTaken
                                  }
                                </Typography>
                              </Box>
                              <Box display="flex" flexDirection="column">
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Evidence
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    mt: 2,
                                  }}
                                >
                                  {allData?.disciplineData[selectedCardIndex]
                                    .imEvidence?.length === 0 ? (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "#525966",
                                      }}
                                    >
                                      NA
                                    </Typography>
                                  ) : (
                                    allData?.disciplineData[
                                      selectedCardIndex
                                    ]?.imEvidence?.map((url, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          width: 80,
                                          height: 80,
                                          borderRadius: 2,
                                          overflow: "hidden",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid #ccc",
                                          mr: 1,
                                          mb: 1,
                                          cursor: "pointer",
                                          transition: "box-shadow 0.3s ease", // Smooth transition effect
                                          "&:hover": {
                                            boxShadow:
                                              "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                          },
                                        }}
                                        onClick={() =>
                                          handleImageClick(
                                            typeof url === "string"
                                              ? url
                                              : url.base64
                                          )
                                        }
                                      >
                                        <img
                                          src={url}
                                          alt={`Evidence ${index + 1}`}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Box>
                                    ))
                                  )}
                                </Box>
                              </Box>
                              {allData?.disciplineData[selectedCardIndex]
                                ?.returnComment?.length === 1 && (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={0.5}
                                  bgcolor="#F5FBFE"
                                  p={1}
                                  borderRadius="4px"
                                  boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                                  mt={1.5}
                                >
                                  {/* Heading for the comment section */}
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#98999D" }}
                                  >
                                    Mines Manager Remark
                                  </Typography>

                                  {/* Display the comment */}
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#525966" }}
                                  >
                                    {
                                      allData.disciplineData[selectedCardIndex]
                                        .returnComment[0].comment
                                    }
                                  </Typography>
                                </Box>
                              )}
                              {allData?.submittedByMM && allData.disciplineData[selectedCardIndex]?.returnComment?.length > 0 && (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="flex-start"
                                  mt={1}
                                >
                                  <AccessTimeOutlinedIcon
                                    sx={{ fontSize: "16px", color: "#838EA3" }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#838EA3" }}
                                  >
                                    Returned by MM,{" "}
                                    {formatUpdateTime(allData?.submittedByMM)}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </>
                        ) : allData?.disciplineData?.[selectedCardIndex]
                            ?.workStatus === 2 ? (
                          <>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#323232",
                                      ml: returnMode === 1 ? 1 : 0,
                                    }}
                                  >
                                    Recommendation {selectedCardIndex + 1}
                                  </Typography>
                                  <Chip
                                    label="Under Review"
                                    sx={{
                                      backgroundColor:
                                        "rgba(234, 179, 8, 0.10)",
                                      color: "#EAB308",
                                      borderRadius: "24px",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      padding: "4px 12px",
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                flexDirection="column"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                    ml: returnMode === 1 ? 3.5 : 0,
                                  }}
                                >
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .question
                                  }
                                </Typography>
                              </Grid>
                            </Grid>

                            <Divider sx={{ my: "12px" }} />

                            <Box display="flex" flexDirection="column" gap={1}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Completion Date
                                </Typography>
                                <Typography variant="caption">
                                  {formatTableDate(
                                    allData?.disciplineData[selectedCardIndex]
                                      .completionDate
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Action Taken
                                </Typography>
                                <Typography gutterBottom variant="caption">
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .actionTaken
                                  }
                                </Typography>
                              </Box>
                              <Box display="flex" flexDirection="column">
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Evidence
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    mt: 2,
                                  }}
                                >
                                  {allData?.disciplineData[selectedCardIndex]
                                    .imEvidence?.length === 0 ? (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "#525966",
                                      }}
                                    >
                                      NA
                                    </Typography>
                                  ) : (
                                    allData?.disciplineData[
                                      selectedCardIndex
                                    ]?.imEvidence?.map((url, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          width: 80,
                                          height: 80,
                                          borderRadius: 2,
                                          overflow: "hidden",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid #ccc",
                                          mr: 1,
                                          mb: 1,
                                          cursor: "pointer",
                                          transition: "box-shadow 0.3s ease", // Smooth transition effect
                                          "&:hover": {
                                            boxShadow:
                                              "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                          },
                                        }}
                                        onClick={() =>
                                          handleImageClick(
                                            typeof url === "string"
                                              ? url
                                              : url.base64
                                          )
                                        }
                                      >
                                        <img
                                          src={url}
                                          alt={`Evidence ${index + 1}`}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Box>
                                    ))
                                  )}
                                </Box>
                              </Box>
                              {allData?.submittedByMM && allData.disciplineData[selectedCardIndex]?.returnComment?.length > 0 && (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="flex-start"
                                  mt={1}
                                >
                                  <AccessTimeOutlinedIcon
                                    sx={{ fontSize: "16px", color: "#838EA3" }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#838EA3" }}
                                  >
                                    Returned by MM,{" "}
                                    {formatUpdateTime(allData?.submittedByMM)}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </>
                        ) : allData?.disciplineData?.[selectedCardIndex]
                            ?.workStatus === 4 ? (
                          <>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#323232",
                                      ml: returnMode === 1 ? 1 : 0,
                                    }}
                                  >
                                    Recommendation {selectedCardIndex + 1}
                                  </Typography>
                                  <Chip
                                    label="Completed"
                                    sx={{
                                      backgroundColor:
                                        "rgba(15, 174, 145, 0.15)",
                                      color: "#0B7F6A",
                                      borderRadius: "24px",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      padding: "4px 12px",
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                flexDirection="column"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                    ml: returnMode === 1 ? 3.5 : 0,
                                  }}
                                >
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .question
                                  }
                                </Typography>
                              </Grid>
                            </Grid>

                            <Divider sx={{ my: "12px" }} />

                            <Box display="flex" flexDirection="column" gap={1}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Completion Date
                                </Typography>
                                <Typography variant="caption">
                                  {formatTableDate(
                                    allData?.disciplineData[selectedCardIndex]
                                      .completionDate
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Action Taken
                                </Typography>
                                <Typography gutterBottom variant="caption">
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .actionTaken
                                  }
                                </Typography>
                              </Box>
                              <Box display="flex" flexDirection="column">
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Evidence
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    mt: 2,
                                  }}
                                >
                                  {allData?.disciplineData[selectedCardIndex]
                                    .imEvidence?.length === 0 ? (
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        color: "#525966",
                                      }}
                                    >
                                      NA
                                    </Typography>
                                  ) : (
                                    allData?.disciplineData[
                                      selectedCardIndex
                                    ]?.imEvidence?.map((url, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          width: 80,
                                          height: 80,
                                          borderRadius: 2,
                                          overflow: "hidden",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          border: "1px solid #ccc",
                                          mr: 1,
                                          mb: 1,
                                          cursor: "pointer",
                                          transition: "box-shadow 0.3s ease", // Smooth transition effect
                                          "&:hover": {
                                            boxShadow:
                                              "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                          },
                                        }}
                                        onClick={() =>
                                          handleImageClick(
                                            typeof url === "string"
                                              ? url
                                              : url.base64
                                          )
                                        }
                                      >
                                        <img
                                          src={url}
                                          alt={`Evidence ${index + 1}`}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Box>
                                    ))
                                  )}
                                </Box>
                              </Box>
                              {allData?.submittedByMM && allData.disciplineData[selectedCardIndex]?.returnComment?.length > 0 && (
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="flex-start"
                                  mt={1}
                                >
                                  <AccessTimeOutlinedIcon
                                    sx={{ fontSize: "16px", color: "#838EA3" }}
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#838EA3" }}
                                  >
                                    Returned by MM,{" "}
                                    {formatUpdateTime(allData?.submittedByMM)}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </>
                        ) : null)}

                      {activeTab === 2 && (
                        <CommentSection
                          returnComments={[
                            ...(allData?.disciplineData?.[selectedCardIndex]
                              ?.returnRemark || []),
                            ...(allData?.disciplineData?.[selectedCardIndex]
                              ?.returnComment || []),
                          ]}
                        />
                      )}
                    </Box>
                  </>
                ) : allData.disciplineData[selectedCardIndex].questionStatus ===
                  2 ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 2,
                      height: "auto",
                      minHeight: "420px",
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323232",
                        mb: 2,
                      }}
                    >
                      {allData.disciplineData[selectedCardIndex].question}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: "16px",
                        flexGrow: 1,
                        textAlign: "center",
                        backgroundImage: `url(${disciplineCover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        borderRadius: "12px",
                        border: "0.5px solid rgba(9, 162, 227, 0.20)",
                        backdropFilter: "blur(7px)",
                        minHeight: "400px",
                      }}
                    >
                      <Box sx={{ width: "70%" }}>
                        <SentimentNeutralIcon
                          sx={{ color: "#09A2E3", fontSize: "60px" }}
                        />
                        <Typography
                          sx={{
                            color: "#323232",
                            fontSize: "24px",
                            fontWeight: "600",
                          }}
                        >
                          Not Applicable
                        </Typography>
                        {allData?.disciplineData[selectedCardIndex]?.notApplicableRemark?.length > 0 && (<Box
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          bgcolor="#F5FBFE"
                          p={1}
                          borderRadius="4px"
                          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                          mt={1.5}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#222222" }}
                            >
                              Remark
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ color: "#525966" }}
                          >
                            {allData?.disciplineData[selectedCardIndex]?.notApplicableRemark}
                          </Typography>
                        </Box>)}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            ) : flow === 2 ? (
              <>
                {/* --------------------Executive Flow---------------- */}
                {allData.disciplineData[selectedCardIndex].questionStatus ===
                  0 &&
                (!executiveEditData || 0) ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 2,
                      height: "auto",
                      minHeight: "420px",
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323232",
                        mb: 2,
                      }}
                    >
                      {allData.disciplineData[selectedCardIndex].question}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: "16px",
                        flexGrow: 1,
                        textAlign: "center",
                        backgroundImage: `url(${disciplineCover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        borderRadius: "12px",
                        border: "0.5px solid rgba(9, 162, 227, 0.20)",
                        backdropFilter: "blur(7px)",
                        minHeight: "400px",
                      }}
                    >
                      <Box sx={{ width: "70%" }}>
                        <SentimentSatisfiedAltIcon
                          sx={{ color: "#09A2E3", fontSize: "60px" }}
                        />
                        <Typography
                          sx={{
                            color: "#323232",
                            fontSize: "24px",
                            fontWeight: "600",
                          }}
                        >
                          Satisfactory
                        </Typography>
                        {allData?.disciplineData[selectedCardIndex]?.satisfactoryRemark?.length > 0 && (<Box
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          bgcolor="#F5FBFE"
                          p={1}
                          borderRadius="4px"
                          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                          mt={1.5}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#222222" }}
                            >
                              Remark
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ color: "#525966" }}
                          >
                            {allData?.disciplineData[selectedCardIndex]?.satisfactoryRemark}
                          </Typography>
                        </Box>)}
                      </Box>
                    </Box>
                  </Box>
                ) : allData.disciplineData[selectedCardIndex].questionStatus ===
                    1 ||
                  executiveEditData ||
                  0 ? (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: 2,
                        height: "auto",
                        minHeight: "420px",
                        p: 2,
                      }}
                    >
                      <Box mb={1}>
                        {allData?.disciplineData?.[selectedCardIndex]
                          ?.returnRemark?.length > 0 ||
                        allData?.disciplineData?.[selectedCardIndex]
                          ?.returnComment?.length > 0 ? (
                          <CustomTabs
                            value={activeTab}
                            onChange={handleTabChange}
                          >
                            <CustomTab label="Executive Info" value={0} />
                            <CustomTab label="Comments" value={2} />
                          </CustomTabs>
                        ) : null}
                      </Box>
                      {activeTab === 0 ? (
                        <>
                          <Box>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap={0}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#323232",
                                      }}
                                    >
                                      Recommendation {selectedCardIndex + 1}
                                    </Typography>
                                  </Box>
                                  {allData.disciplineData?.[selectedCardIndex]
                                    ?.returnRemark?.length > 0 &&
                                    flow === 2 &&
                                    mode === "action" && (
                                      <Box
                                        sx={{
                                          m: 1,
                                          display: "flex",
                                          gap: 1,
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {/* <Button variant="outlined" sx={{color:'#009FF5',height: '32px',padding: '8px 12px'}} onClick={() => handleReturnExecutiveComment(allData?.disciplineData?.[selectedCardIndex]?.question,allData?.disciplineData?.[selectedCardIndex]?.returnRemark,allData?.disciplineData?.[selectedCardIndex]?.id)}>
                                        Comment
                                      </Button> */}
                                        <CenteredIconBox
                                          backgroundColor={
                                            "rgba(9, 162, 227, 0.10)"
                                          }
                                          onClick={() =>
                                            handleReturnExecutiveEdit()
                                          }
                                        >
                                      <Tooltip title="Edit Recommendation">
                                          <EditOutlinedIcon
                                            fontSize="small"
                                            sx={{ color: "#009FF5" }}
                                          />
                                      </Tooltip>
                                        </CenteredIconBox>
                                      
                                        <CenteredIconBox
                                          backgroundColor={
                                            "rgba(9, 162, 227, 0.10)"
                                          }
                                          onClick={() =>
                                            handleReturnExecutiveComment(
                                              allData?.disciplineData?.[
                                                selectedCardIndex
                                              ]?.question,
                                              allData?.disciplineData?.[
                                                selectedCardIndex
                                              ]?.returnRemark,
                                              allData?.disciplineData?.[
                                                selectedCardIndex
                                              ]?.id
                                            )
                                          }
                                        >
                                        <Tooltip title="Add Comment">
                                          <ChatBubbleOutlineIcon
                                            fontSize="small"
                                            sx={{ color: "#009FF5" }}
                                          />
                                      </Tooltip>
                                        </CenteredIconBox>
                                        {/* <Button variant="contained" sx={{color:'white',backgroundColor:'#009FF5',height: '32px',padding: '8px 12px'}} onClick={() => handleReturnExecutiveEdit()}>
                                        Edit
                                      </Button> */}
                                      </Box>
                                    )}
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                display="flex"
                                flexDirection="column"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                  }}
                                >
                                  {
                                    allData.disciplineData[selectedCardIndex]
                                      .question
                                  }
                                </Typography>
                              </Grid>
                            </Grid>

                            <Divider sx={{ my: "12px" }} />

                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                              gap={1}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Observation
                                </Typography>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveEditData?.observation ||
                                    allData.disciplineData[selectedCardIndex]
                                      .observation}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Recommendation
                                </Typography>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                  }}
                                >
                                  {executiveEditData?.recommendation ||
                                    allData.disciplineData[selectedCardIndex]
                                      .recommendation}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Target Completion Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "#525966",
                                    textAlign: "left",
                                  }}
                                >
                                  {formatDateToIndianLocale(
                                    executiveEditData?.targetCompletionDate ||
                                      allData.disciplineData[selectedCardIndex]
                                        .targetCompletionDate
                                  )}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#222",
                                  }}
                                >
                                  Evidence
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    mt: 2,
                                    gap: 1,
                                  }}
                                >
                                  {[
                                    ...(executiveEditData?.evidence || allData.disciplineData[selectedCardIndex]?.evidence || [])
                                  ]?.map((item, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: 2,
                                        overflow: "hidden",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: "1px solid #ccc",
                                        cursor: "pointer",
                                        transition: "box-shadow 0.3s ease", // Smooth transition effect
                                        "&:hover": {
                                          boxShadow:
                                            "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                        },
                                      }}
                                      onClick={() =>
                                        handleImageClick(
                                          typeof item === "string"
                                            ? item
                                            : item.base64
                                        )
                                      } // Uncomment if you want to handle clicks
                                    >
                                      <img
                                        src={
                                          typeof item === "string"
                                            ? item
                                            : item.base64
                                        }
                                        alt={`Evidence ${index + 1}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {/* Remark */}
                          {!(executiveComments.length > 0) &&
                            allData?.disciplineData[selectedCardIndex]
                              .returnRemark?.length === 1 &&
                            allData?.returnOverallRemark &&
                            flow === 2 && (
                              <Box
                                sx={{
                                  backgroundColor: "#F5FBFE",
                                  p: 1,
                                  borderRadius: 1,
                                  mt: "12px",
                                }}
                              >
                                <Typography>Remark</Typography>
                                <Box sx={{ color: "#525966" }}>
                                  {allData?.disciplineData[selectedCardIndex]
                                    .returnRemark?.length > 0
                                    ? allData?.disciplineData[
                                        selectedCardIndex
                                      ].returnRemark?.map((remark, index) => (
                                        <Typography
                                          key={index}
                                          sx={{ color: "#525966" }}
                                        >
                                          {remark.comment}
                                        </Typography>
                                      ))
                                    : "No remark"}
                                </Box>
                              </Box>
                            )}

                          {allData?.submittedByEX && (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              mt={1}
                            >
                              <AccessTimeOutlinedIcon
                                sx={{ fontSize: "16px", color: "#838EA3" }}
                              />
                              <Typography
                                variant="caption"
                                sx={{ color: "#838EA3" }}
                              >
                                Submitted by Executive,{" "}
                                {formatUpdateTime(allData?.submittedByEX)}
                              </Typography>
                            </Box>
                          )}
                          {allData?.submittedByIM && allData.disciplineData[selectedCardIndex]?.returnRemark?.length > 0 && (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              mt={1}
                            >
                              <AccessTimeOutlinedIcon
                                sx={{ fontSize: "16px", color: "#838EA3" }}
                              />
                              <Typography
                                variant="caption"
                                sx={{ color: "#838EA3" }}
                              >
                                Returned by IM,{" "}
                                {formatUpdateTime(allData?.submittedByIM)}
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : (
                        <>
                          <CommentSection
                            returnComments={[
                              ...(allData?.disciplineData?.[selectedCardIndex]
                                ?.returnRemark || []),
                              ...(allData?.disciplineData?.[selectedCardIndex]
                                ?.returnComment || []),
                            ]}
                          />
                          {/* Second Accordion */}

                          {mode === "action" &&
                            executiveComments?.some(
                              (item) =>
                                item.id ===
                                allData?.disciplineData[selectedCardIndex].id
                            ) && (
                              <Accordion
                                disableGutters
                                sx={{
                                  boxShadow: "none",
                                  backgroundImage: "none",
                                  padding: "0 !important",
                                  position: "static",
                                  mt: 2,
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography>Current Comment</Typography>
                                </AccordionSummary>
                                <Divider sx={{ my: "12px" }} />
                                <AccordionDetails>
                                  <Box
                                    sx={{
                                      backgroundColor: "#F5FBFE",
                                      p: 1,
                                      borderRadius: 1,
                                      mt: 0,
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                      gap={0}
                                    >
                                      <Typography>Remark</Typography>
                                      <IconButton
                                        aria-label="edit"
                                        sx={{
                                          color: "#009FF5",
                                          width: 32,
                                          height: 32,
                                          backgroundColor: "#F5FBFE",
                                          borderRadius: "8px",
                                          border: "none",
                                          "&:hover": {
                                            backgroundColor: blue[50],
                                          },
                                        }}
                                        onClick={() =>
                                          handleReturnExecutiveComment(
                                            allData?.disciplineData?.[
                                              selectedCardIndex
                                            ]?.question,
                                            allData?.disciplineData?.[
                                              selectedCardIndex
                                            ]?.returnRemark,
                                            allData?.disciplineData?.[
                                              selectedCardIndex
                                            ]?.id
                                          )
                                        }
                                      >
                                        <EditOutlinedIcon
                                          sx={{ fontSize: 20 }}
                                        />
                                      </IconButton>
                                    </Box>
                                    <Box sx={{ color: "#525966" }}>
                                      {executiveComments.length > 0 ? (
                                        <Typography sx={{ color: "#525966" }}>
                                          {
                                            executiveComments?.find(
                                              (item) =>
                                                item.id ===
                                                allData?.disciplineData[
                                                  selectedCardIndex
                                                ].id
                                            )?.comment
                                          }
                                        </Typography>
                                      ) : (
                                        "No comment"
                                      )}
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            )}
                        </>
                      )}
                    </Box>
                  </>
                ) : allData.disciplineData[selectedCardIndex].questionStatus ===
                  2 ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 2,
                      height: "auto",
                      minHeight: "420px",
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323232",
                        mb: 2,
                      }}
                    >
                      {allData.disciplineData[selectedCardIndex].question}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: "16px",
                        flexGrow: 1,
                        textAlign: "center",
                        backgroundImage: `url(${disciplineCover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        borderRadius: "12px",
                        border: "0.5px solid rgba(9, 162, 227, 0.20)",
                        backdropFilter: "blur(7px)",
                        minHeight: "400px",
                      }}
                    >
                      <Box sx={{ width: "70%" }}>
                        <SentimentNeutralIcon
                          sx={{ color: "#09A2E3", fontSize: "60px" }}
                        />
                        <Typography
                          sx={{
                            color: "#323232",
                            fontSize: "24px",
                            fontWeight: "600",
                          }}
                        >
                          Not Applicable
                        </Typography>
                        {allData?.disciplineData[selectedCardIndex]?.notApplicableRemark?.length > 0 && (<Box
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          bgcolor="#F5FBFE"
                          p={1}
                          borderRadius="4px"
                          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                          mt={1.5}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#222222" }}
                            >
                              Remark
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ color: "#525966" }}
                          >
                            {allData?.disciplineData[selectedCardIndex]?.notApplicableRemark}
                          </Typography>
                        </Box>)}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            ) : (
              // flow 3 mine manager start from here ----------------------//
              <>
                {allData.disciplineData[selectedCardIndex].questionStatus ===
                0 ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 2,
                      height: "auto",
                      minHeight: "420px",
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323232",
                        mb: 2,
                      }}
                    >
                      {allData.disciplineData[selectedCardIndex].question}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: "16px",
                        flexGrow: 1,
                        textAlign: "center",
                        backgroundImage: `url(${disciplineCover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        borderRadius: "12px",
                        border: "0.5px solid rgba(9, 162, 227, 0.20)",
                        backdropFilter: "blur(7px)",
                        minHeight: "400px",
                      }}
                    >
                      <Box sx={{ width: "70%" }}>
                        <SentimentSatisfiedAltIcon
                          sx={{ color: "#09A2E3", fontSize: "60px" }}
                        />
                        <Typography
                          sx={{
                            color: "#323232",
                            fontSize: "24px",
                            fontWeight: "600",
                          }}
                        >
                          Satisfactory
                        </Typography>
                        {allData?.disciplineData[selectedCardIndex]?.satisfactoryRemark?.length > 0 && (<Box
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          bgcolor="#F5FBFE"
                          p={1}
                          borderRadius="4px"
                          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                          mt={1.5}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#222222" }}
                            >
                              Remark
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ color: "#525966" }}
                          >
                            {allData?.disciplineData[selectedCardIndex]?.satisfactoryRemark}
                          </Typography>
                        </Box>)}
                      </Box>
                    </Box>
                  </Box>
                ) : allData.disciplineData[selectedCardIndex].questionStatus ===
                  1 ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 2,
                      height: "100%",
                      p: 2,
                    }}
                  >
                    <Box mb={1}>
                      {allData?.disciplineData[selectedCardIndex]?.imEvidence
                        ?.length > 0 || allData?.disciplineData[selectedCardIndex]?.completionDate !== null || 
                      allData?.disciplineData[selectedCardIndex]?.returnComment
                        ?.length > 0 ||
                      allData?.disciplineData[selectedCardIndex]?.returnRemark
                        ?.length > 0 ||
                      (allData?.disciplineData[selectedCardIndex]
                        ?.workStatus === 1 &&
                        allData?.disciplineData[selectedCardIndex]
                          ?.workInProgressComment !== null) ? (
                        <CustomTabs
                          value={activeTab}
                          onChange={handleTabChange}
                        >
                          <CustomTab label="Executive Input" value={0} />
                          {(allData?.disciplineData[selectedCardIndex]
                            ?.imEvidence?.length > 0 || allData?.disciplineData[selectedCardIndex]?.completionDate !== null ||
                            (allData?.disciplineData[selectedCardIndex]
                              ?.workStatus === 1 &&
                              allData?.disciplineData[selectedCardIndex]
                                ?.workInProgressComment !== null)) && (
                            <CustomTab label="IM Input" value={1} />
                          )}
                          {(allData?.disciplineData?.[selectedCardIndex]
                            ?.returnRemark?.length > 0 ||
                            allData?.disciplineData?.[selectedCardIndex]
                              ?.returnComment?.length > 0) && (
                            <CustomTab label="Comments" value={2} />
                          )}
                        </CustomTabs>
                      ) : null}
                      <Grid container>
                        {/* 
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                        {(allData?.disciplineData[selectedCardIndex]?.imEvidence?.length > 0 || 
                          allData?.disciplineData[selectedCardIndex]?.returnComment?.length > 0 || 
                          allData?.disciplineData[selectedCardIndex]?.returnRemark?.length > 0 ||
                          (allData?.disciplineData[selectedCardIndex]?.workStatus === 1 && 
                          allData?.disciplineData[selectedCardIndex]?.workInProgressComment !== null)
                         )&& 
                            <Chip
                              label={"Executive"}
                              sx={{
                                color: "#525966",
                                backgroundColor:
                                  activeTab == 0
                                    ? "rgba(9, 162, 227, 0.20)"
                                    : "#fff",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: activeTab == 2 ? "30%" : "48%",
                                maxHeight: "32px",
                                borderRadius: "8px",
                              }}
                              onClick={(e) => handleTabChange(e, 0)}
                            />}

                            {(allData?.disciplineData[selectedCardIndex]?.imEvidence?.length > 0 || (allData?.disciplineData[selectedCardIndex]?.workStatus === 1 && allData?.disciplineData[selectedCardIndex]?.workInProgressComment !== null) )&&
                              <Chip
                                label={"IM"}
                                sx={{
                                  color: "#525966",
                                  backgroundColor:
                                    activeTab == 1
                                      ? "rgba(9, 162, 227, 0.20)"
                                      : "#fff",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  width: activeTab == 2 ? "30%" : "48%",
                                  maxHeight: "32px",
                                  borderRadius: "8px",
                                }}
                                onClick={(e) => handleTabChange(e, 1)}
                              />
                            }

                            {(allData?.disciplineData[selectedCardIndex]?.returnComment?.length > 0 || allData?.disciplineData[selectedCardIndex]?.returnRemark?.length > 0) &&
                              <Chip
                                label={"Comments"}
                                sx={{
                                  color: "#525966",
                                  backgroundColor:
                                    activeTab == 2
                                      ? "rgba(9, 162, 227, 0.20)"
                                      : "#fff",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  width: "30%",
                                  maxHeight: "32px",
                                  borderRadius: "8px",
                                }}
                                onClick={(e) => handleTabChange(e, 2)}
                              />} 
                          </Grid>*/}

                        {activeTab != 2 && (
                          <>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between" // Align items to opposite ends
                            >
                              <Typography variant="body1" mt={0}>
                                Recommendation {selectedCardIndex + 1}
                              </Typography>
                              {activeTab == 1 &&
                                getLossStatusChip(
                                  allData?.disciplineData[selectedCardIndex]
                                    ?.workStatus
                                )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              flexDirection="column"
                            >
                              <Typography variant="caption">
                                {
                                  allData.disciplineData[selectedCardIndex]
                                    .question
                                }
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>

                    <Divider sx={{ my: "12px" }} />
                    {activeTab === 1 &&
                    (allData?.disciplineData[selectedCardIndex]?.imEvidence
                      ?.length > 0 ||  allData?.disciplineData[selectedCardIndex]?.completionDate !== null)  ? (
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Completion Date
                          </Typography>
                          <Typography variant="caption">
                            {formatTableDate(
                              allData?.disciplineData[selectedCardIndex]
                                .completionDate
                            )}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            gutterBottom
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Action Taken
                          </Typography>
                          <Typography gutterBottom variant="caption">
                            {
                              allData.disciplineData[selectedCardIndex]
                                .actionTaken
                            }
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Evidence
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}
                          >
                            {allData?.disciplineData[selectedCardIndex]
                              .imEvidence?.length === 0 ? (
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#525966",
                                }}
                              >
                                No Evidence
                              </Typography>
                            ) : (
                              allData?.disciplineData[
                                selectedCardIndex
                              ]?.imEvidence?.map((url, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: 2,
                                    overflow: "hidden",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #ccc",
                                    mr: 1,
                                    mb: 1,
                                    cursor: "pointer",
                                    transition: "box-shadow 0.3s ease", // Smooth transition effect
                                    "&:hover": {
                                      boxShadow:
                                        "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                    },
                                  }}
                                  onClick={() =>
                                    handleImageClick(
                                      typeof url === "string" ? url : url.base64
                                    )
                                  }
                                >
                                  <img
                                    src={url}
                                    alt={`Evidence ${index + 1}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Box>
                              ))
                            )}
                          </Box>

                          {/* {allData?.disciplineData[selectedCardIndex]?.returnComment?.length === 1 && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap={0.5}
                              bgcolor="#F5FBFE"
                              p={1}
                              borderRadius="4px"
                              boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                              mt={1.5}
                            >
                              <Typography variant="body2" sx={{ color: '#98999D' }}>
                                Mines Manager Remark
                              </Typography>

                              <Typography variant="caption" sx={{ color: '#525966' }}>
                                {allData.disciplineData[selectedCardIndex].returnComment[0].comment}
                              </Typography>
                            </Box>
                          )} */}

                          {activeTab == 1 &&
                            allData?.disciplineData[selectedCardIndex]
                              ?.workStatus == 2 &&
                            mode === "action" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  mt: 3,
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  sx={{
                                    color: "#0AA2E3",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    backgroundColor: "white",
                                    mr: 2,
                                  }}
                                  onClick={() => setMmReturnConfirmation(true)}
                                >
                                  Return
                                </Button>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    backgroundColor: "#0AA2E3",
                                  }}
                                  onClick={() => setMmAcceptConfirmation(true)}
                                >
                                  Accept
                                </Button>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    ) : activeTab === 1 &&
                      allData?.disciplineData[selectedCardIndex]?.workStatus ===
                        1 &&
                      allData?.disciplineData[selectedCardIndex]
                        ?.workInProgressComment !== null ? (
                      <Box display="flex" flexDirection="column" gap={0.5}>
                        <Typography variant="body1" sx={{ color: "#222" }}>
                          Work in progress
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#525966" }}>
                          {
                            allData?.disciplineData?.[selectedCardIndex]
                              ?.workInProgressComment
                          }
                        </Typography>
                        {allData.disciplineData[selectedCardIndex]
                          ?.updatedAt && allData.disciplineData[selectedCardIndex]?.returnComment?.length > 0 && (
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-start"
                          >
                            <AccessTimeOutlinedIcon
                              sx={{ fontSize: "16px", color: "#838EA3" }}
                            />
                            <Typography
                              variant="caption"
                              sx={{ color: "#838EA3" }}
                            >
                              Returned by MM,{" "}
                              {formatUpdateTime(
                                allData.disciplineData[selectedCardIndex]
                                  ?.updatedAt
                              )}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ) : activeTab == 0 ? (
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            gutterBottom
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Observation
                          </Typography>
                          <Typography gutterBottom variant="caption">
                            {
                              allData.disciplineData[selectedCardIndex]
                                .observation
                            }
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            gutterBottom
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Recommendation
                          </Typography>
                          <Typography gutterBottom variant="caption">
                            {
                              allData?.disciplineData[selectedCardIndex]
                                ?.recommendation
                            }
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Target Completion Date
                          </Typography>
                          <Typography variant="caption">
                            {formatDateToIndianLocale(
                              allData.disciplineData[selectedCardIndex]
                                ?.targetCompletionDate
                            )}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column">
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Evidence
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}
                          >
                            {allData?.disciplineData[selectedCardIndex].evidence
                              ?.length === 0 ? (
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#525966",
                                }}
                              >
                                NA
                              </Typography>
                            ) : (
                              allData?.disciplineData[
                                selectedCardIndex
                              ]?.evidence?.map((url, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: 2,
                                    overflow: "hidden",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #ccc",
                                    mr: 1,
                                    mb: 1,
                                    cursor: "pointer",
                                    transition: "box-shadow 0.3s ease", // Smooth transition effect
                                    "&:hover": {
                                      boxShadow:
                                        "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                    },
                                  }}
                                  onClick={() =>
                                    handleImageClick(
                                      typeof url === "string" ? url : url.base64
                                    )
                                  }
                                >
                                  <img
                                    src={url}
                                    alt={`Evidence ${index + 1}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Box>
                              ))
                            )}
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="flex-start"
                        >
                          <AccessTimeOutlinedIcon
                            sx={{ fontSize: "16px", color: "#838EA3" }}
                          />
                          <Typography
                            variant="caption"
                            sx={{ color: "#838EA3" }}
                          >
                            Submitted by IM, {""}{" "}
                            {formatUpdateTime(
                              allData.disciplineData[selectedCardIndex]
                                ?.updatedAt
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <CommentSection
                          returnComments={[
                            ...(allData?.disciplineData?.[selectedCardIndex]
                              ?.returnRemark || []),
                            ...(allData?.disciplineData?.[selectedCardIndex]
                              ?.returnComment || []),
                          ]}
                        />
                      </>
                    )}
                  </Box>
                ) : allData.disciplineData[selectedCardIndex].questionStatus ===
                  2 ? (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 2,
                      height: "auto",
                      minHeight: "420px",
                      p: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#323232",
                        mb: 2,
                      }}
                    >
                      {allData.disciplineData[selectedCardIndex].question}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        padding: "16px",
                        flexGrow: 1,
                        textAlign: "center",
                        backgroundImage: `url(${disciplineCover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        borderRadius: "12px",
                        border: "0.5px solid rgba(9, 162, 227, 0.20)",
                        backdropFilter: "blur(7px)",
                        minHeight: "400px",
                      }}
                    >
                      <Box sx={{ width: "70%" }}>
                        <SentimentNeutralIcon
                          sx={{ color: "#09A2E3", fontSize: "60px" }}
                        />
                        <Typography
                          sx={{
                            color: "#323232",
                            fontSize: "24px",
                            fontWeight: "600",
                          }}
                        >
                          Not Applicable
                        </Typography>
                        {allData?.disciplineData[selectedCardIndex]?.notApplicableRemark?.length > 0 && (<Box
                          display="flex"
                          flexDirection="column"
                          gap={0.5}
                          bgcolor="#F5FBFE"
                          p={1}
                          borderRadius="4px"
                          boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                          mt={1.5}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#222222" }}
                            >
                              Remark
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ color: "#525966" }}
                          >
                            {allData?.disciplineData[selectedCardIndex]?.notApplicableRemark}
                          </Typography>
                        </Box>)}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </>
            )}
          </Box>
        </Grid>

        {flow === 1 && editDrawer ? (
          <IMEditCommentDrawer
            open={editDrawer}
            onClose={handleDrawerClose}
            title={"" || recommendationNumber}
            quesData={allData?.disciplineData?.[selectedCardIndex]}
            reportID={allData.id}
            tourDate={allData?.createdAt}
          />
        ) : flow === 1 ? (
          <IMDrawer
            open={drawerOpen}
            onClose={handleDrawerClose}
            title={drawerTitle || recommendationNumber}
            status={true}
            quesData={allData?.disciplineData?.[selectedCardIndex]}
            reportID={allData.id}
            flow={flow}
            tourDate={allData?.createdAt}
          />
        ) : flow === 2 && editDrawer ? (
          <ExecutiveEditDrawer
            open={editDrawer}
            onClose={handleDrawerClose}
            title={"" || recommendationNumber}
            quesData={allData?.disciplineData?.[selectedCardIndex]}
            tourDate={allData?.createdAt}
          />
        ) : (
          <RightDrawer
            open={drawerOpen}
            onClose={handleDrawerClose}
            title={recommendationNumber}
            recommendation={recommendation}
            status={true}
            questionID={questionID}
            flow={flow}
            reportId={allData.id}
            executiveRemark={executiveRemark}
            executiveQues={executiveQues}
          />
        )}

        <AcceptModal
          open={acceptModalOpen}
          onClose={handleAcceptModal}
          onYes={handleAcceptModal}
          message1={message1}
          message2={message2}
        />
        <RemarkModal
          open={openRemarkModal}
          onClose={handleCloseRemarkModal}
          onSubmit={handleSubmitRemarkModal}
        />
        <ReturnValidationModal
          open={openReturnValidationModal}
          onClose={handleCloseReturnValidationModal}
        />
        <IMCommentDrawer
          open={imCommentDrawer}
          onClose={handleDrawerClose}
          title={"" || recommendationNumber}
          quesData={allData?.disciplineData?.[selectedCardIndex]}
          reportID={allData.id}
        />
        <DraggableImageDialog
          imageSrc={selectedImgForDialog}
          open={draggableDialogOpen}
          onClose={handleCloseDraggableDialog}
        />
        <ConfirmationModal
          open={isConfirmationModalOpen}
          title={confirmationModalDetails.title}
          message={confirmationModalDetails.message}
          onCancel={handleConfirmCancel}
          onConfirm={
            confirmationModalDetails.action === "submitReview"
              ? handleConfirmSubmitReviewAction
              : handleConfirmAction
          }
        />

        {/* Confirmation modal for mm Accept action */}
          <ConfirmationModal
            open={mmAcceptConfirmation}
            title="Confirm Accept"
            message="Are you sure you want to accept this recommendation?"
            onCancel={() => setMmAcceptConfirmation(false)}
            onConfirm={mmConfirmAccept}
          />

          {/* Confirmation modal for mm Return action */}
          <ConfirmationModal
            open={mmReturnConfirmation}
            title="Confirm Return"
            message="Are you sure you want to return this recommendation?"
            onCancel={() => setMmReturnConfirmation(false)}
            onConfirm={mmConfirmReturn}
          />
      </Grid>
      {/* Modal to show simple return message for IM */}
      <Modal open={openReturnMessage} onClose={()=>setOpenReturnMessage(0)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" mb={2} fontWeight='600'>
            Please select the recommendations you want to return and click "Proceed for Revalidation".
          </Typography>
          <Button variant="contained" sx={{color:'white'}} onClick={()=>setOpenReturnMessage(0)}>
            Okay
          </Button>
        </Box>
      </Modal>
    </>
  );
}
