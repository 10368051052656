import React, { useState, useEffect } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "../CustomInputField";
import { revalidateTask } from "../../Apis/apiCall";
import ConfirmationModal from "./ConfirmationModal"; 
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant"

export default function IMCommentDrawer({open, onClose, title, quesData, reportID}) {

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);

    const [comment, setComment] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openConfirmationModal = () => setIsModalOpen(true);
    const closeConfirmationModal = () => setIsModalOpen(false);
  
    const handleSubmit = async () => {
      dispatch({type : START_LOADING})
      try {
        await revalidateTask({
          reportID: reportID,
          questionID: quesData.id,
          returnComment: comment,
        });
        onClose(); // Close the modal or dialog after a successful API call
        window.location.reload();
      } catch (error) {
        console.error("Error revalidating task:", error);
      }
      finally{
        dispatch({ type: STOP_LOADING }); 
      }
    };
  
    const handleConfirm = () => {
      closeConfirmationModal(); // Close the modal
      handleSubmit(); // Call the original submit function
    };

  return (
    <>
    {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
      <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "36%", padding: "0px !important" } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          {title || ""}
        </Typography>
      </Grid>
      <div style={{ padding: "28px 24px" }}>

                {/* <Box sx={{ backgroundColor: '#F5FBFE', p: 1, borderRadius: 1, mt: '12px' }}>
                    <Typography>Remark</Typography>
                    <Box sx={{ color: "#525966" }}>
                        {quesData?.returnComment?.length > 0
                            ? quesData?.returnComment?.map((remark, index) => (
                                <Typography key={index} sx={{ color: '#525966' }}>{remark.comment}</Typography>
                            ))
                            : "No remark"}
                    </Box>
                </Box> */}

                <Box my={2} p={3} sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }} borderRadius={4}>

                    <Typography sx={{ mb: 2, fontSize: '14px' }}>
                        {quesData?.question}
                    </Typography>
                    
                        <CustomInput
                            placeholder={"Comment"}
                            label={"Comment"}
                            multiline
                            rows={4}
                            type={"text"}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                </Box>
                <Button variant="contained" onClick={openConfirmationModal} sx={{ float: "right", color: '#ffffff' }}>Submit</Button>
            </div>
      <Fab aria-label="close" onClick={onClose} sx={{
        position: "fixed", top: "100px", right: open ? "37.3%" : "0", transform: "translateX(50%)",
        zIndex: 1200, height: "36px", width: "36px", backgroundColor: "#0AA2E3", borderRadius: "60px 0 0 60px"
      }}>
        <CloseIcon />
      </Fab>

      <ConfirmationModal
        open={isModalOpen}
        title="Confirm Submission"
        message="Are you sure you want to submit this comment?"
        onCancel={closeConfirmationModal}
        onConfirm={handleConfirm}
      />
    </Drawer>
    
    </>
    
  )
}
