import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  Divider,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { addVideoTutorial, addFAQ, addHelpManual } from '../Apis/apiCall';

function CustomHelpManualDialog({ open, onClose, type }) {     //null, videoTutorials, helpManuals, faqs
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [moduleID] = useState(2); // Example static moduleID, you can replace it as needed

  const handleVideoFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    const allowedTypes = ["video/mp4"]; // MIME type for MP4 videos
  
    if (uploadedFile) {
      if (allowedTypes.includes(uploadedFile.type) && uploadedFile.size <= 10 * 1024 * 1024) { // Check file type and size
        setFile(uploadedFile);
      } else if (!allowedTypes.includes(uploadedFile.type)) {
        alert("Invalid file type. Please upload an MP4 file.");
      } else if (uploadedFile.size > 10 * 1024 * 1024) {
        alert("File size exceeds the maximum limit of 10 MB.");
      }
    }
  };

  const handleManualFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]; // MIME types for PDF and DOCX
  
    if (uploadedFile) {
      if (allowedTypes.includes(uploadedFile.type) && uploadedFile.size <= 10 * 1024 * 1024) { // Check file type and size
        setFile(uploadedFile);
      } else if (!allowedTypes.includes(uploadedFile.type)) {
        alert("Invalid file type. Please upload a PDF or DOCX file.");
      } else if (uploadedFile.size > 10 * 1024 * 1024) {
        alert("File size exceeds the maximum limit of 10 MB.");
      }
    }
  };

  const handleSubmit = async () => {
    if(type === "videoTutorials"){
      if (!title || !description || !file) {
        alert("Please fill in all fields and upload a file.");
        return;
      }

      try {
        // Convert the file to base64
        const fileBase64 = await toBase64(file);

        // Create the request body
        const requestBody = {
          moduleID,
          title,
          description,
          base64: fileBase64,
          name: file.name,
          type: file.type,
        };

        // Call the API
        const response = await addVideoTutorial(requestBody);
        alert("Video tutorial added successfully!");
        window.location.reload();
        // console.log(response.data);
      } catch (error) {
        console.error("Error uploading video tutorial:", error);
        alert("Failed to upload video tutorial. Please try again.");
      }
    } else if(type === "faqs") {
      if (!title || !description) {
        alert("Please fill in all fields.");
        return;
      }
      try {
        // Create the request body
        const requestBody = {
          moduleID: 1,
          question: title,
          answer: description
        };

        // Call the API
        const response = await addFAQ(requestBody);
        alert("FAQ added successfully!");
        window.location.reload();
        // console.log(response.data);
      } catch (error) {
        console.error("Error uploading FAQ", error);
        alert("Failed to upload FAQ. Please try again.");
      }
    } else if(type === "helpManuals") {
      if (!title || !description || !file) {
        alert("Please fill in all fields and upload a file.");
        return;
      }
      try {
        // Convert the file to base64
        const fileBase64 = await toBase64(file);

        // Create the request body
        const requestBody = {
          moduleID,
          title,
          description,
          base64: fileBase64,
          name: file.name,
          type: file.type,
        };

        // Call the API
        const response = await addHelpManual(requestBody);
        alert("Help Manual added successfully!");
        window.location.reload();
        // console.log(response.data);
      } catch (error) {
        console.error("Error uploading Help Manual:", error);
        alert("Failed to upload help manual. Please try again.");
      }
    } else {
      console.log("nothing");
    }
  };

  // Utility function to convert file to base64
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]); // Extract base64 string
      reader.onerror = (error) => reject(error);
    });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add More {type === "videoTutorials" ? "Tutorial" : type === "helpManuals" ? "File" : type === "faqs" ? "Question" : ""}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent>
      {type === "videoTutorials" ? (
        <>
        <TextField
          label="Add Title"
          fullWidth
          margin="normal"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          label="Add Description"
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Box
          sx={{
            width: "100%",
            border: "2px dashed rgba(82, 89, 102, 1)",
            borderRadius: 1,
            p: 3,
            mt: 2,
            textAlign: "center",
            cursor: "pointer",
            "&:hover": {
              borderColor: "primary.main",
            },
          }}
          onClick={() => document.getElementById("file-input").click()}
        >
          <input
            id="file-input"
            type="file"
            accept=".mp4"
            onChange={handleVideoFileChange}
            style={{ display: "none" }}
          />
          <CloudUploadIcon sx={{ fontSize: 40, color: "black", mb: 1 }} />
          <Typography
            variant="body2"
            color="#09A2E3"
            sx={{ textDecoration: "underline" }}
          >
            Click to upload
          </Typography>
          <Typography variant="caption" color="black" display="block">
            Mp4 (max. 10 MB)
          </Typography>
        </Box>
        {file && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Uploaded file: {file.name}
          </Typography>
        )}
        </>
      ) : type === "helpManuals" ? (
        <>
          <TextField
            label="Add Title"
            fullWidth
            margin="normal"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Add Description"
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box
            sx={{
              width: "100%",
              border: "2px dashed rgba(82, 89, 102, 1)",
              borderRadius: 1,
              p: 3,
              mt: 2,
              textAlign: "center",
              cursor: "pointer",
              "&:hover": {
                borderColor: "primary.main",
              },
            }}
            onClick={() => document.getElementById("file-input").click()}
          >
            <input
              id="file-input"
              type="file"
              accept=".pdf, .docx"
              onChange={handleManualFileChange}
              style={{ display: "none" }}
            />
            <CloudUploadIcon sx={{ fontSize: 40, color: "black", mb: 1 }} />
            <Typography
              variant="body2"
              color="#09A2E3"
              sx={{ textDecoration: "underline" }}
            >
              Click to upload
            </Typography>
            <Typography variant="caption" color="black" display="block">
              Pdf, Docx (max. 10 MB)
            </Typography>
          </Box>
          {file && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Uploaded file: {file.name}
            </Typography>
          )}
          </>
      ) : type === "faqs" ? (
        <>
          <TextField
            label="Add Question"
            fullWidth
            margin="normal"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Add Answer"
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </>
      ) : (
        <></>
      )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary" sx={{color:'white'}}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomHelpManualDialog;
