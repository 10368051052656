
import {
  UPDATE_FORM_FIELD,
  ADD_CHECKBOX,REMOVE_CHECKBOX,
  UPDATE_DATE_TIME,
  ADD_PERSONNEL_DETAIL,
  SET_CAPA_DETAILS,
  ONCHANGE_DRAWER_FORM,
  ADD_CAPA_DETAIL,
  ADD_EVIDENCE_DETAIL,
  UPDATE_SWITCH_STATE,
  EDIT_CAPA_DETAIL,
  DELETE_CARD_DETAIL,
  SET_ALL_REPORT_DATA,
  UPDATE_EVIDENCE_FILE,
  RESET_STATE,
  REMOVE_INVESTIGATION_MEMBER,
  SET_REVIEW_DATA
} from './constant';

export const resetState = () => ({
  type: RESET_STATE,
});

export const updateFormField = ({ fieldName, value }) => ({
  type: UPDATE_FORM_FIELD,
  payload: { fieldName, value },
});

export const addCheckbox = (label) => ({
  type: ADD_CHECKBOX,
  payload: label,
});

export const removeCheckbox = (label) => ({
  type: REMOVE_CHECKBOX,
  payload: label,
});



export const updateIncidentDateTime = (fieldName, value, title) => ({
  type: UPDATE_DATE_TIME,
  payload: { fieldName, value, title},
});

export const addPersonnelDetail = (PersonnelDetail) => {
  return {
    type: ADD_PERSONNEL_DETAIL,
    payload: PersonnelDetail
  };
};




export const addEvidenceDetail = (evidenceDetail) => {
  return {
    type: ADD_EVIDENCE_DETAIL,
    payload: evidenceDetail
  };
};

export const addCAPADetail = (CAPADetail) => {
  return {
    type: ADD_CAPA_DETAIL,
    payload: CAPADetail
  };
};


 
export const handleChangeDrawerFormData = ({fieldName, value, title}) => ({
  type: ONCHANGE_DRAWER_FORM,
  payload: { fieldName, value, title},
});

export const updateSwitchState = (sectionName, value) => ({
  type: UPDATE_SWITCH_STATE,
  payload: {
    sectionName,
    value,
  },
});

export const editDetail = ({id ,title}) => {
  return {
    type: EDIT_CAPA_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const deleteCardDetail = ({id ,title}) => {
  return {
    type: DELETE_CARD_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const setAllReportData = (data) => {
  return {
      type: SET_ALL_REPORT_DATA,
      payload: data
  };
};

export const updateEvidenceFile = (file) => ({
  type: UPDATE_EVIDENCE_FILE,
  payload: file,
});


export const setCAPADetails = (capaDetails) => {
  return {
    type: SET_CAPA_DETAILS,
    payload: capaDetails,
  };
};

export const removeInvestigationMember = (member) => {
  return {
    type: REMOVE_INVESTIGATION_MEMBER,
    payload: member,
  };
};


export const setReviewData = (reviewData) => ({
  type: SET_REVIEW_DATA,
  payload: reviewData,
});


  