import React, { useEffect, useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const useManualWidthCheck = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isSm = width < 800; // Custom breakpoint for 'sm'
  const isMd = width >= 800 && width < 1160; // Custom breakpoint for 'md'
  const isLg = width >= 1150 && width < 1300; // Custom breakpoint for 'lg'
  const isVLg = width >= 1300; // Custom breakpoint for 'lg'

  return { isSm, isMd, isLg };
};
 
export default function PyramidChart({ data }) {

  // Define width and height based on breakpoints
  const { isSm, isMd, isLg } = useManualWidthCheck();
  const chartDimensions = 
      isSm ? { width: 330, height: 330 }
    : isMd ? { width: 360, height: 360 }
    : isLg ? { width: 440, height: 440 }
    : { width: 540, height: 540 };

  const { width, height } = chartDimensions;

  const totalLevels = data.length;
  const padding = 20;
  const chartHeight = height - 2 * padding;
  const chartWidth = width - 2 * padding;
  const levelHeight = chartHeight / totalLevels;
  const sortedData = [...data].sort((a, b) => a.value - b.value);
 
  // Find the maximum value to calculate relative widths
  const maxValue = Math.max(...data.map((item) => item.value));
 
  const [prevWidth, setPrevWidth] = useState(null);
 
  // Colors for the pyramid levels
  const colors = [
    '#2196F3', // Blue
    '#FF9800', // Orange
    '#4CAF50', // Green
    '#F44336', // Red
    '#9C27B0', // Purple
    '#795548', // Brown
  ];

 
  return (
    <>
      <Box
        sx={{
          position:"relative",
          height: `${height}px`,
        }}
      >
        {/* Pyramid levels */}
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {sortedData.map((item, index) => {
            let prevLevelWidth = 0
            if(index>0){
                prevLevelWidth = (sortedData[index-1].value / maxValue) * chartWidth;
            }
            const levelWidth = (item.value / maxValue) * chartWidth;
            const leftOffset = (width - levelWidth) / 2;
 
            const measuredPercent = (50*(levelWidth - prevLevelWidth))/levelWidth
 
            return (
              <Box
                key={item.label}
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: `${levelHeight}px`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* Pyramid segment */}
                <Tooltip title = {item.label} arrow placement='right'>
                <Box
                  sx={{
                    backgroundColor: colors[index % colors.length],
                    width: `${levelWidth}px`,
                    height: `${levelHeight - 2}px`,
                    position: 'absolute',
                    left: `${leftOffset}px`,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      opacity: 0.9,
                    },
                    clipPath: `polygon(${measuredPercent}% 0%, ${100-measuredPercent}% 0%, 100% 100%, 0% 100%)`,
 
                  }}
                >
                  {/* Label and value */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      transform: 'translateY(-50%)',
                      width: '100%',
                      justifyContent: 'center',
                      display: 'flex',
                      padding: '16px',
                    }}
                  >
                    {/* <Typography
                      variant="body2"
                      sx={{ color: '#fff', fontWeight: '500' }}
                    >
                      {item.label}
                    </Typography> */}
                    <Typography
                      variant="body2"
                      sx={{ color: '#fff', fontWeight: '500' }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                </Box>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}