import React, { useEffect, useState } from 'react';
import { Box, Typography, Stepper, Step, StepButton, StepIcon } from '@mui/material';
import { Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveStep,completeForm } from '../redux/stepper/action'; // Your action to update the active step
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';

const requiredKeys = [
  "Severity", 
  "Activity",
  "Sub Activity",
  "Sub Area",
  "Area",
  "Cause of Accident",
  "Date of Accident", 
  "Department", 
  "Incident Short Summary",
  "Immediate Measures", 
  "Incident Summary",
  "Incident Time", 
  "Incident Type", 
  "Incident Location", 
  "Losses Type", 
  "Reported By - Entity Name", 
  "Reported By - Staff Name",
  "Shift", 
  "Staff Type",
  "Operation Status",
  
];

const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <StepIcon
      icon={completed ?     
      <Box
        sx={{
          width: 22, // Adjust the size of the circle
          height: 22,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#0AA2E3', // Circle background color
        }}
      >
      <DoneIcon sx={{ color: '#ffffff', fontSize: 12 }} />
    </Box>
    : active ? ( 
    <Box
        sx={{
          width: 22, // Adjust the size of the circle
          height: 22,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#0AA2E3', // Circle background color
        }}
      >
      <HourglassEmptyIcon sx={{ color: '#ffffff', fontSize: 12 }} />
    </Box>
    ) :(
      <Box
        sx={{
          width: 22, // Adjust the size of the circle
          height: 22,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'gray', // Circle background color
        }}
      >
      <HourglassEmptyIcon sx={{ color: '#ffffff', fontSize: 12 }} />
    </Box>
    )
    }
      style={{
        color: active ? '#0AA2E3' : completed ? '#0AA2E3' : 'grey',
      }}
    />
  );
};

const CustomStepper = ({ steps }) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const formData = useSelector((state) => state.incidentReportFromReducer);
  const activeStep = useSelector((state) => state.stepReducer.activeStep);
  const isFormComplete = useSelector((state) => state.stepReducer.isFormComplete); // Assuming stepReducer has isFormComplete
  
  const [completed, setCompleted] = useState({});

  useEffect(() => {
    const allRequiredFieldsFilled = requiredKeys.every((key) => {
      const value = formData[key];
      return Array.isArray(value) ? value.length > 0 : Boolean(value);
    });

  if (allRequiredFieldsFilled) {
      setCompleted((prev) => ({ ...prev, 0: true }));
      dispatch(completeForm(true));
  }
  else{
     dispatch(completeForm(false));
  }
   
  }, [formData]);

  useEffect(() => {
    if (showAlert) {
      // Automatically close the alert after 5 seconds
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 1000);

      // Clean up the timer when the component unmounts or showAlert changes
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleStep = (step) => () => {
    // console.log(step);
    // Prevent switching to step 2 without completing the form (step 1)
    if (step === 1 && !isFormComplete) {
      setShowAlert(true); // Show MUI Alert
      return;
    }

    // Mark the current step as completed if the form is completed
    if (step === 1 && isFormComplete) {
      setCompleted((prev) => ({ ...prev, 0: true })); // Mark step 1 as complete
      dispatch(updateActiveStep(0));
    }

    // Switch to the clicked step
    dispatch(updateActiveStep(step));
  };

  return (
    <>
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton onClick={handleStep(index)} 
            icon={<CustomStepIcon active={activeStep === index} completed={completed[index]} />}
            sx={{
                  '& .MuiStepLabel-label': {
                    color: activeStep === index ? '#0AA2E3' : 'inherit',
                  },
                }}
            >
              <Typography variant="body1">{label}</Typography>
            </StepButton>
          </Step>
        ))}
      </Stepper>

     

      
    </Box>
     {showAlert && (
      <Alert
        severity="warning"
        onClose={() => setShowAlert(false)} 
        sx={{
          position: 'fixed',
          top: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          maxWidth: '600px',
          zIndex: 9999, // Ensure the alert is on top of other elements
          textAlign: 'center',
        }}
      >
        Please complete the form before moving to the review step.
      </Alert>
    )}
    </>

  );
};

export default CustomStepper;
