import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Divider,
  IconButton,
  Tooltip, TextField
} from "@mui/material";
import BasicDatePicker from "../CustomeDate";
import CustomInput from "../CustomInputField";
import UploadIcon from "@mui/icons-material/Upload";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MessageIcon from "@mui/icons-material/Message";
import { disciplineCover } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { userFullName, todayDate } from "../../constant";
import {
  addObservation,
  addData,
  deleteImage,
  addSatisfactoryRemark,
  addNotApplicableRemark
} from "../../redux/LossControl/actions";
import DraggableImageDialog from "../DraggableImageDialog";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import SubmitModal from "./SubmitModal";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { submitLossControlReport } from "../../Apis/apiCall";
import Compressor from 'compressorjs';
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loader from "../../Components/Loader/Loader";
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { blue } from "@mui/material/colors";

export default function ReviewAndSubmit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);
  const [cards, setCards] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionData, setQuestionData] = useState({});
  const [questionStatus, setQuestionStatus] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [finalData,setFinalData] = useState([]);
  const [questionRemark, setQuestionRemark] = useState({});  //For satisfactory, not applicable data
  const [remark, setRemark] = useState("");
  const [remarkEditMode, setRemarkEditMode] = useState(0);
  

  const tourData = useSelector((state) => state.lossControlReducer.tours);
  const lID = tourData.locationID;

  useEffect(() => {
    if (tourData?.questionnaire) {
      setCards(tourData.questionnaire);
    }
  }, [tourData]);

  const handleDateChange = (newValue) => {
    updateQuestionData("targetCompletionDate", newValue ? newValue.valueOf() : null);
  };

  const handleOptionChange = (newOption) => {
    const currentQuestion = cards[currentQuestionIndex];
    updateQuestionStatus(currentQuestion.id, newOption);
    dispatch(addObservation(currentQuestion.id, newOption));

    if (newOption === 1) {
      dispatch(
        addData(currentQuestion.id, {
          targetCompletionDate:
            questionData[currentQuestion.id]?.targetCompletionDate,
          evidence: questionData[currentQuestion.id]?.evidence,
          recommendation: questionData[currentQuestion.id]?.recommendation,
          observation: questionData[currentQuestion.id]?.observation,
        })
      );
      dispatch(
        addNotApplicableRemark(currentQuestion.id, "")
      );
      dispatch(
        addSatisfactoryRemark(currentQuestion.id, "")
      );
    } else if (newOption === 0) {
      dispatch(
        addSatisfactoryRemark(currentQuestion.id, currentQuestionSatisfactoryRemark)
      );
      dispatch(
        addNotApplicableRemark(currentQuestion.id, "")
      );
      dispatch(
        addData(currentQuestion.id, {
          targetCompletionDate: null,
          evidence: [],
          recommendation: "",
          observation: "",
        })
      );
    } else if (newOption === 2) {
      dispatch(
        addSatisfactoryRemark(currentQuestion.id, "")
      );
      dispatch(
        addNotApplicableRemark(currentQuestion.id, currentQuestionNotApplicableRemark)
      )
      dispatch(
        addData(currentQuestion.id, {
          targetCompletionDate: null,
          evidence: [],
          recommendation: "",
          observation: "",
        })
      );
    } else {
      console.log("nothing");
    }
  };

  const updateQuestionStatus = (questionId, newOption) => {
    setQuestionStatus((prevStatus) => ({
      ...prevStatus,
      [questionId]: newOption,
    }));
  };
  const updateQuestionSatisfactoryRemark = (questionId, remark) => {
    setQuestionRemark((prevRemark) => ({
      ...prevRemark,
      [questionId]: remark,
    }));
    dispatch(
      addSatisfactoryRemark(questionId, remark)
    )
    setRemark("");
  };
  const updateQuestionNotApplicableRemark = (questionId, remark) => {
    setQuestionRemark((prevRemark) => ({
      ...prevRemark,
      [questionId]: remark,
    }));
    dispatch(
      addNotApplicableRemark(questionId, remark)
    )
    setRemark("");
  };
  const updateQuestionData = (field, value) => {
    const currentQuestion = cards[currentQuestionIndex];
    const fieldValue =
      field === "targetCompletionDate"
        ? new Date(value).toLocaleDateString("en-CA")
        : value;
    setQuestionData((prevData) => {
      const updatedData = {
        ...prevData,
        [currentQuestion.id]: {
          ...prevData[currentQuestion.id],
          [field]: fieldValue,
        },
      };
      dispatch(addData(currentQuestion.id, updatedData[currentQuestion.id]));
      return updatedData;
    });
  };

  const handleNext1 = () => {
    if (currentQuestionIndex < cards.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      //   setFirstModal(true);
      setFirstModalOpen(true);
    }
    setUploadedFiles([]);
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleObservationChange = (e) => {
    updateQuestionData("observation", e.target.value);
  };

  const handleRecommendationChange = (e) => {
    updateQuestionData("recommendation", e.target.value);
  };

  const handleEvidenceUpload = (event) => {
    const files = Array.from(event.target.files);
    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.2,
          success(compressedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve({
                base64: e.target.result.split(",")[1],
                name: compressedFile.name,
                type: compressedFile.type,
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(compressedFile);
          },
          error(err) {
            reject(err);
          },
        });
      });
    });
  
    Promise.all(filePromises)
      .then((newFiles) => {
        const updatedFiles = [
          ...uploadedFiles,
          ...newFiles.filter(
            (newFile) =>
              !uploadedFiles.some(
                (existingFile) => existingFile.name === newFile.name
              )
          ),
        ];
        setUploadedFiles(updatedFiles);
        updateQuestionData("evidence", updatedFiles);
      })
      .catch((error) => {
        console.error("Image upload/compression failed:", error);
      });
  };

  const handleFileDelete = (questionId, imageIndex) => {
    dispatch(deleteImage(questionId, imageIndex));
  };

  const handleImageClick = (file) => {
    setSelectedImage(file);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedImage(null);
  };

  function payloadConverter(inputData) {
    function convertDateToTimestamp(dateStr) {
      return new Date(dateStr).getTime();
    }

    const outputData = {
      locationID: inputData.locationID,
      discipline: inputData.disciplineName,
      questionaires: inputData?.questionnaire?.map((question) => {
        const questionObj = {
          question: question.quesName,
          questionStatus: question.questionStatus,
        };

        if (question.data) {
          if (question.data.observation) {
            questionObj.observation = question.data.observation;
          }
          if (question.data.recommendation) {
            questionObj.recommendation = question.data.recommendation;
          }
          if (question.data.targetCompletionDate) {
            questionObj.targetCompletionDate = convertDateToTimestamp(
              question.data.targetCompletionDate
            );
          }

          if (question.data.evidence && question.data.evidence.length > 0) {
            questionObj.evidence = question.data.evidence.map((evidence) => ({
              base64: evidence.base64,
              name: evidence.name,
              type: evidence.type,
            }));
          }
        }

        if(question.questionStatus === 0){
          questionObj.satisfactoryRemark = question.satisfactoryRemark
        }

        if(question.questionStatus === 2){
          questionObj.notApplicableRemark = question.notApplicableRemark
        }

        return questionObj;
      }),
    };
    setFinalData(outputData)
    return outputData;
  }

  const handleFirstModalYes = async() => {
    setFirstModalOpen(false);

    //API for Report Submitting in Loss Control
    
    const payload = payloadConverter(tourData);
    dispatch({type : START_LOADING})
    try {
      await submitLossControlReport(payload);
      setSecondModalOpen(true);
    } catch (error) {
      console.error("Error while submitting loss control report", error);
      alert("Error while submitting report");
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  const handleSecondModalYes = async () => {
    setSecondModalOpen(false);
    const updatedFinalData = {
      ...finalData,
      siteLocation: tourData.siteLocation
  };
  console.log("FinalData", updatedFinalData);
    navigate(`/module/loss-control/create-tour/${lID}`, { state: updatedFinalData }); 
  };
  const handleSecondModalNo = () => {
    setSecondModalOpen(false);
  };

  const handleFirstModalNo = () => {
    setFirstModalOpen(false);
  };

  const handleSkipSurvey = () => {
    setFirstModalOpen(true);
  }

  const currentQuestion = cards[currentQuestionIndex] || {};
  const currentQuestionData = currentQuestion.data || {};
  const currentQuestionStatus = currentQuestion.questionStatus;
  const currentQuestionSatisfactoryRemark = currentQuestion?.satisfactoryRemark;
  const currentQuestionNotApplicableRemark = currentQuestion?.notApplicableRemark;

  // console.log("finalData",finalData);
  // console.log("tourData",tourData);
  // console.log("cards",cards);
  // console.log("currentQuestion",currentQuestion);
  // console.log("currentQuestionData",currentQuestionData);
  // console.log("currentQuestionStatus",currentQuestionStatus);

  return (
    <>
    {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "16px",
          minHeight: "auto",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Grid item>
            <Typography
              sx={{ fontSize: "20px", fontWeight: "600", color: "#323232" }}
            >
              {tourData.disciplineName}
            </Typography>
            <Typography variant="body2" sx={{ color: "#525966" }}>
              Loss Control Tour
            </Typography> 
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <PersonOutlineOutlinedIcon
                  sx={{ color: "#949494", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{ color: "#949494", fontSize: "12px", fontWeight: "600" }}
                >
                  {userFullName}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <CalendarMonthOutlinedIcon
                  sx={{ color: "#949494", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{ color: "#949494", fontSize: "12px", fontWeight: "600" }}
                >
                  {todayDate}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={0.5}
              >
                <LocationOnOutlinedIcon
                  sx={{ color: "#949494", width: "20px", height: "20px" }}
                />
                <Typography
                  sx={{ color: "#949494", fontSize: "12px", fontWeight: "600" }}
                >
                  {tourData.siteLocation}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2} sx={{ display: "flex" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={{
                borderRadius: "8px",
                background: "#FFF",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                padding: "16px",
                minHeight: "480px",
                flexGrow: 1,
              }}
            >
              <Box>
                <Box sx={{display:'flex',justifyContent:'space-between'}}>

                <Box sx={{ p: 2 }}>
                  <Typography
                    sx={{
                      mb: "4px",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#222",
                    }}
                  >
                    Point {currentQuestion.id}/{cards.length}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#525966",
                      width: "357px",
                    }}
                  >
                    {currentQuestion.quesName}
                  </Typography>
                </Box>

                <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Tooltip arrow title="Skip Review">
                      <Button
                        // variant="outlined"
                        onClick={handleSkipSurvey}
                        sx={{
                          fontSize: { xs: '8px', sm: '10px', md: '12px' }, 
                          maxWidth: '100%',
                          '& .MuiButton-endIcon': {
                            fontSize: { xs: '10px', sm: '12px', md: '14px' }, 
                          },
                          right:'0%',
                        }}
                      >
                        Skip<KeyboardDoubleArrowRight />
                      </Button>
                    </Tooltip>
                  </Box>

                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ textAlign: "center", padding: "20px" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      border: "1px solid #CCC",
                      borderRadius: "100px",
                      padding: "8px",
                      width: "100%",
                    }}
                  >
                    <Button
                      size="large"
                      onClick={() => handleOptionChange(1)}
                      sx={{
                        borderRadius: "100px",
                        backgroundColor:
                          currentQuestionStatus === 1 ? "#09A2E3" : "white",
                        width: "33%",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <SentimentVeryDissatisfiedIcon
                          sx={{
                            fontSize: "24px",
                            color:
                              currentQuestionStatus === 1 ? "white" : "#525966",
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            color:
                              currentQuestionStatus === 1 ? "white" : "#525966",
                          }}
                        >
                          Unsatisfactory
                        </Typography>
                      </Box>
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      onClick={() => handleOptionChange(2)}
                      sx={{
                        borderRadius: "100px",
                        backgroundColor:
                          currentQuestionStatus === 2 ? "#09A2E3" : "white",
                        width: "33%",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <SentimentNeutralIcon
                          sx={{
                            fontSize: "24px",
                            color:
                              currentQuestionStatus === 2 ? "white" : "#525966",
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            color:
                              currentQuestionStatus === 2 ? "white" : "#525966",
                          }}
                        >
                          Not Applicable
                        </Typography>
                      </Box>
                    </Button>
                    <Button
                      color="primary"
                      size="large"
                      onClick={() => handleOptionChange(0)}
                      sx={{
                        borderRadius: "100px",
                        backgroundColor:
                          currentQuestionStatus === 0 ? "#09A2E3" : "white",
                        width: "33%",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <SentimentSatisfiedAltIcon
                          sx={{
                            fontSize: "24px",
                            color:
                              currentQuestionStatus === 0 ? "white" : "#525966",
                          }}
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            color:
                              currentQuestionStatus === 0 ? "white" : "#525966",
                          }}
                        >
                          Satisfactory
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                {currentQuestionIndex !== 0 && (
                  <Button
                    variant="outlined"
                    onClick={handlePrev}
                    disabled={editMode}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      paddingY: "8px",
                      paddingX: "32px",
                      borderRadius: "24px",
                      width: "100%",
                    }}
                  >
                    Previous
                  </Button>
                )}
                {editMode ? (
                  <Button
                    variant="contained"
                    onClick={() => setEditMode(false)}
                    disabled={currentQuestionStatus === null || (currentQuestionStatus === 1 && (currentQuestionData.observation?.length === 0 || !currentQuestionData.observation || 
 currentQuestionData.recommendation?.length === 0 || !currentQuestionData.recommendation || 
 currentQuestionData.targetCompletionDate?.length === 0 || !currentQuestionData.targetCompletionDate)) }
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      backgroundColor: "#09A2E3",
                      color: "#ffffff",
                      paddingY: "8px",
                      paddingX: "32px",
                      borderRadius: "24px",
                      width: "100%",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext1}
                    disabled={currentQuestionStatus === null || (currentQuestionStatus === 1 && (currentQuestionData.observation?.length === 0 || !currentQuestionData.observation || 
 currentQuestionData.recommendation?.length === 0 || !currentQuestionData.recommendation || 
 currentQuestionData.targetCompletionDate?.length === 0 || !currentQuestionData.targetCompletionDate)) }
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      backgroundColor: "#09A2E3",
                      color: "#ffffff",
                      paddingY: "8px",
                      paddingX: "32px",
                      borderRadius: "24px",
                      width: "100%",
                    }}
                  >
                    {currentQuestionIndex < cards.length - 1
                      ? "Next"
                      : "Submit"}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                padding: "16px",
                flexGrow: 1,
                textAlign: "center",
                backgroundImage:
                  currentQuestionStatus === 1
                    ? "none"
                    : `url(${disciplineCover})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "auto",
                width: "100%",
                borderRadius: "12px",
                border: "0.5px solid rgba(9, 162, 227, 0.20)",
                backdropFilter: "blur(7px)",
              }}
            >
              {currentQuestionStatus === null ? (
                <Box sx={{ width: "70%" }}>
                  <MessageIcon sx={{ color: "#09A2E3", fontSize: "100px" }} />
                  <Typography
                    sx={{
                      color: "#323232",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Waiting for your input!
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#4D4D4D", marginBottom: "8px" }}
                  >
                    Please select your response.
                  </Typography>
                </Box>
              ) : currentQuestionStatus === 1 ? (
                <>
                  {editMode ? (
                    // Edit Mode for Unsatisfactory
                    <Box
                      component="form"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: "100%",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          gutterBottom
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Observation
                          <Typography
                            component="span"
                            sx={{ fontSize: "14px", fontWeight: "600", color: "red", marginLeft: "4px" }}
                          >
                            *
                          </Typography>
                        </Typography>
                        <CustomInput
                          value={currentQuestionData.observation}
                          type="text"
                          onChange={handleObservationChange}
                          placeholder="type here..."
                        />
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          gutterBottom
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Recommendation
                          <Typography
                            component="span"
                            sx={{ fontSize: "14px", fontWeight: "600", color: "red", marginLeft: "4px" }}
                          >
                            *
                          </Typography>
                        </Typography>
                        <CustomInput
                          value={currentQuestionData.recommendation}
                          type="text"
                          onChange={handleRecommendationChange}
                          placeholder="type here..."
                        />
                      </Box>

                      {/* Target Completion Date */}
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          gutterBottom
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Target Completion Date
                          <Typography
                            component="span"
                            sx={{ fontSize: "14px", fontWeight: "600", color: "red", marginLeft: "4px" }}
                          >
                            *
                          </Typography>
                        </Typography>
                        {/* <BasicDatePicker
                          value={dayjs(
                            currentQuestionData.targetCompletionDate
                          )}
                          onChange={handleDateChange}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={currentQuestionData?.targetCompletionDate ? dayjs(currentQuestionData.targetCompletionDate) : null}
                                onChange={(newValue) => handleDateChange(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                                format ="DD-MM-YYYY"
                                minDate={dayjs()}
                            />
                        </LocalizationProvider>
                      </Box>
                      {/* Evidence Box */}
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          gutterBottom
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          Evidence
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            border: "1px dashed #09A2E3",
                            padding: 2,
                            borderRadius: 1,
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <IconButton component="label">
                            <UploadIcon
                              fontSize="large"
                              sx={{ color: "#000000" }}
                            />
                            <input
                              hidden
                              accept="image/*,.doc,.pdf,.mp4"
                              type="file"
                              onChange={handleEvidenceUpload}
                              multiple
                            />
                          </IconButton>
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 1,
                              color: "#348BD2",
                              fontWeight: "600",
                              textDecoration: "underline",
                            }}
                          >
                            Click to upload
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: "600" }}
                          >
                            PNG, JPG, Docx, PDF, and MP4 (max. 10 MB)
                          </Typography>
                        </Box>

                        {/* Render uploaded files */}
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            mt: 2,
                            gap: 1,
                          }}
                        >
                          {currentQuestionData?.evidence?.map((file, index) => (
                            <Box
                              key={index}
                              sx={{
                                position: "relative",
                                width: 80,
                                height: 80,
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  handleFileDelete(currentQuestion.id, index)
                                }
                                sx={{
                                  position: "absolute",
                                  top: -8,
                                  right: -8,
                                  zIndex: 2,
                                  backgroundColor: "white",
                                  padding: "4px",
                                  "&:hover": {
                                    backgroundColor: "white",
                                  },
                                  boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                                }}
                                size="small"
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 2,
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  border: "1px solid #ccc",
                                }}
                              >
                                {file.type.startsWith("image/") && (
                                  <img
                                    src={`data:${file.type};base64,${file.base64}`}
                                    alt={file.name}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    // Normal Mode for Unsatisfactory
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: 2,
                        height: "420px",
                        p: 2,
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                        flexDirection="row"
                      >
                        <Box display="flex" flexDirection="column">
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignItems="center"
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "#323232",
                                }}
                              >
                                Point {currentQuestion.id}/{cards.length}
                              </Typography>
                              {/* Edit Icon */}
                              <Box
                                sx={{
                                  border: "0.7px solid #09A2E3",
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "24px",
                                  height: "24px",
                                  ml: 1,
                                }}
                              >
                                <IconButton
                                  onClick={() => setEditMode(true)}
                                  sx={{ color: "#09A2E3" }}
                                >
                                  <EditIcon sx={{ fontSize: "16px" }} />
                                </IconButton>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              flexDirection="column"
                            >
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  color: "#525966",
                                  textAlign: "left",
                                }}
                              >
                                {currentQuestion.quesName}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  color: "#09A2E3",
                                  textAlign: "left",
                                }}
                              >
                                Unsatisfactory
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                      <Divider sx={{ my: "12px" }} />

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            gutterBottom
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Observation
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#525966",
                              textAlign: "left",
                            }}
                          >
                            {currentQuestionData.observation}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            gutterBottom
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Recommendation
                          </Typography>
                          <Typography
                            gutterBottom
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#525966",
                              textAlign: "left",
                            }}
                          >
                            {currentQuestionData.recommendation}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Target Completion Date
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#525966",
                              textAlign: "left",
                            }}
                          >
                            {currentQuestionData.targetCompletionDate}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#222",
                            }}
                          >
                            Evidence
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}
                          >
                            {currentQuestionData?.evidence?.map(
                              (file, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    width: 80,
                                    height: 80,
                                    borderRadius: 2,
                                    overflow: "hidden",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "1px solid #ccc",
                                  }}
                                >
                                  {file.type.startsWith("image/") && (
                                    <img
                                      src={`data:${file.type};base64,${file.base64}`}
                                      alt={file.name}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        cursor: "pointer",
                                        transition: "box-shadow 0.3s ease", // Smooth transition effect
                                        "&:hover": {
                                          boxShadow:
                                            "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow on hover
                                        },
                                      }}
                                      
                                      onClick={() =>
                                        handleImageClick(
                                          typeof file === "string"
                                            ? file
                                            : `data:${file.type};base64,${file.base64}`
                                        )
                                      }
                                    />
                                  )}
                                </Box>
                              )
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              ) : currentQuestionStatus === 2 ? (
                <Box sx={{ width: "70%" }}>
                  <SentimentNeutralIcon
                    sx={{ color: "#09A2E3", fontSize: "60px" }}
                  />
                  <Typography
                    sx={{
                      color: "#323232",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Not Applicable
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#4D4D4D", marginBottom: "8px" }}
                  >
                    We understand that you have marked your response as not
                    applicable.
                  </Typography>
                  <Button size="medium" 
                    onClick={() => {
                      if(currentQuestionNotApplicableRemark?.length > 0 || remarkEditMode === 1){
                        setRemarkEditMode(0);
                        updateQuestionNotApplicableRemark(currentQuestion.id, "");
                      }
                      else{
                        setRemarkEditMode(1);
                        updateQuestionNotApplicableRemark(currentQuestion.id, "");
                      }
                    }} 
                    sx={{ bgcolor: "none", color: "#09A2E3", fontWeight: 400, borderRadius: "4px" }}
                  >
                      {(remarkEditMode === 1 || currentQuestionNotApplicableRemark?.length > 0) ? "- Remove Remark" : "+ Add Remark"}
                  </Button>
                  {remarkEditMode === 1 ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      bgcolor="#F5FBFE"
                      p={0}
                      borderRadius="4px"
                      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                      position="relative"
                      mt={1.5}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Add remark"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                        InputProps={{
                          style: { padding: 0 },
                        }}
                        sx={{
                          border: "none",
                          "& fieldset": { border: "none" },
                          "& .MuiOutlinedInput-root": {
                            bgcolor: "#F5FBFE",
                            pr: 8, // Adjust padding to make room for the button
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={() => {updateQuestionNotApplicableRemark(currentQuestion.id,remark); setRemarkEditMode(0);}}
                        sx={{
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                          color: "#ffffff",
                          padding: "4px 16px",
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  ) : currentQuestionNotApplicableRemark?.length > 0 ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={0.5}
                      bgcolor="#F5FBFE"
                      p={1}
                      borderRadius="4px"
                      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                      mt={1.5}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={0}
                      >
                        <Typography
                          variant="body1"
                          sx={{ color: "#222222" }}
                        >
                          Remark
                        </Typography>
                        <IconButton
                          aria-label="edit"
                          sx={{
                            color: "#009FF5",
                            width: 32,
                            height: 32,
                            backgroundColor: "#F5FBFE",
                            borderRadius: "8px",
                            border: "none",
                            "&:hover": {
                              backgroundColor: blue[50],
                            },
                          }}
                          onClick={() => {
                            setRemarkEditMode(1);
                            setRemark(currentQuestionNotApplicableRemark || "");
                          }}
                        >
                          <EditOutlinedIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ color: "#525966" }}
                      >
                        {currentQuestionNotApplicableRemark}
                      </Typography>
                    </Box>
                  ): null}
                </Box>
              ) : currentQuestionStatus === 0 ? (
                <Box sx={{ width: "70%" }}>
                  <SentimentSatisfiedAltIcon
                    sx={{ color: "#09A2E3", fontSize: "60px" }}
                  />
                  <Typography
                    sx={{
                      color: "#323232",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Satisfactory!
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#4D4D4D", marginBottom: "8px" }}
                  >
                    Good to know that you found this satisfactory.
                  </Typography>
                  <Button size="medium" 
                  onClick={() => {
                    if(currentQuestionSatisfactoryRemark?.length > 0 || remarkEditMode === 1){
                      setRemarkEditMode(0);
                      updateQuestionSatisfactoryRemark(currentQuestion.id, "");
                    }
                    else{
                      setRemarkEditMode(1);
                      updateQuestionSatisfactoryRemark(currentQuestion.id, "");
                    }
                  }} 
                  sx={{ bgcolor: "none", color: "#09A2E3", fontWeight: 400, borderRadius: "4px" }}
                >
                    {(remarkEditMode === 1 || currentQuestionSatisfactoryRemark?.length > 0) ? "- Remove Remark" : "+ Add Remark"}
                </Button>
                {remarkEditMode === 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    bgcolor="#F5FBFE"
                    p={0}
                    borderRadius="4px"
                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                    position="relative"
                    mt={1.5}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Add remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      fullWidth
                      multiline
                      rows={3}
                      InputProps={{
                        style: { padding: 0 },
                      }}
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "#F5FBFE",
                          pr: 8, // Adjust padding to make room for the button
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {updateQuestionSatisfactoryRemark(currentQuestion.id,remark); setRemarkEditMode(0);}}
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 8,
                        color: "#ffffff",
                        padding: "4px 16px",
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                ) : currentQuestionSatisfactoryRemark?.length > 0 ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                    bgcolor="#F5FBFE"
                    p={1}
                    borderRadius="4px"
                    boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20)"
                    mt={1.5}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={0}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "#222222" }}
                      >
                        Remark
                      </Typography>
                      <IconButton
                        aria-label="edit"
                        sx={{
                          color: "#009FF5",
                          width: 32,
                          height: 32,
                          backgroundColor: "#F5FBFE",
                          borderRadius: "8px",
                          border: "none",
                          "&:hover": {
                            backgroundColor: blue[50],
                          },
                        }}
                        onClick={() => {
                          setRemarkEditMode(1);
                          setRemark(currentQuestionSatisfactoryRemark || "");
                        }}
                      >
                        <EditOutlinedIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ color: "#525966" }}
                    >
                      {currentQuestionSatisfactoryRemark}
                    </Typography>
                  </Box>
                ): null}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DraggableImageDialog
        imageSrc={selectedImage}
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
      <SubmitModal
        title="first"
        open={firstModalOpen}
        onClose={handleFirstModalNo}
        onYes={handleFirstModalYes}
      />
      <SubmitModal
        title="second"
        open={secondModalOpen}
        onClose={handleSecondModalNo}
        onYes={handleSecondModalYes}
      />
    </>
  );
}
