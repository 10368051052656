import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoughnutChart from "./ChartAndGraphs/DoughnutChart";
import OfficeTree from "./OfficeTree";

const ChartModal = ({
  open,
  onClose,
  data,
  title,
  treeData,
  allLocations,
  allLocationsReportCountIDs,
  top5LocationsIDs,
  actionItemComplianceLocations,
  completionTimeComplianceLocations,
  disciplineDistributionLocations,
  disciplinesByInstructionLocations,
  top5DisciplinesLocations,
  top5ExecutivesLocations,
  top10EmployeesLocations,
  tourCompletionLocations,
  imDelays
}) => {

  //This is for setting the location ids with particular card
  const [getLocationIDs, setGetLocationIDs] = useState([]);

  useEffect(() => {
    // Define a mapping function that selects data based on the heading
    const selectDataByHeading = (title) => {
      switch (title) {
        case "Top 5 Tour Locations":
          return top5LocationsIDs;
        case " All Tour Locations":
          return allLocationsReportCountIDs;
        case "Recommendations Action Status":
          return actionItemComplianceLocations;
        case "Ontime Compliance":
          return completionTimeComplianceLocations;
        case "Executive By Tours":
          return top5ExecutivesLocations;
        case "Disciplines By Instructions":
          return disciplinesByInstructionLocations;
        case "IM By Completions":
          return top10EmployeesLocations;
        case "IM By Delays":
          return imDelays;
        case "Top 5 Disciplines":
          return top5DisciplinesLocations;
        default:
          return [];
      }
    };
    const ids = [...new Set(selectDataByHeading(title))].sort((a,b) => a-b);

    setGetLocationIDs(ids);
  }, [
    title,
    top5LocationsIDs,
    allLocationsReportCountIDs,
    actionItemComplianceLocations,
    completionTimeComplianceLocations,
    top5ExecutivesLocations,
    disciplinesByInstructionLocations,
    top10EmployeesLocations,
    top5DisciplinesLocations,
    imDelays
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          padding: 0, // Removes padding from the root paper element
          backgroundColor: "#F1F0F1", // Set background color
          minWidth: "80%",
        },
      }}
    >
      <DialogTitle sx={{ padding: 1 }}>
        {/* {title} */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
            color: "#000000",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                backgroundColor: "#FFFFFF",
                borderRadius: "12px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DoughnutChart data={data} title={title} view={1} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                backgroundColor: "#FFFFFF",
                borderRadius: '12px',
                width: '100%',
                height: '60vh',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#1E88E5 #fff',
                '&::-webkit-scrollbar': {
                    width: '4px', 
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#fff', 
                },
            }}
            >
              <OfficeTree data={treeData} allLocations = {allLocations} getLocationIDs={getLocationIDs}/>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent> 
    </Dialog>
  );
};

export default ChartModal;
