import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignTicketDialog from "./AssignTicketDialog";
import { getMessages } from "../../Apis/apiCall";


const ChatBox = ({ flow, isOpen }) => {
  
  const [messages, setMessages] = useState([
    // { sender: 'Ajay', text: 'perfect! ✅', isSender: false },
    // { sender: 'Me', text: 'How are you?', isSender: true },
    // { sender: 'Ajay', text: "I'll be there in 2 mins ⏰", isSender: false },
    // { sender: 'Me', text: 'Haha oh man', isSender: true },
    // { sender: 'Ajay', text: 'aww', isSender: false },
  ]);

  const [newMessage, setNewMessage] = useState("");
  const [closeTicketMode, setCloseTicketMode] = useState(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  
  const [ticketID, setTicketID] = useState(14);

  const senderID = 121; // Replace with the actual sender ID
  const receiverID = 124; // Replace with the actual receiver ID

  const socket = io("http://13.232.214.184:5001"); // Replace with your server URL
  
  // Join a chat between two users (example IDs, replace as needed)
  useEffect(() => {
    
    socket.emit("join_chat", { ticketID, senderID, receiverID });
    // console.log("join_chat",socket.emit("join_chat", { senderID, receiverID }));

    // Listen for incoming messages
    socket.on("receive_message", (data) => {
      console.log("onDATA",data);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: data.senderID === senderID ? "Me" : "Ajay", text: data.message, isSender: data.senderID === senderID },
      ]);
    });

    // Cleanup on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSend = (socket) => {
    if (newMessage.trim()) {
      const timestamp = new Date().toISOString();

      socket.emit("send_message", { ticketID: ticketID, senderID: senderID, receiverID: receiverID, message: newMessage });
      // console.log("emitt",socket.emit("send_message", { senderID, receiverID, message: newMessage, timestamp }));

      // Update local messages
      // setMessages([...messages, { sender: "Me", text: newMessage, isSender: true }]);
      setNewMessage("");
    }
  };

  const fetchMessages = async (ticketID) => {
    try {
      const result = await getMessages(ticketID);
      console.log(result.data.data);
      const data = result.data.data;
      const updatedMessages = data?.map((item) => ({
        sender: item.senderID === senderID ? "Me" : "Ajay",
        text: item.message,
        isSender: item.senderID === senderID,
      }));
      
      setMessages((prevMessages) => [...prevMessages, ...updatedMessages]);

    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{
    fetchMessages(ticketID);
  },[])
  console.log("messsssages",messages);
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: "14px",
          right: "16px",
          width: "400px",
          height: "660px",
          border: "1px solid #E7E7E7",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.25)",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid #ddd",
            backgroundColor: "#ffffff",
          }}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              src="https://via.placeholder.com/40"
              alt="Ajay"
              sx={{ width: 40, height: 40 }}
            />
            <Box ml={2}>
              <Typography variant="subtitle1" fontWeight="bold">
                Ajay
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
                <CircleIcon sx={{ color: "#68D391", fontSize: "12px" }} />
                <Typography variant="body2" sx={{ color: "#68D391" }}>
                  Online
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            {flow === 3 && !closeTicketMode && (
              <Button
                variant="contained"
                size="small"
                sx={{ borderRadius: "4px", backgroundColor: "#09A2E3", color: "white" }}
                onClick={() => setIsReassignModalOpen(true)}
              >
                Reassign
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              sx={{ borderRadius: "4px", backgroundColor: "#09A2E3", color: "white" }}
              onClick={() => setCloseTicketMode(true)}
            >
              {closeTicketMode ? "Closed" : "Close Ticket"}
            </Button>
            {flow === 3 && (
              <IconButton onClick={() => isOpen(false)}>
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {/* Messages */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: "10px",
            backgroundColor: "#ffffff",
          }}
        >
          {messages.map((message, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: message.isSender ? "flex-end" : "flex-start",
                marginBottom: "10px",
              }}
            >
              {!message.isSender && (
                <Avatar
                  src="https://via.placeholder.com/40"
                  alt="Ajay"
                  sx={{ width: 30, height: 30, marginRight: "8px" }}
                />
              )}
              <Box
                sx={{
                  backgroundColor: message.isSender ? "#09A2E3" : "#F1F1F1",
                  color: message.isSender ? "white" : "black",
                  borderRadius: "12px",
                  padding: "8px 12px",
                  maxWidth: "70%",
                }}
              >
                {message.text}
              </Box>
            </Box>
          ))}
        </Box>

        {/* Message Input */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderTop: "1px solid #ddd",
          }}
        >
          <IconButton>
            <AttachmentIcon />
          </IconButton>
          <TextField
            fullWidth
            size="small"
            placeholder="Type a message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            sx={{
              ml: "8px",
              borderRadius: "12px",
              border: "2px solid #E2E8F0",
            }}
          />
          <IconButton onClick={()=>handleSend(socket)}>
            <SendIcon sx={{ color: "#09A2E3" }} />
          </IconButton>
        </Box>
      </Box>
      <AssignTicketDialog open={isReassignModalOpen} onClose={() => setIsReassignModalOpen(false)} />
    </>
  );
};

export default ChatBox;
