import {
    CHANGE_INPUT_VALUE,
    CHANGE_EXECUTIVE_COMMENT,
    CHANGE_EXECUTIVE_EDIT
  } from "./constant";
  
  export const changeInputValue = ({fieldName, value }) => ({
    type: CHANGE_INPUT_VALUE,
    payload: {fieldName, value }
  });
 
  export const changeExecutiveComment = ({quesId, comment}) => ({
    type: CHANGE_EXECUTIVE_COMMENT,
    payload: {quesId, comment }
  });
 
  export const changeExecutiveEdit = ({id, observation, recommendation, targetCompletionDate, evidence}) => ({
    type: CHANGE_EXECUTIVE_EDIT,
    payload: {id, observation, recommendation, targetCompletionDate, evidence}
  });