import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export default function CustomCheckCard ({ title, subtitle, assigned, showIcon, iconBgColor, onClick }) {
  return (
    <Box
        onClick={onClick}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: "8px",
        p: 1,
        width: "30%",
        height: "100%",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={0}
        alignItems="center"
      >
        <Typography variant="body2" sx={{ color: "#525966", textAlign:'center' }}>
          {title}
        </Typography>
        {assigned && (<Typography sx={{ color: "#2196F3", fontSize: "10px" }}>
          ({assigned} {subtitle})
        </Typography>)}
      </Box>
      {showIcon && (
        <Box
          sx={{
            position: "absolute",
            top: -8,
            right: -8,
            width: 24,
            height: 24,
            borderRadius: "50%",
            backgroundColor: iconBgColor || "#1ABC9C",
          }}
        >
          <CheckIcon sx={{ color: "#fff" }} />
        </Box>
      )}
    </Box>
  );
};
