import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Box, Grid, AccordionSummary, Accordion, AccordionDetails, Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { dashboardBroadCast, getLandingPageStats } from "../Apis/apiCall";
import WelcomeUserCardTwo from "../Components/welcomeUserCardTwo";
import SafetyTipSlider from "../Components/AlertSliderShow";
import { task2, checkList } from "../constant";
import BroadcastSlideshowTwo from "../Components/BroadCastInfoSlideTwo";
import { getUserAssignedLocations } from "../Apis/apiCall";
import { backArrow, forwordArrow } from "../constant";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowBack } from "@mui/icons-material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const LandingDashboard = () => {
  const [boardCasteData, setBoardCasteData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [role, setRole] = useState(null);
  const [userName, setUserName] = useState(null);
  const [step, setStep] = useState(0);

  const [dueSelectedModule, setDueSelectedModule] = useState(null);
  const [overdueSelectedModule, setOverdueSelectedModule] = useState(null);

  const loginDataString = localStorage.getItem("loginData");
  const orgConfigData = JSON.parse(localStorage.getItem("loginData"));

  const loginData = loginDataString ? JSON.parse(loginDataString) : null;
  // console.log("loginData", loginData);

  useEffect(() => {
    fetchBroadCasteData();
    const roles = localStorage.getItem("lossControlRoles");
    setRole(roles);
    if (loginDataString) {
      const data = JSON.parse(loginDataString);
      const { firstName } = data;
      setUserName(firstName);
    }
  }, []);

  const fetchBroadCasteData = async () => {
    try {
      const result = await dashboardBroadCast();
      setBoardCasteData(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const IncidentRoles = JSON.parse(
    localStorage.getItem("IncidentRoles") || "[]"
  );

  const LCRoles = JSON.parse(localStorage.getItem("lossControlRoles") || "[]");

  const Item = styled(Paper)(({ theme }) => ({
    borderRadius: "8px",
    background: "#FFF",
  }));

  const handleStepchange = (value) => {
    setStep(value);
  };

  const contentDataTwo = [
    { text: "Report Control", img: task2 },
    { text: "Audit Management", img: task2 },
    { text: "Risk Management", img: task2 },
    { text: "Environment Management", img: task2 },
    { text: "Task Management", img: task2 },
    { text: "Incident Management", img: task2 },
  ];


  const [data, setData] = useState(null);
  
  useEffect(() => {
    fetchAssignedLocations();
    fatchLandingPageStatus();
  }, []);
  
  const fatchLandingPageStatus = async () => {
    try{
      const response = await getLandingPageStats();
      setData(response.data.data[0]);
      // console.log("response.data",response.data.data[0]);
    }catch(err){
      console.error(err)
    }
  };
    const { totalDueAction, dueAction, overdueAction, totalOverDueAction } = data ? data : 0;
    // console.log("dataaaaaaaaaa", data);
  
  
  const fetchAssignedLocations = async () => {
    try {
      const res = await getUserAssignedLocations();
      const assignedLocations = res.data.data?.roles.map((role) => ({
        orgLevelID: role.orgLevelID,
        locationName: role.locationName,
      }));
      setLocations(assignedLocations);
    } catch (error) {
      console.error("Error while fetching locations", error);
    }
  };

  const dynamicMenuItems =
    orgConfigData?.config?.modulesDetail?.map((module) => ({
      text: module.moduleName,
      path: `/module/${module.moduleName.toLowerCase().replace(/\s+/g, "-")}`,
    })) || [];


  const [isDueFlipped, setIsDueFlipped] = useState(false);
  const [isOverDueFlipped, setIsOverDueFlipped] = useState(false);

  const handleDueFlip = () => {
    setIsDueFlipped((prev) => !prev);
  };
  const handleOverDueFlip = () => {
    setIsOverDueFlipped((prev) => !prev);
  };

  function camelCaseToNormal(text) {
    const normalText = text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
    return normalText;
  }

  const extractKeys = (actions) => {
    return Object.keys(actions)
      .filter(key => key !== 'total') // Exclude 'total'
      .map(camelCaseToNormal); // Convert each key to normal text
  };
  const extractCamelKeys = (actions) => {
    return Object.keys(actions)
      .filter(key => key !== 'total') // Exclude 'total'
  };

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {/* Welcome & Weather Section */}
        <Grid item xs={12}>
          <Box display='flex' flexDirection='row' alignItems="center" justifyContent="space-between">
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Welcome {userName || "Jaydon Frankie"}
            </Typography>
            <WelcomeUserCardTwo />
          </Box>
        </Grid>

        {/* End  Weather Section*/}

        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            {/* Broadcast Section */}
            <Grid item xs={12} md={8}>
            <Box 
                sx={{
                  border: "2px solid #CCC",
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  width: "100%",
                  height: "340px", // Fixed height
                  padding: "16px", // Add padding for aesthetic
                  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)"
                }}
              >
                <BroadcastSlideshowTwo broadcasts={boardCasteData?.broadcasts} />
              </Box>
            </Grid>
            {/* End Broadcast Section */}

            {/* Roles Section */}
            <Grid item xs={12} md={4}>
              <Box 
                sx={{
                  border: "2px solid #CCC",
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  width: "100%",
                  height: "340px", // Fixed height
                  overflowY: "auto", // Enable vertical scrolling
                  padding: "16px", // Add padding for aesthetic
                  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)"
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "700", mb: 1.5 }}>
                  Roles
                </Typography>

                <Box display='flex' flexDirection='column' gap={1}>

                <Accordion
                  sx={{
                    boxShadow: 'none',
                    backgroundColor: '#E7F6FD',
                    padding: '0px !important', borderRadius:"8px!important",
                    '&:before': { display: 'none' }, // Removes the default border
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#007BFF', mr: 1 }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      padding: '0px', // Reduced padding for a smaller height
                      '& .MuiAccordionSummary-content': {
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#009FF5',
                        fontWeight: 600,
                        textAlign: 'center',
                      }}
                    >
                      Incident Management
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '16px', backgroundColor: '#F5F8FF' }}>
                  {IncidentRoles.map((roles, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color:'#808080',
                          fontSize: '12px',
                          fontWeight: '600',
                          mb: 1
                        }}
                      >
                      {roles}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    backgroundColor: '#E7F6FD',
                    padding: '0px !important', borderRadius:"8px!important",
                    '&:before': { display: 'none' }, // Removes the default border
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#007BFF', mr: 1 }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      padding: '0px', // Reduced padding for a smaller height
                      '& .MuiAccordionSummary-content': {
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#009FF5',
                        fontWeight: 600,
                        textAlign: 'center',
                      }}
                    >
                      Loss Control
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '16px', backgroundColor: '#F5F8FF' }}>
                  {LCRoles.map((roles, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color:'#808080',
                          fontSize: '12px',
                          fontWeight: '600',
                          mb: 1
                        }}
                      >
                      {roles}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
                </Box>
              </Box>
            </Grid>
            {/* Roles Section End  */}

            {/* Module section */}

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: 'relative',
                  width: "100%",
                  height: "220px",
                  borderRadius: "8px",
                  border: "2px solid #CCC",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
                  padding: "16px",
                }}
              >
                  <Typography sx={{ fontSize: "18px", fontWeight: "700", mb: 1.5 }}>
                    Modules
                  </Typography>
                  <Box
                    gap={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom={1}
                    sx={{ zIndex: "1300", marginTop: "10px", position: 'absolute', right: '16px', top: '12px' }}
                  >
                    <FiberManualRecordIcon
                      onClick={() => handleStepchange(0)}
                      sx={{
                        color: step === 0 ? "#0AA2E3" : "#AEAEB1",
                        fontSize: "18px",
                      }}
                    />

                    {/* <FiberManualRecordIcon
                      onClick={() => handleStepchange(1)}
                      sx={{
                        color: step === 1 ? "#0AA2E3" : "#AEAEB1",
                        fontSize: "18px",
                      }}
                    /> */}
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{ border: "none", background: "none" }}
                  >
                    {/* step 0 start */}
                    {step === 0 &&
                      dynamicMenuItems.map((item, index) => (
                        <Grid
                          item
                          xs={2}
                          sm={4}
                          md={4}
                          key={index.text}
                          sx={{
                            border: "none",
                            background: "none",
                            fontSize: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              border: "none",
                              background: "none",
                              boxShadow: "none",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={checkList}
                              alt="icon"
                              style={{
                                width: "24px",
                                height: "auto",
                                borderRadius: "0px",
                              }}
                            />
                            <Typography
                              sx={{
                                marginTop: "4px",
                                fontWeight: "600",
                                fontSize: '12px',
                              }}
                            >
                              <Link
                                to={item.path}
                                style={{ textDecoration: "none", color: "inherit" }}
                              >
                                {item.text}
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                      ))}

                    {/* step 0 end */}

                    {/* {step === 1 &&
                      contentDataTwo.map((item, index) => (
                        <Grid
                          item
                          xs={2}
                          sm={4}
                          md={4}
                          key={index}
                          sx={{
                            border: "none",
                            background: "none",
                            fontSize: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              border: "none",
                              background: "none",
                              boxShadow: "none",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={checkList}
                              alt='Icon'
                              style={{
                                width: "24px",
                                height: "auto",
                                borderRadius: "0px",
                              }}
                            />
                            <Typography
                              sx={{
                                marginTop: "4px",
                                fontWeight: "600",
                                fontSize: '12px',
                              }}
                            >
                              <Link
                                to={item.path}
                                style={{ textDecoration: "none", color: "inherit" }}
                              >
                                {item.text}
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                      ))} */}
                  </Grid>
              </Box>
            </Grid>
            {/* Module section end */}

            {/* Due Action */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    onClick={() => {
                      if (!isDueFlipped) {
                        handleDueFlip();
                      }
                    }}
                    sx={{
                      perspective: "1000px", // Perspective for 3D effect
                      cursor: 'pointer'
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "220px",
                        transformStyle: "preserve-3d",
                        transform: isDueFlipped ? "rotateY(180deg)" : "none",
                        transition: "transform 0.6s",
                      }}
                    >
                      {/* Front of the card */}
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "221px",
                          backfaceVisibility: "hidden",
                          borderRadius: "8px",
                          border: "2px solid #CCC",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
                          padding: "16px",
                        }}
                      >
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                          Actions Due
                        </Typography>
                        <Box display="flex" justifyContent="center">
                          <Typography
                            sx={{
                              fontSize: "110px",
                              fontWeight: "700",
                              color: "#B4E6FD",
                            }}
                          >
                            {totalDueAction}
                          </Typography>
                        </Box>
                      </Box>

                      {/* Back of the card */}
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "220px",
                          backfaceVisibility: "hidden",
                          transform: "rotateY(180deg)",
                          borderRadius: "8px",
                          border: "2px solid #CCC",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
                          padding: "16px",
                        }}
                      >
                        <Typography sx={{ fontSize: "18px", fontWeight: "700", mb: 2 }} onClick={()=>handleDueFlip()}>
                          Actions Due
                        </Typography>
                        
                        {dueSelectedModule === null ? 
                          [{name : "Loss Control", count: dueAction?.lossControlActions?.total}, 
                          {name : "Incident Management", count: dueAction?.incidentActions?.total}, 
                          {name : "Audit Management", count: dueAction?.auditManagementActions?.total}, 
                          {name : "Environment Management", count: dueAction?.environmentManagementActions?.total}]
                        .map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "4px", // Spacing between boxes
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                                '&:hover': {
                                  color: '#000000', // Secondary button hover color
                                }
                              }}
                              onClick={()=>setDueSelectedModule(item.name)}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {item.count}
                            </Typography>
                          </Box>
                        )) : (
                          <>
                          <Box display='flex' alignItems='center' gap={1} sx={{marginBottom:'6px'}} onClick={()=>(setDueSelectedModule(null))}>
                            <ArrowBack fontSize="14px" sx={{ color: "#8C8C8C", '&:hover': {color: '#000000'} }} />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#8C8C8C",
                              }}
                            >
                              {dueSelectedModule}
                            </Typography>
                          </Box>

                          {dueSelectedModule === "Incident Management" ? extractCamelKeys(data.dueAction.incidentActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data.dueAction.incidentActions[roles]} 
                            </Typography>
                            </Box>
                          )) : dueSelectedModule === "Loss Control" ? extractCamelKeys(data.dueAction.lossControlActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data.dueAction.lossControlActions[roles]} 
                            </Typography>
                            </Box>
                          )) : dueSelectedModule === "Audit Management Actions" ? extractCamelKeys(data.dueAction.auditManagementActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data.dueAction.auditManagementActions[roles]} 
                            </Typography>
                            </Box>
                          )) : dueSelectedModule === "Environment Management Actions" ? extractCamelKeys(data.dueAction.environmentManagementActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data.dueAction.environmentManagementActions[roles]} 
                            </Typography>
                            </Box>
                          )) : null}
                          </>
                        )}
                        
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    onClick={() => {
                      if (!isOverDueFlipped) {
                        handleOverDueFlip();
                      }
                    }}
                    sx={{
                      perspective: "1000px", // Perspective for 3D effect
                      cursor: 'pointer'
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "221px",
                        transformStyle: "preserve-3d",
                        transform: isOverDueFlipped ? "rotateY(180deg)" : "none",
                        transition: "transform 0.6s",
                      }}
                    >
                      {/* Front of the card */}
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backfaceVisibility: "hidden",
                          borderRadius: "8px",
                          border: "2px solid #CCC",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
                          padding: "16px",
                        }}
                      >
                        <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                          Actions Overdue
                        </Typography>
                        <Box display="flex" justifyContent="center">
                          <Typography
                            sx={{
                              fontSize: "110px",
                              fontWeight: "700",
                              color: "#FDB4B4",
                            }}
                          >
                            {totalOverDueAction}
                          </Typography>
                        </Box>
                      </Box>

                      {/* Back of the card */}
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backfaceVisibility: "hidden",
                          transform: "rotateY(180deg)",
                          borderRadius: "8px",
                          border: "2px solid #CCC",
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
                          padding: "16px",
                        }}
                      >
                        <Typography sx={{ fontSize: "18px", fontWeight: "700", mb: 2 }} onClick={()=>handleOverDueFlip()}>
                          Actions Overdue
                        </Typography>
                        {overdueSelectedModule === null ? 
                          [{name : "Loss Control", count: overdueAction?.lossControlActions?.total}, 
                          {name : "Incident Management", count: overdueAction?.incidentActions?.total}, 
                          {name : "Audit Management", count: overdueAction?.auditManagementActions?.total}, 
                          {name : "Environment Management", count: overdueAction?.environmentManagementActions?.total}]
                        .map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "4px", // Spacing between boxes
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                                '&:hover': {
                                  color: '#000000', // Secondary button hover color
                                }
                              }}
                              onClick={()=>setOverdueSelectedModule(item.name)}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {item.count}
                            </Typography>
                          </Box>
                        )) : (
                          <>
                          <Box display='flex' alignItems='center' gap={1} sx={{marginBottom:'6px'}} onClick={()=>(setOverdueSelectedModule(null))}>
                            <ArrowBack fontSize="14px" sx={{ color: "#8C8C8C", '&:hover': {color: '#000000'} }} />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#8C8C8C",
                              }}
                            >
                              {overdueSelectedModule}
                            </Typography>
                          </Box>

                          {overdueSelectedModule === "Incident Management" ? extractCamelKeys(data?.overdueAction?.incidentActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data?.overdueAction?.incidentActions[roles]} 
                            </Typography>
                            </Box>
                          )) : overdueSelectedModule === "Loss Control" ? extractCamelKeys(data?.overdueAction?.lossControlActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data?.overdueAction?.lossControlActions[roles]} 
                            </Typography>
                            </Box>
                          )) : overdueSelectedModule === "Audit Management Actions" ? extractCamelKeys(data?.overdueAction?.auditManagementActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data?.overdueAction?.auditManagementActions[roles]} 
                            </Typography>
                            </Box>
                          )) : overdueSelectedModule === "Environment Management Actions" ? extractCamelKeys(data?.overdueAction?.environmentManagementActions)?.map((roles, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "4px", // Spacing between boxes
                              }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#8C8C8C", // Gray color for the text
                              }}
                            >
                              {camelCaseToNormal(roles)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000", // Black color for the number
                              }}
                            >
                              {data?.overdueAction?.environmentManagementActions[roles]} 
                            </Typography>
                            </Box>
                          )) : null}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>

              </Grid>

            </Grid>
            {/* Due Action end */}

          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            {/* SafetyTipSlider Section */}
            <Grid item xs={12}>
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "2px solid #CCC",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
                  padding: "16px",
                }}
              >
                <SafetyTipSlider data={boardCasteData?.safetyTips} />
              </Box>
            </Grid>
            {/* SafetyTipSlider Section  end*/}

            {/* Location Section  start*/}
            <Grid item xs={12}>
              <Box
                // sx={{ minHeight: " 380px", marginTop: "-70%", overflowY: "auto" }}
                sx={{
                  borderRadius: "8px",
                  border: "2px solid #CCC",
                  backgroundColor: "#ffffff",
                  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.25)",
                  padding: "16px",
                  minHeight: "434px", // Fixed height
                  overflowY: "auto", // Enable vertical scrolling
                  padding: "16px", // Add padding for aesthetic
                  marginTop: 0, // Default for mobile
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                    Locations
                  </Typography>
                <Typography
                  sx={{
                    color: "#8C8C8C",
                    fontSize: "14px",
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 2
                  }}
                >
                  Default Location
                </Typography>
                <Chip
                  label={loginData?.currentWorkLocation}
                  variant="outlined"
                  sx={{
                    padding: "12px 26px",
                    color: "#009FF5",
                    border: "none",
                    fontWeight: "bold",
                    background: "#E7F6FD",
                    borderRadius: "24px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  sx={{
                    color: "#8C8C8C",
                    fontSize: "14px",
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 1
                  }}
                >
                  All Locations
                </Typography>
                    {locations.map((location, index) => (
                      <Chip
                        key={index}
                        label={location.locationName}
                        variant="outlined"
                        sx={{
                          padding: "12px 26px",
                          color: "#666",
                          border: "none",
                          fontWeight: "bold",
                          background: "#EDEDED",
                          borderRadius: "24px",
                          margin: "10px 5px",
                        }}
                      />
                    ))}
              </Box>
            </Grid>

            {/* Location Section  end*/}
          </Grid>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'absolute',
          right: '60px',
          bottom: '26px',
          fontSize: "17px",
          backgroundColor: '#09A2E3',
          color: '#fff',
          padding: '8px 20px',
          fontWeight: '500',
          borderRadius: '50px',
          boxShadow: '0px 0px 15px 4px #09A2E3',
          textTransform: "none",
          '&:hover': {
            backgroundColor: '#078FCC', // Slightly darker shade for hover effect
          },
        }}
        component={Link}
        to="/home"
      >
      <ArrowForwardIcon />
      </Button>
    </>
  );
};

export default LandingDashboard;
