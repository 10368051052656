import React, { useState, useEffect } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomInput from "../CustomInputField";
import dayjs from 'dayjs';
import { changeExecutiveEdit } from '../../redux/LossWorkFlow/actions';
import Compressor from 'compressorjs';

export default function ExecutiveEditDrawer({ open, onClose, title, quesData, tourDate }) {
  const dispatch = useDispatch();
  const executiveEditData = useSelector(
    state => state.lossWorkFlowReducer.executiveEditData?.find(item => item.id === quesData.id)
  );
//   console.log("quesData",quesData);
//   console.log("executiveEditData",executiveEditData);

  // Initialize state with empty values
  const [observation, setObservation] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [targetCompletionDate, setTargetCompletionDate] = useState(null);
  const [imEvidence, setImEvidence] = useState([]);

  // useEffect to set initial values when executiveEditData or quesData changes
  useEffect(() => {
    setObservation(executiveEditData?.observation || quesData?.observation || "");
    setRecommendation(executiveEditData?.recommendation || quesData?.recommendation || "");
    setTargetCompletionDate(executiveEditData?.targetCompletionDate || quesData?.targetCompletionDate || null);
    // setImEvidence(executiveEditData?.evidence || quesData?.evidence || []);
    setImEvidence(executiveEditData?.evidence || quesData?.evidence || []);
  }, [executiveEditData, quesData]);

  // console.log("executiveEditData",executiveEditData);
  // console.log("quesData",quesData);

  const handleDateChange = (newValue) => {
    // Set date back to epoch format on change
    setTargetCompletionDate(newValue ? newValue.valueOf() : null);
  };

  const handleEvidenceUpload = async (e) => {
    const files = Array.from(e.target.files);

    const newEvidence = await Promise.all(
      files.map(async (file) => {
        const base64 = await convertToBase64(file);
        return { name: file.name, type: file.type, base64 };
      })
    );

    setImEvidence((prevEvidence) => [...prevEvidence, ...newEvidence]);
  };

  const handleFileDelete = (index) => {
    const updatedEvidence = [...imEvidence];
    updatedEvidence.splice(index, 1);
    setImEvidence(updatedEvidence);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      // Compress the file before converting it to base64
      new Compressor(file, {
        quality: 0.2, // Set the compression quality
        success(compressedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  const handleSubmit = () => {
    const updatedData = {
      id: quesData.id,
      observation,
      recommendation,
      targetCompletionDate,
      evidence: imEvidence,
    };
    dispatch(changeExecutiveEdit(updatedData));
    onClose();
  };

//   console.log("imEvidence",imEvidence);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "36%", padding: "0px !important" } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          {title || ""}
        </Typography>
      </Grid>
      <Box p={2}>
        <Box my={2} p={3} border="1px solid #ddd" sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }} borderRadius={4}>
          <Box mb={2}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {(quesData?.question || "").replace(/_/g, " ")}
            </Typography>
            <Box mb={2}>
            <CustomInput
              placeholder="type here..."
              label="Observation"
              multiline
              rows={2}
              value={observation}
              onChange={(e) => setObservation(e.target.value)}
            />
            </Box> 
            <Box mb={2}>
            <CustomInput
              placeholder="type here..."
              label="Recommendation"
              multiline
              rows={2}
              value={recommendation}
              onChange={(e) => setRecommendation(e.target.value)}
            />
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Target Completion Date"
                    value={targetCompletionDate ? dayjs(targetCompletionDate) : null}
                    onChange={(newValue) => handleDateChange(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    format ="DD-MM-YYYY"
                    minDate={dayjs(tourDate)}
                />
            </LocalizationProvider>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography gutterBottom sx={{ fontSize: "14px", fontWeight: "600" }}>Evidence (Required)</Typography>
            <Box sx={{
        display: "flex", flexDirection: "column", alignItems: "center",
        border: "1px dashed #09A2E3", padding: 2, borderRadius: 1, textAlign: "center", width: "100%"
      }}>
        <IconButton component="label">
          <UploadIcon fontSize="large" sx={{ color: "#000000" }} />
          <input hidden accept="image/*,.doc,.pdf,.mp4" type="file" onChange={handleEvidenceUpload} multiple />
        </IconButton>
        <Typography variant="caption" sx={{ mt: 1, color: "#348BD2", fontWeight: "600", textDecoration: "underline" }}>
          Click to upload
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: "600" }}>
          PNG, JPG, Docx, PDF, and MP4 (max. 10 MB)
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2, gap: 1 }}>
        {imEvidence.map((file, index) => (
          <Box key={index} sx={{ position: "relative", width: 80, height: 80 }}>
            <IconButton onClick={() => handleFileDelete(index)} sx={{ position: "absolute", top: -8, right: -8, zIndex: 2, backgroundColor: "white" }} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box sx={{ width: "100%", height: "100%", borderRadius: 2, overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #ccc" }}>
              {typeof file === "string" ? (
                <img src={file} alt={`evidence-${index}`} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
              ) : (
                file.type && file.type.startsWith("image/") && (
                  <img src={file.base64} alt={file.name} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                )
              )}
            </Box>
          </Box>
        ))}
      </Box>

          </Box>
        </Box>
        <Grid item xs={12} mt={1}>
          <Button variant="contained" onClick={handleSubmit} sx={{ float: "right", color: '#ffffff' }}>Update</Button>
        </Grid>
      </Box>
      <Fab aria-label="close" onClick={onClose} sx={{
        position: "fixed", top: "100px", right: open ? "37.3%" : "0", transform: "translateX(50%)",
        zIndex: 1200, height: "36px", width: "36px", backgroundColor: "#0AA2E3", borderRadius: "60px 0 0 60px"
      }}>
        <CloseIcon />
      </Fab>
    </Drawer>
  );
}
