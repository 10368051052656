import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, Box, IconButton, Typography } from '@mui/material';
import DraggableMediaDialog from './DraggableImageDialog';
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

export default function CustomHelpManualCard({
  mediaSrc,
  mediaType = 'image', // 'image' or 'video'
  role,
  title,
  description,
  titleColor = '#525966',
  titleFontSize = '16px',
  titleFontWeight = '600',
  descriptionColor = '#525966',
  descriptionFontSize = '12px',
  descriptionFontWeight = '400',
  cardStyles = {},
  mediaStyles = { height: 160 },
  onClick,
}) {

    const [openMediaModel, setOpenMediaModel] = useState(false);
    const [mediaUrl, setMediaUrl] = useState(null);
    const [text, setText] = useState(null);

    const handleClickImage = (image) => {
        setMediaUrl(image);
        // setText(text);
        setOpenMediaModel(true);
    };

    const handleCloseDialog = () => {
        setOpenMediaModel(false);
        setMediaUrl(null);
        setText(null);
    };
  return (
    <Card
      sx={{
        borderRadius: '8px',
        backgroundColor: '#FFF',
        boxShadow: '0px 2.253px 2.253px 0px rgba(0, 0, 0, 0.16)',
        padding: '12px',
        textAlign: 'left',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        },
        ...cardStyles,
      }}
      onClick={onClick}
    >
      {mediaType === 'image' ? (
        <CardMedia
          component="img"
          image={mediaSrc}
          alt={title}
          sx={{ ...mediaStyles }}
        />
      ) : (
        <CardMedia sx={{ ...mediaStyles }}>
        <div style={{ position: 'relative', width: '100%'}} onClick={() => handleClickImage(mediaSrc)}>
        <video
            src={mediaSrc}
            //poster={currentBroadcast.posterUrl} // Add poster URL here
            // controls
            style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            // borderRadius: '8px',
            }}
            onClick={() => handleClickImage(mediaSrc)}
        />
          {/* Custom Play Button Overlay */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // semi-transparent white background
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5v14l11-7L8 5z" fill="#595959" /> {/* Set the play icon color */}
            </svg>
          </div>
        </div>
        </CardMedia>
      )}
      <CardContent sx={{ padding: '0px !important', mt: '12px' }}>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
        <Typography
          sx={{
            color: titleColor,
            fontSize: titleFontSize,
            fontWeight: titleFontWeight,
          }}
        >
          {title}
        </Typography>
        {(mediaType === 'video' && role === 'admin') && (
            <IconButton>
              <ModeEditOutlineOutlinedIcon
            //   onClick={handleEditLocation}
                fontSize="small"
                sx={{ color: "rgba(82, 89, 102, 1)" }}
              />
            </IconButton>
        )}
        </Box>
        <Typography
          sx={{
            color: descriptionColor,
            fontSize: descriptionFontSize,
            fontWeight: descriptionFontWeight,
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <DraggableMediaDialog
            imageSrc={mediaUrl}
            open={openMediaModel}
            onClose={handleCloseDialog}
            text={text}
        />
    </Card>
  );
}
