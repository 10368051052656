import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Box,
    IconButton,
    Grid, Paper, FormControl, InputLabel, Select, MenuItem,
    Radio
} from '@mui/material';
import { disciplineCover, disciplineIconBG } from '../../constant';
import CategoryIcon from '@mui/icons-material/Category';
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function AuditProtocolDialog({open, onClose}) {

    const cards= [
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
        "H2S Hazard Control (Where Applicable) Applicable / Not Applicable",
    ]
  return (
    <>
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                
                    <Typography sx={{color:'#15294B', fontSize:'20px', fontWeight:'600'}}>Select Audit Protocol</Typography>
                    
                <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{ position: "absolute", right: 8, top: 8 }}
                >
                <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
            <Box display='flex' alignItems='center' gap={2} mb={2}>
                <Typography sx={{color:"#374151", fontSize:'14px', fontWeight:'600'}}>Preconfigured Protocol</Typography>
                <FormControl variant="outlined" sx={{width:'40%'}}>
                    {/* <InputLabel id="dropdown-label">Options</InputLabel> */}
                    <Select
                        labelId="dropdown-label"
                        // value={value}
                        // onChange={handleChange}
                        // label="Options"
                        sx={{
                        height: '32px',
                        display: "flex",
                        alignItems: "center",
                        }}
                    >
                        <MenuItem value={10}>Option 1</MenuItem>
                        <MenuItem value={20}>Option 2</MenuItem>
                        <MenuItem value={30}>Option 3</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{maxHeight:'480px', overflowY:'auto'}}>
            <Grid container spacing={1}>
                {cards?.map((description,index)=>(
                    <Grid item xs={6} sm={3} md={2} key = {index}>
                        <Box
                        //   onClick={() => handleDisciplineSelect(index, name)}
                        sx={{
                            // height: "114px",
                            // width: "927px",
                            position: 'relative',
                            borderRadius: "8px",
                            border: "0.5px solid #B7C8E5",
                            padding: "16px",
                            backgroundImage: `url(${disciplineCover})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "flex-start",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            // boxShadow:
                            //   selectedDiscipline === index &&
                            //   "0px 0px 12px 0px rgba(9, 162, 227, 0.65)",
                            cursor: "pointer",
                        }}
                        >
                        <Box
                            sx={{
                            width: "52px",
                            height: "52px",
                            backgroundImage: `url(${disciplineIconBG})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            }}
                        >
                            <CategoryIcon sx={{color:'#09A2E3'}} />
                        </Box>
                        <Typography
                            sx={{
                            color: "#000000",
                            fontSize: "12px",
                            marginTop: "8px",
                            textAlign: "left",
                            display: "flex",
                            justifyContent: "flex-start",
                            }}
                        >
                            {description}
                        </Typography>
                        <Radio
                            // checked={selectedAgent === agent.id}
                            icon={<RadioButtonUncheckedIcon style={{ fontSize: 24 }} />}
                            checkedIcon={<CheckCircleIcon style={{ color: '#09A2E3', fontSize: 24 }} />}
                            sx={{position:'absolute', top:4, right: 4}}
                            // value={agent.id}
                            // onChange={() => console.log(`Selected agent: ${agent.id}`)} // Replace with your logic
                        />
                        </Box>
                    </Grid>

                ))}
            </Grid>
            </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    sx={{ color: "white", backgroundColor: '#09A2E3', borderRadius:'24px', width:'82px' }}
                    // disabled={!selectedNodes.length}
                    // onClick={onNext}
                >
                    Next
                </Button>
            </DialogActions>
            </Dialog>
      
    </>
  )
}

export default AuditProtocolDialog
