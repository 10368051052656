import React, { useState } from "react";
import { Typography, IconButton, Box, Stepper, Step, StepLabel, StepContent, Button } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const AuditVerticalStepperStatus = () => {
  const [showStepper, setShowStepper] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { label: "Step 1", description: "Step 1 description" },
    { label: "Step 2", description: "Step 2 description" },
    { label: "Step 3", description: "Step 3 description" },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <Typography
        sx={{
          textAlign: "end",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          background: "none",
          marginTop: "-20px",
        }}
        onClick={() => setShowStepper((prev) => !prev)} // Toggle visibility of the stepper
      >
        Overall Status
        <IconButton>
          {showStepper ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Typography>

      {/* {showStepper && ( 
        <Box
          sx={{
            width: 400,
            position: "absolute",
            top: "20%",
            left: "72%",
            zIndex: 10,
            padding: "16px",
            background:'#ccc'
          }}
        >
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                      disabled={index === steps.length - 1}
                    >
                      {index === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      disabled={index === 0}
                    >
                      Back
                    </Button>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length && (
            <Box sx={{ mt: 2 }}>
              <Typography>All steps completed!</Typography>
              <Button variant="contained" onClick={handleReset} sx={{ mt: 2 }}>
                Reset
              </Button>
            </Box>
          )}
        </Box>
      )} */}
    </div>
  );
};

export default AuditVerticalStepperStatus;
