import React from 'react';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@mui/material';
import FRCAPADrawer from './FRCAPADrawer';
import { ChatBubbleOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const data = [
    {
        id: '2024-IM-000498',
        type: 'Preventive',
        area: 'Area2',
        subArea: 'Sub21',
        owner: 'EMP22',
        priority: 'High',
        completionDate: '12-08-24',
        status: 'Review',
      },
      {
        id: '2024-IM-000498',
        type: 'Preventive',
        area: 'Area2',
        subArea: 'Sub21',
        owner: 'EMP22',
        priority: 'High',
        completionDate: '12-08-24',
        status: 'Review',
      },
      {
        id: '2024-IM-000498',
        type: 'Corrective',
        area: 'Area2',
        subArea: 'Sub21',
        owner: 'EMP22',
        priority: 'High',
        completionDate: '12-08-24',
        status: 'Review',
      },
];


export default function CAPADetailTable () {
  
  const [fRCAPADrawerOpen, setFRCAPADrawerOpen] = useState(false);
  const handleFRCAPADrawerOpen = () => {
    setFRCAPADrawerOpen(true);
  };
  const handleFRCAPADrawerClose = () => {
    setFRCAPADrawerOpen(false);
  };

  const [commentsModal, setCommentsModal] = useState(false)

  return (
    <>
    <TableContainer sx={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #ccc' }}>
      <Table>
        <TableHead>
        <TableRow sx={{backgroundColor: '#F2F2F2'}}>
            {['CAPA ID', 'Type', 'Area', 'Sub Area', 'Owner', 'Priority', 'Completion Date', 'Status', ''].map(
              (header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    paddingY:'12px',
                    borderRight: index !== 8 ? '1px solid #e0e0e0' : 'none', // Add border except the last column
                    fontWeight: 'bold',
                    color:'#525966'
                  }}
                >
                  {header}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex} onClick={handleFRCAPADrawerOpen} sx={{cursor:'pointer', "&:hover": {backgroundColor: "rgba(82, 89, 102, 0.05)"}}}>
              {Object.values(row).map((cell, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  sx={{
                    paddingY:'10px',
                    borderRight: cellIndex !== 8 ? '1px solid #e0e0e0' : 'none', // Add border except the last column
                  }}
                >
                  {cell}
                </TableCell>
              ))}
                <TableCell
                  sx={{
                    paddingY:'10px',
                  }}
                >
                <IconButton onClick={()=>setCommentsModal(true)}>
                  <ChatBubbleOutline />
                </IconButton>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      <FRCAPADrawer
        open={fRCAPADrawerOpen}
        onClose={handleFRCAPADrawerClose}
        title={"Review CAPA"}
      />
      <Dialog open={commentsModal} onClose={()=>setCommentsModal(false)} fullWidth maxWidth="md" sx={{maxHeight : '700px'}}>
        <DialogTitle>
          <Typography variant="h6">Comments</Typography>
          <IconButton
            aria-label="close"
            onClick={()=>setCommentsModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        {[1,2,3,4,5].map((item,index)=>(
          <Accordion
              elevation={0} // Removes the shadow from the Accordion (Paper)
              sx={{ mb:1, padding: '0px !important', border: '0.5px solid #B7C8E5', borderRadius:"8px!important"}}
            >
              {/* Collapsed View */}
              <AccordionSummary
                // expandIcon={} // Dynamically set icon based on status
                // aria-controls={`panel${index}-content`}
                // id={`panel${index}-header`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#F9FAFC',
                  borderRadius: '8px',
                  paddingX: '12px',
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'none !important', // Prevents the wrapper rotation
                  },
                }}
              >
                <Box>
                  <Typography variant="body2" sx={{ color: '#222222' }}>
                    Reviewed by first Reviewer
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#525966' }}>
                    EMP 24 / emp24@gmail.com / Emp22 
                  </Typography>
                </Box>
              </AccordionSummary>

              {/* Expanded View */}
              <AccordionDetails
                sx={{
                  backgroundColor: '#f9fafc',
                  borderRadius: '8px',
                  paddingX: '12px',
                }}
              >

                {/* Remarks Section */}
               
                  <Box display="flex" flexDirection="column">
                    <Typography sx={{ fontSize:'12px',color: '#222222', mb: 1 }}>
                      Review Input
                    </Typography>
                    <Typography sx={{ fontSize:'12px', color: '#525966' }}>
                    Lorem ipsum dolor sit amet consectetur. Sit lorem proin enim pretium nisi quis. Lorem ipsum dolor sit amet consectetur. Sit lorem proin enim pretium nisi quis. 
                    </Typography>
                  </Box>
              
              </AccordionDetails>
            </Accordion>
        ))}
        </DialogContent>
      </Dialog>
    </>
  );
};
