import React from 'react';
import { Modal, Box, Typography, Button, Stack, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmationModal = ({ open, title, message, onCancel, onConfirm }) => {
  return (
    <Modal open={open} onClose={onCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          pt: 3,
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" component="h2" mb={2}>
          {title}
        </Typography>
        <Typography mb={3}>{message}</Typography>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button onClick={onCancel} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary" variant="contained" sx={{ color: 'white' }}>
            Confirm
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
