import React from "react";
import { useState, useEffect } from "react";
import { Grid, Typography, Box, Chip, Modal, Divider, Button, Avatar, Tabs, Tab } from "@mui/material";
import CustomToggleButtonGroup from "../Components/CustomToggleButtonGroup";
import CustomStepperBox from "../Components/CustomStepperBox";
import IncidentViewTabSection from "../Components/IncidentViewTab";
import CustomWorkflowAccordion from "../Components/CustomWorkflowAccordion";
import RightDrawer from "../Components/RightDrawerWorkFlow";
import CustomCheckCard from "../Components/CustomCheckCard";
import FloatingButton from '../Components/FloatButton';
import { useDispatch, useSelector } from 'react-redux';
import { handleChangeDrawerFormData, setCAPADetails, updateSwitchState, setReviewData } from "../redux/incidentWorkflow/actions";
import { firstReviwerNextDrawerFields, firstReviwerReturnDrawerFields, secondReviwerInvestionReviewNextDrawerFields, firstReviwerReturnInvestionReviewDrawerFields, firstReviwerInvestionReviewNextDrawerFields, assignCAPAFormFields, assignInvestigationFormFields } from "../utils/workFlowFormFields";
import SeverityTag from "../Components/SeverityTag";
import InvestigationCard from '../Components/InvestigationCards';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIncidentViewData, getOverallIncidentStatusTimeline } from '../Apis/apiCall';
import { formatDateToIndianLocale, convertMillisecondsToDate } from '../utils/helper';
import CAPADetailTable from "../Components/CAPADetailTable";
import FRCAPADrawer from "../Components/FRCAPADrawer";
import { setIncidentFormData } from "../redux/incidentReport/actions";




export default function IncidentView() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, customReportId, status, mode, role } = location?.state?.data;
  const dispatch = useDispatch();
  const [levelReviewer, setLevelReviewer] = useState(''); //Can be 1st Viewer Also
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [boxesOpen, setBoxesOpen] = useState(false);
  const [newCapa, setNewCapa] = useState(0);
  const [incidentReportData, setIncidentReportData] = useState([]);
  const [workFlowInputsData, setWorkFlowInputsData] = useState([]);

  console.log("incidentReportData",incidentReportData);

  const [outerTab, setOuterTab] = useState(0);
  const handleOuterChange = (event, newValue) => {
    setOuterTab(newValue);
  };

  const formData = useSelector(state => state.incidentWorkFlowReducer);
  const {
    Return,
    Review,
    CAPA,
    CAPADetail,
    switchStates,
    Assign_Investigation,
  } = formData;
  console.log("getprops", id, status[0], mode, role, newCapa);

  const afterFRInvestigationCompleted = (status[0] === "Investigation Under Approval" || status[0] === "Investigation Approval Resubmission") ? true : false;



  useEffect(() => {
    if ((status[0] === "New Incident Clarification") && role === "Reporting Officer") {
      setLevelReviewer("0");
    }
    if ((status[0] === "New Incident" || status[0] === "New Incident Resubmission" || status[0] === 'Incident Revalidation' || status[0] === "Investigation Completed" || status[0] === "Investigation Resubmission" || status[0] === "Investigation Clarification") && role === "First Reviewer") {
      setLevelReviewer("1");

    }

    if ((status[0] === "Incident Review" || status[0] === "Resubmitted for Review" || status[0] === 'Investigation Validation' || status[0] === "Investigation Completed" || status[0] === "Investigation Approval Resubmission" || status[0] === "Investigation Under Approval") && role === "Second Reviewer") {
      setLevelReviewer("2");

    }
    if ((status[0] === 'Investigation Initiated' || status[0] === "Investigation Reinitiated" || status[0] === "Investigation Query") && role === "Investigator") {
      setLevelReviewer("3");
    }

    if (status[0] === "CAPA Completed" && role === "First Reviewer") {
      setLevelReviewer("4");
    }

    if (status[0] === "CAPA Completed" && role === "Second Reviewer") {
      setLevelReviewer("5");
    }


  }, []);




  useEffect(() => {
    if (id) {
      fetchIncidentAllData(id);
      getWokflowInputData(id);
    }
  }, []);

  const getWokflowInputData = async (incidentId) => {
    try {
      const result = await getOverallIncidentStatusTimeline(incidentId);
      setWorkFlowInputsData(result.data.data);

    } catch (error) {
      console.log(error);
    }
  }

  const fetchIncidentAllData = async (id) => {
    try {
      const result = await getIncidentViewData(id)
      setIncidentReportData({
        ...result.data.data,  // Set all the data properties
        CAPADetail: [
          ...(result.data.data?.CAPADetail || []),  // Add CAPADetail array from the data if available
          ...(result.data.data?.capas || []),
          ...(result.data?.data?.investigationCAPA || [])  // Add investigationCAPA array from the data if available
        ]
      });
      const CAPADetail = result.data.data?.CAPADetail?.map(item => ({
        ...item,
        "Due Date": convertMillisecondsToDate(item?.["Due Date"]),
      }));
      if (role === "Second Reviewer") {
        dispatch(setCAPADetails(CAPADetail.length > 0 ? CAPADetail : []));

        if (status[0] === "Investigation Validation" && role === "Second Reviewer") {
          if (!result.data.data?.capas || result.data.data.capas.length === 0) {
            dispatch(updateSwitchState("CAPA", "No"));
          }
          if (!result.data.data?.investigationLeader) {
            dispatch(updateSwitchState("Investigation", "No"));
          } else {
            dispatch(handleChangeDrawerFormData({ fieldName: "Investigation Leader", value: result.data.data?.investigationLeader, title: "Assign_Investigation" }));
            dispatch(handleChangeDrawerFormData({ fieldName: "Investigation Members", value: result.data.data?.investigationMembers, title: "Assign_Investigation" }));
            dispatch(handleChangeDrawerFormData({ fieldName: "Target completion date", value: convertMillisecondsToDate(result.data.data?.targetCompletionDate), title: "Assign_Investigation" }));
          }
        }
      }
      setNewCapa(CAPADetail?.length)
      // when second rever return to first review reviwer form old data 
      if (status[0] === 'Incident Revalidation' && role === "First Reviewer") {
        const reviewedData = {
          Severity: String(result.data.data?.firstReviewerRecommendedSeverity),
          "Incident Type": result.data.data?.firstReviewerRecommendedIncidentType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
          DGMS: result.data.data?.frReviewData?.DGMS,
          "Factories Act": result.data.data?.frReviewData?.["Factories Act"],
          OISD: result.data.data?.frReviewData?.OISD,
          PNGRB: result.data.data?.frReviewData?.PNGRB,
          "OISD Fire": result.data.data?.frReviewData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.frReviewData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.frReviewData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.frReviewData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.frReviewData?.["High Potential Near Miss"]
        }
        dispatch(setReviewData(reviewedData));
      }
       // when investigation send to first reviwer ,first reviwer form old data 
      if ((status[0] === 'Investigation Completed' || status[0] === 'Investigation Resubmission') && role === "First Reviewer") {
        const reviewedData = {        
          Severity: String(result.data.data?.investigatorRecommendedSeverity),
          "Incident Type": result.data.data?.investigatorRecommendedType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
           DGMS: result.data.data?.invData?.DGMS,
          "Factories Act": result.data.data?.invData?.["Factories Act"],
          OISD: result.data.data?.invData?.OISD,
          PNGRB: result.data.data?.invData?.PNGRB,
          "OISD Fire": result.data.data?.invData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.invData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.invData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.invData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.invData?.["High Potential Near Miss"]
        }
        dispatch(setReviewData(reviewedData));
      }

      if (status[0] === 'Investigation Clarification'  && role === "First Reviewer") {
        const reviewedData = {        
           Severity: String(result.data.data?.investigatorRecommendedSeverity),
          "Incident Type": result.data.data?.investigatorRecommendedType,
          "Investigation Recommended": result.data.data?.investigationRecommended,
           DGMS: result.data.data?.invData?.DGMS,
          "Factories Act": result.data.data?.invData?.["Factories Act"],
          OISD: result.data.data?.invData?.OISD,
          PNGRB: result.data.data?.invData?.PNGRB,
          "OISD Fire": result.data.data?.invData?.["OISD Fire"],
          "PNGRB Fire": result.data.data?.invData?.["PNGRB Fire"],
          "OISD Major Fire": result.data.data?.invData?.["OISD Major Fire"],
          "PNGRB Major Fire": result.data.data?.invData?.["PNGRB Major Fire"],
          "High Potential Near Miss": result.data.data?.invData?.["High Potential Near Miss"]
        }
        dispatch(setReviewData(reviewedData));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDrawerOpen = async (action) => {
    console.log("butttttt", action, levelReviewer);
    if (action === "editReport") {
      const data = await getIncidentformData();
      if (data) {
        const data2 = { ...data, "Date of Accident": convertMillisecondsToDate(data["Date of Accident"]), }
        dispatch(setIncidentFormData(data2));
      }
      navigate("/module/incident-management/report-incident");
    }
    if (action === "previous" || action === "comment") {
      setDrawerTitle("Return");
      setBoxesOpen(false);
      setDrawerOpen(true);
    }
    if (action === "next" && levelReviewer === "1") {
      setDrawerTitle("Review");
      setDrawerOpen(true);
    }

    if (action === "next" && levelReviewer === "2") {
      if (status[0] === "Investigation Completed") {
        setDrawerTitle("Review");
        setDrawerOpen(true);
      } else {
        setBoxesOpen(true);
        const reviewedData = {
          Severity: String(incidentReportData?.firstReviewerRecommendedSeverity),
          "Incident Type": incidentReportData?.firstReviewerRecommendedIncidentType,
          "Investigation Recommended": incidentReportData?.investigationRecommended,
          DGMS: incidentReportData?.frReviewData?.DGMS,
          OISD: incidentReportData?.frReviewData?.OISD,
          PNGRB: incidentReportData?.frReviewData?.PNGRB,
          "OISD Fire": incidentReportData?.frReviewData?.["OISD Fire"],
          "PNGRB Fire": incidentReportData?.frReviewData?.["PNGRB Fire"],
          "Factories Act": incidentReportData?.frReviewData?.["Factories Act"],
          "OISD Major Fire": incidentReportData?.frReviewData?.["OISD Major Fire"],
          "PNGRB Major Fire": incidentReportData?.frReviewData?.["PNGRB Major Fire"],
          "High Potential Near Miss": incidentReportData?.frReviewData?.["High Potential Near Miss"]
        }
        dispatch(setReviewData(reviewedData));
      }
    }

    if (action === "next" && levelReviewer === "3") {
      setDrawerTitle("Review");
      const reviewedData = {
        Severity: String(incidentReportData?.secondReviewerRecommendedSeverity),
        "Incident Type": incidentReportData?.secondReviewerRecommendedIncidentType,
        "Investigation Recommended": incidentReportData?.investigationRecommended,
        DGMS: incidentReportData?.srReviewData?.DGMS,
        OISD: incidentReportData?.srReviewData?.OISD,
        PNGRB: incidentReportData?.srReviewData?.PNGRB,
        "OISD Fire": incidentReportData?.srReviewData?.["OISD Fire"],
        "PNGRB Fire": incidentReportData?.srReviewData?.["PNGRB Fire"],
        "Factories Act": incidentReportData?.srReviewData?.["Factories Act"],
        "OISD Major Fire": incidentReportData?.srReviewData?.["OISD Major Fire"],
        "PNGRB Major Fire": incidentReportData?.srReviewData?.["PNGRB Major Fire"],
        "High Potential Near Miss": incidentReportData?.srReviewData?.["High Potential Near Miss"]
      }

      dispatch(setReviewData(reviewedData));
    }


  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  const getIncidentformData = async () => {
    try {
      const result = await getIncidentViewData(id);
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const [fRCAPAMessage, setFRCAPAMessage] = useState("");


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" gap={1}>
              <Typography variant="h2">{incidentReportData?.["Incident Type"]}</Typography>
              <SeverityTag severity={incidentReportData?.Severity} />
              {incidentReportData?.CAPADetail?.length > 0 &&
                <Chip
                  label={"CAPA Assigned"}
                  sx={{
                    color: "#0FAB8E",
                    backgroundColor: "rgba(19, 222, 185, 0.20)",
                    fontSize: "12px",
                    fontWeight: "500",
                    minWidth: "104px",
                    maxHeight: "24px",
                    borderRadius: "4px",
                  }}
                />}
            </Box>
            <Box display="flex" flexDirection="column" gap={0.5}>

              <Box display="flex" gap={1}>
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  ID:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {customReportId}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  Reporting Officer:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {incidentReportData?.reportedByStaffName}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                <Typography variant="body1" sx={{ color: "#222222" }}>
                  Reporting Time:
                </Typography>
                <Typography variant="body1" sx={{ color: "#525966" }}>
                  {formatDateToIndianLocale(incidentReportData?.["Incident Time"])}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {mode === "action" &&
          <Grid item xs={12} md={4}>
            {levelReviewer === "1" && <CustomToggleButtonGroup
              title="First Reviewer Action"
              defaultValue=""
              options={
                (status[0] === 'Incident Revalidation' ||  status[0] === "Investigation Clarification") ?
                  [{ value: "next", label: "Proceed to next level" }] :
                  [
                    { value: "previous", label: "Return to previous level" },
                    { value: "next", label: "Proceed to next level" },
                  ]}
              onChange={handleDrawerOpen}
            />}
            {levelReviewer === "2" && <CustomToggleButtonGroup
              title="Second Reviewer Action"
              defaultValue=""
              options={status[0] === 'Investigation Validation' ? [
                { value: "next", label: "Proceed to next level" },
              ] : [
                { value: "previous", label: "Return to previous level" },
                { value: "next", label: "Proceed to next level" },
              ]}
              onChange={handleDrawerOpen}
            />}
            {levelReviewer === "3" && <CustomToggleButtonGroup
              title="Investigator Action"
              defaultValue=""
              options={
                status[0] === "Investigation Query" ?
                  [
                    { value: "next", label: "Proceed to next level" },
                  ] :
                  [
                    { value: "previous", label: "Return to previous level" },
                    { value: "next", label: "Proceed to next level" },
                  ]}
              onChange={handleDrawerOpen}
            />}
            {levelReviewer === "4" && <CustomToggleButtonGroup
              title="CAPA Action"
              defaultValue=""
              options={[
                { value: "proceed", label: "Proceed" }
              ]}
              onChange={()=>setFRCAPAMessage("Please select a CAPA Detail in the table to proceed.")}
            />}
            {levelReviewer === "5" && <CustomToggleButtonGroup
              title="CAPA Action"
              defaultValue=""
              options={[
                { value: "proceed", label: "Proceed" }
              ]}
            // onChange={handleSRCAPADrawerOpen}
            />}

            {levelReviewer === "0" && <CustomToggleButtonGroup
              title="Repoting Officer Action"
              defaultValue=""
              options={[
                { value: "comment", label: "Comment" },
                { value: "editReport", label: "Edit Report" },
              ]}
              onChange={handleDrawerOpen}
            />}
          </Grid>
        }

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={1}>
              <CustomStepperBox stepData={workFlowInputsData} />
            </Grid>

            <Grid item xs={12}>
              {levelReviewer === "4" ? (
                <Box
                  sx={{
                    width: "100%",
                    maxHeight: '420px',
                    //paddingY: "12px",
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                  }}
                >
                  <Tabs
                    value={outerTab}
                    onChange={handleOuterChange}
                    aria-label="basic tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{ maxHeight: '36px' }} // Adjust height as necessary
                  >
                    <Tab
                      label={
                        <Typography variant='body1' sx={{ color: outerTab === 0 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                          Incident info
                        </Typography>
                      }
                      sx={{ backgroundColor: 'transparent' }}
                    />
                    <Tab
                      label={
                        <Typography variant='body1' sx={{ color: outerTab === 1 ? 'rgba(9, 162, 227, 1)' : "#525966", px: '24px' }}>
                          CAPA Detail
                        </Typography>
                      }
                      sx={{ backgroundColor: 'transparent' }}
                    />
                  </Tabs>
                  {outerTab === 1 && (
                    <Box sx={{ padding: '16px' }}>
                      <CAPADetailTable />
                    </Box>
                  )}
                </Box>
              ) : (
                <IncidentViewTabSection data={incidentReportData} title="Incident Info" />)}
            </Grid>
          </Grid>
        </Grid>

        <>
          <Grid item xs={12} md={4} mt={1}>
            <Grid container spacing={2}>
              {(levelReviewer === "2" && boxesOpen) && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      width: "100%",
                      paddingX: "24px",
                      paddingY: "12px",
                      backgroundColor: "#ffffff",
                      borderRadius: "8px",
                      position: "relative",
                      pt: 3,
                      height: "110px",
                    }}
                  >
                    <CustomCheckCard
                      title={switchStates?.CAPA === "No" || newCapa < CAPADetail?.length ? "Added CAPA" : "Add CAPA"}
                      subtitle="CAPA"
                      assigned={CAPADetail?.length}
                      showIcon={switchStates?.CAPA === "No" || newCapa < CAPADetail?.length}
                      iconBgColor="#1ABC9C"
                      onClick={() => { setDrawerOpen(true); setDrawerTitle("CAPA"); }}
                    />
                    {!afterFRInvestigationCompleted &&
                    <CustomCheckCard
                      title={Object.keys(Assign_Investigation)?.length > 0 ? "Assigned Investigation" : switchStates?.Investigation === "No" ? "No Investigation" : "Assign Investigation"}
                      subtitle=""
                      assigned=''
                      showIcon={Object.keys(Assign_Investigation)?.length > 0 || switchStates?.Investigation === "No"}
                      iconBgColor="#1ABC9C"
                      onClick={() => { setDrawerOpen(true); setDrawerTitle("Assign_Investigation"); }}
                    /> 
                    }
                    <CustomCheckCard
                      title={Object.keys(Review)?.length > 0 ? "Review Completed" : "Complete Review"}
                      subtitle=""
                      assigned=''
                      showIcon={"Review completed" in Review}
                      iconBgColor="#1ABC9C"
                      onClick={() => { setDrawerOpen(true); setDrawerTitle("Review"); }}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    // paddingX: "24px",
                    paddingY: "16px",
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="body1" sx={{ mb: "16px", ml: "24px" }}>
                    {levelReviewer === "3" && drawerTitle === "Review" ? "Investigation" : "Workflow Inputs"}
                  </Typography>
                  <Divider
                    sx={{
                      width: "100%",
                      border: "0.5px solid #B7C8E5",
                      mb: "16px",
                    }}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1.5}
                    sx={{
                      paddingX: "24px",
                      height: boxesOpen ? "304px" : "430px",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    {levelReviewer === "3" && drawerTitle === "Review" && mode === "action" ?
                      <InvestigationCard levelReviewer={levelReviewer} status={status[0]} IncidentReportData={incidentReportData} />
                      : <CustomWorkflowAccordion data={workFlowInputsData} />
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              position: 'fixed',
              bottom: 30, // Distance from the bottom
              right: 24, // Distance from the right
              zIndex: 1000, // Ensure it's above other components
            }}
          >
            {mode === "action" &&
              <FloatingButton title={"ActionPage"} subtitle={drawerTitle} level={levelReviewer} incidentId={id} status={status[0]} />
            }
          </Box>
        </>

      </Grid>
      <RightDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        title={drawerTitle}
        status={status[0]}
        fields={
          drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Review" ? firstReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "New Incident" ? firstReviwerNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Incident Revalidation" ? firstReviwerNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "New Incident Resubmission" ? firstReviwerNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Completed" ? firstReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Resubmission" ? firstReviwerInvestionReviewNextDrawerFields:drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Resubmission" ? firstReviwerInvestionReviewNextDrawerFields:drawerTitle === "Review" && levelReviewer === "1" && status[0] === "Investigation Clarification" ? firstReviwerInvestionReviewNextDrawerFields
            : drawerTitle === "Review" && levelReviewer === "2" && (status[0] === "Investigation Review" || status[0] === "Investigation Under Approval"|| status[0] === "Investigation Approval Resubmission") ? secondReviwerInvestionReviewNextDrawerFields : drawerTitle === "Review" && levelReviewer === "2" ? firstReviwerNextDrawerFields : drawerTitle === "Assign_Investigation" && levelReviewer == "2" ? assignInvestigationFormFields : drawerTitle === "CAPA" && levelReviewer == "2" ? assignCAPAFormFields : drawerTitle === "Return" && status[0] === "Investigation_Review" ? firstReviwerReturnInvestionReviewDrawerFields : firstReviwerReturnDrawerFields
        }
        cardData={drawerTitle === "CAPA" ? CAPADetail : []}
        data={drawerTitle === "Review" || drawerTitle === "Return" ? incidentReportData : incidentReportData} // Provide the card data if needed

      />

      <Modal
        open={fRCAPAMessage.length > 0}
        onClose={()=>setFRCAPAMessage("")}
        aria-labelledby="message-modal-title"
        aria-describedby="message-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography textAlign="center" id="message-modal-description" sx={{ mt: 2, fontSize:'16px', fontWeight:'600' }}>
            {fRCAPAMessage}
          </Typography>
          <Box textAlign="center" sx={{ mt: 3 }}>
            <Button variant="contained" sx={{color:'white'}} onClick={()=>setFRCAPAMessage("")}>
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
