import React from 'react';
import { TextField } from '@mui/material';

const CustomInput = ({ label, value, onChange, multiline = false, rows = 1, placeholder ,type }) => {
    let isDisable= false;
    if(label === "Injury Count" || label === "Fatality Count"){
        isDisable= true;
    }

    return (
        <TextField
           disabled={isDisable}
            label={label}
            name={label}
            type={type}
            value={value}
            onChange={(e) => {
                const inputValue = e.target.value;
                // Check if label is 'No. of people coming' and restrict the input if the first digit is '0'
                if (label === "No. of People" && inputValue.length > 0 && inputValue[0] === '0') {
                  // Prevent input starting with '0'
                  return;
                }
                onChange(e); // Call the provided onChange handler if the input is valid
              }}
            multiline={multiline}
            inputProps={{ inputMode: 'numeric' }}
            rows={rows}
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            placeholder={placeholder}
            sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                '& .MuiOutlinedInput-root': {
                    minHeight: '40px',
                     // Set minHeight to ensure small size
                },
                mt: 0,
                mb: 0,
                '& .MuiInputBase-input': {
                    fontSize: '12px', // Set input font size
                    overflowY: 'auto',  // Allow vertical scrolling
                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide scrollbar in Chrome/Safari
                    },
                },
                '& .MuiInputBase-input::placeholder': {
                    fontSize: '12px', // Set placeholder font size
                },
                '& .MuiInputLabel-root': {
                    fontSize: '14px', // Optional: Set label font size
                },
            }}
            InputLabelProps={{
                shrink: true, // Ensure label shrinks when input is focused or filled
            }}
        />
    );
};

export default CustomInput;
