

const NotFoundPage = () =>{
    return(
        <>
        
         <p>comming soon...</p>
        </>
       
    )
}
export default  NotFoundPage;