import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, Box, Button } from '@mui/material';
import { cardHeadBG } from '../../constant';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { getIEDAgents } from '../../Apis/apiCall';

export default function AgentsList() {
  const [agentsData, setAgentsData] = useState([]);

  useEffect(() => {
    fetchAgents();
  },[])

  const fetchAgents = async() => {
    try {
      const res = await getIEDAgents();
      setAgentsData(res.data.data);
    } catch (error) {
      console.error("Error while fetching Agents",error);
    }
  }

  return (
    <>
      <Typography sx={{fontSize:'20px', color:'#2E2C34', fontWeight:'600', mb:2}}>Agents</Typography>
      <Grid container spacing={1}>
        {agentsData?.map((agent, index)=>(
            <Grid item xs={12} sm={6} md={4}>
                <Paper
                elevation={1}
                sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '8px !important',
                    minHeight: '260px',
                    flexGrow: 1,
                    border: '0.876px solid #D5E0F6',
                    borderRadius: '16px',
                    cursor: 'pointer',
                    boxShadow: '1.753px 1.753px 3.506px 0px rgba(174, 191, 237, 0.25)',
                    transition: '0.3s',
                    '&:hover': {
                    backgroundColor: '#FAFAFA',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                    borderColor: '#FAFAFA',
                    },
                }}
                >
                <Grid container>
                <Grid item xs={12}>
                    <Box sx={{
                      borderRadius: '12px', backgroundColor: '#09A2E3', height: '80px', padding: '8px', mb: '32px', position: 'relative',
                      backgroundImage: `url(${cardHeadBG})`,
                      backgroundSize: "200%",
                      backgroundPosition: '-300px -100px',
                    }}>
                    
                      <Box sx={{
                        position: 'absolute',
                        border: '2px solid white',
                        left: '16px',
                        bottom: '-32px',
                        zIndex: 10,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '70px',
                        height: '70px',
                        padding: '6px',
                      }}>
                          <img
                              // src={agent.img || "https://i.pravatar.cc/70"}
                              src={agent.img || "https://placehold.co/70"}
                              alt="Avatar"
                              style={{ borderRadius: '50%',height: '70px', border: '4px solid white' }}
                            />
                      </Box>
                    </Box>
                    <Box sx={{ml:'16px', mt:'32px'}}>
                        <Box display='flex' flexDirection='column' gap={1.5}>
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                <Box display='flex' flexDirection='column' gap={0.5}>
                                    <Typography sx={{color:'#1F242F', fontSize:'14px', fontWeight:'600'}}>{agent.name}</Typography>
                                    <Typography sx={{color:'#6B7387', fontSize:'10px', fontWeight:'500'}}>{agent.email}</Typography>
                                </Box>
                                <Button variant='contained' sx={{backgroundColor:'#0571ED', borderRadius:'88px', color:'#ffffff'}}>View Tickets</Button>
                            </Box>
                            <Box display='flex' flexDirection='column' gap={1}>
                                <Box display='flex' flexDirection='row' alignItems='center' gap={0.5}>
                                    <Typography sx={{color:'#353C4B', fontSize:'14px', fontWeight:'700'}}>{agent.assignedTicketsCount}</Typography>
                                    <Typography sx={{color:'#6B7387', fontSize:'12px', fontWeight:'400'}}>Tickets Assigned</Typography>
                                </Box>
                                <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                                    <Box display='flex' flexDirection='row' alignItems='center' gap={0.5}>
                                        <Typography sx={{color:'#353C4B', fontSize:'14px', fontWeight:'700'}}>{agent.completedTicketsCount}</Typography>
                                        <Typography sx={{color:'#6B7387', fontSize:'12px', fontWeight:'400'}}>Tickets Completed</Typography>
                                    </Box>
                                    <Box display='flex' flexDirection='row' alignItems='center' gap={0.5}>
                                        <Typography sx={{color:'#353C4B', fontSize:'14px', fontWeight:'700'}}>{agent.reassignedTicketsCount}</Typography>
                                        <Typography sx={{color:'#6B7387', fontSize:'12px', fontWeight:'400'}}>Tickets Reassigned</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box display="flex" alignItems="center" gap={1}>
                              <LocationOnOutlinedIcon sx={{ color: "#AFB7CD", fontSize: "12px" }} />
                              <Typography sx={{ color: '#AFB7CD', fontSize: '12px', textAlign: 'left' }}>
                                Location
                              </Typography>
                            </Box> */}
                        </Box>
                    </Box>
                  </Grid>
                </Grid>
                </Paper>
            </Grid>
        ))}
        </Grid>
    </>
  )
}
