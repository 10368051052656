import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ReturnValidationModal = ({ open, onClose }) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Remark not filled!
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      You've not filled remarks for some selected recommendations
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ textTransform: 'none', borderRadius: '20px', color:'#ffffff', backgroundColor: '#09A2E3' }}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReturnValidationModal;
