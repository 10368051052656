import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomButtonGroup from './CustomButtonGroup'; // Update the path as necessary
import CustomInput from './CustomInputField'; // Update the path as necessary
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { addContributingFactorDetail } from '../redux/incidentInvestflow/actions';
import CustomMultiSelect from "../Components/MutlipleSelect";

// Style for the Add Button
const AddButtonStyle = {
    fontSize: "14px",
    fontWeight: "500",
    paddingY: "8px",
    paddingX: "16px",
    color: "#fff",
    borderRadius: "60px",
    backgroundColor: "#0AA2E3",
    width: "70px",
    textTransform: "none"
};
const AddButtonStyle2 = {
    fontSize: "14px",
    fontWeight: "500",
    color: "#0AA2E3",
    borderRadius: "60px",
    backgroundColor: "#88d6f8",
    textTransform: "none"
};

// List of checkbox options
const options = ["Slippery Conditions", "Noise", "Slip/Hazard", "Congested Area", "Inadequate Ventilation", "Sun In Eyes", "Inadequate Lighting", "Reduced Visibility", "Weather Condition", "Hazardous Arrangement", "Other"];

const ContributingFactorPage = () => {
    const dispatch = useDispatch();
    const [whyDone, setWhyDone] = useState(false);
    const [step, setStep] = useState(0);
    const [contributingFactor, setContributingFactor] = useState({
        contributingFactor: "",
        reason: ""
    });
    const maxSections = 5;
    const [contributingFactorDetail, setContributingFactorDetail] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState('');
    const [rootIndex, setRootIndex] = useState(0);
    const formData = useSelector(state => state.incidentInvestigationFlowReducer);



    const {
        formEdit,
        ContributingFactorDetail,
        ContributingFactor
    } = formData

    // console.log(contributingFactor);

    useEffect(() => {
        if (ContributingFactor && Object.keys(ContributingFactor).length > 0) {
            setContributingFactorDetail([{ ...ContributingFactor }]);
            setStep(1);
        }
    }, [ContributingFactor]);



    // Function to handle adding a new "Why" section (up to 5 sections) with dynamic key names
    const handleAddSection = () => {
        // Check if we can add more sections
        if (contributingFactorDetail[0].why.length < maxSections) {
            const newSection = {
                reason: '',
                rootMark: false,
                rootCause: [],
                comment: ''
            };

            // Update the state to include the new section
            setContributingFactorDetail(prevDetails => {
                // Create a copy of the existing details and add the new section
                const updatedDetails = [...prevDetails];
                updatedDetails[0].why.push(newSection); // Push the new section to the `why` array
                return updatedDetails; // Return the updated state
            });
        }
    };


    // Function to handle "Why" input change
    const handleWhyChange = (index, event, name) => {
        const newSections = contributingFactorDetail[0].why.map((section, ind) =>
            ind === index ? { ...section, [name]: event.target.value } : section // Update specific section
        );

        // Update the state to reflect the new sections array while keeping the existing contributingFactorDetail intact
        setContributingFactorDetail(prevDetails => {
            const updatedDetails = [...prevDetails]; // Create a copy of the previous details
            updatedDetails[0].why = newSections; // Update the why array in the first contributing factor
            return updatedDetails; // Return the updated state
        });
    };

    const handleRootCauseChange = (id, event) => {
        // Get the current checked state
        const isChecked = event.target.checked;

        // Update the why array so that only one rootMark can be true at a time
        const newSections = contributingFactorDetail[0].why.map((section, index) => {
            if (index === id) {
                // If the current section is the one being changed, set rootMark to checked state
                return { ...section, rootMark: isChecked };
            }
            // For all other sections, set rootMark to false
            return { ...section, rootMark: false };
        });

        // Update the state with the new sections array
        setContributingFactorDetail(prevDetails => {
            const updatedDetails = [...prevDetails];
            updatedDetails[0].why = newSections; // Update the why array in the first contributing factor
            return updatedDetails; // Return the updated state
        });
    };

    // Function to handle group button selection
    const handleSelectChange = (label, value) => {
        setContributingFactor((prev) => ({
            ...prev,
            contributingFactor: value
        }));
    };

    const handleInputChange = (key, e, rootIndex) => {
        const value = e.target.value;  // This will be an array of selected values
        console.log(value, rootIndex, key);
    
        setContributingFactorDetail((prevDetail) => {
            // Update the specific 'why' array item at 'rootIndex'
            const updatedWhy = prevDetail.map((factorDetail) => {
                if (factorDetail?.why) {
                    const updatedWhyItems = factorDetail.why.map((whyItem, wIndex) => {
                        if (wIndex === rootIndex) {
                            // Handle rootCause updates as an array of selected values
                            if (key === 'rootCause') {
                                // Ensure we merge the new selected values with the previous ones without duplication
                                const newRootCause = Array.isArray(whyItem.rootCause)
                                    ? [...new Set([...whyItem.rootCause, ...value])] // Merge and ensure no duplicates
                                    : value; // If rootCause doesn't exist, set it to the selected values
    
                                return {
                                    ...whyItem,
                                    rootCause: newRootCause,
                                };
                            }
                            // Handle comment update
                            else if (key === 'comment') {
                                return {
                                    ...whyItem,
                                    comment: value,
                                };
                            }
                        }
                        return whyItem; // Return unchanged 'why' items
                    });
    
                    // Return updated factorDetail with the modified 'why' array
                    return {
                        ...factorDetail,
                        why: updatedWhyItems,
                    };
                }
                return factorDetail; // If 'why' does not exist, return the factorDetail as is
            });
    
            // Return updated contributingFactorDetail
            return updatedWhy;
        });
    };
    
    


    const handleCheckboxChange = (label) => (event) => {
        setSelectedCheckbox(label);
        setContributingFactor((prev) => ({
            ...prev,
            reason: label
        }));
    };

    const handleNext = () => {
        // Create a new entry with contributingFactor and reason only
        const newEntry = {
            contributingFactor: contributingFactor.contributingFactor,
            reason: contributingFactor.reason,
            why: [{ reason: '', rootCause: [], rootMark: false, comment: '' }] // Initialize the why array as empty, to be updated later
        };

        setContributingFactorDetail([...contributingFactorDetail, newEntry]);
        setSelectedCheckbox('');
        setContributingFactor({
            contributingFactor: "",
            reason: ""
        })
        setStep(1);

    };

    const handleNext2 = () => {
        // Find the index where rootMark is true
        const rootIndex1 = contributingFactorDetail[0]?.why?.findIndex(item => item.rootMark === true);
        
        console.log("Found rootIndex:", rootIndex1);  // Debugging step
    
        if (rootIndex1 !== -1) {
            setRootIndex(rootIndex1);  // Update rootIndex only if it's found
        } else {
            console.warn("No item with rootMark = true found.");  // If rootMark is not true in any item
        }
    
        setWhyDone(true);
        setStep(2);
    };
    const handleBack = () => {
        setStep(1);
    }
    const handleAddContibutingFactor = () => {
        dispatch(addContributingFactorDetail(contributingFactorDetail[0]))
        setContributingFactorDetail([]);
        setStep(0);
    }


    return (
        <>
            {step === 0 &&
                (
                    <>
                        <Box my={3} p={3} border="1px solid #ddd" boxShadow="0 2px 4px 0 #0000001F" borderRadius={4}>
                            <Typography variant='body2' color="#666F7F" sx={{ mb: "8px" }}>
                                Contributing Factor
                            </Typography>

                            {/* Group Button */}
                            <CustomButtonGroup
                                buttonNames={["Environment", "Man", "Material", "Method", "Machine"] || []}
                                selected={contributingFactor?.contributingFactor || ""}
                                onSelect={(value) => handleSelectChange("contributingFactor", value)}
                            />
                            {/* Checkbox options for reasons */}
                            <FormGroup>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        alignItems: 'left',
                                        '@media (max-width: 1300px)': {
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                        },
                                    }}
                                >
                                    {options.map((label, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        p: '2px',
                                                        size: '8px',
                                                        color: "#666F7F",
                                                        '&.Mui-checked': {
                                                            color: '#0AA2E3',
                                                        },
                                                    }}
                                                    checked={selectedCheckbox === label}
                                                    onChange={handleCheckboxChange(label)}
                                                    color="primary"
                                                />
                                            }
                                            label={label}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontSize: '12px',
                                                    color: "#666F7F",
                                                    fontWeight: 400,
                                                },

                                            }}
                                        />
                                    ))}
                                </Box>
                            </FormGroup>
                        </Box>

                        <Grid container justifyContent="flex-end">
                            <Button
                                onClick={handleNext}
                                style={{
                                    ...AddButtonStyle,
                                }}
                            >
                                Next
                            </Button>
                        </Grid>

                    </>
                )}

            {(step === 1 && contributingFactorDetail.length > 0) && (<>
                <Box my={2} p={3} border="1px solid #ddd" boxShadow="0 2px 4px 0 #0000001F" borderRadius={4}>
                    {contributingFactorDetail.map((factorDetail, index) => (
                        <React.Fragment key={index}>
                            <Typography variant='body2' color="#666F7F" sx={{ mb: "8px" }}>
                                Contributing Factors
                            </Typography>
                            <Box display="flex" gap={1}>
                                <Button variant="contained" size="small" sx={{ bgcolor: "#09A2E3", color: "#fff", fontWeight: 400, borderRadius: "4px" }}>
                                    {factorDetail.contributingFactor}
                                </Button>
                                <Button variant="outlined" size="small" sx={{ fontWeight: 400, borderRadius: "4px" }}>
                                    {factorDetail.reason}
                                </Button>
                            </Box>
                        </React.Fragment>))}
                </Box>
                <Box my={2} p={3} border="1px solid #ddd" boxShadow="0 2px 4px 0 #0000001F" borderRadius={4}>
                    {contributingFactorDetail[0]?.why?.map((section, index) => {
                        return (
                            <Grid container key={index} alignItems="center">
                                <Grid item xs={12}>
                                    <CustomInput
                                        label={`Why ${index + 1}`}
                                        value={section[index]} // Access dynamic key here
                                        onChange={(e) => handleWhyChange(index, e, "reason")}
                                        type="text"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Typography variant='body2' sx={{ marginRight: '8px', color: "#7A8699" }}>
                                            Root Cause
                                        </Typography>
                                        <Switch
                                            checked={section.rootMark}
                                            onChange={(event) => handleRootCauseChange(index, event, "rootMark")}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Box>
                <Grid item xs={12} container justifyContent="flex-end">
                    <AddBoxOutlinedIcon sx={{ fontSize: "32px", color: "#0AA2E3" }} onClick={handleAddSection} disabled={contributingFactorDetail[0].why.length >= maxSections} />
                </Grid>

                <Grid container justifyContent="flex-end">
                    <Button
                        onClick={handleNext2}
                        style={{
                            ...AddButtonStyle,
                        }}
                    >
                        Next
                    </Button>
                </Grid>



            </>)}
            {(step === 1 && contributingFactorDetail.length <= 0) && (
                contributingFactorDetail.map((factorDetail, index) => (
                    <Box my={2} p={3} border="1px solid #ddd" boxShadow="0 2px 4px 0 #0000001F" borderRadius={4} key={index}>
                        <Typography variant='body2' color="#666F7F" sx={{ mb: "8px" }}>
                            Contributing Factors
                        </Typography>
                        <Box display="flex" gap={1}>
                            <Button variant="contained" size="small" sx={{ bgcolor: "#09A2E3", color: "#fff", fontWeight: 400, borderRadius: "4px" }}>
                                {factorDetail.contributingFactor}
                            </Button>
                            <Button variant="outlined" size="small" sx={{ fontWeight: 400, borderRadius: "4px" }}>
                                {factorDetail.reason}
                            </Button>
                        </Box>
                    </Box>
                ))
            )}

            {step === 2 && (
                <>
                    <Box my={2} p={3} border="1px solid #ddd" boxShadow="0 2px 4px 0 #0000001F" borderRadius={4}>
                        {contributingFactorDetail?.map((factorDetail, index) => (
                            <React.Fragment key={index}>
                                <Typography variant='body2' color="#666F7F" sx={{ mb: "8px" }}>
                                    Contributing Factors
                                </Typography>
                                <Box display="flex" gap={1}>
                                    <Button variant="contained" size="small" sx={{ bgcolor: "#09A2E3", color: "#fff", fontWeight: 400, borderRadius: "4px" }}>
                                        {factorDetail?.contributingFactor}
                                    </Button>
                                    <Button variant="outlined" size="small" sx={{ fontWeight: 400, borderRadius: "4px" }}>
                                        {factorDetail?.reason}
                                    </Button>
                                </Box>

                                {/* Check if Why is an array and has at least one item */}
                                {(Array.isArray(factorDetail.why) && factorDetail.why.length > 0 && whyDone) && (
                                    factorDetail?.why?.map((ele, idx) => (
                                        <React.Fragment key={idx}>
                                            <Typography variant="body2" sx={{ mt: "24px", lineHeight: "4px", color:ele.rootMark && "#0aa2e3" }}>
                                                Why {idx + 1}
                                            </Typography>
                                            <Typography variant="caption" sx={{ color: "#7A8699" }}>
                                                {ele?.reason}
                                            </Typography>
                                        </React.Fragment>
                                    ))
                                )}
                            </React.Fragment>
                        ))}

                    </Box>
                    <Box my={2} px={2} sx={{ pt: "32px", pb: "8px" }} border="1px solid #ddd" boxShadow="0 2px 4px 0 #0000001F" borderRadius={4} gap={1}>
                        <Grid item xs={12} sx={{ pb: "16px" }}>
                            <CustomMultiSelect
                                label="rootCause"
                                value={contributingFactorDetail[0]?.why[rootIndex]?.rootCause || []}  // Ensure it reads an array
                                options={options}
                                onChange={(e) => handleInputChange("rootCause", e, rootIndex)}  // Pass rootIndex
                            />
                            </Grid>

                            <CustomInput
                                label="comment"
                                value={contributingFactorDetail[0]?.why[rootIndex]?.comment || ""}  // Access specific comment value
                                onChange={(e) => handleInputChange("comment", e, rootIndex)}  // Pass rootIndex
                                type="text"
                                rows={4}
                                multiline
                                placeholder="Write Your Comment..."
                                InputLabelProps={{ shrink: true }}
                            />
                        


                        <Grid container justifyContent="flex-end" spacing={0} sx={{ py: 2 }}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    onClick={handleBack}
                                    style={{
                                        ...AddButtonStyle2,
                                        marginRight: "4px",  // Adds 12px space between the buttons
                                    }}
                                    startIcon={<ArrowBackIosIcon sx={{ mr: 0 }} />}
                                >

                                    Back
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleAddContibutingFactor}
                                    style={{
                                        ...AddButtonStyle,
                                    }}
                                >
                                    Done
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>


                </>)}


        </>
    );
};

export default ContributingFactorPage;
