import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Grid, IconButton, InputAdornment } from '@mui/material';
import {
  loginBgCircle,
  loginBgRectangle,
  ISO9001,
  ISO27001,
  netzeroLogo,
  iEnergyLogo,
  forgotPasswordImage
} from '../constant.js';
import CopyrightIcon from '@mui/icons-material/Copyright';
import WestIcon from '@mui/icons-material/West';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setNewPassword, verifyOtp, sendOtp } from '../Apis/apiCall.js';
import { encrypt } from '../utils/encrypt.js';
import CustomSnackbar from '../Components/CustomSnackbar.js';


const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Email is required')
});
const validationSchema1 = Yup.object({
  otp: Yup.string()
    .matches(/^\d+$/, 'Invalid OTP')
    .required('OTP is required')
});
const validationSchema2 = Yup.object({
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required')
});

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0); // 0 -> Forgot Password, 1 -> OTP Verify, 2 -> Create New Password
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [encEmail, setEncEmail] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSendOtp = async (values, setSubmitting) => {
    const encryptedEmail = encEmail !== null ? encEmail : await encrypt(values.email);
    setEncEmail(encryptedEmail);
    try {
      const response = await sendOtp({ email: encryptedEmail });
      if (response.status === 200) {
        setPageNumber(1);
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('No such user!');
      setSnackbarOpen(true);
      console.error('Failed to send OTP:', error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleResendOtp = async () => {
    try {
      const response = await sendOtp({ email: encEmail });
      if (response.status === 200) {
        setSnackbarSeverity('success');
        setSnackbarMessage('OTP Sent!');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to resend OTP!');
      setSnackbarOpen(true);
      console.error('Failed to resend OTP:', error);
    }
  };

  const handleVerifyOtp = async (values, setSubmitting) => {
    // const encryptedEmail = await encrypt(values.email); // use the same encrypted email if required
    try {
      const response = await verifyOtp({ email: encEmail, otp: values.otp });
      if (response.status === 200) {
        setPageNumber(2);
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to verify OTP!');
      setSnackbarOpen(true);
      console.error('Failed to verify OTP:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSetNewPassword = async (values, setSubmitting) => {
    // const encryptedEmail = await encrypt(values.email); // encrypt if necessary
    const encryptedPassword = await encrypt(values.password);
    const encryptedConfirmPassword = await encrypt(values.confirmPassword);

    try {
      const response = await setNewPassword({ email: encEmail, newPassword: encryptedPassword, confirmPassword: encryptedConfirmPassword });
      if (response.status === 200) {
        navigate('/login');
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to set new password!');
      setSnackbarOpen(true);
      console.error('Failed to set new password:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

    return (
      <Grid container spacing={0} sx={{ backgroundColor: '#F4F5FA', minHeight: '100vh' }}>
        <Grid item xs={12} md={6}>
            <Box sx={{ width:'100%', height:'100%', position:'relative'}}>
                <Typography sx={{ position: 'absolute', top: '24px', left: '24px', zIndex: '10', fontSize: '24px', fontWeight: '600', color: '#000000' }}>HSSE System</Typography>
                <img src={forgotPasswordImage} alt='forgot pwd image' style={{ width: '100%', height: '100vh' }} />
            </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ padding: '24px' }}>
          <Box sx={{
            backgroundImage: `url(${loginBgCircle}), url(${loginBgRectangle})`,
            backgroundSize: 'auto, auto',
            backgroundPosition: 'right -180px top -220px, left -230px bottom -170px',
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundColor: '#ffffff',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '16px',
            position: 'relative'
          }}>
            <Box display='flex' flexDirection='row' alignItems='center' gap={2} sx={{ position: 'absolute', top: '16px', left: '16px' }}>
                <IconButton onClick={() => navigate(-1)} style={{ padding: 0 }}>
                    <WestIcon style={{ fontSize: '20px', color: '#09A2E3', cursor: 'pointer' }} />
                </IconButton>
              <Typography sx={{ fontSize: '20px', color: '#09A2E3' }}>Forgot Password</Typography>
            </Box>

            {pageNumber === 0 && (
              <Box display="flex" justifyContent="center" alignItems="center" height='100%' width="100%">
                <Box display='flex' flexDirection='column' gap={1}>
                  <Typography sx={{ fontSize: '32px', fontWeight: '700', color: '#000000' }}>Forgot password?</Typography>
                  <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#929292' }}>Please enter your valid Email ID for recovery password.</Typography>
                  <Formik
                    initialValues={{ email: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => handleSendOtp(values, setSubmitting)}
                  >
                    {({ values, handleChange, errors, touched, isValid, dirty }) => (
                      <Form noValidate>
                        <Field as={TextField} label="Email*" variant="outlined" fullWidth sx={{ marginTop: 2, mt: 2, borderRadius: '50px', '& .MuiOutlinedInput-root': {borderRadius: '50px',backgroundColor: '#FAFAFA'} }} name="email" value={values.email} onChange={handleChange}
                          error={touched.email && Boolean(errors.email)} helperText={touched.email && errors.email} />
                        <Button type="submit" variant="contained" fullWidth disabled={!(isValid && dirty)} sx={{ marginTop: 2, borderRadius: '50px', color:'#ffffff' }}>Submit</Button>
                      </Form>
                    )}
                  </Formik>
                  <Box display="flex" justifyContent="flex-end" width="100%" >
                      <Box display='flex' flexDirection='row' alignItems='center' gap={0.3} sx={{cursor:'pointer'}} onClick={()=> navigate('/help')}>
                          <Typography variant="body2" color="primary" sx={{'&:hover': {textDecoration:'underline'}}}>Help</Typography>
                          <HelpOutlineIcon color="primary" sx={{ fontSize: '16px' }} />
                      </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {pageNumber === 1 && (
              <Box display="flex" justifyContent="center" alignItems="center" height='100%' width="100%">
                <Box display='flex' flexDirection='column' gap={1}>
                  <Typography sx={{ fontSize: '32px', fontWeight: '700', color: '#000000' }}>OTP Verification</Typography>
                  <Typography sx={{fontSize:'16px', fontWeight:'400', color:'#929292'}}>Please enter your OTP sent in your registered<br/>Email ID for recovery password.</Typography>
                  <Formik
                    initialValues={{ otp: '' }}
                    validationSchema={validationSchema1}
                    onSubmit={(values, { setSubmitting }) => handleVerifyOtp(values, setSubmitting)}
                  >
                    {({ values, handleChange, errors, touched, isValid, dirty }) => (
                      <Form noValidate>
                        <Field as={TextField} label="Enter OTP" variant="outlined" fullWidth sx={{ marginTop: 2, mt: 2, borderRadius: '50px', '& .MuiOutlinedInput-root': {borderRadius: '50px',backgroundColor: '#FAFAFA'} }} name="otp" value={values.otp} onChange={handleChange}
                          error={touched.otp && Boolean(errors.otp)} helperText={touched.otp && errors.otp} />
                        <Button type="submit" variant="contained" fullWidth disabled={!(isValid && dirty)} sx={{ marginTop: 2, borderRadius: '50px', color:'#ffffff' }}>Submit</Button>
                      </Form>
                    )}
                  </Formik>
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" >
                      <Typography variant="body2" color="primary" sx={{'&:hover': {textDecoration:'underline'}, fontWeight:'600', cursor:'pointer'}} onClick={()=>handleResendOtp()}>Resend OTP</Typography>
                      <Box display='flex' flexDirection='row' alignItems='center' gap={0.3} sx={{cursor:'pointer'}} onClick={()=> navigate('/help')}>
                          <Typography variant="body2" color="primary" sx={{'&:hover': {textDecoration:'underline'}}}>Help</Typography>
                          <HelpOutlineIcon color="primary" sx={{ fontSize: '16px' }} />
                      </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {pageNumber === 2 && (
              <Box display="flex" justifyContent="center" alignItems="center" height='100%' width="100%">
                <Box display='flex' flexDirection='column' gap={4}>
                <Box display='flex' flexDirection='column' gap={1}>
                  <Typography sx={{fontSize:'32px', fontWeight:'700', color:'#000000'}}>Create New Password</Typography>
                  <Typography sx={{fontSize:'16px', fontWeight:'400', color:'#929292'}}>Please enter your new password here.</Typography>
                </Box>
                  <Formik
                    initialValues={{ password: '', confirmPassword: '' }}
                    validationSchema={validationSchema2}
                    onSubmit={(values, { setSubmitting }) => handleSetNewPassword(values, setSubmitting)}
                  >
                    {({ values, handleChange, errors, touched, isValid, dirty }) => (
                      <Form noValidate>
                        <Box display='flex' flexDirection='column' gap={0}>
                        <Field as={TextField} label="Create new password" sx={{ marginTop: 2, mt: 2, borderRadius: '50px', '& .MuiOutlinedInput-root': {borderRadius: '50px',backgroundColor: '#FAFAFA'} }} type={showPassword ? 'text' : 'password'} name="password" value={values.password}
                          onChange={handleChange} error={touched.password && Boolean(errors.password)} helperText={touched.password && errors.password}
                          InputProps={{ endAdornment: <InputAdornment position="end"><IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}</IconButton></InputAdornment> }} />
                        <Field as={TextField} label="Confirm New Password" sx={{ marginTop: 2, mt: 2, borderRadius: '50px', '& .MuiOutlinedInput-root': {borderRadius: '50px',backgroundColor: '#FAFAFA'} }} type={showPassword ? 'text' : 'password'} name="confirmPassword" value={values.confirmPassword}
                          onChange={handleChange} error={touched.confirmPassword && Boolean(errors.confirmPassword)} helperText={touched.confirmPassword && errors.confirmPassword}
                          InputProps={{ endAdornment: <InputAdornment position="end"><IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}</IconButton></InputAdornment> }} />
                          </Box>
                        <Button type="submit" variant="contained" fullWidth disabled={!(isValid && dirty)} sx={{ marginTop: 2, borderRadius: '20px', color:'#ffffff' }}>Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            )}
            
            <Box display="flex" justifyContent="center" width="100%">
              <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
                <Box display='flex' flexDirection='row' alignItems='center' gap={4}>
                  <img src={ISO9001} alt='ISO9001 logo' />
                  <img src={ISO27001} alt='ISO27001 logo' />
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                  <Typography sx={{ color: '#545454', fontSize: '12px' }}>Powered by</Typography>
                  <img src={netzeroLogo} alt='netzero logo' />
                </Box>
              </Box>
            </Box>
  
            <Box display="flex" flexDirection='row' alignItems="center" gap={0} sx={{ position: 'absolute', right: '8px', bottom: '8px' }}>
              <CopyrightIcon sx={{ fontSize: '18px', color: '#C3C3C3' }} />
              <img src={iEnergyLogo} alt='iEnergyLogo' style={{ width: '60px' }} />
            </Box>
          </Box>
        </Grid>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={handleSnackbarClose}
        />
      </Grid>
    );
}
