import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Popper, Typography
} from "@mui/material";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { locationMark } from "../../constant";
import L from "leaflet";
import MapSearchBox from "./MapSearchBox";
import { getUserNearbyLocations } from "../../Apis/apiCall";
import { useDispatch } from "react-redux";
import { addLocationID, addSiteLocation } from "../../redux/LossControl/actions";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CustomPopper = (props) => {
  return (
    <Popper
      {...props}
      modifiers={[{ name: "offset", options: { offset: [0, 4] } }]}
    />
  );
};

export default function SelectSiteLocation({ handleNext }) {
  const [centralLocation, setCentralLocation] = useState([0, 0]);
  const [nearbyLocations, setNearbyLocations] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [mapType, setMapType] = useState("map"); // 'map' for normal, 'satellite' for satellite view
  const navigate = useNavigate();

  const pastTourNavigate = (id) => {
    navigate(`/module/loss-control/create-tour/${id}`);
  };

  const dispatch = useDispatch();

  const [plants, setPlants] = useState([]);

  // Custom icons for markers
  const defaultIcon = L.icon({
    iconUrl: `${locationMark}`,
    iconSize: [20, 32],
    iconAnchor: [15, 45],
  });

  const currentLocationIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  L.Marker.prototype.options.icon = defaultIcon;

  // Haversine formula to calculate distance between two points in km
  function haversineDistance(coords1, coords2) {
    const toRad = (x) => (x * Math.PI) / 180;

    const lat1 = coords1[0];
    const lon1 = coords1[1];
    const lat2 = coords2[0];
    const lon2 = coords2[1];

    const R = 6371; // Radius of Earth in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const ans = R * c;
    return ans.toFixed(1);
  }

  // Component to update the map's center
  function MapCenter({ center }) {
    const map = useMap();
    useEffect(() => {
      map.setView(center);
    }, [center, map]);

    return null;
  }

  const handleMapTypeChange = (event, newMapType) => {
    if (newMapType !== null) {
      setMapType(newMapType);
    }
  };

  const tileLayerURL =
    mapType === "map"
      ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Normal map
      : "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"; // Satellite map

  // Function to filter nearby plants within 10km of the selected HQ
  const filterNearbyPlants = (hqCoords) => {
    return plants.filter(
      (plant) => haversineDistance(hqCoords, plant.coords) <= 10
    );
  };

  const handlePlantSelect = (event, newValue) => {
    if (newValue) {
      const plantCoords = newValue.coords;
      setCentralLocation(plantCoords);
      setNearbyLocations([]); // Show only the selected plant
      setSelectedPlant(newValue);
      dispatch(addSiteLocation(newValue.displayName));
      dispatch(addLocationID(newValue.id));
    }
  };

  const locateDevice = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const coords = [latitude, longitude];
          setDeviceLocation(coords);
          setCentralLocation(coords);
          setNearbyLocations(filterNearbyPlants(coords)); // Filter plants within 10km
          setSelectedPlant(null); // Reset selected plant
        },
        (error) => console.error(error),
        { enableHighAccuracy: true }
      );
    }
  };

  // Fetch the user's current location when the component mounts
  useEffect(() => {
    locateDevice();
  }, []);
  // console.log("selectedPlant", selectedPlant);

  // ----------------------------------Api Calls---------------------------------
  const fetchNearbyLocations = async () => {
    try {
      const result = await getUserNearbyLocations();
      //   console.log(result.data.data);

      // Extract coordinates from the array of objects
      const arr = result.data.data;
      const plantsWithCoords = arr.map((plant) => ({
        ...plant,
        coords: [plant.latitude, plant.longitude],
      }));

      setPlants(plantsWithCoords);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchNearbyLocations();
  }, []);

  //To hide the bottom label
  useEffect(() => {
    const bottomControls = document.getElementsByClassName('leaflet-bottom');
    for (let i = 0; i < bottomControls.length; i++) {
      bottomControls[i].style.zIndex = '-1';
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          padding: "16px",
          minHeight: "560px",
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          gap={1}
          sx={{ position: "absolute", top: "24px", left: "24px" }}
        >
          {/* Autocomplete Dropdown for selecting plants */}
          <Box display='flex' flexDirection='column'>
            <MapSearchBox
              plants={plants}
              handlePlantSelect={handlePlantSelect}
              deviceLocation={deviceLocation || [27.332648182377387, 95.29226287437059]}
              getDistance={haversineDistance}
              CustomPopper={CustomPopper}
              placeholder="Search plant"
              width="300px"
            />

            {/* Conditionally render the box below the dropdown when a plant is selected */}
            {selectedPlant !== null && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#ffffff',
                  padding: '10px',
                  marginTop: '4px',
                  borderRadius: '8px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                  zIndex: 1000,
                }}
                onClick={() => pastTourNavigate(selectedPlant.id)}
              >
                <Typography variant="body2" sx={{ color: '#1890FF', fontWeight: 500, textDecoration: 'underline' }}>
                  Past Loss Control Tours
                </Typography>
                <ArrowForwardIcon sx={{ color: '#1890FF' }} />
              </Box>
            )}
          </Box>

          {/* My Location Icon to recenter to device location */}
          <IconButton
            sx={{
              backgroundColor: "white",
              zIndex: 1000,
            }}
            onClick={locateDevice}
          >
            <MyLocationIcon />
          </IconButton>

          {/* For map/satellite view */}
          <ToggleButtonGroup
            value={mapType}
            exclusive
            onChange={handleMapTypeChange}
            style={{ zIndex: 1000, height: "40px", backgroundColor: "white" }}
          >
            <ToggleButton
              value="map"
              sx={{
                backgroundColor: "white",
                color: "black",
                "&.Mui-selected": {
                  backgroundColor: "#09A2E3",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                },
              }}
            >
              Map
            </ToggleButton>
            <ToggleButton
              value="satellite"
              sx={{
                backgroundColor: "white",
                color: "black",
                "&.Mui-selected": {
                  backgroundColor: "#09A2E3",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                },
              }}
            >
              Satellite
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* OpenStreetMap integration */}
        <MapContainer
          center={[27.332648182377387, 95.29226287437059]}
          zoom={13}
          style={{ height: "550px", width: "100%" }}
          zoomControl={false}
        >
          <TileLayer
            url={tileLayerURL}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* Custom component to update the map center */}
          <MapCenter center={[27.332648182377387, 95.29226287437059]} />

          {/* Marker for current device location */}
          {deviceLocation && (
            <Marker position={deviceLocation} icon={currentLocationIcon}>
              <Popup>Your Location</Popup>
            </Marker>
          )}

          {/* Marker for selected plant */}
          {selectedPlant && (
            <Marker position={selectedPlant?.coords} icon={defaultIcon}>
              <Popup>{selectedPlant?.locationCode}</Popup>
            </Marker>
          )}

          {/* Markers for nearby plants */}
          {nearbyLocations?.map((location, index) => (
            <Marker key={index} position={location.coords}>
              <Popup>{location.locationCode}</Popup>
            </Marker>
          ))}
          <ZoomControl position="topright" />

          <Box
            sx={{
              position: 'absolute',
              bottom: 16, // Adjust spacing from bottom as needed
              right: 16,  // Adjust spacing from right as needed
              zIndex: 1000, // Ensures it overlays on top of other elements
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleNext()}
              disabled={selectedPlant === null}
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                backgroundColor: "#09A2E3",
                color: "#ffffff",
                paddingY: "8px",
                paddingX: "32px",
                borderRadius: "24px",
              }}
            >
              Choose Discipline
            </Button>
          </Box>
        </MapContainer>


      </Box>
    </>
  );
}
