import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const AlertModel = ({ open, onClose, onProceed, title, description }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title">{title}</DialogTitle> */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button onClick={onClose} color="error" variant='outlined'>
            Cancel
          </Button>
          <Button onClick={onProceed}  sx={{backgroundColor: '#00A5E3', color:"#fff"}}  variant ='contained' autoFocus>
            Proceed
          </Button>
        </DialogActions>
      
    </Dialog>
  );
};

export default AlertModel;
