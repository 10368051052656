import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
  Divider,
  Switch,
  Checkbox,
  FormControlLabel,
  Paper,
  Chip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "./CustomInputField";
import CustomSelect from "./CustomSelectField";
import CustomButtonGroup from "./CustomButtonGroup";
import CustomMultipleSelect from "./MutlipleSelect"
import CheckboxSelect from "./CustomeCheckInput";
import BasicDateTimePicker from "./CustomDateTime";
import CustomeDatePicker from "./CustomeDate"
import CardEditDelete from "./CardEditDelete";
import { useDispatch, useSelector } from 'react-redux';
import { updateSwitchState, handleChangeDrawerFormData, addPersonnelDetail, addCAPADetail, addEvidenceDetail, removeInvestigationMember } from '../redux/incidentWorkflow/actions';
import dayjs from 'dayjs';
import { getSubArea, getStaffAndEntity, getEntity, getCAPAOwner, getInvestigationLeadersList, getInvestigationEmployees } from '../Apis/apiCall'
import CustomMultiSelect from '../Components/CustomeMutliSelect';
import { assignCAPAFormFields } from '../utils/workFlowFormFields';

const togleStyle =
{
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#0AA2E3', // Color when checked (knob)
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#0AA2E3', // Color when checked (track)
  },
}
const AddButtonStyle = {
  fontSize: "16px",
  fontWeight: "600",
  paddingY: "8px",
  paddingX: "16px",
  color: "#fff",
  borderRadius: "60px",
  backgroundColor: "#0AA2E3",
  width: "70px",
  textTransform: "none"
};

const genderList = ["Male", "Female", "Other"];


const RightDrawer = ({ status, open, onClose, title, fields = [], cardData = [], data
}) => {
  console.log("props", fields, title, cardData);
  const dispatch = useDispatch();
  const formData = useSelector(state => state.incidentWorkFlowReducer);
  const {
    Return,
    Review,
    Assign_Investigation,
    CAPA,
    CAPADetail,
    switchStates,
    formEdit
  } = formData
  console.log("Return", Return?.["Return to previous level"], Review?.["Review completed"])
  const [tabValue, setTabValue] = React.useState('1');
  const [staffNameList, setStaffNameList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [subAreaList, setSubAreaList] = useState([]);
  const [CAPAOwnerList, setCAPAOwnerList] = useState([]);
  const [investigationLeaderList, setInvestigationLeaderList] = useState([]);
  const [investigationEmployees, setInvestigationEmployees] = useState([]);
  const loginDataString = localStorage.getItem('loginData');
  const logindata = JSON.parse(loginDataString);
  const { orgID } = logindata;



  useEffect(() => {
    if (CAPA && CAPA["Area"]) { // Ensure CAPA exists and has Area
      fetchSubArea(CAPA["Area"]);
    }
    fetchCAPAOwner();

    fetchInvestigationLeaderName(orgID);
    fetchInvestigationEmployeeName(orgID);


  }, [CAPA]);


  const fetchSubArea = async (data) => {
    try {
      const result = await getSubArea(data);
      console.log(result.data.data);
      setSubAreaList(result.data.data); // Set the formatted staff list
    } catch (error) {
      console.log(error);
    }
  }

  const fetchInvestigationLeaderName = async (id) => {
    try {
      const result = await getInvestigationLeadersList(id);
      console.log(result.data.data);
      const leaderList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: staffMember.name
      }));
      setInvestigationLeaderList(leaderList); // Set the formatted staff list
    } catch (error) {
      console.log(error);
    }
  }

  const fetchInvestigationEmployeeName = async (id) => {
    try {
      const result = await getInvestigationEmployees(id);
      console.log(result.data.data);
      const employeelist = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: staffMember.name
      }));
      setInvestigationEmployees(employeelist); // Set the formatted staff list
    } catch (error) {
      console.log(error);
    }
  }


  const getNameById = (id, list) => {
    const item = list.find((entry) => entry.value === id);
    return item ? item.label : "Unknown";
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchCAPAOwner = async () => {
    try {
      const result = await getCAPAOwner();
      console.log(result.data.data);
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: `${staffMember.firstName} ${staffMember.lastName}`
      }));
      setCAPAOwnerList(formattedStaffList);

    } catch (error) {
      console.log(error);
    }
  }


  const handleInputChange = (e, fieldName, title) => {
    const { value } = e.target;
    if (fieldName === 'Age' || fieldName === 'LTI Hours' || fieldName === "Hours at Work") {
      if (value.length <= 2) {
        dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
      }
    } else {
      dispatch(handleChangeDrawerFormData({ fieldName, value, title }));

    }
  };

  const handleSelectChange = (label, value, title) => {
    if (label === 'Age' || label === 'LTI Hours' || label === "Hours at Work") {
      if (value.length <= 2) {
        dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));
      }
    } else {
      dispatch(handleChangeDrawerFormData({ fieldName: label, value, title }));

    }


  };

  const handleButtonChange = (fieldName, value, title) => {
    dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
  };

  const handleDateChange = (newValue, label, title) => {
    const formattedDateTime = dayjs(newValue).format('DD-MM-YYYY');
    dispatch(handleChangeDrawerFormData({ fieldName: label, value: formattedDateTime, title }));
  };

  const handleSelect = (value, fieldName) => {
    dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
    if (fieldName === "High Potential Near Miss") {
      dispatch(handleChangeDrawerFormData({ fieldName: "Investigation Recommended", value: value, title }));
    }

  };

  const handleToggle = (name, sectionName) => {
    // Map sectionName to its corresponding detail
    const sectionDetailsMap = {
      Investigation: Assign_Investigation && Object.keys(Assign_Investigation).length > 0 ? Assign_Investigation : []
    };

    // Check if the specific section's detail is not empty
    const hasDetailsForSection = sectionDetailsMap[sectionName] && Object.keys(sectionDetailsMap[sectionName]).length > 0;

    if (name === 'Yes') {
      // If clicked "Yes", update switch state to "Yes"
      dispatch(updateSwitchState(sectionName, name));
    } else {
      // If section has details (like Investigation), keep it "Yes"
      if (hasDetailsForSection) {
        dispatch(updateSwitchState(sectionName, "Yes")); // Keep it as "Yes" if there are details
      } else {
        // If no details, allow toggling to "No"
        dispatch(updateSwitchState(sectionName, "No"));
      }
    }
  };


  const SubmitFormData = async (title) => {
    console.log("hhhhh", title)
    const isObjectValid = (obj) => {
      return obj && typeof obj === 'object' && Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
    };

    switch (title) {
      case "Return":
        if (isObjectValid(Return)) {
          //dispatch(addVehicleDetail(Return));
          onClose();
        }
        break;
      case "Review":
        if (isObjectValid(Review)) {
          onClose();
        }
        break;

      case "CAPA":
        if (isObjectValid(CAPA)) {
          dispatch(addCAPADetail(CAPA));
        }
        break;

      case "Assign_Investigation":
        if (isObjectValid(Assign_Investigation)) {
          onClose();
        }
        break;


      default:
        console.warn("Invalid title provided");
    }
  };




  const renderField = (field, title) => {

    let isSelected;
    const fieldValue = formData?.[title]?.[field.formFieldName] || '';
    if (field.formFieldName === "Employee Type") {
      isSelected = formData?.[title]?.["Type"] ? "Yes" : null;
    }

    const isOISDMajorFire = field.formFieldName === "OISD Major Fire" &&
      (formData?.[title]?.["OISD"] === "Major") &&
      (formData?.[title]?.["OISD Fire"] === true);

    const isOISDMinorFire = field.formFieldName === "OISD Major Fire" &&
      (formData?.[title]?.["OISD"] === "Minor") &&
      (formData?.[title]?.["OISD Fire"] === true);

    const isPNGRBMajorFire = field.formFieldName === "PNGRB Major Fire" &&
      formData?.[title]?.["PNGRB"] === "Major" &&
      (formData?.[title]?.["PNGRB Fire"] === true);

    const isPNGRBMinorFire = field.formFieldName === "PNGRB Major Fire" &&
      (formData?.[title]?.["PNGRB"] === "Minor") &&
      (formData?.[title]?.["PNGRB Fire"] === true);

    switch (field.type) {
      case 'InputBox':
        return (
          <CustomInput
            placeholder={field.formFieldName}
            label={field.formFieldName}
            name={field.formFieldName}
            value={fieldValue}
            type={field?.dataType === "String" ? "text" : "number"}
            onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
          />
        );

      case 'Dropdown': {
        return (
          <>
            {field?.formFieldName === "Body Parts Involved" &&
              <CustomMultipleSelect
                label={field.formFieldName}
                value={fieldValue}
                options={field?.options || ""}
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            }
            {field.formFieldName === "Investigation Members" &&
              <CustomMultiSelect
                label={field.formFieldName}
                value={fieldValue}
                options={investigationEmployees || ""}
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            }
            {isOISDMajorFire &&
              <CustomSelect
                label={"Major Fire"}
                placeholder={"Major Fire Category"}
                value={fieldValue}
                options={field?.options || ""}
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            } {
              isOISDMinorFire &&
              <CustomSelect
                label={"Minor Fire"}
                placeholder={"Minor Fire Category"}
                value={fieldValue}
                options={field?.options || ""}
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            } {isPNGRBMajorFire &&
              <CustomSelect
                label={"Major Fire"}
                placeholder={"Major Fire Category"}
                value={fieldValue}
                options={field?.options || ""}
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            }{isPNGRBMinorFire &&
              <CustomSelect
                label={"Minor Fire"}
                placeholder={"Minor Fire Category"}
                value={fieldValue}
                options={field?.options || ""}
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            }{
              (field.formFieldName !== "PNGRB Major Fire" && field.formFieldName !== "OISD Major Fire" && field.formFieldName !== "Investigation Members")
              &&
              <CustomSelect
                label={field.formFieldName}
                value={fieldValue}
                options={
                  field.formFieldName === "Name"
                    ? staffNameList
                    : field.formFieldName === "Entity"
                      ? entityList
                      : field.formFieldName === "Gender"
                        ? genderList
                        : field.formFieldName === "Sub Area"
                          ? subAreaList
                          : field.formFieldName === "CAPA Owner"
                            ? CAPAOwnerList
                            : field.formFieldName === "Investigation Leader"
                              ? investigationLeaderList
                              : field?.options || ""
                }
                onChange={(e) => handleInputChange(e, field.formFieldName, title)}
              />
            }
          </>
        );
      }

      case 'TextArea':
        return (
          <CustomInput
            placeholder={field.formFieldName}
            label={field.formFieldName}
            multiline
            rows={4}
            type={field?.dataType === "String" ? "text" : "number"}
            value={fieldValue}
            onChange={(e) => handleSelectChange(field.formFieldName, e.target.value, title)}
          />
        );


      case 'ButtonGroup':
        return (
          <>
            {(field.formFieldName === "Severity" || field.formFieldName === "Incident Type") ?
              <>
                <Typography variant='body2'>
                  {field.formFieldName}
                </Typography>
                <Paper
                  elevation={0}
                  sx={{
                    border: '1px solid #B7C8E5',
                    paddingX: '16px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4} display='flex' flexDirection='column' gap={0.5}>
                      <Typography variant="caption" sx={{ color: '#666F7F' }}>
                        Reported
                      </Typography>
                      <Box
                        sx={{
                          backgroundColor: '#F7F7F7',
                          padding: '4px 8px',
                          borderRadius: '4px',
                          width: 'fit-content',
                          fontWeight: 500,
                          color: '#A4B2CC',
                        }}
                      >
                        {data?.[field.formFieldName]}
                      </Box>
                    </Grid>
                    {(status === "Investigation Completed" || status === "Investigation Resubmission" || status === "Investigation Under Approval" || status === "Investigation Approval Resubmission") ?
                      <>
                        <Grid item xs={8} display='flex' flexDirection='column' gap={0.5}>
                          <Typography variant="caption" sx={{ color: '#666F7F' }}>
                            Suggested by Investigator
                          </Typography>

                          {field.formFieldName === "Incident Type" && (
                            <Typography
                              variant="caption"
                              sx={{ fontSize: "9px", color: '#0AA2E3', textDecoration: 'underline' }} // Added margin left for spacing
                            >
                              {fieldValue === "UA" ? "Unsafe Act" :

                                fieldValue === "UC" ? "Unsafe Condition" :

                                  fieldValue === "NM" ? "Near Miss" :
                                    fieldValue === "Minor" ? "Minor" :
                                      fieldValue === "MR" ? "Minor Reportable" :

                                        fieldValue === "DO" ? "Dangerous Occurrence" :

                                          fieldValue === "Serious" ? "Serious Bodily Injury"

                                            : "Fatal"
                              }
                            </Typography>
                          )}
                          <Box
                            sx={{
                              backgroundColor: '#F7F7F7',
                              padding: '4px 8px',
                              borderRadius: '4px',
                              width: 'fit-content',
                              fontWeight: 500,
                              color: '#A4B2CC',
                            }}
                          >
                            {fieldValue}
                          </Box>
                        </Grid>

                      </>
                      : (
                        (status != "Investigation Under Approval" || status != "Investigation Approval Resubmission") &&
                        <Grid item xs={8} display='flex' flexDirection='column' gap={0.5}>
                          <Box display='flex' flexDirection='row' justifyContent='space-between'>
                            <Typography variant="caption" sx={{ color: '#666F7F' }}>
                              {(status === "Investigation Completed" || status === "Investigation Resubmission" || status === "Investigation Under Approval" || status === "Investigation Approval Resubmission") ? "Suggested by Investigator" : "Suggested"}
                            </Typography>
                            {field.formFieldName === "Incident Type" && (
                              <Typography
                                variant="caption"
                                sx={{ fontSize: "9px", color: '#0AA2E3', textDecoration: 'underline' }} // Added margin left for spacing
                              >
                                {fieldValue === "UA" ? "Unsafe Act" :

                                  fieldValue === "UC" ? "Unsafe Condition" :

                                    fieldValue === "NM" ? "Near Miss" :
                                      fieldValue === "Minor" ? "Minor" :
                                        fieldValue === "MR" ? "Minor Reportable" :

                                          fieldValue === "DO" ? "Dangerous Occurrence" :

                                            fieldValue === "Serious" ? "Serious Bodily Injury"

                                              : "Fatal"
                                }
                              </Typography>
                            )}
                          </Box>
                          <CustomButtonGroup
                            buttonNames={field?.options || []}
                            selected={fieldValue}
                            onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
                          />
                        </Grid>)
                    }


                  </Grid>
                </Paper>

              </>
              :
              <>
                {
                  (field.formFieldName !== "Final Incident Type" || field.formFieldName !== "Final Severity") &&
                  <>
                    <Typography variant='body2'>
                      {field.formFieldName}
                    </Typography>
                    <CustomButtonGroup
                      buttonNames={field?.options || []}
                      selected={fieldValue}
                      onSelect={(name) => handleButtonChange(field.formFieldName, name, title)}
                    />

                  </>
                }
              </>

            }
          </>
        );


      case 'DateTime':
        return (
          <BasicDateTimePicker
            label={field.formFieldName}
            value={fieldValue ? fieldValue : ""}
            onChange={(e) => handleDateChange(e, field.formFieldName, title)}
          />
        );
      case 'Date':
        return (
          <CustomeDatePicker
            label={field.formFieldName}
            value={fieldValue ? fieldValue : ""}
            onChange={(e) => handleDateChange(e, field.formFieldName, title)}
          />
        );
      case 'checkBox':
        return (
          <>
            {(field.formFieldName === "OISD Fire" && formData?.[title]?.["OISD"] && formData?.[title]?.["OISD"] != "Not Applicable") && (
              <CheckboxSelect
                label="fire"
                checked={Boolean(fieldValue)} // Ensure checked is always a boolean
                onChange={(newChecked) => handleSelect(newChecked, field.formFieldName)}
              />
            )}
            {(field.formFieldName === "PNGRB Fire" && formData?.[title]?.["PNGRB"] && formData?.[title]?.["PNGRB"] != "Not Applicable") && (
              <CheckboxSelect
                label="fire"
                checked={Boolean(fieldValue)} // Ensure checked is always a boolean
                onChange={(newChecked) => handleSelect(newChecked, field.formFieldName)}
              />
            )}
          </>
        );

      case 'toggleSwitch':
        return (
          <>
            {(field.formFieldName === "Initiate CAPA") ?
              <Box display='flex' alignItems='center'>
                <Typography variant="body2" sx={{ color: '#525966', mr: "2px" }}>{field.formFieldName}</Typography>
                <Typography variant="caption" sx={{ color: '#0AA2E3' }}>({CAPADetail?.length + " " + "CAPA Assigned"})</Typography>

                <Switch size="small" style={{ ...togleStyle }}
                  checked={fieldValue || false}
                  onChange={(e) => handleSelect(e.target.checked, field.formFieldName)}
                />
              </Box> :
              <Box display='flex' alignItems='center'>
                <Typography variant="body2" sx={{ color: '#525966', mr: "1px" }}>{(field.formFieldName === "Investigation Recommended" && formData?.[title]?.["High Potential Near Miss"] === true) ? "Investigation Required" : field.formFieldName}</Typography>
                <Switch size="small" style={{ ...togleStyle }}
                  checked={fieldValue || false}
                  onChange={(e) => handleSelect(e.target.checked, field.formFieldName)}
                />
              </Box>}

          </>
        );



      default:
        return null;
    }
  };


  const renderFields = (fields, title, data) => {
    return (
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid
            item
            xs={12}
            md={
              field.formFieldName === "Severity" ||
                field.formFieldName === "Details not covered above" ||
                field.formFieldName === "Distance From The Face" ||
                field.formFieldName === "CAPA Description" ||
                field.formFieldName === "Equipment Incident Details" ||
                field.formFieldName === "Incident Type" ||
                field.formFieldName === "Review Date" ||
                field.formFieldName === "Review Input" ||
                field.formFieldName === "Return to previous level" ||
                field.formFieldName === "Investigation recommended" ||
                field.formFieldName === "Review  completed" ||
                field.type === "DesignerButtonGroup" ||
                field.formFieldName === "Observation" ||
                field.formFieldName === "Body Parts Involved" ||
                field.formFieldName === "Investigation Leader" ||
                field.formFieldName === "Investigation Members" ||
                field.formFieldName === "Target completion date" ||
                field.formFieldName === "Input On Severity and Incident Type" ||
                field.formFieldName === "Approved Investigation" ||
                field.formFieldName === "Initiate CAPA" ||
                field.formFieldName === "Additional Remarks" ||
                field.formFieldName === "Description of Other Training" ? 12 :
                field.formFieldName === "OISD" || field.formFieldName === "PNGRB" || field.formFieldName === "PNGRB Major Fire" || field.formFieldName === "OISD Major Fire" ? 5 : field.formFieldName === "OISD Fire" || field.formFieldName === "PNGRB Fire" ? 2 :
                  6
            }

            key={field.formFieldName}
          >
            {renderField(field, title)}
          </Grid>
        ))}
      </Grid>
    );
  };



  return (
    <Drawer
      anchor="right" // You can pass 'right', 'left', 'top', 'bottom'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: "36%", padding: '0px !important' }, // Customize drawer style
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >

        <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
          {title.replace(/_/g, ' ')} {/* Replaces all underscores with spaces */}
        </Typography>


      </Grid>

      {title === "CAPA" ?
        (
          <>
            <Box display='flex' justifyContent="flex-end">
              <Switch
                checked={switchStates.CAPA === 'Yes'}
                onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', 'CAPA')}
                sx={{ ...togleStyle }}
              />
            </Box>
            {switchStates.CAPA === 'Yes' && (
              <div style={{ padding: "28px 24px" }}>{fields.length > 0 && renderFields(fields, title)}

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} mt={3}>
                    <Button
                      variant="contained"
                      onClick={() => SubmitFormData(title)}
                      sx={{
                        ...AddButtonStyle
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {cardData.length > 0 && (<CardEditDelete cardData={cardData} title={title} />)}
                  </Grid>
                </Grid>
              </div>)}
          </>
        ) :
        (
          <>
            {(title === "Assign_Investigation") &&
              (<Box display='flex' justifyContent="flex-end">
                <Switch
                  checked={switchStates.Investigation === 'Yes'}
                  onChange={(e) => handleToggle(e.target.checked ? 'Yes' : 'No', 'Investigation')}
                  sx={{ ...togleStyle }}
                />
              </Box>
              )}
            {
                (switchStates.Investigation === 'Yes' && title === "Assign_Investigation") ? (
                  <div style={{ padding: "28px 24px" }}>{fields.length > 0 && renderFields(fields, title)}
                    {title === "Assign_Investigation" &&
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} sx={{ mt: "12px" }}>
                          <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1'>Investigation Leader:</Typography>
                          <Typography variant='caption'>
                            {Assign_Investigation?.["Investigation Leader"]
                              ? getNameById(Assign_Investigation["Investigation Leader"], investigationLeaderList)
                              : "N/A"}
                          </Typography>
                        </Grid>

                        {/* Investigation Members */}
                        <Grid item xs={12}>
                          <Typography variant='body1'>Investigation Members:</Typography>
                          <Grid container spacing={1}>
                            {Assign_Investigation?.["Investigation Members"] &&
                              Assign_Investigation["Investigation Members"].map((memberId, index) => (
                                <Grid item key={index}>
                                  <Chip
                                    label={getNameById(memberId, investigationEmployees)}
                                    onDelete={() => dispatch(removeInvestigationMember(memberId))}
                                    sx={{ color: "#4591F4", bgcolor: "#4591f452" }}
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    }

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4} mt={3}>
                        <Button
                          disabled={

                            title === "Review" ? ((!Review?.["Review completed"] || Review?.["Review completed"] === false) && (!Review?.["Investigation Reviewed"] || Review?.["Investigation Reviewed"] === false)) // This explicitly checks for false
                              : title === "Return" ? (!Return?.["Return to previous level"] || Return?.["Return to previous level"] === false) : false
                          }
                          variant="contained"
                          onClick={() => SubmitFormData(title)}
                          sx={{
                            ...AddButtonStyle
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                      {title !== "Assign Investigation" &&
                        (<Grid item xs={12} sm={12}>
                          <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                        </Grid>
                        )}

                    </Grid>
                  </div>
                ) :
                  (
                    title != "Assign_Investigation" && (
                      <div style={{ padding: "28px 24px" }}>{fields.length > 0 && (
                        <>
                          {title !== "Return" ? (
                            (status === "Investigation Approval Resubmission" || status === "Investigation Under Approval") ?
                              <>
                                {renderFields(fields.slice(0, 5), title)}


                                <>
                                  <Typography variant="body2" sx={{ mt: "12px" }}>
                                    Incident Classification
                                  </Typography>
                                  <div style={{ padding: "16px", border: "1px solid #ccc", borderRadius: "8px" }}>
                                    {renderFields(fields.slice(5, 13), title)}
                                  </div>
                                </>


                                <div style={{ marginTop: "16px" }}>
                                  {renderFields(fields.slice(13), title)}
                                </div>
                              </> :
                              <>
                                {renderFields(fields.slice(0, 3), title)}

                                {fields.length > 2 && (
                                  <>
                                    <Typography variant="body2" sx={{ mt: "12px" }}>
                                      Incident Classification
                                    </Typography>
                                    <div style={{ padding: "16px", border: "1px solid #ccc", borderRadius: "8px" }}>
                                      {renderFields(fields.slice(3, 11), title)}
                                    </div>
                                  </>
                                )}

                                <div style={{ marginTop: "16px" }}>
                                  {renderFields(fields.slice(11), title)}
                                </div>
                              </>
                          ) : (
                            renderFields(fields, title)
                          )}
                        </>

                      )}


                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={4} mt={3}>
                            <Button
                              disabled={

                                title === "Review" ? ((!Review?.["Review completed"] || Review?.["Review completed"] === false) && (!Review?.["Investigation Reviewed"] || Review?.["Investigation Reviewed"] === false) && (!Review?.["Approved Investigation"] || Review?.["Approved Investigation"] === false)) // This explicitly checks for false
                                  : title === "Return" ? (!Return?.["Return to previous level"] || Return?.["Return to previous level"] === false) : false
                              }
                              variant="contained"
                              onClick={() => SubmitFormData(title)}
                              sx={{
                                ...AddButtonStyle
                              }}
                            >
                              Save
                            </Button>
                          </Grid>
                          {/* {title !== "Assign Investigation" &&
                            (<Grid item xs={12} sm={12}>
                              <Divider sx={{ backgroundColor: '#C1C1C1', height: '2px' }} />
                            </Grid>
                            )} */}

                        </Grid>
                      </div>
                    )
                  )
                }
              

          </>
        )}
      <Fab
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "100px",
          right: open ? "37.3%" : "0", // Adjust this value based on drawer width
          transform: "translateX(50%)",
          zIndex: 1200,
          height: "36px",
          width: "36px",
          backgroundColor: "#0AA2E3",
          borderRadius: "60px 0 0 60px",
        }}
      >
        <CloseIcon />
      </Fab>
    </Drawer>
  );
};

export default RightDrawer;
