import { combineReducers } from 'redux';
import loadingReducer from "./Loader/loadingReducer";
import incidentReportFromReducer from './incidentReport/incidentFormReducer'
import incidentWorkFlowReducer from './incidentWorkflow/incidentWorkflowReducer'
import incidentInvestigationFlowReducer from './incidentInvestflow/incidentInvestigationFlowReducer'
import stepReducer from './stepper/stepReducer'
import lossControlReducer from "./LossControl/lossControlReducer"
import lossWorkFlowReducer from "./LossWorkFlow/lossWorkFlowReducer";
import { auditReducer } from './audit/auditReducer';



const rootReducer = combineReducers({
  stepReducer:stepReducer,
  loadingReducer:loadingReducer,
  incidentReportFromReducer :incidentReportFromReducer,
  incidentWorkFlowReducer:incidentWorkFlowReducer,
  incidentInvestigationFlowReducer:incidentInvestigationFlowReducer,
  lossControlReducer:lossControlReducer,
  lossWorkFlowReducer:lossWorkFlowReducer,
  auditReducer:auditReducer
});

export default rootReducer;
