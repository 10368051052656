import { Box, Typography, Button } from '@mui/material';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

const CauseCard = ({ title, causeType, RootCauseAnalysis1 }) => {
    return (
        <Box
            sx={{
                borderRadius: "8px",
                background: "#FFFFFF",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                marginTop: "12px",
            }}
        >
            <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    backgroundColor: "#09A2E3",
                    minHeight: "56px",
                    borderRadius: "8px 8px 0 0",
                    padding: "8px",
                    overflow: "hidden",
                }}
            >
                <Box display="flex" alignItems="center">
                    <Box
                        sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: "none",
                            marginRight: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <TroubleshootIcon
                            sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                        />
                    </Box>
                    <Typography sx={{ fontSize: "20px", fontWeight: "600", color: "#ffffff" }}>
                        {title}
                    </Typography>
                </Box>

            </Box>

            <Box sx={{ padding: "16px" }}>
                <Typography variant="body2">
                    {RootCauseAnalysis1?.[causeType]}
                </Typography>
                {RootCauseAnalysis1?.[causeType + "Descriptions"]?.map((description, index) => (
                    <Typography key={index} variant="caption" sx={{ color: '#4D4D4D' }}>
                        {index + 1}. {description || "N/A"}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

const RootCauseCard = ({cardData}) => {
    return (
        <>
            {cardData?.map((cause, index) => (
                <Box
                    key={index}
                    sx={{
                        borderRadius: "8px",
                        background: "#FFFFFF",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                        minWidth: "370px",
                        maxWidth: "100%",
                        minHeight: '200px',
                        marginTop: "12px",
                    }}
                >
                    {/* Render Immediate Cause */}
                    <CauseCard
                        title="Immediate Cause"
                        causeType="immediateCause"
                        RootCauseAnalysis1={cause.immediateCause}
                       
                    />

                    {/* Render Indirect Cause */}
                    <CauseCard
                        title="Indirect Cause"
                        causeType="indirectCause"
                        RootCauseAnalysis1={cause.indirectCause}
                       
                    />

                    {/* Render Root Cause */}
                    <CauseCard
                        title="Root Cause"
                        causeType="rootCause"
                        RootCauseAnalysis1={cause.rootCause}
                       
                    />
                </Box>
            ))}
        </>
    )
}


export default RootCauseCard;



