import React, { useState, useEffect } from "react";
import {
    Drawer,
    Grid,
    Typography,
    Button,
    Fab,
    Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "../CustomInputField";
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { acceptOrRevertReportByMM } from '../../Apis/apiCall'
import { changeInputValue, changeExecutiveComment } from '../../redux/LossWorkFlow/actions'
import { useNavigate } from "react-router-dom";
import AcceptModal from "../../Components/LossControl/AcceptModal";
import ConfirmationModal from "./ConfirmationModal";
import Loader from "../../Components/Loader/Loader";
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant"

 
const AddButtonStyle = {
    fontSize: "16px",
    fontWeight: "400",
    paddingY: "8px",
    paddingX: "16px",
    color: "#fff",
    borderRadius: "60px",
    backgroundColor: "#0AA2E3",
    width: "auto",
    textTransform: "none"
};

const genderList = ["Male", "Female", "Other"];


const RightDrawer = ({ status, open, onClose, title, recommendation, questionID, flow, reportId, executiveRemark, executiveQues }) => {
    const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);
    const [acceptModalOpen, setAcceptModalOpen] = useState(false);
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');
    const navigate = useNavigate();
    console.log("props", status, open, onClose, title, recommendation, executiveRemark, executiveQues);
    // console.log("Right Drawer Opened")
    console.log("questionID", questionID);
    const formData = useSelector(state => state.lossWorkFlowReducer);
    const { mineManger } = formData
    const { executive } = formData
    console.log("executive", executive);
    const [executiveCommentValue, setExecutiveCommentValue] = useState(executive?.find((item) => (item.id === questionID))?.comment || '');

    const handleInputChange = (e, fieldName) => {
        const { value } = e.target;
        dispatch(changeInputValue({ fieldName, value }));

    };

    const handleExecutiveSave = () => {
        if (questionID && executiveCommentValue) {
            dispatch(changeExecutiveComment({ quesId: questionID, comment: executiveCommentValue }));
            onClose();
            setExecutiveCommentValue('');
        }
    };

    const handleAcceptModal = () => {
        setAcceptModalOpen(false);
        if (flow != 3) {
            navigate(`/my-actions`);
        }
        else {
            window.location.reload();

        }
    };


    const handleDateChange = (newValue, label, title) => {
        const formattedDateTime = dayjs(newValue).format('DD-MM-YYYY');
        //dispatch(handleChangeDrawerFormData({ fieldName: label, value: formattedDateTime, title }));
    };

    const handleSelect = (value, fieldName) => {
        //dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
    };

    const [mmConfirmation, setMmConfirmation] = useState(false);
    const handleMMReturn = async () => {
        dispatch({type : START_LOADING})
        try {
            let values = {
                action: "revert",
                questionID: questionID,
                comment: mineManger.comment
            }
            const result = await acceptOrRevertReportByMM(values, reportId);
            if (result) {
                setMessage1("Recommendations Returned");
                setMessage2(" ");
                setAcceptModalOpen(true);
            }

        } catch (error) {
            console.log(error);
        }
        finally{
            dispatch({ type: STOP_LOADING }); 
          }
    }
    const mmConfirm = () => {
        setMmConfirmation(false);
        handleMMReturn();
    };

    useEffect(() => {
        const foundComment = executive?.find((item) => item.id === questionID)?.comment || '';
        setExecutiveCommentValue(foundComment);
    }, [executive, questionID]);




    return (
        <>
        {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}

<Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { width: "36%", padding: '0px !important' },
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    paddingY: "16px",
                    paddingX: "24px",
                    borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
                    boxShadow: "0px 2px 4px 0px #ABABAB",
                }}
            >
                <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    {title.replace(/_/g, ' ')}
                </Typography>
            </Grid>

            <div style={{ padding: "28px 24px" }}>
                <Typography variant="body2">
                    {recommendation?.replace(/_/g, ' ')}
                </Typography>

                {/* {flow != 3 && <Box sx={{ backgroundColor: '#F5FBFE', p: 1, borderRadius: 1, mt: '12px' }}>
                    <Typography>Remark</Typography>
                    <Box sx={{ color: "#525966" }}>
                        {executiveRemark?.length > 0
                            ? executiveRemark?.map((remark, index) => (
                                <Typography key={index} sx={{ color: '#525966' }}>{remark.comment}</Typography>
                            ))
                            : "No remark"}
                    </Box>
                </Box>
                } */}
                <Box my={2} p={3} sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }} borderRadius={4}>

                    <Typography sx={{ mb: 2, fontSize: '14px' }}>
                        {executiveQues}
                    </Typography>
                    {flow === 3 ? (
                        <CustomInput
                            placeholder={"Comment"}
                            label={"Comment"}
                            multiline
                            rows={4}
                            type={"text"}
                            value={mineManger?.comment}
                            onChange={(e) => handleInputChange(e, "comment", title)}
                        />) : (
                        <CustomInput
                            placeholder={"Comment"}
                            label={"Comment"}
                            multiline
                            rows={4}
                            type={"text"}
                            value={executiveCommentValue}
                            onChange={(e) => setExecutiveCommentValue(e.target.value)}
                        />
                    )}
                </Box>
                <Grid item xs={12} mt={3}>
                    {flow === 3 ? (
                        <Button
                            disabled={!mineManger?.comment}
                            variant="contained"
                            onClick={() => setMmConfirmation(true)}
                            sx={{
                                ...AddButtonStyle,
                                float: "right",
                            }}
                        >
                            Submit
                        </Button>
                    ) : (
                        <Button
                            disabled={!executiveCommentValue}
                            variant="contained"
                            onClick={handleExecutiveSave}
                            sx={{
                                ...AddButtonStyle,
                                float: "right",
                            }}
                        >
                            Save
                        </Button>
                    )}
                </Grid>
            </div>

            <Fab
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "fixed",
                    top: "100px",
                    right: open ? "37.3%" : "0", // Adjust this value based on drawer width
                    transform: "translateX(50%)",
                    zIndex: 1200,
                    height: "36px",
                    width: "36px",
                    backgroundColor: "#0AA2E3",
                    borderRadius: "60px 0 0 60px",
                }}
            >
                <CloseIcon />
            </Fab>
            <AcceptModal
                open={acceptModalOpen}
                onClose={handleAcceptModal}
                onYes={handleAcceptModal}
                message1={message1}
                message2={message2}
            />

            <ConfirmationModal
                open={mmConfirmation}
                title="Confirm Submit"
                message="Are you sure you want to submit this comment?"
                onCancel={() => setMmConfirmation(false)}
                onConfirm={mmConfirm}
            />
        </Drawer>
        </>
        
    );
};

export default RightDrawer;
