import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import { Checkmark } from '../../constant';
import { Close } from '@mui/icons-material';

export default function AcceptModal ({ open, onClose, onYes ,message1,message2 }) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth 
      PaperProps={{
        style: { borderRadius: '8px', width: '550px' }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} color="inherit" size="small">
            <Close />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 0, pb: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center">

          <img src={Checkmark} alt="check" />
          
          <Typography align="center" gutterBottom sx={{ fontWeight: 600, fontSize: '20px', m: 1 }}>
           {message1 ? message1 : ""}
          </Typography>
          <Typography variant="body2" align="center">
            {message2 ? message2 :""} 
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
        <Button
          variant="contained"
          onClick={onYes}
          sx={{ borderRadius: 28, px: 3, bgcolor: 'deepskyblue', color: 'white', flexGrow:'1' }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};