import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import { Checkmark } from '../../constant';
import { Close } from '@mui/icons-material';

// This is the submit modal for the Executive Loss Control Report , Do not change this 

const SubmitModal = ({ title, open, onClose, onYes }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth 
      PaperProps={{
        style: { borderRadius: '8px', width: '550px' }
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} color="inherit" size="small">
            <Close />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 0, pb: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center">

          <img src={Checkmark} alt="check" />
          
          <Typography align="center" gutterBottom sx={{ fontWeight: 600, fontSize: '20px', m: 1 }}>
            {title === "first" ? "Are you sure?" : "Report Submitted Successfully!!"}
          </Typography>
          <Typography variant="body2" align="center">
            {title === "first" ? "After submission you won’t be able to make changes" : "Here’s a snapshot of your report "}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
        {title === "first" && (<Button
          variant="outlined"
          onClick={onClose}
          sx={{ borderRadius: 28, px: 3, color: "#4D4D4D", flexGrow:'1' }}
        >
          No
        </Button>)}
        <Button
          variant="contained"
          onClick={onYes}
          sx={{ borderRadius: 28, px: 3, bgcolor: 'deepskyblue', color: 'white', flexGrow:'1' }}
        >
          {title === "first" ? "Yes" : "See Snapshot"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitModal;