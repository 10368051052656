import React from "react";
import { Grid, Typography, Chip, Divider, Box, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  redMinesManager,
  greenSafetyManager,
  incidentManagementIcon,
  actionIcon
} from "../../constant";
import SeverityTag from "../../Components/SeverityTag";
import {
  formatDateToIndianLocale,
  removeUnderScoreInString,
} from "../../utils/helper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import StatusChip from "../../Components/Chip";
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import NorthWestOutlinedIcon from '@mui/icons-material/NorthWestOutlined';

export default function CalendarCard({ cardData, onClose, title }) {
  const navigate = useNavigate();
  const dataArray = Array.isArray(cardData) ? cardData : [cardData];

  const getStatusChip = (status) => {
    switch (status) {
      case "Due":
        return (
          <Chip
            label="Due"
            sx={{
              color: "#FF7742",
              backgroundColor: "rgba(255, 119, 66, 0.1)",
              display: "flex",
              width: "225px",
              padding: "5px 101px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              borderRadius: "16px",
              whiteSpace: "nowrap",
              "& .MuiChip-label": {
                overflow: "visible",
              },
            }}
          />
        );
      case "Overdue":
        return (
          <Chip
            label="Overdue"
            sx={{
              color: "#FF231F",
              backgroundColor: "rgba(255, 87, 83, 0.15)",
              display: "flex",
              width: "225px",
              padding: "5px 101px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              borderRadius: "16px",
              "& .MuiChip-label": {
                overflow: "visible",
              },
              whiteSpace: "nowrap",
            }}
          />
        );

        case 'In_Progress':
          return <StatusChip label="In Progress" color="#0B7F6A" bgColor="rgba(11, 127, 106, 0.1)" />;
        case 'New_Survey':
          return <StatusChip label="New Tour" color="#FF8A00" bgColor="rgba(255, 138, 0, 0.1)" />;
        case 'Under_Review':
          return <StatusChip label="Under Review" color="#009FF5" bgColor="rgba(0, 159, 245, 0.1)" />;
        case 'Revalidation':
          return <StatusChip label="Revalidation" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
        case 'Resubmission':
          return <StatusChip label="Resubmission" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
        case 'Recommendation_Resubmission':
          return (
            <StatusChip
              label={<NorthWestOutlinedIcon style={{ color: "#FF7742" }} />}
              bgColor="rgba(255, 119, 66, 0.1)"
            />
          );
        case 'Returned_By_MM':
          return <StatusChip label="Returned" color="#FF7742" bgColor="rgba(255, 119, 66, 0.1)" />;
        case 'Completed':
          return <StatusChip label="Completed" color="#0B7F6A" bgColor="rgba(11, 127, 106, 0.1)" />;
  
        // Reported statuses
        case "New Incident":
          return <StatusChip label="New Incident" sx={{ color: '#FF231F', backgroundColor: 'rgba(255, 87, 83, 0.15)', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
        case 'New Incident Clarification':
          return <StatusChip label="New Incident Clarification" sx={{ color: '#FF231F', backgroundColor: 'rgba(255, 87, 83, 0.15)', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
  
        // Review statuses
        case "Incident Review":
          return <StatusChip label="Incident Review" sx={{ color: '#FF8A00', backgroundColor: '#FFF2D5', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;

        case "Investigation Completed":
          return <StatusChip label="Investigation Completed" sx={{ color: '#FF8A00', backgroundColor: '#FFF2D5', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;

        case 'CAPA_Review':
          return <StatusChip label="Review" sx={{ color: '#FF8A00', backgroundColor: '#FFF2D5', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
        case 'Incident Revalidation':
          return <StatusChip label="Incident Revalidation" sx={{ color: '#FF8A00', backgroundColor: '#FFF2D5', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;

        case 'Investigation Reinitiated':
          return <StatusChip label="Investigation Reinitiated" sx={{ color: '#FF8A00', backgroundColor: '#FFF2D5', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;

        case 'CAPA_Review_With_Return_Arrow':
          return <StatusChip label="Review Return" sx={{ color: '#FF8A00', backgroundColor: '#FFF2D5', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
  
        // Investigation statuses
        case 'Investigation Initiated':
          return <StatusChip label="Investigation Initiated" sx={{ color: '#007BFF', backgroundColor: '#E3F2FD', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
        case 'Investigation Validation':
          return <StatusChip label="Investigation Validation" sx={{ color: '#0FAE91', backgroundColor: 'rgba(19, 222, 185, 0.15)', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
  
        // CAPA statuses
        case 'CAPA_Assigned':
          return <StatusChip label="CAPA Assigned" sx={{ color: '#0B7F6A', backgroundColor: 'rgba(11, 127, 106, 0.1)', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
        case 'CAPA_In_Progress':
          return <StatusChip label="CAPA In Progress" sx={{ color: '#0B7F6A', backgroundColor: 'rgba(11, 127, 106, 0.1)', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;
  
        // Closed status
        case 'Incident Closed':
          return <StatusChip label="Incident Closed" sx={{ color: '#4CAF50', backgroundColor: 'rgba(76, 175, 80, 0.15)', fontSize: "12px", fontWeight: '500', minWidth: '96px', maxHeight: '30px' }} />;

      default:
        return (
          <Chip label="" color="#888" bgColor="rgba(136, 136, 136, 0.1)" />
        );
    }
  };

  const getIcon = (title) => {
    return title === "Incident Management" ? (
      <img src={incidentManagementIcon} alt="IM" />
    ) : title === "Loss Control" ? (
      <img src={redMinesManager} alt="LC" />
    ) : (
      <img src={greenSafetyManager} alt="PSS" />
    );
  };

  return (
    <>
      {dataArray.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography
            variant="h6"
            sx={{ color: "#525966", fontWeight: "bold" }}
          >
            Not any data found
          </Typography>
        </Box>
      ) : (
        dataArray?.map((data, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            lg={title === "Loss Control" ? 4 : 4}
          >
            <Paper
              elevation={1}
              sx={{
                backgroundColor: title === "Loss Control" ? "#FFF" : "#FFFFFF",
                padding: "24px",
                // minHeight: title === "All" ? "180px" : "230px",
                height: 'auto',
                // minWidth: '300px',
                flexGrow: 1,
                border: "1px solid #E1EAFA",
                borderRadius: "12px",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.17)",
                transition: "0.3s", // Smooth transition for the hover effect
                "&:hover": {
                  backgroundColor:
                    title === "Loss Control" ? "#FFF" : "#FFFFFF", // Light blue color on hover
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Stronger shadow on hover
                  borderColor: "#FAFAFA", // Slightly darker border on hover
                },
              }}
            >
              {/* Edit Icon */}
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={0} sx={{ position: "relative" }}>
                    {/* Edit Icon */}
                    <Grid item sx={{ position: "absolute", top: 0, right: 0 }}>
                      <img src={actionIcon} alt="actionIcon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (title === "Loss Control") {
                            navigate("/my-actions/loss-control-edit", {
                              state: { data },
                            });
                          }
                          if (title === "Incident Management") {
                            navigate("/my-actions/incident-edit", {
                              state: { data },
                            });
                          }
                        }}
                      />
                    </Grid>

                    {/* Logo and Name */}
                    <Grid item>
                      <Box display="flex" gap="12px" alignItems="center">
                        {/* Assuming this is your logo */}
                        {getIcon(title, data)}{" "}

                          {title === "Loss Control" && (
                            <Box display='flex' flexDirection='column' gap={0}>
                            <Typography
                              variant="body1"
                              sx={{ color: "#423B50", fontWeight: 700 }}
                            >
                              {data?.discipline}
                            </Typography>
                              <Typography sx={{ fontSize:'10px',color: "#7A8699", whiteSpace: "nowrap" }}>
                                ID : {data?.customReportId}
                              </Typography>
                            </Box>
                          )}
                          {title === "All" && (
                            <Box display='flex' flexDirection='column' gap={0}>
                            <Typography
                              variant="body1"
                              sx={{ color: "#423B50", fontWeight: 700 }}
                            >
                              {data?.moduleName === "Incident Management"
                                ? "Incident"
                                : data?.moduleName}
                            </Typography>
                            <Typography variant="caption" sx={{ color: "#7A8699" }}>
                                ID : {data["customReportId"]}
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {title === "Incident Management" && (
                  <Grid item xs={12} mt={1}>
                    <Grid container spacing={0} justifyContent="space-between">
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#423B50",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {data?.["Incident Type"]}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#7A8699",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {data?.["customReportId"]}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <SeverityTag severity={data?.Severity} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {title === "Incident Management" ? (
                  <>
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          backgroundColor: "#E0E2E7",
                          height: "1px",
                          mt: "8px",
                          mb: "16px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" gap={1} mb="8px">
                        <Typography variant="body2" sx={{ color: "#7A8699" }}>
                          Incident Time:
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#423B50" }}>
                          {formatDateToIndianLocale(data?.["Incident Time"])}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" gap={1} mb="8px">
                        <Typography variant="body2" sx={{ color: "#7A8699" }}>
                          Role:
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#423B50" }}>
                          {data.role}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                ) : title === "Loss Control" ? (
                  <>
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          backgroundColor: "#E0E2E7",
                          height: "1px",
                          mt: "8px",
                          mb: "16px",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" gap={0.5} sx={{mb:1}}>
                          <Typography variant="body2" sx={{ color: "#353C4B", fontWeight:'600' }}>
                            Tour Date :
                          </Typography>

                          <Typography variant="body2" sx={{ color: "#423B50" }}>
                            {formatDateToIndianLocale(data?.["Tour Date"])}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={0.5} sx={{mb:1}}>
                          <Typography variant="body2" sx={{ color: "#353C4B", fontWeight:'600' }}>
                            Role :
                          </Typography>

                          <Typography variant="body2" sx={{ color: "#423B50" }}>
                            {data?.role}
                          </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={0.5}
                        mb="8px"
                        alignItems="center"
                      >
                        {Array.isArray(data?.status) ? (
                          data?.status?.map((status, index) => (
                            <React.Fragment key={index}>
                              {getStatusChip(status)}
                            </React.Fragment>
                          ))
                        ) : (
                          // Fallback if data.Status is not an array (you can handle this however you want)
                          <Typography variant="body2" sx={{ color: "#423B50" }}>
                            No status available
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    <Grid>{getStatusChip(data.actionStatus)}</Grid>
                  </>
                ) : title === "All" ? (
                  <>
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          backgroundColor: "#E0E2E7",
                          height: "1px",
                          mt: "8px",
                          mb: "8px",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" gap={1} mb="8px">
                        {Object.keys(data).map((key) => {
                          if (
                            key.includes("Due Date") ||
                            key.includes("Tour Date") ||
                            key.includes("Incident Date")
                          ) {
                            return (
                              <React.Fragment key={key}>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#7A8699" }}
                                >
                                  {key}:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#423B50" }}
                                >
                                  {formatDateToIndianLocale(data[key])}
                                </Typography>
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </Box>
                    </Grid>

                    {/* Showing Status for the action */}

                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="body2" sx={{ color: "#7A8699" }}>
                          Status:
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#423B50" }}>
                          {Array.isArray(data?.status) ? (
                            data.status.map((status, index) => (
                              <React.Fragment key={index}>
                                {getStatusChip(status)}
                              </React.Fragment>
                            ))
                          ) : (
                            // Fallback if data.Status is not an array (you can handle this however you want)
                            <Typography
                              variant="body2"
                              sx={{ color: "#423B50" }}
                            >
                              No status available
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Paper>
          </Grid>
        ))
      )}
    </>
  );
}
