import React, { useState } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import CustomInput from './CustomInputField';
import { useDispatch, useSelector } from 'react-redux';
import { addCause, updateCause, removeCause } from '../redux/incidentInvestflow/actions';
import CustomSelect from './CustomSelectField';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

const AddButtonStyle = {
    fontSize: "16px",
    fontWeight: "600",
    backgroundColor: "#fff",
    color: "#0AA2E3",
    border: '3px solid #0AA2E3',
    borderRadius: "4px"
};

const options = ['fire', 'water', 'other'];

const CauseAnalysis = () => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [editingIndex, setEditingIndex] = useState(null); // For tracking the editing item
    const [formData, setFormData] = useState({ cause: '', description: '' }); // Form state

    const causeTypes = ['rootCause', 'immediateCause', 'indirectCause'];

    const currentCauseData = useSelector(state => state.incidentInvestigationFlowReducer.RootCauseAnalysis[causeTypes[step - 1]]);

    // Handles form input changes
    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const handleAddCause = () => {
        if(formData.cause  && formData.description){
            dispatch(addCause(causeTypes[step - 1], { ...formData }));
            setFormData({ cause: '', description: '' });
        }
       
    };

    const handleEditCause = (index) => {
        setEditingIndex(index);
        setFormData(currentCauseData[index]); // Populate form with current data
    };

    const handleSaveEdit = () => {
        dispatch(updateCause(causeTypes[step - 1], editingIndex, formData));
        setEditingIndex(null);
        setFormData({ cause: '', description: '' });
    };

    const handleRemoveCause = (index) => {
        dispatch(removeCause(causeTypes[step - 1], index));
    };

    return (
        <Box>
            <CauseSection
                title={causeTypes[step - 1].replace(/([A-Z])/g, ' $1')}
                causeData={currentCauseData}
                formData={formData}
                onInputChange={handleInputChange}
                onAdd={handleAddCause}
                onSaveEdit={handleSaveEdit}
                handleEditCause={handleEditCause}
                handleRemoveCause={handleRemoveCause}
                editingIndex={editingIndex}
            />
            <Box display="flex" justifyContent={"flex-end"} mt={2}>
                {step > 1 && <Button onClick={() => setStep(step - 1)}>Back</Button>}
                {step < 3 && <Button onClick={() => setStep(step + 1)}>Next</Button>}
            </Box>
        </Box>
    );
};

const CauseSection = ({ title, causeData, formData, onInputChange, onAdd, onSaveEdit, handleEditCause, handleRemoveCause, editingIndex }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const handleDotClick = (index) => {
        setCurrentSlide(index); // Set the slide manually when dot is clicked
    };

    return (
        <Box my={2} p={2} border="1px solid #ddd" borderRadius={4}>
            <Box sx={{ background: '#09A2E31F', p: "8px" }} borderRadius={1}>
                <Typography variant="body2" color="primary">
                    {title.charAt(0).toUpperCase() + title.slice(1)}
                </Typography>
            </Box>

            {/* Form for adding or editing cause */}
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <CustomSelect
                        label="Cause"
                        value={formData.cause}
                        options={['fire', 'water', 'other']}
                        onChange={(e) => onInputChange('cause', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        label="Description"
                        value={formData.description}
                        onChange={(e) => onInputChange('description', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={4} mt={3}>
                    {editingIndex !== null ? (
                        <Button
                            variant="contained"
                            onClick={onSaveEdit}
                            style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                backgroundColor: "#fff",
                                color: "#0AA2E3",
                                border: '3px solid #0AA2E3',
                                borderRadius: "4px"
                            }}
                        >
                            Save
                        </Button>
                    ) : (
                        <AddBoxOutlinedIcon sx={{ fontSize: "32px", color: "#0AA2E3" }} onClick={onAdd} />
                    )}
                </Grid>
            </Grid>

            {/* Display current cause slide */}
            {causeData && causeData.length > 0 && (
                <Box
                    sx={{
                        marginTop: "16px",
                        backgroundColor: "#EFEFF1",
                        padding: "12px",
                        borderRadius: "8px",
                        minHeight: '260px',
                    }}
                >
                    <Typography sx={{ color: "#625B71", fontSize: "16px", mb: "8px" }}>
                        Preview
                    </Typography>
                    <Box
                        sx={{
                            borderRadius: "8px",
                            background: "#FFFFFF",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
                            minWidth: "355px",
                            maxWidth: "355px",
                            minHeight: '200px',
                            marginTop: "12px",
                        }}
                    >
                        {/* Display only the current slide */}
                        {causeData.length > 0 && (
                            <>
                                <Box
                                    position="relative"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        backgroundColor: "#09A2E3",
                                        minHeight: "56px",
                                        borderRadius: "8px 8px 0 0",
                                        padding: "8px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Box
                                            sx={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                                marginRight: "8px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <TroubleshootIcon
                                                sx={{ width: "100%", height: "100%", color: "#ffffff" }}
                                            />
                                        </Box>
                                        <Typography sx={{ fontSize: "20px", fontWeight: "600", color: "#ffffff" }}>
                                            {title}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" gap={1}>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.20)', padding: '4px', borderRadius: '5px', minWidth: 'auto', boxShadow: 'none' }}
                                            onClick={() => handleEditCause(currentSlide)}
                                        >
                                            <EditIcon sx={{ color: '#ffffff' }} />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            sx={{ backgroundColor: 'rgba(255, 255, 255, 0.20)', padding: '4px', borderRadius: '5px', minWidth: 'auto', boxShadow: 'none' }}
                                            onClick={() => handleRemoveCause(currentSlide)}
                                        >
                                            <DeleteOutline sx={{ color: '#ffffff' }} />
                                        </Button>
                                    </Box>
                                </Box>
                                <Box sx={{ padding: "16px" }}>
                                    <Typography variant="body2">
                                        {causeData[currentSlide].cause}
                                    </Typography>
                                    <Typography variant="caption" sx={{ color: '#4D4D4D' }}>
                                        {causeData[currentSlide].description || "N/A"}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>

                    {/* Dot navigation */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        {causeData.map((_, index) => (
                            <Box
                                key={index}
                                onClick={() => handleDotClick(index)}
                                sx={{
                                    height: 8,
                                    width: 8,
                                    borderRadius: '50%',
                                    backgroundColor: index === currentSlide ? '#0AA2E3' : '#ccc',
                                    mx: 0.5,
                                    cursor: 'pointer',
                                }}
                            />
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default CauseAnalysis;
