import React from 'react';
import { waves, wavesRed } from '../constant';
import { Box, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function IncidentTopCard({data}) {
    // console.log("data",data);
  return (
    <Box position="relative" sx={{ backgroundColor: "#FFFFFF", padding: "16px", minHeight: "104px", flexGrow: 1, borderRight: "0.538px solid #B7C8E5", overflow: 'hidden' }}>
      {/* Text Content */}
      <Box display='flex' alignItems='center' justifyContent='space-between' zIndex={1}>
        <Box>
          <Box display='flex' flexDirection='column' gap='2px'>
              <Typography variant='body2' sx={{color:'#525966', fontWeight:'500'}}>{data.name}</Typography>
              <Typography variant='caption' sx={{color:'#8FA6CC'}}>{data.info}</Typography>
          </Box>
          <Box display='flex'>
            {data.indicator === "positive" ? (
              <>
                <ArrowDropUpIcon sx={{ width: '25px', height: '32px', color: '#05CD99' }} />
                <Typography sx={{ color: '#05CD99', fontWeight: '500', fontSize: '20px' }}>
                  {data.percentage}
                </Typography>
              </>
            ) : (
              <>
                <ArrowDropDownIcon sx={{ width: '25px', height: '32px', color: '#EB3D63' }} />
                  <Typography sx={{ color: '#EB3D63', fontWeight: '500', fontSize: '20px' }}>
                    {data.percentage.replace('-', '')}
                  </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box>
          <Typography sx={{color:'#525966', fontWeight:'600', fontSize: '36px'}}>{data.value}</Typography>
        </Box>
      </Box>

      {/* Waves Image */}
      {parseFloat(data.percentage) > 0 ? (
      <img 
        src={waves} 
        alt="waves" 
        style={{ 
          position: 'absolute', 
          bottom: '-14px', 
          left: 0, 
          width: '100%', 
          height: 'auto', 
          zIndex: 0,
        }} 
      />) : (
        <img 
        src={wavesRed} 
        alt="waves" 
        style={{ 
          position: 'absolute', 
          bottom: '-14px', 
          left: 0, 
          width: '100%', 
          height: 'auto', 
          zIndex: 0,
        }} 
      />
      )}
    </Box>
  );
}
