import React from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { moduleCardImage,checkList } from "../constant";
import Icon from "@mui/icons-material/Checklist";

const ModulePage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const orgConfigData = JSON.parse(localStorage.getItem('loginData'));

  // Define dynamic menu items based on the modules available in the organization data
  const dynamicMenuItems = orgConfigData?.config?.modulesDetail?.map((module) => ({
    text: module.moduleName,
    path: `/module/${module.moduleName.toLowerCase().replace(/\s+/g, '-')}`,
  })) || [];



  

  // Handle card click to navigate to the respective module
  const handleCardClick = (path) => {
    navigate(path);
  };

  // Check if the current route is the base module route
  const isModuleBasePath = location.pathname === '/module';

  return (
    <div>
      {isModuleBasePath && (
       <>
       <Grid container spacing={4}>
         {dynamicMenuItems.map((module, index) => (
           <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
             <Card
              onClick={() => index === 1  && handleCardClick(module.path)}
               //onClick={() => handleCardClick(module.path)}

               style={{
                 backgroundImage: `url(${moduleCardImage})`,
                 backgroundSize: "cover",
                 backgroundPosition: "center",
                 borderRadius: "16px",
                 boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                 position: "relative",
                 height: "100%",               
                 padding: "20px",
                 color: "#000",
                 cursor:'pointer',
                 textAlign: "left", // Aligns all content to the left
               }}
             >
               {/* Circular frame with checklist image aligned to the left */}
               <div style={{
                 backgroundColor: "#fff",
                 borderRadius: "50%",
                 width: "60px",
                 height: "60px",
                 display: "flex",
                 alignItems: "center",
                 justifyContent: "center",
                 boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                 marginBottom: "32px",
               }}>
                 <img
                   src={checkList}
                   alt="Checklist Icon"
                   style={{
                     width: "40px",
                     height: "40px",
                   }}
                 />
               </div>
     
               {/* Content text aligned to the left */}
               <CardContent style={{padding: 0, }}>
                 <Typography variant="h6" sx={{ fontWeight: 600, textWrap:"wrap" }} gutterBottom>
                   {module.text}
                 </Typography>
               </CardContent>
             </Card>
           </Grid>
         ))}
       </Grid>
     </>

      )}
      <Outlet />
    </div>
  );
};

export default ModulePage;
