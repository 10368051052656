import {
  SET_SELECTED_DATE,
  SET_SELECTED_TEMPLATE,
  SET_SELECTED_WORKFLOW,
} from "./constant";

const initialState = {
  selectedDate: null,
  selectedTemplate: null,
  selectedWorkflow: [],
};

export const auditReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_DATE:
      console.log("action.payload", action.payload);
      return {
        ...state,
        selectedDate: action?.payload,
      };
    case SET_SELECTED_TEMPLATE:
      console.log("action.payload", action.payload);
      return { ...state, selectedTemplate: action.payload };

    case SET_SELECTED_WORKFLOW:
      console.log("action.payload", action.payload);
      return { ...state, selectedWorkflow: action.payload };

    default:
      return state;
  }
};
