import React, { useEffect, useRef, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";

import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  CardContent,
  Card,
  IconButton,
  Button,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { auditIcon, actionWhiteIcon } from "../../constant";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  addMonths,
  subMonths,
} from "date-fns";
import { useNavigate } from "react-router-dom";

import "react-big-calendar/lib/css/react-big-calendar.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCalendarDateActions } from "../../Apis/apiCall";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux"; // Import Redux hooks
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { ContinuousColorLegend } from "@mui/x-charts";
import { setScheduleSelectedDate } from "../../redux/audit/action";


const locales = { "en-US": require("date-fns/locale/en-US") };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const calendarStyles = `
  .rbc-header {
    padding: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #666;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .rbc-date-cell {
    padding: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    display: flex;
    justify-content: flex-start;
    // border-right: 1px solid #ddd;
  }
  
  .rbc-month-view {
    background-image: 
      linear-gradient(#e0e0e0 1px, transparent 1px),
      linear-gradient(90deg, #e0e0e0 1px, transparent 1px);
    background-size: calc(100% / 7) 100%, calc(100% / 7) 100%;
    background-position: -1px -1px;
    background-color: #fff;
    border-radius: 8px
  }
 
  
  .rbc-today {
    background-color: #f5f5f5;
  }

  .selected-date {
    background-color: #E0F7FA !important;
  }

  .rbc-event {
    background-color: transparent !important;
    // border-right: 1px solid #ddd;
    box-shadow: none !important;
    margin: 2px !important;
  }

  .rbc-event.rbc-selected {
    background-color: transparent !important;
  }

  .module-chip {
    margin: 2px;
    font-size: 11px;
    height: 20px;
    border-radius: 10px;
    background-color: #e3f2fd;
    color: #1976d2;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
  }
    
`;

const CustomToolbar = ({ date, onNavigate, onMonthChange }) => {
  const handleNavigation = (action) => {
    onNavigate(action);
    const newDate = action === "NEXT" ? addMonths(date, 1) : subMonths(date, 1);
    onMonthChange(newDate.getMonth() + 1, newDate.getFullYear());
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between" // For horizontal spacing
      sx={{ padding: "0px 0px 12px 0px" }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, fontSize: "16px", color: "#222" }}
      >
        {format(date, "MMMM yyyy")}
      </Typography>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, fontSize: "16px", color: "#222" }}
      >
        Scheduled Audit
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        {/* Wrapping IconButtons in a Box with gap */}
        <IconButton onClick={() => handleNavigation("PREV")} size="small">
          <ArrowBackIos sx={{ fontSize: 16, color: "#3C3E43" }} />
        </IconButton>
        <IconButton onClick={() => handleNavigation("NEXT")} size="small">
          <ArrowForwardIos sx={{ fontSize: 16, color: "#3C3E43" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default function ScheduleAudit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.loadingReducer.loading);

  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [cardData, setCardData] = useState(null);
  const [cardOpen, setCardOpen] = useState(false);
  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [isExpanded, setisExpanded] = useState(false);
  const [iscelenderHide, setIsCelenderHide] = useState(false);

  const cardRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, [currentMonth, currentYear]);

  const fetchEvents = async () => {
    dispatch({ type: START_LOADING });
    try {
      const response = await getCalendarDateActions(currentMonth, currentYear);
      const formattedEvents = response.data.data.map((dateData) => ({
        date: new Date(dateData.date),
        modules: dateData.data.filter((module) => module.actions.length > 0),
        allDay: true,
        start: new Date(dateData.date),
        end: new Date(dateData.date),
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

  const handleMonthChange = (month, year) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const selectedEvent = events.find(
      (event) => event.date.toDateString() === date.toDateString()
    );
    if (selectedEvent) {
      setSelectedModuleName(selectedEvent.modules[0]?.moduleName || "");
    }
  };

  const handleSelectDate = (date) => {
    setSelectedDate(date); // Update the selected date state
  };

  const handleCloseCard = () => {
    setCardOpen(false);
    setCardData(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        handleCloseCard();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const EventComponent = ({ event }) => (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
      {event?.modules?.map((module, index) => {
        // Check if any action in the module is overdue
        const hasOverdueAction = module?.actions?.some(
          (action) => action.actionStatus?.toLowerCase() === "overdue"
        );

        return (
          module?.actions?.length > 0 && (
            <Box
              key={index}
              sx={{
                display: "flex",
                padding: "2px 12px 2px 6px",
                alignItems: "center",
                gap: "8px",
                alignSelf: "stretch",
                borderRadius: "0px 2px 2px 0px",
                borderLeft: hasOverdueAction
                  ? "2px solid #E53935"
                  : "2px solid #0AA2E3",
                background: hasOverdueAction
                  ? "linear-gradient(0deg, rgba(229, 57, 53, 0.10) 0%, rgba(229, 57, 53, 0.10) 100%), #FFF"
                  : "linear-gradient(0deg, rgba(10, 162, 227, 0.10) 0%, rgba(10, 162, 227, 0.10) 100%), #FFF",
                color: hasOverdueAction ? "#E53935" : "#0AA2E3",
                fontSize: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              onClick={() => handleSelectDate(new Date())}
            >
              {module.moduleName}
            </Box>
          )
        );
      })}
    </Box>
  );

 
  const selectedDates = (date) => {
    setSelectedDate(date);
    const selectedEvent = events.find(
      (event) => event?.date?.toDateString() === date?.toDateString()
    );
    if (selectedEvent) {
      setSelectedModuleName(selectedEvent.modules[0]?.moduleName || "");
    }
  };

const handleClickStartAudit=(date)=>{
  console.log("date",date)
  dispatch(setScheduleSelectedDate(date));
  navigate(`/module/audit-management/audit-schedule/new-audit-schedule`);
}


  const handleIconClick = () => {
    setisExpanded(!isExpanded);
  };

  const handleShowDetails = () => {
    if (isExpanded) {
      // Close expanded view first
      setisExpanded(false);
      setTimeout(() => {
        // Then show calendar (after expanded view is closed)
        setIsCelenderHide(true);
        console.log("Hello! I am an alert box!!");
      }, 300); // Adjust delay if needed
    } else {
      // Directly show calendar if expanded view is already closed
      setIsCelenderHide(true);
      console.log("Hello! I am an alert box!!");
    }
  };

  const cards = Array.from({ length: 12 }, (_, index) => (
    <Box
      sx={{
        background: "#FAFAFA",
        padding: "8px 8px 16px 8px",
        border: " 0.876px solid #D5E0F6",
        borderRadius: "16px",
        boxShadow: "1.753px 1.753px 3.506px 0px rgba(174, 191, 237, 0.25)",
        width: "250px",
      }}
    >
      <Box
        sx={{
          borderRadius: "12px",
          backgroundColor: "#09A2E3",
          width: "230px",
          padding: "8px",
        }}
      >
        <Typography
          sx={{
            color: "#ffffff",
            fontWeight: "500",
            fontSize: "12px",
          }}
        >
          Pre-Spud Audit
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          color: "#353C4B",
          fontSize: "10px",
          fontWeight: "500",
          marginBottom: "4px",
          marginTop: "6px",
        }}
      >
        Frequency:{" "}
        <Typography
          component="span"
          sx={{
            color: "#6B7387",
            fontSize: "10px",
            fontWeight: "400",
          }}
        >
          Monthly
        </Typography>
      </Typography>
      {/* Progress Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Progress Bar */}
        <LinearProgress
          variant="determinate"
          value={50} // Adjust value based on progress
          sx={{
            width: "140px",
            height: "6px",
            borderRadius: "3px",
            backgroundColor: "#E6EAF2",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#4CAF50",
            },
          }}
        />

        {/* Status */}
        <Typography
          variant="body2"
          sx={{
            fontSize: "10px",
            fontWeight: "600",
            color: "#525966",
          }}
        >
          24/48
        </Typography>
      </Box>
    </Box>
  ));

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        padding: "16px",
        minHeight: "auto",
      }}
    >
      <Grid container spacing={2} sx={{ display: "flex" }}>
        <Grid
          xs={isExpanded ? 12 : 12}
          sm={isExpanded ? 12 : 12}
          md={isExpanded ? 12 : 3}
          item
          // xs={12}
          // md={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              background: "#F1F1F1",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
              padding: "16px",
              flexGrow: 1,
              minHeight: "75vh",
              width: "100%",
              alignItems: "flex-start",
              gap: "10px",
              cursor: "pointer", // Fixed the typo
              "&:hover": {
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Optional hover effect
              },
            }}
            onClick={handleShowDetails}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "8px",
                  color: "#000",
                }}
              >
                Active Audit
              </Typography>

              {isExpanded ? (
                <KeyboardDoubleArrowLeftIcon
                  onClick={handleIconClick}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <KeyboardDoubleArrowRightIcon
                  onClick={handleIconClick}
                  sx={{ cursor: "pointer" }}
                />
              )}
            </Box>

            {/* Left side card section start */}
            <Box
              sx={{
                maxHeight: "500px", // Set the maximum height for the container
                overflowY: "auto", // Enable vertical scrolling
              }}
            >
              <Grid container spacing={2}>
                {cards.map((card, index) => (
                  <Grid
                    item
                    xs={12} // Full width on mobile
                    sm={isExpanded ? 6 : 12} // Half width on tablet
                    md={isExpanded ? 3 : 12} // Adjust columns dynamically
                    key={index}
                  >
                    {card}
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Left side card section end */}

            {/* Disciplines list */}
            <Grid
              container
              spacing={1.5}
              sx={{
                overflowY: "auto",
                maxHeight: "340px",
                whiteSpace: "nowrap",
              }}
            ></Grid>
          </Box>
        </Grid>
        {!isExpanded && (
          <>
            <Grid
              item
              xs={12}
              md={9}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                    padding: "0px 0px 12px 0px",
                  },
                  gap: 2,
                }}
              >
                {loading && (
                  <Box
                    sx={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      zIndex: 1000,
                    }}
                  >
                    <Loader />
                  </Box>
                )}
                {/* Calendar Section */}

                {!iscelenderHide && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        height: "100%", // Ensures it fills the parent container
                      }}
                    >
                      <style>{calendarStyles}</style>

                      <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{
                          width: "100%",
                          height: "500px", // Ensures it fills the parent container
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          padding: "8px",
                        }}
                        views={["month"]}
                        defaultView="month"
                        selectable
                        selected={selectedDate}
                        onChange={handleSelectDate}
                        onSelectSlot={({ start }) => handleDateSelect(start)}
                        components={{
                          toolbar: (props) => (
                            <CustomToolbar
                              {...props}
                              onMonthChange={handleMonthChange}
                            />
                          ),
                          event: EventComponent,
                          dateCellWrapper: ({ value, children }) => (
                            <Box
                              onClick={() => handleDateSelect(value)}
                              className={
                                selectedDate?.toDateString() ===
                                value?.toDateString()
                                  ? "selected-date"
                                  : ""
                              }
                              sx={{ cursor: "pointer", height: "100%" }}
                            >
                              {children}
                            </Box>
                          ),
                        }}
                      />
                    </Box>
                  </>
                )}
                {iscelenderHide && (
                  <>
                    <Box>
                      {/* Header */}
                      <Typography sx={{ color: "#000000" }}>
                        Pre-Spud Audit | IND-R-DM-5 (WDW)-24-S-010
                      </Typography>

                      {/* Details Section */}
                      <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Scheduled Date
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 500,
                              color: "#757575",
                              fontSize: "14px",
                            }}
                          >
                            28-10-2024 10:40
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Scheduled By
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              color: "#757575",
                              fontSize: "14px",
                            }}
                          >
                            Emp 22
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Start Date
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              color: "#757575",
                              fontSize: "14px",
                            }}
                          >
                            24-12-2024
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            End At
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              color: "#757575",
                              fontSize: "14px",
                            }}
                          >
                            24-12-2025
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Category
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              color: "#757575",
                              fontSize: "14px",
                            }}
                          >
                            Category Name
                          </Typography>
                        </Grid>

                        {/* Sections */}
                        <Grid item xs={9}>
                          <Typography
                            sx={{
                              color: "#2E2A40",
                              fontSize: "14px",
                              fontWeight: "700",
                            }}
                          >
                            Section{" "}
                            <span
                              sx={{
                                color: "#9D9D9D",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              / H2S Hazard Control (Where Applicable) Applicable
                              / Not Applicable
                            </span>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                          >
                            {[...Array(7)].map((_, index) => (
                              <Chip
                                key={index}
                                label="Section 1"
                                sx={{
                                  borderRadius: "12px",
                                  background:
                                    "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                  color: "#09A2E3",
                                  fontWeight: "600",
                                  fontSize: "10px",
                                }}
                              />
                            ))}
                            <Chip
                              label="+4"
                              sx={{
                                borderRadius: "12px",
                                background:
                                  "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                color: "#09A2E3",
                                fontWeight: "600",
                                fontSize: "10px",
                              }}
                            />
                          </Box>
                        </Grid>

                        {/* Locations */}
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Audit Location
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                          >
                            {[...Array(9)].map((_, index) => (
                              <Chip
                                key={index}
                                label="Location 1"
                                sx={{
                                  borderRadius: "12px",
                                  background:
                                    "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                  color: "#09A2E3",
                                  fontWeight: "600",
                                  fontSize: "10px",
                                }}
                              />
                            ))}
                            <Chip
                              label="+4"
                              sx={{
                                borderRadius: "12px",
                                background:
                                  "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                color: "#09A2E3",
                                fontWeight: "600",
                                fontSize: "10px",
                              }}
                            />
                          </Box>
                        </Grid>

                        {/* Team Members */}
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Audit Leader
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                          >
                            {[...Array(4)].map((_, index) => (
                              <Chip
                                key={index}
                                label="Name"
                                sx={{
                                  borderRadius: "12px",
                                  background:
                                    "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                  color: "#09A2E3",
                                  fontWeight: "600",
                                  fontSize: "10px",
                                }}
                              />
                            ))}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Audit Team Member
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                          >
                            {[...Array(9)].map((_, index) => (
                              <Chip
                                key={index}
                                label="Name"
                                sx={{
                                  borderRadius: "12px",
                                  background:
                                    "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                  color: "#09A2E3",
                                  fontWeight: "600",
                                  fontSize: "10px",
                                }}
                              />
                            ))}
                            <Chip
                              label="+4"
                              sx={{
                                borderRadius: "12px",
                                background:
                                  "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                color: "#09A2E3",
                                fontWeight: "600",
                                fontSize: "10px",
                              }}
                            />
                          </Box>
                        </Grid>

                        {/* Recurring */}
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Recurring
                          </Typography>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Recurrence pattern
                          </Typography>
                          <Typography
                            sx={{
                              color: "#757575",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Weekly - Every 2 weeks :
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                              marginTop: 1,
                            }}
                          >
                            {[
                              "Sun",
                              "Mon",
                              "Tue",
                              "Wed",
                              "Thu",
                              "Fri",
                              "Sat",
                            ].map((day, index) => (
                              <Chip
                                key={index}
                                label={day}
                                sx={{
                                  borderRadius: "12px",
                                  background:
                                    "linear-gradient(0deg, rgba(9, 162, 227, 0.04) 0%, rgba(9, 162, 227, 0.04) 100%), #FFF",
                                  color: "#09A2E3",
                                  fontWeight: "600",
                                  fontSize: "10px",
                                }}
                              />
                            ))}
                          </Box>
                        </Grid>

                        {/* Range of Recurrence */}
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              color: "#2d2a3f",
                              fontWeight: "500",
                              fontSize: "14px",
                              marginBottom: 1,
                            }}
                          >
                            Range of Recurrence
                          </Typography>
                          <Grid container spacing={2}>
                            {/* Start Date */}
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  color: "#757575",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                Start
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#09A2E3",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                4-12-2024
                              </Typography>
                            </Grid>
                            {/* End Date */}
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  color: "#757575",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                End by
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#09A2E3",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                              >
                                4-12-2024
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Footer Buttons */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 3,
                        }}
                      >
                        {" "}
                        <Typography
                          sx={{
                            color: "#2E2A40",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Audit Workflow
                          <Typography
                            sx={{
                              color: "#84818A",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Standard
                          </Typography>
                        </Typography>
                        <Box>
                          <Button
                            sx={{
                              marginRight: 2,
                              border: "1px solid #09A2E3",
                              borderRadius: "4px",
                              color: "#09A2E3",
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            sx={{
                              marginRight: 2,
                              border: "1px solid #09A2E3",
                              borderRadius: "4px",
                              color: "#09A2E3",
                            }}
                          >
                            Delete
                          </Button>
                          <Button
                            sx={{
                              borderRadius: "4px",
                              background: "#09A2E3",
                              color: "#fff",
                              fontSize: "16px",
                            }}
                          >
                            Edit
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>

              {!iscelenderHide && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: "10px",
                      paddingRight: "16px", // Optional, adds spacing from the right edge
                    }}
                  >
                    <Button
                      sx={{
                        width: "240px",
                        borderRadius: "30px",
                        background: selectedDate ? "#09A2E3" : "#ccc",
                        color: selectedDate ? "#fff" : "#666",
                        textTransform: "none",
                        cursor: selectedDate ? "pointer" : "not-allowed", // Show appropriate cursor
                      }}
                      disabled={!selectedDate} // Button is disabled when no date is selected
                      onClick={() => {
                        if (selectedDate) {
                          handleClickStartAudit(selectedDate);
                        }
                      }}
                    >
                      Schedule a new Audit
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
