import {
  UPDATE_FORM_FIELD,
  ADD_CHECKBOX,
  REMOVE_CHECKBOX,
  UPDATE_INCIDENT_DATE_TIME,
  ONCHANGE_DRAWER_FORM,
  ADD_EQUIPMENT_DETAIL,
  ADD_PERSONNEL_DETAIL,
  ADD_VEHICLE_DETAIL,
  ADD_EVIDENCE_DETAIL,
  ADD_CAPA_DETAIL,
  ADD_LTI_DETAIL,
  UPDATE_SWITCH_STATE,
  EDIT_CAPA_DETAIL,
  DELETE_CARD_DETAIL,
  SET_ALL_REPORT_DATA,
  SET_LTI_DETAILS,
  UPDATE_EVIDENCE_FILE,
  UPDATE_FILTER,
  RESET_FILTER,
  RESET_STATE,
  SET_REPORT_FORM_DATA
} from './constant';

const initialState = {
  allReportsData: [],
  GeneralInfo: [],
  PersonnelDetail: [],
  VehicleDetail: [],
  EquipmentDetail: [],
  CAPADetail: [],
  LTIDetail: [],
  EvidenceDetail: [],
  Regulatory: null,
  switchStates: {
    Services: 'No',
    Evidence: 'No',
    CAPA: 'No',
    Regulatory: 'No',
    LTI: 'No',
    Equipment: 'NO',
    Vehicle: "NO",
    Personnel: "No",
  },
  formEdit: {
    Evidence: false,
    CAPA: false,
    Equipment: false,
    Vehicle: false,
    Personnel: false,
  },
  Filter: {
    Modules: "",
    ModuleId: "",
    Roles: "",
    Status: "",
    overDue:""
  }

};
const incidentReportFromReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_FORM_DATA:
      return {
        ...state,
        ...action.payload.data,  // Merges incoming data with state
      };

    case RESET_STATE:
      return { ...initialState };

    case RESET_FILTER:
      return {
        ...state,
        Filter: { ...initialState.Filter } // Reset Filter to its initial values
      };

    case UPDATE_FILTER:
      const { fieldName, moduleId, moduleName } = action.payload;
      return {
        ...state,
        Filter: {
          ...state.Filter,
          Modules: fieldName,   // Update module name in `Modules`
          ModuleId: moduleId,
          Roles: "",
          Status: ""        // Update module ID in `ModuleId`
        }
      };


    case UPDATE_EVIDENCE_FILE:
      return {
        ...state,
        Evidence: {
          ...state.Evidence,
          file: Array.isArray(state.Evidence.file)
            ? [...state.Evidence.file, action.payload.fileURL] // Append the new file object to the array
            : [action.payload.fileURL], // Initialize as array if it's not an array yet
          files: Array.isArray(state.Evidence.files)
            ? [...state.Evidence.files, action.payload.file] // Append the new file URL to the array
            : [action.payload.file], // Initialize as array if it's not an array yet
        },
      };
    case SET_LTI_DETAILS:
      const currentState = state.LTIdDetail || []; // Ensure currentState is an array
      const existingIndex = currentState.findIndex(item => item.Name === action.payload.Name);

      let updatedLTIData;

      if (existingIndex >= 0) {
        // Update the existing LTI data
        updatedLTIData = currentState.map((item, index) =>
          index === existingIndex ? { ...item, ...action.payload } : item
        );
      } else {
        // Add new LTI data
        updatedLTIData = [...currentState, action.payload];
      }

      return {
        ...state,
        LTIdetail: updatedLTIData, // Update LTIdetail with the new state
      };

    case UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,  // Dynamically update field
      };

    case ADD_CHECKBOX:
      if (!state.GeneralInfo.includes(action.payload)) {
        return {
          ...state,
          GeneralInfo: [...state.GeneralInfo, action.payload],
        };
      }
      return state;

    case REMOVE_CHECKBOX:
      return {
        ...state,
        GeneralInfo: state.GeneralInfo.filter(item => item !== action.payload),
      };
    case SET_ALL_REPORT_DATA:
      return {
        ...state,
        allReportsData: action.payload
      };


    case UPDATE_INCIDENT_DATE_TIME:
      return {
        ...state,

        [action.payload.label]: action.payload.value, // Dynamically use label as the key

      };

    // Dynamic case for adding/updating PERSONNEL detail
    case ONCHANGE_DRAWER_FORM:
      return {
        ...state,
        [action.payload.title]: {
          ...state[action.payload.title],
          [action.payload.fieldName]: action.payload.value, // Use fieldName dynamically as key
        }
      };



    // ADD_LTI_DETAIL case
    case ADD_LTI_DETAIL: {
      const existingIndex = state.LTIDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedLTIDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedLTIDetail = state.LTIDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedLTIDetail = [
          ...state.LTIDetail,
          {
            id: state.LTIDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        LTIDetail: updatedLTIDetail,
        LTI: {} // Reset LTI object
      };
    }

    // ADD_PERSONNEL_DETAIL case
    case ADD_PERSONNEL_DETAIL: {
      const noOfPeople = state.Personnel?.["No. of People"] || 0;
      const currentPersonnelDetailCount = state.PersonnelDetail.length;


      const existingIndex = state.PersonnelDetail.findIndex(detail => detail.id === action.payload.id);
      if (existingIndex === -1 && currentPersonnelDetailCount >= noOfPeople) {
        return state; // Prevent adding new personnel details if limit is reached
      }

      let updatedPersonnelDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedPersonnelDetail = state.PersonnelDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedPersonnelDetail = [
          ...state.PersonnelDetail,
          {
            id: state.PersonnelDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      const retainedPersonnel = Object.keys(state.Personnel || {}) // Ensure state.PERSONNEL is not null or undefined
        .filter(key => [
          "No. of People",
          "Injury Count",
          "Fatality Count",
          "Location of Accident"
        ].includes(key))
        .reduce((acc, key) => ({ ...acc, [key]: state.Personnel[key] }), {});

      return {
        ...state,
        PersonnelDetail: updatedPersonnelDetail,
        formEdit: { ...state.formEdit, Personnel: false },
        Personnel: retainedPersonnel // Retain only specific keys, resetting the rest
      };

    }

    // ADD_VEHICLE_DETAIL case
    case ADD_VEHICLE_DETAIL: {
      const existingIndex = state.VehicleDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedVehicleDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedVehicleDetail = state.VehicleDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedVehicleDetail = [
          ...state.VehicleDetail,
          {
            id: state.VehicleDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        VehicleDetail: updatedVehicleDetail,
        formEdit: { ...state.formEdit, Vehicle: false },
        Vehicle: {} // Reset Vehicle object
      };
    }

    // ADD_EQUIPMENT_DETAIL case
    case ADD_EQUIPMENT_DETAIL: {
      const existingIndex = state.EquipmentDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedEquipmentDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedEquipmentDetail = state.EquipmentDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedEquipmentDetail = [
          ...state.EquipmentDetail,
          {
            id: state.EquipmentDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        EquipmentDetail: updatedEquipmentDetail,
        formEdit: { ...state.formEdit, Equipment: false },
        Equipment: {} // Reset Equipment object
      };
    }

    // ADD_EVIDENCE_DETAIL case
    case ADD_EVIDENCE_DETAIL: {
      const existingIndex = state.EvidenceDetail.findIndex(detail => detail.id === action.payload.id);

      let updatedEvidenceDetail;

      if (existingIndex !== -1) {
        // Replace existing object
        updatedEvidenceDetail = state.EvidenceDetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add new object
        updatedEvidenceDetail = [
          ...state.EvidenceDetail,
          {
            id: state.EvidenceDetail.length + 1, // Increment ID based on array length
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        EvidenceDetail: updatedEvidenceDetail,
        formEdit: { ...state.formEdit, Evidence: false },
        Evidence: {} // Reset Evidence object
      };
    }


    case ADD_CAPA_DETAIL: {
      const existingIndex = state.CAPADetail.findIndex(detail => detail.id === action.payload.id);

      let updatedCAPADetail;

      if (existingIndex !== -1) {
        // Replace the object at the found index with the new object
        updatedCAPADetail = state.CAPADetail.map((detail, index) =>
          index === existingIndex ? { ...detail, ...action.payload } : detail
        );
      } else {
        // Add a new object if it doesn't already exist
        updatedCAPADetail = [
          ...state.CAPADetail,
          {
            id: state.CAPADetail.length + 1, // Increment ID based on array length (if a new object)
            ...action.payload
          }
        ];
      }

      return {
        ...state,
        CAPADetail: updatedCAPADetail,
        formEdit: { ...state.formEdit, CAPA: false },
        CAPA: {} // Reset CAPA object
      };
    }

    case UPDATE_SWITCH_STATE:
      return {
        ...state,
        switchStates: {
          ...state.switchStates,
          [action.payload.sectionName]: action.payload.value, // Update the specific switch state
        },
      };

    case EDIT_CAPA_DETAIL: {
      const { id, title } = action.payload;
      let detailArray;
      switch (title) {
        case 'CAPA':
          detailArray = state.CAPADetail;
          break;
        case 'Personnel':
          detailArray = state.PersonnelDetail;
          break;
        case 'Vehicle':
          detailArray = state.VehicleDetail;
          break;
        case 'Equipment':
          detailArray = state.EquipmentDetail;
          break;
        case 'Evidence':
          detailArray = state.EvidenceDetail;
          break;
        default:
          detailArray = [];
          break;
      }

      const detailObject = detailArray.find(detail => detail.id === id);

      return {
        ...state,
        formEdit: { ...state.formEdit, [title]: true },
        [title]: title === "Personnel"
          ? {
            ...state[title],     // Keep the existing data of Personnel
            ...detailObject      // Merge the new data from detailObject
          }
          : detailObject || {}    // For other titles, simply set the detailObject
      };
    };



    case DELETE_CARD_DETAIL: {
      const { id, title } = action.payload;
      let updatedDetailArray;

      // Determine which detail array to modify based on the title
      switch (title) {
        case 'CAPA':
          updatedDetailArray = state.CAPADetail.filter(detail => detail.id !== id);
          return {
            ...state,
            CAPADetail: updatedDetailArray, // Update CAPADetail
          };

        case 'Personnel':
          updatedDetailArray = state.PersonnelDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            PersonnelDetail: updatedDetailArray, // Update PersonnelDetail
          };

        case 'Vehicle':
          updatedDetailArray = state.VehicleDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            VehicleDetail: updatedDetailArray, // Update VehicleDetail
          };

        case 'Equipment':
          updatedDetailArray = state.EquipmentDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            EquipmentDetail: updatedDetailArray, // Update EquipmentDetail
          };

        case 'Evidence':
          updatedDetailArray = state.EvidenceDetail.filter(detail => detail.id !== id);
          return {
            ...state,
            EvidenceDetail: updatedDetailArray, // Update EvidenceDetail
          };

        default:
          return state; // Return the current state if the title doesn't match any case
      }
    }



    default:
      return state;
  }
};

export default incidentReportFromReducer;
