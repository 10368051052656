import { START_LOADING, STOP_LOADING,SHOW_TREE_MODEL ,SET_SEARCH_QUERY} from './constant';

const initialState = {
  loading: false,
  isShowTreeModel:false,
  searchQuery: '',
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {

    case START_LOADING:
      return { ...state, loading: true };

    case STOP_LOADING:
      return { ...state, loading: false };

      case SHOW_TREE_MODEL:
      return { ...state, isShowTreeModel: !state.isShowTreeModel };
      
      case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
      
    default:
      return state;
  }
};

export default loadingReducer;
