import React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Dynamic Multi-Select Component
const CustomMultiSelect = ({ label, value, options = [], onChange, name, multiple = true }) => {
  const theme = useTheme();
  const safeOptions = Array.isArray(options) ? options : [];

  // Helper function to normalize option value and label for both string and object formats
  const getOptionLabel = (option) => {
    return typeof option === 'object' && option !== null ? option.label : option;
  };

  // Determine the value object(s) based on whether the option is a string or object
  const findOption = (value) => {
    if (!value) return multiple ? [] : null;
    return multiple
      ? safeOptions.filter((opt) =>
          typeof opt === 'object' && opt !== null
            ? value.includes(opt.value)
            : value.includes(opt)
        )
      : safeOptions.find((opt) =>
          typeof opt === 'object' && opt !== null
            ? opt.value === value
            : opt === value
        ) || null;
  };

  return (
    <FormControl sx={{  width: '100%' }}>
      <InputLabel id="custom-multi-select-label">{label}</InputLabel>
      <Select
        labelId="custom-multi-select-label"
        id="custom-multi-select"
        fullWidth
        multiple={multiple} // Enable multiple selection if true
        value={findOption(value)} // Handles both object and string options for single or multiple selection
        onChange={(event) => {
          const selectedValue = multiple
            ? event.target.value.map((val) =>
                typeof val === 'object' ? val.value : val
              )
            : typeof event.target.value === 'object'
            ? event.target.value.value
            : event.target.value;
          onChange({ target: { name, value: selectedValue } });
        }}
        InputLabelProps={{
            shrink: true,
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          Array.isArray(selected)
            ? selected.map((opt) => getOptionLabel(opt)).join(', ')
            : getOptionLabel(selected)
        } // Show selected values as plain text
        MenuProps={MenuProps}
        sx={{
          minHeight: '44px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              top: 0,
            },
            '&:hover fieldset': {
              borderColor: '#3f51b5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#3f51b5',
            },
            
          },
        }}
      >
        {safeOptions.map((option) => (
          <MenuItem
            key={typeof option === 'object' ? option.value : option}
            value={option}
            style={{
              fontSize: '12px',
              fontWeight: findOption(value).includes(option)
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
            }}
          >
            {getOptionLabel(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMultiSelect;
