import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

export default function CompletionModalForReview({ open, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: 400,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, color: "#333" }}>
            Tour Completed!
          <br />
          Review your report before submission.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: "#09A2E3", // Custom blue color
            textTransform: "none",
            padding: "10px 20px",
            fontWeight: "600",
            borderRadius: "24px",
            color:'white',
            "&:hover": {
              backgroundColor: "#007BB5", // Darker shade on hover
            },
          }}
          onClick={handleClose}
        >
          Review
        </Button>
      </Box>
    </Modal>
  );
};
