import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { resetState } from '../redux/incidentWorkflow/actions';
import { useDispatch, useSelector } from 'react-redux';
import AlertModel from '../Components/AlertModel';

const CustomToggleButton = styled(ToggleButton)(({ theme, active }) => ({
  border: 'none', // No border
  color: active ? '#FFFFFF' : '#0AA2E3',
  fontSize: '14px',
  backgroundColor: active ? '#0AA2E3' : 'white',
  padding: '8px', // Padding inside button
  '&.Mui-selected': {
    color: '#FFFFFF', // Color when selected
    backgroundColor: '#0AA2E3', // Background color when selected
  },
  '&:hover': {
    backgroundColor: active ? '#0AA2E3' : '#f0f0f0', // Slight hover effect for inactive buttons
  },
}));

export default function CustomToggleButtonGroup({
  title = '',
  options = [],
  defaultValue = '',
  onChange
}) {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [message, setMessage] = useState("You have filled return form are you wanted to cancel it to procees with review");
  const [open, setOpen] = useState(false);
  const workflowData = useSelector(state => state.incidentWorkFlowReducer);
  const workInvestflowData = useSelector(state => state.incidentInvestigationFlowReducer);
  // console.log("tttt",title);

  const {
    Return: workflowReturn,    // Rename Return to workflowReturn
    Review,
    switchStates: workflowSwitchStates,   // Rename switchStates to workflowSwitchStates
    Investigation,
    Assign_Investigation,
    CAPADetail: workflowCAPADetail  // Rename CAPADetail to workflowCAPADetail
  } = workflowData;
  
  // Destructure workInvestflowData and rename conflicting variables
  const {
    Return: investReturn,   // Rename Return to investReturn
    switchStates: investSwitchStates,  // Rename switchStates to investSwitchStates
    WorkflowInput,
    RootCauseAnalysis,
    RegulatoryInfo,
    CAPADetail: investCAPADetail,  // Rename CAPADetail to investCAPADetail
    InvestigationEvidenceDetail,
    WitnessStatementDetail,
    IncidentResponsibilityDetail,
    MitigationMeasuresDetail,
    LessonsLearnedDetail,
    CompensationInfoDetail,
    ContributingFactorDetail
  } = workInvestflowData;



  const handleButtonClick = (value) => {
    // console.log(value);
    setSelectedValue(value); 
    if (onChange) {
      if (
        (value === 'next' && Object.keys(workflowReturn).length > 0) || value === 'next' && Object.keys(investReturn).length > 0  ||
        (value === 'previous' && (
          Object.keys(Review).length > 0 ||  
          Object.keys(Assign_Investigation).length > 0
        ) || (value === 'previous' && title === "Investigator Action" && (investSwitchStates.CAPA === "No" || (investCAPADetail && investCAPADetail.length > 0)) ||
        (investSwitchStates.InvestigationEvidence === "No" || (InvestigationEvidenceDetail && InvestigationEvidenceDetail.length > 0)) ||
        (investSwitchStates.WitnessStatement === "No" || (WitnessStatementDetail && WitnessStatementDetail.length > 0)) ||
        (investSwitchStates.IncidentResponsibility === "No" || (IncidentResponsibilityDetail && IncidentResponsibilityDetail.length > 0)) ||
        (investSwitchStates.MitigationMeasures === "No" || (MitigationMeasuresDetail && MitigationMeasuresDetail.length > 0)) ||
        (investSwitchStates.LessonsLearned === "No" || (LessonsLearnedDetail && LessonsLearnedDetail.length > 0)) ||
        (investSwitchStates.CompensationInfo === "No" || (CompensationInfoDetail && CompensationInfoDetail.length > 0)) ||
        (investSwitchStates.ContributingFactor === "No" || (ContributingFactorDetail && ContributingFactorDetail.length > 0)) ||
        (investSwitchStates.RegulatoryInfo === "No" || (RegulatoryInfo && Object.keys(RegulatoryInfo).length > 0)) ||
        (investSwitchStates.RootAnalysis === "No" || (RootCauseAnalysis && RootCauseAnalysis.length > 0)) ||
        (WorkflowInput && Object.keys(WorkflowInput).length > 0)))
      ) {
        setOpen(true); // Open the modal
        // Notify the parent component of the selected value
      } else {

        onChange(value); // Just notify the parent if it's the same value or empty

      }
    }
  };

  const handleProceed = () => {
     
    onChange(selectedValue);
    dispatch(resetState());
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false); // Close dialog on cancel
  };

  return (
    <div>
      <Typography variant="body2" sx={{ color: '#666F7F', mb: '8px' }}>
        {title}
      </Typography>
      <ToggleButtonGroup
        value={selectedValue}
        exclusive
        // onChange={handleActionChange}
        aria-label={title.toLowerCase().replace(/\s/g, '-')}
      >
        {options.map((option) => (
          <CustomToggleButton
            key={option.value}
            active={selectedValue === option.value}
            onClick={() => handleButtonClick(option.value)}
          >
            {option.label}
          </CustomToggleButton>
        ))}
      </ToggleButtonGroup>
      <AlertModel
        open={open}
        onClose={handleClose}
        onProceed={handleProceed}
        // title={title}
        description={message}
      />
    </div>
  );
}

