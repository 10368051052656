import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Radio,
  Grid,
} from "@mui/material";
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export default function AssignTicketDialog({open, onClose}) {
  const [selectedAgent, setSelectedAgent] = useState(null);

  const agents = [
    { id: 1, name: "Simran", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 2, name: "Anjali", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 3, name: "Rahul", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 4, name: "Dipanshu", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
    { id: 5, name: "Sarthak", email: "Emailid@gmail.com", completed: 20, assigned: 15 },
  ];

  const handleSelect = (id) => {
    setSelectedAgent(id);
  };

  return (
    <Dialog 
        open={open}
      onClose={onClose}
      fullWidth 
      maxWidth="sm"
    >
      <DialogTitle>
        <Box display="flex" flexDirection='column' gap={1}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#E6F7FF',
                        borderRadius: '50%',
                        width: '48px', // Set equal width and height for a perfect circle
                        height: '48px',
                    }}
                >
                <BusinessCenterOutlinedIcon fontSize="32px" sx={{color: '#09A2E3'}}/>
                </Box>
                <Box>
            <Typography sx={{color:'#15294B', fontSize:'20px', fontWeight:'600'}}>Assign this ticket</Typography>
            <Typography sx={{color:'#667085', fontSize:'14px'}}>
                Select an agent to assign to the ticket.
            </Typography>
            </Box>
        </Box>
      </DialogTitle>
      <DialogContent
  sx={{
    display: "flex",
    flexDirection: "column",
    height: "100%", // Ensure it takes up full height
  }}
>
  <TextField
    fullWidth
    placeholder="Search for an Agent"
    size="small"
    sx={{
      mb: 2,
      border: "1px solid #EFF1F4",
      borderRadius: "8px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px", // Ensures the border radius for the text box
      },
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon sx={{ color: "#9095A0" }} />
        </InputAdornment>
      ),
    }}
  />
  <Box
    sx={{
      flexGrow: 1, // Allow the box to grow and fill remaining space
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px 12px 10px 12px",
      overflowY: "auto", // Enable vertical scrolling
    }}
  >
    {agents.map((agent) => (
      <Card
        key={agent.id}
        sx={{
          mb: 2,
          border: selectedAgent === agent.id ? "2px solid #1976d2" : "1px solid #ddd", padding:'0px !important',
          borderRadius: "8px",
          backgroundColor: selectedAgent === agent.id ? "#e3f2fd" : "white",
        }}
      >
        <CardActionArea onClick={() => handleSelect(agent.id)}>
          <CardContent sx={{padding:'12px !important'}}>
            <Box display='flex' flexDirection='row' alignItems='flex-start' justifyContent='space-between'>
                <Box display='flex' flexDirection='row' alignItems='flex-start' gap={1.5}>
                    <Avatar>{agent.name.charAt(0)}</Avatar>

                    <Box display='flex' flexDirection='column' gap={1}>
                        <Box display='flex' flexDirection='row' alignItems='flex-start' gap={1.5}>
                            <Box>
                        <Typography sx={{color:'#091E42', fontSize:'14px', fontWeight:'700'}}>
                        {agent.name}
                        </Typography>
                        <Typography sx={{color:'#667085', fontSize:'12px', fontWeight:'500'}}>
                            {agent.email}
                        </Typography>
                        </Box>

                        </Box>
                        <Box display='flex' flexDirection='column' gap={0}>
                        <Typography sx={{color:'#091E42', fontSize:'14px', fontWeight:'500'}}>
                            No. of tickets completed: {agent.completed}
                        </Typography>
                        <Typography sx={{color:'#091E42', fontSize:'14px', fontWeight:'500'}}>
                            No. of assigned tickets: {agent.assigned}
                        </Typography>
                        </Box>
                    </Box>
                </Box>

                <Radio
                    checked={selectedAgent === agent.id}
                    icon={<RadioButtonUncheckedIcon style={{ fontSize: 24 }} />}
                    checkedIcon={<CheckCircleIcon style={{ color: '#09A2E3', fontSize: 24 }} />}
                    value={agent.id}
                    // onChange={() => console.log(`Selected agent: ${agent.id}`)} // Replace with your logic
                />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    ))}
  </Box>
</DialogContent>

      <DialogActions>
          <Box display='flex' alignItems='center' gap={1} sx={{width:'100%'}}>
        <Button variant="outlined" onClick={() => onClose()} sx={{width:'100%', border: '1px solid #09A2E3', color:'#344054'}}>
          Cancel
        </Button>
        <Button
          variant="contained" sx={{width:'100%', backgroundColor:'#09A2E3', color:'white'}}
          onClick={() => console.log("Assigning to Agent:", selectedAgent)}
          disabled={!selectedAgent}
        >
          Assign
        </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
