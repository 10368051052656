import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  TextField,
  Accordion,
  AccordionSummary,
  Button,
  styled,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import DisciplinePieChart from "../Components/LossControl/DisciplinePieChart";
import DetailedView from "../Components/LossControl/DetailedView";
import {
  getPastLossControlTours,
  getLossControlReport,
  getSiteLocationName,
} from "../Apis/apiCall";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { todayDate, userFullName } from "../constant";
import { formatDateToIndianLocale } from "../utils/helper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CenteredIconBox = ({
  children,
  backgroundColor = "rgba(0, 159, 245, 0.10)",
  borderRadius = "6px",
  padding = "8px",
  ...props
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
        padding: padding,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const StyledTab = styled(Tab)(({ selected }) => ({
  textTransform: "none",
  borderRadius: "12px",
  backgroundColor: selected ? "#09A2E3" : "#F5FBFF",
  color: selected ? "#ffffff" : "#323232",
  fontWeight: "400",
  minHeight: "26px !important",
  margin: "0 8px",
  transition: "background-color 0.3s ease",

  "&.Mui-selected": {
    color: "#ffffff",
    minHeight: "26px !important",
  },
}));

const loginDataString = localStorage.getItem("loginData");
const loginData = loginDataString ? JSON.parse(loginDataString) : null;

export default function PastLossControl() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const finalData = location.state || {};
  const tabsRef = useRef(null);
  const [listView, setListView] = useState(0);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [detailedView, setDetailedView] = useState(false);
  const [pastLossControl, setPastLossControl] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [value, setValue] = useState(0);
  const [filteredData, setFilteredData] = useState(pastLossControl);
  //for particular discipline if selected
  const [selectedDisciplineID, setSelectedDisciplineID] = useState(null);
  const [selectedDiscipline, setSelectedDiscipline] = useState({});
  const [questions, setQuestions] = useState([]);
  const [disciplineData, setDisciplineData] = useState([]);
  const [counts, setCounts] = useState([]);
  const [pastLCEXE, setPastLCEXE] = useState(null);
  const [pastLCDate, setPastLCDate] = useState(null);
  const [pastLCLoc, setPastLCLoc] = useState(null);
  const [pastDisciplineName, setPastDisciplineName] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchDisciplines();
  }, []);

  const fetchDisciplines = () => {
    const data = JSON.parse(localStorage.getItem("loginData"));

    const formFields =
      data?.config?.modulesDetail?.[1]?.sectionsDetail?.[0]?.formFields;
    if (formFields) {
      const formFieldNames = formFields?.map((field) => field.formFieldName);
      setDisciplines(formFieldNames);
    } else {
      console.error("formFields is undefined or cannot be found");
    }
  };

  useEffect(() => {
    fetchAllPastLossControlTours();
    fetchSiteLocation();
  }, []);

  useEffect(() => {
    if (selectedDisciplineID) {
      getlossControlData(selectedDisciplineID);
    }
  }, [selectedDisciplineID]);

  const fetchSiteLocation = async () => {
    try {
      const res = await getSiteLocationName(id);
      setPastLCLoc(res.data.data.displayName);
    } catch (error) {
      console.error("Errow while getting site location", error);
    }
  };

  const fetchAllPastLossControlTours = async () => {
    try {
      const res = await getPastLossControlTours(id);
      setPastLossControl(res.data?.data);
      if (res.data?.data?.length > 0) {
        setSelectedDisciplineID(res.data?.data[0].id);
        await getlossControlData(res.data?.data[0].id);
      }
    } catch (error) {
      console.log("Error while fetching past loss control tours");
    }
  };

  const getlossControlData = async (obj) => {
    try {
      const res = await getLossControlReport(obj);
      console.log(res.data.data);
      setDisciplineData(res.data.data);
      setQuestions(res.data.data.disciplineData);
      setPastDisciplineName(res.data.data.discipline);
      setPastLCEXE(
        `${res.data.data.firstName} ${res.data.data.middleName} ${res.data.data.lastName}`
      );
      setPastLCDate(res.data.data.submittedByEX);
      setPastLCLoc(res.data.data.locationName);
      const fetchedCounts = [
        res?.data?.data?.counts?.satisfactory,
        res?.data?.data?.counts?.unsatisfactory,
        res?.data?.data?.counts?.notApplicable,
      ];
      setCounts(fetchedCounts);
    } catch (error) {
      alert("Error while fetching questionnaires");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setFilteredData(pastLossControl);
    } else {
      const selectedDisciplineName = disciplines[newValue - 1];
      setFilteredData(
        pastLossControl?.filter(
          (item) => item.discipline === selectedDisciplineName
        )
      );
    }
  };

  const handleSelectedDisipline = async (obj) => {
    setSelectedDiscipline(obj);
    setSelectedDisciplineID(obj.id);
    try {
      const res = await getLossControlReport(obj.id);
      setDisciplineData(res.data?.data);
      setQuestions(res.data?.data?.disciplineData);
      setPastDisciplineName(res.data.data.discipline);
      setPastLCEXE(
        `${res.data.data.firstName} ${res.data.data.middleName} ${res.data.data.lastName}`
      );
      setPastLCDate(res.data.data.submittedByEX);
      setPastLCLoc(res.data.data.locationName);
      const fetchedCounts = [
        res?.data?.data?.counts?.satisfactory,
        res?.data?.data?.counts?.unsatisfactory,
        res?.data?.data?.counts?.notApplicable,
      ];
      setCounts(fetchedCounts);
    } catch (error) {
      alert("Error while fetching questionnaires");
    }
  };

  // Handle the input change and update the search query state
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter the disciplines based on the search query
  const filteredDisciplines = disciplines?.filter((discipline) =>
    discipline.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChangeQuestion = (index) => {
    setSelectedCardIndex(index === selectedCardIndex ? null : index);
  };

  useEffect(() => {
    setFilteredData(pastLossControl);
  }, [pastLossControl]);

  const handleBackToDashboard = () => {
    navigate("/module/loss-control");
  };

  function convertEpochToIST(timestamp) {
    const date = new Date(timestamp);
    const istDate = date.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });

    return istDate;
  }

  const scrollLeft = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft -= 50;
    }
  };

  const scrollRight = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft += 50;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        padding: "16px",
        minHeight: "auto",
      }}
    >
      {finalData && Object.keys(finalData).length > 0 && (
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Report Submitted Successfully!!
          </Typography>
          <Typography variant="subtitle2">
            Here's a snapshot of your tour just submitted.
          </Typography>
        </Box>
      )}
      {detailedView === false ? (
        <Grid container spacing={2} sx={{ display: "flex" }}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                position: "relative",
                borderRadius: "8px",
                background: "#FFF",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                padding: "16px",
                flexGrow: 1,
                minHeight: "75vh",
                width: "100%",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{
                    color: "#222222",
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  Past Loss Control Tours
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  <CenteredIconBox
                    backgroundColor={
                      !listView ? "#009FF5" : "rgba(0, 159, 245, 0.10)"
                    }
                    onClick={() => setListView(0)}
                  >
                    <PieChartOutlinedIcon
                      fontSize="small"
                      sx={{ color: !listView ? "white" : "#009FF5" }}
                    />
                  </CenteredIconBox>

                  <CenteredIconBox
                    backgroundColor={
                      listView ? "#009FF5" : "rgba(0, 159, 245, 0.10)"
                    }
                    onClick={() => setListView(1)}
                  >
                    <FormatListBulletedIcon
                      fontSize="small"
                      sx={{ color: listView ? "white" : "#009FF5" }}
                    />
                  </CenteredIconBox>
                </Box>
              </Box>

              <Box display="flex" gap={0.5} alignItems="center">
                <LocationOnOutlinedIcon fontSize="small" />
                <Typography
                  sx={{ color: "#323232", fontSize: "14px", fontWeight: "400" }}
                >
                  Tour Site:
                </Typography>
                <Typography
                  sx={{ color: "#009FF5", fontSize: "14px", fontWeight: "400" }}
                >
                  {finalData && Object.keys(finalData)?.length > 0
                    ? finalData.siteLocation
                    : pastLCLoc}
                </Typography>
              </Box>

              {/* Search Bar */}

              <Box
                display="flex"
                alignItems="center"
                mt={2}
                sx={{
                  backgroundColor: "rgba(244, 244, 244, 0.96)",
                  borderRadius: "10px",
                  paddingX: "12px",
                  paddingY: "0px",
                }}
              >
                <SearchIcon style={{ color: "#6F767E" }} />
                <TextField
                  placeholder="Search or type"
                  variant="outlined"
                  value={searchQuery} // Bind the value to the state
                  onChange={handleSearchChange} // Update the state on input change
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#000",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                    },
                  }}
                />
              </Box>

              {/* List of Disciplines */}

              <Box
                mt={2}
                ref={tabsRef}
                sx={{
                  width: "88%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  ml: "5%",
                  //position:'relative'
                }}
              >
                <IconButton
                  onClick={scrollLeft}
                  sx={{
                    position: "absolute",
                    left: 5,
                    zIndex: 1,
                    top: "29%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>

                <Box
                  ref={tabsRef}
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    minWidth: "max-content",
                  }}
                >
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="custom tabs"
                    variant="scrollable"
                    scrollButtons={false}
                    sx={{
                      display: "flex",
                    }}
                  >
                    <StyledTab label="All" selected={value === 0} />
                    {filteredDisciplines.map((discipline, index) => (
                      <StyledTab
                        key={index}
                        label={discipline}
                        selected={value === index + 1}
                      />
                    ))}
                  </StyledTabs>
                </Box>

                <IconButton
                  onClick={scrollRight}
                  sx={{
                    position: "absolute",
                    right: 10,
                    zIndex: 1,
                    top: "29%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box>

              <Divider sx={{ my: "12px", width: "99%" }} />

              {/* Disciplines list */}
              
              <Grid
                container
                spacing={1.5}
                sx={{
                  overflowY: "auto",
                  maxHeight: "340px",
                  whiteSpace: "nowrap",
                }}
              >
                {filteredData.length > 0 ? (
                  filteredData?.map((obj, index) => (
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          backgroundColor: "#F5FBFE",
                          borderRadius: "6px",
                          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                          padding: "12px",
                          width: "99%",
                          cursor: "pointer",
                          border:
                            selectedDisciplineID === obj.id
                              ? "2px solid #a0d9ef"
                              : "none",
                        }}
                        onClick={() => handleSelectedDisipline(obj)}
                      >
                        <Box display="flex" flexDirection="column" gap={0.5}>
                          <Typography variant="body1" sx={{ color: "#1A1A1A" }}>
                            {obj.discipline}
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0.5}
                          >
                            <PersonOutlineOutlinedIcon
                              sx={{
                                color: "#525966",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                color: "#525966",
                                fontSize: "13px",
                                fontWeight: "400",
                              }}
                            >
                              {`${obj.firstName} ${obj.middleName} ${obj.lastName}`}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={0.5}
                          >
                            <CalendarMonthOutlinedIcon
                              sx={{
                                color: "#525966",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            <Typography
                              sx={{
                                color: "#525966",
                                fontSize: "13px",
                                fontWeight: "400",
                              }}
                            >
                              {convertEpochToIST(obj.createdAt)}
                            </Typography>
                          </Box>
                        </Box>
                        <ArrowForwardIosIcon
                          sx={{
                            color: "#525966",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
                    <Typography>No record found</Typography>
                  </Box>
                )}
              </Grid>




              <Button
                onClick={handleBackToDashboard}
                variant="outlined"
                color="error" // Use color prop instead of type for color in Material-UI
                sx={{
                  position: "relative",
                  bottom: 0, // Adjust to desired padding from the bottom
                  left: 2, // Adjust to desired padding from the left
                  top: 5,
                }}
              >
                Exit
              </Button>
            </Box>
          </Grid>







          <Grid
            item
            xs={12}
            md={7}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {selectedDiscipline ||
            (finalData && Object.keys(finalData)?.length > 0) ? (
              <Box
                sx={{
                  borderRadius: "8px",
                  background: "#FFF",
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                  padding: "16px",
                  flexGrow: 1,
                  minHeight: "75vh",
                  width: "100%",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#222",
                    fontSize: "16px",
                    fontWeight: "500",
                    mb: "4px",
                  }}
                >
                  {finalData && Object.keys(finalData)?.length > 0
                    ? finalData.discipline
                    : pastDisciplineName}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                >
                  {selectedDiscipline && (
                    <>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={0.5}
                      >
                        <PersonOutlineOutlinedIcon
                          sx={{
                            color: "#525966",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#525966",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {finalData && Object.keys(finalData)?.length > 0
                            ? userFullName
                            : pastLCEXE}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={0.5}
                      >
                        <CalendarMonthOutlinedIcon
                          sx={{
                            color: "#525966",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#525966",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {finalData && Object.keys(finalData)?.length > 0
                            ? todayDate
                            : pastLCDate !== null
                            ? convertEpochToIST(pastLCDate)
                            : ""}
                        </Typography>
                      </Box>
                    </>
                  )}
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={0.5}
                  >
                    <LocationOnOutlinedIcon
                      sx={{ color: "#525966", width: "20px", height: "20px" }}
                    />
                    <Typography
                      sx={{
                        color: "#525966",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {finalData && Object.keys(finalData)?.length > 0
                        ? finalData?.siteLocation
                        : pastLCLoc}
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: "12px" }} />

                {listView === 0 && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1.5}
                    sx={{
                      borderRadius: "10px",
                      border: "0.394px solid #B7C8E5",
                      padding: "16px",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                      {finalData && Object.keys(finalData)?.length > 0
                        ? finalData.discipline
                        : setPastDisciplineName}
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5.5}>
                        <Box display="flex" flexDirection="column" gap={1}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Box
                                sx={{
                                  padding: "8px",
                                  backgroundColor: "rgba(19, 222, 185, 0.20)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                }}
                              >
                                <SentimentSatisfiedAltIcon
                                  sx={{ fontSize: "20px", color: "#0FAB8E" }}
                                />
                              </Box>
                              <Typography
                                sx={{
                                  color: "#525966",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                }}
                              >
                                Satisfactory
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                color: "#525966",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}
                            >
                              {finalData?.questionaires?.filter(
                                (q) => q?.questionStatus === 0
                              ).length >= 0
                                ? finalData?.questionaires?.filter(
                                    (q) => q?.questionStatus === 0
                                  )?.length
                                : counts[0]}
                            </Typography>
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Box
                                sx={{
                                  padding: "8px",
                                  backgroundColor: "rgba(255, 87, 83, 0.20)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                }}
                              >
                                <SentimentVeryDissatisfiedIcon
                                  sx={{ fontSize: "20px", color: "#FF231F" }}
                                />
                              </Box>
                              <Typography
                                sx={{
                                  color: "#525966",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                }}
                              >
                                Unsatisfactory
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                color: "#525966",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}
                            >
                              {finalData?.questionaires?.filter(
                                (q) => q?.questionStatus === 1
                              ).length >= 0
                                ? finalData?.questionaires?.filter(
                                    (q) => q?.questionStatus === 1
                                  )?.length
                                : counts[1]}
                            </Typography>
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Box
                                sx={{
                                  padding: "8px",
                                  backgroundColor: "rgba(9, 162, 227, 0.20)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                }}
                              >
                                <SentimentNeutralIcon
                                  sx={{ fontSize: "20px", color: "#09A2E3" }}
                                />
                              </Box>
                              <Typography
                                sx={{
                                  color: "#525966",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                }}
                              >
                                Not Applicable
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                color: "#525966",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}
                            >
                              {finalData?.questionaires?.filter(
                                (q) => q?.questionStatus === 2
                              )?.length >= 0
                                ? finalData?.questionaires?.filter(
                                    (q) => q?.questionStatus === 2
                                  )?.length
                                : counts[2]}
                            </Typography>
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                              backgroundColor: "rgba(9, 162, 227, 0.10)",
                              borderRadius: "8px",
                              padding: "8px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#525966",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}
                            >
                              Total Points
                            </Typography>
                            <Typography
                              sx={{
                                color: "#525966",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}
                            >
                              {finalData?.questionaires?.length ||
                                counts[0] + counts[1] + counts[2]}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={0.5}>
                        <Divider orientation="vertical" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <DisciplinePieChart
                            counts={
                              (counts && counts.length > 0) ||
                              (finalData && Object.keys(finalData)?.length > 0)
                                ? finalData?.questionaires
                                  ? [
                                      finalData.questionaires?.filter(
                                        (q) => q.questionStatus === 0
                                      ).length,
                                      finalData.questionaires?.filter(
                                        (q) => q.questionStatus === 1
                                      ).length,
                                      finalData.questionaires?.filter(
                                        (q) => q.questionStatus === 2
                                      )?.length,
                                    ]
                                  : counts
                                : [0, 0, 0] // Default fallback if no data is available
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {listView !== 0 && (
                  <div
                    style={{ maxHeight: "calc(11 * 40px)", overflowY: "auto" }}
                  >
                    <Grid container spacing={1}>
                      {(finalData?.questionaires &&
                      finalData.questionaires?.length > 0
                        ? finalData.questionaires
                        : questions && questions?.length > 0
                        ? questions
                        : []
                      )?.map((card, index) => (
                        <Grid item xs={12} key={index}>
                          <Accordion
                            expanded={selectedCardIndex === index}
                            onChange={() => handleChangeQuestion(index)}
                            sx={{
                              borderRadius: "8px",
                              backgroundColor: "#F9FAFC",
                              border: "0.5px solid rgba(9, 162, 227, 0.20)",
                              boxShadow: "none",
                              overflow: "hidden",
                              transition: "max-height 0.3s ease",
                              paddingY: "0px !important",
                            }}
                          >
                            <AccordionSummary
                              sx={{
                                height: "40px",
                                padding: 0,
                                justifyContent: "flex-start",
                                "& .MuiAccordionSummary-expandIconWrapper": {
                                  display: "none",
                                },
                              }}
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                gap={1}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{
                                    backgroundColor: "#E6F6FE",
                                    borderRadius: "50%",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#09A2E3",
                                      fontSize: "10px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    {index + 1}
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "#525966" }}
                                >
                                  {selectedCardIndex === index
                                    ? card?.question
                                    : card?.question?.length > 20
                                    ? `${card?.question.substring(0, 30)}...`
                                    : card?.question}
                                </Typography>
                              </Box>
                            </AccordionSummary>
                          </Accordion>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}

                <Box display="flex" justifyContent="flex-end" mt={2}>
                  {((selectedDiscipline && questions?.length > 0) ||
                    (finalData && Object.keys(finalData)?.length > 0)) && (
                    <Button
                      variant="contained"
                      onClick={() => setDetailedView(true)}
                      size="small"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        backgroundColor: "#09A2E3",
                        color: "#ffffff",
                        paddingY: "8px",
                        paddingX: "32px",
                        borderRadius: "24px",
                      }}
                    >
                      See Details
                    </Button>
                  )}
                </Box>
              </Box>
            ) : (
              <Typography>Select Discipline to View Data</Typography>
            )}
          </Grid>
        </Grid>







      ) : (
        <DetailedView
          cards={
            finalData && Object.keys(finalData)?.length > 0
              ? finalData.questionaires
              : questions
          }
          close={setDetailedView}
          selectedDiscipline={selectedDiscipline}
          disciplineData={disciplineData}
          newSnapshot={finalData && Object.keys(finalData)?.length > 0 ? 1 : 0}
          finalData={finalData}
        />
      )}
    </Box>
  );
}
