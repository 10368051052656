import React, {useState, useEffect} from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Fab,
  Box,
  Switch,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "../CustomInputField";
import { completeOrSaveTask } from "../../Apis/apiCall";
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import UploadIcon from "@mui/icons-material/Upload";
import Compressor from 'compressorjs';
import ConfirmationModal from "./ConfirmationModal";
import dayjs from "dayjs";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../../redux/Loader/constant"
 
const AddButtonStyle = {
  fontSize: "16px",
  fontWeight: "600",
  paddingY: "8px",
  paddingX: "16px",
  color: "#fff",
  borderRadius: "60px",
  backgroundColor: "#0AA2E3",
  width: "70px",
  textTransform: "none",
};

export default function IMDrawer({ status, open, onClose, title, quesData, reportID,flow, tourDate }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);
  const [isCompleted, setIsCompleted] = useState(0);
  const [comment, setComment] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [action, setAction] = useState("");
  const [imEvidence, setImEvidence] = useState([]);

  console.log("IM Drawer Opened")
  const handleEvidenceUpload = async (event) => {
    const files = event.target.files;
    const newEvidence = await Promise.all(
      Array.from(files).map(async (file) => {
        const base64 = await convertToBase64(file);
        return {
          base64: base64.split(',')[1], // Remove data prefix for storage
          name: file.name,
          type: file.type,
        };
      })
    );
    setImEvidence((prev) => [...prev, ...newEvidence]);
  };

  const handleFileDelete = (index) => {
    setImEvidence((prev) => prev.filter((_, i) => i !== index));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      // Compress the file before converting it to base64
      new Compressor(file, {
        quality: 0.2, // Set the compression quality
        success(compressedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
        },
        error(err) {
          reject(err);
        },
      });
    });
  };
  function convertToEpoch(dateString) {
    const date = new Date(dateString);
    return Math.floor(date.getTime()); // Convert milliseconds to seconds
  }


  const handleSwitchChange = (event) => {
    setIsCompleted(event.target.checked ? 1 : 0);
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalDetails, setConfirmationModalDetails] = useState({ title: 'Confirmation!', message: 'Are you sure you want to proceed?'});

  const handleSave1 = async () => {
    const data = {
      reportID: reportID,
      questionID: quesData.id,
      isCompleted: isCompleted,
      workInProgressComment: comment
    };
    dispatch({type : START_LOADING})
    try {
      const response = await completeOrSaveTask(data);
      console.log("Response:", response.data);
      onClose(); // Close after save
      window.location.reload();
    } catch (error) {
      console.error("Error saving task:", error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };

  const handleSave2 = async () => {
    const data = {
      reportID: reportID,
      questionID: quesData.id,
      isCompleted: isCompleted,
      actionTaken: action,
      completionDate: convertToEpoch(selectedDate),
      imEvidence: imEvidence
    };
    // console.log("completion issue", data);
    dispatch({type : START_LOADING})
    try {
      const response = await completeOrSaveTask(data);
      console.log("Response:", response.data);
      onClose(); // Close after save
      window.location.reload();
    } catch (error) {
      console.error("Error saving task:", error);
    }finally{
      dispatch({ type: STOP_LOADING }); 
    }
  };
  // Function to handle confirmation modal open
  const handleButtonClick = () => {
    // setPendingSaveAction(isCompleted === 0 ? handleSave1 : handleSave2);
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmCancel = () => {
    setIsConfirmationModalOpen(false);
    // setPendingSaveAction(null);
  };
  // Function to handle the confirm action
  const handleConfirmAction = () => {
    // if (pendingSaveAction) {
    //   pendingSaveAction();
    // }
    isCompleted === 0 ? handleSave1() : handleSave2();
    setIsConfirmationModalOpen(false);
  };

  useEffect(() => {
        setComment(quesData?.workInProgressComment || "");
    }, [quesData?.workInProgressComment]);


  return (
    <>
    {loading && ( 
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Loader />
        </Box>
      )}

    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: "36%", padding: "0px !important" },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          {(title || "").replace(/_/g, " ")}
        </Typography>
      </Grid>

      <div style={{ padding: "28px 24px" }}>
        {flow === 1 && (
            <Typography variant="body2">
            {(quesData?.question || "").replace(/_/g, " ")}
            </Typography>
        )}
        
        <Box
          my={2}
          p={3}
          border="1px solid #ddd"
          sx={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)" }}
          borderRadius={4}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
            mb={2}
          >
            {flow === 1 && (
                <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1.5}
              >
                <Typography
                  sx={{ color: "#222", fontSize: "14px", fontWeight: "500" }}
                >
                  Work in Progress
                </Typography>
                <Switch
                  checked={isCompleted === 0 ? 0 : 1}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography
                  sx={{ color: "#222", fontSize: "14px", fontWeight: "500" }}
                >
                  Work Completed
                </Typography>
              </Box>
            )}
            
          </Box>
          {isCompleted === 1 || flow === 2 ? (
            <>
            <Box mb={2}>
            <Typography variant="body2" sx={{mb:2}}>
            {(quesData?.question || "").replace(/_/g, " ")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Completion Date"
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)}
                    fullWidth
                    slotProps={{ textField: { fullWidth: true } }}
                    format ="DD-MM-YYYY"
                    renderInput={(params) => (
                    <TextField 
                        {...params} 
                        fullWidth 
                        variant="outlined" 
                        InputLabelProps={{ shrink: true }} 
                    />
                    )}
                    minDate={dayjs(tourDate)}
                    maxDate={dayjs()}
                />
                </LocalizationProvider>
            </Box>
            <Box mb={2}>
                <CustomInput
                    placeholder="type here..."
                    label="Action Taken"
                    multiline
                    rows={4}
                    type="text"
                    value={action}
                    onChange={(e) => setAction(e.target.value)}
                />
                </Box>

                {/* Evidence Box */}
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography gutterBottom sx={{ fontSize: "14px", fontWeight: "600" }}>
                        Evidence
                    </Typography>
                    <Box
                        sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px dashed #09A2E3",
                        padding: 2,
                        borderRadius: 1,
                        textAlign: "center",
                        width: "100%",
                        }}
                    >
                        <IconButton component="label">
                        <UploadIcon fontSize="large" sx={{ color: "#000000" }} />
                        <input
                            hidden
                            accept="image/*,.doc,.pdf,.mp4"
                            type="file"
                            onChange={handleEvidenceUpload}
                            multiple
                        />
                        </IconButton>
                        <Typography
                        variant="caption"
                        sx={{
                            mt: 1,
                            color: "#348BD2",
                            fontWeight: "600",
                            textDecoration: "underline",
                        }}
                        >
                        Click to upload
                        </Typography>
                        <Typography variant="caption" sx={{ fontWeight: "600" }}>
                        PNG, JPG, Docx, PDF, and MP4 (max. 10 MB)
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        mt: 2,
                        gap: 1,
                        }}
                    >
                        {imEvidence.map((file, index) => (
                        <Box key={index} sx={{ position: "relative", width: 80, height: 80 }}>
                            <IconButton
                            onClick={() => handleFileDelete(index)}
                            sx={{
                                position: "absolute",
                                top: -8,
                                right: -8,
                                zIndex: 2,
                                backgroundColor: "white",
                                padding: "4px",
                                "&:hover": {
                                backgroundColor: "white",
                                },
                                boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                            }}
                            size="small"
                            >
                            <CloseIcon fontSize="small" />
                            </IconButton>
                            <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                borderRadius: 2,
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #ccc",
                            }}
                            >
                            {file.type.startsWith("image/") && (
                                <img
                                src={`data:${file.type};base64,${file.base64}`}
                                alt={file.name}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                                />
                            )}
                            </Box>
                        </Box>
                        ))}
                    </Box>
                    </Box>
                
            </>
          ) : (
            <CustomInput
            placeholder="type here..."
            label="Comment"
            multiline
            rows={4}
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
        />)}
        </Box>
        <Grid item xs={12} mt={3}>
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              float: "right",
              color: '#ffffff'
            }}
          >
            {"Submit"}
          </Button>
        </Grid>
      </div>

      <Fab
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "100px",
          right: open ? "37.3%" : "0", // Adjust this value based on drawer width
          transform: "translateX(50%)",
          zIndex: 1200,
          height: "36px",
          width: "36px",
          backgroundColor: "#0AA2E3",
          borderRadius: "60px 0 0 60px",
        }}
      >
        <CloseIcon />
      </Fab>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title={confirmationModalDetails.title}
        message={confirmationModalDetails.message}
        onCancel={handleConfirmCancel}
        onConfirm={handleConfirmAction}
      />
    </Drawer>
    </>
  );
}