import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarGraph = ({ data }) => {
  // Chart.js data and options
  

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true, // Show vertical grid lines
        },
        ticks: {
          display: false, // Hide the labels on the x-axis
        },
      },
      y: {
        grid: {
          display: true, // Show horizontal grid lines
        },
        ticks: {
          display: true, // Show labels on y-axis
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
  };
  

  return (
    <Box sx={{ width: '100%', height: "130px" }}>      
      <Bar data={data} options={options} />
    </Box>
  );
};

export default BarGraph;
