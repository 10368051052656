import temperature from "./assets/images/temperature.svg";
import Investigation from "./assets/images/Investigation.svg";
import MinesManager from "./assets/images/MinesManager.svg";
import ReportIncident from "./assets/images/ReportIncident.svg";
import SafetyFirst from "./assets/images/SafetyFirst.png";
import SafetyOfficer from "./assets/images/SafetyOfficer.svg";
import waves from "./assets/images/waves.png";
import searchIcon from "./assets/images/search.svg";
import listIcon from "./assets/images/list.svg";
import filterIcon from "./assets/images/filter.svg";
import greenSafetyManager from "./assets/images/greenSafetyManager.svg";
import blueInvestigator from "./assets/images/blueInvestigator.svg";
import blueCAPA from "./assets/images/blueCAPA.svg";
import yellowReportIncident from "./assets/images/yellowReportIncident.svg";
import redMinesManager from "./assets/images/redMinesManager.svg";
import actionIcon from "./assets/images/actionIcon.svg";
import Capa from "./assets/images/Capa.svg";
import cardHeadBG from "./assets/images/cardHeadBG.png";
import eyeIcon from "./assets/images/eyeIcon.svg";
import wavesRed from "./assets/images/wavesRed.png";
import rainy from "./assets/images/rainy.gif";
import sunny from "./assets/images/sunny.gif";
import person_outline from "./assets/images/person_outline.svg";
import setting_outline from "./assets/images/setting_outline.svg";
import car_outline from "./assets/images/car_outline.svg";
import incidentManagementIcon from "./assets/images/incidentManagementIcon.svg";
import locationMark from "./assets/images/locationMark.png";
import editColoredIcon from "./assets/images/editColoredIcon.svg";
import disciplineCover from "./assets/images/disciplineCover.png";
import disciplineIconBG from "./assets/images/disciplineIconBG.svg";
import disciplineIconInner from "./assets/images/disciplineIconInner.svg";
import disciplineIconOuter from "./assets/images/disciplineIconOuter.svg";
import disciplineFinance from "./assets/images/disciplineFinance.svg";
import disciplineDrilling from "./assets/images/disciplineDrilling.svg";
import disciplineDefaultIcon from "./assets/images/disciplineDefaultIcon.svg";
import overDueImage from "./assets/images/Overdue.png";
import cloudImage from "./assets/images/cloudImage.png";
import Checkmark from "./assets/images/Checkmark.svg";
import disciplineOGPS from "./assets/images/disciplineOGPS.svg";
import disciplinePSS from "./assets/images/disciplinePSS.svg";
import disciplineGMS from "./assets/images/disciplineGMS.svg";
import moduleCardImage from "./assets/images/moduleCardImage.png";
import checkList from "./assets/images/checkList.png";
import actionWhiteIcon from "./assets/images/actionWhiteIcon.svg";
import oilindiaLogo from "./assets/images/oilindiaLogo.png";
import loginBgCircle from "./assets/images/loginBgCircle.svg";
import loginBgRectangle from "./assets/images/loginBgRectangle.svg";
import ISO9001 from "./assets/images/ISO9001.svg";
import ISO27001 from "./assets/images/ISO27001.svg";
import netzeroLogo from "./assets/images/netzeroLogo.svg";
import iEnergyLogo from "./assets/images/iEnergyLogo.png";
import loginpageSafetyImage from "./assets/images/loginpageSafetyImage.jpg";
import helpImage from "./assets/images/helpImage.svg";
import forgotPasswordImage from "./assets/images/forgotPasswordImage.png";
import ProfileMenuBG from "./assets/images/ProfileMenuBG.svg";
import videoTutorialCardImg from "./assets/images/videoTutorialCardImg.png";
import helpManualsCardImg from "./assets/images/helpManualsCardImg.png";
import faqsCardImg from "./assets/images/faqsCardImg.png";
import rainCloud from "./assets/images/sun-behind-rain-cloud.png";
import safetyTip from "./assets/images/safety-tip-img.png";
import task2 from "./assets/images/Task 2.png";
import netZero from "./assets/images/netzero.png";
import backArrow from "./assets/images/arrow_back_ios.png";
import forwordArrow from "./assets/images/Arrow-forward.png";
import auditIcon from "./assets/images/Medical-services.png";
import toprightArrow from "./assets/images/Arrow-outward.png";
import rating from "./assets/images/scorerating.png";
import sectionIcon from "./assets/images/Category.png";
import broadcast_default_image from "./assets/images/broadcast_default_image.png";

export const typeButtonGroup = [
  { id: 1, label: "UA" }, // Unsafe Act
  { id: 2, label: "UC" }, // Unsafe Condition
  { id: 3, label: "NM" }, // Near Miss
  { id: 3, label: "Minor" }, // Near Miss
  { id: 4, label: "MR" }, // Medical Treatment Injury
  { id: 5, label: "DO" }, // Lost Time Injury
  { id: 6, label: "Serious" }, // Serious Bodily Injury
  { id: 7, label: "Fatal" }, // Fatality
];

export const severityButtonGroup = [
  { id: 1, label: "1" },
  { id: 2, label: "2" },
  { id: 3, label: "3" },
  { id: 4, label: "4" },
  { id: 5, label: "5" },
  { id: 6, label: "NA" },
];

const data = JSON.parse(localStorage.getItem("loginData"));
export const userFullName = `${data?.firstName || ""} ${data?.lastName || ""}`;
export const userEmail = `${data?.email || ""}`;
export const userMobileNumber = `${data?.mobileNumber || ""}`;
export const userGender = `${data?.gender || ""}`;
export const userDOB = `${data?.dateOfBirth || ""}`;

const date = new Date();
export const todayDate = `${date.getDate()}-${
  date.getMonth() + 1
}-${date.getFullYear()}`;

export const sidebarLogo = data?.config?.organizationConfig?.logo;
export const orgName = data?.config?.organizationConfig?.name;
export const currWorkLocation = data?.currentWorkLocation;

export {
  broadcast_default_image,
  faqsCardImg,
  helpManualsCardImg,
  videoTutorialCardImg,
  ProfileMenuBG,
  forgotPasswordImage,
  helpImage,
  loginpageSafetyImage,
  iEnergyLogo,
  loginBgCircle,
  loginBgRectangle,
  ISO9001,
  ISO27001,
  netzeroLogo,
  oilindiaLogo,
  actionWhiteIcon,
  checkList,
  moduleCardImage,
  disciplineGMS,
  disciplinePSS,
  disciplineOGPS,
  overDueImage,
  cloudImage,
  editColoredIcon,
  locationMark,
  incidentManagementIcon,
  person_outline,
  setting_outline,
  car_outline,
  wavesRed,
  eyeIcon,
  cardHeadBG,
  Capa,
  actionIcon,
  redMinesManager,
  yellowReportIncident,
  blueCAPA,
  blueInvestigator,
  greenSafetyManager,
  searchIcon,
  listIcon,
  filterIcon,
  waves,
  SafetyOfficer,
  SafetyFirst,
  ReportIncident,
  MinesManager,
  Investigation,
  temperature,
  sunny,
  rainy,
  disciplineCover,
  disciplineIconBG,
  disciplineIconInner,
  disciplineIconOuter,
  disciplineFinance,
  disciplineDrilling,
  disciplineDefaultIcon,
  Checkmark,
  rainCloud,
  safetyTip,
  task2,
  netZero,
  backArrow,
  forwordArrow,
  auditIcon,
  toprightArrow,
  rating,
  sectionIcon,
};
