import React, { useEffect, useState } from "react";
import { 
  Box, 
  Typography, 
  TextField, 
  MenuItem, 
  Button, 
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { updateTicket, getModuleSections } from "../../Apis/apiCall";
import Compressor from 'compressorjs';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CompletionModal from "../../Components/LossControl/CompletionModal";

export default function EditTicket() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { ticketDetails } = location.state || {};

    const [module, setModule] = useState(ticketDetails?.moduleID);
    const [subSectionOptions, setSubSectionOptions] = useState([]);
    const [urlAttachments, setUrlAttachments] = useState(
      ticketDetails?.attachments?.map(url => ({
        url,
        isUrl: true
      })) || []
    );
    const [base64Attachments, setBase64Attachments] = useState([]);
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      fetchModuleSections(module);
    }, [module]);
  
    const fetchModuleSections = async(module) => {
      try {
        const res = await getModuleSections(module);
        setSubSectionOptions(res.data.data);
      } catch (error) {
        console.error("Error while fetching module sections", error);
      }
    }
  
    const moduleOptions = [
      { value: 1, label: "Incident Management" },
      { value: 2, label: "Loss Control" },
      { value: 3, label: "CAPA Management" },
    ];
  
    const priorityOptions = [
      { value: "high", label: "High" },
      { value: "medium", label: "Medium" },
      { value: "low", label: "Low" }
    ];
  
    const [formData, setFormData] = useState({
      module: ticketDetails?.moduleID || "",
      section: ticketDetails?.section || "",
      priority: ticketDetails?.priority || "",
      issueSummary: ticketDetails?.issueSummary || "",
      issueDescription: ticketDetails?.issueDescription || "",
    });
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      if(name === 'module') {
        setModule(value);
      }
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleEvidenceUpload = (event) => {
      const files = Array.from(event.target.files);
      const filePromises = files.map((file) => {
        return new Promise((resolve, reject) => {
          if (file?.type?.startsWith("image/")) {
            new Compressor(file, {
              quality: 0.2,
              success(compressedFile) {
                const reader = new FileReader();
                reader.onload = (e) => {
                  resolve({
                    base64: e.target.result.split(",")[1],
                    name: compressedFile.name,
                    type: compressedFile.type,
                  });
                };
                reader.onerror = reject;
                reader.readAsDataURL(compressedFile);
              },
              error(err) {
                reject(err);
              },
            });
          } else {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve({
                base64: e.target.result.split(",")[1],
                name: file.name,
                type: file.type,
              });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          }
        });
      });
    
      Promise.all(filePromises)
        .then((newFiles) => {
          setBase64Attachments(prev => [...prev, ...newFiles]);
        })
        .catch((error) => {
          console.error("File upload/compression failed:", error);
        });
    };
  
    const handleRemoveFile = (index, isUrl) => {
      if (isUrl) {
        setUrlAttachments(prev => prev.filter((_, i) => i !== index));
      } else {
        setBase64Attachments(prev => prev.filter((_, i) => i !== index));
      }
    };
  
    const handleConfirmationOpen = () => {
      setOpen(true);
    }
  
    const handleConfirmationClose = () => {
      setOpen(false);
    }
  
    const handleYes = () => {
      handleSubmit();
      handleConfirmationClose();
    }
  
    const handleSubmit = async() => {
      const payload = {
        moduleID: formData.module,
        section: formData.section, 
        priority: formData.priority, 
        issueSummary: formData.issueSummary,
        issueDescription: formData.issueDescription,
        attachments: [
          ...urlAttachments.map(item => item.url),
          ...base64Attachments.map(file => ({
            name: file.name,
            type: file.type,
            base64: file.base64,
          }))
        ]
      };
      
      try {
        await updateTicket(payload, id);
        navigate(`/support/view-ticket/${id}`);
      } catch (error) {
        console.error("Error while updating ticket", error);
      }
    };
  
    const handleBack = () => {
      navigate(`/support/view-ticket/${id}`);
    }
  
    return (
      <>
      <Box>
        <Typography sx={{ fontSize: "20px", color: "#2E2C34", fontWeight: 600 }}>
          Edit Ticket
        </Typography>
  
        <Box
          sx={{
            p: 2,
            width: "100%",
            mx: "auto",
            borderRadius: 2,
            backgroundColor: "#fff",
            mt: 2,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: 'space-between', mb: 2 }}>
              <Grid container spacing={2}>
                {/* Module Selection */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
                    gutterBottom
                  >
                    Modules
                  </Typography>
                  <Select
                    name="module"
                    value={formData.module}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      fontSize: "14px",
                      alignItems: "center",
                      borderRadius: "4px",
                      border: "0.794px solid #E7E7E7",
                      height: "50px",
                      minHeight: "unset",
                      color: "#9A9FA5",
                    }}
                  >
                    {moduleOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* Sub-Section Selection */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
                    gutterBottom
                  >
                    Sub-Section
                  </Typography>
                  <Select
                    name="section"
                    value={formData.section}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    disabled={!formData.module}
                  >
                    {subSectionOptions?.map((option) => (
                      <MenuItem key={option.section} value={option.section}>
                        {option.section}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* Priority Selection */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography
                    sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
                    gutterBottom
                  >
                    Priority
                  </Typography>
                  <Select
                    name="priority"
                    value={formData.priority}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      fontSize: "14px",
                      alignItems: "center",
                      borderRadius: "4px",
                      border: "0.794px solid #E7E7E7",
                      height: "50px",
                      minHeight: "unset",
                      color: "#9A9FA5",
                    }}
                  >
                    {priorityOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>
  
            {/* Issue Summary */}
            <Typography
              sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600, mb: 1 }}
              gutterBottom
            >
              Issue Summary
            </Typography>
            <TextField
              fullWidth
              name="issueSummary"
              value={formData.issueSummary}
              onChange={handleChange}
              placeholder="Type ticket issue here..."
              sx={{mb:1}}
            />
  
            {/* Issue Description */}
            <Typography
              sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600 }}
              gutterBottom
            >
              Issue Description
            </Typography>
            <TextField
              fullWidth
              name="issueDescription"
              value={formData.issueDescription}
              onChange={handleChange}
              multiline
              rows={1}
              placeholder="Issue description"
              sx={{mb:1}}
            />
  
            {/* Attachments Section */}
            <Typography
              sx={{ fontSize: "14px", color: "#2E2C34", fontWeight: 600, mb: 2 }}
              gutterBottom
            >
              Attachments
            </Typography>
            <Box
              sx={{
                width: "50%",
                border: "2px dashed #09A2E3",
                borderRadius: 1,
                p: 3,
                mb: 2,
                textAlign: "center",
                cursor: "pointer",
                "&:hover": {
                  borderColor: "primary.main",
                },
              }}
              onClick={() => document.getElementById("file-input").click()}
            >
              <input
                id="file-input"
                type="file"
                multiple
                accept=".png,.jpg,.doc,.pdf,.mp4"
                onChange={handleEvidenceUpload}
                style={{ display: "none" }}
              />
              <CloudUploadIcon sx={{ fontSize: 40, color: "black", mb: 1 }} />
              <Typography
                variant="body2"
                color="#09A2E3"
                sx={{ textDecoration: "underline" }}
              >
                Click to upload
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                PNG, JPG, Doc, Pdf and Mp4 (max. 10 MB)
              </Typography>
            </Box>
  
            {/* File Preview Section */}
            <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              {/* URL-based attachments */}
              {urlAttachments.map((file, index) => (
                <Box
                  key={`url-${index}`}
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    width: '80px',
                    height: '80px',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <IconButton
                    onClick={() => handleRemoveFile(index, true)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: 'error.main',
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  <img
                    src={file.url}
                    alt="attachment"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ))}

              {/* Base64 attachments */}
              {base64Attachments.map((file, index) => (
                <Box
                  key={`base64-${index}`}
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    width: '80px',
                    height: '80px',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <IconButton
                    onClick={() => handleRemoveFile(index, false)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: 'error.main',
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '16px' }} />
                  </IconButton>
                  {file.type.startsWith('image/') ? (
                    <img
                      src={`data:${file.type};base64,${file.base64}`}
                      alt={file.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Typography
                      variant="caption"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        color: '#757575',
                        fontSize: '12px',
                        padding: 1,
                      }}
                    >
                      {file.name}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
  
            {/* Action Buttons */}
            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#09A2E3", color: "white" }}
                onClick={handleConfirmationOpen}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
      <CompletionModal
        open={open}
        onClose={handleConfirmationClose}
        onYes={handleYes}
        subMessage1="Are you sure?"
        subMessage2="Are you sure you want to edit this ticket?"
      />
      </>
  );
};
