import React, { useState } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import CustomButtonGroup from './CustomButtonGroup';

export default function DesignerButtonGroup ({title, reported, buttonNames}) {
    const [selectedSuggested, setSelectedSuggested] = useState(3); // Default selection

    const handleSelect = (name) => {
        setSelectedSuggested(name);
    };

    return (
        <>
            <Typography variant="caption" sx={{ fontWeight: 500, color: '#525966' }}>
                {title}
            </Typography>
        <Paper
            elevation={0}
            sx={{
                border: '1px solid #B7C8E5',
                paddingX: '16px',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6} display='flex' flexDirection='column' gap={0.5}>
                    <Typography variant="caption" sx={{ color: '#666F7F' }}>
                        Reported
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#F7F7F7',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            width: 'fit-content',
                            fontWeight: 500,
                            color: '#A4B2CC',
                        }}
                    >
                        {reported}
                    </Box>
                </Grid>
                <Grid item xs={6} display='flex' flexDirection='column' gap={0.5}>
                    <Typography variant="caption" sx={{color: '#666F7F' }}>
                        Suggested
                    </Typography>
                    <CustomButtonGroup
                        buttonNames={buttonNames}
                        selected={selectedSuggested}
                        onSelect={handleSelect}
                    />
                </Grid>
            </Grid>
        </Paper>
        </>
    );
};