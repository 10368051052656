import * as React from "react";
import { useLocation, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const drawerWidth = 227;

const BreadBox = styled(Box)(({ theme, open }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: open ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 57px)`,
  padding: "8px 24px",
  transition: "width 0.3s ease",
  marginLeft: open ? `${drawerWidth + 2}px` : "75px",
  marginTop: "58px",
  background: "#FCFCFC",
  color: "#525966",
}));

const getBreadcrumbs = (pathname) => {
  const paths = pathname.split("/").filter(Boolean);

  return paths.map((path, index) => {
    // Replace hyphen with space and capitalize the first letter of each word
    const formattedName = path
      .split("-") // Split by hyphen
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
      .join(" "); // Join with space

    return {
      name: formattedName,
      path: `/${paths.slice(0, index + 1).join("/")}`,
    };
  });
};

export default function BasicBreadcrumbs({ open }) {
  const location = useLocation();
  const breadcrumbs = getBreadcrumbs(location.pathname);

  return (
    <BreadBox open={open}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          to="/"
          style={{ display: "flex", alignItems: "center", color: "#7B809A" }}
        >
          <HomeIcon style={{ width: "20px", height: "20px" }} />
        </Link>
        {breadcrumbs.map((breadcrumb, index) => {
          const isActive = location.pathname === breadcrumb.path; // Check if the link is active
          return (
            <Link
              key={index}
              to={breadcrumb.path}
              style={{
                color: isActive ? "#000" : "#7B809A", // Active link is black, inactive is grey
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              {breadcrumb.name}
            </Link>
          );
        })}
      </Breadcrumbs>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "92px",
          height: "28px",
          padding: "13px 14px",
          backgroundColor: "#09A2E3",
          borderRadius: "8.32px 0px 0px 8.32px",
          gap: "3px",
          position: "fixed",
          right: "0",
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: 600,
            color: "white",
            lineHeight: "30px",
          }}
        >
          net
        </Typography>
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: 600,
            color: "black",
            lineHeight: "30px",
          }}
        >
          zero
        </Typography>
      </div>
    </BreadBox>
  );
}
