import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Grid,
  Button,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system"; // Import styled from @mui/system
import FlagIcon from "@mui/icons-material/Flag"; // Replace with your desired icon
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const QuestionnairSection = ({ tabData }) => {
  const tabsRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState(0); // Local state for the selected tab
  const [showBothButtons, setShowBothButtons] = useState(false);
  const [questionnaireSection, SetQuestionnaireSection] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue); // Update the selected tab index
  };

  const StyledTabs = styled(Tabs)({
    minHeight: "48px",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  });

  const StyledTab = styled(Tab)(({ selected }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    borderRadius: "40px",
    background: selected ? "#FCFCFC;" : "#FCFCFC;", // Background changes based on selection
    color: "#000",
    fontSize: "16px",
    fontWeight: "600",
    padding: "8px 16px",
    textTransform: "none",
    marginLeft: "10px",
    width: "150px",
    "& .MuiTab-iconWrapper": {
      display: "flex",
      alignItems: "center",
    },
  }));

  const gridItems = Array(24).fill("02");
  gridItems[0] = "01"; // Change the first item's text to "01" for demo

  const chartData = {
    // labels: ["Attempted", "Not Attempted"],
    datasets: [
      {
        data: [20, 40], // Example values
        backgroundColor: ["#2ECC71", "#FF6B6B"], // Colors for the segments
        hoverBackgroundColor: ["#27AE60", "#FF4D4D"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = chartData.labels[tooltipItem.dataIndex];
            const value = chartData.datasets[0].data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  const handleQuestionOpen = () => {
    alert("Hello");
    SetQuestionnaireSection(!questionnaireSection);
  };

  const AttemptedBox = ({ color, label, count }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: color,
            borderRadius: "50%",
          }}
        ></Box>
        <Typography>{label}</Typography>
      </Box>
      <Typography sx={{ fontWeight: "bold" }}>{count}</Typography>
    </Box>
  );

  const data = [
    { color: "#13DEB9", label: "Attempted", count: 20 },
    { color: "#E74C3C", label: "Not Attempted", count: 40 },
  ];

  return (
    <Box
      ref={tabsRef}
      sx={{
        width: "95%",
        overflowX: "auto",
        whiteSpace: "nowrap",
        padding: "10px",
        marginBottom: "10px",
      }}
    >
      <StyledTabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="custom tabs"
        scrollButtons={false}
      >
        {tabData.map((tab, index) => (
          <StyledTab
            key={index}
            label={
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                {tab.icon}
                <Typography>{tab.label}</Typography>
              </Box>
            }
            sx={{
              backgroundColor: selectedTab === index ? "#09A2E3" : "#FCFCFC",
              color: selectedTab === index ? "#fff" : "#000",
              borderRadius: "40px",
              transition: "background-color 0.3s, color 0.3s",
            }}
            onClick={() => setSelectedTab(index)} // Handle tab selection
          />
        ))}
      </StyledTabs>

      {/* Conditional rendering based on the selected tab */}
      {!questionnaireSection && (
        <>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              padding: "16px",
              minHeight: "auto",
              marginTop: "20px",
            }}
          >
            <Grid container spacing={2} sx={{ display: "flex" }}>
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    border: "0.5px solid #B7C8E5",
                    background: "#FFF",
                    boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                    padding: "30px",
                    height: "400px",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Questionnaire / H2S Hazard Control
                  </Typography>
                  <Grid container spacing={2}>
                    {gridItems.map((item, index) => (
                      <Grid item xs={2} sm={2} md={1.5} key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "40px",
                            width: "40px",
                            padding: "12px",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                            backgroundColor: "#FFF", // Highlight the first box
                            cursor: "pointer",
                            fontSize: "12px",
                            color: "#525966",
                            fontWeight: "600",
                            marginTop: "10px",
                            "&:hover": {
                              backgroundColor: "#F1F4F8",
                            },
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "600" }}
                          >
                            {item}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 3,
                    }}
                  >
                    {!showBothButtons ? (
                      // Initially display only the "Next" button
                      <Button
                        variant="contained"
                        sx={{
                          width: "50%",
                          backgroundColor: "#09A2E3",
                          color: "#FFF",
                          padding: "8px",
                          width: "100%",
                          borderRadius: "24px",
                          marginTop: "10px",
                          "&:hover": {
                            backgroundColor: "#09A2E3",
                          },
                        }}
                        onClick={() => setShowBothButtons(true)} // Show both buttons when clicked
                      >
                        Next
                      </Button>
                    ) : (
                      // Display "Next" and "Back" buttons
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <Button
                          sx={{
                            width: "50%",
                            border: "1px solid #09A2E3",
                            color: "#09A2E3",
                            padding: "8px",
                            flex: 1,
                            borderRadius: "24px",
                          }}
                          onClick={() => alert("Back button clicked")}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#09A2E3",
                            color: "#FFF",
                            padding: "8px",
                            flex: 1,
                            borderRadius: "24px",
                            "&:hover": {
                              backgroundColor: "#09A2E3",
                            },
                          }}
                          onClick={() => handleQuestionOpen()}
                        >
                          Next
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    border: "0.5px solid #B7C8E5",
                    background: "#FFF",
                    boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                    padding: "30px",
                    height: "381px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      PSA - 007
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: "500" }}>
                      0/1
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        color: "#6E6E6E",
                        lineHeight: 1.5,
                        whiteSpace: "pre-line",
                      }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit.Lorem ipsum dolor sit amet.
                    </Typography>
                  </Box>

                  <TextField
                    placeholder="Response"
                    multiline
                    variant="outlined"
                    sx={{
                      width: "100%",
                      marginTop: "16px",
                      borderRadius: "11.566px",
                      backgroundColor: "#FFF",
                      boxShadow:
                        "0px 1.446px 2.892px 0px rgba(16, 24, 40, 0.05)",
                      border: "1.446px solid  #D0D5DD",
                      color: "#667085",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "50px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "30%",
                        backgroundColor: "#09A2E3",
                        color: "#FFF",
                        padding: "8px 24px",
                        borderRadius: "24px",
                        "&:hover": {
                          backgroundColor: "#007bb2",
                        },
                      }}
                    >
                      Start Audit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* {selectedTab === 0 && <Typography variant="h6">Hello</Typography>}
        {selectedTab === 1 && (
          <Typography variant="h6">Welcome to Section 2</Typography>
        )}
        {selectedTab === 2 && (
          <Typography variant="h6">This is Section 3</Typography>
        )} */}
          </Box>
        </>
      )}

      {questionnaireSection && (
        <>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              padding: "16px",
              minHeight: "auto",
              marginTop: "20px",
            }}
          >
            <Box>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", alignItems: "stretch" }}
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "12px",
                      border: "0.5px solid #B7C8E5",
                      background: "#FFF",
                      boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                      padding: "30px",
                      flex: 1, // Ensure this box stretches to fill the height
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#222",
                      }}
                    >
                      Questionnaire{" "}
                      <span
                        sx={{
                          fontWeight: "500",
                          fontSize: "16px",
                          color: "#9D9D9D",
                        }}
                      >
                        / H2S Hazard Control
                      </span>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "16px",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: "600" }}>
                        PSA - 007
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: "500" }}>
                        0/1
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          color: "#6E6E6E",
                          lineHeight: 1.5,
                          whiteSpace: "pre-line",
                          marginBottom: "10px",
                        }}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit.Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit.Lorem ipsum dolor sit amet.
                      </Typography>
                    </Box>

                    <Box
                    //   sx={{
                    //     display: "flex",
                    //     alignItems: "center",
                    //     width: "100%",
                    //     border: "1.446px solid #D0D5DD",
                    //     borderRadius: "11.566px",
                    //     backgroundColor: "#FFF",
                    //     boxShadow: "0px 1.446px 2.892px rgba(16, 24, 40, 0.05)",
                    //     padding: "8px",
                    //   }}
                    >
                      {/* Text Field */}
                      <TextField
                        placeholder="Response"
                        multiline
                        variant="standard"
                        InputProps={{
                          disableUnderline: true, // Removes the default underline
                        }}
                        sx={{
                          flex: 1,
                          padding: "1px",
                          color: "#667085",
                          borderRadius: "50px",
                          boxShadow:
                            "0px 1.446px 2.892px 0px rgba(16, 24, 40, 0.05)",
                          border: "1.446px solid #D0D5DD",
                          background: "#FFF",
                          width: "100%",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex", // Enables flexbox layout
                        justifyContent: "flex-end", // Aligns items to the right
                        alignItems: "center", // Ensures vertical alignment
                      }}
                    >
                      {/* Icons */}
                      <IconButton>
                        <FlagIcon sx={{ color: "#000" }} />
                      </IconButton>
                      <IconButton>
                        <ChatBubbleIcon sx={{ color: "#000" }} />
                      </IconButton>
                      <IconButton>
                        <AttachFileIcon sx={{ color: "#000" }} />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        // display: "flex",
                        // justifyContent: "flex-end",
                        marginTop: "50px",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          backgroundColor: "#09A2E3",
                          color: "#FFF",
                          padding: "8px 24px",
                          borderRadius: "24px",
                          "&:hover": {
                            backgroundColor: "#007bb2",
                          },
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "12px",
                      border: "0.5px solid #B7C8E5",
                      background: "#FFF",
                      boxShadow: "0px 2.318px 4.636px 0px rgba(0, 0, 0, 0.16)",
                      padding: "30px",
                      flex: 1, // Ensure this box stretches to fill the height
                    }}
                  >
                    <Grid container spacing={2}>
                      {gridItems.map((item, index) => (
                        <Grid item xs={2} sm={2} md={1.5} key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40px",
                              width: "40px",
                              padding: "12px",
                              borderRadius: "8px",
                              boxShadow:
                                "0px 2.318px 4.636px rgba(0, 0, 0, 0.16)",
                              backgroundColor:
                                selectedIndex === index ? "#E5F6FF" : "#FFF",
                              cursor: "pointer",
                              fontSize: "12px",
                              color: "#525966",
                              fontWeight: "600",
                              marginTop: "10px",
                              "&:hover": {
                                backgroundColor: "#F1F4F8",
                              },
                            }}
                            onClick={() => setSelectedIndex(index)}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "600" }}
                            >
                              {selectedIndex === index ? "01" : item}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                    {/* Pie Chart Section */}
                    <Box
                      sx={{
                        marginTop: "30px",
                        display: "flex",
                        alignItems: "center",
                        width: "411px",
                        borderRadius: "8px",
                        border: "0.394px solid #B7C8E5",
                        background: "#FFF",
                        padding: "10px",
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "500",
                            marginBottom: "10px",
                            fontSize: "12px",
                            color: "#222",
                          }}
                        >
                          H2S Hazard Control
                        </Typography>
                        <Box>
                          {data?.map((item, index) => (
                            <AttemptedBox
                              key={index}
                              color={item.color}
                              label={item.label}
                              count={item.count}
                            />
                          ))}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                              justifyContent: "space-between",
                            background:"#E6F6FC",
                            padding:"10px",
                            borderRadius:'12px',
                            marginTop:'10px'
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Typography>Total Project</Typography>
                            </Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                               60
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ margin: "0 20px" }}
                      />

                      <Box sx={{ width: "150px", height: "150px" }}>
                        <Doughnut data={chartData} options={chartOptions} />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 3,
                      }}
                    ></Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuestionnairSection;
