import React, { useState, useEffect } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Box,
  Fab,  
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import CustomInput from "./CustomInputField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import FRCAPACard from "./FRCAPACard";

const data = [
    { id: "2024-IM-000498", type: "Preventive", owner: "EMP22", action: false },
    { id: "2024-IM-000498", type: "Preventive", owner: "EMP22", action: false },
    { id: "2024-IM-000498", type: "Preventive", owner: "EMP22", action: false },
    { id: "2024-IM-000498", type: "Preventive", owner: "EMP22", action: false },
  ];

export default function FRCAPADrawer({ open, onClose, title }) {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "36%", padding: "0px !important" } }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingY: "16px",
          paddingX: "24px",
          borderBottom: "1px solid rgba(171, 171, 171, 0.50)",
          boxShadow: "0px 2px 4px 0px #ABABAB",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
          {title}
        </Typography>
      </Grid>
      {/* <Box sx={{padding:'16px'}}>
      <TableContainer style={{ borderRadius: "8px", border: '1px solid #ccc' }}>
      <Table>
        <TableHead>
          <TableRow sx={{color:'#525966'}}>
            <TableCell sx={{paddingY:'8px'}}><strong>CAPA ID</strong></TableCell>
            <TableCell sx={{paddingY:'8px'}}><strong>Type</strong></TableCell>
            <TableCell sx={{paddingY:'8px'}}><strong>Owner</strong></TableCell>
            <TableCell sx={{paddingY:'8px'}} align="center"><strong>Action</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} sx={{cursor:'pointer', "&:hover": {backgroundColor: "rgba(82, 89, 102, 0.05)"} }}>
              <TableCell sx={{paddingY:'0px'}}>{row.id}</TableCell>
              <TableCell sx={{paddingY:'0px'}}>{row.type}</TableCell>
              <TableCell sx={{paddingY:'0px'}}>{row.owner}</TableCell>
              <TableCell sx={{paddingY:'0px'}} align="center">
                <Switch checked={row.action} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box> */}
    <Box sx={{padding:'16px'}}>
        <FRCAPACard />
    </Box>

      <Fab
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "100px",
          right: open ? "37.3%" : "0",
          transform: "translateX(50%)",
          zIndex: 1200,
          height: "36px",
          width: "36px",
          backgroundColor: "#0AA2E3",
          borderRadius: "60px 0 0 60px",
        }}
      >
        <CloseIcon />
      </Fab>
    </Drawer>
  );
}
