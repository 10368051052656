import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { Typography } from '@mui/material';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

// Helper function to determine the file type
const getFileType = (src) => {
  if (/^data:image\/(jpeg|jpg|png|gif);base64,/.test(src)) return 'base64-image';
  if (/^data:video\/(mp4|webm|ogg);base64,/.test(src)) return 'base64-video';
  if (/\.(jpg|jpeg|png|gif)$/i.test(src)) return 'image';
  if (/\.(mp4|webm|ogg)$/i.test(src)) return 'video';
  if (/\.(pdf)$/i.test(src)) return 'pdf';
  if (/\.(doc|docx|xls|xlsx)$/i.test(src)) return 'document';
  return 'unknown';
};

const DraggableMediaDialog = ({ imageSrc, open, onClose, text }) => {
  const fileType = useMemo(() => getFileType(imageSrc), [imageSrc]);
  // console.log("imageSrc", imageSrc);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="md"
      PaperProps={{
        sx: { p: '0 !important' },
      }}
    >
      <DialogContent sx={{ p: 0 }}>
        {text && <Typography variant='body2' p={2}>{text}</Typography>}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '80vh' }}>
          {fileType === 'image' || fileType === 'base64-image' ? (
            <img src={imageSrc} alt="Preview" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
          ) : fileType === 'video' || fileType === 'base64-video' ? (
            <video src={imageSrc} controls style={{ maxWidth: '100%', maxHeight: '80vh' }} />
          ) : fileType === 'pdf' ? (
            <iframe src={imageSrc} style={{ width: '100%', height: '80vh' }} title="PDF Preview" />
          ) : fileType === 'document' ? (
            <iframe 
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${imageSrc}`} 
              style={{ width: '100%', height: '80vh' }} 
              title="Document Preview" 
            />
          ) : (
            <Box sx={{ textAlign: 'center' }}>Unsupported file type</Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DraggableMediaDialog;
