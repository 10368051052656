import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  FormControl,
  InputLabel,
} from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { incidentManagementIcon, redMinesManager, filterIcon } from "../constant";
import {
  getModuleWithReportCount,
  getAllReportsPDFByModule,
  getAllLocation,
} from "../Apis/apiCall";
import PdfViewer from "../Components/PdfReview";
import { formatDateToIndianLocale } from "../utils/helper";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Loader from "../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { START_LOADING, STOP_LOADING } from "../redux/Loader/constant";

const iconStyle = {
  color: "#009FF5",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  padding: "4px",
};

const disciplines = [
  "Asset",
  "Drilling",
  "OGPS",
  "PSS",
  "GMS & LPG",
  "Engineering",
  "Finance & Other",
  "HR",
  "Security",
  "Medical",
];
const incidentType = ["UA", "UC", "SBI", "NM", "MTI", "Fatality"];
const locations = ["Jorhat Tank Farm", "Location 1", "Location 2"];

const incidentStatus = [
  "Drafted",
  "New Incident",
  "New Incident Clarification",
  "New Incident Resubmission",
  "Incident Review",
  "Incident Revalidation",
  "Resubmitted for Review",
  "Investigation Initiated",
  "Investigation Validation",
  "Investigation Reinitiated",
  "Investigation Completed",
  "Investigation Query",
  "Investigation Resubmission",
  "Investigation Under Approval",
  "Investigation Clarification",
  "Investigation Approval Resubmission",
  "CAPA Assigned",
  "CAPA In Progress",
  "CAPA Revalidation",
  "CAPA Resubmitted",
  "CAPA Final Review",
  "CAPA Clarification",
  "CAPA Review Resubmission",
  "Incident Closed",
];

const lossControlStatus = [
  "Drafted",
  "New_Survey",
  "Revalidation",
  "In_Progress",
  "Under_Review",
  "Completed",
  "Returned_By_MM",
  "Resubmission",
  "Recommendation_Resubmission",
];

const ReportPage = () => {
  const [modules, setModules] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedModule, setSelectedModule] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [disciplineFilter, setDisciplineFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [lossContolReportsData, setLossContolReportsData] = useState([]);
  const [incidentReportsData, setIncidentReportsData] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [selectedSingleReport, setSelectedSingleReport] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [incidentFilterDialog, setIncidentFilterDialog] = useState(false);
  const [filters, setFilters] = useState({
    dgms: "",
    factoriesAct: "",
    oisd: "",
    pngrb: "",
    oisdminor: "",
    oisdmajor: "",
    pngrbminor: "",
    pngrbmajor: "",
  });
  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingReducer.loading);

  useEffect(() => {
    getReportCount();
    fetchAllLocationDropValue();
  }, []);

  useEffect(() => {
    fetchReportTableData(selectedModule);
    setDisciplineFilter("");
    setStatusFilter("");
  }, [selectedModule]);

  const getReportCount = async () => {
    dispatch({ type: START_LOADING });
    try {
      const result = await getModuleWithReportCount();
      setModules([...result.data.data]);
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

  const fetchReportTableData = async (moduleID) => {
    dispatch({ type: START_LOADING });
    try {
      const result = await getAllReportsPDFByModule(moduleID);
      if (selectedModule == 1) {
        setIncidentReportsData([...result.data.data]);
      } else {
        setLossContolReportsData([...result.data.data]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

  const fetchAllLocationDropValue = async () => {
    dispatch({ type: START_LOADING });
    try {
      const result = await getAllLocation();
      const arr = result.data.data;
      const locations = arr?.map((ele) => ele.displayName);
      setLocationList(locations);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

  const handleSelectModule = (module) => {
    setSelectedModule(module.moduleID);
    setDisciplineFilter("");
    setStatusFilter("");
  };
  const handlePreview = () => {
    setPreviewMode(true);
    setPdfUrl(selectedSections[0]?.pdfUrl);
  };
  const handlePreviewBack = () => {
    setPreviewMode(false);
    setSelectedSections([]);
    setPdfUrl(null);
  };

  const handleSelectSingleReport = (module) => {
    setSelectedSingleReport(module.reportID);
    setPdfUrl(module.pdfUrl);
  };

  const handleRowSelection = (row) => {
    setSelectedSections(
      (prevSelected) =>
        prevSelected.some((item) => item.reportID === row.reportID)
          ? prevSelected.filter((item) => item.reportID !== row.reportID)
          : [...prevSelected, row] // Add the entire row object to the selectedSections array
    );
  };

  // Function to handle global filter input
  const handleGlobalFilterChange = (event) => {
    setGlobalFilter(event.target.value);
  };

  // Function to handle location filter change
  const handleLocationChange = (event) => {
    setLocationFilter(event.target.value);
  };

  // Function to handle discipline filter change
  const handleDisciplineChange = (event) => {
    setDisciplineFilter(event.target.value);
  };

  // Function to handle status filter change
  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Filter data based on all filters (global, location, and discipline)
  const lossContolFilteredData = lossContolReportsData?.filter((row) => {
    if (!row || typeof row !== "object") return false; // Skip invalid rows

    const matchesGlobalFilter =
      globalFilter === "" ||
      Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value?.toLowerCase().includes(globalFilter.toLowerCase())
      );

    const matchesLocationFilter =
      locationFilter === "" || row?.location === locationFilter;
    const matchesDisciplineFilter =
      disciplineFilter === "" || row?.discipline === disciplineFilter;
    const matchesStatusFilter =
      statusFilter === "" || row?.currentStatus?.includes(statusFilter);

    return (
      matchesGlobalFilter &&
      matchesLocationFilter &&
      matchesDisciplineFilter &&
      matchesStatusFilter
    );
  });

  const incidentFilteredData = incidentReportsData?.filter((row) => {
    if (!row || typeof row !== "object") return false; // Skip invalid rows

    const matchesGlobalFilter =
      globalFilter === "" ||
      Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value?.toLowerCase().includes(globalFilter.toLowerCase())
      );

    const matchesLocationFilter =
      locationFilter === "" || row?.location === locationFilter;
    const matchesDisciplineFilter =
      disciplineFilter === "" || row?.type === disciplineFilter;
    const matchesStatusFilter =
      statusFilter === "" || row?.currentStatus?.includes(statusFilter);

    return (
      matchesGlobalFilter &&
      matchesLocationFilter &&
      matchesDisciplineFilter &&
      matchesStatusFilter
    );
  });

  const handleDownload = (url) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch PDF");
        }
        return response.blob(); // Convert the response to a Blob
      })
      .then((blob) => {
        // Create an invisible link element
        const link = document.createElement("a");

        // Create an object URL for the Blob
        const urlObject = URL.createObjectURL(blob);

        // Set the link's href to the Blob URL
        link.href = urlObject;

        // Set the download attribute with the file name (taken from the URL)
        link.download = url.split("/").pop(); // Filename from the last part of the URL

        // Append the link to the body, trigger a click event, and then remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL after the download is triggered
        URL.revokeObjectURL(urlObject);
      })
      .catch((error) => console.error("Error downloading the PDF:", error));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ color: "#424242", fontWeight: "bold" }}
          >
            Reports
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          {previewMode ? (
            <Box
              sx={{
                background: "#FAFAFA",
                borderRadius: "12px",
                minHeight: "550px",
                p: 2,
              }}
            >
              <KeyboardArrowLeftIcon
                sx={{ color: "#09A2E3" }}
                onClick={handlePreviewBack}
              />
              <Typography variant="body2" sx={{ color: "#424242" }}>
                {selectedModule == 1 ? "Incident Management" : "Loss Control"}
              </Typography>
              <List>
                {selectedSections?.map((module, index) => (
                  <ListItem
                    button
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor:
                        selectedSingleReport === module?.reportID
                          ? "#e2f3fa"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedSingleReport === module?.reportID
                            ? "#e2f3fa"
                            : "#f0f0f0",
                      },
                      borderRadius: 1,
                      padding: 1,
                    }}
                  >
                    {/* File icon and Report ID */}
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      onClick={() => handleSelectSingleReport(module)}
                    >
                      <InsertDriveFileIcon />
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#636363",
                          maxWidth: "150px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        ID: {module?.reportID}
                      </Typography>
                    </Box>

                    {/* Save icon */}
                    <IconButton onClick={() => handleDownload(module?.pdfUrl)}>
                      <SaveAltOutlinedIcon sx={{ color: "#09A2E3" }} />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Box
              sx={{
                background: "#FAFAFA",
                borderRadius: "12px",
                minHeight: "550px",
                p: 2,
              }}
            >
              <Typography variant="body2" sx={{ color: "#424242" }}>
                Modules
              </Typography>
              <List>
                {modules.map((module, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleSelectModule(module)}
                    sx={{
                      backgroundColor:
                        selectedModule === module.moduleID
                          ? "#e2f3fa"
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          selectedModule === module.moduleID
                            ? "#e2f3fa"
                            : "#f0f0f0",
                      },
                      borderRadius: 1,
                    }}
                  >
                    {module.moduleID == 1 ? (
                      <img
                        src={incidentManagementIcon}
                        alt="incident"
                        style={iconStyle}
                      />
                    ) : (
                      <img src={redMinesManager} alt="loss" style={iconStyle} />
                    )}
                    <ListItemText
                      primary={
                        module.moduleID == 1
                          ? "Incident Management"
                          : "Loss Contol"
                      }
                      secondary={`${module.totalReports} Reports`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={9}>
          {previewMode ? (
            <PdfViewer pdfUrl={pdfUrl} />
          ) : (
            <Box sx={{ background: "#FAFAFA", borderRadius: "12px", p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    backgroundColor: "#F4F4F4",
                    borderRadius: "10px",
                    pl: "4px",
                  }}
                >
                  <SearchIcon style={{ color: "#9A9FA5" }} />
                  <TextField
                    value={globalFilter}
                    onChange={handleGlobalFilterChange}
                    placeholder="Search Reports"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    sx={{
                      height: "42px",
                      "& .MuiInputBase-input": {
                        color: "#9A9FA5",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "#9A9FA5",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: 0,
                      },
                    }}
                  />
                </Box>
                <Box display="flex" alignItems="center" gap={1}>

                {selectedModule == 1 ?(
                  <img
                    src={filterIcon}
                    alt="filter"
                    style={{ cursor: "pointer", height: 40 }}
                    onClick={() => setIncidentFilterDialog(true)}
                  />) : null}

                  <Select
                    value={locationFilter}
                    onChange={handleLocationChange}
                    variant="outlined"
                    size="small"
                    displayEmpty
                    sx={{
                      width: 120,
                      height: 40,
                      fontSize: "14px",
                    }} // Adjust width and height here
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px", // Menu font size
                          maxHeight: 250, // Fixed max height for the dropdown
                          minWidth: 100, // Fixed width for the dropdown
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none", // Hide scrollbar
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px", // Font size for menu items
                            height: "36px", // Fixed height for each menu item
                          },
                        },
                      },
                    }}
                  >
                    {/* Placeholder MenuItem */}
                    <MenuItem value="" disabled>
                      All Locations
                    </MenuItem>

                    {/* Other MenuItems */}
                    {locationList?.map((location, index) => (
                      <MenuItem key={index} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </Select>

                  <Select
                    value={disciplineFilter ? disciplineFilter : ""}
                    onChange={handleDisciplineChange}
                    variant="outlined"
                    size="small"
                    displayEmpty
                    sx={{
                      width: 120,
                      height: 40,
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px",
                          maxHeight: 250,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px", // Font size for menu items
                            height: "36px", // Fixed height for each menu item
                          },
                        },
                      },
                    }}
                  >
                    {" "}
                    <MenuItem value="" disabled>
                      {selectedModule == 1 ? " All Types" : " All Disciplines"}
                    </MenuItem>
                    incidentType
                    {selectedModule == 1
                      ? incidentType?.map((type, index) => (
                          <MenuItem key={index} value={type ? type : ""}>
                            {type ? type : ""}
                          </MenuItem>
                        ))
                      : disciplines?.map((discipline, index) => (
                          <MenuItem
                            key={index}
                            value={discipline ? discipline : ""}
                          >
                            {discipline ? discipline : ""}
                          </MenuItem>
                        ))}
                  </Select>

                  <Select
                    value={statusFilter ? statusFilter : ""}
                    onChange={handleStatusChange}
                    variant="outlined"
                    size="small"
                    displayEmpty
                    sx={{
                      width: 110,
                      height: 40,
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px",
                          maxHeight: 250,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px", // Font size for menu items
                            height: "36px", // Fixed height for each menu item
                          },
                        },
                      },
                    }}
                  >
                    {" "}
                    <MenuItem value="" disabled>
                      All Status
                    </MenuItem>
                    incidentType
                    {selectedModule == 1
                      ? incidentStatus?.map((inStatus, index) => (
                          <MenuItem
                            key={index}
                            value={inStatus ? inStatus : ""}
                          >
                            {inStatus ? inStatus : ""}
                          </MenuItem>
                        ))
                      : lossControlStatus?.map((lcStatus, index) => (
                          <MenuItem
                            key={index}
                            value={lcStatus ? lcStatus : ""}
                          >
                            {lcStatus ? lcStatus : ""}
                          </MenuItem>
                        ))}
                  </Select>

                  {selectedSections.length > 0}
                  <Button
                    disabled={selectedSections.length <= 0}
                    variant="contained"
                    sx={{
                      bgcolor: "#09A2E3",
                      color: "#fff",
                      fontWeight: 400,
                      borderRadius: "4px",
                      height: 40,
                    }}
                    onClick={handlePreview}
                  >
                    Preview
                  </Button>
                </Box>
              </Box>

              <Divider />
              
              {selectedModule == 2 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Tour ID</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Location</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          Executive
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          Discipline
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lossContolFilteredData.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            height: "44px",
                            "& > *": { height: "44px", padding: "8px" },
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedSections.some(
                                (item) => item.reportID === row.reportID
                              )}
                              onChange={() => handleRowSelection(row)}
                            />
                          </TableCell>
                          <TableCell>{row?.reportID}</TableCell>
                          <TableCell>{row?.location}</TableCell>
                          <TableCell>{row?.reportedBy}</TableCell>
                          <TableCell>
                            {formatDateToIndianLocale(row?.date)}
                          </TableCell>
                          <TableCell>{row?.discipline}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleDownload(row?.pdfUrl)}
                            >
                              <SaveAltOutlinedIcon sx={{ color: "#09A2E3" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          Incident ID
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Location</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>
                          reportingOfficer
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Severity</TableCell>
                        <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {incidentFilteredData?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            height: "44px",
                            "& > *": { height: "44px", padding: "8px" },
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedSections.some(
                                (item) => item.reportID === row.reportID
                              )}
                              onChange={() => handleRowSelection(row)}
                            />
                          </TableCell>
                          <TableCell>{row?.reportID}</TableCell>
                          <TableCell>{row?.location}</TableCell>
                          <TableCell>{row?.reportedBy}</TableCell>
                          <TableCell>
                            {formatDateToIndianLocale(row?.date)}
                          </TableCell>
                          <TableCell>{row?.type}</TableCell>
                          <TableCell>{row?.severity}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleDownload(row?.pdfUrl)}
                            >
                              <SaveAltOutlinedIcon sx={{ color: "#09A2E3" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
              {loading && (
                <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    zIndex: 1000,
                }}
                >
                <Loader />
                </Box>
            )}
            <Dialog
        open={incidentFilterDialog}
        onClose={() => setIncidentFilterDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{fontWeight:'700', fontSize:'20px'}}>Filters</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={3} mt={1}>
            {/* DGMS Filter */}
            <Box>
            <Typography gutterBottom variant="body2">DGMS</Typography>
            <FormControl size="small" fullWidth>
              <Select
                value={filters.dgms}
                onChange={(e) => handleFilterChange("dgms", e.target.value)}
                variant="outlined"
                displayEmpty
                sx={{
                  width: "100%",
                  height: 40,
                  fontSize: "14px",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      fontSize: "12px",
                      maxHeight: 250,
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      "-ms-overflow-style": "none",
                      "scrollbar-width": "none",
                      "& .MuiMenuItem-root": {
                        fontSize: "12px",
                        height: "36px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select DGMS
                </MenuItem>
                {[
                  "Minor (Medicine & Discharge)",
                  "Reportable Injury",
                  "Serious Bodily Injury",
                  "Fatal",
                  "Dangerous Occurrence",
                  "Not Applicable",
                ].map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>

            {/* Factories Act Filter */}
            <Box>
            <Typography gutterBottom variant="body2">Factories Act</Typography>
            <FormControl size="small" fullWidth>
              <Select
                value={filters.factoriesAct}
                onChange={(e) => handleFilterChange("factoriesAct", e.target.value)}
                variant="outlined"
                displayEmpty
                sx={{
                  width: "100%",
                  height: 40,
                  fontSize: "14px",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      fontSize: "12px",
                      maxHeight: 250,
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      "-ms-overflow-style": "none",
                      "scrollbar-width": "none",
                      "& .MuiMenuItem-root": {
                        fontSize: "12px",
                        height: "36px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Factories Act
                </MenuItem>
                {[
                  "Minor (Medicine & Discharge)",
                  "Reportable",
                  "Serious",
                  "Fatal",
                  "Dangerous Occurrence",
                  "Not Applicable",
                ].map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>

            {/* OISD Filter */}
            <Box display='flex' alignItems='center' gap={2}>
            <Box sx={{width:'100%'}}>
            <Typography gutterBottom variant="body2">OISD</Typography>
            <FormControl size="small" fullWidth>
              <Select
                value={filters.oisd}
                onChange={(e) => handleFilterChange("oisd", e.target.value)}
                variant="outlined"
                displayEmpty
                sx={{
                  width: "100%",
                  height: 40,
                  fontSize: "14px",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      fontSize: "12px",
                      maxHeight: 250,
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      "-ms-overflow-style": "none",
                      "scrollbar-width": "none",
                      "& .MuiMenuItem-root": {
                        fontSize: "12px",
                        height: "36px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select OISD
                </MenuItem>
                {[
                  "Minor (Choose option for Fire or Non-Fire)",
                  "Major (Choose option for Fire or Non-Fire)",
                  "Not Applicable",
                ].map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
            {filters.oisd === "Minor (Choose option for Fire or Non-Fire)" ? (
                <Box>
                <Typography gutterBottom variant="body2">Minor</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={filters.oisdminor}
                    onChange={(e) => handleFilterChange("oisdminor", e.target.value)}
                    variant="outlined"
                    displayEmpty
                    sx={{
                      width: "100%",
                      height: 40,
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px",
                          maxHeight: 250,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px",
                            height: "36px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {[
                      "Fire",
                      "Non-Fire"
                    ].map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Box>
              ) : filters.oisd === "Major (Choose option for Fire or Non-Fire)" ? (
                <Box>
                <Typography gutterBottom variant="body2">Major</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={filters.oisdmajor}
                    onChange={(e) => handleFilterChange("oisdmajor", e.target.value)}
                    variant="outlined"
                    displayEmpty
                    sx={{
                      width: "100%",
                      height: 40,
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px",
                          maxHeight: 250,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px",
                            height: "36px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {[
                      "Fire",
                      "Non-Fire"
                    ].map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Box>
              ) : null}
            </Box>

            {/* PNGRB Filter */}
            <Box display='flex' alignItems='center' gap={2}>
              <Box sx={{width:'100%'}}>
                <Typography gutterBottom variant="body2">PNGRB</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={filters.pngrb}
                    onChange={(e) => handleFilterChange("pngrb", e.target.value)}
                    variant="outlined"
                    displayEmpty
                    sx={{
                      width: "100%",
                      height: 40,
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px",
                          maxHeight: 250,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px",
                            height: "36px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select PNGRB
                    </MenuItem>
                    {[
                      "Minor (Choose option for Fire or Non-Fire)",
                      "Major (Choose option for Fire or Non-Fire)",
                      "Not Applicable",
                    ].map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {filters.pngrb === "Minor (Choose option for Fire or Non-Fire)" ? (
                <Box>
                <Typography gutterBottom variant="body2">Minor</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={filters.pngrbminor}
                    onChange={(e) => handleFilterChange("pngrbminor", e.target.value)}
                    variant="outlined"
                    displayEmpty
                    sx={{
                      width: "100%",
                      height: 40,
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px",
                          maxHeight: 250,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px",
                            height: "36px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {[
                      "Fire",
                      "Non-Fire"
                    ].map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Box>
              ) : filters.pngrb === "Major (Choose option for Fire or Non-Fire)" ? (
                <Box>
                <Typography gutterBottom variant="body2">Major</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    value={filters.pngrbmajor}
                    onChange={(e) => handleFilterChange("pngrbmajor", e.target.value)}
                    variant="outlined"
                    displayEmpty
                    sx={{
                      width: "100%",
                      height: 40,
                      fontSize: "14px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          fontSize: "12px",
                          maxHeight: 250,
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          "-ms-overflow-style": "none",
                          "scrollbar-width": "none",
                          "& .MuiMenuItem-root": {
                            fontSize: "12px",
                            height: "36px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {[
                      "Fire",
                      "Non-Fire"
                    ].map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Box>
              ) : null}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIncidentFilterDialog(false)}>Cancel</Button>
          <Button variant="contained" sx={{color:'white'}} onClick={() => console.log(filters)}>
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default ReportPage;
