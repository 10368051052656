// actions.js
import { UPDATE_ACTIVE_STEP, COMPLETE_FORM } from './constant';

export const updateActiveStep = (step) => ({
  type: UPDATE_ACTIVE_STEP,
  payload: step,
});


export const completeForm = (data) => ({
  type: COMPLETE_FORM,
  payload:data
});
