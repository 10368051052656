import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomRightOverlay({ open, setOpen }) {
  const toggleDrawer = (open) => () => {
    setOpen(open); // Control drawer state using the passed prop
  };

  const list = () => (
    <Box
      sx={{
        width: 300,
        borderRadius: '15px', // Rounded corners
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Soft shadow for the floating effect
        margin: '20px', // Creates space from the edges
        padding: '10px', // Inner padding for content
        backgroundColor: '#fff', // White background for the drawer
      }}
      role="presentation"
    >
      {/* Close Button at the top-right */}
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor="right"
        open={open} // Use the open state passed from parent
        variant="persistent" // Allow interaction with the background
        PaperProps={{
          sx: {
            borderRadius: '15px', // Border radius for the drawer itself
            margin: '20px', // Adds space from the top, bottom, and sides
            width: '340px', // Width including margins
            height: '95%',
          },
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}
