import React,{useEffect} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import {  updateIncidentDateTime, } from '../redux/incidentReport/actions'; // Import your action here



export default function BasicDateTimePicker({ label, onChange }) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = React.useState(dayjs());

  useEffect(()=>{
    const formattedDateTime = dayjs().format('DD-MM-YYYY HH:mm:ss');
    dispatch(updateIncidentDateTime(label, formattedDateTime));
  },[]);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);  // Set the raw dayjs object for DateTimePicker
    onChange(newValue, label);  // Pass the formatted date to onChange callback
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb" >
      <MobileDateTimePicker
        label={label}
        value={selectedDate}  // Keep the raw dayjs object here
        onChange={handleDateChange}
        slotProps={{ textField: { size: 'small',width:"100%" } }}
        MuiInputBase-root-MuiOutlinedInput-root
        sx={{ "&.MuiFormControl-root.MuiTextField-root": {
                width: "100%", // Ensure full width for the form control
              },
              "&.MuiInputBase-root.MuiOutlinedInput-root":{
                fontSize:"12px !important",
              },
              "&.MuiInputBase-input":{
                fontSize:"12px !important",
              }
            }}
        renderInput={(params) => (
          <TextField
            {...params}
          
            margin="normal"
            variant="outlined"
            fullWidth
            sx={{
              fontSize:"12px",
              "& .MuiOutlinedInput-input": {
                padding: '8px 12px', // Adjust padding for small size
              },
              "& .MuiFormLabel-root": {
                transform: 'translate(14px, -9px) scale(0.75)', // Adjust label positioning
              },
              
            
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
