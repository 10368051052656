// Import all necessary components and icons from MUI
import {
  Box,
  Grid,
  Typography,
  Button,
  Switch,
  Tooltip
} from '@mui/material';

import {
  AccountCircle as AccountCircleIcon,
  Edit as EditIcon,
  DeleteOutline,
  ContactPageOutlined as ContactPageOutlinedIcon,
  DateRangeOutlined as DateRangeOutlinedIcon,
  EmojiPeople as EmojiPeopleIcon,
  AccessTime as AccessTimeIcon
} from '@mui/icons-material';


import { cardHeadBG } from "../constant";

const CustomBox = ({ children, size = "30px", bgColor = "#F6F6F6" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: bgColor,
      }}
    >
      {children}
    </Box>
  );
};




const PersonnnelCard = ({ person }) => {
  return (
    <Box

      sx={{
        borderRadius: "8px",
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20)",
        minWidth: "370px",
        maxWidth: "100%",
        height: "270px"
      }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: "#09A2E3",
          minHeight: "56px",
          borderRadius: "8px 8px 0 0",
          padding: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            {/* Profile image */}
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "none",
                marginRight: "8px",
                overflow: "hidden",
              }}
            >
              {person.image ? (
                <Box
                  component="img"
                  src={person.image}
                  alt="Profile"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <AccountCircleIcon
                  sx={{
                    width: "100%",
                    height: "100%",
                    color: "#ffffff",
                  }}
                />
              )}
            </Box>
            {/* Title and ID */}
            <Box>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#ffffff",
                  }}
                >
                  {person.Name}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#ffffff",
                }}
              >
                {person["Staff Type"] + "/" + person.Designation}
              </Typography>
            </Box>
          </Box>
        </Box>

        <img
          src={cardHeadBG}
          alt="bg"
          style={{
            position: "absolute",
            bottom: "-410px",
            left: "-360px",
            width: "727px",
            height: "560px",
            zIndex: 0,
          }}
        />

      </Box>
      <Box sx={{ padding: "16px" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center" gap={1}>
              <CustomBox>
                <ContactPageOutlinedIcon
                  sx={{ width: "20px", height: "20px" }}
                />
              </CustomBox>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#222222",
                  }}
                >
                  Gender
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "400",
                    color: "#4D4D4D",
                  }}
                >
                  {person.Gender ? person.Gender : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={4}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Age
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "400",
                  color: "#4D4D4D",
                }}
              >
                {person.Age ? person.Age : "N/A"}
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={3}>
            <Box display="flex" flexDirection="row" alignItems='center' gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                LTI
              </Typography>
              <Switch
                checked={person.LTI === 1}
                disabled
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "rgba(33, 150, 243, 1)", // Customize the color when ON (checked)
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                  {
                    backgroundColor: "#4caf50", // Customize the track color when ON
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "rgba(33, 150, 243, 1)", // Customize the track color when OFF
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center" gap={1}>
              <CustomBox>
                <DateRangeOutlinedIcon
                  sx={{ width: "20px", height: "20px" }}
                />
              </CustomBox>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#222222",
                  }}
                >
                  IME Date
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "400",
                    color: "#4D4D4D",
                  }}
                >
                  {person["IME Date"] ? person["IME Date"] : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                PME Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "400",
                  color: "#4D4D4D",
                }}
              >
                {person["PME Date"] ? person["PME Date"] : "N/A"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                VTC Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "400",
                  color: "#4D4D4D",
                }}
              >
                {person["VTC Date"] ? person["VTC Date"] : "N/A"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center" gap={1}>
              <CustomBox>
                <EmojiPeopleIcon
                  sx={{ width: "20px", height: "20px" }}
                />
              </CustomBox>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#222222",
                  }}
                >
                  Injury Code
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "400",
                    color: "#4D4D4D",
                  }}
                >
                  {person["Injury Code"]
                    ? person["Injury Code"]
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Body Parts Involved
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "400",
                  color: "#4D4D4D",
                }}
              >
                <Tooltip title={(person["Body Parts Involved"] && person["Body Parts Involved"].length > 0)
                  ? person["Body Parts Involved"].join(", ")
                  : "N/A"}>
                  <span>
                    {person["Body Parts Involved"] && person["Body Parts Involved"].length > 0
                      ? person["Body Parts Involved"].join(", ").length > 5
                        ? `${person["Body Parts Involved"].join(", ").substring(0, 5)}...`
                        : person["Body Parts Involved"].join(", ")
                      : "N/A"}
                  </span>
                </Tooltip>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center" gap={1}>
              <CustomBox>
                <AccessTimeIcon
                  sx={{ width: "20px", height: "20px" }}
                />
              </CustomBox>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#222222",
                  }}
                >
                  Hours at work
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: "400",
                    color: "#4D4D4D",
                  }}
                >
                  {person["Hours at work"]
                    ? person["Hours at work"]
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Form A No.
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "400",
                  color: "#4D4D4D",
                }}
              >
                {person["Form A No."]
                  ? person["Form A No."]
                  : "N/A"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#222222",
                }}
              >
                Outcome
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: "400",
                  color: "#4D4D4D",
                }}
              >
                {person["Outcome"] ? person["Outcome"] : "N/A"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>

  )
}

export default PersonnnelCard;