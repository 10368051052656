import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, InputBase } from "@mui/material";
import IncidentTopCard from "../Components/IncidentTopCard";
import CustomSelect from "../Components/CustomSelectField";
import CustomButtonGroup from "../Components/CustomButtonGroup";
import { filterIcon, listIcon, searchIcon } from "../constant";
import IncidentModuleCard from "../Components/IncidentModuleCard";
import ActionsCard from "../Components/ActionsCard";
import CustomTable from "../Components/CustomTable";
import RightDrawer from "../Components/RightDrawerIncidentReport";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../redux/Loader/actions";
import {
  setAllReportData,
  handleChangeDrawerFormData,
  updateFilter,
  resetFilter
} from "../redux/incidentReport/actions"; // Import your action here
import { getHistoryStatis, getAllReports, getAllReportsByModule, getAllStatiCard, getAllStatiCardByModule, getEntity, getStaffAndEntity, getFilterdropValues, ReportHistoryList, ReportCompletedHistoryList } from '../Apis/apiCall';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useLocation } from 'react-router-dom';
import Loader from "../Components/Loader/Loader";
import { START_LOADING, STOP_LOADING } from "../redux/Loader/constant"

const lossControlstatus = [
  { label: "New Survey", value: "New_Survey" },
  { label: "Revalidation", value: "Revalidation" },
  { label: "In Progress", value: "In_Progress" },
  { label: "Resubmission", value: "Resubmission" },
  { label: "Under Review", value: "Under_Review" },
  { label: "Returned", value: "Returned_By_MM" },
  { label: "Completed", value: "Completed" },
]

const incidentStatus = [
  { label: "New Incident", value: "New Incident" },
  { label: "Incident Review", value: "Incident Review" },
  { label: "Resubmitted for Review", value: "Resubmitted for Review" },
  { label: "Incident Revalidation", value: "Incident Revalidation" },
  { label: "Investigation Initiated", value: "Investigation Initiated" },
  { label: "Investigation Validation", value: "Investigation Validation" },
  { label: "Investigation Reinitiated", value: "Investigation Reinitiated" },
  { label: "Investigation Resubmission", value: "Investigation Resubmission" },
  { label: "Investigation Approval Resubmission", value: "Investigation Approval Resubmission" },
  { label: "Investigation Clarification", value: "Investigation Clarification" },
  { label: "Investigation Under Approval", value: "Investigation Under Approval" },
  { label: "Investigation Completed", value: "Investigation Completed" },
  { label: "Investigation Query", value: "Investigation Query" },
  { label: "CAPA Review", value: "CAPA Review" },
  { label: "CAPA Assigned", value: "CAPA Assigned" },
  { label: "CAPA InProgress", value: "CAPA InProgress" },
  { label: "CAPA Resubmitted", value: "CAPA Resubmitted" },
  { label: "CAPA Revalidation", value: "CAPA Revalidation" },
  { label: "CAPA Clarification", value: "CAPA Clarification" },
  { label: "CAPA Review Resubmission", value: "CAPA Review Resubmission" },
  { label: "CAPA Completed", value: "CAPA Completed" },
  { label: "Incident Closed", value: "Incident Closed" }
];


const incidentRoles = [
  { "label": "Head Safety", "value": "Head Safety" },
  { "label": "Head HR", "value": "Head HR" },
  { "label": "Managing Director", "value": "Managing Director" },
  { "label": "Reporting Officer", "value": "Reporting Officer" },
  { "label": "Investigator", "value": "Investigator" },
  { "label": "First Reviewer", "value": "First Reviewer" },
  { "label": "Second Reviewer", "value": "Second Reviewer" },
  { "label": "CAPA Owner", "value": "CAPA Owner" },

]


const lossRoles = [
  { "label": "Executive Officer", "value": "Executive Officer" },
  { "label": "Installation Manager", "value": "Installation Manager" },
  { "label": "Mine Manager", "value": "Mine Manager" },
  { "label": "Agent", "value": "Agent" }
]




export default function AllActionPage() {
  const dispatch = useDispatch();
  const locations = useLocation();
  const { moduleID, moduleName, homepage, overDue } = locations?.state || {};
  const loginDataString = localStorage.getItem("loginData");
  const data = JSON.parse(loginDataString);
  const modules = data?.config?.modulesDetail;
  const roles = data?.config?.permissionDetails?.workflowRoles;
  const [cardData, setCardData] = useState([]);
  const [staffNameList, setStaffNameList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [location, setLocation] = useState([]);
  const [reportingOfficer, setReportingOfficer] = useState([]);;
  const [executiveOfficerName, setExecutiveOfficerName] = useState([]);
  const [tableView, setTableView] = useState(false);
  const [selectedIncidentsToShow, setSelectedIncidentsToShow] = useState("All Action");
  const [filteredData, setFilteredData] = useState([]);
  const [fourCard, setFourCard] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { searchQuery } = useSelector((state) => state.loadingReducer);


  const loading = useSelector((state) => state.loadingReducer.loading);



  const roleIDs = [27, 28, 29];

  const [filterForm, setFilterForm] = useState(false);

  const { Filter, allReportsData } = useSelector(
    (state) => state.incidentReportFromReducer
  );

  console.log(Filter, "Filter");
  const [filterApplied, setFilterApplied] = useState(false);

  const incidentFilterFormFields = [
    {
      formFieldID: 29,
      formFieldName: "Incident Type",
      dataType: "String",
      type: "ButtonGroup",
      isEditable: 0,
      options: ["UA", "UC", "NS", "Minor", "MR", "DO" ,"Serious", "Fatal"],
    },
    {
      formFieldID: 30,
      formFieldName: "Severity",
      dataType: "Number",
      type: "ButtonGroup",
      isEditable: 0,
      options: ["1", "2", "3", "4", "5","NA"],
    },
    {
      formFieldID: 31,
      formFieldName: "Shift",
      dataType: "String",
      type: "ButtonGroup",
      isEditable: 0,
      options: ["I", "II", "III", "General"],
    },
    {
      formFieldID: 36,
      formFieldName: "Incident Time",
      dataType: "Date",
      type: "Date",
      isEditable: 0,
      options: null,
    },
    {
      formFieldID: 42,
      formFieldName: "Staff Type",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: [
        { label: "Employee", value: "Employee" },
        { label: "Executive", value: "Executive" },
        { label: "Contactor", value: "Contactor" },
        { label: "Visitor", value: "Visitor" },
        { label: "Workperson", value: "Workperson" },
        { label: "Executive", value: "Executive" },
      ],
    },
    {
      formFieldID: 41,
      formFieldName: "Reported By - Staff Name",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: staffNameList,
    },
    {
      formFieldID: 40,
      formFieldName: "Reported By - Entity Name",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: entityList,
    },


    {
      formFieldID: 43,
      formFieldName: "Incident Location",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: location,
    },
    {
      formFieldID: 44,
      formFieldName: "Department",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: ["Mining", "Mechanical", "Electrical", "Civil", "Personal", "Survey", "Security", "IT", "Others"],
    },
    {
      formFieldID: 45,
      formFieldName: "Status",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: incidentStatus,
    },
    {
      formFieldID: 46,
      formFieldName: "Reporting Officer",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: reportingOfficer,
    },
  ];

  const lossFilterFormFields = [
    {
      formFieldID: 1,
      formFieldName: "Executive Name",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: executiveOfficerName,
    },
    {
      formFieldID: 2,
      formFieldName: "Discipline",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: ["Asset", "Drilling", "OGPS", "PSS", "GMS & LPG", "Engineering", "Finance & Other", "HR", "Security", "Medical"],
    },

    {
      formFieldID: 3,
      formFieldName: "Date",
      dataType: "Date",
      type: "Date",
      isEditable: 0,
      options: null,
    },


    {
      formFieldID: 4,
      formFieldName: "Location",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: location,
    },

    {
      formFieldID: 5,
      formFieldName: "Status",
      dataType: "String",
      type: "Dropdown",
      isEditable: 0,
      options: lossControlstatus,
    },

  ];


  const handleFilterClose = () => {
    setFilterForm(false);
  };

  // useEffect(() => {
  //   if (!selectedIncidentsToShow) {
  //     fetchReportCardData();
  //     fetchTopCardData();
  //     getAllfilterOptions();
  //   }

  // }, [Filter]);



  const getAllfilterOptions = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getFilterdropValues();
      const formattedExecutiveOfficers = result.data?.data?.executiveOfficers?.map(officer => ({
        value: officer?.name,
        label: officer?.name
      }));

      const formattedReportingOfficers = result.data?.data?.reportingOfficers?.map(officer => ({
        value: officer?.name,
        label: officer?.name
      }));

      const formattedLocations = result.data?.data?.locations?.map(location => ({
        value: location?.displayName,
        label: location?.displayName
      }));

      // Set the state for each dropdown
      setExecutiveOfficerName(formattedExecutiveOfficers);
      setReportingOfficer(formattedReportingOfficers);
      setLocation(formattedLocations);
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  useEffect(() => {
    dispatch(setSearchQuery(""));
    if (homepage) {
      dispatch(updateFilter({ fieldName: moduleName, moduleId: moduleID, moduleName: "Filter" }));

    }
    fetchReportCardData();
    fetchTopCardData();
    getAllfilterOptions();
  }, []);

  useEffect(() => {
    if (Filter?.ModuleId != "") {
      fetchReportCardDataByModule(Filter.ModuleId);
      fetchTopCardDataMyModule(Filter.ModuleId)
    }
  }, [Filter.ModuleId]);

  useEffect(() => {

    if (Filter?.ModuleId != "") {
      dispatch(handleChangeDrawerFormData({ fieldName: "overDue", value: "", title: "Filter" }));
      if (selectedIncidentsToShow === "Completed") {
        fetchTopCardDataHistoy(Filter.ModuleId, roleIDs, true, "my")
      }
      else {
        fetchTopCardDataMyModule(Filter.ModuleId)
      }
    }

  }, [selectedIncidentsToShow]);

  useEffect(() => {

    if (Filter.Modules === "Incident Management") {
      const filteredReports = allReportsData.filter((report) => {
        const matchesRole = !Filter.Roles || report.role === Filter.Roles;
        const matchesStatus = !Filter.Status ||
          (Array.isArray(report.status)
            ? report.status.includes(Filter.Status)
            : report.status === Filter.Status);
        const matchesSeverity =
          !Filter.Severity || report.Severity == Filter.Severity;
        const matchesShift = !Filter.Shift || report.Shift === Filter.Shift;
        const matchesIncidentType =
          !Filter["Incident Type"] ||
          report?.["Incident Type"] === Filter["Incident Type"];
        const matchesIncidentTime =
          !Filter["Incident Time"] ||
          report?.["Incident Time"] === Filter["Incident Time"];
        const matchesStaffType =
          !Filter["Staff Type"] ||
          report?.["Staff Type"] === Filter["Staff Type"];
        const matchesReportedStaffName =
          !Filter["Reported By - Staff Name"] ||
          report["Reported By - Staff Name"] === Filter["Reported By - Staff Name"];
        const matchesReportedEntityName =
          !Filter["Reported By - Entity Name"] ||
          report["Reported By - Entity Name"] === Filter["Reported By - Entity Name"];
        const matchesIncidentLocation =
          !Filter["Incident Location"] || report["Incident Location"] === Filter["Incident Location"];
        const matchesDepartment =
          !Filter.Department || report.Department === Filter.Department;
        const matchesOverDue =
          !Filter["overDue"]
          || report.overdueTaskCount >= Filter["overDue"];


        return (
          matchesRole &&
          matchesStatus &&
          matchesIncidentTime &&
          matchesSeverity &&
          matchesIncidentType &&
          matchesStaffType &&
          matchesShift &&
          matchesReportedStaffName &&
          matchesReportedEntityName &&
          matchesIncidentLocation &&
          matchesDepartment &&
          matchesOverDue
        );
      });
      // .filter(module => module.data.length > 0);

      const isAnyFilterApplied = Object.values(Filter).some((value) => value);
      if (isAnyFilterApplied) {
        setFourCard(true);
      }

      const isModuleSet = Filter.Modules;

      // Check if any other key in Filter (excluding 'Module') has a value
      const hasOtherFilters = Object.keys(Filter).some(
        (key) => key != "Modules" && key != "ModuleId" && Filter[key]
      );

      // Apply filtering only if both conditions are met
      if (isModuleSet && hasOtherFilters) {
        setFilterApplied(true); // Apply filtering
        setFilteredData(filteredReports.length > 0 ? filteredReports : []);
      } else {
        setFilterApplied(false); // Reset filter state
        setFilteredData([]); // Clear the filtered data if filters are not applied
      }
    }
    if (Filter.Modules === "Loss Control") {
      const filteredReports = allReportsData.filter((report) => {
        const matchesRole = !Filter.roles || (Array.isArray(report?.role) && report?.Role.includes(Filter.Roles));

        const matchesStatus = !Filter.Status ||
          (Array.isArray(report.status)
            ? report.status.includes(Filter.Status)
            : report.status === Filter.Status);
        const matchesDiscipline =
          !Filter.Discipline || report.discipline == Filter.Discipline;
        const matchesDate =
          !Filter["Date"] ||
          report?.["Tour Date"] === Filter["Date"];

        const matchesExecutiveName =
          !Filter["Executive Name"] ||
          report["Executive Name"] === Filter["Executive Name"];

        const matchesLocation =
          !Filter["Location"] || report.locationName === Filter["Location"];

        const matchesOverDue =
          !Filter["overDue"]
          || report.overdueTaskCount >= Filter["overDue"];

        return (
          matchesRole &&
          matchesDate &&
          matchesStatus &&
          matchesDiscipline &&
          matchesExecutiveName &&
          matchesOverDue &&
          matchesLocation
        );
      });
      // .filter(module => module.data.length > 0);

      const isAnyFilterApplied = Object.values(Filter).some((value) => value);
      if (isAnyFilterApplied) {
        setFourCard(true);
      }

      //const hasStatusOrRoles = Filter.Status || Filter.Roles;

      const isModuleSet = !!Filter.Modules;

      // Check if any other key in Filter (excluding 'Module') has a value
      const hasOtherFilters = Object.keys(Filter).some(
        (key) => (key !== "Modules" && key !== "ModuleId") && Filter[key]
      );

      // Apply filtering only if both conditions are met
      if (isModuleSet && hasOtherFilters) {
        setFilterApplied(true); // Apply filtering
        setFilteredData(filteredReports.length > 0 ? filteredReports : []);
      }
      else {
        console.log("secondddddd")
        setFilterApplied(false); // Reset filter state
        setFilteredData([]); // Clear the filtered data if filters are not applied
      }
      console.log("report data ", filteredReports);


    }

  }, [Filter, selectedIncidentsToShow]);



  const fetchTopCardDataHistoy = async (moduleID, roleIDs, status, action) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getHistoryStatis(moduleID, roleIDs, status, action);
      setCardData(result.data.data);
      console.log("kkkkkkk")
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }


  const fetchTopCardDataMyModule = async (value) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getAllStatiCardByModule(value);
      setCardData(result.data.data);
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const fetchTopCardData = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getAllStatiCard();
      setCardData(result.data.data);
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }
  const fetchReportCardData = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getAllReports();
      dispatch(setAllReportData(result.data.data));
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const fetchReportCardDataByModule = async (value) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getAllReportsByModule(value);
      dispatch(setAllReportData(result.data.data));
    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  useEffect(() => {
    console.log(Filter["Staff Type"]);
    if (Filter["Staff Type"]) {
      fetchStaffEntitylist(Filter["Staff Type"]);
    }
  }, [Filter["Staff Type"]]);

  useEffect(() => {
    console.log(Filter["Staff Type"]);
    if (Filter["Staff Type"] && Filter["Reported By - Staff Name"]) {
      fetchEntity(Filter["Staff Type"], Filter["Reported By - Staff Name"]);
    }
  }, [Filter["Reported By - Staff Name"]]);

  const fetchStaffEntitylist = async () => {
    dispatch({type : START_LOADING})
    try {
      const result = await getStaffAndEntity(Filter["Staff Type"]);
      console.log(result.data.data);

      // Map the staff array to the desired format (key as `id` and label as `firstName` + `lastName`)
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: `${staffMember.firstName} ${staffMember.lastName}`
      }));

      setStaffNameList(formattedStaffList); // Set the formatted staff list

    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }

  const fetchEntity = async (type, id) => {
    dispatch({type : START_LOADING})
    try {
      const result = await getEntity(type, id);
      console.log(result.data.data);
      const formattedStaffList = result.data.data.map(staffMember => ({
        value: staffMember.id,
        label: staffMember.name
      }));

      setEntityList(formattedStaffList); // Set the formatted staff list

    } catch (error) {
      console.log(error);
    }
    finally{
      dispatch({ type: STOP_LOADING }); 
    }
  }


  const fetchCompletedHistoryList = async (ModuleId, roleIDs) => {
    try {
      const result = await ReportCompletedHistoryList(ModuleId, roleIDs);
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleInputChange = (e, fieldName, title) => {
    if (fieldName === "Modules") {
      let id;
      const { value } = e.target;  // Destructure the value from the event directly

      // Set label based on the value
      if (value === "Incident Management") {
        id = 1;
      } else {
        id = 2;
      }

      // Dispatch the action with label and value
      dispatch(updateFilter({ fieldName: value, moduleId: id, moduleName: title }));
    } else {
      const { value } = e.target; // For other fields, get value from target
      dispatch(handleChangeDrawerFormData({ fieldName, value, title }));
    }
  };



  const handleIncidentsToShow2 = (fieldName) => {
    setSelectedIncidentsToShow((prev) => {
      const newSelected = fieldName;
      filterMyIncidentOnly(newSelected);
      return newSelected;
    });
  };

  const filterMyIncidentOnly = async (fieldName) => {
    // Determine data to set based on the field name clicked
    if (fieldName === "All Action") {
      fetchReportCardDataByModule(Filter.ModuleId);
    }

    if (fieldName === "Completed") {
      const data = await fetchCompletedHistoryList(Filter.ModuleId, roleIDs);
      dispatch(setAllReportData(data))
    }
  };

  const handleSearchIconClick = () => {
    setShowSearchInput((prev) => !prev); // Toggle visibility
    if (showSearchInput) setSearchQuery(''); // Reset query when hiding input
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    dispatch(setSearchQuery(query)); // Update search query
  };

  const handleResetFilter = () => {
    setSelectedIncidentsToShow(null);
    setFourCard(false)
    dispatch(resetFilter());
  }


  return (
    <>
    {loading && ( 
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 1000,
        }}
      >
        <Loader />
      </Box>
    )}
    <Box component="main" sx={{ flexGrow: 1, width: "100%" }}>
      {/* top cards */}
      <Grid container spacing={0} mb={3} sx={{ width: "100%" }}>
        {Filter.Modules === "Incident Management" ? (
          <>
            {cardData?.map((item, index) => (
              <Grid key={index} item xs={12} md={fourCard && selectedIncidentsToShow == "All Action" ? 3:fourCard ? 4  : 4} sx={{ width: "100%" }}>
                <IncidentTopCard
                  data={{
                    name: item.title,                 // Title from the data
                    info: "Last Month Trend", // Static info
                    percentage: `${item.percentage}%`, // Percentage value
                    value: item.value,
                    indicator: item.indicator              // Value from the data
                  }}
                />
              </Grid>
            ))}
          </>

        ) : Filter.Modules === "Loss Control" ? (
          <>
            {cardData?.map((item, index) => (
              <Grid item xs={12} md={4} sx={{ width: "100%" }}>
                <IncidentTopCard
                  data={{
                    name: item.title,                 // Title from the data
                    info: "Monthly Trend", // Static info
                    percentage: `${item.percentage}%`, // Percentage value
                    value: item.value,
                    indicator: item.indicator                              // Value from the data
                  }}
                />
              </Grid>
            ))}

          </>
        ) : (
          <>
            {cardData?.map((item, index) => (
              <Grid item xs={12} md={fourCard ? 3 : 4} sx={{ width: "100%" }}>
                <IncidentTopCard
                  data={{
                    name: item.title,                 // Title from the data
                    info: "Last Month Trend", // Static info
                    percentage: `${item.percentage}%`, // Percentage value
                    value: item.value,
                    indicator: item.indicator                              // Value from the data
                  }}
                />
              </Grid>
            ))}
          </>
        )}




      </Grid>

      <Grid
        container
        spacing={0}
        sx={{
          borderRadius: "4px",
          backgroundColor: "#F9FAFC",
          boxShadow: "0px 0px 12px -4px rgba(0, 0, 0, 0.25)",
          padding: "20px",
          width: "100%",
        }}
      >
        {/* Filter Dropdowns */}
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CustomSelect
                label="Modules"
                value={Filter?.Modules}
                options={modules?.map((obj) => ({
                  label: obj.moduleName,
                  value: obj.moduleName,
                }))}
                onChange={(e) => handleInputChange(e, "Modules", "Filter")}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <CustomSelect
                label="Roles"
                value={Filter?.Roles}
                options={Filter?.Modules === "Loss Control" ? lossRoles : incidentRoles}
                onChange={(e) => handleInputChange(e, "Roles", "Filter")}
                disable={!fourCard}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomSelect
                label="Status"
                value={Filter?.Status}
                options={Filter?.Modules === "Loss Control" ? lossControlstatus : incidentStatus}
                onChange={(e) => handleInputChange(e, "Status", "Filter")}
                disable={!fourCard}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={12}>
          <Grid container spacing={2} mt="20px" mb="16px">
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item>
                {(Filter.Modules === "Incident Management" || Filter.Modules === "Loss Control") && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <CustomButtonGroup
                        buttonNames={["All Action"]}
                        selected={selectedIncidentsToShow}
                        onSelect={(name) => handleIncidentsToShow2(name)}
                      />
                    </Grid>

                    <Grid item>
                      <CustomButtonGroup
                        buttonNames={["Completed"]}
                        selected={selectedIncidentsToShow}
                        onSelect={(name) => handleIncidentsToShow2(name)}
                      />
                    </Grid>

                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <RefreshOutlinedIcon sx={{ color: "#8FA6CC", fontSize: "32px", borderRadius: "4px", background: "#EEF2FA", p: "4px" }} onClick={handleResetFilter} />
                  </Grid>
                  {fourCard && (
                    <Grid item>
                      <AccessTimeFilledIcon sx={{ color: "#8FA6CC", fontSize: "32px", color: "#CC0000", borderRadius: "4px", p: "4px", background: Filter.overDue == 1 ? "#0aa2e3" : "#EEF2FA" }} onClick={() => dispatch(handleChangeDrawerFormData({ fieldName: "overDue", value: 1, title: "Filter" }))} />
                    </Grid>)}
                  {fourCard && (
                    <Grid item>
                      <img
                        src={filterIcon}
                        alt="filter"
                        style={{ cursor: "pointer", }}
                        onClick={() => setFilterForm(true)}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {/* Search Icon */}
                      <img
                        src={searchIcon}
                        alt="search"
                        style={{ cursor: 'pointer', marginRight: 10 }}
                        onClick={handleSearchIconClick}
                      />
                      {/* Search Input */}
                      {showSearchInput && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: '#EEF2FA',
                            borderRadius: '8px',
                            minWidth: { xs: '50%', md: '25%' },
                            height: '36px',
                          }}
                        >
                          <InputBase
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            sx={{
                              padding: '0 10px',
                              width: '100%',
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>




                  <Grid item>
                    <img
                      src={listIcon}
                      alt="list"
                      style={{ cursor: "pointer" }}
                      onClick={() => setTableView(!tableView)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!tableView && (
          <Grid item xs={12} sx={{ width: "100%" }}>
            {" "}
            {/* Adjust height as needed */}
            <Grid container spacing={2} sx={{ width: "100%" }}>
              {fourCard ? (
                <IncidentModuleCard
                  cardData={filterApplied ? filteredData : allReportsData}
                  title={Filter.Modules}
                  mode={"view"}
                  tab={selectedIncidentsToShow}
                />
              ) : !fourCard && allReportsData?.length > 0 ? (
                <IncidentModuleCard cardData={allReportsData} title={"All"} mode={"view"} tab={selectedIncidentsToShow} />
              ) : (
                <Typography
                  variant="body1"
                  alignText="center"
                  sx={{ width: "100%", padding: "20px" }}
                >
                  No data available.
                </Typography>
              )}
            </Grid>
          </Grid>
        )}

        {tableView && (
          <Grid item xs={12} sx={{ width: "100%" }}>
            {fourCard ? (
              <CustomTable data={filterApplied ? filteredData : allReportsData} title={Filter.Modules} mode={"view"} />
            ) : (
              <CustomTable data={allReportsData} title={"All"} mode={"view"} />
            )}
          </Grid>
        )}
      </Grid>

      <RightDrawer
        open={filterForm}
        onClose={handleFilterClose}
        title="Filter"
        fields={Filter.Modules === "Loss Control" ? lossFilterFormFields : incidentFilterFormFields}
        cardData={[]}
      />
    </Box>
    </>
  );
}
