import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { MapContainer, TileLayer, Circle, Marker, Tooltip } from "react-leaflet";
import { Box, Grid } from "@mui/material";
import "leaflet/dist/leaflet.css";
import LocationSelector from "./LocationSelector";
import MapControls from "./MapControls";
import { getLossControlMapData } from "../../Apis/apiCall";
import L from "leaflet";
import { locationMark } from "../../constant";

export default function LossControlMapView() {
  const [disciplines, setDisciplines] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(-1);
  const [locationData, setLocationData] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [currentLocations, setCurrentLocations] = useState([]);
  const [locationStatus, setLocationStatus] = useState({
    total: 0,
    withReports: 0,
    noReports: 0,
  });
  const [unselectedDisciplines, setUnselectedDisciplines] = useState([]);
  const [selectedLocationID,setSelectedLocationID] = useState([]);

  const handleDisciplineSelectionChange = (newUnselectedDisciplines) => {
    setUnselectedDisciplines(newUnselectedDisciplines);
  };

  const mapRef = useRef(null);

  const handleMapCreated = (map) => {
    mapRef.current = map;
  };

  const getLocationsAtLevel = useCallback((data, level) => {
    const findLocations = (node) => {
      if (!node) return [];
      if (node.orgLevel === level) {
        return [node];
      }
      if (node.children && node.children.length > 0) {
        return node.children.flatMap((child) => findLocations(child));
      }
      return [];
    };
    return data.flatMap((node) => findLocations(node));
  }, []);

  const calculateLocationStatus = useCallback((locations) => {
    if (!locations) return { total: 0, withReports: 0, noReports: 0 };
    const withReports = locations.filter((loc) => loc.reportCount > 0).length;
    const total = locations.length;
    return {
      total,
      withReports,
      noReports: total - withReports,
    };
  }, []);

  const fetchDisciplines = useCallback(() => {
    try {
      const data = JSON.parse(localStorage.getItem("loginData") || "{}");
      const formFields = data?.config?.modulesDetail?.[1]?.sectionsDetail?.[0]?.formFields || [];
      const formFieldNames = formFields.map((field) => field.formFieldName);
      setDisciplines(formFieldNames);
    } catch (error) {
      console.error("Error fetching disciplines:", error);
      setDisciplines([]);
    }
  }, []);

  // Initial fetch for disciplines
  useEffect(() => {
    fetchDisciplines();
  }, [fetchDisciplines]);

  // Fetch initial location data on component mount
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const res = await getLossControlMapData({});
        setLocationData(res.data.data);
        setAllLocations(res.data.data)
        const initialLocations = getLocationsAtLevel(res.data.data, -1);
        setCurrentLocations(initialLocations);
        setLocationStatus(calculateLocationStatus(initialLocations));
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };
    fetchLocations();
  }, [getLocationsAtLevel, calculateLocationStatus]);

  const getLocationsByParentSelection = useCallback((data, parentLocationId) => {
    const findLocations = (node) => {
      if (!node) return [];
      // If the node's parent is the selected one, return the node
      if (node.id === parentLocationId) {
        return [node];
      }
      if (node.children && node.children.length > 0) {
        return node.children.flatMap((child) => findLocations(child));
      }
      return [];
    };
  
    return data.flatMap((node) => findLocations(node));
  }, []);
  

  // Fetch locations based on unselected disciplines
  useEffect(() => {
    const fetchFilteredLocations = async () => {
      if (unselectedDisciplines.length > 0) {
        try {
          const disciplinesString = unselectedDisciplines.join(',');
          const res = await getLossControlMapData({ unselectedDisciplines: disciplinesString });
          setLocationData(res.data.data);

          let filteredLocations;

          if (currentLevel === -1) {
            // At the highest level, show all locations at this level
            filteredLocations = locationData.filter((loc) => loc.orgLevel === currentLevel);
          } else {
            // Get parent children
            const parentChildren = getLocationsByParentSelection(locationData, selectedLocationID);

            // Check for children of the selected parent
            filteredLocations = parentChildren.length > 0 ? parentChildren[0].children || [] : [];
          }

          setCurrentLocations(filteredLocations);
          setLocationStatus(calculateLocationStatus(filteredLocations));
        } catch (error) {
          console.error("Error fetching filtered locations:", error);
        }
      }
    };

    fetchFilteredLocations();
  }, [unselectedDisciplines, getLocationsByParentSelection, calculateLocationStatus, selectedLocationID]);

  const defaultIcon = L.icon({
    iconUrl: `${locationMark}`,
    iconSize: [20, 25],
    iconAnchor: [15, 45],
    iconColor:"blue"
  });

  const selectedLocationIcon = L.icon({
    iconUrl: `${locationMark}`,
    iconSize: [25, 30],
    iconAnchor: [15, 45],
    iconColor:"blue"
  })

  const locationCircles = useMemo(
    () =>
      currentLocations.map((location) => {
        const position = location.locationCode
          ? [location.latitude, location.longitude]
          : [location.avgLatitude, location.avgLongitude];

        const radius = 5000; // Radius in meters

        return location.locationCode ? (
          <Marker
            key={location.id}
            position={position}
            icon={location.id === selectedLocationID ? selectedLocationIcon : defaultIcon}
            eventHandlers={{
              click: () => {
                setSelectedLocationID(location.id);
                const children = location.children || [];
                if (children.length > 0) {
                  setCurrentLevel(location.orgLevel + 1);
                  setCurrentLocations(children);
                  setSelectedLocationID(location.id);
                  setLocationStatus(calculateLocationStatus(children));
                }
              },
            }}
          >
            <Tooltip>{location.displayName}</Tooltip>
          </Marker>
        ) : (
          <Circle
            key={location.id}
            center={position}
            radius={radius}
            eventHandlers={{
              click: () => {
                setSelectedLocationID(location.id)
                const children = location.children || [];
                if (children.length > 0) {
                  setCurrentLevel(location.orgLevel + 1);
                  setCurrentLocations(children);
                  setLocationStatus(calculateLocationStatus(children));
                }
              },
            }}
          >
            <Tooltip>{location.displayName}</Tooltip>
          </Circle>
        );
      }),
    [currentLocations, calculateLocationStatus]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <Box
          sx={{
            width: "auto",
            height: "650px",
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          <MapContainer
            center={[27.332648182377387, 95.29226287437059]} 
            zoom={10}
            zoomControl={false}
            style={{ width: "100%", height: "100%" }}
            whenCreated={handleMapCreated}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {locationCircles}
            <MapControls
              disciplines={disciplines}
              selectedLocation={currentLevel}
              onDisciplineChange={handleDisciplineSelectionChange}
              unselectedDisciplines={unselectedDisciplines}
            />
          </MapContainer>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <LocationSelector
            selectedLocation={currentLevel}
            locations={currentLocations}
            locationStatus={locationStatus}
            map={mapRef.current}
            unselectedDisciplines={unselectedDisciplines}
            allLocations = {allLocations}
            selectedLocationID = {selectedLocationID}
            disciplines={disciplines}
            onDisciplineChange={handleDisciplineSelectionChange}
          />
        </Box>
      </Grid> 
    </Grid>
  );
}
