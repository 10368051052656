// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.human-body {
  width: 100%;
  position: relative;
  height: 300px;
  display: block;
  margin: 40px auto;
}

.human-body svg:hover {
  cursor: pointer;
}

.human-body svg path:hover {
  fill: #F44336;
}

.human-body svg {
  position: absolute;
  left:25%;
  fill: #2be8ef;
}

.human-body svg path {
  transition: fill 0.3s ease;
  color: black;
}
.human-body svg path:hover {
  /* opacity: 0.8; */
  color: black;
}

.human-body svg path title:hover {
  color: black;
}


`, "",{"version":3,"sources":["webpack://./src/Components/ChartAndGraphs/HumanBody.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,0BAA0B;EAC1B,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".human-body {\n  width: 100%;\n  position: relative;\n  height: 300px;\n  display: block;\n  margin: 40px auto;\n}\n\n.human-body svg:hover {\n  cursor: pointer;\n}\n\n.human-body svg path:hover {\n  fill: #F44336;\n}\n\n.human-body svg {\n  position: absolute;\n  left:25%;\n  fill: #2be8ef;\n}\n\n.human-body svg path {\n  transition: fill 0.3s ease;\n  color: black;\n}\n.human-body svg path:hover {\n  /* opacity: 0.8; */\n  color: black;\n}\n\n.human-body svg path title:hover {\n  color: black;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
