import React, { useState, useEffect } from "react";
import { useMap } from "react-leaflet";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tooltip,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem, 
  // OutlinedInput,
  // Checkbox,
  // ListItemText,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import Description from "@mui/icons-material/Description";
import Navigation from "@mui/icons-material/Navigation";
import "leaflet/dist/leaflet.css";
import AddIcon from '@mui/icons-material/Add';
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const MapControls = ({ disciplines, onDisciplineChange,unselectedDisciplines }) => {
  //const [selectedDisciplines, setSelectedDisciplines] = useState(disciplines || []);
  const map = useMap();

  // Initialize the selected disciplines based on props
  // useEffect(() => {
  //   setSelectedDisciplines(disciplines);
  // }, [disciplines]);

  // Handle discipline selection changes
  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedDisciplines(value);

  //   // Notify parent component about unselected disciplines
  //   const unselectedDisciplines = disciplines.filter(
  //     (discipline) => !value.includes(discipline)
  //   );
  //   onDisciplineChange(unselectedDisciplines);
  // };

  const [anchorEl, setAnchorEl] = useState(null);
  const selectedDisciplines = disciplines.filter(d => !unselectedDisciplines.includes(d));
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const visibleChips = 3;
  
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisciplineToggle = (discipline) => {
    const newUnselected = unselectedDisciplines.includes(discipline)
      ? unselectedDisciplines.filter(d => d !== discipline)
      : [...unselectedDisciplines, discipline];
    onDisciplineChange(newUnselected);
  };

  const open = Boolean(anchorEl);

  const handleZoomIn = () => {
    map.setZoom(map.getZoom() + 1);
  };

  const handleZoomOut = () => {
    map.setZoom(map.getZoom() - 1);
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        map.setView([position.coords.latitude, position.coords.longitude], 13);
      });
    }
  };

  return (
    <>
      <Box>
        {/* Navigation sidebar with controls */}
        <Box
          sx={{
            position: "absolute",
            left: 16,
            top: isExpanded ? "25%" :"12%",
            transform: "translateY(-50%)",
            zIndex: 1000,
            display: "flex",
            width: "44px",
            height: "auto",
            padding: "11.525px 8.644px",
            flexDirection: "column",
            alignItems: "center",
            gap: "23.051px",
            borderRadius: "30.254px",
            border: "0.746px solid #868686",
            background: "rgba(241, 241, 241, 0.40)",
            backdropFilter: "blur(6.823729038238525px)",
          }}
        >
          {/* Main navigation icons */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "12.051px",
            }}
          >

            {isExpanded === true ? (
              <>
                <Tooltip arrow title="Loss Control" placement="right">
                <IconButton size="small">
                  <ManageAccountsIcon
                    sx={{
                      fontSize: "30px",
                      backgroundColor: "#c9c9c9",
                      borderRadius: "50%",
                      p: 0.5,
                      boxShadow: '0px 0px 6px 2px rgba(30, 144, 255, 0.6) '
                    }}
                  />
                </IconButton>
                </Tooltip>
                <Tooltip arrow title="Incident Management" placement="right">
                <IconButton size="small">
                  <TouchAppIcon
                    sx={{
                      fontSize: "30px",
                      backgroundColor: "#c9c9c9",
                      borderRadius: "50%",
                      p: 0.5,
                    }}
                  />
                </IconButton>
                </Tooltip>
                <IconButton size="small">
                  <Description
                    sx={{
                      fontSize: "30px",
                      backgroundColor: "#c9c9c9",
                      borderRadius: "50%",
                      p: 0.5,
                    }}
                  />
                </IconButton>
                <IconButton size="small">
                  <AssessmentIcon
                    sx={{
                      fontSize: "30px",
                      backgroundColor: "#c9c9c9",
                      borderRadius: "50%",
                      p: 0.5,
                    }}
                  />
                </IconButton>
                <IconButton onClick={toggleExpand}>
                <KeyboardDoubleArrowDownIcon
                  sx={{
                    fontSize: "30px",
                    backgroundColor: "#c9c9c9",
                    borderRadius: "50%",
                    p: 0.5,
                  }}
                />
              </IconButton>
                </>
            ) : (
              <IconButton onClick={toggleExpand}>
                <KeyboardDoubleArrowDownIcon
                  sx={{
                    fontSize: "30px",
                    backgroundColor: "#c9c9c9",
                    borderRadius: "50%",
                    p: 0.5,
                  }}
                />
              </IconButton>
            )}
            


          </Box>
        </Box>

        {/* Zoom and location controls */}
        <Box
          sx={{
            display: "flex",
            transform: "translateY(-50%)",
            zIndex: 1000,
            position: "absolute",
            left: 7,
            top: "82%",
            height: "44px",
            padding: "5.982px 11.965px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "14.956px",
            alignSelf: "stretch",
          }}
        >
          <IconButton
            size="small"
            onClick={handleZoomIn}
            sx={{
              bgcolor: "white",
              height: "44px",
              width: "40px",
              borderRadius: "8px",
              border: "0.748px solid #DCCFCF",
              boxShadow: "1.796px 1.796px 3.592px 0px rgba(0, 0, 0, 0.25)",
              "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
            }}
          >
            <Add sx={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleZoomOut}
            sx={{
              bgcolor: "white",
              height: "44px",
              width: "40px",
              borderRadius: "8px",
              border: "0.748px solid #DCCFCF",
              boxShadow: "1.796px 1.796px 3.592px 0px rgba(0, 0, 0, 0.25)",
              "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
            }}
          >
            <Remove sx={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleLocationClick}
            sx={{
              bgcolor: "white",
              height: "44px",
              width: "40px",
              borderRadius: "8px",
              border: "0.748px solid #DCCFCF",
              boxShadow: "1.796px 1.796px 3.592px 0px rgba(0, 0, 0, 0.25)",
              "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
            }}
          >
            <ExploreOutlinedIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
      </Box>

      {/* Discipline dropdown
      <Box
        sx={{
          position: "absolute",
          left: { md: "70%", lg: "82%", xs: "65%" },
          top: "10%",
          transform: "translateY(-50%)",
          zIndex: 1000,
          display: "flex",
          width: { xs: "120px", md: "133px" },
          padding: "6px 8px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl
          sx={{
            width: "200px",
            backgroundColor: "white",
            borderRadius: "10px",
            border: "1px solid #9D9D9D",
          }}
        >
          <InputLabel
            id="discipline-select-label"
            className="text-white bg-transparent px-1"
            sx={{ fontSize: "16px", fontWeight: 500 }}
          >
            Discipline
          </InputLabel>
          <Select
            labelId="discipline-select-label"
            multiple
            value={selectedDisciplines}
            onChange={handleChange}
            input={<OutlinedInput label="Disciplines" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: 200,
                  overflowY: "auto",
                  m:0,
                  p:'0 !important',
                },
              },
            }}
            sx={{ borderRadius: "10px" }}
          >
            {disciplines?.map((discipline) => (
              <MenuItem key={discipline} value={discipline}>
                <Checkbox checked={selectedDisciplines.indexOf(discipline) > -1} />
                <ListItemText primary={discipline} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box> */}

      {/* Discipline Chips */}
      <Box
      sx={{
        position: 'absolute',
        right: { xs: '20px', md: '40px' },
        top: '20px',
        zIndex: 1000,
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
        maxWidth: '60%',
        alignItems: 'center'
      }}
    >
      {selectedDisciplines.slice(0, visibleChips).map((discipline) => (
        <Chip
          key={discipline}
          label={discipline}
          sx={{
            display: 'inline-flex',
            padding: '8px 8px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '20px',
            backgroundColor: '#B4DEF0',
            color:'#09A2E3',
            fontWeight:600
          }}
        />
      ))}
      
      {selectedDisciplines.length > visibleChips && (
        <Chip
          label={`+ ${selectedDisciplines.length - visibleChips} More`}
          onClick={handleMoreClick}
          sx={{
            display: 'inline-flex',
            padding: '6px 6px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            borderRadius: '20px',
            backgroundColor: '#B4DEF0',
            color:'#09A2E3',
            fontWeight:600
          }}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ width: 250, maxHeight: 300, overflow: 'auto' }}>
          {disciplines.map((discipline) => (
            <ListItem key={discipline} dense button onClick={() => handleDisciplineToggle(discipline)}>
              <Checkbox
                edge="start"
                checked={!unselectedDisciplines.includes(discipline)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemText primary={discipline} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
    </>
  );
};

export default MapControls;
