import { START_LOADING, STOP_LOADING ,SHOW_TREE_MODEL,SET_SEARCH_QUERY} from './constant';

export const startLoading = () => ({
    type: START_LOADING,
  });

  export const showTreeModel = () => ({
    type: SHOW_TREE_MODEL,
  });
  
  
  export const stopLoading = () => ({
    type: STOP_LOADING,
  });

  export const setSearchQuery = (query) => ({
    type: SET_SEARCH_QUERY,
    payload: query,
  });
  