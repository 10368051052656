import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Avatar,
  Stack,
  Modal,
} from "@mui/material";
import { viewTicket, withdrawTicket } from "../../Apis/apiCall";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { formatDateToIndianLocale2 } from "../../utils/helper";
import ChatBox from "./ChatBox";

export default function ViewTicket () {
  const { id } = useParams();
  const location = useLocation();
  const { flow } = location.state || {};
  const navigate = useNavigate();

  const [ticketDetails, setTicketDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(true);

  useEffect(()=>{
    if(flow === 3){
      setIsChatBoxOpen(false);
    }
  },[])

  useEffect(() => {
    const fetchTicketDetails = async () => {
      try {
        const res = await viewTicket(id);
        setTicketDetails(res?.data?.data || {});
      } catch (error) {
        console.error("Error while fetching ticket details", error);
      }
    };

    if (id) {
      fetchTicketDetails();
    }
  }, [id]);

  if (!ticketDetails) {
    return <Typography>Loading...</Typography>;
  }

  const handleBackButton = () => {
    navigate(`/support`);
  };

  const handleWithdrawButton = async () => {
    try {
      await withdrawTicket(id);
      navigate(`/support`);
    } catch (error) {
      console.error("error while withdrawing ticket", error);
    }
  };

  const handleEditButton = () => {
    navigate(`/support/edit-ticket/${id}`, { state: { ticketDetails } });
  };

  return (
    <>
      <Box>
        <Typography
          sx={{ color: "#2E2C34", fontSize: "20px", fontWeight: 600, mb: 2 }}
        >
          Ticket
        </Typography>
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: 2,
            borderRadius: 2,
            boxShadow: 2,
            maxWidth: "100%",
            margin: "0 auto",
            position: 'relative'
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 3,
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: ticketDetails.status === "withdraw" ? "red" : "#FFD700",
                borderRadius: "50%",
              }}
            />
            <Typography variant="h6" fontWeight="bold">
              {`Ticket #${ticketDetails.customID || ticketDetails.id}`}
            </Typography>
          </Box>

          {/* Ticket Details */}
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            {[
              {
                label: "Ticket No.",
                value: ticketDetails.customID || ticketDetails.id,
              },
              {
                label: "Date & Time",
                value: formatDateToIndianLocale2(ticketDetails.createdAt),
              },
              { label: "Reported by", value: ticketDetails.name },
              {
                label: "Module",
                value:
                  ticketDetails.moduleID === 2
                    ? "Loss Control"
                    : "Incident Management",
              },
              { label: "Ticket Status", value: ticketDetails.status.charAt(0).toUpperCase() + ticketDetails.status.slice(1) },
              { label: "Started By", value: ticketDetails.createdBy },
              { label: "Sub-Section", value: ticketDetails.section },
              { label: "Closed By", value: "" },
              { label: "Role", value: "System Admin" },
              { label: "Site Name", value: "" },
              { label: "Priority", value: ticketDetails.priority.charAt(0).toUpperCase() + ticketDetails.priority.slice(1) },
              {
                label: "Started At",
                value: formatDateToIndianLocale2(ticketDetails.createdAt),
              },
              {
                label: "Closed At",
                value:
                  ticketDetails.status === "closed"
                    ? formatDateToIndianLocale2(ticketDetails.updatedAt)
                    : "--",
              },
            ].map((item, index) => (
              <>
              <Grid item xs={6} sm={3} key={index}>
                <Typography variant="body2" sx={{color:'#2E2A40',fontWeight:600}}>
                  {item.label}
                </Typography>
                <Typography variant="body1" sx={{color:'#757575'}}>{item.value || "--"}</Typography>
              </Grid>
              {isChatBoxOpen && index%3===2 && (
              <Grid item xs={6} sm={3}>
              </Grid>)}
              </>
            ))}
          </Grid>

          {/* Issue Summary */}
          <Typography variant="body1" fontWeight="bold" marginBottom={1}>
            Issue Summary
          </Typography>
          <Typography variant="body2" marginBottom={2}>
            {ticketDetails.issueSummary || "--"}
          </Typography>

          {/* Issue Description */}
          <Typography variant="body1" fontWeight="bold" marginBottom={1}>
            Issue Description
          </Typography>
          <Typography variant="body2" marginBottom={3}>
            {ticketDetails.issueDescription || "--"}
          </Typography>

          {/* Attachments */}
          <Typography variant="body1" fontWeight="bold" marginBottom={1}>
            Attachments
          </Typography>
          <Stack direction="row" spacing={1} marginBottom={3}>
            {ticketDetails.attachments?.length > 0 ? (
              ticketDetails?.attachments?.map((attach, index) => (
                <Avatar
                  key={index}
                  variant="square"
                  src={attach}
                  alt="Attachment"
                  sx={{ width: 64, height: 64 }}
                />
              ))
            ) : (
              <Typography>No Attachments</Typography>
            )}
          </Stack>

          {/* Buttons */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBackButton}
            >
              Back
            </Button>

            {/* Button 1-User, 2-Agent, 3-Admin */}
            {flow === 1 ? (
              <>
            {ticketDetails?.status !== "withdraw" && (
              <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenModal(true)} // Open the confirmation modal
                >
                  Withdraw
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  onClick={() => handleEditButton()}
                >
                  Edit
                </Button>
              </Box>
            )}
            </>
            ) : flow === 2 ? (
              <>
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  // onClick={() => handleAcceptButton()}
                >
                  Accept
                </Button>
              </>
            ) : flow === 3 ? (
              <>
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: '#09A2E3' }}
                  onClick={() => setIsChatBoxOpen(true)}
                >
                  View
                </Button>
              </>
            ) : (
              <></>
            )}
            
          </Box>
          {isChatBoxOpen && (<ChatBox flow = {flow} isOpen = {setIsChatBoxOpen}/>)}
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)} // Close the modal
        aria-labelledby="withdraw-modal-title"
        aria-describedby="withdraw-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="withdraw-modal-title"
            variant="h6"
            sx={{ fontWeight: 600, mb: 2 }}
          >
            Are you sure?
          </Typography>
          <Typography
            id="withdraw-modal-description"
            variant="body2"
            color="textSecondary"
            sx={{ mb: 3 }}
          >
            After withdrawing, the ticket will be deleted permanently.
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            {/* Cancel Button */}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(false)} // Close the modal
            >
              No
            </Button>
            {/* Confirm Button */}
            <Button
              variant="contained"
              color="primary"
              sx={{ color: "white" }}
              onClick={() => {
                setOpenModal(false); // Close the modal
                handleWithdrawButton(); // Run the withdraw API
              }}
            >
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
