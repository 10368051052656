import React from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const LineGraph = ({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      y: {
        grid: {
          display: true, // Show horizontal grid lines
        },
      },
    },
    plugins: {
        legend: {
            display: false, // Show legend
          },
      tooltip: {
        enabled: true, // Show tooltips on hover
      },
    },
  };

  return (
    <Box sx={{ width: '100%', height: '130px' }}>
      <Line data={data} options={options} />
    </Box>
  );
};

export default LineGraph;
