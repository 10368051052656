import {
  ADD_SITE_LOCATION,
  ADD_DISCIPLINE_NAME,
  ADD_DEFAULT_QUESTIONS,
  ADD_NEW_CHECKPOINTS,
  ADD_OBSERVATION,
  ADD_DATA,
  DELETE_IMAGE,
  ADD_LOCATION_ID,
  UPDATE_DASHBOARD_FILTER,
  UPDATE_DASHBOARD_FILTER_ARRAY,
  ADD_SATISFACTORY_REMARK,
  ADD_NOTAPPLICABLE_REMARK
} from "./constant";

export const updateDashboardFilter = ({key, value}) => {
  return {
      type: UPDATE_DASHBOARD_FILTER,
      payload: {
          key,
          value,
      },
  };
};
export const updateDashboardLocation = (data) => {
  return {
      type: UPDATE_DASHBOARD_FILTER_ARRAY,
      payload: {
          value:data,
      },
  };
};

export const addSiteLocation = (siteLocation) => ({
  type: ADD_SITE_LOCATION,
  payload: siteLocation,
});

export const addLocationID = locationID => ({
  type: ADD_LOCATION_ID,
  payload: locationID
})

export const addDisciplineName = (disciplineName) => ({
  type: ADD_DISCIPLINE_NAME,
  payload: disciplineName,
});

export const addDefaultQuestions = (questions) => ({
  type: ADD_DEFAULT_QUESTIONS,
  payload: questions,
});

export const addNewCheckpoints = (checkpoints) => ({
  type: ADD_NEW_CHECKPOINTS,
  payload: checkpoints,
});

export const addObservation = (questionId, observation) => ({
  type: ADD_OBSERVATION,
  payload: { questionId, observation },
});

export const addData = (questionId, data) => ({
  type: ADD_DATA,
  payload: { questionId, data },
});

export const addSatisfactoryRemark = (questionId, remark) => ({
  type: ADD_SATISFACTORY_REMARK,
  payload: { questionId, remark },
});

export const addNotApplicableRemark = (questionId, remark) => ({
  type: ADD_NOTAPPLICABLE_REMARK,
  payload: { questionId, remark },
});

export const deleteImage = (questionId, indexToDelete) => ({
  type: DELETE_IMAGE,
  payload: {questionId, indexToDelete}
})

