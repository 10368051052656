import {
  ADD_SITE_LOCATION,
  ADD_DISCIPLINE_NAME,
  ADD_DEFAULT_QUESTIONS,
  ADD_NEW_CHECKPOINTS,
  ADD_OBSERVATION,
  ADD_DATA,
  DELETE_IMAGE,
  ADD_LOCATION_ID,
  UPDATE_DASHBOARD_FILTER,
  UPDATE_DASHBOARD_FILTER_ARRAY,
  ADD_SATISFACTORY_REMARK,
  ADD_NOTAPPLICABLE_REMARK
} from "./constant";

// const loginDataString = localStorage.getItem("loginData");
// const loginData = loginDataString ? JSON.parse(loginDataString) : null;

const initialState = {
  tours: {
    locationID: null,
    siteLocation: "",
    disciplineName: "",
    questionnaire: {},
    dashboardFilter: {
      period: 1,
      location: [],
    }
  },
};

const removeImageFromEvidence = (currentEvidence, imageIndex) => {
  const updatedEvidence = currentEvidence.filter((_, index) => index !== imageIndex);
  return updatedEvidence;
};

const lossControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_FILTER_ARRAY:
    
      return {
        ...state,
        tours: {
          ...state.tours,
          dashboardFilter: {
            ...state.tours.dashboardFilter,
            location: state.tours.dashboardFilter?.location.length === 0
              ? [...state.tours.dashboardFilter?.location, ...action.payload.value] // Add initial values if empty
              : [...action.payload.value], // Replace the entire array after the first time
          },
        },
      };
    case UPDATE_DASHBOARD_FILTER:
      const { key, value } = action.payload;
      return {
        ...state,
        tours: {
          ...state.tours,
          dashboardFilter: {
            ...state.tours.dashboardFilter,
            [key]: value, // Update the specific key in dashboardFilter
          },
        },
      };
    case ADD_LOCATION_ID:
      return {
        ...state,
        tours: {
          ...state.tours,
          locationID: action.payload,
        },
      }
    case ADD_SITE_LOCATION:
      return {
        ...state,
        tours: {
          ...state.tours,
          siteLocation: action.payload,
        },
      };
    case ADD_DISCIPLINE_NAME:
      return {
        ...state,
        tours: {
          ...state.tours,
          disciplineName: action.payload,
        },
      };
    case ADD_DEFAULT_QUESTIONS:
      return {
        ...state,
        tours: {
          ...state.tours,
          questionnaire: action.payload,
        },
      };
    case ADD_NEW_CHECKPOINTS:
      return {
        ...state,
        tours: {
          ...state.tours,
          questionnaire: [...state.tours.questionnaire, action.payload],
        },
      };
    case ADD_OBSERVATION:
      return {
        ...state,
        tours: {
          ...state.tours,
          questionnaire: state.tours.questionnaire.map((question) =>
            question.id === action.payload.questionId
              ? {
                ...question,
                questionStatus: action.payload.observation,
              }
              : question
          ),
        },
      };
    case ADD_SATISFACTORY_REMARK:
      return {
        ...state,
        tours: {
          ...state.tours,
          questionnaire: state.tours.questionnaire.map((question) =>
            question.id === action.payload.questionId
              ? {
                ...question,
                satisfactoryRemark: action.payload.remark,
              }
              : question
          ),
        },
      };
    case ADD_NOTAPPLICABLE_REMARK:
      return {
        ...state,
        tours: {
          ...state.tours,
          questionnaire: state.tours.questionnaire.map((question) =>
            question.id === action.payload.questionId
              ? {
                ...question,
                notApplicableRemark: action.payload.remark,
              }
              : question
          ),
        },
      };
    case ADD_DATA:
      return {
        ...state,
        tours: {
          ...state.tours,
          questionnaire: state.tours.questionnaire?.map((question) =>
            question.id === action.payload.questionId
              ? {
                ...question,
                data: {
                  ...question.data,
                  ...Object.entries(action.payload.data).reduce(
                    (acc, [key, value]) => {
                      if (key === "evidence") {
                        const currentEvidence =
                          question.data &&
                            Array.isArray(question.data.evidence)
                            ? question.data.evidence
                            : [];

                        if (Array.isArray(value)) {
                          if (value.length === 0) {
                            acc[key] = [];
                          } else {
                            const existingNames = new Set(
                              currentEvidence.map((file) => file.name)
                            );
                            const uniqueNewFiles = value.filter(
                              (newFile) => !existingNames.has(newFile.name)
                            );
                            acc[key] = [
                              ...currentEvidence,
                              ...uniqueNewFiles,
                            ];
                          }
                        } else if (
                          typeof value === "object" &&
                          value !== null
                        ) {
                          const existingNames = new Set(
                            currentEvidence.map((file) => file.name)
                          );
                          if (!existingNames.has(value.name)) {
                            acc[key] = [...currentEvidence, value];
                          } else {
                            acc[key] = currentEvidence;
                          }
                        } else {
                          console.error("Invalid evidence data:", value);
                          acc[key] = currentEvidence;
                        }
                      } else {
                        acc[key] = value;
                      }
                      return acc;
                    },
                    {}
                  ),
                },
              }
              : question
          ),
        },
      };
    case DELETE_IMAGE:
      return {
        ...state,
        tours: {
          ...state.tours,
          questionnaire: state.tours.questionnaire?.map((question) =>
            question.id === action.payload.questionId
              ? {
                ...question,
                data: {
                  ...question.data,
                  evidence: removeImageFromEvidence(
                    question.data.evidence || [],
                    action.payload.indexToDelete
                  ),
                },
              }
              : question
          ),
        },
      };

    default:
      return state;
  }
};

export default lossControlReducer;
