import React from 'react';
import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from './LossControl/ConfirmationModal';

export default function FRApprovalCommentModal ({ open, onClose }) {

  const [confirmation, setConfirmation] = useState(false);
  const [comment, setComment] = useState("");

  return (
    <>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: 12,
          padding: '16px',
        },
      }}
    >
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Return Comments
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '16px' }}>
        <TextField
          value={comment}
          multiline
          rows={4}
          placeholder="Max. 200 Characters"
          variant="outlined"
          fullWidth
          InputProps={{
            style: {
              borderRadius: 8,
            },
          }}
          onChange={(e)=>setComment(e.target.value)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              borderRadius: 20,
              textTransform: 'none',
              padding: '8px 16px',
              color:'white'
            }}
            onClick={()=>setConfirmation(true)}
            disabled={comment?.length === 0}
          >
            Return
          </Button>
        </div>
      </DialogContent>
    </Dialog>
    <ConfirmationModal open={confirmation} title="Are you sure?" message="Do you really want to do this?" onCancel={()=>setConfirmation(false)} onConfirm={()=>console.log("Confirm")} />
    </>
  );
};
