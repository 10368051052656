import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  IconButton,
  Box,
  Chip
} from "@mui/material";

export default function TicketTableComp({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Paginate data
  const paginatedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2, boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            {[
              "S. No.",
              "Ticket No.",
              "Reported By",
              "Status",
              "Priority",
              "Created Date",
              "Closed Date",
              "Timelapse",
              "Action",
            ].map((heading) => (
              <TableCell key={heading} sx={{ fontWeight: "bold" }}>
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell>{page * rowsPerPage + index + 1}</TableCell>
              <TableCell>{row.id ? `Ticket #${row.id}` : "----"}</TableCell>
              <TableCell>{row.name || "----"}</TableCell>
              <TableCell>
                <Chip label={row.status.charAt(0).toUpperCase() + row.status.slice(1)} sx={{backgroundColor:'#F1FAFE',color:'#09A2E3',fontWeight: 'bold',fontSize:'10px'}} size="small" />
              </TableCell>
              <TableCell>
                <Chip label={row.priority.charAt(0).toUpperCase() + row.priority.slice(1)} sx={{backgroundColor:(row.priority === "high" || row.priority === "critical")  ? '#FEF1F3' : row.priority === "medium" ? "#ffffdf" : "#90ee9080" ,color:(row.priority === "high" || row.priority === "critical")  ? '#FF0000' :  row.priority === "medium" ? "orange" : 'green' ,fontWeight: 'bold',fontSize:'10px',marginRight:1}} size="small" />
              </TableCell>
              <TableCell>
                {new Date(row.createdAt).toLocaleDateString("en-GB") || "----"}
              </TableCell>
              <TableCell>----</TableCell>
              <TableCell>----</TableCell>
              <TableCell>
                <IconButton>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M12.0961 0.762027L10.403 2.45515H2.14307V14.307H13.9949V6.04626L15.688 4.35314V15.1536C15.688 15.3781 15.5989 15.5934 15.4401 15.7522C15.2813 15.9109 15.066 16.0001 14.8415 16.0001H1.29651C1.07199 16.0001 0.856664 15.9109 0.697903 15.7522C0.539142 15.5934 0.449951 15.3781 0.449951 15.1536V1.60859C0.449951 1.38407 0.539142 1.16874 0.697903 1.00998C0.856664 0.851218 1.07199 0.762027 1.29651 0.762027H12.0961ZM15.2521 0.00012207L16.45 1.19716L8.66752 8.97959L7.47217 8.98129L7.47048 7.78256L15.2521 0.00012207Z"
                      fill="#09A2E3"
                    />
                  </svg>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
