import React from 'react';
import { Autocomplete, Box, TextField, Typography, InputAdornment } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

export default function MapSearchBox ({
  plants,
  handlePlantSelect,
  deviceLocation,
  getDistance, // Function to calculate distance
  CustomPopper, // Custom Popper component
  placeholder = "Select Plant",
  width = '280px',
}) {

  const getDistanceText = (deviceLocation, location) => {
    const distance = getDistance(deviceLocation, location.coords);
    return `You are ${distance} km from ${location.displayName}`;
  };

  return (
    <Autocomplete
      options={plants}
      getOptionLabel={(option) => option.locationCode}
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (option) =>
            option.locationCode.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.displayName.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
      onChange={handlePlantSelect}
      size="small"
      PopperComponent={CustomPopper} // Custom Popper for the 2px gap
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0px',
            gap: '8px',
          }}
        >
          <Tooltip title={getDistanceText(deviceLocation, option)}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', flexGrow: 1 }}>
              <Box display='flex' flexDirection='column' alignItems='center' gap={0.4} sx={{ maxWidth: '100px' }}>
                <Box
                  sx={{
                    width: 26,
                    height: 26,
                    borderRadius: '50%',
                    backgroundColor: '#EAE9EF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LocationOnOutlinedIcon sx={{ color: '#757575', height: '20px' }} />
                </Box>
                <Typography variant="caption" sx={{ color: '#6B6B6D' }}>
                  {getDistance(deviceLocation, option.coords) + " km"}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 500, color: '#6B6B6D' }}>
                  {option.locationCode.length < 12 ? option.locationCode : option.locationCode.substring(0, 11) + '...'}
                </Typography>
                <Typography variant="caption" sx={{ color: 'rgba(82, 89, 102, 1)' }}>
                  {option.displayName.length < 21 ? option.displayName : option.displayName.substring(0, 20) + '...'}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexShrink: 0 }}>
            <ArrowForwardIcon sx={{ color: '#757575', transform: 'rotate(-135deg)' }} />
          </Box>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              border: 'none',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
              borderRadius: '8px',
            },
          }}
          sx={{
            width,
            zIndex: 1000,
            backgroundColor: 'white',
            borderRadius: '8px',
            '& fieldset': {
              border: 'none',
              borderRadius: '8px',
            },
          }}
        />
      )}
    />
  );
}
