
import {
  SET_COMPENSATION_INFO_DATA,
  ADD_MITIGATION_MEASURES,
  ADD_LESSON_LEARNED,
  UPDATE_FORM_FIELD,
  ADD_TO_CAUSE_ANALYSIS,
  EDIT_TO_CAUSE_ANALYSIS,
  SET_ROOT_CAUSE_ANALYSIS,
  ADD_INCIDENT_RESPONSIBILITY,
  UPDATE_CAUSE,ADD_CAUSE,REMOVE_CAUSE,
  ADD_CHECKBOX,REMOVE_CHECKBOX,
  UPDATE_DATE_TIME,
  ADD_PERSONNEL_DETAIL,
  SET_CAPA_DETAILS,
  ONCHANGE_DRAWER_FORM,
  ADD_CAPA_DETAIL,
  ADD_EVIDENCE_DETAIL,
  UPDATE_SWITCH_STATE,
  EDIT_CAPA_DETAIL,
  DELETE_CARD_DETAIL,
  SET_ALL_REPORT_DATA,
  UPDATE_INVESTIGATION_EVIDENCE_FILE,
  RESET_STATE,
  REMOVE_INVESTIGATION_MEMBER,
  UPDATE_INVESTIGATION_WITNESS_FILE,
  ADD_WITNESS_STATEMENT,
  ADD_COMPENSATION_INFO,
  ADD_CONTRIBUTING_FACTOR_DETAIL,
  EDIT_FACTOR_CARD_DETAIL,
  DELETE_FACTOR_CARD_DETAIL,
  SET_INCIDENT_DATA,
  SET_WORKFLOW_INPUT_DATA
} from './constant';

export const setIncidentData = (incidentData) => {
  return {
    type: SET_INCIDENT_DATA,
    payload: incidentData,
  };
};

export const setWorkflowInputData = (WorkflowInputData) => {
  return {
    type: SET_WORKFLOW_INPUT_DATA,
    payload: WorkflowInputData,
  };
};

export const setRootCauseAnalysis = (data) => ({
  type: SET_ROOT_CAUSE_ANALYSIS,
  payload: data,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const updateFormField = ({ fieldName, value }) => ({
  type: UPDATE_FORM_FIELD,
  payload: { fieldName, value },
});

export const addCheckbox = (label) => ({
  type: ADD_CHECKBOX,
  payload: label,
});

export const removeCheckbox = (label) => ({
  type: REMOVE_CHECKBOX,
  payload: label,
});



export const updateIncidentDateTime = (fieldName, value, title) => ({
  type: UPDATE_DATE_TIME,
  payload: { fieldName, value, title},
});

export const addPersonnelDetail = (PersonnelDetail) => {
  return {
    type: ADD_PERSONNEL_DETAIL,
    payload: PersonnelDetail
  };
};

export const addMitigationMeasures = (MitigationMeasures) => {
  return {
    type: ADD_MITIGATION_MEASURES,
    payload: MitigationMeasures
  };
};


export const addWitnessStatementDetail = (WitnessStatement) => {
  return {
    type: ADD_WITNESS_STATEMENT,
    payload: WitnessStatement
  };
};

export const addCompensationInfoDetail = (CompensetionInfo) => {
  return {
    type: ADD_COMPENSATION_INFO,
    payload: CompensetionInfo
  };
};


export const setCompensationFormData = ({row,title}) => {
  return {
    type: SET_COMPENSATION_INFO_DATA,
    payload: {row,title}
  };
};









export const addLessonsLearned = (LessonsLearned) => {
  return {
    type: ADD_LESSON_LEARNED,
    payload: LessonsLearned
  };
};

export const AddIncidentResponsibility = (IncidentResponsibility) => {
  return {
    type: ADD_INCIDENT_RESPONSIBILITY,
    payload: IncidentResponsibility
  };
};





export const addEvidenceDetail = (evidenceDetail) => {
  return {
    type: ADD_EVIDENCE_DETAIL,
    payload: evidenceDetail
  };
};

export const addCAPADetail = (CAPADetail) => {
  return {
    type: ADD_CAPA_DETAIL,
    payload: CAPADetail
  };
};


 
export const handleChangeDrawerFormData = ({fieldName, value, title}) => ({
  type: ONCHANGE_DRAWER_FORM,
  payload: { fieldName, value, title},
});

export const updateSwitchState = (sectionName, value) => ({
  type: UPDATE_SWITCH_STATE,
  payload: {
    sectionName,
    value,
  },
});

export const editInvestFlowCardDetail = ({id ,title}) => {
  return {
    type: EDIT_CAPA_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const deleteInvestCardDetail = ({id ,title}) => {
  return {
    type: DELETE_CARD_DETAIL,
    payload: { id ,title }  // Pass the ID of the LTI detail you want to edit
  };
};

export const editContributingFactorDetail = (name) => {
  return {
    type: EDIT_FACTOR_CARD_DETAIL,
    payload: name  // Pass the ID of the LTI detail you want to edit
  };
};


export const deleteContributingCardDetail = (name) => {
  return {
    type: DELETE_FACTOR_CARD_DETAIL,
    payload: name  // Pass the ID of the LTI detail you want to edit
  };
};





export const setAllReportData = (data) => {
  return {
      type: SET_ALL_REPORT_DATA,
      payload: data
  };
};




export const setCAPADetails = (capaDetails) => {
  return {
    type: SET_CAPA_DETAILS,
    payload: capaDetails,
  };
};

export const removeInvestigationMember = (member) => {
  return {
    type: REMOVE_INVESTIGATION_MEMBER,
    payload: member,
  };
};



export const addCause = (causeType, newCause) => ({
  type: ADD_CAUSE,
  payload: { causeType, newCause }
});
export const removeCause = (causeType, index) => ({
  type: REMOVE_CAUSE,
  payload: { causeType, index }
});




// Action to clear cause data (rootCause, immediateCause, indirectCause)
export const updateCause = (causeType, index, updatedData) => ({
  type: UPDATE_CAUSE,
  payload: { causeType, index, updatedData }
});



export const updateInvestigationEvidenceFile = ({file,fileURL}) => ({
  type: UPDATE_INVESTIGATION_EVIDENCE_FILE,
  payload: {file,fileURL},
});



export const updateInvestigationWitnessStatementFile = ({file,fileURL}) => ({
  type: UPDATE_INVESTIGATION_WITNESS_FILE,
  payload: {file,fileURL},
});

export const addContributingFactorDetail = (contributingFactorDetail) => {
  return {
      type: ADD_CONTRIBUTING_FACTOR_DETAIL,
      payload: contributingFactorDetail
  };
};


  