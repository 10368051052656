import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getAllLocationName } from "../../Apis/apiCall";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const TreeNode = ({
  node,
  level = 0,
  selectedNodes,
  currentNode,
  onNodeSelect,
  onToggle,
}) => {
  const nodeIdentifier = node.orgLevel === -1 ? node.orgID : node.id;
  const isSelected = selectedNodes?.includes(nodeIdentifier);
  const hasChildren = Array.isArray(node.children) && node.children.length > 0;

  const handleClick = (e) => {
    e.stopPropagation();
    onNodeSelect(nodeIdentifier, node.orgLevel);
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    onToggle(nodeIdentifier);
  };

  return (
    <Box sx={{ ml: 4, position: "relative" }}>
      {level > 0 && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "-25px",
            height: "100%",
            borderLeft: "1px solid #ccc",
            zIndex: 0,
          }}
        />
      )}

      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "4px 0",
          position: "relative",
          opacity: 1,
        }}
      >
        {level > 0 && (
          <Box
            sx={{
              position: "absolute",
              left: "-25px",
              top: "4px",
              width: "26px",
              height: "16px",
              borderBottom: "1px solid #ccc",
              borderLeft: "1px solid #ccc", // Add the left border to complete the curve
              borderRadius: "0 0 0 16px", // Top-left radius for a curved edge
            }}
          />
        )}
        <Box
          onClick={handleToggle}
          sx={{
            width: 16,
            height: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(hasChildren && {
              fontSize: "12px",
              color: "#666",
              backgroundColor: "transparent",
            }),
          }}
        >
          {hasChildren && (node.isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
        </Box>
        <Box
          sx={{
            width: 16,
            height: 16,
            borderRadius: hasChildren ? "0" : "none",
            border: !hasChildren ? "1px solid black" : "none",
            fontSize: "16px",
            color: "#666",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0
          }}
        >
          {isSelected && !hasChildren && (
            <CheckIcon
              sx={{
                fontSize: "inherit",
                color: "white",
                backgroundColor: "#09A2E3",
                borderRadius: "0%",
              }}
            />
          )}
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: "#333",
            ml: hasChildren ? 0 : 1,
            fontWeight: "normal",
            border: "none",
            backgroundColor: "transparent",
            borderRadius: "4px",
            padding: "2px 4px",
            whiteSpace:'nowrap'
          }}
        >
          {node.displayName} {node.orgLevel !== -1 && `- L${node.orgLevel}`}
        </Typography>
      </Box>
      {node.isOpen && hasChildren && (
        <Box>
          {node.children.map((childNode) => (
            <TreeNode
              key={childNode.id}
              node={childNode}
              level={level + 1}
              selectedNodes={selectedNodes}
              currentNode={currentNode}
              onNodeSelect={onNodeSelect}
              onToggle={onToggle}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const AuditSitesTree = ({ open, onClose, onNext }) => {
  const orgID = 86;
  const [orgTree, setOrgTree] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [currentNode, setCurrentNode] = useState(null);

  useEffect(() => {
    const fetchOrgTree = async () => {
      try {
        // const res = await getOrgTree(orgID);
        const res = await getAllLocationName();
        const orgTreeData = res.data.data;
        setOrgTree(orgTreeData);
      } catch (error) {
        console.error("Error while fetching organization tree", error);
      }
    };

    fetchOrgTree();
  }, [orgID]);

  const handleNodeSelect = (nodeID, nodeLevel) => {
    if (nodeLevel !== 4) return;

    setCurrentNode(nodeID);
    setSelectedNodes((prev) =>
      prev?.includes(nodeID)
        ? prev.filter((id) => id !== nodeID)
        : [...prev, nodeID]
    );
  };

  const handleToggle = (nodeID) => {
    setOrgTree((prevTree) => {
      const toggleNode = (nodes) =>
        nodes.map((node) => {
          const nodeIdentifier = node.orgLevel === -1 ? node.orgID : node.id;
          if (nodeIdentifier === nodeID) {
            return { ...node, isOpen: !node.isOpen };
          }
          if (node.children) {
            return { ...node, children: toggleNode(node.children) };
          }
          return node;
        });
      return toggleNode(prevTree);
    });
  };

  const findNodeHierarchy = (nodes, searchId) => {
    const findPath = (node, path = []) => {
      const nodeIdentifier = node.orgLevel === -1 ? node.orgID : node.id;
      if (nodeIdentifier === searchId) return [...path, node];
      if (node.children) {
        for (const child of node.children) {
          const foundPath = findPath(child, [...path, node]);
          if (foundPath) return foundPath;
        }
      }
      return null;
    };

    for (const node of nodes) {
      const path = findPath(node);
      if (path) return path;
    }
    return [];
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Select Audit Sites
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Container maxWidth={false}>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  borderRadius: "8px",
                  backgroundColor: "rgba(82, 89, 102, 0.04)",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  height: "500px",
                  overflow: "auto",
                  position: "relative",
                  padding:'16px'
                }}
              >
                <Paper
                    elevation={0}
                    sx={{
                    p: 2,
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                    height: "100%",
                    overflow: "auto",
                    position: "relative",
                    padding:'16px'
                    }}
                >
                <Box sx={{ mt: 0 }}>
                  {orgTree?.length > 0 ? (
                    orgTree.map((node) => (
                      <TreeNode
                        key={node.id}
                        node={node}
                        selectedNodes={selectedNodes}
                        currentNode={currentNode}
                        onNodeSelect={handleNodeSelect}
                        onToggle={handleToggle}
                      />
                    ))
                  ) : (
                    <Typography sx={{ display: "flex", justifyContent: "center" }}>
                      No Data Available
                    </Typography>
                  )}
                </Box>
              </Paper>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  borderRadius: "8px",
                  backgroundColor: "#F8F9F9",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
                  height: "500px",
                  overflow: "auto",
                  padding:'16px'
                }}
              >
                <Typography variant="h6" sx={{ mt: 0, mb: 1 }}>
                  Selected Sites
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {selectedNodes.map((nodeId) => {
                  const hierarchy = findNodeHierarchy(orgTree, nodeId);
                  const hierarchyString = hierarchy
                    .map((node) => (node.orgLevel !== -1 ? ("L" + node.orgLevel + ":") : "") + " " + node.displayName)
                    .join(" → ");
                    {/* console.log("hierarchy",hierarchy); */}
                  return (
                    <Box
                      key={nodeId}
                      sx={{
                        mb: 1,
                        p: 1,
                        border: "1px solid #e0e0e0",
                        borderRadius: "8px",
                        backgroundColor: "#EDFBFF",
                      }}
                    >
                      <Typography variant="body2">{hierarchyString}</Typography>
                    </Box>
                  );
                })}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
            variant="contained"
            sx={{ color: "white", backgroundColor: '#09A2E3', borderRadius:'24px', width:'82px' }}
            disabled={!selectedNodes.length}
            onClick={onNext}
        >
            Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuditSitesTree;
